import { Instance, types } from "mobx-state-tree";
import { DateRangeFilterModel } from "./DateRangeFilterModel";
import { MultiSelectFilterValueModel } from "./MultiSelectFilterValueModel";
import { DateRangePickerInput } from "@surya-digital/leo-reactjs-material-ui";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { getFormattedDateString } from "@pulse/shared-components";

export enum FilterType {
  OpenEnded = "OPEN_ENDED",
  MultiSelect = "MULTI_SELECT",
  DateRange = "DATE_RANGE",
}

export const FilterModel = types
  .model("FilterModel", {
    key: types.maybe(types.string),
    filterType: types.enumeration(Object.values(FilterType)),
    openEndedFilterValue: types.maybe(types.string),
    multiSelectFilterValue: types.array(MultiSelectFilterValueModel),
    dateRangeFilterValue: types.maybeNull(DateRangeFilterModel),
  })
  .views((store) => ({
    get commaSeparatedMultiSelectFilterValues(): string {
      return store.multiSelectFilterValue
        .map((filter) => filter.label)
        .join(", ");
    },
    get getDateRangeFilterValueString(): string {
      if (
        store.dateRangeFilterValue?.startDate &&
        store.dateRangeFilterValue?.endDate
      ) {
        return `${getFormattedDateString(
          store.dateRangeFilterValue.startDate,
        )} - ${getFormattedDateString(store.dateRangeFilterValue.endDate)}`;
      } else {
        console.error(
          "startDate and endDate cannot be null or undefined in this flow.",
        );
        return EMPTY_CHARACTER;
      }
    },
    get getDateRangePickerInput(): DateRangePickerInput {
      return {
        startDate: store.dateRangeFilterValue?.startDate ?? null,
        endDate: store.dateRangeFilterValue?.endDate ?? null,
      };
    },
  }));

export const createFilterModel = (
  key: string | undefined,
): Instance<typeof FilterModel> => {
  return FilterModel.create({
    key,
    filterType: FilterType.OpenEnded,
  });
};
