import { Stack, Typography } from "@mui/material";
import React from "react";
import { CheckIcon } from "lucide-react";
import { TFunction } from "i18next";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { ICON_SIZE, getIconProps } from "@pulse/shared-components";

export interface FileUploadDescriptionProps {
  fileName: string | undefined;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

export const FileUploadedDescription = ({
  fileName,
  t,
  spacing,
  typography,
  tokens,
}: FileUploadDescriptionProps): React.ReactElement => {
  return (
    <Stack gap={spacing.spaceXXS}>
      <Typography {...typography.s2} color={tokens.label}>
        {t("projects.respondentList.fileDetails")}
      </Typography>
      <Typography {...typography.b2} color={tokens.label}>
        {fileName}
      </Typography>
      <Stack direction="row" gap={spacing.spaceXXS}>
        <CheckIcon {...getIconProps(tokens.iconSuccess, ICON_SIZE.default)} />
        <Typography {...typography.b2} color={tokens.labelSuccess}>
          {t("common.uploadDialog.fileUploaded")}
        </Typography>
      </Stack>
    </Stack>
  );
};
