import React, { useEffect } from "react";
import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  TableReloadHandle,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import {
  AddNewSPoCDialogState,
  AddNewSPoCRPCError,
  AddNewSPoCStore,
} from "../../store/AddNewSPoCStore";
import { observer } from "mobx-react";
import { AddNewSPoCChild } from "./AddNewSPoCChild";
import { DialogErrorContent } from "../../../../components/DialogErrorContent";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { DialogSuccessContent } from "../../../../components/DialogSuccessContent";

interface AddSPoCDialogProps {
  isAddSPoCDialogOpen: boolean;
  setIsAddSPoCDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  spacing: Spacing;
  addNewSPoCStore: Instance<typeof AddNewSPoCStore>;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  navigateTo: NavigateToFunctions;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const AddSPoCDialog = observer(
  ({
    isAddSPoCDialogOpen,
    setIsAddSPoCDialogOpen,
    t,
    spacing,
    addNewSPoCStore,
    typography,
    tokens,
    navigateTo,
    tableRef,
  }: AddSPoCDialogProps): React.ReactElement => {
    useEffect(() => {
      addNewSPoCStore.resetStore();
    }, []);

    const getErrorText = (): string => {
      switch (addNewSPoCStore.rpcError) {
        case AddNewSPoCRPCError.MaxSPoCReached: {
          return t(
            "projects.userAccess.addNewSPoC.maxSPoCsReachedErrorDescription",
          );
        }
        case AddNewSPoCRPCError.ProjectAlreadyArchived: {
          return t(
            "projects.userAccess.addNewSPoC.projectAlreadyArchivedErrorDescription",
          );
        }
        case AddNewSPoCRPCError.SPoCAlreadyExists: {
          return t(
            "projects.userAccess.addNewSPoC.spocNotUniqueErrorDescription",
          );
        }
        case AddNewSPoCRPCError.BOUCannotBeSPoC: {
          return t("projects.userAccess.addNewSPoC.bouCannotBeSPoC");
        }
        case AddNewSPoCRPCError.UnableToAddSPoC:
        default: {
          return t(
            "projects.userAccess.addNewSPoC.unexpectedErrorWhileAddingSPoC",
          );
        }
      }
    };

    const getDilogChild = (): React.ReactElement => {
      switch (addNewSPoCStore.addNewSPoCDialogState) {
        case AddNewSPoCDialogState.AddNewSPoCState: {
          return (
            <AddNewSPoCChild
              addNewSPoCStoreProp={addNewSPoCStore}
              spacing={spacing}
              t={t}
            />
          );
        }
        case AddNewSPoCDialogState.ErrorState: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
        case AddNewSPoCDialogState.SuccessState: {
          return (
            <DialogSuccessContent
              spacing={spacing}
              typography={typography}
              tokens={tokens}
              description={t(
                "projects.userAccess.addNewSPoC.spocAddedSuccessfullyDescription",
              )}
            />
          );
        }
      }
    };

    const getPrimaryButtonText = (): string | undefined => {
      return addNewSPoCStore.isPrimaryButtonVisible
        ? t("projects.userAccess.addNewSPoC.addSPoC")
        : undefined;
    };

    const getSecondaryButtonText = (): string => {
      switch (addNewSPoCStore.addNewSPoCDialogState) {
        case AddNewSPoCDialogState.AddNewSPoCState: {
          return t("common.cancel");
        }
        case AddNewSPoCDialogState.ErrorState: {
          return t("common.close");
        }
        case AddNewSPoCDialogState.SuccessState: {
          return t("common.done");
        }
      }
    };

    return (
      <Dialog
        open={isAddSPoCDialogOpen}
        width="560px"
        title={t("projects.userAccess.addNewSPoC.addNewSPoCDialogTitle")}
        contentPadding={spacing.spaceLG}
        primaryButtonText={getPrimaryButtonText()}
        isPrimaryButtonDisabled={addNewSPoCStore.isPrimaryButtonDisabled}
        onPrimaryButtonClick={async (): Promise<void> => {
          addNewSPoCStore.setIsAddSPoCButtonClicked(true);
          await processProjectParams(addNewSPoCStore.addNewSPoC, navigateTo);
        }}
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={(): void => {
          setIsAddSPoCDialogOpen(false);
          if (addNewSPoCStore.refreshTableOnDialogClose) {
            tableRef.current?.reload();
          }
          addNewSPoCStore.resetStore();
        }}
        isSecondaryButtonDisabled={addNewSPoCStore.isRPCLoading}
        disableBackdropClick={true}
      >
        {getDilogChild()}
      </Dialog>
    );
  },
);
