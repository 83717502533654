import { flow, getRoot, Instance, types } from "mobx-state-tree";
import {
  BOUserDetailsModel,
  createBOUserDetailsModel,
} from "../../models/BOUserDetailsModel";
import { getAPIClient } from "../../networking/APIClient";
import { EditBOUserRPC, UserName } from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { RootStore } from "../../root/store/RootStore";
import { useEditBOUserRPCClientImpl } from "../rpcs/RPC";

export enum EditBOUserDialogState {
  EditBOUser = "EditBOUser",
  Error = "Error",
}

export enum EditBOUserRPCError {
  InvalidRoleId = "INVALID_BO_USER_ID",
  UnableToUpdateBOUser = "UNABLE_TO_UPDATE_BO_USER",
}

export const EditBOUserStore = types
  .model("EditBOUserStore", {
    boUserDetails: BOUserDetailsModel,
    editBOUserDialogState: types.optional(
      types.enumeration(
        "EditBOUserDialogState",
        Object.values(EditBOUserDialogState),
      ),
      EditBOUserDialogState.EditBOUser,
    ),
    rpcError: types.maybeNull(
      types.enumeration(
        "EditBOUserRPCError",
        Object.values(EditBOUserRPCError),
      ),
    ),
    isEditBOButtonClicked: types.optional(types.boolean, false),
    isRPCLoading: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get areTextFieldsErrored(): boolean {
      return (
        store.boUserDetails.isFirstNameInvalid ||
        store.boUserDetails.isLastNameInvalid
      );
    },
    get isFirstNameInvalid(): boolean {
      return (
        store.isEditBOButtonClicked && store.boUserDetails.isFirstNameInvalid
      );
    },
    get isLastNameInvalid(): boolean {
      return (
        store.isEditBOButtonClicked && store.boUserDetails.isLastNameInvalid
      );
    },
  }))
  .actions((store) => ({
    resetStoreData: (): void => {
      store.boUserDetails = createBOUserDetailsModel();
      store.editBOUserDialogState = EditBOUserDialogState.EditBOUser;
      store.isEditBOButtonClicked = false;
    },
    setIsEditBOButtonClicked: (isButtonClicked: boolean): void => {
      store.isEditBOButtonClicked = isButtonClicked;
    },
    editBOUser: flow(function* (boUserId: string) {
      store.rpcError = null;
      if (
        store.areTextFieldsErrored ||
        store.boUserDetails.selectedRole === null
      ) {
        return;
      }
      try {
        store.isRPCLoading = true;
        const apiClient = getAPIClient(store);
        const request = new EditBOUserRPC.Request(
          new LeoUUID(boUserId),
          new UserName(
            store.boUserDetails.firstName,
            store.boUserDetails.lastName,
          ),
          store.boUserDetails.selectedRole,
        );
        const {
          response,
          error,
        }: {
          response?: EditBOUserRPC.Response;
          error?: EditBOUserRPC.Errors.Errors;
        } = yield useEditBOUserRPCClientImpl(apiClient).execute(request);
        if (response) {
          // We don't need to do anything on response.
        } else if (error) {
          store.editBOUserDialogState = EditBOUserDialogState.Error;
          switch (error.code) {
            case EditBOUserRPCError.InvalidRoleId:
              store.rpcError = EditBOUserRPCError.InvalidRoleId;
              break;
            case EditBOUserRPCError.UnableToUpdateBOUser:
              store.rpcError = EditBOUserRPCError.UnableToUpdateBOUser;
              break;
            default:
              console.error(`Unhandled RPCError ${error.code}`);
              break;
          }
        }
      } catch (e) {
        store.editBOUserDialogState = EditBOUserDialogState.Error;
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
      store.isRPCLoading = false;
      store.isEditBOButtonClicked = false;
    }),
  }));

export const createEditBOUserStore = (): Instance<typeof EditBOUserStore> => {
  return EditBOUserStore.create({ boUserDetails: createBOUserDetailsModel() });
};
