import { Stack } from "@mui/material";
import {
  BorderStyle,
  EMBED_CODE_VALIDATIONS,
  EMBED_NAME_VALIDATIONS,
  getCheckBoxState,
} from "@pulse/shared-components";
import {
  Spacing,
  FoundationColorTokens,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  TextInputField,
  TextAreaInputField,
  Checkbox,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import { AddNewEmbedError, EmbedStore } from "../../store/EmbedStore";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";
import { DialogType } from "../spocManagement/AssignAndViewSpocsDialog";
import { AssignAndViewSpocSelection } from "../spocManagement/AssignRespondentSelection";

interface AddNewEmbedDialogChildProps {
  t: TFunction;
  spacing: Spacing;
  embedStore: Instance<typeof EmbedStore>;
  projectDetailsStore: Instance<typeof ProjectDetailsStore>;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  border: BorderStyle;
}

export const AddNewEmbedDialogChild = observer(
  ({
    t,
    spacing,
    embedStore,
    projectDetailsStore,
    tokens,
    typography,
    border,
  }: AddNewEmbedDialogChildProps): React.ReactElement => {
    const getEmbedNameTextFieldHelperText = (): string | undefined => {
      switch (embedStore.rpcAndValidationError) {
        case AddNewEmbedError.InvalidEmbedName:
          return t(
            "projects.reports.embeds.embedNameHelperText.invalidEmbedName",
            {
              allowedSpecialCharacters:
                EMBED_NAME_VALIDATIONS.allowedSpecialCharacters,
            },
          );
        case AddNewEmbedError.InvalidEmbedNameLength:
          return t(
            "projects.reports.embeds.embedNameHelperText.invalidEmbedNameLength",
            {
              minLength: EMBED_NAME_VALIDATIONS.minLength,
              maxLength: EMBED_NAME_VALIDATIONS.maxLength,
            },
          );
        case AddNewEmbedError.EmbedNameNotUnique:
          return t("projects.reports.embeds.embedNameNotUniqueErrorText");
      }
    };

    const getEmbedCodeFieldHelperText = (): string | undefined => {
      switch (embedStore.rpcAndValidationError) {
        case AddNewEmbedError.InvalidEmbedCode: {
          return t("projects.reports.embeds.embedNameHelperText.invalidIframe");
        }
        case AddNewEmbedError.InvalidEmbedCodeLength: {
          return t(
            "projects.reports.embeds.embedNameHelperText.invalidEmbedCodeLength",
            {
              minLength: EMBED_CODE_VALIDATIONS.minLength,
              maxLength: EMBED_CODE_VALIDATIONS.maxLength,
            },
          );
        }
      }
    };

    return (
      <Stack padding={spacing.spaceLG} gap={spacing.spaceLG} width="100%">
        <TextInputField
          label={t("projects.reports.embeds.embedName")}
          placeholder={t("projects.reports.embeds.enterEmbedName")}
          name="embedName"
          onTextChange={embedStore.setEmbedName}
          value={embedStore.embedName}
          type="text"
          helperText={getEmbedNameTextFieldHelperText()}
          error={embedStore.doesEmbedNameTextFieldContainError}
          required={true}
        />
        <TextAreaInputField
          label={t("projects.reports.embeds.embedCode")}
          placeholder={t("projects.reports.embeds.enterEmbedCode")}
          name="embedCode"
          onTextChange={embedStore.setEmbedCode}
          value={embedStore.embedCode}
          helperText={getEmbedCodeFieldHelperText()}
          error={embedStore.doesEmbedCodeTextFieldContainError}
          required={true}
          numberOfRows={2}
        />
        <Checkbox
          state={getCheckBoxState(embedStore.isAssignToSelectedSpocsChecked)}
          label={t("projects.reports.embeds.assignToSelectedSPoCs")}
          onChange={embedStore.setAssignToSelectedSpocs}
        />
        <AssignAndViewSpocSelection
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          t={t}
          border={border}
          dialogType={DialogType.AssignSpocs}
          projectDetailsStore={projectDetailsStore}
          searchSpocFieldDescription={t(
            "projects.reports.embeds.searchAndAddSPoCsDialogDescription",
          )}
          searchSpocFieldGap={spacing.spaceSM}
          searchSpocFieldPadding={undefined}
          noSpocsFoundContainerGap={undefined}
          noSpocsFoundContainerPadding={undefined}
          isSearchProjectSpocFieldDisabled={
            projectDetailsStore.isSearchProjectSpocFieldDisabled ||
            !projectDetailsStore.reportStore.embedStore
              .isAssignToSelectedSpocsChecked
          }
          selectedSpocListGap={spacing.spaceMD}
          selectedSpocListPadding={undefined}
          selectedSpocElementPadding={undefined}
          matchedSpocs={undefined}
          noSpocsFoundText={t(
            "projects.assignSpocsToRespondents.noSpocsFoundText",
          )}
          searchSpocFieldTitle={undefined}
          selectedRespondentOrEmbedName={undefined}
          isSpocListEmpty={projectDetailsStore.isProjectSpocListEmpty}
        />
      </Stack>
    );
  },
);
