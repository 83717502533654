import { Stack, Typography } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import {
  Button,
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { BorderStyle, EMPTY_CHARACTER } from "@pulse/shared-components";
import { TFunction } from "i18next";
import { DividerComponent } from "../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";

interface SectionComponentProps extends PropsWithChildren {
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  border: BorderStyle;
  borderRadius: CornerRadius;
  title: string;
  onExpand?: () => Promise<void>;
}

export const SectionComponent = ({
  t,
  border,
  borderRadius,
  spacing,
  typography,
  tokens,
  title,
  children,
  onExpand,
}: SectionComponentProps): React.ReactElement => {
  const [isChildVisible, setIsChildVisible] = useState(false);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      border={border.default}
      borderRadius={borderRadius.radiusXS}
      height="fit-content !important"
      divider={<DividerComponent width="100%" orientation="horizontal" />}
      sx={{ overflowY: "visible" }}
      minWidth="1264px"
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        boxSizing="border-box"
        padding={`${spacing.spaceSM} ${spacing.spaceXL}`}
        gap={spacing.spaceXS}
        justifyContent={"space-between"}
        width="100%"
      >
        <Typography {...typography.sh3} color={tokens.labelHighEmphasis}>
          {title}
        </Typography>
        <Button
          label={
            isChildVisible
              ? t("projects.reports.embeds.embedSection.showLess")
              : t("projects.reports.embeds.embedSection.showMore")
          }
          name={EMPTY_CHARACTER}
          size={"small"}
          variant={"plain-color"}
          onClick={async (): Promise<void> => {
            setIsChildVisible(!isChildVisible);
            if (!isChildVisible && onExpand) {
              await onExpand();
            }
          }}
        />
      </Stack>
      {isChildVisible && children}
    </Stack>
  );
};
