import React, { SetStateAction } from "react";
import { QuestionItemProps } from "./QuestionItem";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";
import { Instance } from "mobx-state-tree";
import { TFunction } from "i18next";
import { ChildQuestionItemComponent } from "./ChildQuestionItemComponent";
import {
  FoundationColorTokens,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import { ChildQuestionItemButton } from "./ChildQuestionItemButton";
import { BorderStyle } from "@pulse/shared-components";
import { SurveyStore } from "../../store/SurveyStore";

export interface ChildQuestionItemsListProps {
  questionItem: QuestionItemProps;
  questionDetails: Instance<typeof QuestionDetailsStore>;
  t: TFunction;
  surveyStore: Instance<typeof SurveyStore>;
  setSelectedItemQuestionId: (value: SetStateAction<string | null>) => void;
  setIsMessageBoxVisible: (value: SetStateAction<boolean>) => void;
  setMessageBoxQuestionItemId: (value: SetStateAction<string | null>) => void;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  selectedQuestionItemId: string | null;
  border: BorderStyle;
}

export const ChildQuestionItemsList = observer(
  ({
    questionDetails,
    questionItem,
    t,
    surveyStore,
    setIsMessageBoxVisible,
    setMessageBoxQuestionItemId,
    setSelectedItemQuestionId,
    selectedQuestionItemId,
    ...props
  }: ChildQuestionItemsListProps): React.ReactElement => {
    const newChildQuestion = surveyStore.findNewlyAddedGroupChildQuestion(
      questionItem.questionId,
    );
    return (
      <>
        {questionDetails.childQuestionIds.map((childQuestionId) => {
          const childQuestionDetails =
            surveyStore.findQuestionById(childQuestionId);
          const isItemSelected =
            selectedQuestionItemId ===
            childQuestionDetails?.surveyQuestionDetails.questionId;
          return childQuestionDetails ? (
            !isItemSelected ? (
              <ChildQuestionItemButton
                {...props}
                childQuestionDetails={childQuestionDetails}
                questionDetails={questionDetails}
                questionId={questionItem.questionId}
                setSelectedItemQuestionId={setSelectedItemQuestionId}
                setIsMessageBoxVisible={setIsMessageBoxVisible}
                setMessageBoxQuestionItemId={setMessageBoxQuestionItemId}
                t={t}
                key={childQuestionId}
              >
                <ChildQuestionItemComponent
                  {...props}
                  childQuestionDetails={childQuestionDetails}
                  isItemSelected={isItemSelected}
                />
              </ChildQuestionItemButton>
            ) : (
              <ChildQuestionItemComponent
                {...props}
                childQuestionDetails={childQuestionDetails}
                isItemSelected={isItemSelected}
                key={childQuestionId}
              />
            )
          ) : (
            console.error("Child question details were not found.")
          );
        })}

        {newChildQuestion && (
          <ChildQuestionItemComponent
            {...props}
            childQuestionDetails={newChildQuestion}
            isItemSelected={true}
          />
        )}
      </>
    );
  },
);
