import React from "react";
import { ChevronUp } from "@surya-digital/leo-reactjs-material-ui";
import {
  ChevronDown,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { BorderStyle } from "@pulse/shared-components";

export interface MenuItemProps {
  label: string;
  onClick: () => void;
}

export interface MenuButtonProps {
  label: string;
  menuItems: MenuItemProps[];
  disabled?: boolean;
  menuFullWidth?: boolean;
  cornerRadius: CornerRadius;
  spacing: Spacing;
  border: BorderStyle;
  colorPalette: FoundationColorTokens<string>;
  typography: LeoTypography;
}

export const MenuButton = ({
  label,
  menuItems,
  disabled = false,
  menuFullWidth = false,
  cornerRadius,
  colorPalette,
  border,
  typography,
  spacing,
}: MenuButtonProps): React.ReactElement => {
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null,
  );
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const open = Boolean(anchorElement);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorElement(null);
  };

  return (
    <div>
      <Button
        disableElevation
        name={label}
        ref={buttonRef}
        disabled={disabled}
        sx={{
          height: "32px",
          borderRadius: cornerRadius.radiusXS,
          padding: `${spacing.spaceXS} ${spacing.spaceMD}`,
          border: border.default,
          outline: "outline-width",
          color: colorPalette.label,
          position: "relative",
          "&:hover": {
            background: colorPalette.backgroundHovered,
            border: border.default,
          },
          "&& .MuiTouchRipple-child": {
            background: colorPalette.backgroundHovered,
          },
        }}
        variant={"outlined"}
        onClick={handleClick}
      >
        <>
          {label && (
            <Typography
              sx={{
                textTransform: "none",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                typography: typography.bt3,
                marginRight: spacing.spaceXXS,
              }}
            >
              {label}
            </Typography>
          )}
          <Stack width="16px" height="16px">
            {open ? (
              <ChevronUp
                color={
                  disabled ? colorPalette.iconLowEmphasis : colorPalette.icon
                }
              />
            ) : (
              <ChevronDown
                color={
                  disabled ? colorPalette.iconLowEmphasis : colorPalette.icon
                }
              />
            )}
          </Stack>
        </>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        sx={{
          "& .MuiPaper-root": {
            width: menuFullWidth ? "unset" : buttonRef.current?.clientWidth,
            "& .MuiMenuItem-root:hover": {
              backgroundColor: colorPalette.backgroundSelected,
            },
          },
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={(): void => {
              item.onClick();
              handleClose();
            }}
          >
            <Typography sx={{ ...typography.b1, color: colorPalette.label }}>
              {item.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
