import { Stack, Typography } from "@mui/material";
import {
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { PasswordInputField } from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import React from "react";
import { UserAccessStore } from "../../../store/UserAccessStore";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

interface ChangePasswordChildProps {
  spacing: Spacing;
  userAccessStore: Instance<typeof UserAccessStore>;
  t: TFunction;
  typography: LeoTypography;
}

export const ChangePasswordChild = observer(
  ({
    spacing,
    userAccessStore,
    t,
    typography,
  }: ChangePasswordChildProps): React.ReactElement => {
    const getNewPasswordHelperText = (): string | undefined => {
      if (
        !userAccessStore.isEnteredPasswordsSame &&
        userAccessStore.isChangePasswordDialogHelperTextsVisible
      ) {
        return t("projects.spocRespondent.settings.passwordsDoNotMatch");
      } else if (userAccessStore.isInvalidPasswordError) {
        return t(
          "projects.spocRespondent.settings.passwordDoesNotFollowGuidelined",
        );
      }
    };

    const getConfirmNewPasswordHelperText = (): string | undefined => {
      if (
        !userAccessStore.isEnteredPasswordsSame &&
        userAccessStore.isChangePasswordDialogHelperTextsVisible
      ) {
        return t("projects.spocRespondent.settings.passwordsDoNotMatch");
      } else if (userAccessStore.isInvalidPasswordError) {
        return t(
          "projects.spocRespondent.settings.passwordDoesNotFollowGuidelined",
        );
      }
    };

    return (
      <Stack gap={spacing.spaceLG} width="100%">
        <PasswordInputField
          id={t("projects.spocRespondent.settings.newPasswordLabel")}
          label={t("projects.spocRespondent.settings.newPasswordLabel")}
          name={t("projects.spocRespondent.settings.newPasswordLabel")}
          onTextChange={(newPassword: string) => {
            userAccessStore.setNewPassword(newPassword);
          }}
          placeholder={t(
            "projects.spocRespondent.settings.newPasswordPlaceholder",
          )}
          value={userAccessStore.newPassword}
          required={true}
          error={userAccessStore.isNewPasswordInvalid}
          helperText={getNewPasswordHelperText()}
          disabled={userAccessStore.isChangeSPoCPasswordRPCLoading}
        />
        <Stack gap={spacing.spaceXXS}>
          <PasswordInputField
            id={t("projects.spocRespondent.settings.confirmNewPasswordLabel")}
            label={t(
              "projects.spocRespondent.settings.confirmNewPasswordLabel",
            )}
            name={t("projects.spocRespondent.settings.confirmNewPasswordLabel")}
            onTextChange={(confirmNewPassword: string) => {
              userAccessStore.setConfirmNewPassword(confirmNewPassword);
            }}
            placeholder={t(
              "projects.spocRespondent.settings.confirmNewPasswordPlaceholder",
            )}
            value={userAccessStore.confirmNewPassword}
            required={true}
            error={userAccessStore.isConfirmNewPasswordInvalid}
            helperText={getConfirmNewPasswordHelperText()}
            disabled={userAccessStore.isChangeSPoCPasswordRPCLoading}
          />
          <Stack gap={spacing.spaceXXS}>
            <Typography {...typography.s3}>
              {t("projects.spocRespondent.settings.thePasswordMust")}
            </Typography>
            <Typography {...typography.b3}>
              <li>{t("projects.spocRespondent.settings.mustBe8CharLong")}</li>
            </Typography>
            <Typography {...typography.b3}>
              <li>{t("projects.spocRespondent.settings.includeAtleast")}</li>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  },
);
