import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  useFoundationColorTokens,
  useRadioButtonColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { useSingleChoiceQuestionV1Store } from "../store/hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  SingleChoiceDisplayTypeV1Enum,
  getRadioButtonStyleProps,
} from "@pulse/shared-components";

export const ChoiceDisplayRadioComponent = observer((): React.ReactElement => {
  const typography = useTypography();
  const tokens = useFoundationColorTokens();
  const radioButtonTokens = useRadioButtonColorTokens();
  const { t } = useTranslation();
  const singleChoiceStore = useSingleChoiceQuestionV1Store();
  const spacing = useSpacing();
  const radioButtonStyleProps = getRadioButtonStyleProps(
    tokens,
    radioButtonTokens,
    spacing,
  );

  return (
    <RadioGroup
      onChange={(e): void => {
        singleChoiceStore.setSingleChoiceDisplayType(e.target.value, t);
        if (
          singleChoiceStore.singleSelectQuestionV1Model.isNpsBorderColor ||
          singleChoiceStore.singleSelectQuestionV1Model.isNpsPreFilledColor
        ) {
          singleChoiceStore.setNPSSelection(true);
        } else if (singleChoiceStore.singleSelectQuestionV1Model.isDefaultNps) {
          singleChoiceStore.setNPSSelection(false);
        }
      }}
    >
      <Stack direction="row" gap={spacing.spaceXS}>
        <Stack minWidth="200px">
          <Typography sx={{ ...typography.sh3, marginBottom: spacing.spaceSM }}>
            {t("surveys.addSurveyQuestion.singleChoice.selection")}
          </Typography>
          <FormControlLabel
            value={SingleChoiceDisplayTypeV1Enum.RadioButton}
            label={t("surveys.addSurveyQuestion.singleChoice.radioButtons")}
            control={<Radio {...radioButtonStyleProps} />}
            checked={singleChoiceStore.singleSelectQuestionV1Model.isRadio}
          />
          <FormControlLabel
            value={SingleChoiceDisplayTypeV1Enum.Card}
            label={t("surveys.addSurveyQuestion.singleChoice.cards")}
            checked={singleChoiceStore.singleSelectQuestionV1Model.isCard}
            control={<Radio {...radioButtonStyleProps} />}
          />
          <FormControlLabel
            value={SingleChoiceDisplayTypeV1Enum.Dropdown}
            label={t("surveys.addSurveyQuestion.singleChoice.dropdown")}
            control={<Radio {...radioButtonStyleProps} />}
            checked={singleChoiceStore.singleSelectQuestionV1Model.isDropdown}
          />
        </Stack>
        <Stack minWidth="200px">
          <Typography sx={{ ...typography.sh3, marginBottom: spacing.spaceSM }}>
            {t("surveys.addSurveyQuestion.singleChoice.slider")}
          </Typography>
          <FormControlLabel
            value={SingleChoiceDisplayTypeV1Enum.DefaultSlider}
            label={t("surveys.addSurveyQuestion.singleChoice.defaultSlider")}
            control={<Radio {...radioButtonStyleProps} />}
            checked={
              singleChoiceStore.singleSelectQuestionV1Model.isDefaultSlider
            }
          />
          <FormControlLabel
            value={SingleChoiceDisplayTypeV1Enum.RygSlider}
            label={t("surveys.addSurveyQuestion.singleChoice.rygSlider")}
            control={<Radio {...radioButtonStyleProps} />}
            checked={singleChoiceStore.singleSelectQuestionV1Model.isRygSlider}
          />
        </Stack>
        <Stack minWidth="200px">
          <Typography sx={{ ...typography.sh3, marginBottom: spacing.spaceSM }}>
            {t("surveys.addSurveyQuestion.singleChoice.ratingScale")}
          </Typography>
          <FormControlLabel
            value={SingleChoiceDisplayTypeV1Enum.DefaultScale}
            label={t("surveys.addSurveyQuestion.singleChoice.defaultScale")}
            control={<Radio {...radioButtonStyleProps} />}
            checked={
              singleChoiceStore.singleSelectQuestionV1Model.isDefaultScale
            }
          />
          <FormControlLabel
            value={SingleChoiceDisplayTypeV1Enum.ScaleWithText}
            label={t("surveys.addSurveyQuestion.singleChoice.scaleWithText")}
            control={<Radio {...radioButtonStyleProps} />}
            checked={
              singleChoiceStore.singleSelectQuestionV1Model.isScaleWithText
            }
          />
          <FormControlLabel
            value={SingleChoiceDisplayTypeV1Enum.NeutralScale}
            label={t("surveys.addSurveyQuestion.singleChoice.neutralScale")}
            control={<Radio {...radioButtonStyleProps} />}
            checked={
              singleChoiceStore.singleSelectQuestionV1Model.isNeutralScale
            }
          />
        </Stack>
        <Stack minWidth="200px">
          <Typography sx={{ ...typography.sh3, marginBottom: spacing.spaceSM }}>
            {t("surveys.addSurveyQuestion.singleChoice.netPromoterScore")}
          </Typography>
          <FormControlLabel
            value={SingleChoiceDisplayTypeV1Enum.DefaultNps}
            label={t("surveys.addSurveyQuestion.singleChoice.defaultNPS")}
            control={<Radio {...radioButtonStyleProps} />}
            checked={singleChoiceStore.singleSelectQuestionV1Model.isDefaultNps}
          />
          <FormControlLabel
            value={SingleChoiceDisplayTypeV1Enum.NpsPreFilledColor}
            label={t(
              "surveys.addSurveyQuestion.singleChoice.npsPrefilledColor",
            )}
            control={<Radio {...radioButtonStyleProps} />}
            checked={
              singleChoiceStore.singleSelectQuestionV1Model.isNpsPreFilledColor
            }
          />
          <FormControlLabel
            value={SingleChoiceDisplayTypeV1Enum.NpsBorderColor}
            label={t("surveys.addSurveyQuestion.singleChoice.npsBorderColor")}
            control={<Radio {...radioButtonStyleProps} />}
            checked={
              singleChoiceStore.singleSelectQuestionV1Model.isNpsBorderColor
            }
          />
        </Stack>
      </Stack>
    </RadioGroup>
  );
});
