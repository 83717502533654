import { NetworkingError } from "@pulse/shared-components";
import { Instance, types } from "mobx-state-tree";

export enum GetPresignedDownloadURLForRespondentsWithFiltersErrors {
  DeletedColumnFilter = "DELETED_COLUMN_FILTER",
}

export enum DownloadRespondentCSVDialogState {
  DownloadConfirmation = "DOWNLOAD_CONFIRMATION",
  SuccessState = "SUCCESS_STATE",
  ErrorState = "ERROR_STATE",
}

export const RespondentDownloadStore = types
  .model("RespondentDownloadStore", {
    rpcErrors: types.maybeNull(
      types.union(
        types.enumeration(
          Object.values(GetPresignedDownloadURLForRespondentsWithFiltersErrors),
        ),
        types.enumeration(Object.values(NetworkingError)),
      ),
    ),
    downloadRespondentCSVDialogState: types.optional(
      types.enumeration(Object.values(DownloadRespondentCSVDialogState)),
      DownloadRespondentCSVDialogState.DownloadConfirmation,
    ),
    isRPCLoading: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    resetRespondentCSVDialog: (): void => {
      store.downloadRespondentCSVDialogState =
        DownloadRespondentCSVDialogState.DownloadConfirmation;
      store.rpcErrors = null;
    },
  }))
  .views((store) => ({
    get isDownloadRespondentsCSVPrimaryButtonVisible(): boolean {
      return (
        store.downloadRespondentCSVDialogState ===
        DownloadRespondentCSVDialogState.DownloadConfirmation
      );
    },
  }));

export const createRespondentDownloadStore = (): Instance<
  typeof RespondentDownloadStore
> => {
  return RespondentDownloadStore.create();
};
