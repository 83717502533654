import React from "react";
import {
  MAX_FIELD_HINT_CHARACTER_LIMIT,
  ZERO_VALUE,
  showMaxCharacterCountIndicator,
} from "@pulse/shared-components";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { TextInputField } from "@surya-digital/leo-reactjs-material-ui";

export interface OpenEndedFieldTextInputProps {
  value: string | undefined;
  onTextChange: (inputString: string) => void;
  t: TFunction;
  error?: boolean;
  width: string | undefined;
}

export const OpenEndedFieldHintInput = observer(
  ({
    value,
    onTextChange,
    t,
    width,
  }: OpenEndedFieldTextInputProps): React.ReactElement => {
    return (
      <TextInputField
        name="hint"
        width={width}
        minWidth={width}
        value={value}
        placeholder={t("surveys.addSurveyQuestion.enterHint")}
        type="text"
        label={t("surveys.addSurveyQuestion.openEnded.hint")}
        onTextChange={(inputValue) => {
          onTextChange(
            inputValue.slice(ZERO_VALUE, MAX_FIELD_HINT_CHARACTER_LIMIT),
          );
        }}
        maxCharacterLength={MAX_FIELD_HINT_CHARACTER_LIMIT}
        showCharacterCountIndicator={(): boolean =>
          showMaxCharacterCountIndicator(MAX_FIELD_HINT_CHARACTER_LIMIT, value)
        }
      />
    );
  },
);
