import { Instance, types } from "mobx-state-tree";

export const ProjectIdAndNameModel = types.model("ProjectIdAndNameModel", {
  projectId: types.string,
  projectName: types.string,
});

export const createProjectIdAndNameModel = (
  projectId: string,
  projectName: string,
): Instance<typeof ProjectIdAndNameModel> => {
  return ProjectIdAndNameModel.create({
    projectId,
    projectName,
  });
};
