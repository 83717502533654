import { Instance, flow, getRoot, types } from "mobx-state-tree";
import { RootStore } from "../../root/store/RootStore";
import {
  ResetUserPasswordRPC,
  UpdateUsernameRPC,
  UserName,
} from "@pulse/pulse-rpcs";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import {
  useResetUserPasswordRPCClient,
  useUpdateUsernameRPCClient,
} from "../rpcs/RPC";
import { getAPIClient } from "../../networking/APIClient";
import { getPersistedUserName } from "../../store/user/UserPrivileges";
import { UserNameModel } from "../../models/UserNameModel";
import { UserLocalStorageKeys } from "../../store/user/UserLocalStorageKeys";
import {
  MIN_USERNAME_CHAR_LIMIT,
  MAX_USERNAME_CHAR_LIMIT,
} from "@pulse/shared-components";
import { FIRST_AND_LAST_NAME_REGEX } from "@pulse/shared-components";

export enum UpdateUserNameErrors {
  UnableToUpdateUserName = "UNABLE_TO_UPDATE_USERNAME",
  InvalidFirstName = "INVALID_FIRST_NAME",
  InvalidLastName = "INVALID_LAST_NAME",
  InvalidUserName = "INVALID_USER_NAME",
}

export enum ResetUserPasswordRPCErrors {
  SendEmailFailed = "SEND_EMAIL_FAILED",
  EmailAlreadySent = "EMAIL_ALREADY_SENT",
  ResetPasswordLimitReached = "RESET_PASSWORD_LIMIT_REACHED",
}

export enum ResetUserPasswordDialogState {
  ResetUserPassword = "ResetUserPassword",
  Error = "Error",
  Success = "Success",
}

export const ProfileStore = types
  .model("ProfileStore", {
    userName: UserNameModel,
    resetUserPasswordDialogState: types.optional(
      types.enumeration(
        "ResetUserPasswordDialogState",
        Object.values(ResetUserPasswordDialogState),
      ),
      ResetUserPasswordDialogState.ResetUserPassword,
    ),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          "UpdateUserNameErrors",
          Object.values(UpdateUserNameErrors),
        ),
        types.enumeration(
          "ResetUserPasswordErrors",
          Object.values(ResetUserPasswordRPCErrors),
        ),
      ),
    ),
    isSaveButtonClicked: types.optional(types.boolean, false),
    isUpdateUsernameRPCLoading: types.optional(types.boolean, false),
    isResetPasswordRPCLoading: types.optional(types.boolean, false),
    isUpdateUsernameErrorDialogVisible: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get isUserNameUnchanged(): boolean {
      return (
        store.userName.firstName.trim() ===
          localStorage.getItem(UserLocalStorageKeys.firstName)?.trim() &&
        store.userName.lastName.trim() ===
          localStorage.getItem(UserLocalStorageKeys.lastName)?.trim()
      );
    },
  }))
  .views((store) => ({
    get doesStoreContainErrors(): boolean {
      return store.rpcError !== null;
    },
    get isSaveChangesButtonDisabled(): boolean {
      return (
        store.isUserNameUnchanged ||
        store.userName.firstName.trim() === EMPTY_CHARACTER ||
        store.userName.lastName.trim() === EMPTY_CHARACTER ||
        store.rpcError !== null
      );
    },
    get doesFirstNameTextFieldContainErrors(): boolean {
      return (
        store.rpcError === UpdateUserNameErrors.InvalidFirstName ||
        store.rpcError === UpdateUserNameErrors.InvalidUserName
      );
    },
    get doesLastNameTextFieldContainErrors(): boolean {
      return (
        store.rpcError === UpdateUserNameErrors.InvalidLastName ||
        store.rpcError === UpdateUserNameErrors.InvalidUserName
      );
    },
  }))
  .actions((store) => ({
    setFirstName: (firstName: string): void => {
      store.userName.firstName = firstName;
    },
    setLastName: (lastName: string): void => {
      store.userName.lastName = lastName;
    },
    resetRPCError: (): void => {
      store.rpcError = null;
    },
    isUserNameValid: (): boolean => {
      try {
        const firstName = store.userName.firstName.trim();
        const lastName = store.userName.lastName.trim();
        const userNameRegex = new RegExp(FIRST_AND_LAST_NAME_REGEX);
        if (
          firstName.length < MIN_USERNAME_CHAR_LIMIT ||
          firstName.length > MAX_USERNAME_CHAR_LIMIT ||
          !userNameRegex.test(firstName)
        ) {
          store.rpcError = UpdateUserNameErrors.InvalidFirstName;
          return false;
        }
        if (
          lastName.length < MIN_USERNAME_CHAR_LIMIT ||
          lastName.length > MAX_USERNAME_CHAR_LIMIT ||
          !userNameRegex.test(lastName)
        ) {
          store.rpcError = UpdateUserNameErrors.InvalidLastName;
          return false;
        }
        new UserName(
          store.userName.firstName.trim(),
          store.userName.lastName.trim(),
        );

        return true;
      } catch (e) {
        store.rpcError = UpdateUserNameErrors.InvalidUserName;
        return false;
      }
    },
    resetStore: (): void => {
      store.userName = getPersistedUserName();
      store.isUpdateUsernameRPCLoading = false;
      store.isResetPasswordRPCLoading = false;
      store.rpcError = null;
      store.isUpdateUsernameErrorDialogVisible = false;
      store.isSaveButtonClicked = false;
      store.resetUserPasswordDialogState =
        ResetUserPasswordDialogState.ResetUserPassword;
    },
    resetResetUserPasswordDialog: (): void => {
      store.resetUserPasswordDialogState =
        ResetUserPasswordDialogState.ResetUserPassword;
      store.rpcError = null;
      store.isResetPasswordRPCLoading = false;
    },
    setIsUpdateUsernameErrorDialogVisible: (
      isUpdateUsernameErrorDialogVisible: boolean,
    ): void => {
      store.isUpdateUsernameErrorDialogVisible =
        isUpdateUsernameErrorDialogVisible;
    },
  }))
  .actions((store) => ({
    resetErrorDialog: (): void => {
      store.resetRPCError();
      store.setIsUpdateUsernameErrorDialogVisible(false);
    },
    updateUserName: flow(function* () {
      store.isUpdateUsernameRPCLoading = true;
      store.rpcError = null;
      store.userName.firstName = store.userName.firstName.trim();
      store.userName.lastName = store.userName.lastName.trim();
      try {
        const apiClient = getAPIClient(store);
        const request = new UpdateUsernameRPC.Request(
          new UserName(store.userName.firstName, store.userName.lastName),
        );
        const {
          response,
          error,
        }: {
          response?: UpdateUsernameRPC.Response;
          error?: UpdateUsernameRPC.Errors.Errors;
        } = yield useUpdateUsernameRPCClient(apiClient).execute(request);

        if (response) {
          // The privileges are persisted for the following reasons.
          // 1. They help to identify if the user is logged in or not.
          // 2. This ensures that we don't lose privileges once the page reloads ( since the state is refreshed ).
          localStorage.setItem(
            UserLocalStorageKeys.firstName,
            store.userName.firstName,
          );
          localStorage.setItem(
            UserLocalStorageKeys.lastName,
            store.userName.lastName,
          );
        } else if (error) {
          store.setIsUpdateUsernameErrorDialogVisible(true);
          switch (error.code) {
            case UpdateUserNameErrors.UnableToUpdateUserName: {
              store.rpcError = UpdateUserNameErrors.UnableToUpdateUserName;
              break;
            }
            default: {
              /* This error is set here so that that page goes into an error state in case there is an
               *  unexpected RPC error. */
              store.rpcError = UpdateUserNameErrors.UnableToUpdateUserName;
              console.error(`Unexpected error occured: ${error}`);
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isUpdateUsernameRPCLoading = false;
      }
    }),
    resetUserPassword: flow(function* () {
      store.isResetPasswordRPCLoading = true;
      store.rpcError = null;
      try {
        const apiClient = getAPIClient(store);
        const request = new ResetUserPasswordRPC.Request();
        const {
          response,
          error,
        }: {
          response?: ResetUserPasswordRPC.Response;
          error?: ResetUserPasswordRPC.Errors.Errors;
        } = yield useResetUserPasswordRPCClient(apiClient).execute(request);

        if (response) {
          store.resetUserPasswordDialogState =
            ResetUserPasswordDialogState.Success;
          // The privileges are persisted for the following reasons.
          // 1. They help to identify if the user is logged in or not.
          // 2. This ensures that we don't lose privileges once the page reloads ( since the state is refreshed ).
          localStorage.setItem(
            UserLocalStorageKeys.firstName,
            store.userName.firstName.trim(),
          );
          localStorage.setItem(
            UserLocalStorageKeys.lastName,
            store.userName.lastName.trim(),
          );
        } else if (error) {
          store.resetUserPasswordDialogState =
            ResetUserPasswordDialogState.Error;
          switch (error.code) {
            case ResetUserPasswordRPCErrors.SendEmailFailed: {
              store.rpcError = ResetUserPasswordRPCErrors.SendEmailFailed;
              break;
            }
            case ResetUserPasswordRPCErrors.EmailAlreadySent: {
              store.rpcError = ResetUserPasswordRPCErrors.EmailAlreadySent;
              break;
            }
            case ResetUserPasswordRPCErrors.ResetPasswordLimitReached: {
              store.rpcError =
                ResetUserPasswordRPCErrors.ResetPasswordLimitReached;
              break;
            }
            default: {
              console.error(`Unexpected error occured: ${error}`);
            }
          }
        }
      } catch (e) {
        store.resetUserPasswordDialogState = ResetUserPasswordDialogState.Error;
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isResetPasswordRPCLoading = false;
      }
    }),
  }));

export const createProfileStore = (): Instance<typeof ProfileStore> => {
  return ProfileStore.create({ userName: getPersistedUserName() });
};
