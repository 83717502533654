import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import { TranslationsStore } from "../../store/TranslationsStore";
import { BorderStyle } from "@pulse/shared-components";
import {
  CornerRadius,
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
  Tabs,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { TranslationDataList } from "./TranslationDataList";
import { Stack } from "@mui/material";

export interface TranslationDataTabsProps {
  translationsStore: Instance<typeof TranslationsStore>;
  border: BorderStyle;
  borderRadius: CornerRadius;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  spacing: Spacing;
}

export const TranslationDataTabs = observer(
  ({
    translationsStore,
    border,
    borderRadius,
    typography,
    tokens,
    t,
    spacing,
  }: TranslationDataTabsProps): React.ReactElement => {
    const TranslationTabOrList = observer((): React.ReactElement => {
      if (translationsStore.isSingleLanguagePresent) {
        return (
          <TranslationDataList
            translationsStore={translationsStore}
            border={border}
            borderRadius={borderRadius}
            typography={typography}
            tokens={tokens}
            t={t}
            spacing={spacing}
          />
        );
      } else {
        return (
          <Stack gap={spacing.spaceXL}>
            <Tabs
              tabs={translationsStore.translationListTabs}
              onTabChange={(index: number) => {
                translationsStore.setSelectedLanguageBasedOnIndex(index);
              }}
              selectedTabIndex={
                translationsStore.selectedLanguage?.tabIndex ?? 0
              }
            />
            <TranslationDataList
              translationsStore={translationsStore}
              border={border}
              borderRadius={borderRadius}
              typography={typography}
              tokens={tokens}
              t={t}
              spacing={spacing}
            />
          </Stack>
        );
      }
    });

    return (
      <Stack margin={`${spacing.spaceXL} 0px`}>
        <TranslationTabOrList />
      </Stack>
    );
  },
);
