import {
  BackOfficeSurveySettings,
  SurveySettings,
  ThemedImage,
  SurveyLanguage,
  SurveyLanguageCode,
  SurveyLocalizedText,
  SurveyName,
  SurveyTheme,
  SurveySettingDialogProperties,
  MiscellaneousSettingsText,
} from "@pulse/pulse-rpcs";
import { SurveySettingsEnums } from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";

/*
 * This is used to mock the BackOfficeSurveySettings object in the responses of CloseSurvey, ReOpenSurvey and GetSurveySettings RPCs.
 * @example
    new BackOfficeSurveySettings(
        new SurveySettings(
          new ThemedImage(
            new URL(
              "https://cdn.pixabay.com/photo/2016/12/13/22/39/motorcycle-1905281_1280.jpg",
            ),
            new URL(
              "https://cdn.pixabay.com/photo/2022/12/10/21/34/volkswagen-7647805_640.jpg",
            ),
          ),
          [
            new SurveyLanguage(
              new LeoUUID("c17d7aa0-8e2b-4a1f-a29d-0819bc6f345d"),
              new SurveyLanguageCode("En"),
            ),
          ],
          new SurveyLanguageCode("En"),
          "Montserrat",
          SurveySettingsEnums.FontSizeScale.FontSizeScale.DEFAULT_SCALE,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          new SurveyLocalizedText("Start"),
          new SurveyLocalizedText(
            "Something went wrong. Please refresh the page, to try again.",
          ),
          new SurveyLocalizedText("Continue"),
          new SurveyLocalizedText("Next"),
          new SurveyLocalizedText("Back"),
        ),
        new SurveyName("Employee Satisfaction"),
        [
          new ThemeWithHexCodes(
            SurveyTheme.SurveyTheme.LILAC_SERENADE,
            new HexCode("#6F3E7C"),
          ),
          new ThemeWithHexCodes(
            SurveyTheme.SurveyTheme.SUMMER_SUNSET,
            new HexCode("#E59809"),
          ),
          new ThemeWithHexCodes(
            SurveyTheme.SurveyTheme.WINTER_MIDNIGHT,
            new HexCode("#0653A0"),
          ),
          new ThemeWithHexCodes(
            SurveyTheme.SurveyTheme.AUTUMN_HARVEST,
            new HexCode("#39AD4E"),
          ),
          new ThemeWithHexCodes(
            SurveyTheme.SurveyTheme.SPRING_BLOSSOM,
            new HexCode("#D2691E"),
          ),
          new ThemeWithHexCodes(
            SurveyTheme.SurveyTheme.CRIMSON_FALL,
            new HexCode("#DC143C"),
          ),
          new ThemeWithHexCodes(
            SurveyTheme.SurveyTheme.ARCTIC_MONOCHROME,
            new HexCode("#3E3E3E"),
          ),
        ],
        SurveyTheme.SurveyTheme.ARCTIC_MONOCHROME,
        ["Raleway", "Inter", "Lato", "Roboto", "Montserrat", "Lora"],
        true,
        new SurveySettingDialogProperties(
          new SurveyLocalizedText("Submit Response?"),
          new SurveyLocalizedText(
            "Press 'Submit' button to submit your response.",
          ),
          new SurveyLocalizedText("Submit"),
          new SurveyLocalizedText("Cancel"),
          new SurveyLocalizedText("Submit"),
        ),
        false,
        new SurveySettingDialogProperties(
          new SurveyLocalizedText("Reset Response?"),
          new SurveyLocalizedText(
            "Press 'Reset' button to reset all filled responses. it will navigate you to the first question.",
          ),
          new SurveyLocalizedText("Reset"),
          new SurveyLocalizedText("Cancel"),
          new SurveyLocalizedText("Restart"),
        ),
        true,
        new SurveySettingDialogProperties(
          new SurveyLocalizedText("Saved your Responses Successfully"),
          new SurveyLocalizedText(
            "Your completed responses have been saved successfully, You can conveniently continue from where you left off at a later time.",
          ),
          new SurveyLocalizedText("Close"),
          undefined,
          new SurveyLocalizedText("Save Draft"),
        ),
        false,
        new SurveySettingDialogProperties(
          new SurveyLocalizedText("Info"),
          new SurveyLocalizedText(`- Unless otherwise specified, the ratings in this assessment are captured on a scale of **7 points**
        - Click on **BEGIN** to start the survey
        - Use the **BACK** button to move forward / **PROCEED** button to move back a page
        - The survey can be taken in multiple sessions. Data is saved automatically as you progress. You can also save the progress mid page
        - If a question is not applicable to you, please select the **Not Applicable** option. You may de-select this at anytime before completion
        - Finally, press the **SUBMIT** button at the bottom of the last page to submit your responses`),
          new SurveyLocalizedText("Close"),
        ),
        true,
        new SurveyLocalizedText(`#Welcome to ABC Corp's Annual XYZ Survey 20xx
        We wish to understand your expectations, and what best we can do to build a fruitful / win-win relationship in future.
        The survey is expected to take 5-6 minutes.
        - Unless otherwise specified, the ratings in this assessment are captured on a scale of **7 points**
        - Click on **BEGIN** to start the survey
        - Use the **BACK** button to move forward / **PROCEED** button to move back a page
        - The survey can be taken in multiple sessions. Data is saved automatically as you progress. You can also save the progress mid page
        - If a question is not applicable to you, please select the **Not Applicable** option. You may de-select this at anytime before completion
        - Finally, press the **SUBMIT** button at the bottom of the last page to submit your responses`),
        true,
        new SurveyLocalizedText(`#Thank You.
        You have successfully submitted the survey.`),
        new SurveyLocalizedText("Thank you!"),
        new SurveyLocalizedText(
          "Thank you for your interest in the survey, but the survey is closed now.",
        ),
        new LeoUUID(),
        new LeoUUID(),
      );
 */
export const MockBackOfficeSurveySettingsResponse =
  new BackOfficeSurveySettings(
    new SurveySettings(
      new ThemedImage(
        new URL(
          "https://cdn.pixabay.com/photo/2016/12/13/22/39/motorcycle-1905281_1280.jpg",
        ),
        new URL(
          "https://cdn.pixabay.com/photo/2022/12/10/21/34/volkswagen-7647805_640.jpg",
        ),
      ),
      [
        new SurveyLanguage(
          new LeoUUID("d5136d26-0fb0-4e55-968b-2a826ac4e532"),
          new SurveyLanguageCode("En"),
        ),
      ],
      new SurveyLanguageCode("En"),
      "Montserrat",
      SurveySettingsEnums.FontSizeScale.FontSizeScale.DEFAULT_SCALE,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      new SurveyLocalizedText("Start"),
      new SurveyLocalizedText("Internal server error"),
      new SurveyLocalizedText("Continue"),
      new SurveyLocalizedText("Next"),
      new SurveyLocalizedText("Previous"),
      new SurveyLocalizedText("Back"),
      new SurveySettingDialogProperties(
        new SurveyLocalizedText("Survey Already Submitted"),
        new SurveyLocalizedText(
          "Thank you for your interest in the survey, but this survey has already been submitted and the data is safe and secure.",
        ),
        new SurveyLocalizedText("Close"),
      ),
      new SurveySettingDialogProperties(
        new SurveyLocalizedText("Select Your Preferred Language"),
        new SurveyLocalizedText(
          "You can change the language during the survey by clicking the language switcher located in the top right corner.",
        ),
        new SurveyLocalizedText("Select Language"),
      ),
      new MiscellaneousSettingsText(
        new SurveyLocalizedText("Choose an option"),
        new SurveyLocalizedText("Please Select One"),
        new SurveyLocalizedText(
          "Please complete this question as it is marked mandatory. Your input is valuable to us!",
        ),
        new SurveyLocalizedText(
          "Please complete at least 1 question from each row. Your input is valuable to us!",
        ),
        new SurveyLocalizedText(
          "Please complete all the questions in the row(s) attempted by you. Your input is valuable to us!",
        ),
        new SurveyLocalizedText(
          "Please rank all the options. Your input is valuable to us!",
        ),
        new SurveyLocalizedText("Tip: Drag the slider to a point on the scale"),
        new SurveyLocalizedText("Maximum options to be selected:"),
        new SurveyLocalizedText("Please rank all the options"),
        new SurveyLocalizedText("Required"),
      ),
      new SurveyLocalizedText("Reset"),
      true,
      true,
    ),
    new SurveyName("Employee Satisfaction"),
    SurveyTheme.SurveyTheme.ARCTIC_MONOCHROME,
    true,
    new SurveySettingDialogProperties(
      new SurveyLocalizedText("Submit Response?"),
      new SurveyLocalizedText("Press 'Submit' button to submit your response."),
      new SurveyLocalizedText("Submit"),
      new SurveyLocalizedText("Cancel"),
      new SurveyLocalizedText("Submit"),
    ),
    false,
    new SurveySettingDialogProperties(
      new SurveyLocalizedText("Reset Response?"),
      new SurveyLocalizedText(
        "Press 'Reset' button to reset all filled responses. it will navigate you to the first question.",
      ),
      new SurveyLocalizedText("Reset"),
      new SurveyLocalizedText("Cancel"),
      new SurveyLocalizedText("Restart"),
    ),
    true,
    new SurveySettingDialogProperties(
      new SurveyLocalizedText("Saved your Responses Successfully"),
      new SurveyLocalizedText(
        "Your completed responses have been saved successfully, You can conveniently continue from where you left off at a later time.",
      ),
      new SurveyLocalizedText("Close"),
      undefined,
      new SurveyLocalizedText("Save Draft"),
    ),
    false,
    new SurveySettingDialogProperties(
      new SurveyLocalizedText("Info"),
      new SurveyLocalizedText(`- Unless otherwise specified, the ratings in this assessment are captured on a scale of **7 points**
  - Click on **BEGIN** to start the survey
  - Use the **BACK** button to move forward / **PROCEED** button to move back a page
  - The survey can be taken in multiple sessions. Data is saved automatically as you progress. You can also save the progress mid page
  - If a question is not applicable to you, please select the **Not Applicable** option. You may de-select this at anytime before completion
  - Finally, press the **SUBMIT** button at the bottom of the last page to submit your responses`),
      new SurveyLocalizedText("Close"),
    ),
    true,
    new SurveyLocalizedText(`#Welcome to ABC Corp's Annual XYZ Survey 20xx
  We wish to understand your expectations, and what best we can do to build a fruitful / win-win relationship in future.
  
  The survey is expected to take 5-6 minutes.
  
  - Unless otherwise specified, the ratings in this assessment are captured on a scale of **7 points**
  - Click on **BEGIN** to start the survey
  - Use the **BACK** button to move forward / **PROCEED** button to move back a page
  - The survey can be taken in multiple sessions. Data is saved automatically as you progress. You can also save the progress mid page
  - If a question is not applicable to you, please select the **Not Applicable** option. You may de-select this at anytime before completion
  - Finally, press the **SUBMIT** button at the bottom of the last page to submit your responses`),
    new SurveyLocalizedText(`#Thank You.
  You have successfully submitted the survey.`),
    new SurveyLocalizedText("Thank you!"),
    new SurveyLocalizedText(
      "Thank you for your interest in the survey, but the survey is closed now.",
    ),
    new LeoUUID(),
    new LeoUUID(),
  );
