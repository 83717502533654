import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { DetailSectionComponent } from "../../../components/DetailSectionComponent";
import { getSurveyStatusChipProps } from "../../utils/getSurveyStatusChipProps";
import { QuestionnaireListPane } from "../components/QuestionnaireListPane";
import {
  BriefcaseIcon,
  ClipboardListIcon,
  EditIcon,
  HomeIcon,
  UploadIcon,
} from "lucide-react";
import { observer } from "mobx-react";
import {
  ICON_SIZE,
  getIconProps,
  getStringDefaultIfUndefined,
  useBorder,
} from "@pulse/shared-components";
import {
  processSurveyParams,
  useRouteNavigator,
} from "../../../routes/RoutesHelper";
import { SectionHeaderProps } from "../../../components/SectionHeader";
import {
  Button,
  LoadingIndicator,
  Plus,
  TableReloadHandle,
  useCornerRadius,
  useFoundationColorTokens,
  useShadow,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Stack } from "@mui/material";
import { useProjectDetailsStore, useSurveyStore } from "../../store/Hooks";
import { FeatureInDevelopmentSnackbar } from "@pulse/shared-components";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { navigateOrGetNetworkErrorPage } from "../../../utility/navigateOrGetNetworkErrorPage";
import { SurveyRulesUploadDialog } from "../components/SurveyRulesUploadDialog";
import { PreviewSurveyButton } from "../components/PreviewSurveyButton";
import { RespondentsPane } from "../../projects/components/RespondentsPane";
import { SurveySettingsPane } from "../components/surveySettings/SurveySettingsPane";
import { RespondentTitleActionElement } from "../../projects/components/RespondentTitleActionElement";
import { TranslationsPane } from "../components/translations/TranslationsPane";
import { getTranslationsSectionHeaderProps } from "../components/translations/TranslationsSectionHeader";
import { getDefaultProjectDetailsTab } from "../../projects/utils/ProjectDetailsTabUtils";

export enum HeaderTabElements {
  QUESTIONNAIRE,
  TRANSLATIONS,
  RESPONDENTS,
  SETTINGS,
}

export const SurveyDetailsPage = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigateTo = useRouteNavigator();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const border = useBorder();
  const borderRadius = useCornerRadius();
  const shadow = useShadow();
  const [isUploadRulesDialogVisible, setIsUploadRulesDialogVisible] =
    useState(false);
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const spacing = useSpacing();
  const surveySettingsPageRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<TableReloadHandle | null>(null);
  const boxShadow = useShadow();

  const surveyStore = useSurveyStore();
  const projectDetailsStore = useProjectDetailsStore();
  const errorStore = useNetworkingErrorStore();

  useEffect(() => {
    return () => {
      projectDetailsStore.surveyStore.resetTabIndex();
      errorStore.removeError();
    };
  }, []);

  useEffect(() => {
    processSurveyParams(surveyStore.callRpcUsingTabIndex, navigateTo);
  }, [surveyStore.tabIndex]);

  const errorPage = navigateOrGetNetworkErrorPage(
    errorStore.error,
    navigateTo,
    errorStore.removeError,
  );

  if (errorPage) {
    errorStore.removeError();
    return errorPage;
  }

  const TabElement = observer((): React.ReactElement => {
    switch (surveyStore.tabIndex) {
      case HeaderTabElements.QUESTIONNAIRE:
        return (
          <QuestionnaireListPane
            surveyStore={projectDetailsStore.surveyStore}
            spacing={spacing}
            border={border}
            borderRadius={borderRadius}
            t={t}
            navigateTo={navigateTo}
            shadow={shadow}
          />
        );
      case HeaderTabElements.TRANSLATIONS: {
        return (
          <TranslationsPane
            translationStore={surveyStore.translationsStore}
            t={t}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
            navigateTo={navigateTo}
            border={border}
            borderRadius={borderRadius}
          />
        );
      }
      case HeaderTabElements.RESPONDENTS:
        return (
          <RespondentsPane
            isSurveyFilterShown={false}
            respondentStore={projectDetailsStore.respondentStore}
            surveyFilterName={surveyStore.surveyName}
            spacing={spacing}
            t={t}
            tokens={tokens}
            typography={typography}
            tableRef={tableRef}
          />
        );
      case HeaderTabElements.SETTINGS:
        return (
          <SurveySettingsPane
            surveyStore={surveyStore}
            spacing={spacing}
            t={t}
            tokens={tokens}
            typography={typography}
            border={border}
            borderRadius={borderRadius}
            navigateTo={navigateTo}
            surveySettingsPageRef={surveySettingsPageRef}
          />
        );
      default:
        return <></>;
    }
  });

  const getQuestionnaireListSectionHeaderProps = (): SectionHeaderProps => {
    const getQuestionnaireButtons = (): React.ReactElement => {
      if (surveyStore.isSurveyClosed) {
        return (
          <PreviewSurveyButton
            isAddQuestionPage={false}
            t={t}
            questionDetailsStore={surveyStore.questionDetailsStore}
            isButtonDisabled={false}
            navigateTo={navigateTo}
          />
        );
      } else if (surveyStore.isQuestionListEmpty) {
        return (
          <Button
            name="createQuestionnaire"
            label={t("surveys.questionnaireList.createQuestionnaire")}
            icon={<Plus />}
            iconPosition="leading"
            variant="filled"
            size="medium"
            onClick={(): void => {
              processSurveyParams(async (surveyId, projectId) => {
                navigateTo.surveyAddQuestion(projectId, surveyId);
              }, navigateTo);
            }}
          />
        );
      } else {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              name="uploadRules"
              label={t("surveys.questionnaireList.uploadRules")}
              icon={<UploadIcon />}
              iconPosition="leading"
              variant="outlined-neutral"
              size="medium"
              onClick={(): void => {
                setIsUploadRulesDialogVisible(true);
              }}
            />
            <PreviewSurveyButton
              isAddQuestionPage={false}
              t={t}
              questionDetailsStore={surveyStore.questionDetailsStore}
              isButtonDisabled={false}
              navigateTo={navigateTo}
            />
            <Button
              name="editQuestionnaire"
              label={t("surveys.questionnaireList.editQuestionnaire")}
              icon={<EditIcon />}
              iconPosition="leading"
              variant="filled"
              size="medium"
              onClick={(): void => {
                processSurveyParams(async (surveyId, projectId) => {
                  navigateTo.surveyAddQuestion(projectId, surveyId);
                }, navigateTo);
              }}
            />
          </Stack>
        );
      }
    };

    return {
      title: t("surveys.questionnaire"),
      actionElement: !surveyStore.isRPCLoading ? (
        getQuestionnaireButtons()
      ) : (
        <></>
      ),
      prefersLargeTitle: true,
    };
  };

  const getSettingsSectionHeaderProps = (): SectionHeaderProps => {
    const GetSurveySettingsTabActionButton = observer(
      (): React.ReactElement => {
        return (
          <Button
            label={t("common.saveChangesButtonText")}
            name="saveChanges"
            size="medium"
            variant="filled"
            disabled={
              surveyStore.backOfficeSurveySettingsStore
                .isSaveChangesSettingsButtonDisabled
            }
            onClick={() => {
              surveyStore.backOfficeSurveySettingsStore.updatedBackOfficeSurveySettings?.backOfficeSurveySettingsUIStore.setIsSaveButtonClicked(
                true,
              );
              if (
                surveyStore.backOfficeSurveySettingsStore.validateSurveyName() &&
                surveyStore.backOfficeSurveySettingsStore.updatedBackOfficeSurveySettings?.backOfficeSurveySettingsUIStore.areSurveySettingsValid()
              ) {
                processSurveyParams(
                  surveyStore.backOfficeSurveySettingsStore
                    .updateSurveySettings,
                  navigateTo,
                );
              }
            }}
          />
        );
      },
    );
    return {
      title: t("surveys.settings.settingsTabTitleText"),
      actionElement: <GetSurveySettingsTabActionButton />,
      prefersLargeTitle: true,
    };
  };

  return (
    <>
      <LoadingIndicator isLoading={surveyStore.isRPCLoading} />
      <DetailSectionComponent
        isManageUsersLogoVisible={true}
        isAuditLogsLogoVisible={true}
        mainHeaderProps={{
          title: getStringDefaultIfUndefined(surveyStore.surveyName),
          icon: (
            <ClipboardListIcon
              {...getIconProps(tokens.iconSubtle, ICON_SIZE.default)}
            />
          ),
          prefersLargeTitle: true,
          chipProps:
            surveyStore.surveyStatus &&
            getSurveyStatusChipProps(surveyStore.surveyStatus, t),
          generateGenericSurveyLinkProps: {
            surveyStore,
            navigateTo,
            t,
            spacing,
            tokens,
            typography,
          },
        }}
        tabsProps={{
          tabs: [
            { label: t("surveys.questionnaire") },
            { label: t("surveys.translations.translationsTitleText") },
            { label: t("surveys.respondents") },
            { label: t("surveys.settings.settingsTabTitleText") },
          ],
          selectedTabIndex: surveyStore.tabIndex,
          onTabChange: (index: number) => {
            surveyStore.setTabIndex(index);
          },
          disableBottomBorder: true,
        }}
        breadcrumbProps={{
          links: [
            {
              label: t("projects.projects"),
              icon: <HomeIcon />,
              onLabelClick: navigateTo.project,
            },
            {
              label: projectDetailsStore.projectName,
              icon: <BriefcaseIcon />,
              onLabelClick: () =>
                surveyStore.projectId &&
                navigateTo.projectDetails(
                  surveyStore.projectId,
                  getDefaultProjectDetailsTab(t, navigateTo),
                ),
            },
          ],
          currentLabel: surveyStore.surveyName,
          currentLabelIcon: <ClipboardListIcon />,
        }}
        isChildHeightVariable={false}
        isOverflowHidden={false}
        {...(surveyStore.isQuestionnaireTabIndexSelected && {
          sectionHeaderProps: getQuestionnaireListSectionHeaderProps(),
        })}
        {...(surveyStore.isRespondentTabIndexSelected && {
          sectionHeaderProps: {
            title: t("surveys.respondents"),
            prefersLargeTitle: true,
            titleActionElement: (
              <RespondentTitleActionElement
                t={t}
                border={border}
                boxShadow={boxShadow}
                tableRef={tableRef}
                borderRadius={borderRadius}
                tokens={tokens}
                respondentStore={projectDetailsStore.respondentStore}
                typography={typography}
                spacing={spacing}
              />
            ),
          },
        })}
        {...(surveyStore.isSurveySettingsTabIndexSelected && {
          sectionHeaderProps: getSettingsSectionHeaderProps(),
          isOverflowHidden: true,
        })}
        {...(surveyStore.isTranslationsTabIndexSelected && {
          sectionHeaderProps: getTranslationsSectionHeaderProps({
            spacing,
            navigateTo,
            surveyStore,
            t,
            tokens,
            typography,
          }),
          isOverflowHidden: true,
        })}
      >
        <TabElement />
        {isUploadRulesDialogVisible && (
          <SurveyRulesUploadDialog
            isUploadRulesDialogVisible={isUploadRulesDialogVisible}
            setIsUploadRulesDialogVisible={setIsUploadRulesDialogVisible}
            t={t}
            navigateTo={navigateTo}
            errorStore={errorStore}
            projectDetailsStore={projectDetailsStore}
            tokens={tokens}
            spacing={spacing}
            typography={typography}
          />
        )}
        <FeatureInDevelopmentSnackbar
          isSnackbarVisible={isSnackbarVisible}
          setIsSnackbarVisible={setIsSnackbarVisible}
        />
      </DetailSectionComponent>
    </>
  );
});
