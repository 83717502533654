import React from "react";
import { Stack, Typography } from "@mui/material";
import {
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTypography } from "@surya-digital/leo-reactjs-material-ui";
import { RenderActionElements } from "./Header";
import {
  ZERO_VALUE,
  getEllipsizedTextProperties,
  useBorder,
} from "@pulse/shared-components";

export interface SectionHeaderProps {
  title: string;
  prefersLargeTitle?: Boolean;
  actionElement?: React.ReactElement;
  tabElement?: React.ReactElement;
  padding?: string;
  color?: string;
  titleActionElement?: React.ReactElement;
}

/**
 * Creates a Section Header for the Pulse project.
 * @param title - Title of the header.
 * @param prefersLargeTitle - If true SubHeading1 is used as the typography for title, else SubHeading3 is used.
 * @param actionElement - React element to be rendered on the right side of the component.
 * @param padding - This optional prop is used to add customised padding if required.
 * @param titleActionElement - React element to be rendered to the immediate right of the title.
 * @returns - Header component
 * @example
 *      <Header
          title="Tata Consultants New 2023"
          prefersLargeTitle={true}
          actionElement={
            <Button
              name="primaryButton"
              variant="filled"
              size="medium"
              title="Button Label"
              onClick={(): void => {}}
            />
          }
          titleActionElement=null
        />
 */
export function SectionHeader({
  title,
  prefersLargeTitle,
  actionElement,
  tabElement,
  padding,
  color,
  titleActionElement,
}: SectionHeaderProps): React.ReactElement {
  const typography = useTypography();
  const tokens = useFoundationColorTokens();
  const spacing = useSpacing();
  const border = useBorder();

  const getPadding = (): string => {
    if (padding) {
      return padding;
    } else if (prefersLargeTitle) {
      return `${spacing.spaceLG} ${spacing.space2XL}`;
    } else {
      return `${spacing.spaceMD} ${spacing.spaceXL}`;
    }
  };

  const getPaddingBottom = (): string => {
    if (tabElement !== undefined) {
      return `${ZERO_VALUE}`;
    } else if (prefersLargeTitle) {
      return spacing.spaceLG;
    } else {
      return spacing.spaceMD;
    }
  };

  return (
    <Stack
      gap={spacing.spaceSM}
      width="100%"
      minHeight="48px"
      borderBottom={border.default}
      padding={getPadding()}
      paddingBottom={getPaddingBottom()}
      sx={{
        backgroundColor: tokens.backgroundElevatedLevel1,
      }}
    >
      <Stack
        direction="row"
        spacing={spacing.spaceSM}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={spacing.spaceSM} alignItems="center">
          <Typography
            sx={{
              ...(prefersLargeTitle ? typography.sh1 : typography.sh3),
              ...getEllipsizedTextProperties(2),
              color: color ? color : tokens.label,
              wordBreak: "break-word",
            }}
          >
            {title}
          </Typography>
          {titleActionElement}
        </Stack>
        {actionElement && RenderActionElements(actionElement)}
      </Stack>
      {tabElement && <Stack>{tabElement}</Stack>}
    </Stack>
  );
}
