import React from "react";
import { SectionHeaderProps } from "../../../../components/SectionHeader";
import {
  Button,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { UploadIcon } from "lucide-react";
import { TFunction } from "i18next";
import { TranslationsUploadDialog } from "./TranslationsUploadDialog";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { Instance } from "mobx-state-tree";
import { SurveyStore } from "../../store/SurveyStore";
import { observer } from "mobx-react";

interface TranslationsTabActionButtonProps {
  t: TFunction;
  navigateTo: NavigateToFunctions;
  surveyStore: Instance<typeof SurveyStore>;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
}

const TranslationsTabActionButton = observer(
  ({
    t,
    navigateTo,
    surveyStore,
    typography,
    tokens,
    spacing,
  }: TranslationsTabActionButtonProps): React.ReactElement => {
    const translationUploadStore =
      surveyStore.translationsStore.translationUploadStore;
    return (
      <>
        {translationUploadStore.isTranslationUploadDialogVisible && (
          <TranslationsUploadDialog
            isUploadTranslationsDialogVisible={
              translationUploadStore.isTranslationUploadDialogVisible
            }
            setIsUploadTranslationsDialogVisible={
              translationUploadStore.setIsTranslationDialogVisible
            }
            t={t}
            navigateTo={navigateTo}
            surveyStore={surveyStore}
            tokens={tokens}
            spacing={spacing}
            typography={typography}
          />
        )}
        {surveyStore.translationsStore.isUploadTranslationButtonVisible && (
          <Button
            label={t("surveys.translations.uploadTranslationsText")}
            name="uploadTranslations"
            size="medium"
            variant="filled"
            iconPosition="leading"
            icon={<UploadIcon />}
            disabled={false}
            onClick={() => {
              translationUploadStore.setIsTranslationDialogVisible(true);
            }}
          />
        )}
      </>
    );
  },
);

export const getTranslationsSectionHeaderProps = ({
  t,
  navigateTo,
  surveyStore,
  typography,
  tokens,
  spacing,
}: TranslationsTabActionButtonProps): SectionHeaderProps => {
  return {
    title: t("surveys.translations.translationsTitleText"),
    actionElement: (
      <TranslationsTabActionButton
        spacing={spacing}
        tokens={tokens}
        typography={typography}
        navigateTo={navigateTo}
        surveyStore={surveyStore}
        t={t}
      />
    ),
    prefersLargeTitle: true,
  };
};
