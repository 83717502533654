import { Instance } from "mobx-state-tree";
import { QuestionDetailsStore } from "./QuestionDetailsStore";
import { OpenEndedFieldV1Store } from "./OpenEndedFieldV1Store";
import { SingleChoiceQuestionV1Store } from "./SingleChoiceQuestionV1Store";
import { MultiChoiceQuestionV1Store } from "./MultiChoiceQuestionV1Store";
import { SurveyRulesUploadStore } from "./SurveyRulesUploadStore";
import { RankingQuestionV1Store } from "./RankingQuestionV1Store";
import { GridQuestionV1Store } from "./GridQuestionV1Store";
import { useSurveyStore } from "../../store/Hooks";

export const useQuestionDetailsStore = (): Instance<
  typeof QuestionDetailsStore
> => {
  return useSurveyStore().questionDetailsStore;
};

export const useOpenEndedFieldStore = (): Instance<
  typeof OpenEndedFieldV1Store
> => {
  return useQuestionDetailsStore().updatedQuestionDetails.openEndedFieldV1Store;
};

export const useSingleChoiceQuestionV1Store = (): Instance<
  typeof SingleChoiceQuestionV1Store
> => {
  return useQuestionDetailsStore().updatedQuestionDetails
    .singleChoiceQuestionV1Store;
};

export const useMultiChoiceQuestionV1Store = (): Instance<
  typeof MultiChoiceQuestionV1Store
> => {
  return useQuestionDetailsStore().updatedQuestionDetails
    .multiChoiceQuestionV1Store;
};

export const useSurveyRulesUploadStore = (): Instance<
  typeof SurveyRulesUploadStore
> => {
  return useSurveyStore().surveyRulesUploadStore;
};

export const useRankingQuestionV1Store = (): Instance<
  typeof RankingQuestionV1Store
> => {
  return useQuestionDetailsStore().updatedQuestionDetails
    .rankingQuestionV1Store;
};

export const useGridQuestionV1Store = (): Instance<
  typeof GridQuestionV1Store
> => {
  return useQuestionDetailsStore().updatedQuestionDetails.gridQuestionV1Store;
};
