import {
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  RadioProps,
  Radio,
} from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { LinkType, ReportStore, ReportType } from "../../store/ReportStore";
import {
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import {
  getCamelCaseFromLinkType,
  getCamelCaseFromReportType,
} from "../../utils/StringUtils";

interface DownloadReportsPreferencesProps {
  reportStore: Instance<typeof ReportStore>;
  typography: LeoTypography;
  spacing: Spacing;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  radioProps: RadioProps;
}

export const DownloadReportsPreferences = observer(
  ({
    reportStore,
    typography,
    spacing,
    t,
    tokens,
    radioProps,
  }: DownloadReportsPreferencesProps): React.ReactElement => {
    return (
      <Stack gap={spacing.spaceMD}>
        <Stack gap={spacing.spaceXXS}>
          <Typography
            sx={{ ...typography.s1, color: tokens.label, width: "520px" }}
          >
            {t("projects.reports.downloadPreferencesText")}
          </Typography>
          <Typography
            sx={{ ...typography.b2, color: tokens.labelSubtle, width: "520px" }}
          >
            {t("projects.reports.downloadPreferencesDescriptionText")}
          </Typography>
        </Stack>
        <Stack direction="row" gap={spacing.spaceXL}>
          <Stack gap={spacing.spaceSM}>
            <Typography
              sx={{ ...typography.b2, color: tokens.label, width: "200px" }}
            >
              {t("projects.reports.linkTypeText")}
            </Typography>
            <RadioGroup
              value={reportStore.selectedLinkType}
              onChange={(_, value) => {
                reportStore.setSelectedLinkType(
                  LinkType[value as keyof typeof LinkType],
                );
              }}
              sx={{
                gap: spacing.spaceSM,
              }}
            >
              {Object.keys(LinkType).map((linkType) => {
                return (
                  <FormControlLabel
                    key={linkType}
                    control={
                      <Radio
                        disabled={reportStore.isRPCLoading}
                        {...radioProps}
                      />
                    }
                    label={t(
                      `projects.reports.downloadPreferencesEnumOptions.${getCamelCaseFromLinkType(
                        LinkType[linkType as keyof typeof LinkType],
                      )}`,
                    )}
                    value={linkType}
                  />
                );
              })}
            </RadioGroup>
          </Stack>
          <Stack gap={spacing.spaceSM}>
            <Typography
              sx={{ ...typography.b2, color: tokens.label, width: "200px" }}
            >
              {t("projects.reports.reportTypeText")}
            </Typography>
            <RadioGroup
              value={reportStore.selectedReportType}
              onChange={(_, value) => {
                reportStore.setSelectedReportType(
                  ReportType[value as keyof typeof ReportType],
                );
              }}
              sx={{
                gap: spacing.spaceSM,
              }}
            >
              {Object.keys(ReportType).map((reportType) => {
                return (
                  <FormControlLabel
                    key={reportType}
                    control={
                      <Radio
                        disabled={reportStore.isRPCLoading}
                        {...radioProps}
                      />
                    }
                    value={reportType}
                    label={t(
                      `projects.reports.downloadPreferencesEnumOptions.${getCamelCaseFromReportType(
                        ReportType[reportType as keyof typeof ReportType],
                      )}`,
                    )}
                  />
                );
              })}
            </RadioGroup>
          </Stack>
        </Stack>
      </Stack>
    );
  },
);
