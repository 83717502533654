import { Stack } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import {
  FoundationColorTokens,
  RadioButtonColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import { GridColumnQuestionV1Model } from "../../models/GridColumnQuestionV1Model";
import { BorderStyle } from "@pulse/shared-components";
import { TFunction } from "i18next";
import { GridSingleSelectChoiceOptions } from "./GridSingleSelectChoiceOptions";
import { SingleSelectPresetPane } from "../singleSelectOptionComponents/SingleSelectPresetPane";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";

interface GridSingleChoiceOptionsPaneProps {
  setIsChoicePresetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tokens: FoundationColorTokens<string>;
  radioButtonTokens: RadioButtonColorTokens<string>;
  gridColumnQuestionV1Model: Instance<typeof GridColumnQuestionV1Model>;
  typography: LeoTypography;
  spacing: Spacing;
  border: BorderStyle;
  t: TFunction;
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
}

export const GridSingleChoiceOptionsPane = observer(
  ({
    setIsChoicePresetDialogOpen,
    t,
    spacing,
    gridColumnQuestionV1Model,
    typography,
    border,
    tokens,
    radioButtonTokens,
    questionDetailsStore,
  }: GridSingleChoiceOptionsPaneProps): React.ReactElement => {
    return (
      <Stack gap={spacing.spaceLG}>
        <SingleSelectPresetPane
          spacing={spacing}
          border={border}
          t={t}
          typography={typography}
          setIsChoicePresetDialogOpen={setIsChoicePresetDialogOpen}
          isChoosePresetButtonDisabled={
            gridColumnQuestionV1Model.singleChoice
              .gridSingleSelectQuestionV1Model.isNPS
          }
        />
        <Stack spacing={spacing.spaceSM}>
          {gridColumnQuestionV1Model.singleChoice.gridSingleSelectQuestionV1Model.options.map(
            (_field, index) => {
              return (
                <GridSingleSelectChoiceOptions
                  key={index}
                  index={index}
                  tokens={tokens}
                  gridColumnQuestionV1Model={gridColumnQuestionV1Model}
                  questionDetailsStore={questionDetailsStore}
                  t={t}
                  radioButtonTokens={radioButtonTokens}
                  spacing={spacing}
                />
              );
            },
          )}
        </Stack>
      </Stack>
    );
  },
);
