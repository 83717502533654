import { Instance, cast, flow, getRoot, types } from "mobx-state-tree";
import {
  TranslationUploadStore,
  createTranslationUploadStore,
} from "./TranslationUploadStore";
import {
  CommonErrors,
  SurveyLanguageModel,
  createSurveyLanguageModel,
} from "@pulse/shared-components";
import {
  SurveyTranslationTextModel,
  createSurveyTranslationTextModel,
} from "../models/SurveyTranslationTextModel";
import { RootStore } from "../../root/store/RootStore";
import { getAPIClient } from "../../networking/APIClient";
import {
  GetAllTranslationsRPC,
  SurveyLanguage,
  SurveyLanguageCode,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useGetAllTranslationsRPCClientImpl } from "../rpcs/RPC";
import {
  SurveyLanguageListWithTabIndexModel,
  createSurveyLanguageListWithTabIndexModel,
} from "../models/SurveyLanguageListWithTabIndexModel";
import { TabProps } from "@surya-digital/leo-reactjs-material-ui";

export enum TranslationType {
  FirstTranslation = "FIRST_TRANSLATION",
  SpecificTranslation = "SPECIFIC_TRANSLATION",
}

export enum TranslationUpdateStatus {
  InProgress = "IN_PROGRESS",
  NoTranslationsFound = "NO_TRANSLATIONS_FOUND",
  TranslationData = "TRANSLATION_DATA",
  IsLoading = "IS_LOADING",
  IsError = "IS_ERROR",
}

export enum GetAllTranslationsError {
  InvalidLanguageId = "INVALID_LANGUAGE_ID",
  BaseTranslationNotFound = "BASE_TRANSLATION_NOT_FOUND",
}

export const TranslationsStore = types
  .model("TranslationsStore", {
    translationUploadStore: TranslationUploadStore,
    translationType: types.optional(
      types.enumeration("TranslationType", Object.values(TranslationType)),
      TranslationType.FirstTranslation,
    ),
    translationUpdateStatus: types.optional(
      types.enumeration(
        "TranslationUpdateStatus",
        Object.values(TranslationUpdateStatus),
      ),
      TranslationUpdateStatus.IsLoading,
    ),
    surveyLanguageList: types.array(SurveyLanguageListWithTabIndexModel),
    baseLanguage: types.maybeNull(SurveyLanguageModel),
    translatedLanguage: types.maybeNull(SurveyLanguageModel),
    selectedTranslationText: types.array(SurveyTranslationTextModel),
    rpcErrors: types.maybeNull(
      types.enumeration(
        "GetAllTranslationsError",
        Object.values(GetAllTranslationsError),
      ),
    ),
    selectedLanguage: types.maybeNull(SurveyLanguageListWithTabIndexModel),
  })
  .views((store) => ({
    get isSingleLanguagePresent(): boolean {
      return store.surveyLanguageList.length === 1;
    },
    get requestTranslationType():
      | GetAllTranslationsRPC.RequestEnums.TranslationType.TranslationType
      | undefined {
      switch (store.translationType) {
        case TranslationType.FirstTranslation: {
          return new GetAllTranslationsRPC.RequestEnums.TranslationType.FirstTranslation();
        }
        case TranslationType.SpecificTranslation: {
          if (store.selectedLanguage === null) {
            console.error("The selected language cannot be null.");
            return;
          }
          return new GetAllTranslationsRPC.RequestEnums.TranslationType.SpecificTranslation(
            new LeoUUID(store.selectedLanguage.surveyLanguage.languageId),
          );
        }
      }
    },
    get isRPCLoading(): boolean {
      return (
        store.translationUpdateStatus === TranslationUpdateStatus.IsLoading
      );
    },
    isLastTranslationElement(index: number): boolean {
      return store.selectedTranslationText.length - 1 === index;
    },
    get translationListTabs(): TabProps[] {
      return store.surveyLanguageList.map((surveyLanguage) => {
        return {
          label: surveyLanguage.surveyLanguage.languageCode,
        };
      });
    },
    get isUploadTranslationButtonVisible(): boolean {
      return store.translationUpdateStatus !== TranslationUpdateStatus.IsError;
    },
  }))
  .actions((store) => ({
    resetStore: (): void => {
      store.rpcErrors = null;
      store.translationType = TranslationType.FirstTranslation;
      store.translationUpdateStatus = TranslationUpdateStatus.IsLoading;
      store.selectedLanguage = null;
      store.selectedTranslationText.clear();
      store.baseLanguage = null;
      store.translatedLanguage = null;
      store.surveyLanguageList.clear();
      store.translationUploadStore = createTranslationUploadStore();
    },
    setSelectedLanguageBasedOnIndex: (index: number): void => {
      store.translationType = TranslationType.SpecificTranslation;
      const selectedLanguage = store.surveyLanguageList.find(
        (surveyLanguage) => surveyLanguage.tabIndex === index,
      );
      if (selectedLanguage === undefined) {
        console.error("The selected language was not found.");
        return;
      } else {
        store.selectedLanguage = createSurveyLanguageListWithTabIndexModel(
          selectedLanguage.tabIndex,
          new SurveyLanguage(
            new LeoUUID(selectedLanguage.surveyLanguage.languageId),
            new SurveyLanguageCode(
              selectedLanguage.surveyLanguage.languageCode,
            ),
          ),
        );
      }
    },
    getAllTranslations: flow(function* (surveyId: string, projectId: string) {
      store.translationUpdateStatus = TranslationUpdateStatus.IsLoading;
      try {
        const apiClient = getAPIClient(store);
        const requestTranslationType = store.requestTranslationType;
        if (requestTranslationType === undefined) {
          console.error("TranslationType was found to be undefined.");
          store.translationUpdateStatus = TranslationUpdateStatus.IsError;
          return;
        }
        const request = new GetAllTranslationsRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
          requestTranslationType,
        );

        const {
          response,
          error,
        }: {
          response?: GetAllTranslationsRPC.Response;
          error?: GetAllTranslationsRPC.Errors.Errors;
        } =
          yield useGetAllTranslationsRPCClientImpl(apiClient).execute(request);

        if (response) {
          const translationUpdateStatus = response.translationUpdateStatus;
          if (
            translationUpdateStatus instanceof
            GetAllTranslationsRPC.ResponseEnums.TranslationUpdateStatus
              .InProgress
          ) {
            store.translationUpdateStatus = TranslationUpdateStatus.InProgress;
          } else if (
            translationUpdateStatus instanceof
            GetAllTranslationsRPC.ResponseEnums.TranslationUpdateStatus
              .NoTranslationsFound
          ) {
            store.translationUpdateStatus =
              TranslationUpdateStatus.NoTranslationsFound;
          } else if (
            translationUpdateStatus instanceof
            GetAllTranslationsRPC.ResponseEnums.TranslationUpdateStatus
              .TranslationData
          ) {
            store.translationUpdateStatus =
              TranslationUpdateStatus.TranslationData;
            store.baseLanguage = createSurveyLanguageModel(
              translationUpdateStatus.baseLanguage,
            );
            store.translatedLanguage = createSurveyLanguageModel(
              translationUpdateStatus.translatedLanguage,
            );
            store.surveyLanguageList = cast(
              translationUpdateStatus.surveyLanguageList.map(
                (surveyLanguage, index) => {
                  return createSurveyLanguageListWithTabIndexModel(
                    index,
                    surveyLanguage,
                  );
                },
              ),
            );
            store.selectedTranslationText = cast(
              translationUpdateStatus.selectedTranslationTexts.map(
                (translationText) => {
                  return createSurveyTranslationTextModel(translationText);
                },
              ),
            );
          }
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidSurveyId:
            case CommonErrors.InvalidProjectId: {
              break;
            }
            case GetAllTranslationsError.BaseTranslationNotFound: {
              store.translationUpdateStatus = TranslationUpdateStatus.IsError;
              store.rpcErrors = GetAllTranslationsError.BaseTranslationNotFound;
              break;
            }
            case GetAllTranslationsError.InvalidLanguageId: {
              store.translationUpdateStatus = TranslationUpdateStatus.IsError;
              store.rpcErrors = GetAllTranslationsError.InvalidLanguageId;
              break;
            }
            default: {
              store.translationUpdateStatus = TranslationUpdateStatus.IsError;
              console.error(`Unexpected error occured: ${error}`);
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          store.translationUpdateStatus = TranslationUpdateStatus.IsError;
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
  }));

export const createTranslationsStore = (): Instance<
  typeof TranslationsStore
> => {
  return TranslationsStore.create({
    translationUploadStore: createTranslationUploadStore(),
  });
};
