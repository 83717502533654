import React from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { AddQuestionsPage } from "../modules/surveys/pages/AddQuestionsPage";
import { ProjectDetailsPage } from "../modules/projects/pages/ProjectDetailsPage";
import { RootPage } from "../modules/root/pages/RootPage";
import { SurveyDetailsPage } from "../modules/surveys/pages/SurveyDetailsPage";
import { ProjectsPage } from "../modules/projects/pages/ProjectsPage";
import { Route, useRouteNavigator } from "./RoutesHelper";
import { useTranslation } from "react-i18next";
import {
  ErrorLayout,
  ErrorPage,
  InternalServerErrorLightIllustration,
  NetworkErrorLightIllustration,
  PageNotFoundLightIllustration,
} from "@pulse/shared-components";
import { SignInPage } from "../modules/auth/pages/SignInPage";
import {
  ButtonProps,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { SPoCRespondent } from "../modules/projects/pages/SPoCRespondent";
import { ManageUsers } from "../modules/manageUsers/pages/ManageUsers";
import { AuditLogsPage } from "../modules/auditLogs/pages/AuditLogsPage";
import { ProfileSettingsPage } from "../modules/profileSettings/pages/ProfileSettingsPage";

const projectRoutes = (): RouteObject[] => {
  return [
    {
      path: Route.Project,
      element: <ProjectsPage />,
    },
    {
      path: Route.ProjectDetails,
      element: <ProjectDetailsPage />,
    },
  ];
};

const manageUsersRoutes = (): RouteObject[] => {
  return [
    {
      path: Route.ManageUsers,
      element: <ManageUsers />,
    },
  ];
};

const auditLogRoute = (): RouteObject => {
  return {
    path: Route.AuditLogs,
    element: <AuditLogsPage />,
  };
};

const profileSettingsRoute = (): RouteObject => {
  return {
    path: Route.ProfileSettings,
    element: <ProfileSettingsPage />,
  };
};

const spocRespondentRoutes = (): RouteObject[] => {
  return [
    {
      path: Route.SPoCRespondent,
      element: <SPoCRespondent />,
    },
  ];
};

const surveyRoutes = (): RouteObject[] => {
  return [
    {
      path: Route.SurveyDetails,
      element: <SurveyDetailsPage />,
    },
    {
      path: Route.SurveyAddQuestion,
      element: <AddQuestionsPage />,
    },
  ];
};

const errorRoutes = (): RouteObject[] => {
  const navigateTo = useRouteNavigator();
  const { t } = useTranslation();
  const tokens = useFoundationColorTokens();

  const notFoundErrorPage = (
    <ErrorPage
      title={t("errors.pageNotFoundTitle")}
      description={t("errors.pageNotFoundDescription")}
      icon={<PageNotFoundLightIllustration themeTokens={tokens} />}
      buttonProps={
        {
          buttonLabel: t("common.goToHome"),
          onClick: (): void => {
            navigateTo.root();
          },
        } as ButtonProps
      }
    />
  );
  const pageNotFound: RouteObject = {
    path: Route.PageNotFound,
    element: notFoundErrorPage,
  };

  const networkErrorPage = (
    <ErrorPage
      title={t("errors.networkErrorTitle")}
      description={t("errors.networkErrorDescription")}
      icon={<NetworkErrorLightIllustration themeTokens={tokens} />}
      buttonProps={
        {
          buttonLabel: t("common.refresh"),
          onClick: (): void => {
            navigateTo.root();
          },
        } as ButtonProps
      }
    />
  );
  const networkFound: RouteObject = {
    path: Route.NetworkError,
    element: networkErrorPage,
  };

  const internalServerError: RouteObject = {
    path: Route.InternalServerError,
    element: (
      <ErrorPage
        title={t("errors.internalServerErrorTitle")}
        description={t("errors.internalServerErrorDescription")}
        icon={<InternalServerErrorLightIllustration themeTokens={tokens} />}
        buttonProps={
          {
            buttonLabel: t("common.goToHome"),
            onClick: (): void => {
              navigateTo.root();
            },
          } as ButtonProps
        }
      />
    ),
  };
  const pathNotFound: RouteObject = {
    path: Route.PathNotFound,
    element: notFoundErrorPage,
  };

  return [
    {
      path: Route.Root,
      element: <ErrorLayout />,
      children: [pageNotFound, internalServerError, pathNotFound, networkFound],
    },
  ];
};

const authRoutes = (): RouteObject[] => {
  return [
    {
      path: Route.Root,
      element: <RootPage />,
    },
    {
      path: Route.SignIn,
      element: <SignInPage />,
    },
  ];
};

const getRoutes = (): RouteObject[] => {
  return [
    ...authRoutes(),
    ...projectRoutes(),
    ...manageUsersRoutes(),
    ...surveyRoutes(),
    ...errorRoutes(),
    ...spocRespondentRoutes(),
    auditLogRoute(),
    profileSettingsRoute(),
  ];
};

export const Router = (): React.ReactElement | null => {
  const RouteElement = (): React.ReactElement | null => {
    return useRoutes(getRoutes());
  };

  return <RouteElement />;
};
