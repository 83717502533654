import { Stack, Typography } from "@mui/material";
import { getCheckBoxState } from "@pulse/shared-components";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { Checkbox } from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";

interface SettingsCheckboxComponentProps {
  isCheckboxSelected: boolean;
  setIsCheckboxSelected: (checkboxState: boolean) => void;
  checkboxlabel: string;
  checkboxDescription?: string;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

export const SettingsCheckboxComponent = observer(
  ({
    isCheckboxSelected,
    setIsCheckboxSelected,
    checkboxlabel,
    checkboxDescription,
    spacing,
    typography,
    tokens,
  }: SettingsCheckboxComponentProps): React.ReactElement => {
    return (
      <Stack gap={spacing.spaceXXS} py={spacing.spaceSM} width="100%">
        <Checkbox
          state={getCheckBoxState(isCheckboxSelected)}
          onChange={setIsCheckboxSelected}
          label={checkboxlabel}
        />
        {checkboxDescription && (
          <Typography
            {...typography.b2}
            color={tokens.labelSubtle}
            paddingLeft={spacing.space2XL}
            width="100%"
          >
            {checkboxDescription}
          </Typography>
        )}
      </Stack>
    );
  },
);
