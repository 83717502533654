import { Stack } from "@mui/material";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useEffect, useRef } from "react";
import { ProjectsRootStore } from "../store/ProjectsRootStore";
import { TFunction } from "i18next";
import {
  ProjectListComponent,
  ProjectListHeaderEnum,
} from "./projectList/ProjectListComponent";
import {
  FoundationColorTokens,
  Spacing,
  TableReloadHandle,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";
import { FilterComponent } from "../../../components/filter/components/FilterComponent";
import { FilterType } from "../../../components/filter/model/FilterModel";
import { ErrorComponent } from "../../../components/ErrorComponent";
import { SignInStore } from "../../auth/store/SignInStore";

export interface ProjectListPaneProps {
  projectsRootStore: Instance<typeof ProjectsRootStore>;
  isArchivedProjectList: boolean;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  spacing: Spacing;
  navigateTo: NavigateToFunctions;
  setIsChildHeightVariable: React.Dispatch<React.SetStateAction<boolean>>;
  signInStore: Instance<typeof SignInStore>;
}

export const ProjectListPane = observer(
  ({
    t,
    tokens,
    isArchivedProjectList,
    projectsRootStore,
    typography,
    spacing,
    navigateTo,
    setIsChildHeightVariable,
    signInStore,
  }: ProjectListPaneProps): React.ReactElement => {
    const tableRef = useRef<TableReloadHandle | null>(null);

    // This useEffect is added to set the isChildHeightVariable state so that the component is centered
    // when no projects are present.
    useEffect(() => {
      setIsChildHeightVariable(true);
      if (
        !projectsRootStore.isRPCLoading &&
        !projectsRootStore.doesStoreContainProjectListErrors &&
        !projectsRootStore.isPaginatedListVisible(isArchivedProjectList)
      ) {
        setIsChildHeightVariable(false);
      }
    }, [
      projectsRootStore.isRPCLoading,
      projectsRootStore.doesStoreContainProjectListErrors,
      projectsRootStore.isPaginatedListVisible(isArchivedProjectList),
    ]);

    const filterList: { key: string; label: string }[] = [
      {
        key: ProjectListHeaderEnum.Project,
        label: t("projects.projectListHeaders.project"),
      },
      {
        key: ProjectListHeaderEnum.Creator,
        label: t("projects.projectListHeaders.creator"),
      },
      {
        key: ProjectListHeaderEnum.Surveys,
        label: t("projects.projectListHeaders.surveys"),
      },
      {
        key: ProjectListHeaderEnum.CreatedOn,
        label: t("projects.projectListHeaders.createdOn"),
      },
      {
        key: ProjectListHeaderEnum.LastModified,
        label: t("projects.projectListHeaders.lastModified"),
      },
    ];

    if (projectsRootStore.doesStoreContainProjectListErrors) {
      return (
        <ErrorComponent
          errorMessage={t("projects.fetchingProjectsErrorText")}
          resolveButtonText={t("common.refresh")}
          resolveButtonCallback={() => {
            navigateTo.reload();
          }}
        />
      );
    } else {
      return (
        <Stack
          padding={spacing.spaceXL}
          height="100%"
          gap={spacing.spaceLG}
          minWidth="1264px"
          margin="auto"
        >
          {projectsRootStore.isPaginatedListVisible(isArchivedProjectList) && (
            <Stack width="100%">
              <FilterComponent
                isDisabled={
                  projectsRootStore.isRPCLoading ||
                  projectsRootStore.doesStoreContainProjectListErrors
                }
                store={projectsRootStore.filterStore}
                onApplyFiltersClick={async () => {
                  projectsRootStore.resetIsGetProjectListRPCCalled();
                  tableRef.current?.reload();
                }}
                onRemoveFiltersClick={() => {
                  projectsRootStore.resetIsGetProjectListRPCCalled();
                  tableRef.current?.reload();
                }}
                filters={filterList.map((column) => {
                  if (
                    column.key === ProjectListHeaderEnum.CreatedOn ||
                    column.key === ProjectListHeaderEnum.LastModified
                  ) {
                    return {
                      ...column,
                      valueType: FilterType.DateRange,
                    };
                  } else {
                    return {
                      ...column,
                      valueType: FilterType.OpenEnded,
                    };
                  }
                })}
              />
            </Stack>
          )}

          <Stack paddingBottom={spacing.spaceXL} height="100%">
            <ProjectListComponent
              t={t}
              tableRef={tableRef}
              tokens={tokens}
              isArchivedProjectsList={isArchivedProjectList}
              projectsRootStore={projectsRootStore}
              typography={typography}
              spacing={spacing}
              navigateTo={navigateTo}
              signInStore={signInStore}
            />
          </Stack>
        </Stack>
      );
    }
  },
);
