import { getStringTextForNullableValues } from "@pulse/shared-components";
import {
  EllipsisTypography,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React from "react";

export interface StyledEllipsisTypographyProps {
  data: string | number | null;
  typography: Typography;
  t: TFunction;
}

export const StyledEllipsisTypography = ({
  data,
  typography,
  t,
}: StyledEllipsisTypographyProps): React.ReactElement => {
  return (
    <EllipsisTypography
      textColor={"label"}
      maxLines={1}
      typography={{ ...typography.b1 }}
    >
      {data ? data : getStringTextForNullableValues(data, t)}
    </EllipsisTypography>
  );
};
