import React from "react";
import { UserAccessStore, UserAccessTabs } from "../../store/UserAccessStore";
import { DeveloperErrorFlow } from "../../../../components/DeveloperErrorFlow";
import { ManageSPoCsPane } from "./ManageSPoCsPane";
import { Instance } from "mobx-state-tree";
import { TFunction } from "i18next";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import {
  FoundationColorTokens,
  Spacing,
  TableReloadHandle,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import { SignInStore } from "../../../auth/store/SignInStore";
import { UserPrivileges } from "../../../store/user/UserPrivileges";
import { ManageBOUserPane } from "./ManageBOUserPane";

interface UserAccessTabElementProps {
  userAccessStore: Instance<typeof UserAccessStore>;
  t: TFunction;
  navigateTo: NavigateToFunctions;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  spacing: Spacing;
  signInStore: Instance<typeof SignInStore>;
  userAccessTableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const UserAccessTabElement = observer(
  ({
    userAccessStore,
    navigateTo,
    t,
    tokens,
    typography,
    spacing,
    signInStore,
    userAccessTableRef,
  }: UserAccessTabElementProps): React.ReactElement => {
    switch (userAccessStore.selectedTab) {
      case UserAccessTabs.ManageBOU: {
        if (!signInStore.isPrivilegeGranted(UserPrivileges.viewProjectBOUs)) {
          return <DeveloperErrorFlow />;
        }
        return (
          <ManageBOUserPane
            manageBOUserStore={userAccessStore.manageBOUserStore}
            t={t}
            navigateTo={navigateTo}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
            signInStore={signInStore}
            tableRef={userAccessTableRef}
            boUserStore={undefined}
            isMultiSelectEnabled={true}
            isManageUserPane={false}
          />
        );
      }
      case UserAccessTabs.ManageSPoCs: {
        if (!signInStore.isPrivilegeGranted(UserPrivileges.viewSpocs)) {
          return <DeveloperErrorFlow />;
        }
        return (
          <ManageSPoCsPane
            userAccessStore={userAccessStore}
            t={t}
            navigateTo={navigateTo}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
            signInStore={signInStore}
            tableRef={userAccessTableRef}
          />
        );
      }
      default:
        return <DeveloperErrorFlow />;
    }
  },
);
