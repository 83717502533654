import React, { SetStateAction, useEffect } from "react";
import { TFunction } from "i18next";
import { SurveyRulesUploadPane } from "./SurveyRulesUploadPane";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import {
  NetworkingError,
  NetworkingErrorStore,
  SuccessfulDialogComponent,
} from "@pulse/shared-components";
import { UploadFileDialog } from "../../../components/fileUpload/UploadFileDialog";
import { ProjectDetailsStore } from "../../projects/store/ProjectDetailsStore";
import {
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";

export interface SurveyRulesUploadDialogProps {
  isUploadRulesDialogVisible: boolean;
  setIsUploadRulesDialogVisible: React.Dispatch<SetStateAction<boolean>>;
  t: TFunction;
  navigateTo: NavigateToFunctions;
  errorStore: Instance<typeof NetworkingErrorStore>;
  projectDetailsStore: Instance<typeof ProjectDetailsStore>;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
}

export const SurveyRulesUploadDialog = observer(
  ({
    isUploadRulesDialogVisible,
    setIsUploadRulesDialogVisible,
    t,
    navigateTo,
    errorStore,
    projectDetailsStore,
    typography,
    spacing,
    tokens,
  }: SurveyRulesUploadDialogProps): React.ReactElement => {
    const surveyStore = projectDetailsStore.surveyStore;
    const surveyRulesStore = surveyStore.surveyRulesUploadStore;
    let errorBannerText: string | undefined;

    if (surveyRulesStore.rpcError && !errorStore) {
      switch (surveyRulesStore.rpcError) {
        case NetworkingError.InternalError:
        case NetworkingError.InternalServerError: {
          navigateTo.internalServerError();
        }
      }
    }

    if (surveyRulesStore.isFileInvalid) {
      errorBannerText = t("surveys.rulesDialog.invalidFileErrorDescription");
    }

    useEffect(() => {
      return projectDetailsStore.surveyStore.surveyRulesUploadStore.clearErrorsAndFlags();
    }, [isUploadRulesDialogVisible]);

    const downloadSurveyRules = async (): Promise<void> => {
      await processSurveyParams(async (surveyId, _projectId) => {
        await surveyRulesStore.downloadSurveyExistingRules(
          surveyId,
          surveyStore.surveyName,
        );
      }, navigateTo);
      return Promise.resolve();
    };

    if (surveyRulesStore.hasFileValidated) {
      return (
        <SuccessfulDialogComponent
          showSuccessfulDialogComponent={surveyRulesStore.hasFileValidated}
          title={t("surveys.rulesDialog.fileValidationTitle")}
          description={t("surveys.rulesDialog.fileValidationDescription")}
          onSecondaryButtonClick={async () => {
            setIsUploadRulesDialogVisible(false);
            surveyRulesStore.setHasFileValidated(false);
            processSurveyParams(async (surveyId, projectId) => {
              await projectDetailsStore.surveyStore.getQuestionsOfSurvey(
                surveyId,
                projectId,
              );
            }, navigateTo);
          }}
          secondaryButtonText={t("common.done")}
          descriptionTextWidth={undefined}
        />
      );
    }
    return (
      <UploadFileDialog
        isUploadFileDialogVisible={isUploadRulesDialogVisible}
        title={t("surveys.rulesDialog.title")}
        description={t("projects.respondentList.downloadTemplateDescription")}
        note={t("common.uploadDialog.uploadNoteText")}
        primaryDownloadButtonText={t(
          "projects.respondentList.downloadTemplate",
        )}
        primaryDownloadButtonOnClick={
          surveyRulesStore.downloadSurveyRulesTemplate
        }
        secondaryDowloadButtonText={
          surveyStore.doesSurveyContainRules
            ? t("surveys.rulesDialog.existingRulesButtonText")
            : undefined
        }
        secondaryDownloadButtonOnClick={
          surveyStore.doesSurveyContainRules ? downloadSurveyRules : undefined
        }
        primaryDialogButtonText={t("surveys.rulesDialog.primaryButtonText")}
        primaryDialogButtonOnClick={(): Promise<void> => {
          return processSurveyParams(async (surveyId, projectId) => {
            await surveyRulesStore.validateAndUploadSurveyRules(
              surveyId,
              projectId,
            );
          }, navigateTo);
        }}
        secondaryDialogButtonText={t("common.cancel")}
        isPrimaryDialogButtonDisabled={
          surveyRulesStore.isValidateRuleButtonDisabled
        }
        fileUploadPane={
          <SurveyRulesUploadPane
            surveyRulesUploadStore={surveyRulesStore}
            t={t}
            tokens={tokens}
            navigateTo={navigateTo}
            spacing={spacing}
            typography={typography}
          />
        }
        isSecondaryDialogButtonDisabled={
          surveyRulesStore.isSurveyRulesUploadDialogSecondaryButtonDisabled
        }
        secondaryDialogButtonOnClick={() => {
          setIsUploadRulesDialogVisible(false);
          surveyRulesStore.clearRpcErrorsAndValidations();
        }}
        errorBannerText={errorBannerText}
      />
    );
  },
);
