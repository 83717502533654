import { observer } from "mobx-react";
import React from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import {
  Spacing,
  FoundationColorTokens,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Stack, Typography } from "@mui/material";
import { SettingsRichTextEditor } from "./SettingsRichTextEditor";
import { MAX_THANK_YOU_PAGE_TEXT_LENGTH } from "@pulse/shared-components";
import { SettingsCheckboxComponent } from "./SettingsCheckboxComponent";
import { Instance } from "mobx-state-tree";
import { RTEFileServiceModel } from "../../../projects/models/RTEFileServiceModel";

interface ThankYouPageCustomisationProps {
  thankYouPageText: string;
  setThankYouPageText: (thankYouPageText: string) => void;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  isThankYouPageTextErrored: boolean;
  setIsFIThankYouPageLogoShown: (isCheckboxChecked: boolean) => void;
  isFIThankYouPageLogoShown: boolean;
  rteFileService: Instance<typeof RTEFileServiceModel>;
  updatedThankYouPageText: string;
}

export const ThankYouPageCustomisation = observer(
  ({
    thankYouPageText,
    setThankYouPageText,
    t,
    spacing,
    typography,
    tokens,
    isThankYouPageTextErrored,
    setIsFIThankYouPageLogoShown,
    isFIThankYouPageLogoShown,
    rteFileService,
    updatedThankYouPageText,
  }: ThankYouPageCustomisationProps): React.ReactElement => {
    return (
      <SettingsContainer
        settingHeadingText={t(
          "surveys.settings.thankYouPageCustomisationSettings.thankYouPageCustomisationTitleText",
        )}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
        tooltipChild={
          <Typography {...typography.b3} color={tokens.labelLowEmphasis}>
            {t("surveys.settings.newLineDescription")}
          </Typography>
        }
      >
        <Stack width="720px" gap={spacing.spaceLG}>
          <SettingsCheckboxComponent
            isCheckboxSelected={isFIThankYouPageLogoShown}
            setIsCheckboxSelected={setIsFIThankYouPageLogoShown}
            checkboxlabel={t(
              "surveys.settings.thankYouPageCustomisationSettings.isFIThankYouPageLogoShownText",
            )}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
          />

          <SettingsRichTextEditor
            label={t(
              "surveys.settings.thankYouPageCustomisationSettings.surveyCompletedPageRteLabelText",
            )}
            placeholder={t(
              "surveys.settings.thankYouPageCustomisationSettings.surveyCompletedPageRtePlaceholderText",
            )}
            rteText={thankYouPageText}
            setRTEText={(rteText: string) => {
              if (rteText !== updatedThankYouPageText) {
                rteFileService.setIsFileUploadError(false);
              }
              setThankYouPageText(rteText);
            }}
            isError={isThankYouPageTextErrored}
            t={t}
            maxRTETextLength={MAX_THANK_YOU_PAGE_TEXT_LENGTH}
            attachmentConfig={rteFileService.richTextAttachmentProps}
            isRTEFileServiceHelperTextVisible={
              rteFileService.isRTEFileServiceError
            }
          />
        </Stack>
      </SettingsContainer>
    );
  },
);
