import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React, { useEffect, useState } from "react";
import {
  ArchiveBOUserDialogState,
  SendPasswordResetLinkArchiveBOUserStore,
  ArchiveBOUserRPCError,
} from "../store/SendPasswordResetLinkArchiveBOUserStore";
import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  TableReloadHandle,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { DialogErrorContent } from "../../../components/DialogErrorContent";
import { Stack } from "@mui/material";
import { observer } from "mobx-react";
import { NestedTypographyDestructiveComponent } from "../../projects/components/NestedTypographyDestructiveComponent";
import {
  COUNTDOWN_INTERVAL_IN_MS,
  END_OF_COUNTDOWN,
  TOTAL_COUNTDOWN_TIME_IN_SEC,
  ZERO_VALUE,
} from "@pulse/shared-components";

interface ArchiveBOUserDialogProps {
  isArchiveBOUserDialogVisible: boolean;
  setIsArchiveBOUserDialogVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  t: TFunction;
  spacing: Spacing;
  sendPasswordResetLinkArchiveBOUserStore: Instance<
    typeof SendPasswordResetLinkArchiveBOUserStore
  >;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  boUserId: string;
  firstName: string;
  lastName: string;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const ArchiveBOUserDialog = observer(
  ({
    boUserId,
    isArchiveBOUserDialogVisible,
    setIsArchiveBOUserDialogVisible,
    sendPasswordResetLinkArchiveBOUserStore,
    spacing,
    t,
    tokens,
    typography,
    tableRef,
    firstName,
    lastName,
  }: ArchiveBOUserDialogProps): React.ReactElement => {
    const [countDownValue, setCountDownValue] = useState(
      TOTAL_COUNTDOWN_TIME_IN_SEC,
    );
    useEffect(() => {
      const interval = setInterval(() => {
        setCountDownValue(countDownValue - 1);
      }, COUNTDOWN_INTERVAL_IN_MS);
      if (countDownValue === END_OF_COUNTDOWN) clearInterval(interval);
      return () => clearInterval(interval);
    }, [countDownValue]);

    useEffect(() => {
      return () => {
        sendPasswordResetLinkArchiveBOUserStore.resetStoreData();
      };
    }, []);

    const getPrimaryButtonText = (): string | undefined => {
      switch (
        sendPasswordResetLinkArchiveBOUserStore.archiveBOUserDialogState
      ) {
        case ArchiveBOUserDialogState.Error: {
          return undefined;
        }
        case ArchiveBOUserDialogState.ArchiveBOUser: {
          if (countDownValue === 0) {
            return t("manageUsers.archiveBOUserDialog.archiveUser");
          } else {
            return (
              t("manageUsers.archiveBOUserDialog.archiveUser") +
              ` (${countDownValue})`
            );
          }
        }
      }
    };

    const getSecondaryButtonText = (): string | undefined => {
      switch (
        sendPasswordResetLinkArchiveBOUserStore.archiveBOUserDialogState
      ) {
        case ArchiveBOUserDialogState.Error: {
          return t("common.close");
        }
        case ArchiveBOUserDialogState.ArchiveBOUser: {
          return t("common.cancel");
        }
      }
    };

    const getErrorText = (): string => {
      switch (sendPasswordResetLinkArchiveBOUserStore.rpcError) {
        case ArchiveBOUserRPCError.InvalidBOUserId:
        case ArchiveBOUserRPCError.UnableToArchiveBOUser: {
          return t(
            "manageUsers.archiveBOUserDialog.errorTexts.unexpectedError",
          );
        }
        case ArchiveBOUserRPCError.CannotArchiveSelf: {
          return t(
            "manageUsers.archiveBOUserDialog.errorTexts.cannotArchiveSelf",
          );
        }
        default: {
          return t(
            "manageUsers.archiveBOUserDialog.errorTexts.unexpectedError",
          );
        }
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (
        sendPasswordResetLinkArchiveBOUserStore.archiveBOUserDialogState
      ) {
        case ArchiveBOUserDialogState.ArchiveBOUser: {
          return (
            <Stack gap={spacing.spaceXS} direction="row">
              <NestedTypographyDestructiveComponent
                tokens={tokens}
                typography={typography}
                middleBoldText={`${firstName} ${lastName}`}
                startText={t(
                  "manageUsers.archiveBOUserDialog.startDescriptionText",
                )}
                endText={t(
                  "manageUsers.archiveBOUserDialog.endDescriptionText",
                )}
                noteText={undefined}
                spacing={spacing}
              />
            </Stack>
          );
        }
        case ArchiveBOUserDialogState.Error: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
      }
    };

    return (
      <Dialog
        open={isArchiveBOUserDialogVisible}
        title={t("manageUsers.archiveBOUserDialog.archiveUser")}
        contentPadding={spacing.spaceLG}
        disableBackdropClick={true}
        primaryButtonColor="destructive"
        primaryButtonText={getPrimaryButtonText()}
        secondaryButtonText={getSecondaryButtonText()}
        isPrimaryButtonDisabled={countDownValue !== ZERO_VALUE}
        onPrimaryButtonClick={async (): Promise<void> => {
          await sendPasswordResetLinkArchiveBOUserStore.archiveBOUser(boUserId);
          if (!sendPasswordResetLinkArchiveBOUserStore.doesStoreContainError) {
            tableRef.current?.reload();
            setIsArchiveBOUserDialogVisible(false);
          }
        }}
        isSecondaryButtonDisabled={
          sendPasswordResetLinkArchiveBOUserStore.isRPCLoading
        }
        onSecondaryButtonClick={() => {
          setIsArchiveBOUserDialogVisible(false);
          if (sendPasswordResetLinkArchiveBOUserStore.isInvalidBOUserIdError) {
            tableRef.current?.reload();
          }
          sendPasswordResetLinkArchiveBOUserStore.resetStoreData();
        }}
        width="560px"
      >
        {/*
        getDialogChild() does is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
