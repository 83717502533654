import { GetRespondentDetailsRPC } from "@pulse/pulse-rpcs";

export const getSortOrder = (
  sortOrder: "asc" | "desc",
): GetRespondentDetailsRPC.RequestEnums.SortOrder.SortOrder => {
  if (sortOrder === "asc") {
    return GetRespondentDetailsRPC.RequestEnums.SortOrder.SortOrder.ASCENDING;
  } else {
    return GetRespondentDetailsRPC.RequestEnums.SortOrder.SortOrder.DESCENDING;
  }
};
