import { LeoDate, LeoTimestamp } from "@surya-digital/leo-ts-runtime";

export const getFormatedLeoDate = (date: Date | undefined | null): LeoDate => {
  if (date) {
    const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return new LeoDate(dateString);
  } else {
    // If date is undefined or null then LeoDate returns current date.
    return new LeoDate();
  }
};

export const getFormatedLeoTimeStamp = (
  date: Date | undefined | null,
): LeoTimestamp => {
  if (date) {
    const offsetInMilliSeconds = date.getTimezoneOffset() * 60000;
    const temp = new LeoTimestamp(
      new Date(date.getTime() - offsetInMilliSeconds).toISOString(),
    );
    return temp;
  } else {
    // If date is undefined or null then LeoTimestamp returns current timestamp.
    return new LeoTimestamp();
  }
};
