import React from "react";
import { SectionComponent } from "../SectionComponent";
import { BorderStyle } from "@pulse/shared-components";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { TFunction } from "i18next";
import { Stack } from "@mui/material";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { PivotTableStore } from "../../store/PivotTableStore";
import { Instance } from "mobx-state-tree";
import { observer } from "mobx-react";
import { PivotTable } from "./PivotTable";
import { PivotTableRespondentColumnDropdown } from "./PivotTableRespondentColumnDropdown";
import { PivotErrorDialog } from "./PivotErrorDialog";

interface PivotComponentProps {
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  border: BorderStyle;
  borderRadius: CornerRadius;
  navigateTo: NavigateToFunctions;
  pivotTableStore: Instance<typeof PivotTableStore>;
}

export const PivotComponent = observer(
  ({
    border,
    borderRadius,
    navigateTo,
    spacing,
    t,
    tokens,
    typography,
    pivotTableStore,
  }: PivotComponentProps): React.ReactElement => {
    return (
      <SectionComponent
        t={t}
        spacing={spacing}
        tokens={tokens}
        typography={typography}
        border={border}
        borderRadius={borderRadius}
        title={t("projects.dashboard.pivotTable.pivotTableTitle")}
        onExpand={async () => {
          pivotTableStore.clearStore();
          await processProjectParams(
            pivotTableStore.getRespondentColumnNames,
            navigateTo,
          );
        }}
      >
        {pivotTableStore.isErrorDialogVisible && (
          <PivotErrorDialog
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            t={t}
            pivotTableStore={pivotTableStore}
            navigateTo={navigateTo}
          />
        )}
        <Stack width="100%">
          <PivotTableRespondentColumnDropdown
            t={t}
            pivotTableStore={pivotTableStore}
            navigateTo={navigateTo}
            spacing={spacing}
          />
          <PivotTable
            pivotTableStore={pivotTableStore}
            t={t}
            typography={typography}
            tokens={tokens}
            spacing={spacing}
          />
        </Stack>
      </SectionComponent>
    );
  },
);
