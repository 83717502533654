import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import {
  FoundationColorTokens,
  RadioButtonColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { Instance } from "mobx-state-tree";
import { GridQuestionV1Store } from "../../store/GridQuestionV1Store";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import {
  BorderStyle,
  GridColumnQuestionTypeV1Enum,
  getRadioButtonStyleProps,
} from "@pulse/shared-components";

interface GridQuestionTypeOptionsProps {
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  radioButtonTokens: RadioButtonColorTokens<string>;
  index: number;
  gridQuestionV1Store: Instance<typeof GridQuestionV1Store>;
  t: TFunction;
  border: BorderStyle;
}

export const GridQuestionTypeOptions = observer(
  ({
    spacing,
    typography,
    tokens,
    radioButtonTokens,
    index,
    gridQuestionV1Store,
    t,
    border,
  }: GridQuestionTypeOptionsProps): React.ReactElement => {
    const radioButtonStyleProps = getRadioButtonStyleProps(
      tokens,
      radioButtonTokens,
      spacing,
      `0px ${spacing.spaceXS} 0px 0px`,
    );

    // The MUI component has a default margin left of -11px. This property is used to override the default margin left value.
    const marginLeftCSSProp: SxProps = {
      "&.MuiFormControlLabel-root": {
        marginLeft: "unset",
      },
    };

    return (
      <Stack gap={spacing.spaceLG} width={"580px"}>
        <Typography
          sx={{
            ...typography.sh3,
            color: tokens.label,
            padding: `${spacing.spaceSM} 0px`,
            borderBottom: border.default,
          }}
        >
          {t("surveys.addSurveyQuestion.questionTypeLiteral")}
        </Typography>
        <RadioGroup
          onChange={(e): void => {
            gridQuestionV1Store.resetFieldValidationsAndErrors();
            gridQuestionV1Store.resetSpecificColumnQuestionType(index, t);
            gridQuestionV1Store.gridColumnQuestions[index].setQuestionType(
              e.target.value,
            );
          }}
          row={true}
          sx={{
            gap: spacing.spaceSM,
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <FormControlLabel
            value={GridColumnQuestionTypeV1Enum.OpenEnded}
            label={t("surveys.questionTypes.openEnded")}
            control={<Radio {...radioButtonStyleProps} />}
            checked={gridQuestionV1Store.gridColumnQuestions[index].isOpenEnded}
            sx={marginLeftCSSProp}
          />
          <FormControlLabel
            value={GridColumnQuestionTypeV1Enum.SingleChoice}
            label={t("surveys.questionTypes.singleSelect")}
            control={<Radio {...radioButtonStyleProps} />}
            checked={
              gridQuestionV1Store.gridColumnQuestions[index].isSingleChoice
            }
            sx={marginLeftCSSProp}
          />
          <FormControlLabel
            value={GridColumnQuestionTypeV1Enum.MultiChoice}
            label={t("surveys.questionTypes.multiSelect")}
            control={<Radio {...radioButtonStyleProps} />}
            checked={
              gridQuestionV1Store.gridColumnQuestions[index].isMultiChoice
            }
            sx={marginLeftCSSProp}
          />
        </RadioGroup>
      </Stack>
    );
  },
);
