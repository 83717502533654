import { Button, Stack, SxProps, TextField } from "@mui/material";
import {
  FoundationColorTokens,
  Plus,
  Spacing,
  useCornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { Minus } from "lucide-react";
import { useBorder } from "@pulse/shared-components";
import { DividerComponent } from "./commonQuestionConfigurationDetailsComponents/DividerComponent";
import { ICON_SIZE } from "@pulse/shared-components";
import { getIconProps } from "@pulse/shared-components";

interface NumericStepperProps {
  isPlusIconDisabled: boolean;
  onPlusIconClick: () => void;
  isMinusIconDisabled: boolean;
  onMinusIconClick: () => void;
  onInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  value: number | null;
  isError: boolean;
  isInputFieldDisabled?: boolean;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
}

export const NumericStepper = ({
  isPlusIconDisabled,
  onPlusIconClick,
  isMinusIconDisabled,
  onMinusIconClick,
  onInputChange,
  value,
  isError,
  isInputFieldDisabled = false,
  spacing,
  tokens,
}: NumericStepperProps): React.ReactElement => {
  const cornerRadius = useCornerRadius();
  const border = useBorder();
  const getButtonStyleProps = (
    borderRadius = `${cornerRadius.radiusXS} 0 0 ${cornerRadius.radiusXS}`,
  ): SxProps => {
    return {
      height: "48px",
      borderRadius,
      padding: `${spacing.spaceSM} ${spacing.spaceMD}`,
      color: tokens.label,
      position: "relative",
      "&:hover": {
        background: tokens.backgroundHovered,
      },
      minWidth: "fit-content",
    };
  };

  return (
    <Stack
      border={isError ? border.error : border.default}
      borderRadius={cornerRadius.radiusXS}
      divider={<DividerComponent height="100%" orientation="vertical" />}
      flexDirection="row"
    >
      <Button
        disableElevation
        disabled={isMinusIconDisabled}
        sx={{ ...getButtonStyleProps() }}
        name="minusButton"
        variant="text"
        onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
          event.stopPropagation();
          onMinusIconClick();
        }}
      >
        <Minus
          {...getIconProps(
            isMinusIconDisabled ? tokens.iconLowEmphasis : tokens.icon,
            ICON_SIZE.medium,
          )}
        />
      </Button>
      {/* Leo component was not used here because the borders cannot be overridden as per the requirement. */}
      <TextField
        name={"inputField"}
        value={value}
        type={"text"}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
          if (Number(event.target.value) > 0) {
            onInputChange(event);
          }
        }}
        disabled={isInputFieldDisabled}
        inputProps={{ style: { textAlign: "center", paddingRight: "0" } }}
        sx={{
          // We are overriding MUI TextField component to remove the border and align the input to the center.
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              border: "none",
              borderRadius: "0",
              alignItems: "center",
            },
          },
          width: "72px",
        }}
      />
      <Button
        disableElevation
        disabled={isPlusIconDisabled}
        sx={{
          ...getButtonStyleProps(
            `0 ${cornerRadius.radiusXS} ${cornerRadius.radiusXS} 0`,
          ),
        }}
        name="plusButton"
        variant="text"
        onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
          event.stopPropagation();
          onPlusIconClick();
        }}
      >
        <Plus
          {...getIconProps(
            isPlusIconDisabled ? tokens.iconLowEmphasis : tokens.icon,
            ICON_SIZE.medium,
          )}
        />
      </Button>
    </Stack>
  );
};
