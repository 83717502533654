import { Box } from "@mui/material";
import { QuestionType } from "@pulse/pulse-rpcs";
import { RadioButtonChecked } from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React from "react";
import {
  ArrowDownWideNarrowIcon,
  LayoutGridIcon,
  ListTodoIcon,
  MenuSquareIcon,
  MessageCircleIcon,
} from "lucide-react";
import { CheckBoxOutlined } from "../../assets/icons/CheckBoxOutlinedIcon";
import { ICON_SIZE, getIconProps } from "@pulse/shared-components";

export const getQuestionTypeIcon = (
  questionType: QuestionType.QuestionType,
  color: string,
  isIconLarge?: boolean,
): React.ReactElement => {
  const iconDimensions = isIconLarge ? ICON_SIZE.medium : ICON_SIZE.default;
  const iconColorAndWidthProps = getIconProps(color, iconDimensions);
  let icon;
  switch (questionType) {
    case QuestionType.QuestionType.OPEN_ENDED:
      icon = <MenuSquareIcon {...iconColorAndWidthProps} />;
      break;
    case QuestionType.QuestionType.SINGLE_CHOICE:
      icon = <RadioButtonChecked {...iconColorAndWidthProps} />;
      break;
    case QuestionType.QuestionType.MULTIPLE_CHOICE:
      icon = <CheckBoxOutlined {...iconColorAndWidthProps} />;
      break;
    case QuestionType.QuestionType.GRID:
      icon = <ListTodoIcon {...iconColorAndWidthProps} />;
      break;
    case QuestionType.QuestionType.GROUP:
      icon = <LayoutGridIcon {...iconColorAndWidthProps} />;
      break;
    case QuestionType.QuestionType.RANKING:
      icon = <ArrowDownWideNarrowIcon {...iconColorAndWidthProps} />;
      break;
    case QuestionType.QuestionType.MESSAGE:
      icon = <MessageCircleIcon {...iconColorAndWidthProps} />;
      break;
  }
  return (
    <Box height={iconDimensions} width={iconDimensions}>
      {icon}
    </Box>
  );
};

export const getQuestionTypeTypography = (
  questionType: QuestionType.QuestionType,
  t: TFunction,
): string => {
  switch (questionType) {
    case QuestionType.QuestionType.OPEN_ENDED:
      return t("surveys.questionTypes.openEnded");
    case QuestionType.QuestionType.SINGLE_CHOICE:
      return t("surveys.questionTypes.singleSelect");
    case QuestionType.QuestionType.MULTIPLE_CHOICE:
      return t("surveys.questionTypes.multiSelect");
    case QuestionType.QuestionType.GRID:
      return t("surveys.questionTypes.grid");
    case QuestionType.QuestionType.GROUP:
      return t("surveys.questionTypes.group");
    case QuestionType.QuestionType.RANKING:
      return t("surveys.questionTypes.ranking");
    case QuestionType.QuestionType.MESSAGE:
      return t("surveys.questionTypes.message");
  }
};
