import { Stack, Typography } from "@mui/material";
import {
  Typography as LeoTypography,
  FoundationColorTokens,
  Spacing,
  TextInputField,
  MultiAutoCompleteInputField,
  AutoCompleteInputField,
  DateRangePickerField,
  InvalidDateError,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { FilterType } from "../model/FilterModel";
import { Instance } from "mobx-state-tree";
import { FilterStore } from "../store/FilterStore";
import { Filter } from "./FilterComponent";
import { TFunction } from "i18next";
import {
  EMPTY_CHARACTER,
  MAX_OPEN_ENDED_FILTER_CHARACTER_COUNT,
  ZERO_VALUE,
  showMaxCharacterCountIndicator,
} from "@pulse/shared-components";

export interface FilterInputComponentProps {
  store: Instance<typeof FilterStore>;
  filters: Filter[];
  t: TFunction;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  setIsDateRangeInputInvalid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FilterInputComponent = observer(
  ({
    store,
    filters,
    t,
    typography,
    tokens,
    spacing,
    setIsDateRangeInputInvalid,
  }: FilterInputComponentProps): React.ReactElement => {
    const SelectedFilterType = observer((): React.ReactElement => {
      if (store.currentSelectedFilter !== null) {
        const filterValue = filters.find(
          (filter) => filter.key === store.currentSelectedFilter.key,
        );
        if (filterValue?.valueType === FilterType.OpenEnded) {
          return (
            <TextInputField
              name={"openEndedFilter"}
              value={store.currentSelectedFilter.openEndedFilterValue}
              onTextChange={(inputValue) => {
                store.setOpenEndedFilterValue(
                  inputValue.slice(
                    ZERO_VALUE,
                    MAX_OPEN_ENDED_FILTER_CHARACTER_COUNT,
                  ),
                );
              }}
              label={t("filters.value")}
              type={"text"}
              placeholder={t("filters.valuePlaceholder")}
              maxCharacterLength={MAX_OPEN_ENDED_FILTER_CHARACTER_COUNT}
              showCharacterCountIndicator={(): boolean =>
                showMaxCharacterCountIndicator(
                  MAX_OPEN_ENDED_FILTER_CHARACTER_COUNT,
                  store.currentSelectedFilter.openEndedFilterValue ??
                    EMPTY_CHARACTER,
                )
              }
              disabled={
                store.isNoFilterValueTypeSelected || store.isFilterAlreadyAdded
              }
            />
          );
        } else if (filterValue?.valueType === FilterType.DateRange) {
          return (
            <DateRangePickerField
              value={store.currentSelectedFilter.getDateRangePickerInput}
              startDateLabel={t("filters.fromDateLabelText")}
              endDateLabel={t("filters.toDateLabelText")}
              onChange={store.setDateRangeFilterValue}
              startDatePlaceholder={t("common.dateRangePlaceholderText")}
              endDatePlaceholder={t("common.dateRangePlaceholderText")}
              format="dd MMM, yyyy"
              disabled={
                store.isNoFilterValueTypeSelected || store.isFilterAlreadyAdded
              }
              onError={(
                startDateError: InvalidDateError | null,
                endDateError: InvalidDateError | null,
              ): void => {
                if (startDateError) {
                  console.error(startDateError);
                }
                if (endDateError) {
                  console.error(endDateError);
                }
                if (startDateError !== null || endDateError !== null) {
                  setIsDateRangeInputInvalid(true);
                } else {
                  setIsDateRangeInputInvalid(false);
                }
              }}
            />
          );
        } else if (
          filterValue !== undefined &&
          filterValue.values !== undefined
        ) {
          return (
            <MultiAutoCompleteInputField
              id={"multiAutoCompleteField"}
              selectType="checkbox"
              label={t("filters.value")}
              placeholder={t("filters.valuePlaceholder")}
              options={filterValue.values}
              value={store.currentSelectedFilter.multiSelectFilterValue.map(
                (item) => {
                  return { id: item.id, label: item.label };
                },
              )}
              onChange={(value) => {
                store.setMultiSelectFilterValue(value);
              }}
            />
          );
        }
      }
      return (
        <TextInputField
          name={"value"}
          value={store.currentSelectedFilter.openEndedFilterValue}
          onTextChange={store.setOpenEndedFilterValue}
          label={t("filters.value")}
          type={"text"}
          placeholder={t("filters.valuePlaceholder")}
          disabled={store.isNoFilterValueTypeSelected}
        />
      );
    });

    return (
      <Stack gap={spacing.spaceMD} padding={spacing.spaceXL}>
        <Stack gap={spacing.spaceSM} direction="row">
          <Stack minWidth="240px">
            <AutoCompleteInputField
              id="value-type"
              options={filters.map((filter) => {
                return { id: filter.key, label: filter.key };
              })}
              placeholder={t("filters.selectValueTypeText")}
              label={t("filters.valueType")}
              onChange={(selectedItem) => {
                const selectedItemType = filters.find(
                  (filter) => filter.key === selectedItem?.id,
                )?.valueType;
                store.removeCurrentSelectedFilter();
                if (selectedItemType !== undefined) {
                  store.addSelectedFilterKey(
                    selectedItem?.label,
                    selectedItemType,
                  );
                  // This else block also gets triggered when the user unselects an option, as in this case `selectedItemType` is undefined.
                } else {
                  console.warn(`Filter could not be found for ${selectedItem}`);
                }
              }}
            />
          </Stack>
          <Stack
            width={store.isDateRangeCurrentSelectedField ? "496px" : "360px"}
          >
            <SelectedFilterType />
          </Stack>
        </Stack>
        {store.isFilterAlreadyAdded && (
          <Typography sx={{ ...typography.b2, color: tokens.labelWarning }}>
            {t("filters.filterAlreadyAddedText")}
          </Typography>
        )}
      </Stack>
    );
  },
);
