import { GetRespondentDetailsRPC, SurveyLinkStatus } from "@pulse/pulse-rpcs";
import {
  Chip,
  ContentCellOptions,
  FoundationColorTokens,
  IconButton,
  Spacing,
  Table,
  TableOptions,
  TableReloadHandle,
  TableRowItem,
  TableSortOption,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import {
  getRespondentListHeaders,
  getSurveyLinkStatusChipProps,
} from "../../../../utils/RespondentTableUtils";
import { SPoCRespondentStore } from "../../../store/SPoCRespondentStore";
import { Instance } from "mobx-state-tree";
import { RespondentFilterOptionsModel } from "../../../../models/respondentFilterOptionsModel";
import {
  NavigateToFunctions,
  processSPoCParams,
} from "../../../../../routes/RoutesHelper";
import { RespondentModel } from "../../../models/RespondentModel";
import { RespondentWithSurveyDetailsModel } from "../../../models/RespondentWithSurveyDetailsModel";
import { StyledEllipsisTypography } from "../../../../../components/StyledEllipsisTypography";
import { Stack } from "@mui/material";
import { ListX, MinusSquare } from "lucide-react";
import {
  IconContainer,
  InformationDialogComponent,
} from "@pulse/shared-components";
import { observer } from "mobx-react";
import { FilterComponent } from "../../../../../components/filter/components/FilterComponent";
import { RespondentFilters } from "../../RespondentsPane";
import { FilterType } from "../../../../../components/filter/model/FilterModel";
import { UnassignRespondentFromSPoCDialog } from "./UnassignSPoCRespondentDialog";

interface SPoCRespondentsListProps {
  t: TFunction;
  spocRespondentStore: Instance<typeof SPoCRespondentStore>;
  navigateTo: NavigateToFunctions;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
}
interface TableDataProps {
  id: number;
  firstName: string | null;
  lastName: string | null;
  emailId: string | null;
  phoneNumber: string | null;
  surveyName: string | null;
  surveyLinkStatus: SurveyLinkStatus.SurveyLinkStatus;
  respondentWithSurveyDetail: Instance<typeof RespondentWithSurveyDetailsModel>;
}

export const SPoCRespondentsList = observer(
  ({
    t,
    spocRespondentStore,
    navigateTo,
    typography,
    tokens,
    spacing,
  }: SPoCRespondentsListProps): React.ReactElement => {
    const [isApplyFiltersClicked, setIsApplyFiltersClicked] = useState(false);
    const [
      isUnassignRespondentFromSPoCDialogOpen,
      setIsUnassignRespondentFromSPoCDialogOpen,
    ] = useState(false);
    const tableRef = useRef<TableReloadHandle | null>(null);
    const [selectedRespondent, setSelectedRespondent] = useState<
      number | undefined
    >();
    useEffect(() => {
      return spocRespondentStore.multiSelectTableStore.unselectAllRows();
    }, []);

    const getRespondentDetails = async (
      pageIndex: number,
      sort?: TableSortOption,
    ): Promise<void> => {
      await processSPoCParams(async (projectId, spocId) => {
        await spocRespondentStore.getRespondentDetails(
          pageIndex,
          projectId,
          spocId,
          sort,
          undefined,
        );
      }, navigateTo);
    };

    const getRespondents = async (
      options: TableOptions<Instance<typeof RespondentFilterOptionsModel>>,
      setTotalItems: React.Dispatch<React.SetStateAction<number>>,
    ): Promise<string | TableRowItem[]> => {
      if (isApplyFiltersClicked) {
        setIsApplyFiltersClicked(false);
        await getRespondentDetails(0, options.sort);
      } else {
        await getRespondentDetails(
          options.pageNumber ? options.pageNumber - 1 : 0,
          options.sort,
        );
      }

      const columnKeys =
        spocRespondentStore.mapRespondentColumnsToColumnWithIndex();

      const columns = (
        respondent: Instance<typeof RespondentModel>,
      ): ContentCellOptions[] => {
        return columnKeys.map((column) => {
          return {
            columnId: column,
            data: respondent[column as keyof typeof respondent] as string,
          };
        });
      };

      setTotalItems(spocRespondentStore.totalItems);

      const data: TableDataProps[] =
        spocRespondentStore.respondentWithSurveyDetailsList.map(
          (respondentWithSurveyDetail) => {
            return {
              emailId: respondentWithSurveyDetail.respondent.emailId,
              firstName: respondentWithSurveyDetail.respondent.firstName,
              id: respondentWithSurveyDetail.respondent.id,
              lastName: respondentWithSurveyDetail.respondent.lastName,
              phoneNumber: respondentWithSurveyDetail.respondent.phoneNumber,
              surveyName: respondentWithSurveyDetail.surveyName,
              surveyLinkStatus: respondentWithSurveyDetail.surveyLinkStatus,
              respondentWithSurveyDetail,
            };
          },
        );

      return data.map(
        ({
          emailId,
          firstName,
          id,
          lastName,
          phoneNumber,
          surveyName,
          surveyLinkStatus,
          respondentWithSurveyDetail,
        }) => {
          return {
            id: `${id}`,
            cells: [
              {
                data: (
                  <StyledEllipsisTypography
                    data={id}
                    typography={typography}
                    t={t}
                  />
                ),
                columnId:
                  GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.ID,
              },
              {
                data: (
                  <StyledEllipsisTypography
                    data={firstName}
                    typography={typography}
                    t={t}
                  />
                ),
                columnId:
                  GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn
                    .FIRST_NAME,
              },
              {
                data: (
                  <StyledEllipsisTypography
                    data={lastName}
                    typography={typography}
                    t={t}
                  />
                ),
                columnId:
                  GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn
                    .LAST_NAME,
              },
              {
                data: (
                  <StyledEllipsisTypography
                    data={emailId}
                    typography={typography}
                    t={t}
                  />
                ),
                columnId:
                  GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn
                    .EMAIL_ID,
              },
              {
                data: (
                  <StyledEllipsisTypography
                    data={phoneNumber}
                    typography={typography}
                    t={t}
                  />
                ),
                columnId: "PhoneNumber",
              },
              {
                data: (
                  <StyledEllipsisTypography
                    data={surveyName}
                    typography={typography}
                    t={t}
                  />
                ),
                columnId: "SurveyName",
              },
              {
                data: (
                  <Stack alignItems="start">
                    <Chip {...getSurveyLinkStatusChipProps(surveyLinkStatus)} />
                  </Stack>
                ),
                columnId: "SurveyStatus",
              },
              ...columns(respondentWithSurveyDetail.respondent),
              {
                columnId: "IndividualRespondentAction",
                data: (
                  <IconButton
                    icon={<MinusSquare />}
                    color="destructive"
                    name="UnassignButton"
                    variant="plain-color"
                    size="small"
                    onClick={() => {
                      setSelectedRespondent(id);
                      setIsUnassignRespondentFromSPoCDialogOpen(true);
                    }}
                  />
                ),
              },
            ],
          };
        },
      );
    };

    return (
      <Stack
        padding={spacing.spaceXL}
        gap={spacing.spaceLG}
        minWidth="1446px"
        maxWidth="100%"
        height="100%"
      >
        <InformationDialogComponent
          isDialogOpen={spocRespondentStore.isGetRespondentErrorDialogOpen}
          title={t("projects.spocRespondent.somethingWentWrongErrorTitle")}
          description={t(
            "projects.spocRespondent.somethingWentWrongErrorDescription",
          )}
          resolveButtonCallBack={navigateTo.reload}
          closeButtonCallback={spocRespondentStore.resetError}
          buttonText={t("common.refresh")}
        />
        <UnassignRespondentFromSPoCDialog
          isUnassignRespondentFromSPoCDialogOpen={
            isUnassignRespondentFromSPoCDialogOpen
          }
          setIsUnassignRespondentFromSPoCDialogOpen={
            setIsUnassignRespondentFromSPoCDialogOpen
          }
          spocRespondentStore={spocRespondentStore}
          spacing={spacing}
          t={t}
          selectedRespondent={selectedRespondent}
          tokens={tokens}
          typography={typography}
          setSelectedRespondent={setSelectedRespondent}
          tableRef={tableRef}
          navigateTo={navigateTo}
        />
        <FilterComponent
          isDisabled={
            spocRespondentStore.isRPCLoading ||
            spocRespondentStore.doesRespondentStoreContainError
          }
          store={spocRespondentStore.filterStore}
          onApplyFiltersClick={async () => {
            setIsApplyFiltersClicked(true);
            tableRef.current?.reload();
          }}
          onRemoveFiltersClick={() => {
            setIsApplyFiltersClicked(false);
            tableRef.current?.reload();
          }}
          filters={spocRespondentStore.spocRespondentFilterList.map(
            (column) => {
              if (column.key === RespondentFilters.Survey) {
                return {
                  ...column,
                  valueType: FilterType.MultiSelect,
                  values: spocRespondentStore.surveyList.map((survey) => {
                    return {
                      id: survey.surveyName,
                      label: survey.surveyName,
                    };
                  }),
                };
              }
              if (column.key === RespondentFilters.SurveyStatus) {
                return {
                  ...column,
                  valueType: FilterType.MultiSelect,
                  values: [
                    {
                      id: SurveyLinkStatus.SurveyLinkStatus.SUBMITTED,
                      label: t("surveyLinkStatus.submitted"),
                    },
                    {
                      id: SurveyLinkStatus.SurveyLinkStatus.NOT_ASSIGNED,
                      label: t("surveyLinkStatus.unassigned"),
                    },
                    {
                      id: SurveyLinkStatus.SurveyLinkStatus.NOT_STARTED,
                      label: t("surveyLinkStatus.notStarted"),
                    },
                    {
                      id: SurveyLinkStatus.SurveyLinkStatus.VIEWED,
                      label: t("surveyLinkStatus.viewed"),
                    },
                    {
                      id: SurveyLinkStatus.SurveyLinkStatus.STARTED,
                      label: t("surveyLinkStatus.started"),
                    },
                    {
                      id: SurveyLinkStatus.SurveyLinkStatus.LAST_PAGE,
                      label: t("surveyLinkStatus.lastPage"),
                    },
                    {
                      id: SurveyLinkStatus.SurveyLinkStatus.IN_PROGRESS,
                      label: t("surveyLinkStatus.inProgress"),
                    },
                  ],
                };
              } else {
                return {
                  ...column,
                  valueType: FilterType.OpenEnded,
                };
              }
            },
          )}
        />
        <Table
          ref={tableRef}
          name={"spocRespondentList"}
          header={getRespondentListHeaders(
            spocRespondentStore.respondentColumns,
          )}
          onTableOptionsChange={getRespondents}
          viewOverrides={{
            empty: {
              icon: IconContainer(
                <ListX
                  width="32px"
                  height="32px"
                  color={tokens.iconLowEmphasis}
                />,
              ),
              message: t("projects.respondentList.noRespondentsAddedTitle"),
            },
            loading: { message: t("common.searchTableLoadingState") },
          }}
          paginationOption={{
            itemsPerPage: spocRespondentStore.itemsPerPage,
            getPageIndicatorText(startItem, endItem, totalItems): string {
              return t("common.paginationIndicationText", {
                startItem,
                endItem,
                totalItems,
              });
            },
            position: "BOTH",
          }}
          styleOverrides={{
            background: tokens.backgroundElevatedLevel1,
            divider: "row",
          }}
          leftPinnedColumnIds={[
            GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.ID,
          ]}
          rightPinnedColumnIds={["IndividualRespondentAction"]}
          multiSelectOptions={{
            selectAllItemsText: t(
              "common.multiSelectTableTexts.selectAllItems",
              {
                totalItemCount: spocRespondentStore.totalItems,
              },
            ),
            clearAllItemsText: t(
              "common.multiSelectTableTexts.clearAllSelection",
            ),
            clearSelectionText: t(
              "common.multiSelectTableTexts.clearSelection",
            ),
            selectionText: t("common.multiSelectTableTexts.selection", {
              selectedItemsCount:
                spocRespondentStore.multiSelectTableStore.totalSelectedItems(
                  spocRespondentStore.totalItems,
                ),
            }),
            shouldPinSelectionColumn: true,
            store: spocRespondentStore.multiSelectTableStore,
            actionElements: [
              {
                label: t("projects.spocRespondent.remove"),
                name: t("projects.spocRespondent.remove"),
                onClick: (): void => {
                  setIsUnassignRespondentFromSPoCDialogOpen(true);
                  setSelectedRespondent(undefined);
                },
                variant: "outlined-color",
                color: "destructive",
              },
            ],
          }}
        />
      </Stack>
    );
  },
);
