import { AddBOUserToProjectRPC, GetBOUserDetailsRPC } from "@pulse/pulse-rpcs";
import { Instance, cast, flow, getRoot, types } from "mobx-state-tree";
import {
  useAddBOUserToProjectRPCClient,
  useGetBOUserDetailsRPCClient,
} from "../rpcs/RPC";
import { RootStore } from "../../root/store/RootStore";
import { getAPIClient } from "../../networking/APIClient";
import { APIClient } from "@surya-digital/tedwig";
import {
  BOUsersDetailsModel,
  createBOUsersDetailsModel,
} from "../models/BOUsersDetailsModel";
import { EMPTY_LIST_LENGTH } from "@pulse/shared-components";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";

export enum AddBOUserToProjectError {
  InvalidProjectId = "INVALID_PROJECT_ID",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  MaxBOUsersReached = "MAX_BO_USERS_REACHED",
  InvalidBOUserId = "INVALID_BO_USER_ID",
  BOUserAlreadyExists = "BO_USER_ALREADY_EXISTS",
}

export enum AddProjectBOUDialogState {
  AddNewProjectBOUSuccess = "Add New Project BOU Success",
  AddNewProjectBOUError = "Add New Project BOU Error",
  FetchingBOUDetails = "Fetching BOU Details",
  FetchingBOUDetailsSuccess = "Fetching BOU Details Success",
  FetchingBOUDetailsError = "Fetching BOU Details Error",
}

export const AddProjectBOUStore = types
  .model("AddProjectBOUStore", {
    rpcError: types.maybeNull(
      types.enumeration("rpcError", Object.values(AddBOUserToProjectError)),
    ),
    addProjectBOUDialogState: types.optional(
      types.enumeration(
        "addNewEmbedDialogState",
        Object.values(AddProjectBOUDialogState),
      ),
      AddProjectBOUDialogState.FetchingBOUDetails,
    ),
    isRPCLoading: types.optional(types.boolean, false),
    selectedBOUserIds: types.array(types.string),
    boUserDetails: types.array(BOUsersDetailsModel),
  })
  .actions((store) => ({
    removeSelectedBOUserId: (selectedBOUserId: string): void => {
      if (store.selectedBOUserIds.includes(selectedBOUserId)) {
        store.selectedBOUserIds.splice(
          store.selectedBOUserIds.indexOf(selectedBOUserId),
          1,
        );
      }
    },
    addSelectedBOUserId: (selectedBOUserId: string): void => {
      if (!store.selectedBOUserIds.includes(selectedBOUserId)) {
        store.selectedBOUserIds.push(selectedBOUserId);
      } else {
        console.error(`${selectedBOUserId} is already present in the list.`);
      }
    },
    resetAddProjectBOUDialog: (): void => {
      store.addProjectBOUDialogState =
        AddProjectBOUDialogState.FetchingBOUDetails;
      store.rpcError = null;
      store.isRPCLoading = false;
      store.boUserDetails.clear();
      store.selectedBOUserIds.clear();
    },
    getBOUserDetails: flow(function* () {
      store.isRPCLoading = true;
      store.rpcError = null;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetBOUserDetailsRPC.Request();
        const {
          response,
          error,
        }: {
          response?: GetBOUserDetailsRPC.Response;
          error?: GetBOUserDetailsRPC.Errors.Errors;
        } = yield useGetBOUserDetailsRPCClient(apiClient).execute(request);
        if (response) {
          store.boUserDetails = cast(
            response.boUserDetails.map((boUserDetails) => {
              return createBOUsersDetailsModel(boUserDetails);
            }),
          );
          store.addProjectBOUDialogState =
            AddProjectBOUDialogState.FetchingBOUDetailsSuccess;
        } else if (error) {
          console.error(`Unhandled error occured: ${error}`);
          store.addProjectBOUDialogState =
            AddProjectBOUDialogState.FetchingBOUDetailsError;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    addUsersToProject: flow(function* (projectId: string) {
      store.isRPCLoading = true;
      store.rpcError = null;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const boUserIds = store.selectedBOUserIds.map((boUserId) => {
          return new LeoUUID(boUserId);
        });

        const request = new AddBOUserToProjectRPC.Request(
          new LeoUUID(projectId),
          boUserIds,
        );
        const {
          response,
          error,
        }: {
          response?: AddBOUserToProjectRPC.Response;
          error?: AddBOUserToProjectRPC.Errors.Errors;
        } = yield useAddBOUserToProjectRPCClient(apiClient).execute(request);
        if (response) {
          store.addProjectBOUDialogState =
            AddProjectBOUDialogState.AddNewProjectBOUSuccess;
        } else if (error) {
          switch (error.code) {
            case AddBOUserToProjectError.InvalidProjectId:
              break;
            case AddBOUserToProjectError.ProjectAlreadyArchived:
              store.addProjectBOUDialogState =
                AddProjectBOUDialogState.AddNewProjectBOUError;
              store.rpcError = AddBOUserToProjectError.ProjectAlreadyArchived;
              break;
            case AddBOUserToProjectError.MaxBOUsersReached:
              store.addProjectBOUDialogState =
                AddProjectBOUDialogState.AddNewProjectBOUError;
              store.rpcError = AddBOUserToProjectError.MaxBOUsersReached;
              break;
            case AddBOUserToProjectError.BOUserAlreadyExists:
              store.addProjectBOUDialogState =
                AddProjectBOUDialogState.AddNewProjectBOUError;
              store.rpcError = AddBOUserToProjectError.BOUserAlreadyExists;
              break;
            case AddBOUserToProjectError.InvalidBOUserId:
              store.addProjectBOUDialogState =
                AddProjectBOUDialogState.AddNewProjectBOUError;
              store.rpcError = AddBOUserToProjectError.InvalidBOUserId;
              break;
            default:
              console.error(`Unhandled error occured: ${error}`);
              store.addProjectBOUDialogState =
                AddProjectBOUDialogState.AddNewProjectBOUError;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }))
  .views((store) => ({
    get isSelectedBOUserListEmpty(): boolean {
      return store.selectedBOUserIds.length === EMPTY_LIST_LENGTH;
    },
    get isAddProjectBOUDialogPrimaryButtonVisible(): boolean {
      return (
        store.rpcError === null &&
        store.addProjectBOUDialogState ===
          AddProjectBOUDialogState.FetchingBOUDetailsSuccess
      );
    },
    get selectedBOUserDetails(): Instance<typeof BOUsersDetailsModel>[] {
      // Filters out all the BO users that have not been selected by the user.
      return store.boUserDetails.filter((boUserDetails) => {
        return (
          store.selectedBOUserIds.find((selectedBOUserId) => {
            return boUserDetails.boUserId === selectedBOUserId;
          }) !== undefined
        );
      });
    },
    get nonSelectedBOUserDetails(): Instance<typeof BOUsersDetailsModel>[] {
      // Filters out all the BO users that have already been selected by the user.
      return store.boUserDetails.filter((boUserDetails) => {
        return (
          store.selectedBOUserIds.find((selectedBOUserId) => {
            return boUserDetails.boUserId === selectedBOUserId;
          }) === undefined
        );
      });
    },
  }));

export const createAddNewProjectBOUStore = (): Instance<
  typeof AddProjectBOUStore
> => {
  return AddProjectBOUStore.create();
};
