import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useRef, useState } from "react";
import { EmbedStore } from "../../store/EmbedStore";
import { Stack, Typography } from "@mui/material";
import {
  AutoCompleteInputField,
  Button,
  Spacing,
  Typography as LeoTypography,
  EmptyState,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { BorderStyle, EMPTY_CHARACTER } from "@pulse/shared-components";
import { AlertCircle, ListX } from "lucide-react";
import { ICON_SIZE } from "@pulse/shared-components";
import { getIconProps } from "@pulse/shared-components";

export interface EmbedViewProps {
  embedStore: Instance<typeof EmbedStore>;
  spacing: Spacing;
  t: TFunction;
  border: BorderStyle;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

export const EmbedView = observer(
  ({
    embedStore,
    spacing,
    t,
    border,
    typography,
    tokens,
  }: EmbedViewProps): React.ReactElement => {
    const [selectedEmbedId, setSelectedEmbedId] = useState<string | null>(null);
    const iframeContainerRef = useRef<HTMLDivElement>(null);

    const IframeComponent = (): React.ReactElement => {
      const embedIframe = embedStore.selectedEmbedDetailsView?.embedCode;

      const ErrorOrEmptyEmbedView = (): React.ReactElement => {
        return (
          <Stack
            width="1264px"
            borderRadius={spacing.spaceXS}
            border={border.default}
            padding={spacing.spaceXL}
            gap={spacing.spaceXS}
            alignItems="center"
          >
            {embedIframe ? (
              <>
                <AlertCircle
                  {...getIconProps(tokens.iconError, ICON_SIZE.large)}
                />
                <Typography
                  {...typography.b1}
                  color={tokens.labelError}
                  marginBottom={spacing.spaceXS}
                >
                  {t("projects.reports.embeds.embedView.invalidReportFound")}
                </Typography>
              </>
            ) : (
              <EmptyState
                title={t("projects.reports.embeds.embedView.noReportSelected")}
                icon={<ListX />}
                description={EMPTY_CHARACTER}
              />
            )}
          </Stack>
        );
      };
      return embedStore.isValidEmbedView && embedIframe ? (
        <Stack
          width="1264px"
          overflow="scroll"
          borderRadius={spacing.spaceXS}
          border={border.default}
          padding={spacing.spaceXL}
          ref={iframeContainerRef}
          gap={spacing.spaceXS}
        >
          <div dangerouslySetInnerHTML={{ __html: embedIframe }}></div>
        </Stack>
      ) : (
        <ErrorOrEmptyEmbedView />
      );
    };

    return (
      <Stack
        gap={spacing.spaceSM}
        alignItems="flex-end"
        justifyContent="flex-end"
        minWidth="1264px"
        paddingBottom={spacing.spaceXL}
      >
        <Stack direction="row" gap={spacing.spaceSM} alignItems="center">
          <AutoCompleteInputField
            id="SelectEmbed"
            label={EMPTY_CHARACTER}
            placeholder={t(
              "projects.reports.embeds.embedView.selectReportPlaceholder",
            )}
            options={embedStore.embedDetails.map((embedDetails) => {
              return {
                id: embedDetails.id,
                label: embedDetails.embedName,
              };
            })}
            disabled={embedStore.isEmbedDetailsEmpty}
            onChange={(embedDetails): void => {
              if (embedDetails && embedDetails.id) {
                setSelectedEmbedId(embedDetails.id);
              } else {
                setSelectedEmbedId(null);
                // This else block also gets triggered when the user unselects an option, as in this case `embedDetails` is null and `embedDetails.id` is undefined.
                // As per the discussion here: https://surya-digital.slack.com/archives/C06EPPC2Y1E/p1712747738387459, this happens because the Leo component calls this onSelect on every change.
                console.warn("Embed and Embed Id not found.");
              }
            }}
            value={
              embedStore.selectedEmbedDetailsView
                ? {
                    label: embedStore.selectedEmbedDetailsView.embedName,
                    id: embedStore.selectedEmbedDetailsView.id,
                  }
                : undefined
            }
            width="360px"
          />
          <Button
            label={t("projects.reports.embeds.embedView.viewReportButtonText")}
            onClick={() => {
              selectedEmbedId
                ? embedStore.setEmbedDetailsViewById(selectedEmbedId)
                : embedStore.resetEmbedDetailsView();
            }}
            size="large"
            variant="filled"
            name="viewReport"
            disabled={
              embedStore.selectedEmbedDetailsView?.id === selectedEmbedId ||
              selectedEmbedId === null
            }
          />
        </Stack>
        {/* Needs to be called as a function to avoid rerendering of the embed in case the autocomplete field is changed. */}
        {IframeComponent()}
      </Stack>
    );
  },
);
