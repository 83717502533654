import { Instance, types } from "mobx-state-tree";

export const ProjectDetailsModel = types.model("ProjectDetailsModel", {
  projectId: types.string,
  creator: types.string,
  projectName: types.string,
  surveys: types.string,
  createdOn: types.Date,
  lastModified: types.Date,
});

export const createProjectDetailsModel = (
  projectId: string,
  creator: string,
  projectName: string,
  surveys: string,
  createdOn: string,
  lastModified: string,
): Instance<typeof ProjectDetailsModel> => {
  return ProjectDetailsModel.create({
    projectId,
    creator,
    projectName,
    surveys,
    createdOn: new Date(createdOn),
    lastModified: new Date(lastModified),
  });
};
