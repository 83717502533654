import { Stack } from "@mui/material";
import {
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  useMultiChoiceQuestionV1Store,
  useQuestionDetailsStore,
} from "../store/hooks";
import { observer } from "mobx-react";
import { CommonChoiceOptionsLayout } from "./CommonChoiceOptionsLayout";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { ResetFieldsButton } from "./commonQuestionConfigurationDetailsComponents/ResetFieldsButton";
import { QuestionOptionsTitleWithTooltip } from "./QuestionOptionsTitleWithTooltip";

interface MultiSelectFieldCustomisationPaneProps {
  setIsResetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MultiSelectFieldCustomisationPane = observer(
  ({
    setIsResetDialogOpen,
  }: MultiSelectFieldCustomisationPaneProps): React.ReactElement => {
    const { t } = useTranslation();
    const spacing = useSpacing();
    const multiChoiceV1Store = useMultiChoiceQuestionV1Store();
    const questionDetailsStore = useQuestionDetailsStore();
    const tokens = useFoundationColorTokens();

    const ResetButton = observer((): React.ReactElement => {
      return (
        <ResetFieldsButton
          isResetDisabled={multiChoiceV1Store.isResetDisabled}
          setIsResetDialogOpen={setIsResetDialogOpen}
          t={t}
          spacing={spacing}
        />
      );
    });

    const ChoiceCustomisationPane = observer((): React.ReactElement => {
      return (
        <Stack width="600px">
          <QuestionOptionsTitleWithTooltip
            title={t("surveys.choiceCustomisation")}
            spacing={spacing}
            tokens={tokens}
          />
          <Stack spacing={spacing.spaceSM} mt={spacing.spaceLG}>
            {multiChoiceV1Store.multiChoiceQuestionV1Model.options.map(
              (_field, index) => {
                return (
                  <CommonChoiceOptionsLayout
                    key={index}
                    optionTextWidth="456px"
                    optionCodeWidth="132px"
                    t={t}
                    index={index}
                    spacing={spacing}
                    options={
                      multiChoiceV1Store.multiChoiceQuestionV1Model.options
                    }
                    isAddNewFieldButtonDisabled={multiChoiceV1Store.isAddNewFieldButtonDisabled()}
                    isDeleteButtonDisabled={
                      multiChoiceV1Store.isDeleteButtonDisabled
                    }
                    setFieldValidations={
                      questionDetailsStore.setFieldValidations
                    }
                    doesOptionContainErrors={
                      multiChoiceV1Store.doesOptionContainErrors
                    }
                    addNewField={() =>
                      multiChoiceV1Store.addNewField(
                        {
                          option: EMPTY_CHARACTER,
                          code: EMPTY_CHARACTER,
                        },
                        index,
                      )
                    }
                    doesOptionCodeContainErrors={
                      multiChoiceV1Store.doesOptionCodeContainErrors
                    }
                    isOptionCodeNotUnique={
                      questionDetailsStore.isOptionCodeNotUnique
                    }
                    setDuplicateOptionCode={
                      questionDetailsStore.setDuplicateOptionCode
                    }
                    setCode={multiChoiceV1Store.setCode}
                    isChevronUpDisabled={multiChoiceV1Store.isChevronUpDisabled}
                    isChevronDownDisabled={
                      multiChoiceV1Store.isChevronDownDisabled
                    }
                    moveFieldUp={multiChoiceV1Store.moveFieldUp}
                    moveFieldDown={multiChoiceV1Store.moveFieldDown}
                    removeField={multiChoiceV1Store.removeField}
                    setFieldOptionText={multiChoiceV1Store.setFieldOptionText}
                  />
                );
              },
            )}
          </Stack>
          <ResetButton />
        </Stack>
      );
    });

    return (
      <Stack gap={spacing.space2XL}>
        <ChoiceCustomisationPane />
      </Stack>
    );
  },
);
