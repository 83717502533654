import { ListItemButton } from "@mui/material";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import { SurveyQuestionModel } from "../../models/SurveyQuestionModel";
import React, { SetStateAction } from "react";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";
import { TFunction } from "i18next";

export interface ChildQuestionItemButtonProps extends React.PropsWithChildren {
  childQuestionDetails: Instance<typeof SurveyQuestionModel>;
  setSelectedItemQuestionId: (value: SetStateAction<string | null>) => void;
  setIsMessageBoxVisible: (value: SetStateAction<boolean>) => void;
  setMessageBoxQuestionItemId: (value: SetStateAction<string | null>) => void;
  questionDetails: Instance<typeof QuestionDetailsStore>;
  t: TFunction;
  questionId: string;
}

export const ChildQuestionItemButton = observer(
  ({
    childQuestionDetails,
    setIsMessageBoxVisible,
    setMessageBoxQuestionItemId,
    setSelectedItemQuestionId,
    questionDetails,
    questionId,
    t,
    children,
  }: ChildQuestionItemButtonProps): React.ReactElement => {
    return (
      <ListItemButton
        onClick={(): void => {
          if (!questionDetails.isSaveButtonDisabled) {
            setMessageBoxQuestionItemId(
              childQuestionDetails.surveyQuestionDetails.questionId,
            );
            setIsMessageBoxVisible(true);
          } else {
            setSelectedItemQuestionId(
              childQuestionDetails.surveyQuestionDetails.questionId,
            );
            if (questionId !== EMPTY_CHARACTER) {
              questionDetails.getQuestionDetails(
                childQuestionDetails.surveyQuestionDetails.questionId,
                t,
              );
            }
          }
        }}
        sx={{ padding: "0px", flexGrow: "unset" }}
      >
        {children}
      </ListItemButton>
    );
  },
);
