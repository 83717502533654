import { Instance } from "mobx-state-tree";
import React, { useEffect, useRef, useState } from "react";
import {
  DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE,
  DEFAULT_PROJECT_LIST_SORT_INDEX,
  EMPTY_CHARACTER,
  FeatureInDevelopmentSnackbar,
  ICON_SIZE,
  IconContainer,
  getFormattedDateString,
  getIconProps,
} from "@pulse/shared-components";
import { TFunction } from "i18next";
import {
  FoundationColorTokens,
  Spacing,
  TableHeader,
  TableOptions,
  TableReloadHandle,
  TableRowItem,
  TableSortOption,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { IconButton, Table } from "@surya-digital/leo-reactjs-material-ui";
import { ListX, MinusSquare } from "lucide-react";
import { StyledEllipsisTypography } from "../../../../components/StyledEllipsisTypography";
import { Stack } from "@mui/material";
import { FilterComponent } from "../../../../components/filter/components/FilterComponent";
import { FilterType } from "../../../../components/filter/model/FilterModel";
import { observer } from "mobx-react";
import { SignInStore } from "../../../auth/store/SignInStore";
import { UserPrivileges } from "../../../store/user/UserPrivileges";
import { ManageBOUserStore } from "../../store/ManageBOUserStore";
import { EditBOUser } from "../../../manageUsers/components/EditBoUser";
import { BOUserStore } from "../../../manageUsers/store/BOUserStore";
import { BoUserRole } from "@pulse/pulse-rpcs";
import { DeleteProjectBOUserDialog } from "./DeleteProjectBOUserDialog";
import { SendPasswordResetLink } from "../../../manageUsers/components/SendPasswordResetLink";
import { ArchiveBOUser } from "../../../manageUsers/components/ArchiveBOUser";

interface ManageBOUserPaneProps {
  manageBOUserStore: Instance<typeof ManageBOUserStore>;
  t: TFunction;
  navigateTo: NavigateToFunctions;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  spacing: Spacing;
  signInStore: Instance<typeof SignInStore>;
  isManageUserPane?: boolean;
  tableRef: React.MutableRefObject<TableReloadHandle | null> | undefined;
  isMultiSelectEnabled: boolean;
  boUserStore: Instance<typeof BOUserStore> | undefined;
}

export enum ManageProjectBOUserListHeaderEnum {
  Name = "Name",
  FirstName = "First Name",
  LastName = "Last Name",
  EmailAddress = "Email Address",
  Role = "Role",
  AddedOn = "Added On",
  ManageBOUserAction = "Manage BO User Action",
}

export enum ManageBOUserListHeaderEnum {
  FirstName = "First Name",
  LastName = "Last Name",
  EmailAddress = "Email Address",
  Role = "Role",
  AddedOn = "Added On",
  ManageBOUserAction = "Manage BO User Action",
}

export const ManageBOUserPane = observer(
  ({
    manageBOUserStore,
    t,
    tokens,
    navigateTo,
    typography,
    spacing,
    signInStore,
    isManageUserPane = false,
    tableRef,
    isMultiSelectEnabled,
    boUserStore,
  }: ManageBOUserPaneProps): React.ReactElement => {
    const [isRemoveProjectUserDialogOpen, setIsRemoveProjectUserDialogOpen] =
      useState(false);
    const [selectedBOUser, setSelectedBOUser] = useState<
      { name: string; boUserId: string } | undefined
    >();
    const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
    const boUserTableRef = tableRef ?? useRef<TableReloadHandle | null>(null);

    useEffect(() => {
      return (): void => {
        setSelectedBOUser(undefined);
        setIsRemoveProjectUserDialogOpen(false);
        manageBOUserStore.multiSelectTableStore.unselectAllRows();
      };
    }, []);

    const getConditionalFilterList = (): { key: string; label: string }[] => {
      if (isManageUserPane) {
        return [
          {
            key: ManageProjectBOUserListHeaderEnum.FirstName,
            label: t("projects.userAccess.manageBOUsersTableHeaders.name"),
          },
          {
            key: ManageProjectBOUserListHeaderEnum.LastName,
            label: t("projects.userAccess.manageBOUsersTableHeaders.name"),
          },
        ];
      } else {
        return [
          {
            key: ManageProjectBOUserListHeaderEnum.Name,
            label: t("projects.userAccess.manageBOUsersTableHeaders.name"),
          },
        ];
      }
    };

    const filterList: { key: string; label: string }[] = [
      {
        key: ManageProjectBOUserListHeaderEnum.AddedOn,
        label: t("projects.userAccess.manageBOUsersTableHeaders.addedOn"),
      },
      {
        key: ManageProjectBOUserListHeaderEnum.EmailAddress,
        label: t("projects.userAccess.manageBOUsersTableHeaders.emailAddress"),
      },
      {
        key: ManageProjectBOUserListHeaderEnum.Role,
        label: t("projects.userAccess.manageBOUsersTableHeaders.role"),
      },
      ...getConditionalFilterList(),
    ];

    const getProjectBOUsers = async (
      pageIndex: number,
      sort?: TableSortOption,
    ): Promise<void> => {
      if (isManageUserPane) {
        await manageBOUserStore.getBOUsers(pageIndex, sort);
      } else {
        await processProjectParams(async (projectId) => {
          await manageBOUserStore.getProjectBOUsers(pageIndex, projectId, sort);
        }, navigateTo);
      }
    };

    const getConditionalHeaders = (): TableHeader => {
      if (isManageUserPane) {
        return [
          {
            columnId: ManageProjectBOUserListHeaderEnum.FirstName,
            label: t("projects.userAccess.manageBOUsersTableHeaders.firstName"),
            // A fixed width is added here as opposed to a fill (based on design) as the component title breaks with 100% width.
            width: "244px",
            sortable: true,
          },
          {
            columnId: ManageProjectBOUserListHeaderEnum.LastName,
            label: t("projects.userAccess.manageBOUsersTableHeaders.lastName"),
            // A fixed width is added here as opposed to a fill (based on design) as the component title breaks with 100% width.
            width: "244px",
            sortable: true,
          },
        ];
      } else {
        return [
          {
            columnId: ManageProjectBOUserListHeaderEnum.Name,
            label: t("projects.userAccess.manageBOUsersTableHeaders.name"),
            // A fixed width is added here as opposed to a fill (based on design) as the component title breaks with 100% width.
            width: "434px",
            sortable: true,
          },
        ];
      }
    };

    const getHeaders = (): TableHeader => {
      const boUserListHeaders: TableHeader = [
        ...getConditionalHeaders(),
        {
          columnId: ManageProjectBOUserListHeaderEnum.EmailAddress,
          label: t(
            "projects.userAccess.manageBOUsersTableHeaders.emailAddress",
          ),
          sortable: true,
          // A fixed width is added here as opposed to a fill (based on design) as the component title breaks with 100% width.
          width: isManageUserPane ? "352px" : "434px",
        },
        {
          columnId: ManageProjectBOUserListHeaderEnum.Role,
          label: t("projects.userAccess.manageBOUsersTableHeaders.role"),
          // Below width is greater than in the design to keep the text on the same line.
          width: "200px",
        },
        {
          columnId: ManageProjectBOUserListHeaderEnum.AddedOn,
          label: t("projects.userAccess.manageBOUsersTableHeaders.addedOn"),
          width: "180px",
          sortable: true,
        },
      ];
      if (signInStore.isPrivilegeGranted(UserPrivileges.processProjectBOUser)) {
        boUserListHeaders.push({
          columnId: ManageProjectBOUserListHeaderEnum.ManageBOUserAction,
          label: EMPTY_CHARACTER,
          width: "auto",
          sortable: false,
        });
      }

      return boUserListHeaders;
    };

    const getActionElements = (
      boUserId: string,
      firstName: string,
      lastName: string,
      role: BoUserRole.BoUserRole,
    ): {
      data: JSX.Element;
      columnId: ManageProjectBOUserListHeaderEnum;
    } => {
      if (isManageUserPane) {
        return {
          columnId: ManageProjectBOUserListHeaderEnum.ManageBOUserAction,
          data: boUserStore ? (
            <Stack flexDirection="row" gap={spacing.spaceXS}>
              <EditBOUser
                t={t}
                spacing={spacing}
                editBOUserStore={boUserStore?.editBOUserStore}
                typography={typography}
                tokens={tokens}
                boUserId={boUserId}
                tableRef={boUserTableRef}
                firstName={firstName}
                lastName={lastName}
                role={role}
              />
              <SendPasswordResetLink
                t={t}
                spacing={spacing}
                typography={typography}
                tokens={tokens}
                boUserId={boUserId}
                sendPasswordResetLinkArchiveBOUserStore={
                  boUserStore.sendPasswordResetLinkStore
                }
                tableRef={boUserTableRef}
              />
              <ArchiveBOUser
                t={t}
                spacing={spacing}
                typography={typography}
                tokens={tokens}
                boUserId={boUserId}
                sendPasswordResetLinkArchiveBOUserStore={
                  boUserStore.sendPasswordResetLinkStore
                }
                tableRef={boUserTableRef}
                firstName={firstName}
                lastName={lastName}
              />
            </Stack>
          ) : (
            <></>
          ),
        };
      } else {
        return {
          columnId: ManageProjectBOUserListHeaderEnum.ManageBOUserAction,
          data: (
            <IconButton
              icon={<MinusSquare />}
              color="destructive"
              name="deleteButton"
              variant="plain-color"
              size="small"
              onClick={() => {
                setIsSnackbarVisible(true);
              }}
            />
          ),
        };
      }
    };

    const getProjectBOUsersList = async (
      options: TableOptions,
      setTotalItems: React.Dispatch<React.SetStateAction<number>>,
    ): Promise<string | TableRowItem[]> => {
      if (!manageBOUserStore.manageProjectBOUserListFilter.areNoFiltersAdded) {
        await getProjectBOUsers(DEFAULT_PROJECT_LIST_SORT_INDEX, options.sort);
      } else {
        await getProjectBOUsers(
          options.pageNumber
            ? options.pageNumber - 1
            : DEFAULT_PROJECT_LIST_SORT_INDEX,
          options.sort,
        );
      }

      setTotalItems(manageBOUserStore.totalItems);
      if (isManageUserPane) {
        return manageBOUserStore.boUserList.map(
          ({
            emailAddress,
            createdOn,
            firstName,
            lastName,
            boUserId,
            role,
          }) => {
            const boUserListCells = [
              {
                data: (
                  <StyledEllipsisTypography
                    data={firstName}
                    t={t}
                    typography={typography}
                  />
                ),
                columnId: ManageProjectBOUserListHeaderEnum.FirstName,
              },
              {
                data: (
                  <StyledEllipsisTypography
                    data={lastName}
                    t={t}
                    typography={typography}
                  />
                ),
                columnId: ManageProjectBOUserListHeaderEnum.LastName,
              },
              {
                data: (
                  <StyledEllipsisTypography
                    data={emailAddress}
                    t={t}
                    typography={typography}
                  />
                ),
                columnId: ManageProjectBOUserListHeaderEnum.EmailAddress,
              },
              {
                data: (
                  <StyledEllipsisTypography
                    data={role}
                    t={t}
                    typography={typography}
                  />
                ),
                columnId: ManageProjectBOUserListHeaderEnum.Role,
              },
              {
                data: (
                  <StyledEllipsisTypography
                    data={getFormattedDateString(createdOn)}
                    t={t}
                    typography={typography}
                  />
                ),
                columnId: ManageProjectBOUserListHeaderEnum.AddedOn,
              },
            ];
            if (
              signInStore.isPrivilegeGranted(
                UserPrivileges.processProjectBOUser,
              )
            ) {
              boUserListCells.push(
                getActionElements(
                  boUserId,
                  firstName,
                  lastName,
                  BoUserRole.BoUserRole[
                    role.toUpperCase() as keyof typeof BoUserRole.BoUserRole
                  ],
                ),
              );
            }
            return {
              id: boUserId,
              cells: boUserListCells,
            };
          },
        );
      } else {
        return manageBOUserStore.projectBOUserList.map(
          ({ emailAddress, createdOn, fullName: name, boUserId, role }) => {
            const projectsBOUserListCells = [
              {
                data: (
                  <StyledEllipsisTypography
                    data={name}
                    t={t}
                    typography={typography}
                  />
                ),
                columnId: ManageProjectBOUserListHeaderEnum.Name,
              },
              {
                data: (
                  <StyledEllipsisTypography
                    data={emailAddress}
                    t={t}
                    typography={typography}
                  />
                ),
                columnId: ManageProjectBOUserListHeaderEnum.EmailAddress,
              },
              {
                data: (
                  <StyledEllipsisTypography
                    data={role}
                    t={t}
                    typography={typography}
                  />
                ),
                columnId: ManageProjectBOUserListHeaderEnum.Role,
              },
              {
                data: (
                  <StyledEllipsisTypography
                    data={getFormattedDateString(createdOn)}
                    t={t}
                    typography={typography}
                  />
                ),
                columnId: ManageProjectBOUserListHeaderEnum.AddedOn,
              },
            ];
            if (
              signInStore.isPrivilegeGranted(
                UserPrivileges.processProjectBOUser,
              )
            ) {
              projectsBOUserListCells.push({
                columnId: ManageProjectBOUserListHeaderEnum.ManageBOUserAction,
                data: (
                  <IconButton
                    icon={<MinusSquare />}
                    color="destructive"
                    name="deleteButton"
                    variant="plain-color"
                    size="small"
                    onClick={() => {
                      setSelectedBOUser({ name, boUserId });
                      setIsRemoveProjectUserDialogOpen(true);
                    }}
                  />
                ),
              });
            }
            return {
              id: boUserId,
              cells: projectsBOUserListCells,
            };
          },
        );
      }
    };

    return (
      <Stack
        height="100%"
        padding={spacing.spaceXL}
        gap={spacing.spaceLG}
        alignItems="center"
        minWidth="1376px"
        margin="auto"
      >
        <FeatureInDevelopmentSnackbar
          isSnackbarVisible={isSnackbarVisible}
          setIsSnackbarVisible={setIsSnackbarVisible}
        />
        {isRemoveProjectUserDialogOpen && tableRef && (
          <DeleteProjectBOUserDialog
            isDeleteProjectBOUserDialogDialogOpen={
              isRemoveProjectUserDialogOpen
            }
            setIsDeleteProjectBOUserDialogDialogOpen={
              setIsRemoveProjectUserDialogOpen
            }
            manageBOUserStore={manageBOUserStore}
            spacing={spacing}
            t={t}
            selectedBOUser={selectedBOUser}
            tokens={tokens}
            typography={typography}
            setSelectedBOUser={setSelectedBOUser}
            tableRef={tableRef}
            navigateTo={navigateTo}
          />
        )}
        <Stack width="100%">
          <FilterComponent
            isDisabled={
              manageBOUserStore.isRPCLoading ||
              manageBOUserStore.doesStoreContainBOUserListError
            }
            store={manageBOUserStore.manageProjectBOUserListFilter}
            onApplyFiltersClick={async () => {
              boUserTableRef.current?.reload();
            }}
            onRemoveFiltersClick={() => {
              boUserTableRef.current?.reload();
            }}
            filters={filterList.map((column) => {
              if (column.key === ManageProjectBOUserListHeaderEnum.AddedOn) {
                return {
                  ...column,
                  valueType: FilterType.DateRange,
                };
              } else {
                return {
                  ...column,
                  valueType: FilterType.OpenEnded,
                };
              }
            })}
          />
        </Stack>
        <Stack minWidth="100%" paddingBottom={spacing.spaceXL}>
          <Table
            ref={boUserTableRef}
            name={"manageBOUserList"}
            header={getHeaders()}
            onTableOptionsChange={getProjectBOUsersList}
            viewOverrides={{
              empty: {
                icon: IconContainer(
                  <ListX
                    {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
                  />,
                ),
                message: t("projects.userAccess.noBOUserFound"),
              },
              loading: { message: t("common.searchTableLoadingState") },
            }}
            leftPinnedColumnIds={[]}
            rightPinnedColumnIds={[
              ManageProjectBOUserListHeaderEnum.ManageBOUserAction,
            ]}
            paginationOption={{
              itemsPerPage: DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE,
              getPageIndicatorText(startItem, endItem, totalItems): string {
                return t("common.paginationIndicationText", {
                  startItem,
                  endItem,
                  totalItems,
                });
              },
              position: "BOTH",
            }}
            styleOverrides={{
              background: tokens.backgroundElevatedLevel1,
              divider: "row",
            }}
            multiSelectOptions={
              isMultiSelectEnabled
                ? {
                    selectAllItemsText: t(
                      "common.multiSelectTableTexts.selectAllItems",
                      {
                        totalItemCount: manageBOUserStore.totalItems,
                      },
                    ),
                    clearAllItemsText: t(
                      "common.multiSelectTableTexts.clearAllSelection",
                    ),
                    clearSelectionText: t(
                      "common.multiSelectTableTexts.clearSelection",
                    ),
                    selectionText: t("common.multiSelectTableTexts.selection", {
                      selectedItemsCount:
                        manageBOUserStore.multiSelectTableStore.totalSelectedItems(
                          manageBOUserStore.totalItems,
                        ),
                    }),
                    shouldPinSelectionColumn: true,
                    store: manageBOUserStore.multiSelectTableStore,
                    actionElements: isManageUserPane
                      ? undefined
                      : [
                          {
                            label: t("common.remove"),
                            name: t("common.remove"),
                            onClick: (): void => {
                              setIsRemoveProjectUserDialogOpen(true);
                            },
                            variant: "outlined-color",
                            color: "destructive",
                          },
                        ],
                  }
                : undefined
            }
          />
        </Stack>
      </Stack>
    );
  },
);
