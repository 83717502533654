import { Instance, types } from "mobx-state-tree";

export const SurveyIdAndNameModel = types.model("SurveyIdAndNameModel", {
  surveyId: types.string,
  surveyName: types.string,
});

export const createSurveyIdAndNameModel = (
  surveyId: string,
  surveyName: string,
): Instance<typeof SurveyIdAndNameModel> => {
  return SurveyIdAndNameModel.create({
    surveyId,
    surveyName,
  });
};
