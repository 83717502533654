import { Radio } from "@mui/material";
import {
  FoundationColorTokens,
  RadioButtonColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";

export interface SingleSelectOptionRadioButtonProps {
  value: number;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  radioButtonTokens: RadioButtonColorTokens<string>;
  tokens: FoundationColorTokens<string>;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SingleSelectOptionRadioButton = observer(
  ({
    value,
    checked,
    onChange,
    onClick,
    tokens,
    radioButtonTokens,
  }: SingleSelectOptionRadioButtonProps): React.ReactElement => {
    return (
      <Radio
        value={value}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        sx={{
          maxWidth: "40px",
          alignItems: "start",
          color: tokens.iconPrimary,
          "&.Mui-checked": {
            color: tokens.iconPrimary,
            "&.Mui-hovered": {
              color: radioButtonTokens.radioButtonIconHovered,
            },
          },
        }}
        disableRipple={true}
      />
    );
  },
);
