import {
  Instance,
  cast,
  getParent,
  getParentOfType,
  types,
} from "mobx-state-tree";
import {
  EMPTY_CHARACTER,
  GridSingleSelectQuestionV1Model,
  SINGLE_SELECT_NPS_OPTIONS_COUNT,
  MAX_SINGLE_CHOICE_OPTIONS,
  MIN_SINGLE_CHOICE_OPTIONS,
  PresetScales,
  QuestionOptionsModel,
  createGridSingleSelectQuestionV1Model,
  doesCodeContainAllowedCharacters,
  MAXIMUM_GRID_COLUMN_OPTIONS,
} from "@pulse/shared-components";
import { getLocalizedSingleChoiceQuestionOptions } from "../../utils/getLocalizedSingleChoiceQuestionOptions";
import { TFunction } from "i18next";
import {
  FieldValidations,
  QuestionDetailsStore,
} from "../store/QuestionDetailsStore";
import { GridColumnQuestionV1Model } from "./GridColumnQuestionV1Model";
import { SingleChoicePresets } from "../store/SingleChoiceQuestionV1Store";
import { getGridSingleChoiceDisplayTypeEnum } from "@pulse/shared-components";

export const GridSingleChoiceQuestionV1Model = types
  .model("GridSingleChoiceQuestionV1Model", {
    gridSingleSelectQuestionV1Model: GridSingleSelectQuestionV1Model,
  })
  .views((store) => ({
    get isResetDisabled(): boolean {
      if (
        store.gridSingleSelectQuestionV1Model.options.length ===
        MIN_SINGLE_CHOICE_OPTIONS
      ) {
        return (
          store.gridSingleSelectQuestionV1Model.options[0].code ===
            EMPTY_CHARACTER &&
          store.gridSingleSelectQuestionV1Model.options[0].option ===
            EMPTY_CHARACTER
        );
      }
      return false;
    },
    get isAddNewFieldButtonDisabled(): boolean {
      return (
        store.gridSingleSelectQuestionV1Model.options.length >=
          MAX_SINGLE_CHOICE_OPTIONS ||
        (store.gridSingleSelectQuestionV1Model.isNPS &&
          store.gridSingleSelectQuestionV1Model.options.length >=
            SINGLE_SELECT_NPS_OPTIONS_COUNT) ||
        ((store.gridSingleSelectQuestionV1Model.isRadio ||
          store.gridSingleSelectQuestionV1Model.isSliderOrScale) &&
          store.gridSingleSelectQuestionV1Model.options.length >=
            MAXIMUM_GRID_COLUMN_OPTIONS)
      );
    },
    isChevronDownDisabled(index: number): boolean {
      return index === store.gridSingleSelectQuestionV1Model.options.length - 1;
    },
    get isDeleteButtonDisabled(): boolean {
      return (
        store.gridSingleSelectQuestionV1Model.options.length ===
          MIN_SINGLE_CHOICE_OPTIONS ||
        store.gridSingleSelectQuestionV1Model.isNPS
      );
    },
    isChevronUpDisabled(index: number): boolean {
      return index === 0;
    },
    doesOptionCodeContainErrors(index: number): boolean {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );
      return (
        (store.gridSingleSelectQuestionV1Model.options[index].code.trim() ===
          EMPTY_CHARACTER &&
          questionDetailsStore.fieldValidations ===
            FieldValidations.UnfilledOptionField) ||
        store.gridSingleSelectQuestionV1Model.options[index].code.trim() ===
          questionDetailsStore.duplicateOptionCode
      );
    },
    get doesNAOptionTextContainErrors(): boolean {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );
      return (
        store.gridSingleSelectQuestionV1Model.NAOption.naOptionString.trim() ===
          EMPTY_CHARACTER &&
        questionDetailsStore.fieldValidations ===
          FieldValidations.UnfilledOptionField
      );
    },
    get doesNAOptionCodeContainErrors(): boolean {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );
      return (
        (store.gridSingleSelectQuestionV1Model.NAOption.naOptionCode.trim() ===
          EMPTY_CHARACTER &&
          questionDetailsStore.fieldValidations ===
            FieldValidations.UnfilledOptionField) ||
        store.gridSingleSelectQuestionV1Model.NAOption.naOptionCode.trim() ===
          questionDetailsStore.duplicateOptionCode
      );
    },
  }))

  .actions((store) => ({
    resetFieldValidationsAndErrors: (): void => {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );
      questionDetailsStore.setFieldValidations(null);
      questionDetailsStore.setRPCErrors(null);
      questionDetailsStore.setDuplicateOptionCode(null);
    },
  }))
  .actions((store) => ({
    resetOptions: (): void => {
      store.gridSingleSelectQuestionV1Model.options = cast([
        {
          code: EMPTY_CHARACTER,
          option: EMPTY_CHARACTER,
        },
      ]);
      store.gridSingleSelectQuestionV1Model.defaultOptionIndex = undefined;
      store.resetFieldValidationsAndErrors();
    },
    setDefaultOptionIndex: (index: number | undefined): void => {
      store.gridSingleSelectQuestionV1Model.defaultOptionIndex = index;
    },
  }))
  .actions((store) => ({
    setOptionsBasedOnPreset: (t: TFunction): void => {
      const gridColumnQuestionModel = <
        Instance<typeof GridColumnQuestionV1Model>
      >getParent(store);
      if (gridColumnQuestionModel.singleChoicePresetType === undefined) {
        store.resetOptions();
      } else {
        const singleChoicePresetValues =
          getLocalizedSingleChoiceQuestionOptions(
            t,
            gridColumnQuestionModel.singleChoicePresetType,
            gridColumnQuestionModel.singleChoicePresetScale,
          );
        store.gridSingleSelectQuestionV1Model.options = cast(
          singleChoicePresetValues?.map((option, index) => {
            return {
              option,
              code: (index + 1).toString(),
            };
          }),
        );
      }
    },
    setNPSSelection: (npsSelection: boolean): void => {
      store.gridSingleSelectQuestionV1Model.isNPSSelectionColored =
        npsSelection;
    },
    setFieldOptionText: (fieldOptionText: string, index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.gridSingleSelectQuestionV1Model.options[index].option =
        fieldOptionText;
    },
    setCode: (code: string, index: number): void => {
      if (doesCodeContainAllowedCharacters(code)) {
        store.resetFieldValidationsAndErrors();
        store.gridSingleSelectQuestionV1Model.options[index].code = code;
      }
    },
    addNewField: (
      singleChoiceOptionField: Instance<typeof QuestionOptionsModel>,
      index: number,
    ): void => {
      store.resetFieldValidationsAndErrors();
      store.gridSingleSelectQuestionV1Model.options.splice(
        index + 1,
        0,
        singleChoiceOptionField,
      );
      if (
        store.gridSingleSelectQuestionV1Model.defaultOptionIndex !==
          undefined &&
        store.gridSingleSelectQuestionV1Model.defaultOptionIndex > index
      ) {
        store.setDefaultOptionIndex(
          store.gridSingleSelectQuestionV1Model.defaultOptionIndex + 1,
        );
      }
    },
    moveFieldUp: (index: number): void => {
      const singleChoiceOptionFieldArrayCopy = [
        ...store.gridSingleSelectQuestionV1Model.options,
      ];
      const replacedElement = singleChoiceOptionFieldArrayCopy[index - 1];
      singleChoiceOptionFieldArrayCopy[index - 1] =
        singleChoiceOptionFieldArrayCopy[index];
      singleChoiceOptionFieldArrayCopy[index] = replacedElement;
      store.gridSingleSelectQuestionV1Model.options.replace(
        singleChoiceOptionFieldArrayCopy,
      );
      if (store.gridSingleSelectQuestionV1Model.defaultOptionIndex === index) {
        store.setDefaultOptionIndex(index - 1);
      } else if (
        store.gridSingleSelectQuestionV1Model.defaultOptionIndex ===
        index - 1
      ) {
        store.setDefaultOptionIndex(index);
      }
    },
    moveFieldDown: (index: number): void => {
      const openEndedFieldArrayCopy = [
        ...store.gridSingleSelectQuestionV1Model.options,
      ];
      const replacedElement = openEndedFieldArrayCopy[index + 1];
      openEndedFieldArrayCopy[index + 1] = openEndedFieldArrayCopy[index];
      openEndedFieldArrayCopy[index] = replacedElement;
      store.gridSingleSelectQuestionV1Model.options.replace(
        openEndedFieldArrayCopy,
      );
      if (store.gridSingleSelectQuestionV1Model.defaultOptionIndex === index) {
        store.setDefaultOptionIndex(index + 1);
      } else if (
        store.gridSingleSelectQuestionV1Model.defaultOptionIndex ===
        index + 1
      ) {
        store.setDefaultOptionIndex(index);
      }
    },
    removeField: (index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.gridSingleSelectQuestionV1Model.options.splice(index, 1);
      if (store.gridSingleSelectQuestionV1Model.defaultOptionIndex === index) {
        store.setDefaultOptionIndex(undefined);
      }
      if (
        store.gridSingleSelectQuestionV1Model.defaultOptionIndex !==
          undefined &&
        store.gridSingleSelectQuestionV1Model.defaultOptionIndex > index
      ) {
        store.setDefaultOptionIndex(
          store.gridSingleSelectQuestionV1Model.defaultOptionIndex - 1,
        );
      }
    },
    validateFields: (): void => {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );

      if (
        store.gridSingleSelectQuestionV1Model.options.length >
        MAXIMUM_GRID_COLUMN_OPTIONS
      ) {
        if (store.gridSingleSelectQuestionV1Model.isRadio) {
          questionDetailsStore.setFieldValidations(
            FieldValidations.MaxGridRadioButtonCountReached,
          );
          return;
        } else if (store.gridSingleSelectQuestionV1Model.isSliderOrScale) {
          questionDetailsStore.setFieldValidations(
            FieldValidations.MaxSliderRatingScaleCountReached,
          );
          return;
        }
      }

      if (store.gridSingleSelectQuestionV1Model.isNPS) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.InvalidGridNPSSelected,
        );
        return;
      }

      store.gridSingleSelectQuestionV1Model.options.forEach((option) => {
        if (option.code.trim() === EMPTY_CHARACTER) {
          questionDetailsStore.setFieldValidations(
            FieldValidations.UnfilledOptionField,
          );
        }
      });

      if (store.gridSingleSelectQuestionV1Model.isSliderOrScale) {
        if (store.gridSingleSelectQuestionV1Model.NAOption.isNAOptionChecked) {
          if (
            store.gridSingleSelectQuestionV1Model.NAOption.naOptionCode ===
              EMPTY_CHARACTER ||
            store.gridSingleSelectQuestionV1Model.NAOption.naOptionString ===
              EMPTY_CHARACTER
          ) {
            questionDetailsStore.setFieldValidations(
              FieldValidations.UnfilledOptionField,
            );
          }
        }
      }
    },
    validateInitialOptionCodes(
      initialQuestionDetails: Instance<typeof store>,
    ): void {
      const questionDetailsStore = getParentOfType(store, QuestionDetailsStore);
      const updatedOptionCodes =
        store.gridSingleSelectQuestionV1Model.options.map(
          (singleSelectOption) => {
            return singleSelectOption.code.trim();
          },
        );

      const initialOptionCodes =
        initialQuestionDetails.gridSingleSelectQuestionV1Model.options.map(
          (singleSelectOption) => {
            return singleSelectOption.code;
          },
        );
      if (
        JSON.stringify(updatedOptionCodes) !==
          JSON.stringify(initialOptionCodes) ||
        store.gridSingleSelectQuestionV1Model.NAOption.naOptionCode.trim() !==
          initialQuestionDetails.gridSingleSelectQuestionV1Model.NAOption
            .naOptionCode
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.QuestionAndOptionCodesCannotBeUpdated,
        );
      }
    },
  }))
  .actions((store) => ({
    setSingleChoiceDisplayType: (
      singleChoiceDisplayType: string,
      t: TFunction,
    ): void => {
      store.gridSingleSelectQuestionV1Model.singleChoiceDisplayType =
        getGridSingleChoiceDisplayTypeEnum(singleChoiceDisplayType);
      const gridColumnQuestionModel = <
        Instance<typeof GridColumnQuestionV1Model>
      >getParentOfType(store, GridColumnQuestionV1Model);
      if (store.gridSingleSelectQuestionV1Model.isNPS) {
        gridColumnQuestionModel.setSingleChoicePresetScale(
          PresetScales.NpsPresetScale,
        );
        gridColumnQuestionModel.setSingleChoicePresetType(
          SingleChoicePresets.Nps,
        );
        store.setOptionsBasedOnPreset(t);
      }
    },
  }));

export const createGridSingleChoiceQuestionV1Model = (
  gridSingleSelectQuestionV1Model: Instance<
    typeof GridSingleSelectQuestionV1Model
  > = createGridSingleSelectQuestionV1Model(),
): Instance<typeof GridSingleChoiceQuestionV1Model> => {
  return GridSingleChoiceQuestionV1Model.create({
    gridSingleSelectQuestionV1Model,
  });
};
