import { Stack, Typography } from "@mui/material";
import { getIconProps, ICON_SIZE } from "@pulse/shared-components";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { Info } from "lucide-react";
import { observer } from "mobx-react";
import React from "react";

interface InformationDialogContentProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  informationText: string;
  typography: LeoTypography;
}

export const InformationDialogContent = observer(
  ({
    spacing,
    tokens,
    informationText,
    typography,
  }: InformationDialogContentProps): React.ReactElement => {
    return (
      <Stack direction="row" gap={spacing.spaceXS} width="100%">
        <Info {...getIconProps(tokens.iconInfo, ICON_SIZE.default)} />
        <Typography
          sx={{
            ...typography.b2,
            color: tokens.labelInfo,
            width: "492px",
          }}
        >
          {informationText}
        </Typography>
      </Stack>
    );
  },
);
