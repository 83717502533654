import { observer } from "mobx-react";
import React from "react";
import { InformationDialogComponent } from "@pulse/shared-components";
import { useTranslation } from "react-i18next";
import { useRouteNavigator } from "../../../../routes/RoutesHelper";
import { useQuestionDetailsStore } from "../../store/hooks";
import { GetQuestionDetailsErrors } from "../../store/QuestionDetailsStore";

export const QuestionDetailsRPCErrorDialog = observer(
  (): React.ReactElement => {
    const { t } = useTranslation();
    const navigateTo = useRouteNavigator();
    const questionDetailsStore = useQuestionDetailsStore();

    switch (questionDetailsStore.rpcErrors) {
      case GetQuestionDetailsErrors.InvalidQuestionId: {
        return (
          <InformationDialogComponent
            isDialogOpen={questionDetailsStore.isRPCErrorDialogOpen}
            title={t("surveys.addSurveyQuestion.failedToFetchTitle")}
            description={t(
              "surveys.addSurveyQuestion.unexpectedErrorFetchDescription",
            )}
            buttonText={t("surveys.addSurveyQuestion.reload")}
            resolveButtonCallBack={(): void => {
              navigateTo.reload();
            }}
          />
        );
      }
      case GetQuestionDetailsErrors.QuestionWasDeleted: {
        return (
          <InformationDialogComponent
            isDialogOpen={questionDetailsStore.isRPCErrorDialogOpen}
            title={t("surveys.addSurveyQuestion.failedToFetchTitle")}
            description={t(
              "surveys.addSurveyQuestion.questionDeletedDescription",
            )}
            buttonText={t("surveys.addSurveyQuestion.reload")}
            resolveButtonCallBack={(): void => {
              navigateTo.reload();
            }}
          />
        );
      }
      default:
        return <></>;
    }
  },
);
