import { RespondentColumnDetails } from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";

export interface TableColumnHeader {
  columnId: string;
  label: string;
  width: string;
  sortable?: boolean;
}

export const RespondentColumnDetailsModel = types
  .model("RespondentColumnDetailsModel", {
    index: types.number,
    name: types.string,
  })
  .views((store) => ({
    get tableColumnHeader(): TableColumnHeader {
      return {
        columnId: `column${store.index}`,
        label: store.name,
        width: "280px",
      };
    },
  }));

export const createRespondentColumnDetailsModel = ({
  index,
  name,
}: RespondentColumnDetails): Instance<typeof RespondentColumnDetailsModel> => {
  return RespondentColumnDetailsModel.create({
    index,
    name: name.name,
  });
};
