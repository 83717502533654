import {
  EMPTY_CHARACTER,
  MAX_OPTION_TEXT_CHARACTER_LIMIT,
  ZERO_VALUE,
  showMaxCharacterCountIndicator,
} from "@pulse/shared-components";
import { TextInputField } from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import React from "react";

export interface SingleSelectOptionTextInputProps {
  value: string;
  onTextChange: (inputString: string) => void;
  t: TFunction;
  error: boolean;
  isDisabled: boolean;
  width: string | undefined;
}

export const SingleSelectOptionTextInput = observer(
  ({
    value,
    onTextChange,
    t,
    error,
    isDisabled,
    width,
  }: SingleSelectOptionTextInputProps): React.ReactElement => {
    return (
      <TextInputField
        width={width}
        minWidth={width}
        name="option"
        value={value}
        label={EMPTY_CHARACTER}
        error={error}
        placeholder={t("surveys.addSurveyQuestion.enterOption")}
        type="text"
        disabled={isDisabled}
        onTextChange={(inputValue) => {
          onTextChange(
            inputValue.slice(ZERO_VALUE, MAX_OPTION_TEXT_CHARACTER_LIMIT),
          );
        }}
        maxCharacterLength={MAX_OPTION_TEXT_CHARACTER_LIMIT}
        showCharacterCountIndicator={(): boolean =>
          showMaxCharacterCountIndicator(MAX_OPTION_TEXT_CHARACTER_LIMIT, value)
        }
      />
    );
  },
);
