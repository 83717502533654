import {
  FileAttributes,
  GetPresignedDocumentUploadURLForRespondentsRPC,
  GetPresignedDownloadURLForRespondentTemplateRPC,
  RespondentColumnName,
  ValidateUniqueColumnAndUpdateRespondentRPC,
} from "@pulse/pulse-rpcs";
import {
  Instance,
  cast,
  flow,
  getParentOfType,
  getRoot,
  types,
} from "mobx-state-tree";
import {
  FileUploadError,
  getFileSHA,
  uploadFileToS3,
  validateRespondentsCSVFile,
} from "../../utils/FileUploadUtils";
import {
  useGetPresignedDocumentUploadURLForRespondentsRPCClientImpl,
  useGetPresignedDownloadURLForRespondentTemplateRPCClient,
  useValidateUniqueColumnAndUpdateRespondentClientImpl,
} from "../rpcs/RPC";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  CSV_FILE_TYPE,
  CommonErrors,
  EMPTY_CHARACTER,
  MAX_FILE_NAME,
  MAX_RESPONDENTS_FILE_SIZE_IN_BYTES,
  MIN_FILE_NAME,
  MIN_FILE_SIZE_IN_BYTES,
  NetworkingError,
  RESPONDENT_CSV_TEMPLATE_NAME,
  SHA_256_LENGTH,
  TEMPLATE_FILE_DELIMITER,
  getDelimitedFileName,
} from "@pulse/shared-components";
import {
  downloadFile,
  insertDocumentRecord,
} from "../../utils/FileDownloadUtils";
import { getAPIClient } from "../../networking/APIClient";
import { RootStore } from "../../root/store/RootStore";
import { RespondentStore } from "./RespondentStore";

export enum ValidateUniqueColumnAndUpdateRespondentErrors {
  InvalidFile = "INVALID_FILE",
  UnknownFile = "UNKNOWN_FILE",
  InvalidUniqueColumnName = "INVALID_UNIQUE_COLUMN_NAME",
  RespondentUpdateInProgress = "RESPONDENT_UPDATE_IN_PROGRESS",
  ProjectArchived = "PROJECT_ARCHIVED",
  DuplicateRecordFound = "DUPLICATE_RECORD_FOUND",
  RespondentColumnLimitReached = "RESPONDENT_COLUMN_LIMIT_REACHED",
}

export enum GetPresignedDocumentUploadURLForRespondentsErrors {
  RespondentUpdateInProgress = "RESPONDENT_UPDATE_IN_PROGRESS",
}

export enum UploadType {
  First = "First",
  Subsequent = "Subsequent",
}

export enum RespondentUploadDialogState {
  initialState = "INITIAL_STATE",
  fileUploaded = "FILE_UPLOADED",
  fileValidated = "FILE_VALIDATED",
  templateFileDownloading = "TEMPLATE_FILE_DOWNLOADING",
}

export const RespondentUploadStore = types
  .model("RespondentUploadStore", {
    uploadType: types.optional(
      types.enumeration(Object.values(UploadType)),
      UploadType.First,
    ),
    fileName: types.maybe(types.string),
    documentId: types.maybe(types.string),
    respectUniqueColumn: types.optional(types.boolean, true),
    isRPCLoading: types.optional(types.boolean, false),
    uniqueColumnList: types.array(types.string),
    selectedUniqueColumn: types.optional(types.string, EMPTY_CHARACTER),
    validationErrors: types.maybeNull(
      types.enumeration(Object.values(FileUploadError)),
    ),
    rpcErrors: types.maybeNull(
      types.union(
        types.enumeration(
          Object.values(GetPresignedDocumentUploadURLForRespondentsErrors),
        ),
        types.enumeration(
          Object.values(ValidateUniqueColumnAndUpdateRespondentErrors),
        ),
        types.enumeration(Object.values(NetworkingError)),
      ),
    ),
    invalidFileDetails: types.maybeNull(types.string),
    respondentUploadDialogState: types.optional(
      types.enumeration(
        "RespondentUploadDialogState",
        Object.values(RespondentUploadDialogState),
      ),
      RespondentUploadDialogState.initialState,
    ),
    isRespondentUploadDialogVisible: types.optional(types.boolean, false),
    isDuplicateValidationDialogVisible: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get isFileValidated(): boolean {
      return (
        store.respondentUploadDialogState ===
        RespondentUploadDialogState.fileValidated
      );
    },
    get hasFileUploaded(): boolean {
      return (
        store.respondentUploadDialogState ===
        RespondentUploadDialogState.fileUploaded
      );
    },
    get doesStoreContainErrors(): boolean {
      return store.rpcErrors !== null;
    },
    get isFileInvalid(): boolean {
      return (
        store.rpcErrors ===
        ValidateUniqueColumnAndUpdateRespondentErrors.InvalidFile
      );
    },
    get isSelectedUniqueColumnEmpty(): boolean {
      return store.selectedUniqueColumn === EMPTY_CHARACTER;
    },
    get doesColumnContainDuplicates(): boolean {
      return (
        store.rpcErrors ===
        ValidateUniqueColumnAndUpdateRespondentErrors.DuplicateRecordFound
      );
    },
    get isUploadTypeFirst(): boolean {
      return store.uploadType === UploadType.First;
    },
  }))
  .actions((store) => ({
    setIsDuplicateValidationDialogVisible: (isDialogVisible: boolean): void => {
      store.isDuplicateValidationDialogVisible = isDialogVisible;
    },
    setIsRespondentUploadDialogVisible: (isDialogVisible: boolean): void => {
      store.isRespondentUploadDialogVisible = isDialogVisible;
    },
    resetRPCErrors: (): void => {
      store.rpcErrors = null;
    },
    resetValidationErrors: (): void => {
      store.validationErrors = null;
    },
    resetUploadCSVStates: (): void => {
      store.respondentUploadDialogState =
        RespondentUploadDialogState.initialState;
    },
    setSelectedUniqueColumn: (selectedUniqueColumn: string): void => {
      store.selectedUniqueColumn = selectedUniqueColumn;
    },
    setFileName: (fileName: string | undefined): void => {
      store.fileName = fileName;
    },
    setUploadType: (uploadType: UploadType): void => {
      store.uploadType = uploadType;
    },
    setRespectUniqueColumn: (respectUniqueColumn: boolean): void => {
      store.respectUniqueColumn = respectUniqueColumn;
    },
    getFileAttribute: flow(function* (file: File) {
      if (!CSV_FILE_TYPE.includes(file.type)) {
        store.validationErrors = FileUploadError.InvalidFileFormat;
        return;
      }
      const sha256 = yield getFileSHA(file);

      try {
        const csvColumns: string[] = yield validateRespondentsCSVFile(file)
          .then((csv) => csv)
          .catch((error) => {
            throw new Error(error);
          });
        store.uniqueColumnList = cast(csvColumns);
      } catch (error) {
        if (error instanceof Error) {
          switch (error.message) {
            case FileUploadError.BaseColumnsNotFound: {
              store.validationErrors = FileUploadError.BaseColumnsNotFound;
              return;
            }
            case FileUploadError.MaxColumnsExceeded: {
              store.validationErrors = FileUploadError.MaxColumnsExceeded;
              return;
            }
            case FileUploadError.MaxRowsExceeded: {
              store.validationErrors = FileUploadError.MaxRowsExceeded;
              return;
            }
          }
        }
      }

      try {
        if (sha256.length !== SHA_256_LENGTH) {
          store.validationErrors = FileUploadError.InvalidFileSHA;
          return;
        } else if (
          file.name.length < MIN_FILE_NAME ||
          file.name.length > MAX_FILE_NAME
        ) {
          store.validationErrors = FileUploadError.InvalidFileName;
          return;
        } else if (file.size > MAX_RESPONDENTS_FILE_SIZE_IN_BYTES) {
          store.validationErrors = FileUploadError.MaxFileSizeReached;
          return;
        } else if (file.size < MIN_FILE_SIZE_IN_BYTES) {
          store.validationErrors = FileUploadError.MinFileSizeNotReached;
          return;
        }

        const fileAttribute = new FileAttributes(sha256, file.name, file.size);
        return fileAttribute;
      } catch (error) {
        store.validationErrors = FileUploadError.InternalError;
      }
    }),
  }))
  .actions((store) => ({
    uploadCSVFile: flow(function* (file: File, projectId: string) {
      store.isRPCLoading = true;
      store.resetValidationErrors();
      store.resetUploadCSVStates();
      store.resetRPCErrors();
      try {
        const apiClient = getAPIClient(store);

        const fileAttribute: FileAttributes | undefined =
          yield store.getFileAttribute(file);

        if (fileAttribute === undefined) {
          return;
        }

        const {
          response,
          error,
        }: {
          response?: GetPresignedDocumentUploadURLForRespondentsRPC.Response;
          error?: GetPresignedDocumentUploadURLForRespondentsRPC.Errors.Errors;
        } = yield useGetPresignedDocumentUploadURLForRespondentsRPCClientImpl(
          apiClient,
        ).execute(
          new GetPresignedDocumentUploadURLForRespondentsRPC.Request(
            fileAttribute,
            new LeoUUID(projectId),
          ),
        );

        if (response) {
          try {
            yield uploadFileToS3(response.documentURL, file);
            store.documentId = yield insertDocumentRecord(
              fileAttribute,
              apiClient,
            );
            store.respondentUploadDialogState =
              RespondentUploadDialogState.fileUploaded;
          } catch (e) {
            store.respondentUploadDialogState =
              RespondentUploadDialogState.initialState;
            store.validationErrors = FileUploadError.InternalError;
          }
        } else if (error) {
          switch (error.code) {
            case GetPresignedDocumentUploadURLForRespondentsErrors.RespondentUpdateInProgress: {
              store.rpcErrors =
                GetPresignedDocumentUploadURLForRespondentsErrors.RespondentUpdateInProgress;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    validateUniqueColumnAndUpdateRespondent: flow(function* (
      projectId: string,
      isLiveRespondentsSelected,
    ) {
      store.validationErrors = null;
      store.rpcErrors = null;
      store.isRPCLoading = true;
      store.resetUploadCSVStates();

      try {
        const apiClient = getAPIClient(store);

        let uploadType:
          | ValidateUniqueColumnAndUpdateRespondentRPC.RequestEnums.UploadType.First
          | ValidateUniqueColumnAndUpdateRespondentRPC.RequestEnums.UploadType.Subsequent;
        switch (store.uploadType) {
          case UploadType.First: {
            uploadType =
              new ValidateUniqueColumnAndUpdateRespondentRPC.RequestEnums.UploadType.First(
                new RespondentColumnName(store.selectedUniqueColumn),
              );
            break;
          }
          case UploadType.Subsequent: {
            uploadType =
              new ValidateUniqueColumnAndUpdateRespondentRPC.RequestEnums.UploadType.Subsequent();
            break;
          }
        }

        const request = new ValidateUniqueColumnAndUpdateRespondentRPC.Request(
          uploadType,
          new LeoUUID(projectId),
          new LeoUUID(store.documentId),
          isLiveRespondentsSelected,
          store.respectUniqueColumn,
        );
        const {
          response,
          error,
        }: {
          response?: ValidateUniqueColumnAndUpdateRespondentRPC.Response;
          error?: ValidateUniqueColumnAndUpdateRespondentRPC.Errors.Errors;
        } =
          yield useValidateUniqueColumnAndUpdateRespondentClientImpl(
            apiClient,
          ).execute(request);

        if (response) {
          const respondentStore = getParentOfType(store, RespondentStore);
          respondentStore.setIsRespondentUpdateStatusInProgress(true);
        }
        if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              break;
            case ValidateUniqueColumnAndUpdateRespondentErrors.DuplicateRecordFound: {
              store.rpcErrors =
                ValidateUniqueColumnAndUpdateRespondentErrors.DuplicateRecordFound;
              break;
            }
            case ValidateUniqueColumnAndUpdateRespondentErrors.InvalidFile: {
              if (
                error instanceof
                ValidateUniqueColumnAndUpdateRespondentRPC.Errors.InvalidFile
              ) {
                store.invalidFileDetails = error.invalidFileDetails;
              }
              store.rpcErrors =
                ValidateUniqueColumnAndUpdateRespondentErrors.InvalidFile;
              break;
            }
            case ValidateUniqueColumnAndUpdateRespondentErrors.InvalidUniqueColumnName: {
              store.rpcErrors =
                ValidateUniqueColumnAndUpdateRespondentErrors.InvalidUniqueColumnName;
              break;
            }
            case ValidateUniqueColumnAndUpdateRespondentErrors.ProjectArchived: {
              store.rpcErrors =
                ValidateUniqueColumnAndUpdateRespondentErrors.ProjectArchived;
              break;
            }
            case ValidateUniqueColumnAndUpdateRespondentErrors.RespondentUpdateInProgress: {
              store.rpcErrors =
                ValidateUniqueColumnAndUpdateRespondentErrors.RespondentUpdateInProgress;
              break;
            }
            case ValidateUniqueColumnAndUpdateRespondentErrors.UnknownFile: {
              store.rpcErrors =
                ValidateUniqueColumnAndUpdateRespondentErrors.UnknownFile;
              break;
            }
            case ValidateUniqueColumnAndUpdateRespondentErrors.RespondentColumnLimitReached: {
              store.rpcErrors =
                ValidateUniqueColumnAndUpdateRespondentErrors.RespondentColumnLimitReached;
              break;
            }
          }
        }
      } catch (e) {
        // If there is an error, we need to set the duplicate validation dialog to not be visible, since it will go to the success state once the RPC is called.
        store.setIsDuplicateValidationDialogVisible(false);
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        store.respondentUploadDialogState =
          RespondentUploadDialogState.fileValidated;
      }
    }),
    downloadRespondentTemplate: flow(function* (
      projectId: string,
      projectName: string,
    ) {
      store.respondentUploadDialogState =
        RespondentUploadDialogState.templateFileDownloading;
      try {
        const apiClient = getAPIClient(store);
        const request =
          new GetPresignedDownloadURLForRespondentTemplateRPC.Request(
            new LeoUUID(projectId),
          );
        const {
          response,
          error,
        }: {
          response?: GetPresignedDownloadURLForRespondentTemplateRPC.Response;
          error?: GetPresignedDownloadURLForRespondentTemplateRPC.Errors.Errors;
        } =
          yield useGetPresignedDownloadURLForRespondentTemplateRPCClient(
            apiClient,
          ).execute(request);

        const fileName =
          getDelimitedFileName(projectName) +
          TEMPLATE_FILE_DELIMITER +
          RESPONDENT_CSV_TEMPLATE_NAME;

        if (response) {
          yield downloadFile(response.documentURL.href, fileName);
        }
        if (error) {
          store.rpcErrors = NetworkingError.InternalError;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.resetUploadCSVStates();
      }
    }),
  }));

export const createRespondentUploadStore = (): Instance<
  typeof RespondentUploadStore
> => {
  return RespondentUploadStore.create();
};
