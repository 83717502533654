import React, { PropsWithChildren, SetStateAction } from "react";
import { ListItemButton, Stack, Typography } from "@mui/material";
import {
  useTypography,
  useFoundationColorTokens,
  useSpacing,
  ChevronRight,
  ChevronDown,
  useCornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { QuestionType } from "@pulse/pulse-rpcs";
import {
  CHEVRON_ICON_SIZE,
  EMPTY_CHARACTER,
  getEllipsizedTextProperties,
  useBorder,
} from "@pulse/shared-components";
import { TFunction } from "i18next";
import { QuestionItemFooter } from "./QuestionItemFooter";
import { QuestionItemOrder } from "./QuestionItemOrder";
import { ChildQuestionItemsList } from "./ChildQuestionItemsList";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";
import { SurveyStore } from "../../store/SurveyStore";

export interface QuestionItemProps {
  question: string | null;
  questionCode: string;
  questionType: QuestionType.QuestionType;
  order: number;
  questionId: string;
  isRuleApplied: boolean;
  isVisibleByDefault: boolean;
}

export const QuestionItem = observer(
  ({
    questionItem,
    setSelectedItemQuestionId,
    setIsMessageBoxVisible,
    setMessageBoxQuestionItemId,
    t,
    selectedQuestionItemId,
    surveyStore,
    questionDetails,
  }: {
    questionItem: QuestionItemProps;
    setSelectedItemQuestionId: (value: SetStateAction<string | null>) => void;
    setIsMessageBoxVisible: (value: SetStateAction<boolean>) => void;
    setMessageBoxQuestionItemId: (value: SetStateAction<string | null>) => void;
    t: TFunction;
    selectedQuestionItemId: string | null;
    surveyStore: Instance<typeof SurveyStore>;
    questionDetails: Instance<typeof QuestionDetailsStore>;
  }): React.ReactElement => {
    const typography = useTypography();
    const tokens = useFoundationColorTokens();
    const spacing = useSpacing();
    const border = useBorder();
    const cornerRadius = useCornerRadius();
    const isItemSelected =
      selectedQuestionItemId === questionItem.questionId ||
      (questionItem.questionId === EMPTY_CHARACTER &&
        surveyStore.isNewlyAddedQuestionPresent);
    // A group question must be expanded in 2 cases:
    // - When a child question inside the group had been selected.
    // - When the group question itself was selected and it contains atleast 1 child.
    const isGroupQuestionExpanded =
      surveyStore.isChildQuestionSelected(
        questionItem.questionId,
        selectedQuestionItemId,
      ) ||
      (questionItem.questionType === QuestionType.QuestionType.GROUP &&
        isItemSelected &&
        !questionDetails.isChildQuestionListEmpty);

    const QuestionListItemButton = observer(
      ({ children }: PropsWithChildren): React.ReactElement => {
        return (
          <ListItemButton
            onClick={(): void => {
              if (!questionDetails.isSaveButtonDisabled) {
                setMessageBoxQuestionItemId(questionItem.questionId);
                setIsMessageBoxVisible(true);
              } else {
                setSelectedItemQuestionId(questionItem.questionId);
                if (questionItem.questionId !== EMPTY_CHARACTER) {
                  questionDetails.getQuestionDetails(
                    questionItem.questionId,
                    t,
                  );
                }
              }
            }}
            sx={{ padding: "0px", flexGrow: "unset" }}
          >
            {children}
          </ListItemButton>
        );
      },
    );

    const QuestionItemComponent = observer((): React.ReactElement => {
      const ChevronRightIcon = (): React.ReactElement => {
        return (
          <ChevronRight
            width={CHEVRON_ICON_SIZE}
            height={CHEVRON_ICON_SIZE}
            color={tokens.icon}
          />
        );
      };

      return (
        <>
          <Stack
            padding={`${spacing.spaceMD} ${spacing.spaceXL}`}
            width={"100%"}
            minHeight="140px"
            justifyContent="space-between"
            gap={spacing.spaceXXS}
            borderBottom={border.default}
            sx={{
              background: isItemSelected
                ? tokens.backgroundPrimarySubtle
                : tokens.backgroundElevatedLevel1,
              "&:hover": {
                background: !isItemSelected
                  ? tokens.backgroundHovered
                  : undefined,
              },
            }}
          >
            <Stack
              direction={"row"}
              gap={spacing.spaceXS}
              alignSelf="stretch"
              alignItems="center"
            >
              <QuestionItemOrder
                typography={typography}
                tokens={tokens}
                border={border}
                order={questionItem.order}
                isItemSelected={isItemSelected}
                spacing={spacing}
                cornerRadius={cornerRadius}
              />
              <Typography
                sx={{
                  ...typography.b2,
                  color: tokens.labelLowEmphasis,
                  ...getEllipsizedTextProperties(),
                  flex: 1,
                }}
              >
                {questionItem.questionCode}
              </Typography>
              {questionItem.questionType === QuestionType.QuestionType.GROUP ? (
                isGroupQuestionExpanded ? (
                  <ChevronDown
                    width={CHEVRON_ICON_SIZE}
                    height={CHEVRON_ICON_SIZE}
                    color={tokens.icon}
                  />
                ) : (
                  <ChevronRightIcon />
                )
              ) : (
                <></>
              )}
            </Stack>
            <Stack>
              {questionItem.question && (
                <Typography
                  sx={{
                    ...typography.s1,
                    ...getEllipsizedTextProperties(2),
                    color: tokens.label,
                    wordBreak: "break-word",
                  }}
                >
                  {questionItem.question}
                </Typography>
              )}
            </Stack>
            <QuestionItemFooter
              tokens={tokens}
              typography={typography}
              questionItem={questionItem}
              t={t}
              spacing={spacing}
            />
          </Stack>
        </>
      );
    });

    const QuestionItemListComponent = observer((): React.ReactElement => {
      if (isItemSelected) {
        return <QuestionItemComponent />;
      } else {
        return (
          <QuestionListItemButton>
            <QuestionItemComponent />
          </QuestionListItemButton>
        );
      }
    });

    if (!surveyStore.isChildQuestion(questionItem.questionId)) {
      return (
        <>
          <QuestionItemListComponent />
          {isGroupQuestionExpanded && (
            <ChildQuestionItemsList
              questionDetails={questionDetails}
              surveyStore={surveyStore}
              questionItem={questionItem}
              setSelectedItemQuestionId={setSelectedItemQuestionId}
              setIsMessageBoxVisible={setIsMessageBoxVisible}
              setMessageBoxQuestionItemId={setMessageBoxQuestionItemId}
              tokens={tokens}
              t={t}
              typography={typography}
              selectedQuestionItemId={selectedQuestionItemId}
              border={border}
            />
          )}
        </>
      );
    } else {
      return <></>;
    }
  },
);
