import {
  Dialog,
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React from "react";
import {
  ChangePasswordDialogState,
  ChangeSpocPasswordRPCError,
  CommonRPCError,
  UserAccessStore,
} from "../../../store/UserAccessStore";
import { Instance } from "mobx-state-tree";
import { ChangePasswordChild } from "./ChangePasswordChild";
import {
  NavigateToFunctions,
  processSPoCParams,
} from "../../../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { DialogErrorContent } from "../../../../../components/DialogErrorContent";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { DialogSuccessContent } from "../../../../../components/DialogSuccessContent";

interface ChangePasswordDialogProps {
  isChangePasswordDialogOpen: boolean;
  setIsChangePasswordDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  userAccessStore: Instance<typeof UserAccessStore>;
  spacing: Spacing;
  typography: LeoTypography;
  navigateTo: NavigateToFunctions;
  tokens: FoundationColorTokens<string>;
}

export const ChangePasswordDialog = observer(
  ({
    isChangePasswordDialogOpen,
    setIsChangePasswordDialogOpen,
    t,
    userAccessStore,
    spacing,
    typography,
    navigateTo,
    tokens,
  }: ChangePasswordDialogProps): React.ReactElement => {
    const getPrimaryButtonText = (): string | undefined => {
      return userAccessStore.isChangeSPoCPasswordDialogState
        ? t("projects.spocRespondent.settings.changePassword")
        : undefined;
    };

    const getSecondaryButtonText = (): string => {
      switch (userAccessStore.changePasswordDialogState) {
        case ChangePasswordDialogState.ChangePasswordState: {
          return t("common.cancel");
        }
        case ChangePasswordDialogState.ErrorState: {
          return t("common.close");
        }
        case ChangePasswordDialogState.SuccessState: {
          return t("common.done");
        }
      }
    };

    const getErrorText = (): string => {
      switch (userAccessStore.rpcErrors) {
        case CommonRPCError.ProjectAlreadyArchived: {
          return t(
            "projects.spocRespondent.settings.projectAlreadyArchivedErrorText",
          );
        }
        case ChangeSpocPasswordRPCError.UnableToChangePassword: {
          return t(
            "projects.spocRespondent.settings.unexpectedErrorWhileChangingPassword",
          );
        }
        default: {
          return EMPTY_CHARACTER;
        }
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (userAccessStore.changePasswordDialogState) {
        case ChangePasswordDialogState.ChangePasswordState: {
          return (
            <ChangePasswordChild
              spacing={spacing}
              userAccessStore={userAccessStore}
              t={t}
              typography={typography}
            />
          );
        }
        case ChangePasswordDialogState.ErrorState: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
        case ChangePasswordDialogState.SuccessState: {
          return (
            <DialogSuccessContent
              spacing={spacing}
              typography={typography}
              tokens={tokens}
              description={t(
                "projects.spocRespondent.settings.passwordChangedSuccessfully",
              )}
            />
          );
        }
      }
    };

    return (
      <Dialog
        open={isChangePasswordDialogOpen}
        title={t("projects.spocRespondent.settings.changePassword")}
        primaryButtonText={getPrimaryButtonText()}
        isPrimaryButtonDisabled={
          userAccessStore.isChangePasswordPrimaryButtonDisabled
        }
        onPrimaryButtonClick={async (): Promise<void> => {
          await processSPoCParams(
            userAccessStore.changeSPoCPassword,
            navigateTo,
          );
        }}
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={(): void => {
          setIsChangePasswordDialogOpen(false);
          userAccessStore.resetChangePasswordProps();
        }}
        isSecondaryButtonDisabled={
          userAccessStore.isChangeSPoCPasswordRPCLoading
        }
        contentPadding={spacing.spaceLG}
        disableBackdropClick={true}
      >
        {/*
        getDialogChild() does is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
