import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  CornerRadius,
  LoadingIndicator,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { TFunction } from "i18next";
import { Stack } from "@mui/material";
import { BorderStyle } from "@pulse/shared-components";
import { SignInStore } from "../../../auth/store/SignInStore";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";
import React, { useEffect } from "react";
import { EmbedView } from "./EmbedView";
import { SectionComponent } from "../SectionComponent";
import { EmbedTable } from "./EmbedTable";

export interface EmbedsPaneProps {
  navigateTo: NavigateToFunctions;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  border: BorderStyle;
  borderRadius: CornerRadius;
  signInStore: Instance<typeof SignInStore>;
  projectDetailsStore: Instance<typeof ProjectDetailsStore>;
  setIsSnackbarVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmbedsPane = observer(
  ({
    projectDetailsStore,
    navigateTo,
    tokens,
    t,
    spacing,
    border,
    typography,
    borderRadius,
    signInStore,
  }: EmbedsPaneProps): React.ReactElement => {
    const embedStore = projectDetailsStore.reportStore.embedStore;

    useEffect(() => {
      return () => {
        embedStore.clearSelectedEmbedDetails();
        embedStore.resetEmbedDetailsView();
      };
    }, []);

    return embedStore.isEmbedsPaneLoading ? (
      <LoadingIndicator isLoading={embedStore.isRPCLoading} variant="overlay" />
    ) : (
      <Stack
        padding={spacing.spaceXL}
        width="100%"
        height="100%"
        alignItems="center"
        gap={spacing.spaceXL}
      >
        <SectionComponent
          t={t}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          border={border}
          borderRadius={borderRadius}
          title={t("projects.reports.embeds.embedSection.embeddedReports")}
        >
          <EmbedTable
            projectDetailsStore={projectDetailsStore}
            t={t}
            navigateTo={navigateTo}
            tokens={tokens}
            typography={typography}
            signInStore={signInStore}
            spacing={spacing}
            border={border}
            borderRadius={borderRadius}
          />
        </SectionComponent>
        <EmbedView
          spacing={spacing}
          embedStore={embedStore}
          t={t}
          border={border}
          tokens={tokens}
          typography={typography}
        />
      </Stack>
    );
  },
);
