import React, { useEffect, useRef, useState } from "react";
import { DetailSectionComponent } from "../../../components/DetailSectionComponent";
import { useTranslation } from "react-i18next";
import { HeaderProps } from "../../../components/Header";
import {
  Button,
  TableReloadHandle,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Plus } from "lucide-react";
import { FeatureInDevelopmentSnackbar } from "@pulse/shared-components";
import { ManageBOUserPane } from "../../projects/components/userAccess/ManageBOUserPane";
import {
  useBOUserStore,
  useManageBOUserStore,
  useSignInStore,
} from "../../store/Hooks";
import { useRouteNavigator } from "../../../routes/RoutesHelper";
import { AddNewBOUserDialog } from "../components/AddNewBOuserDialog";
import { AddBOUserDialogState } from "../store/AddBOUserStore";
import { navigateOrGetNetworkErrorPage } from "../../../utility/navigateOrGetNetworkErrorPage";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { observer } from "mobx-react";

export const ManageUsers = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const manageBOUserStore = useManageBOUserStore();
  const navigateTo = useRouteNavigator();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const spacing = useSpacing();
  const signInStore = useSignInStore();
  const boUserStore = useBOUserStore();
  const [
    isFeatureInDevelopmentSnackbarVisible,
    setIsFeatureInDevelopmentSnackbarVisible,
  ] = useState(false);
  const [isAddNewBOUserDialogOpen, setIsAddNewBOUserDialogOpen] =
    useState(false);
  const tableRef = useRef<TableReloadHandle | null>(null);
  const errorStore = useNetworkingErrorStore();

  useEffect(() => {
    return () => {
      errorStore.removeError();
    };
  }, []);

  const errorPage = navigateOrGetNetworkErrorPage(
    errorStore.error,
    navigateTo,
    errorStore.removeError,
  );

  if (errorPage) {
    errorStore.removeError();
    return errorPage;
  }

  const AddNewBOUButton = (): React.ReactElement => {
    return (
      <Button
        name="addNewBOU"
        label={t("manageUsers.addNewBOUDialog.addNewBOU")}
        icon={<Plus />}
        iconPosition="leading"
        size="medium"
        variant="filled"
        onClick={async (): Promise<void> => {
          setIsAddNewBOUserDialogOpen(true);
          // Currently we are using enum for BO users roles rather it should be an API call to get all the roles which will be updated as part of this ticket: https://feedbackinsights.atlassian.net/browse/PUL2-839
          // await boUserStore.getRoles();
          boUserStore.addBOUserStore.setDialogState(
            AddBOUserDialogState.AddBOUser,
          );
        }}
      />
    );
  };

  const getHeaderProps = (): HeaderProps => {
    return {
      title: t("manageUsers.manageBOUTitle"),
      prefersLargeTitle: true,
      actionElement: <AddNewBOUButton />,
      backButtonCallback: (): void => {
        navigateTo.project();
      },
    };
  };

  return (
    <>
      {isAddNewBOUserDialogOpen && (
        <AddNewBOUserDialog
          isAddNewBOUserDialogOpen={isAddNewBOUserDialogOpen}
          setIsAddNewBOUserDialogOpen={setIsAddNewBOUserDialogOpen}
          spacing={spacing}
          t={t}
          boUserStore={boUserStore}
          tokens={tokens}
          typography={typography}
          tableRef={tableRef}
        />
      )}
      <DetailSectionComponent
        mainHeaderProps={getHeaderProps()}
        isOverflowHidden={false}
        isChildHeightVariable={false}
        isManageUsersLogoVisible={false}
        isAuditLogsLogoVisible={true}
      >
        {isFeatureInDevelopmentSnackbarVisible && (
          <FeatureInDevelopmentSnackbar
            isSnackbarVisible={isFeatureInDevelopmentSnackbarVisible}
            setIsSnackbarVisible={setIsFeatureInDevelopmentSnackbarVisible}
          />
        )}
        <ManageBOUserPane
          manageBOUserStore={manageBOUserStore}
          t={t}
          navigateTo={navigateTo}
          tokens={tokens}
          typography={typography}
          spacing={spacing}
          signInStore={signInStore}
          isManageUserPane={true}
          tableRef={tableRef}
          isMultiSelectEnabled={false}
          boUserStore={boUserStore}
        />
      </DetailSectionComponent>
    </>
  );
});
