import {
  Typography as LeoTypography,
  FoundationColorTokens,
  Spacing,
  Dialog,
  TableReloadHandle,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import React, { useEffect } from "react";
import { TFunction } from "i18next";
import { ErrorSuccessDialogContent } from "../ErrorSuccessDialogContent";
import {
  AddBOUserToProjectError,
  AddProjectBOUDialogState,
  AddProjectBOUStore,
} from "../../store/AddProjectBOUStore";
import { DialogLoaderContent } from "../DialogLoaderContent";
import { AddProjectBOUDialogChild } from "./AddProjectBOUDialogChild";
import { BorderStyle } from "@pulse/shared-components";
import { observer } from "mobx-react";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";

export interface AddProjectBOUDialogProps {
  isAddBOUDialogOpen: boolean;
  setIsAddBOUDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  spacing: Spacing;
  addNewSPoCStore: Instance<typeof AddProjectBOUStore>;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  navigateTo: NavigateToFunctions;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const AddProjectBOUDialog = observer(
  ({
    addNewSPoCStore,
    spacing,
    typography,
    t,
    tokens,
    border,
    isAddBOUDialogOpen,
    setIsAddBOUDialogOpen,
    navigateTo,
    tableRef,
  }: AddProjectBOUDialogProps): React.ReactElement => {
    useEffect(() => {
      return () => {
        addNewSPoCStore.resetAddProjectBOUDialog();
      };
    }, []);

    const getErrorText = (): string => {
      switch (addNewSPoCStore.rpcError) {
        case AddBOUserToProjectError.ProjectAlreadyArchived:
          return t("common.projectAlreadyArchivedErrorText");
        case AddBOUserToProjectError.BOUserAlreadyExists:
          return t(
            "projects.userAccess.addProjectBOUser.boUserAlreadyExistsErrorDescription",
          );
        case AddBOUserToProjectError.MaxBOUsersReached:
          return t(
            "projects.userAccess.addProjectBOUser.maxUsersReachedErrorDescription",
          );
        default:
          return t(
            "projects.userAccess.addProjectBOUser.addBOUserToProjectErrorText",
          );
      }
    };

    const getSecondaryButtonText = (): string => {
      switch (addNewSPoCStore.addProjectBOUDialogState) {
        case AddProjectBOUDialogState.FetchingBOUDetailsError:
        case AddProjectBOUDialogState.AddNewProjectBOUError:
          return t("common.close");
        case AddProjectBOUDialogState.AddNewProjectBOUSuccess:
          return t("common.dismiss");
        default:
          return t("common.cancel");
      }
    };

    const DialogChild = (): React.ReactElement => {
      switch (addNewSPoCStore.addProjectBOUDialogState) {
        case AddProjectBOUDialogState.FetchingBOUDetails:
          return (
            <DialogLoaderContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              loaderText={t(
                "projects.userAccess.addProjectBOUser.fetchingBOUsersText",
              )}
            />
          );
        case AddProjectBOUDialogState.FetchingBOUDetailsSuccess:
          return (
            <AddProjectBOUDialogChild
              t={t}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              border={border}
              addProjectBOUStore={addNewSPoCStore}
            />
          );
        case AddProjectBOUDialogState.FetchingBOUDetailsError:
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={t(
                "projects.userAccess.addProjectBOUser.fetchingBOUsersErrorText",
              )}
            />
          );
        case AddProjectBOUDialogState.AddNewProjectBOUSuccess:
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              isError={false}
              errorSuccessText={t(
                "projects.userAccess.addProjectBOUser.addBOUserToProjectSuccessText",
              )}
              typography={typography}
            />
          );
        case AddProjectBOUDialogState.AddNewProjectBOUError:
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={getErrorText()}
            />
          );
      }
    };
    return (
      <Dialog
        open={isAddBOUDialogOpen}
        title={t(
          "projects.userAccess.addProjectBOUser.addProjectBOUserDialogTitle",
        )}
        secondaryButtonText={getSecondaryButtonText()}
        isSecondaryButtonDisabled={addNewSPoCStore.isRPCLoading}
        onSecondaryButtonClick={() => {
          setIsAddBOUDialogOpen(false);
          addNewSPoCStore.resetAddProjectBOUDialog();
          tableRef.current?.reload();
        }}
        primaryButtonText={
          addNewSPoCStore.isAddProjectBOUDialogPrimaryButtonVisible
            ? t("projects.userAccess.addProjectBOUser.addButtonText")
            : undefined
        }
        isPrimaryButtonDisabled={addNewSPoCStore.isSelectedBOUserListEmpty}
        onPrimaryButtonClick={async (): Promise<void> => {
          await processProjectParams(
            addNewSPoCStore.addUsersToProject,
            navigateTo,
          );
        }}
        disableBackdropClick={true}
        contentPadding={"0px"}
        width="560px"
      >
        <DialogChild />
      </Dialog>
    );
  },
);
