import { EMPTY_CHARACTER, PresetScales } from "@pulse/shared-components";
import { TFunction } from "i18next";
import { SingleChoicePresets } from "../surveys/store/SingleChoiceQuestionV1Store";

const getMediumPresetValues = (presetValues: string[]): string[] => {
  const initialPresetValues = [...presetValues];
  initialPresetValues.splice(2, 1);
  initialPresetValues.splice(3, 1);
  return initialPresetValues;
};

const getModeratePresetValues = (presetValues: string[]): string[] => {
  const initialPresetValues = [...presetValues];
  // Since moderatePresetValues should have only 6 values out of the 7 presetValues, the neutral value is being removed which is present at index 3.
  initialPresetValues.splice(3, 1);
  return initialPresetValues;
};

export const getLocalizedSingleChoiceQuestionOptions = (
  t: TFunction,
  singleChoicePresetType: SingleChoicePresets,
  scale: number,
): string[] | undefined => {
  const npsOptions = Array(9).fill(EMPTY_CHARACTER);
  npsOptions.unshift(
    t("surveys.addSurveyQuestion.singleChoice.notAtAllLikely"),
  );
  npsOptions.push(t("surveys.addSurveyQuestion.singleChoice.extremelyLikely"));

  switch (singleChoicePresetType) {
    case SingleChoicePresets.GoodPoor: {
      return [
        t("surveys.addSurveyQuestion.singleChoice.poor"),
        t("surveys.addSurveyQuestion.singleChoice.fair"),
        t("surveys.addSurveyQuestion.singleChoice.good"),
      ];
    }
    case SingleChoicePresets.Value: {
      return [
        t("surveys.addSurveyQuestion.singleChoice.high"),
        t("surveys.addSurveyQuestion.singleChoice.medium"),
        t("surveys.addSurveyQuestion.singleChoice.low"),
      ];
    }
    case SingleChoicePresets.Agreement: {
      const agreementPresetValues = [
        t("surveys.addSurveyQuestion.singleChoice.stronglyDisagree"),
        t("surveys.addSurveyQuestion.singleChoice.disagree"),
        t("surveys.addSurveyQuestion.singleChoice.somewhatDisagree"),
        t("surveys.addSurveyQuestion.singleChoice.agreeNorDisagree"),
        t("surveys.addSurveyQuestion.singleChoice.somewhatAgree"),
        t("surveys.addSurveyQuestion.singleChoice.agree"),
        t("surveys.addSurveyQuestion.singleChoice.stronglyAgree"),
      ];
      switch (scale) {
        case PresetScales.ExtremePresetScale:
          return agreementPresetValues;
        case PresetScales.MediumPresetScale: {
          return getMediumPresetValues(agreementPresetValues);
        }
        case PresetScales.ModeratePresetScale:
          return getModeratePresetValues(agreementPresetValues);
      }
      break;
    }
    case SingleChoicePresets.Satisfaction: {
      const satisfactionPresetValues = [
        t("surveys.addSurveyQuestion.singleChoice.extremelyDissatisfied"),
        t("surveys.addSurveyQuestion.singleChoice.dissatisfied"),
        t("surveys.addSurveyQuestion.singleChoice.somewhatDissatisfied"),
        t("surveys.addSurveyQuestion.singleChoice.neutral"),
        t("surveys.addSurveyQuestion.singleChoice.somewhatSatisfied"),
        t("surveys.addSurveyQuestion.singleChoice.satisfied"),
        t("surveys.addSurveyQuestion.singleChoice.extremelySatisfied"),
      ];
      switch (scale) {
        case PresetScales.ExtremePresetScale:
          return satisfactionPresetValues;
        case PresetScales.MediumPresetScale: {
          return getMediumPresetValues(satisfactionPresetValues);
        }
        case PresetScales.ModeratePresetScale:
          return getModeratePresetValues(satisfactionPresetValues);
      }
      break;
    }
    case SingleChoicePresets.Likelihood: {
      const likelihoodPresetValues = [
        t("surveys.addSurveyQuestion.singleChoice.notAtAllLikely"),
        t("surveys.addSurveyQuestion.singleChoice.unlikely"),
        t("surveys.addSurveyQuestion.singleChoice.somewhatUnlikely"),
        t("surveys.addSurveyQuestion.singleChoice.neutral"),
        t("surveys.addSurveyQuestion.singleChoice.somewhatLikely"),
        t("surveys.addSurveyQuestion.singleChoice.likely"),
        t("surveys.addSurveyQuestion.singleChoice.extremelyLikely"),
      ];
      switch (scale) {
        case PresetScales.ExtremePresetScale:
          return likelihoodPresetValues;
        case PresetScales.MediumPresetScale: {
          return getMediumPresetValues(likelihoodPresetValues);
        }
        case PresetScales.ModeratePresetScale:
          return getModeratePresetValues(likelihoodPresetValues);
      }
      break;
    }
    case SingleChoicePresets.Willingness: {
      const willingnessPresetValues = [
        t("surveys.addSurveyQuestion.singleChoice.notAtAllWilling"),
        t("surveys.addSurveyQuestion.singleChoice.notWilling"),
        t("surveys.addSurveyQuestion.singleChoice.somewhatNotWilling"),
        t("surveys.addSurveyQuestion.singleChoice.neutral"),
        t("surveys.addSurveyQuestion.singleChoice.somewhatWilling"),
        t("surveys.addSurveyQuestion.singleChoice.willing"),
        t("surveys.addSurveyQuestion.singleChoice.extremelyWilling"),
      ];
      switch (scale) {
        case PresetScales.ExtremePresetScale:
          return willingnessPresetValues;
        case PresetScales.MediumPresetScale: {
          return getMediumPresetValues(willingnessPresetValues);
        }
        case PresetScales.ModeratePresetScale:
          return getModeratePresetValues(willingnessPresetValues);
      }
      break;
    }
    case SingleChoicePresets.Nps: {
      return npsOptions;
    }
  }
};
