import { observer } from "mobx-react";
import React from "react";
import { InformationDialogComponent } from "@pulse/shared-components";
import {
  processProjectParams,
  useRouteNavigator,
} from "../../../../routes/RoutesHelper";
import { EditSurveyQuestionErrors } from "../../store/QuestionDetailsStore";
import { useTranslation } from "react-i18next";
import { useQuestionDetailsStore } from "../../store/hooks";
import { getDefaultProjectDetailsTab } from "../../../projects/utils/ProjectDetailsTabUtils";

export const AddQuestionErrorDialog = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigateTo = useRouteNavigator();
  const questionDetailsStore = useQuestionDetailsStore();

  switch (questionDetailsStore.rpcErrors) {
    case EditSurveyQuestionErrors.JsonVersionNotSupported: {
      return (
        <InformationDialogComponent
          isDialogOpen={questionDetailsStore.isRPCErrorDialogOpen}
          title={t("surveys.addSurveyQuestion.failedToSaveTitle")}
          description={t(
            "surveys.addSurveyQuestion.unexpectedErrorDescription",
          )}
          buttonText={t("surveys.addSurveyQuestion.goToSurveys")}
          resolveButtonCallBack={(): void => {
            processProjectParams(async (projectId) => {
              navigateTo.projectDetails(
                projectId,
                getDefaultProjectDetailsTab(t, navigateTo),
              );
            }, navigateTo);
          }}
        />
      );
    }
    case EditSurveyQuestionErrors.SurveyIsClosed: {
      return (
        <InformationDialogComponent
          isDialogOpen={questionDetailsStore.isRPCErrorDialogOpen}
          title={t("surveys.addSurveyQuestion.failedToSaveTitle")}
          description={t("surveys.addSurveyQuestion.surveyClosedDescription")}
          buttonText={t("common.close")}
          resolveButtonCallBack={(): void => {
            questionDetailsStore.setRPCErrors(null);
          }}
        />
      );
    }
    case EditSurveyQuestionErrors.ProjectAlreadyArchived: {
      return (
        <InformationDialogComponent
          isDialogOpen={questionDetailsStore.isRPCErrorDialogOpen}
          title={t("surveys.addSurveyQuestion.failedToSaveTitle")}
          description={t(
            "surveys.addSurveyQuestion.projectArchivedDescription",
          )}
          buttonText={t("common.goToHome")}
          resolveButtonCallBack={(): void => {
            navigateTo.root();
          }}
        />
      );
    }
    case EditSurveyQuestionErrors.MaxQuestionsReached: {
      return (
        <InformationDialogComponent
          isDialogOpen={questionDetailsStore.isRPCErrorDialogOpen}
          title={t("surveys.addSurveyQuestion.failedToSaveTitle")}
          description={t(
            "surveys.addSurveyQuestion.maxQuestionsReachedDescription",
          )}
          buttonText={t("common.close")}
          resolveButtonCallBack={(): void => {
            questionDetailsStore.setRPCErrors(null);
          }}
        />
      );
    }
    default:
      return <></>;
  }
});
