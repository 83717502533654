import { Box, Stack, Typography } from "@mui/material";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { TFunction } from "i18next";
import { TextInputField } from "@surya-digital/leo-reactjs-material-ui";

interface RespondentDetailTextInputFieldProps {
  t: TFunction;
  label: string;
  defaultValue: string;
  maxInputLength: number;
  callBack: (inputValue: string) => void;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  isFieldDisabled: boolean;
  setIsEditRespondentErrored: (isEditRespondentErrored: boolean) => void;
}

// This component should not be inside an observer as the observer will rerender the component and the focus will be lost from the input field.
export const RespondentDetailTextInputField = ({
  t,
  label,
  defaultValue,
  maxInputLength,
  callBack,
  spacing,
  typography,
  tokens,
  isFieldDisabled,
  setIsEditRespondentErrored,
}: RespondentDetailTextInputFieldProps): React.ReactElement => {
  const doesCurrentInputValueContainErrors = (): boolean => {
    return defaultValue.length > maxInputLength;
  };

  return (
    <Stack flexDirection="row" gap={spacing.spaceSM} width="100%">
      {/* We need to keep the typography in a container otherwise it will underflow. */}
      <Box>
        <Typography
          {...typography.s1}
          color={tokens.labelHighEmphasis}
          width="240px"
        >
          {label}
        </Typography>
      </Box>
      <Stack width="100%">
        <TextInputField
          name={EMPTY_CHARACTER}
          placeholder={EMPTY_CHARACTER}
          value={defaultValue}
          type={"text"}
          onTextChange={function (value: string): void {
            callBack(value);
            if (value.length > maxInputLength) {
              setIsEditRespondentErrored(true);
            } else {
              setIsEditRespondentErrored(false);
            }
          }}
          label={EMPTY_CHARACTER}
          disabled={isFieldDisabled}
          error={doesCurrentInputValueContainErrors()}
          helperText={
            doesCurrentInputValueContainErrors()
              ? t(
                  "projects.respondentList.editRespondentDetailsDialog.inputFieldHelperText",
                  { label, maxInputLength },
                )
              : undefined
          }
        />
      </Stack>
    </Stack>
  );
};
