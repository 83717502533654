import { QuestionType } from "@pulse/pulse-rpcs";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { types, Instance } from "mobx-state-tree";

export const SurveyQuestionDetailsModel = types
  .model("SurveyQuestionDetailsModel", {
    questionId: types.string,
    questionCode: types.string,
    questionType: types.enumeration<QuestionType.QuestionType>(
      "QuestionType",
      Object.values(QuestionType.QuestionType),
    ),
    question: types.maybeNull(types.string),
    order: types.integer,
    parentQuestionId: types.maybeNull(types.string),
    childQuestionIds: types.array(types.string),
  })
  .views((store) => ({
    get isGroupQuestion(): boolean {
      return store.questionType === QuestionType.QuestionType.GROUP;
    },
    isProvidedQuestionIdSameAsCurrentQuestionId: (
      questionId: string,
    ): boolean => {
      return store.questionId === questionId;
    },
  }))
  .actions((store) => ({
    setQuestionType: (questionType: QuestionType.QuestionType): void => {
      store.questionType = questionType;
    },
    removeQuestionId: (): void => {
      store.questionId = EMPTY_CHARACTER;
    },
    setOrder: (order: number): void => {
      store.order = order;
    },
  }));

export type SurveyQuestionDetails = typeof SurveyQuestionDetailsModel.Type;

export const createSurveyQuestionDetailsModel = ({
  questionId,
  questionCode,
  questionType,
  question,
  order,
  parentQuestionId,
}: SurveyQuestionDetails): Instance<typeof SurveyQuestionDetailsModel> => {
  return SurveyQuestionDetailsModel.create({
    questionId,
    questionCode,
    questionType,
    question,
    order,
    parentQuestionId,
  });
};
