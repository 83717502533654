import { Stack, Typography } from "@mui/material";
import { IconContainer } from "@pulse/shared-components";
import {
  ListX,
  Plus,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { Button, useTypography } from "@surya-digital/leo-reactjs-material-ui";
import React from "react";

interface EmptyListPaneProps {
  headingText: string;
  subHeadingText?: string;
  helperButtonText?: string;
  helperButtonCallback?: () => void;
  helperButtonIcon?: React.ReactElement<React.SVGProps<SVGSVGElement>>;
}

export const EmptyListPane = ({
  headingText,
  subHeadingText,
  helperButtonText,
  helperButtonCallback,
  helperButtonIcon,
}: EmptyListPaneProps): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      height="100%"
    >
      {IconContainer(
        <ListX width="32px" height="32px" color={tokens.iconLowEmphasis} />,
      )}
      <Stack alignItems="center">
        <Typography {...typography.sh3} color={tokens.labelLowEmphasis}>
          {headingText}
        </Typography>
        {subHeadingText && (
          <Typography {...typography.b2} color={tokens.labelLowEmphasis}>
            {subHeadingText}
          </Typography>
        )}
      </Stack>
      {helperButtonCallback && helperButtonText && (
        <Button
          name="helperButton"
          label={helperButtonText}
          icon={helperButtonIcon ?? <Plus />}
          iconPosition="leading"
          variant="outlined-color"
          size="small"
          onClick={helperButtonCallback}
        />
      )}
    </Stack>
  );
};
