import { Stack } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { SingleSelectChoiceOptions } from "./SingleSelectChoiceOptions";
import {
  useFoundationColorTokens,
  useRadioButtonColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import {
  useQuestionDetailsStore,
  useSingleChoiceQuestionV1Store,
} from "../store/hooks";
import { ResetFieldsButton } from "./commonQuestionConfigurationDetailsComponents/ResetFieldsButton";
import { useBorder } from "@pulse/shared-components";
import { SingleSelectPresetPane } from "./singleSelectOptionComponents/SingleSelectPresetPane";

interface ChoiceOptionsPaneProps {
  setIsResetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsChoicePresetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChoiceOptionsPane = observer(
  ({
    setIsResetDialogOpen,
    setIsChoicePresetDialogOpen,
  }: ChoiceOptionsPaneProps): React.ReactElement => {
    const tokens = useFoundationColorTokens();
    const radioButtonTokens = useRadioButtonColorTokens();
    const singleChoiceStore = useSingleChoiceQuestionV1Store();
    const typography = useTypography();
    const spacing = useSpacing();
    const { t } = useTranslation();
    const questionDetailsStore = useQuestionDetailsStore();
    const border = useBorder();

    return (
      <Stack width="600px">
        <SingleSelectPresetPane
          spacing={spacing}
          border={border}
          t={t}
          typography={typography}
          setIsChoicePresetDialogOpen={setIsChoicePresetDialogOpen}
          isChoosePresetButtonDisabled={
            questionDetailsStore.updatedQuestionDetails
              .singleChoiceQuestionV1Store.singleSelectQuestionV1Model.isNPS
          }
        />
        <Stack spacing={spacing.spaceSM} mt={spacing.spaceLG}>
          {singleChoiceStore.singleSelectQuestionV1Model.options.map(
            (_field, index) => {
              return (
                <SingleSelectChoiceOptions
                  key={index}
                  index={index}
                  tokens={tokens}
                  singleChoiceStore={singleChoiceStore}
                  questionDetailsStore={questionDetailsStore}
                  t={t}
                  radioButtonTokens={radioButtonTokens}
                  spacing={spacing}
                />
              );
            },
          )}
        </Stack>
        <ResetFieldsButton
          isResetDisabled={singleChoiceStore.isResetDisabled}
          setIsResetDialogOpen={setIsResetDialogOpen}
          t={t}
          spacing={spacing}
        />
      </Stack>
    );
  },
);
