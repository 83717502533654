import {
  Spacing,
  FoundationColorTokens,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import { Box, Stack, Typography } from "@mui/material";
import { Button, Dialog } from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../../routes/RoutesHelper";
import { AlertCircle, HelpCircle } from "lucide-react";
import {
  EMPTY_CHARACTER,
  ICON_SIZE,
  getIconProps,
} from "@pulse/shared-components";
import {
  BackOfficeSurveySettingsStore,
  CloseSurveyRPCError,
  CommonProjectAlreadyArchivedRPCError,
  ReOpenSurveyRPCError,
} from "../../store/BackOfficeSurveySettingsStore";
import { SurveyStore } from "../../store/SurveyStore";

interface CloseReopenSurveySectionProps {
  backOfficeSurveySettingsStore: Instance<typeof BackOfficeSurveySettingsStore>;
  surveyStore: Instance<typeof SurveyStore>;
  navigateTo: NavigateToFunctions;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

export const CloseReopenSurveySection = observer(
  ({
    backOfficeSurveySettingsStore,
    surveyStore,
    navigateTo,
    t,
    spacing,
    typography,
    tokens,
  }: CloseReopenSurveySectionProps): React.ReactElement => {
    const [
      isCloseReOpenSurveyDialogVisible,
      setIsCloseReOpenSurveyDialogVisible,
    ] = useState(false);

    const getTitleText = (): string => {
      if (
        backOfficeSurveySettingsStore.rpcError ===
          CloseSurveyRPCError.SurveyIsNotActive ||
        (backOfficeSurveySettingsStore.rpcError ===
          CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived &&
          !surveyStore.isSurveyClosed)
      ) {
        return t(
          "surveys.settings.closeReOpenSurveySettings.failedToCloseSurvey",
        );
      } else if (
        backOfficeSurveySettingsStore.rpcError ===
          ReOpenSurveyRPCError.SurveyIsNotClosed ||
        (backOfficeSurveySettingsStore.rpcError ===
          CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived &&
          surveyStore.isSurveyClosed)
      ) {
        return t(
          "surveys.settings.closeReOpenSurveySettings.failedToReOpenSurvey",
        );
      } else if (backOfficeSurveySettingsStore.rpcError === null) {
        return surveyStore.isSurveyClosed
          ? t(
              "surveys.settings.closeReOpenSurveySettings.reOpenSurveyDialogTitle",
            )
          : t(
              "surveys.settings.closeReOpenSurveySettings.closeSurveyDialogTitle",
            );
      } else {
        console.error(
          `No title found for error ${backOfficeSurveySettingsStore.rpcError}`,
        );
        return EMPTY_CHARACTER;
      }
    };

    const getPrimarybuttonText = (): string | undefined => {
      if (backOfficeSurveySettingsStore.rpcError !== null) {
        return undefined;
      }
      return surveyStore.isSurveyClosed
        ? t("surveys.settings.closeReOpenSurveySettings.reOpenSurveyText")
        : t("surveys.settings.closeReOpenSurveySettings.closeSurveyButtonText");
    };

    const getSecondaryButtonText = (): string => {
      if (backOfficeSurveySettingsStore.rpcError !== null) {
        return t("common.close");
      }
      return t("common.cancel");
    };

    const buttonText = surveyStore.isSurveyClosed
      ? t("surveys.settings.closeReOpenSurveySettings.reOpenSurveyText")
      : t("surveys.settings.closeReOpenSurveySettings.closeSurveyButtonText");

    const dialogDescription = surveyStore.isSurveyClosed
      ? t(
          "surveys.settings.closeReOpenSurveySettings.reOpenSurveyDialogDescription",
        )
      : t(
          "surveys.settings.closeReOpenSurveySettings.closeSurveyDialogDescription",
        );

    const ErrorState = ({
      description,
    }: {
      description: string;
    }): React.ReactElement => {
      return (
        <Stack
          width="100%"
          alignItems="center"
          gap={spacing.spaceXS}
          padding={spacing.spaceXL}
        >
          <AlertCircle {...getIconProps(tokens.iconError, ICON_SIZE.large)} />
          <Typography
            sx={{
              color: tokens.labelError,
              textAlign: "center",
            }}
          >
            {description}
          </Typography>
        </Stack>
      );
    };

    const DialogChild = observer((): React.ReactElement => {
      switch (backOfficeSurveySettingsStore.rpcError) {
        case CloseSurveyRPCError.SurveyIsNotActive:
          return (
            <ErrorState
              description={t(
                "surveys.settings.closeReOpenSurveySettings.surveyIsNotActiveErrorMessage",
              )}
            />
          );
        case ReOpenSurveyRPCError.SurveyIsNotClosed:
          return (
            <ErrorState
              description={t(
                "surveys.settings.closeReOpenSurveySettings.surveyIsNotClosedErrorMessage",
              )}
            />
          );
        case CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived:
          return (
            <ErrorState
              description={t("surveys.settings.projectArchivedErrorMessage")}
            />
          );
        default:
          return (
            <Stack direction="row" width="100%" gap={spacing.spaceXS}>
              {/* Box component is added to preserve the width of the help circle icon.  */}
              <Box>
                <HelpCircle {...getIconProps(tokens.icon, ICON_SIZE.default)} />
              </Box>
              <Typography {...typography.b2} color={tokens.label} width="100%">
                {dialogDescription}
              </Typography>
            </Stack>
          );
      }
    });

    return (
      <SettingsContainer
        settingHeadingText={
          surveyStore.isSurveyClosed
            ? t(
                "surveys.settings.closeReOpenSurveySettings.reOpenSurveyTitleText",
              )
            : t(
                "surveys.settings.closeReOpenSurveySettings.closeSurveyTitleText",
              )
        }
        settingSubHeadingText={
          surveyStore.isSurveyClosed
            ? t(
                "surveys.settings.closeReOpenSurveySettings.reOpenSurveyDescriptionText",
              )
            : t(
                "surveys.settings.closeReOpenSurveySettings.closeSurveyDescriptionText",
              )
        }
        spacing={spacing}
        typography={typography}
        tokens={tokens}
      >
        <Stack width="fit-content">
          <Button
            label={buttonText}
            name="closeRepoenSurvey"
            size="medium"
            variant="outlined-color"
            disabled={surveyStore.isSurveyDraft}
            onClick={() => setIsCloseReOpenSurveyDialogVisible(true)}
          />
        </Stack>
        {isCloseReOpenSurveyDialogVisible && (
          <Dialog
            open={isCloseReOpenSurveyDialogVisible}
            title={getTitleText()}
            primaryButtonText={getPrimarybuttonText()}
            secondaryButtonText={getSecondaryButtonText()}
            onPrimaryButtonClick={async (): Promise<void> => {
              await processSurveyParams(
                surveyStore.isSurveyClosed
                  ? backOfficeSurveySettingsStore.reOpenSurvey
                  : backOfficeSurveySettingsStore.closeSurvey,
                navigateTo,
              );
            }}
            onSecondaryButtonClick={() => {
              backOfficeSurveySettingsStore.clearRPCError();
              setIsCloseReOpenSurveyDialogVisible(false);
            }}
            isPrimaryButtonDisabled={
              backOfficeSurveySettingsStore.isCloseReOpenSurveyRPCLoading
            }
            isSecondaryButtonDisabled={
              backOfficeSurveySettingsStore.isCloseReOpenSurveyRPCLoading
            }
            width="560px"
            contentPadding={spacing.spaceLG}
            disableBackdropClick={true}
          >
            <DialogChild />
          </Dialog>
        )}
      </SettingsContainer>
    );
  },
);
