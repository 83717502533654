import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { KeycloakProvider } from "keycloak-react-web";
import { keycloak } from "./modules/auth/keycloak/keycloak";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  <React.StrictMode>
    <KeycloakProvider
      client={keycloak}
      initOptions={{
        onLoad: "check-sso",
        checkLoginIframe: true,
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
        pkceMethod: "S256",
      }}
    >
      <App />
    </KeycloakProvider>
  </React.StrictMode>,
);
