import { observer } from "mobx-react";
import React from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import { TFunction } from "i18next";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { Stack, Typography } from "@mui/material";
import { Button } from "@surya-digital/leo-reactjs-material-ui";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../../routes/RoutesHelper";
import { UploadClientLogoError } from "../../store/UploadClientLogoStore";
import { Instance } from "mobx-state-tree";
import {
  BorderStyle,
  DARK_THEME_BACKGROUND_ELEVATED_LEVEL_1,
  JPG_FILE_EXTENSION,
  MAX_CLIENT_LOGO_FILE_SIZE_IN_MB,
  MIN_CLIENT_LOGO_FILE_SIZE_IN_KB,
  PNG_FILE_EXTENSION,
  BIG_SCREEN_CLIENT_LOGO_HEIGHT,
  getBigScreenClientLogoProps,
} from "@pulse/shared-components";
import { BackOfficeSurveySettingsStore } from "../../store/BackOfficeSurveySettingsStore";

interface ChangeLogoSettingsProps {
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  lightThemeLogoUrl: string | undefined;
  darkThemeLogoUrl: string | undefined | null;
  cornerRadius: CornerRadius;
  backOfficeSurveySettingsStore: Instance<typeof BackOfficeSurveySettingsStore>;
  navigateTo: NavigateToFunctions;
  border: BorderStyle;
  updateClientLightThemeLogoURL: (clientLightThemeLogoURL: string) => void;
  updateClientDarkThemeLogoURL: (clientDarkThemeLogoURL: string) => void;
  updateClientLightThemeLogoId: (clientLightThemeLogoId: string) => void;
  updateClientDarkThemeLogoId: (clientDarkThemeLogoId: string) => void;
  setIsErrorBannerVisible: (isErrorBannerVisible: boolean) => void;
}

export const ChangeLogoSettings = observer(
  ({
    t,
    spacing,
    typography,
    tokens,
    cornerRadius,
    darkThemeLogoUrl,
    lightThemeLogoUrl,
    backOfficeSurveySettingsStore,
    navigateTo,
    border,
    updateClientDarkThemeLogoURL,
    updateClientLightThemeLogoURL,
    updateClientDarkThemeLogoId,
    updateClientLightThemeLogoId,
    setIsErrorBannerVisible,
  }: ChangeLogoSettingsProps): React.ReactElement => {
    const uploadClientLogoStore =
      backOfficeSurveySettingsStore.uploadClientLogoStore;
    const inputComponentId = "client-logo-file-upload";
    const UploadLogoComponent = ({
      title,
      logoUrl,
      updateLogoURL,
      updateLogoId,
      isDarkTheme,
    }: {
      title: string;
      logoUrl: string | undefined | null;
      updateLogoURL: (logoURL: string) => void;
      updateLogoId: (logoId: string) => void;
      isDarkTheme: boolean;
    }): React.ReactElement => {
      const handleFileUpload = async (file: File): Promise<void> => {
        uploadClientLogoStore.setFileName(file.name);
        await processSurveyParams(async (surveyId, projectId) => {
          await uploadClientLogoStore.uploadLogo(
            surveyId,
            projectId,
            file,
            updateLogoURL,
            updateLogoId,
          );
        }, navigateTo);
        switch (uploadClientLogoStore.fileUploadError) {
          case UploadClientLogoError.InternalError:
          case UploadClientLogoError.InvalidFileSHA:
          case UploadClientLogoError.InvalidFileName:
            navigateTo.internalServerError();
            break;
        }
      };

      // This function gets triggered when the user uses the input field to upload the file ( by clicking on the label in place of using drag and drop).
      const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();
        if (event.target.files && event.target.files.length) {
          const _file = event.target.files[0];
          handleFileUpload(_file);
        }
      };

      return (
        <Stack gap={spacing.spaceLG} width="100%">
          <Typography {...typography.s1} color={tokens.label} width="100%">
            {title}
          </Typography>
          {logoUrl && (
            <Stack
              width="220px"
              height="140px"
              alignItems="center"
              justifyContent="center"
              border={border.default}
              borderRadius={cornerRadius.radiusXS}
              sx={{
                backgroundColor: isDarkTheme
                  ? DARK_THEME_BACKGROUND_ELEVATED_LEVEL_1
                  : tokens.backgroundElevatedLevel1,
              }}
            >
              <img
                src={logoUrl}
                height={BIG_SCREEN_CLIENT_LOGO_HEIGHT}
                style={{ ...getBigScreenClientLogoProps(cornerRadius) }}
              />
            </Stack>
          )}
          <input
            type="file"
            // Since we are only allowing the png or jpg type as of now, hence the accept value is set to that.
            accept={PNG_FILE_EXTENSION || JPG_FILE_EXTENSION}
            id={inputComponentId + title}
            // Since we are only allowing one file to be selected, hence multiple is set to false.
            multiple={false}
            onChange={onChange}
            // Since we don't require the default UI for input field hence the display is set to none.
            style={{ display: "none" }}
          />
          <Stack width="fit-content" direction="row" gap={spacing.spaceSM}>
            <Button
              label={t(
                "surveys.settings.clientLogoSettings.uploadLogoButtonText",
              )}
              name="uploadThemedLogo"
              size="medium"
              variant="outlined-color"
              onClick={() => {
                setIsErrorBannerVisible(false);
                uploadClientLogoStore.resetFileUploadError();
                document.getElementById(inputComponentId + title)?.click();
              }}
            />
            <Button
              label={t(
                "surveys.settings.clientLogoSettings.resetLogoButtonText",
              )}
              name={t(
                "surveys.settings.clientLogoSettings.resetLogoButtonText",
              )}
              size="medium"
              variant="outlined-color"
              color="destructive"
              onClick={async () => {
                uploadClientLogoStore.resetFileUploadError();
                await processSurveyParams(
                  async (
                    surveyId: string,
                    projectId: string,
                  ): Promise<void> => {
                    await backOfficeSurveySettingsStore.resetClientLogo(
                      surveyId,
                      projectId,
                      isDarkTheme,
                    );
                  },
                  navigateTo,
                );
              }}
            />
          </Stack>
        </Stack>
      );
    };

    const getErrorText = (): string | undefined => {
      switch (uploadClientLogoStore.fileUploadError) {
        case UploadClientLogoError.InvalidFileFormat:
          return t(
            "surveys.settings.clientLogoSettings.helperTexts.invalidFormat",
          );
        case UploadClientLogoError.MaxFileSizeReached:
          return t(
            "surveys.settings.clientLogoSettings.helperTexts.maxFileSizeReached",
            {
              maxFileSize: MAX_CLIENT_LOGO_FILE_SIZE_IN_MB,
            },
          );
        case UploadClientLogoError.MinFileSizeNotReached:
          return t(
            "surveys.settings.clientLogoSettings.helperTexts.minFileSizeReached",
            { minFileSize: MIN_CLIENT_LOGO_FILE_SIZE_IN_KB },
          );
      }
    };

    return (
      <SettingsContainer
        settingHeadingText={t(
          "surveys.settings.clientLogoSettings.clientLogoTitleText",
        )}
        settingSubHeadingText={t(
          "surveys.settings.clientLogoSettings.clientLogoDescriptionText",
          {
            maxFileSize: MAX_CLIENT_LOGO_FILE_SIZE_IN_MB,
          },
        )}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
      >
        <Stack gap={spacing.spaceLG}>
          <Stack gap={spacing.spaceLG} direction="row" width="720px">
            <UploadLogoComponent
              title={t(
                "surveys.settings.clientLogoSettings.lightThemeLogoText",
              )}
              logoUrl={lightThemeLogoUrl}
              updateLogoURL={updateClientLightThemeLogoURL}
              updateLogoId={updateClientLightThemeLogoId}
              isDarkTheme={false}
            />
            <UploadLogoComponent
              title={t("surveys.settings.clientLogoSettings.darkThemeLogoText")}
              logoUrl={darkThemeLogoUrl}
              updateLogoURL={updateClientDarkThemeLogoURL}
              updateLogoId={updateClientDarkThemeLogoId}
              isDarkTheme={true}
            />
          </Stack>
          <Typography {...typography.b2} color={tokens.labelError}>
            {getErrorText()}
          </Typography>
        </Stack>
      </SettingsContainer>
    );
  },
);
