import {
  Instance,
  cast,
  getParent,
  getParentOfType,
  types,
} from "mobx-state-tree";
import {
  OpenEndedFieldModel,
  GridColumnQuestionTypeV1Enum,
  QuestionOptionsModel,
  createQuestionOptionsModel,
  createGridSingleSelectQuestionV1Model,
  ZERO_VALUE,
} from "@pulse/shared-components";
import { QuestionDetailsModel } from "../models/QuestionDetailsModel";
import {
  FieldValidations,
  QuestionDetailsStore,
  initializeSingleChoiceNAOptionModel,
} from "./QuestionDetailsStore";
import {
  EMPTY_CHARACTER,
  doesCodeContainAllowedCharacters,
} from "@pulse/shared-components";
import {
  Code,
  GridColumnQuestionTypeV1,
  GridMultiChoiceQuestionV1,
  GridOptionV1,
  GridQuestionV1,
  GridQuestionV1DTO,
  SurveyLocalizedText,
} from "@pulse/pulse-rpcs";
import {
  GridColumnQuestionV1Model,
  createGridColumnQuestionV1Model,
} from "../models/GridColumnQuestionV1Model";
import { TFunction } from "i18next";
import {
  MultiChoiceQuestionV1Store,
  createMultiChoiceQuestionV1Store,
} from "./MultiChoiceQuestionV1Store";
import { createGridSingleChoiceQuestionV1Model } from "../models/GridSingleChoiceQuestionV1Model";
import { GridSingleChoiceDisplayTypeV1Enum } from "@pulse/shared-components";
import { initializeNewOpenEndedField } from "../components/openEndedFieldComponents/OpenEndedField";
import { getGridSingleSelectV1Model } from "@pulse/shared-components";
import { GridTabElements } from "../components/GridQuestionCustomisationPane";
import { GridSize } from "@pulse/pulse-rpcs";
import { MAX_GRID_COLUMNS } from "@pulse/shared-components";
import { MAX_GRID_ROWS } from "@pulse/shared-components";
import { DEFAULT_GRID_ROW_SIZE } from "@pulse/shared-components";

export const GridQuestionV1Store = types
  .model("GridQuestionV1Store", {
    rowOptions: types.array(QuestionOptionsModel),
    rowHeader: types.string,
    areAllRowOptionsMandatoryIfRowAttempted: types.boolean,
    gridColumnQuestions: types.array(GridColumnQuestionV1Model),
    gridTabIndex: types.number,
    isChoiceResetAllowed: types.optional(types.boolean, false),
    gridRowSize: types.optional(types.number, DEFAULT_GRID_ROW_SIZE),
  })
  .views((store) => ({
    get isRowOptionVisible(): boolean {
      return store.rowOptions.some(
        (rowOption: Instance<typeof QuestionOptionsModel>) => {
          return rowOption.option.trim() !== EMPTY_CHARACTER;
        },
      );
    },
    get isSingleGridColumnPresent(): boolean {
      return store.gridColumnQuestions.length === 1;
    },
  }))
  .views((store) => ({
    get serializedJSON(): string {
      const rowOptions = store.rowOptions.map((option) => {
        return new GridOptionV1(
          new Code(option.code),
          option.option === EMPTY_CHARACTER
            ? null
            : new SurveyLocalizedText(option.option),
        );
      });
      const gridColumns = store.gridColumnQuestions.map(
        (gridColumnQuestion) => {
          return gridColumnQuestion.gridColumnV1Object;
        },
      );
      return JSON.stringify(
        new GridQuestionV1(
          store.rowHeader !== EMPTY_CHARACTER
            ? new SurveyLocalizedText(store.rowHeader)
            : null,
          rowOptions,
          gridColumns,
          store.areAllRowOptionsMandatoryIfRowAttempted,
          store.isChoiceResetAllowed,
          store.gridRowSize && store.isRowOptionVisible
            ? new GridSize(store.gridRowSize)
            : null,
        ).toDTO(),
      );
    },
    doesRowOptionCodeContainErrors(index: number): boolean {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );
      return (
        (store.rowOptions[index].code.trim() === EMPTY_CHARACTER &&
          questionDetailsStore.fieldValidations ===
            FieldValidations.UnfilledOptionField) ||
        store.rowOptions[index].code.trim() ===
          questionDetailsStore.duplicateOptionCode
      );
    },
    get isAddNewFieldButtonDisabledForRows(): boolean {
      return store.rowOptions.length >= MAX_GRID_ROWS;
    },
    isChevronUpDisabled(index: number): boolean {
      return index === 0;
    },
    isChevronDownDisabledForRows(index: number): boolean {
      return index === store.rowOptions.length - 1;
    },
    isChevronDownDisabledForColumns(index: number): boolean {
      return index === store.gridColumnQuestions.length - 1;
    },
    get isDeleteButtonDisabledForRows(): boolean {
      return store.rowOptions.length === 1;
    },
    get isDeleteButtonDisabledForColumns(): boolean {
      return store.gridColumnQuestions.length === 1;
    },
    get isResetDisabled(): boolean {
      if (store.rowOptions.length === 1) {
        return (
          store.rowOptions[0].code === EMPTY_CHARACTER &&
          store.rowOptions[0].option === EMPTY_CHARACTER
        );
      }
      return false;
    },
    get gridColumnSizeCustomisations(): number[] {
      const rowAndColumnSizes: number[] = [];

      store.gridColumnQuestions.map((gridColumnQuestion) => {
        rowAndColumnSizes.push(gridColumnQuestion.gridColumnSize);
      });
      return rowAndColumnSizes;
    },
  }))
  .views((store) => ({
    get totalGridSize(): number {
      let totalGridRowWidth = 0;
      if (store.isRowOptionVisible && store.gridRowSize) {
        totalGridRowWidth = store.gridRowSize;
      }
      store.gridColumnQuestions.forEach((gridColumnQuestion) => {
        totalGridRowWidth += gridColumnQuestion.gridColumnSize;
      });
      return totalGridRowWidth;
    },
  }))
  .views((store) => ({
    get isMaxGridWidthReached(): boolean {
      return store.totalGridSize >= MAX_GRID_COLUMNS;
    },
    get isMaxGridWidthExceeded(): boolean {
      return store.totalGridSize > MAX_GRID_COLUMNS;
    },
    isGridLimitExceededInSizeDialog(
      gridRowSize: number | undefined,
      gridColumnSizes: number[],
    ): boolean {
      let totalGridRowWidth = 0;
      if (store.isRowOptionVisible && gridRowSize) {
        totalGridRowWidth = gridRowSize;
      }
      gridColumnSizes.forEach((gridColumnSize) => {
        totalGridRowWidth += gridColumnSize;
      });
      return totalGridRowWidth > MAX_GRID_COLUMNS;
    },
    isPrimaryButtonDisabled(
      gridColumnSizeCustomisations: number[],
      gridRowSize: number,
    ): boolean {
      if (store.gridRowSize !== gridRowSize) {
        return false;
      }
      let isSizeChanged = false;
      store.gridColumnQuestions.forEach((gridColumnQuestion, index) => {
        if (
          gridColumnQuestion.gridColumnSize !==
          (gridColumnSizeCustomisations[index] ?? ZERO_VALUE)
        ) {
          if (!isSizeChanged) {
            isSizeChanged = true;
          }
        }
      });
      return !isSizeChanged;
    },
  }))
  .actions((store) => ({
    setGridColumnSizeCustomisations: (
      gridColumnSizeCustomisations: number[],
      gridRowSize: number,
    ): void => {
      store.gridColumnQuestions.forEach((gridColumnQuestion, index) => {
        gridColumnQuestion.setGridColumnSize(
          gridColumnSizeCustomisations[index],
        );
      });
      store.gridRowSize = gridRowSize;
    },
    resetFieldValidationsAndErrors: (): void => {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );
      questionDetailsStore.setFieldValidations(null);
      questionDetailsStore.setRPCErrors(null);
      questionDetailsStore.setDuplicateOptionCode(null);
    },
    setIsChoiceResetAllowed: (isChoiceResetAllowed: boolean): void => {
      store.isChoiceResetAllowed = isChoiceResetAllowed;
    },
  }))
  .actions((store) => ({
    resetSpecificColumnQuestionType: (index: number, t: TFunction): void => {
      store.gridColumnQuestions[index].singleChoice =
        createGridSingleChoiceQuestionV1Model(
          createGridSingleSelectQuestionV1Model(
            [createQuestionOptionsModel()],
            GridSingleChoiceDisplayTypeV1Enum.RadioButton,
            initializeSingleChoiceNAOptionModel(t),
            true,
          ),
        );
      store.gridColumnQuestions[index].multiChoice =
        createMultiChoiceQuestionV1Store();
      store.gridColumnQuestions[index].openEndedSingleEntry =
        initializeNewOpenEndedField(t);
    },

    setRowOptionText: (fieldOptionText: string, index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.rowOptions[index].option = fieldOptionText;
    },
    setRowCode: (code: string, index: number): void => {
      if (doesCodeContainAllowedCharacters(code)) {
        store.resetFieldValidationsAndErrors();
        store.rowOptions[index].code = code;
      }
    },
    setRowHeader: (headerText: string): void => {
      store.resetFieldValidationsAndErrors();
      store.rowHeader = headerText;
    },
    setAreAllRowsOptionsMandatory: (
      areAllRowOptionsMandatory: boolean,
    ): void => {
      store.areAllRowOptionsMandatoryIfRowAttempted = areAllRowOptionsMandatory;
    },
    resetGridRows: (): void => {
      store.rowOptions = cast([
        {
          code: EMPTY_CHARACTER,
          option: EMPTY_CHARACTER,
        },
      ]);
      store.rowHeader = EMPTY_CHARACTER;
      store.gridRowSize = DEFAULT_GRID_ROW_SIZE;
      store.resetFieldValidationsAndErrors();
    },
    resetGridColumns: (): void => {
      store.gridColumnQuestions = cast([createGridColumnQuestionV1Model()]);
      store.resetFieldValidationsAndErrors();
    },
    setGridTabIndex: (tabIndex: number): void => {
      store.gridTabIndex = tabIndex;
    },
  }))
  .actions((store) => ({
    addNewGridRow: (
      gridRowOptionField: Instance<typeof QuestionOptionsModel>,
      index: number,
    ): void => {
      store.resetFieldValidationsAndErrors();
      store.rowOptions.splice(index + 1, 0, gridRowOptionField);
    },
    moveGridRowUp: (index: number): void => {
      const gridRowOptionsArrayCopy = [...store.rowOptions];
      const replacedElement = gridRowOptionsArrayCopy[index - 1];
      gridRowOptionsArrayCopy[index - 1] = gridRowOptionsArrayCopy[index];
      gridRowOptionsArrayCopy[index] = replacedElement;
      store.rowOptions.replace(gridRowOptionsArrayCopy);
    },
    moveGridRowDown: (index: number): void => {
      const gridRowOptionsArrayCopy = [...store.rowOptions];
      const replacedElement = gridRowOptionsArrayCopy[index + 1];
      gridRowOptionsArrayCopy[index + 1] = gridRowOptionsArrayCopy[index];
      gridRowOptionsArrayCopy[index] = replacedElement;
      store.rowOptions.replace(gridRowOptionsArrayCopy);
    },
    removeGridRow: (index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.rowOptions.splice(index, 1);
    },
    addNewGridColumn: (
      gridColumnQuestion: Instance<typeof GridColumnQuestionV1Model>,
      index: number,
    ): void => {
      store.resetFieldValidationsAndErrors();
      store.gridColumnQuestions.splice(index + 1, 0, gridColumnQuestion);
    },
    moveGridColumnUp: (index: number): void => {
      const gridColumnsArrayCopy = [...store.gridColumnQuestions];
      const replacedElement = gridColumnsArrayCopy[index - 1];
      gridColumnsArrayCopy[index - 1] = gridColumnsArrayCopy[index];
      gridColumnsArrayCopy[index] = replacedElement;
      store.gridColumnQuestions.replace(gridColumnsArrayCopy);
    },
    moveGridColumnDown: (index: number): void => {
      const gridColumnsArrayCopy = [...store.gridColumnQuestions];
      const replacedElement = gridColumnsArrayCopy[index + 1];
      gridColumnsArrayCopy[index + 1] = gridColumnsArrayCopy[index];
      gridColumnsArrayCopy[index] = replacedElement;
      store.gridColumnQuestions.replace(gridColumnsArrayCopy);
    },
    removeGridColumn: (index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.gridColumnQuestions.splice(index, 1);
    },
    resetGridRowsAndColumns: (): void => {
      store.resetGridColumns();
      store.resetGridRows();
    },
    deserializeJSON: (t: TFunction): void => {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const gridQuestionV1DTO = <GridQuestionV1DTO>(
        JSON.parse(questionDetailsModel.questionDetailsJSON)
      );
      const gridQuestionV1 = GridQuestionV1.fromDTO(gridQuestionV1DTO);
      store.rowOptions = cast(
        gridQuestionV1.rowOptions.map((rowOption) => {
          return QuestionOptionsModel.create({
            code: rowOption.code.code,
            option: rowOption.option?.text ?? EMPTY_CHARACTER,
          });
        }),
      );
      store.gridRowSize =
        gridQuestionV1.gridRowSize?.width ?? DEFAULT_GRID_ROW_SIZE;
      store.isChoiceResetAllowed = gridQuestionV1.isChoiceResetAllowed ?? false;
      store.setRowHeader(gridQuestionV1.rowHeader?.text ?? EMPTY_CHARACTER);
      store.setAreAllRowsOptionsMandatory(
        gridQuestionV1.areAllRowOptionsMandatoryIfRowAttempted,
      );
      store.gridColumnQuestions = cast(
        gridQuestionV1.columnQuestions.map((columnQuestion) => {
          if (
            columnQuestion.columnQuestionType instanceof
            GridColumnQuestionTypeV1.MultiChoice
          ) {
            const multiChoiceQuestionV1Model = (
              gridMultiChoiceQuestionV1: GridMultiChoiceQuestionV1,
            ): Instance<typeof MultiChoiceQuestionV1Store> => {
              return MultiChoiceQuestionV1Store.create({
                multiChoiceQuestionV1Model: {
                  maxSelection: gridMultiChoiceQuestionV1.maxSelectionAllowed,
                  options: gridMultiChoiceQuestionV1.options.map((options) => {
                    return QuestionOptionsModel.create({
                      option: options.option.text,
                      code: options.code.code,
                    });
                  }),
                },
              });
            };
            return GridColumnQuestionV1Model.create({
              columnCode: columnQuestion.columnCode.code,
              columnHeader:
                columnQuestion.columnHeader?.text ?? EMPTY_CHARACTER,
              questionType: GridColumnQuestionTypeV1Enum.MultiChoice,
              multiChoice: multiChoiceQuestionV1Model(
                columnQuestion.columnQuestionType.multiChoiceQuestionAttributes,
              ),
              gridColumnSize: columnQuestion.gridColumnSize?.width,
            });
          } else if (
            columnQuestion.columnQuestionType instanceof
            GridColumnQuestionTypeV1.OpenEnded
          ) {
            const openEndedField =
              columnQuestion.columnQuestionType.openEndedQuestionAttributes
                .option;

            const openEndedSingleEntry = OpenEndedFieldModel.create({
              code: openEndedField.optionCode.code,
              fieldTitle:
                openEndedField.optionFieldTitle?.text ?? EMPTY_CHARACTER,
              fieldHint:
                openEndedField.optionFieldHint?.text ?? EMPTY_CHARACTER,
            });
            return GridColumnQuestionV1Model.create({
              columnCode: columnQuestion.columnCode.code,
              columnHeader:
                columnQuestion.columnHeader?.text ?? EMPTY_CHARACTER,
              questionType: GridColumnQuestionTypeV1Enum.OpenEnded,
              openEndedSingleEntry,
              gridColumnSize: columnQuestion.gridColumnSize?.width,
            });
          } else if (
            columnQuestion.columnQuestionType instanceof
            GridColumnQuestionTypeV1.SingleChoice
          ) {
            return GridColumnQuestionV1Model.create({
              columnCode: columnQuestion.columnCode.code,
              columnHeader:
                columnQuestion.columnHeader?.text ?? EMPTY_CHARACTER,
              questionType: GridColumnQuestionTypeV1Enum.SingleChoice,
              singleChoice: createGridSingleChoiceQuestionV1Model(
                getGridSingleSelectV1Model(
                  columnQuestion.columnQuestionType
                    .singleChoiceQuestionAttributes,
                  t("surveys.addSurveyQuestion.singleChoice.notApplicable"),
                  t("surveys.addSurveyQuestion.singleChoice.na"),
                ),
              ),
              gridColumnSize: columnQuestion.gridColumnSize?.width,
            });
          } else return createGridColumnQuestionV1Model();
        }),
      );
    },
    validateFields: (): void => {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );
      if (store.isMaxGridWidthExceeded) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.MaxGridSizeReached,
        );
        return;
      }

      store.rowOptions.forEach((option) => {
        if (option.code.trim() === EMPTY_CHARACTER) {
          questionDetailsStore.setFieldValidations(
            FieldValidations.UnfilledOptionField,
          );
        }
      });
      const doRowsContainValidationErrors =
        questionDetailsStore.isValidationErrorPresent;

      store.gridColumnQuestions.forEach((columnQuestion) => {
        if (columnQuestion.columnCode.trim() === EMPTY_CHARACTER) {
          questionDetailsStore.setFieldValidations(
            FieldValidations.UnfilledOptionField,
          );
        }
        if (columnQuestion.isSingleChoice) {
          columnQuestion.singleChoice.validateFields();
        } else if (columnQuestion.isOpenEnded) {
          if (
            columnQuestion.openEndedSingleEntry.code.trim() === EMPTY_CHARACTER
          ) {
            questionDetailsStore.setFieldValidations(
              FieldValidations.UnfilledOptionField,
            );
          }
        } else if (columnQuestion.isMultiChoice) {
          columnQuestion.multiChoice.validateFields();
        }
      });
      if (
        !doRowsContainValidationErrors &&
        questionDetailsStore.isValidationErrorPresent
      ) {
        store.setGridTabIndex(GridTabElements.COLUMN_QUESTIONS);
        return;
      } else {
        store.setGridTabIndex(GridTabElements.ROW_QUESTIONS);
      }
    },
    validateInitialOptionCodes(
      initialQuestionDetails: Instance<typeof store>,
    ): void {
      const questionDetailsStore = getParentOfType(store, QuestionDetailsStore);
      const updatedRowOptionCodes = store.rowOptions.map((rowOption) => {
        return rowOption.code.trim();
      });
      const initialRowOptionCodes = initialQuestionDetails.rowOptions.map(
        (rowOption) => {
          return rowOption.code;
        },
      );
      if (
        JSON.stringify(updatedRowOptionCodes) !==
        JSON.stringify(initialRowOptionCodes)
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.QuestionAndOptionCodesCannotBeUpdated,
        );
        return;
      }

      const updatedColumnQuestionCodes = store.gridColumnQuestions.map(
        (columnQuestion) => {
          return columnQuestion.columnCode.trim();
        },
      );
      const initialColumnQuestionCodes =
        initialQuestionDetails.gridColumnQuestions.map((columnQuestion) => {
          return columnQuestion.columnCode;
        });

      if (
        JSON.stringify(updatedColumnQuestionCodes) !==
        JSON.stringify(initialColumnQuestionCodes)
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.QuestionAndOptionCodesCannotBeUpdated,
        );
        return;
      }

      store.gridColumnQuestions.forEach((updatedGridColumnQuestion) => {
        const initialColumnQuestion =
          initialQuestionDetails.gridColumnQuestions.find(
            (initialColumnQuestionDetails) => {
              return (
                updatedGridColumnQuestion.columnCode.trim() ===
                initialColumnQuestionDetails.columnCode
              );
            },
          );
        if (initialColumnQuestion === undefined) {
          console.error(
            `Initial column question details were not found for ${updatedGridColumnQuestion.columnCode}`,
          );
          questionDetailsStore.setFieldValidations(
            FieldValidations.QuestionAndOptionCodesCannotBeUpdated,
          );
          return;
        }
        if (
          updatedGridColumnQuestion.questionType !==
          initialColumnQuestion.questionType
        ) {
          questionDetailsStore.setFieldValidations(
            FieldValidations.QuestionAndOptionCodesCannotBeUpdated,
          );
        }
        if (updatedGridColumnQuestion.isOpenEnded) {
          if (
            updatedGridColumnQuestion.openEndedSingleEntry.code.trim() !==
            initialColumnQuestion.openEndedSingleEntry.code
          ) {
            questionDetailsStore.setFieldValidations(
              FieldValidations.QuestionAndOptionCodesCannotBeUpdated,
            );
          }
        } else if (updatedGridColumnQuestion.isSingleChoice) {
          updatedGridColumnQuestion.singleChoice.validateInitialOptionCodes(
            initialColumnQuestion.singleChoice,
          );
        } else {
          updatedGridColumnQuestion.multiChoice.validateInitialOptionCodes(
            initialColumnQuestion.multiChoice,
          );
        }
      });
    },
  }));

export const createGridQuestionV1Store = (): Instance<
  typeof GridQuestionV1Store
> => {
  return GridQuestionV1Store.create({
    rowOptions: [createQuestionOptionsModel()],
    rowHeader: EMPTY_CHARACTER,
    areAllRowOptionsMandatoryIfRowAttempted: false,
    gridColumnQuestions: [createGridColumnQuestionV1Model()],
    gridTabIndex: GridTabElements.ROW_QUESTIONS,
  });
};
