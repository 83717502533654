import React, { useEffect } from "react";
import {
  Dialog,
  FoundationColorTokens,
  Shadow,
  Spacing,
  Typography as LeoTypography,
  cornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { ManageQuestionsDialogChild } from "./ManageQuestionsDialogChild";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import { ICON_SIZE, getIconProps } from "@pulse/shared-components";
import {
  ManageQuestionsStore,
  ManageSurveyQuestionsError,
} from "../../store/ManageQuestionsStore";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../../routes/RoutesHelper";
import { Stack, Typography } from "@mui/material";
import { Info } from "lucide-react";

interface ManageQuestionsDialogProps {
  t: TFunction;
  isManageQuestionsDialogOpen: boolean;
  setIsManageQuestionsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  manageQuestionsStore: Instance<typeof ManageQuestionsStore>;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  shadow: Shadow;
  typography: LeoTypography;
  navigateTo: NavigateToFunctions;
  getQuestionsOfSurvey: (surveyId: string, projectId: string) => Promise<void>;
  setSelectedItemQuestionId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
}

export const ManageQuestionsDialog = observer(
  ({
    t,
    isManageQuestionsDialogOpen,
    setIsManageQuestionsDialogOpen,
    manageQuestionsStore,
    spacing,
    tokens,
    shadow,
    typography,
    navigateTo,
    getQuestionsOfSurvey,
    setSelectedItemQuestionId,
  }: ManageQuestionsDialogProps): React.ReactElement => {
    useEffect(() => {
      manageQuestionsStore.setManageQuestionsList();
    }, []);

    const onPrimaryButtonClick = async (): Promise<void> => {
      if (manageQuestionsStore.isOutdateQuestionError) {
        setIsManageQuestionsDialogOpen(false);
        await processSurveyParams(getQuestionsOfSurvey, navigateTo);
        manageQuestionsStore.resetRPCError();
      } else {
        await processSurveyParams(
          manageQuestionsStore.updateQuestionsOrder,
          navigateTo,
        );
      }
      if (!manageQuestionsStore.doesStoreContainError) {
        setIsManageQuestionsDialogOpen(false);
        setSelectedItemQuestionId(null);
        await processSurveyParams(getQuestionsOfSurvey, navigateTo);
      }
    };

    const ManageQuestionsErrorDialogChild = observer((): React.ReactElement => {
      const getErrorDialogMessage = (): string => {
        switch (manageQuestionsStore.rpcError) {
          case ManageSurveyQuestionsError.SurveyIsLive:
            return t("surveys.manageQuestions.errorMessages.surveyIsLive");
          case ManageSurveyQuestionsError.SurveyIsClosed:
            return t("surveys.manageQuestions.errorMessages.surveyIsClosed");
          case ManageSurveyQuestionsError.OutDatedQuestionsFound:
            return t(
              "surveys.manageQuestions.errorMessages.outdatedQuestionList",
            );
          case ManageSurveyQuestionsError.ProjectAlreadyArchived:
            return t("surveys.manageQuestions.errorMessages.projectIsArchived");
          default:
            return t("surveys.manageQuestions.unexpectedErrorDescription");
        }
      };

      return (
        <Stack
          padding={spacing.spaceMD}
          gap={spacing.spaceXS}
          alignItems="center"
        >
          <Info {...getIconProps(tokens.iconError, ICON_SIZE.large)} />
          <Typography
            {...typography.b1}
            color={tokens.labelError}
            width="827px"
            align="center"
          >
            {getErrorDialogMessage()}
          </Typography>
        </Stack>
      );
    });

    const getPrimaryButtonText = (): string | undefined => {
      if (manageQuestionsStore.isOutdateQuestionError) {
        return t("common.refresh");
      } else if (manageQuestionsStore.doesStoreContainError) {
        return undefined;
      } else {
        return t("surveys.manageQuestions.saveChanges");
      }
    };

    const getSecondaryButtonText = (): string | undefined => {
      if (manageQuestionsStore.isOutdateQuestionError) {
        return undefined;
      } else if (manageQuestionsStore.doesStoreContainError) {
        return t("common.close");
      } else {
        return t("common.cancel");
      }
    };

    const isPrimaryButtonDisabled = (): boolean => {
      if (manageQuestionsStore.isOutdateQuestionError) {
        return false;
      } else if (
        manageQuestionsStore.doesStoreContainError ||
        manageQuestionsStore.isRPCLoading
      ) {
        return true;
      } else {
        return manageQuestionsStore.isSubmitButtonDisabled;
      }
    };

    return (
      <Dialog
        open={isManageQuestionsDialogOpen}
        title={t("surveys.manageQuestions.manageQuestions")}
        primaryButtonText={getPrimaryButtonText()}
        onPrimaryButtonClick={onPrimaryButtonClick}
        isPrimaryButtonDisabled={isPrimaryButtonDisabled()}
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsManageQuestionsDialogOpen(false);
          manageQuestionsStore.resetRPCError();
          manageQuestionsStore.resetManageQuestionsList();
        }}
        isSecondaryButtonDisabled={manageQuestionsStore.isRPCLoading}
        // The dialog by default adds a padding of 20px. Passing undefined doesn't override that behaviour, therefore 0px is being passed.
        contentPadding="0px"
      >
        {manageQuestionsStore.rpcError ? (
          <ManageQuestionsErrorDialogChild />
        ) : (
          <ManageQuestionsDialogChild
            manageQuestionsStore={manageQuestionsStore}
            cornerRadius={cornerRadius}
            shadow={shadow}
            spacing={spacing}
          />
        )}
      </Dialog>
    );
  },
);
