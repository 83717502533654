import React from "react";

import { observer } from "mobx-react";
import { Stack, Typography } from "@mui/material";
import {
  Checkbox,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { SingleSelectOptionTextInput } from "./singleSelectOptionComponents/SingleSelectOptionTextInput";
import { SingleSelectOptionCodeInput } from "./singleSelectOptionComponents/SingleSelectOptionCodeInput";
import { BorderStyle, getCheckBoxState } from "@pulse/shared-components";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { QuestionDetailsStore } from "../store/QuestionDetailsStore";

export interface NAOptionPaneProps {
  typography: LeoTypography;
  spacing: Spacing;
  border: BorderStyle;
  t: TFunction;
  isNAOptionChecked: boolean;
  naCheckBoxOnChange: (value: boolean) => void;
  naOptionString: string;
  naOptionCode: string;
  doesNAOptionTextContainErrors: boolean;
  doesNAOptionCodeContainErrors: boolean;
  onNAOptionTextChange: (inputString: string) => void;
  onNAOptionCodeChange: (inputString: string) => void;
  isNAOptionDisabled: boolean;
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
}

export const NAOptionPane = observer(
  ({
    spacing,
    typography,
    t,
    border,
    isNAOptionChecked,
    naCheckBoxOnChange,
    naOptionString,
    naOptionCode,
    doesNAOptionTextContainErrors,
    doesNAOptionCodeContainErrors,
    onNAOptionTextChange,
    onNAOptionCodeChange,
    isNAOptionDisabled,
    questionDetailsStore,
  }: NAOptionPaneProps): React.ReactElement => {
    return (
      <Stack gap={spacing.spaceSM}>
        <Typography
          width="600px"
          sx={{
            ...typography.sh3,
            borderBottom: border.default,
            padding: `${spacing.spaceSM} 0px`,
          }}
        >
          {t("surveys.addSurveyQuestion.singleChoice.naOption")}
        </Typography>
        <Stack gap={spacing.spaceSM}>
          <Checkbox
            label={t("surveys.addSurveyQuestion.singleChoice.showNAOption")}
            state={getCheckBoxState(isNAOptionChecked)}
            onChange={naCheckBoxOnChange}
          />
          <Stack direction="row" gap={spacing.spaceSM}>
            <SingleSelectOptionTextInput
              width={undefined}
              value={naOptionString}
              error={doesNAOptionTextContainErrors}
              onTextChange={onNAOptionTextChange}
              t={t}
              isDisabled={isNAOptionDisabled}
            />

            <SingleSelectOptionCodeInput
              width="132px"
              value={naOptionCode}
              error={doesNAOptionCodeContainErrors}
              onTextChange={onNAOptionCodeChange}
              isDisabled={isNAOptionDisabled}
              t={t}
              questionDetailsStore={questionDetailsStore}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  },
);
