import { AlertCircle } from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { ValidateRespondentDialogButtonProps } from "./ValidateRespondentColumnDialog";

export const DuplicateValidationErrorComponent = observer(
  ({
    spacing,
    t,
    tokens,
    typography,
  }: ValidateRespondentDialogButtonProps): React.ReactElement => {
    return (
      <Stack direction="row" spacing={spacing.spaceXS} width="100%">
        <Stack width="20px">
          <AlertCircle color={tokens.iconError} width="20px" />
        </Stack>
        <Typography sx={{ ...typography.b2, color: tokens.labelError }}>
          {t("projects.respondentList.duplicateRecordsDetected")}
        </Typography>
      </Stack>
    );
  },
);
