import { CircularProgress, Stack, Typography } from "@mui/material";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { observer } from "mobx-react";
import { RespondentUploadStore } from "../../store/RespondentUploadStore";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { SINGLE_SPACING } from "@pulse/shared-components";

interface ValidateRespondentDialogButtonProps {
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  respondentUploadStore: Instance<typeof RespondentUploadStore> | null;
}

export const DuplicateValidationLoader = observer(
  ({
    t,
    spacing,
    tokens,
    typography,
    respondentUploadStore,
  }: ValidateRespondentDialogButtonProps): React.ReactElement => {
    return (
      <Stack
        padding={spacing.spaceXL}
        alignItems="center"
        spacing={spacing.spaceXS}
        textAlign="center"
        width="100%"
      >
        <CircularProgress
          size="32px"
          style={{
            color: tokens.iconPrimary,
          }}
        />
        <Typography sx={{ ...typography.b2, color: tokens.labelPrimary }}>
          {t("projects.respondentList.checkingForDuplicates")}
          {SINGLE_SPACING}
          <Typography
            component={"span"}
            sx={{ ...typography.s1, color: tokens.labelPrimary }}
          >
            {respondentUploadStore?.fileName}
          </Typography>
          {t("projects.respondentList.pleaseWait")}
        </Typography>
      </Stack>
    );
  },
);
