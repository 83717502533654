import { observer } from "mobx-react";
import React from "react";
import { ConfirmationDialogComponent } from "@pulse/shared-components";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";

interface DeleteQuestionConfirmationDialogProps {
  isDeleteQuestionDialogOpen: boolean;
  setIsDeleteQuestionDialogOpen: (value: React.SetStateAction<boolean>) => void;
  t: TFunction;
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
  selectedQuestionItemId: string | null;
  setSelectedItemQuestionId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
}

export const DeleteQuestionConfirmationDialog = observer(
  ({
    setIsDeleteQuestionDialogOpen,
    isDeleteQuestionDialogOpen,
    t,
    questionDetailsStore,
    selectedQuestionItemId,
    setSelectedItemQuestionId,
  }: DeleteQuestionConfirmationDialogProps): React.ReactElement => {
    return (
      <ConfirmationDialogComponent
        isDialogOpen={isDeleteQuestionDialogOpen}
        title={t("surveys.deleteQuestion.deleteQuestionTitle")}
        description={
          questionDetailsStore.isGroupQuestion
            ? t("surveys.deleteQuestion.deleteGroupQuestionDescription")
            : t("surveys.deleteQuestion.deleteQuestionDescription")
        }
        primaryButtonText={t(
          "surveys.deleteQuestion.deletedQuestionButtonText",
        )}
        primaryButtonCallBack={async (): Promise<void> => {
          setIsDeleteQuestionDialogOpen(false);
          if (selectedQuestionItemId) {
            await questionDetailsStore.deleteQuestion(selectedQuestionItemId);
            if (!questionDetailsStore.rpcErrors) {
              setSelectedItemQuestionId(null);
            }
          } else {
            console.error("Selected question item id was found to be null.");
          }
        }}
        primaryButtonType="destructive"
        secondaryButtonCallback={async (): Promise<void> => {
          setIsDeleteQuestionDialogOpen(false);
        }}
        secondaryButtonText={t("common.cancel")}
      />
    );
  },
);
