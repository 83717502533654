import { Stack, Typography } from "@mui/material";
import { BorderStyle } from "@pulse/shared-components";
import {
  CornerRadius,
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";

export interface TranslationDataListElementProps {
  border: BorderStyle;
  baseLanguageText: string | null;
  translatedLanguageText: string | null;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  spacing: Spacing;
  isBackgroundColorDark: boolean;
  isLastElement: boolean;
  borderRadius: CornerRadius;
}

export const TranslationDataListElement = ({
  border,
  baseLanguageText,
  translatedLanguageText,
  tokens,
  typography,
  spacing,
  isBackgroundColorDark,
  isLastElement,
  borderRadius,
}: TranslationDataListElementProps): React.ReactElement => {
  const ListElement = ({
    borderRight,
    languageText,
  }: {
    borderRight: string | undefined;
    languageText: string | null;
  }): React.ReactElement => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        height="100%"
        padding={spacing.spaceLG}
        borderRight={borderRight}
      >
        <Typography {...typography.b2} color={tokens.label}>
          {languageText}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack
      direction="row"
      width="100%"
      height="fit-content"
      borderBottom={isLastElement ? undefined : border.default}
      borderRadius={
        isLastElement
          ? `0 0 ${borderRadius.radiusXS} ${borderRadius.radiusXS}`
          : undefined
      }
      bgcolor={
        isBackgroundColorDark
          ? tokens.backgroundSubtle
          : tokens.backgroundElevatedLevel1
      }
    >
      <ListElement
        borderRight={border.default}
        languageText={baseLanguageText}
      />
      <ListElement
        borderRight={undefined}
        languageText={translatedLanguageText}
      />
    </Stack>
  );
};
