import { Stack, Typography } from "@mui/material";
import {
  AlertCircle,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { Button, useTypography } from "@surya-digital/leo-reactjs-material-ui";
import React from "react";

export interface ErrorComponentProps {
  errorMessage: string;
  resolveButtonText: string;
  resolveButtonCallback: () => void;
}

export const ErrorComponent = ({
  errorMessage,
  resolveButtonText,
  resolveButtonCallback,
}: ErrorComponentProps): React.ReactElement => {
  const typography = useTypography();
  const tokens = useFoundationColorTokens();
  const spacing = useSpacing();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%", gap: spacing.spaceXS }}
    >
      <AlertCircle width="32px" height="32px" color={tokens.iconError} />
      <Typography
        sx={{
          ...typography.b1,
          color: tokens.labelError,
          marginBottom: spacing.spaceXS,
        }}
      >
        {errorMessage}
      </Typography>
      <Button
        name="resolveButton"
        label={resolveButtonText}
        variant="outlined-neutral"
        size="small"
        onClick={(): void => {
          resolveButtonCallback();
        }}
      />
    </Stack>
  );
};
