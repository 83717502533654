import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import { RespondentStore } from "../../store/RespondentStore";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";
import {
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
  useRadioButtonColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { getRadioButtonStyleProps } from "@pulse/shared-components";

interface ChooseSurveyRadioListProps {
  respondentStore: Instance<typeof RespondentStore>;
  projectStore: Instance<typeof ProjectDetailsStore>;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
}

export const ChooseSurveyRadioList = observer(
  ({
    respondentStore,
    projectStore,
    typography,
    tokens,
    spacing,
  }: ChooseSurveyRadioListProps): React.ReactElement => {
    const radioButtonTokens = useRadioButtonColorTokens();

    const radioProps = getRadioButtonStyleProps(
      tokens,
      radioButtonTokens,
      spacing,
      // Only horizontal padding is provided here because the vertical padding is given to the radio button as a whole.
      // Adding vertical padding here would add padding only to the radio button icon and not the text.
      `0px ${spacing.spaceXS}`,
    );
    return (
      <RadioGroup
        value={respondentStore.selectedSurvey?.surveyName}
        onChange={(_, value) => {
          const surveyIdAndName = projectStore.getSurveyIdAndNameByName(value);
          if (surveyIdAndName) {
            respondentStore.setSelectedSurvey(
              surveyIdAndName.surveyId,
              surveyIdAndName.surveyName,
            );
          } else {
            console.error("SurveyIdAndName cannot be null in this flow.");
          }
        }}
      >
        {projectStore.respondentStore.surveyList.map((surveyIdAndName) => (
          <Stack py={spacing.spaceXS} key={surveyIdAndName.surveyId}>
            <FormControlLabel
              control={
                <Radio
                  disabled={respondentStore.isRPCLoading}
                  {...radioProps}
                />
              }
              value={surveyIdAndName.surveyName}
              label={
                <Typography
                  {...typography.b1}
                  width="488px"
                  color={tokens.label}
                  sx={{ wordBreak: "break-word" }}
                >
                  {surveyIdAndName.surveyName}
                </Typography>
              }
            />
          </Stack>
        ))}
      </RadioGroup>
    );
  },
);
