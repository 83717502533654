import {
  EMPTY_CHARACTER,
  MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
  ZERO_VALUE,
  showMaxCharacterCountIndicator,
} from "@pulse/shared-components";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";
import React from "react";
import { TextInputField } from "@surya-digital/leo-reactjs-material-ui";

export interface SingleSelectOptionCodeInputProps {
  value: string;
  onTextChange: (inputString: string) => void;
  t: TFunction;
  error: boolean;
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
  isDisabled: boolean;
  width: string | undefined;
}

export const SingleSelectOptionCodeInput = observer(
  ({
    value,
    onTextChange,
    t,
    error,
    questionDetailsStore,
    isDisabled,
    width,
  }: SingleSelectOptionCodeInputProps): React.ReactElement => {
    return (
      <TextInputField
        minWidth={width}
        width={width}
        name="code"
        value={value}
        label={EMPTY_CHARACTER}
        placeholder={t("surveys.addSurveyQuestion.enterCode")}
        type="text"
        error={error}
        helperText={
          questionDetailsStore.isOptionCodeNotUnique(value)
            ? t("surveys.addSurveyQuestion.enterUniqueCode")
            : undefined
        }
        disabled={isDisabled}
        onTextChange={(inputValue) => {
          onTextChange(
            inputValue.slice(
              ZERO_VALUE,
              MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
            ),
          );
        }}
        maxCharacterLength={MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT}
        showCharacterCountIndicator={(): boolean =>
          showMaxCharacterCountIndicator(
            MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
            value,
          )
        }
      />
    );
  },
);
