import { Stack, Typography } from "@mui/material";
import { BorderStyle } from "@pulse/shared-components";
import {
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React from "react";

export interface TranslationDataListHeaderProps {
  border: BorderStyle;
  baseLanguageCode: string | undefined;
  translatedLanguageCode: string | undefined;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  spacing: Spacing;
}

export const TranslationDataListHeader = ({
  border,
  baseLanguageCode,
  translatedLanguageCode,
  t,
  tokens,
  typography,
  spacing,
}: TranslationDataListHeaderProps): React.ReactElement => {
  const ListHeaderElement = ({
    borderRight,
    heading,
    languageCode,
  }: {
    borderRight: string | undefined;
    heading: string;
    languageCode: string | undefined;
  }): React.ReactElement => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        height="fit-content"
        padding={`${spacing.spaceMD} ${spacing.spaceLG}`}
        borderRight={borderRight}
      >
        <Typography {...typography.s2} color={tokens.label}>
          {heading}
        </Typography>
        <Typography {...typography.s2} color={tokens.label}>
          {languageCode}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack
      direction="row"
      width="100%"
      height="fit-content"
      borderBottom={border.default}
    >
      <ListHeaderElement
        borderRight={border.default}
        heading={t("surveys.translations.baseLanguageText")}
        languageCode={baseLanguageCode}
      />
      <ListHeaderElement
        borderRight={undefined}
        heading={t("surveys.translations.translatedLanguageText")}
        languageCode={translatedLanguageCode}
      />
    </Stack>
  );
};
