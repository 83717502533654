import { Stack } from "@mui/material";
import {
  ChevronDown,
  ChevronUp,
  Plus,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { IconButton } from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { Trash2Icon } from "lucide-react";
import { observer } from "mobx-react";
import { DividerComponent } from "./commonQuestionConfigurationDetailsComponents/DividerComponent";
import { BorderStyle } from "@pulse/shared-components";

interface ChoiceListButtonsProps {
  spacing: Spacing;
  isAddNewFieldButtonDisabled: boolean;
  addNewFields: () => void;
  index: number;
  isChevronUpDisabled: (index: number) => boolean;
  isChevronDownDisabled: (index: number) => boolean;
  moveFieldUp: (index: number) => void;
  moveFieldDown: (index: number) => void;
  removeField: (index: number) => void;
  isDeleteButtonDisabled: boolean;
  border: BorderStyle | undefined;
  paddingTop: string | undefined;
}

export const ChoiceListButtons = observer(
  ({
    spacing,
    isAddNewFieldButtonDisabled,
    addNewFields,
    index,
    isChevronUpDisabled,
    moveFieldUp,
    isChevronDownDisabled,
    isDeleteButtonDisabled,
    moveFieldDown,
    removeField,
    border,
    paddingTop,
  }: ChoiceListButtonsProps): React.ReactElement => {
    return (
      <Stack
        direction="row"
        gap={spacing.spaceSM}
        alignItems="start"
        paddingTop={paddingTop}
        borderTop={border ? border.default : border}
      >
        <IconButton
          icon={<Plus />}
          name="addButton"
          variant="plain-neutral"
          size="medium"
          disabled={isAddNewFieldButtonDisabled}
          onClick={() => addNewFields()}
        />
        <IconButton
          icon={<ChevronUp />}
          name="moveUpButton"
          variant="plain-neutral"
          size="medium"
          disabled={isChevronUpDisabled(index)}
          onClick={() => {
            moveFieldUp(index);
          }}
        />
        <IconButton
          icon={<ChevronDown />}
          name="moveDownButton"
          variant="plain-neutral"
          size="medium"
          disabled={isChevronDownDisabled(index)}
          onClick={() => {
            moveFieldDown(index);
          }}
        />
        <DividerComponent height="20px" orientation="vertical" />
        <IconButton
          icon={<Trash2Icon />}
          name="deleteButton"
          variant="plain-neutral"
          size="medium"
          disabled={isDeleteButtonDisabled}
          onClick={() => {
            removeField(index);
          }}
        />
      </Stack>
    );
  },
);
