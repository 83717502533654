import { Stack } from "@mui/material";
import {
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useOpenEndedFieldStore } from "../store/hooks";
import { observer } from "mobx-react";
import { OpenEndedField } from "./openEndedFieldComponents/OpenEndedField";
import { ResetFieldsButton } from "./commonQuestionConfigurationDetailsComponents/ResetFieldsButton";
import { QuestionOptionsTitleWithTooltip } from "./QuestionOptionsTitleWithTooltip";

interface OpenEndedFieldCustomisationPaneProps {
  setIsResetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OpenEndedFieldCustomisationPane = observer(
  ({
    setIsResetDialogOpen,
  }: OpenEndedFieldCustomisationPaneProps): React.ReactElement => {
    const { t } = useTranslation();
    const tokens = useFoundationColorTokens();
    const openEndedFieldStore = useOpenEndedFieldStore();
    const spacing = useSpacing();

    const OpenEndedFieldCustomisationOptions = observer(
      (): React.ReactElement => {
        return (
          <Stack gap={spacing.spaceXL}>
            {openEndedFieldStore.openEndedField.map((_field, index) => {
              return <OpenEndedField index={index} key={index} />;
            })}
          </Stack>
        );
      },
    );

    return (
      <Stack>
        <QuestionOptionsTitleWithTooltip
          title={t(
            "surveys.addSurveyQuestion.openEnded.openEndedFieldCustomisation",
          )}
          spacing={spacing}
          tokens={tokens}
          marginBottom={spacing.spaceXL}
        />
        <OpenEndedFieldCustomisationOptions />
        <ResetFieldsButton
          isResetDisabled={openEndedFieldStore.isResetDisabled}
          setIsResetDialogOpen={setIsResetDialogOpen}
          t={t}
          spacing={spacing}
        />
      </Stack>
    );
  },
);
