import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import {
  DeleteColumnDialogState,
  DeleteColumnError,
  RespondentStore,
} from "../../store/RespondentStore";
import {
  Dialog,
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { ErrorSuccessDialogContent } from "../ErrorSuccessDialogContent";
import { DeleteColumnDialogSuccess } from "./DeleteColumnSuccess";
import { DeleteColumnDialogSelection } from "./DeleteColumnDialogSelection";

interface DeleteColumnDialogProps {
  isDeleteColumnDialogVisible: boolean;
  setIsDeleteColumnDialogVisible: (isDialogVisible: boolean) => void;
  t: TFunction;
  respondentStore: Instance<typeof RespondentStore>;
  navigateTo: NavigateToFunctions;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  spacing: Spacing;
}

export const DeleteColumnDialog = observer(
  ({
    isDeleteColumnDialogVisible,
    setIsDeleteColumnDialogVisible,
    t,
    respondentStore,
    navigateTo,
    tokens,
    typography,
    spacing,
  }: DeleteColumnDialogProps): React.ReactElement => {
    const errorSuccessText = (): string => {
      switch (respondentStore.rpcErrors) {
        case DeleteColumnError.ProjectUniqueColumnCannotBeDeleted:
          return t(
            "projects.deleteRespondentColumn.projectUniqueColumnCannotBeDeletedErrorText",
          );
        case DeleteColumnError.ProjectArchived:
          return t("projects.deleteRespondentColumn.projectArchivedErrorText");
        default:
          return t(
            "projects.deleteRespondentColumn.deleteRespondentColumnErrorText",
          );
      }
    };
    const DeleteColumnDialogContent = observer((): React.ReactElement => {
      switch (respondentStore.deleteColumnDialogState) {
        case DeleteColumnDialogState.SelectColumnState:
          return (
            <DeleteColumnDialogSelection
              t={t}
              respondentStore={respondentStore}
              tokens={tokens}
              spacing={spacing}
              typography={typography}
            />
          );
        case DeleteColumnDialogState.SuccessState:
          return respondentStore.selectedDeleteColumn ? (
            <DeleteColumnDialogSuccess
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              deletedColumnName={respondentStore.selectedDeleteColumn?.name}
            />
          ) : (
            <>
              {console.error(
                "SelectedDeleteColumn cannot be null in this flow.",
              )}
            </>
          );
        case DeleteColumnDialogState.ErrorState:
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={errorSuccessText()}
            />
          );
      }
    });

    const secondaryButtonText = (): string => {
      switch (respondentStore.deleteColumnDialogState) {
        case DeleteColumnDialogState.SelectColumnState:
          return t("common.cancel");
        case DeleteColumnDialogState.SuccessState:
          return t("common.done");
        case DeleteColumnDialogState.ErrorState:
          return t("common.close");
      }
    };

    return (
      <Dialog
        open={isDeleteColumnDialogVisible}
        title={t("projects.respondentList.deleteColumn")}
        isSecondaryButtonDisabled={respondentStore.isRPCLoading}
        secondaryButtonText={secondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsDeleteColumnDialogVisible(false);
          respondentStore.resetDeleteColumnDialog();
        }}
        primaryButtonText={
          respondentStore.isDeleteColumnSelectState
            ? t("projects.respondentList.deleteColumn")
            : undefined
        }
        primaryButtonColor="destructive"
        isPrimaryButtonDisabled={
          respondentStore.isDeleteColumnPrimaryButtonDisabled
        }
        onPrimaryButtonClick={async () => {
          processProjectParams(respondentStore.deleteColumn, navigateTo);
        }}
        disableBackdropClick={true}
        contentPadding={spacing.spaceLG}
      >
        <DeleteColumnDialogContent />
      </Dialog>
    );
  },
);
