import { Stack, Typography } from "@mui/material";
import { BorderStyle } from "@pulse/shared-components";
import {
  Spacing,
  Typography as LeoTypography,
  Button,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import React from "react";

export interface SingleSelectPresetPaneProps {
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  border: BorderStyle;
  setIsChoicePresetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isChoosePresetButtonDisabled: boolean;
}

export const SingleSelectPresetPane = observer(
  ({
    t,
    typography,
    border,
    spacing,
    setIsChoicePresetDialogOpen,
    isChoosePresetButtonDisabled,
  }: SingleSelectPresetPaneProps): React.ReactElement => {
    return (
      <Stack
        borderBottom={border.default}
        flexDirection="row"
        justifyContent="space-between"
        py={spacing.spaceSM}
        gap={spacing.spaceXS}
      >
        <Typography sx={{ ...typography.sh3 }}>
          {t("surveys.addSurveyQuestion.singleChoice.choiceOptions")}
        </Typography>
        <Button
          label={t(
            "surveys.addSurveyQuestion.singleChoice.choosePresetLiteral",
          )}
          name={"choosePreset"}
          size={"small"}
          variant="outlined-neutral"
          onClick={(): void => {
            setIsChoicePresetDialogOpen(true);
          }}
          disabled={isChoosePresetButtonDisabled}
        />
      </Stack>
    );
  },
);
