import { Instance } from "mobx-state-tree";
import { useRootStore } from "../../root/store/RootStore";
import {
  NetworkingErrorStore,
  NetworkingStore,
} from "@pulse/shared-components";

export const useNetworkingStore = (): Instance<typeof NetworkingStore> => {
  return useRootStore().networkingStore;
};

export const useNetworkingErrorStore = (): Instance<
  typeof NetworkingErrorStore
> => {
  return useNetworkingStore().errorStore;
};
