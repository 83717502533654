import React from "react";

export const MousePointerClickIcon = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.23865 1.69721C9.94989 1.50645 10.6811 1.92839 10.8719 2.63963L11.9079 6.50229C12.0986 7.21354 11.6767 7.94475 10.9655 8.13552C10.2542 8.32628 9.523 7.90434 9.33224 7.1931L8.29624 3.33044C8.10548 2.61919 8.52741 1.88798 9.23865 1.69721ZM19.5429 4.45689C20.0636 4.97759 20.0636 5.82181 19.5429 6.34251L16.7135 9.17184C16.1928 9.69254 15.3486 9.69254 14.8279 9.17184C14.3072 8.65114 14.3072 7.80692 14.8279 7.28622L17.6572 4.45689C18.1779 3.93619 19.0222 3.93619 19.5429 4.45689ZM1.69621 9.23841C1.88691 8.52715 2.61809 8.10515 3.32935 8.29585L7.19335 9.33185C7.90461 9.52255 8.3266 10.2537 8.1359 10.965C7.9452 11.6762 7.21402 12.0982 6.50276 11.9075L2.63876 10.8715C1.9275 10.6808 1.50551 9.94966 1.69621 9.23841ZM11.0572 11.0569C11.4392 10.6748 12.0141 10.5611 12.5128 10.7689L28.5128 17.4356C29.0249 17.6489 29.3513 18.157 29.3326 18.7114C29.3139 19.2657 28.9538 19.7506 28.4286 19.9289L23.491 21.605L28.0282 26.1422C28.5489 26.6629 28.5489 27.5071 28.0282 28.0278C27.5075 28.5485 26.6633 28.5485 26.1426 28.0278L21.6054 23.4906L19.9292 28.4283C19.7509 28.9535 19.2661 29.3135 18.7117 29.3323C18.1573 29.351 17.6492 29.0245 17.4359 28.5125L10.7692 12.5125C10.5614 12.0138 10.6751 11.4389 11.0572 11.0569ZM14.4762 14.4759L18.5392 24.2271L19.7694 20.6031C19.9027 20.2106 20.2109 19.9023 20.6034 19.7691L24.2275 18.5389L14.4762 14.4759ZM9.17042 14.8271C9.69136 15.3476 9.69176 16.1918 9.17131 16.7127L6.34464 19.5421C5.82419 20.063 4.97997 20.0634 4.45902 19.5429C3.93808 19.0225 3.93768 18.1783 4.45814 17.6573L7.2848 14.828C7.80526 14.3071 8.64948 14.3067 9.17042 14.8271Z"
        fill="currentColor"
      />
    </svg>
  );
};
