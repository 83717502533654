import {
  EMPTY_CHARACTER,
  projectAndSurveyNameRegex,
} from "@pulse/shared-components";
import {
  Instance,
  flow,
  getParentOfType,
  getRoot,
  types,
} from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import { ProjectName, UpdateProjectDetailsRPC } from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useUpdateProjectDetailsRPCClient } from "../rpcs/RPC";
import { RootStore } from "../../root/store/RootStore";
import {
  DatePickerInput,
  DateRangePickerInput,
} from "@surya-digital/leo-reactjs-material-ui";
import { getFormatedLeoTimeStamp } from "../../utils/DateUtils";
import { ProjectDetailsStore } from "./ProjectDetailsStore";

export enum UpdateProjectDetailsRPCErrors {
  InvalidProjectId = "INVALID_PROJECT_ID",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  ProjectNameNotUnique = "PROJECT_NAME_NOT_UNIQUE",
  InvalidProjectName = "INVALID_PROJECT_NAME",
  InvalidProjectDates = "INVALID_PROJECT_DATES",
  InvalidProjectNameLength = "INVALID_PROJECT_NAME_LENGTH",
}

export const UpdateProjectDetailsStore = types
  .model("UpdateProjectDetailsStore", {
    projectId: types.maybe(types.string),
    projectName: types.optional(types.string, EMPTY_CHARACTER),
    startDate: types.maybeNull(types.Date),
    endDate: types.maybeNull(types.Date),
    rpcError: types.maybeNull(
      types.enumeration(
        "UpdateProjectDetailsRPCErrors",
        Object.values(UpdateProjectDetailsRPCErrors),
      ),
    ),
    isRPCLoading: types.optional(types.boolean, false),
    isUpdateProjectDetailsErrorDialogVisible: types.optional(
      types.boolean,
      false,
    ),
  })
  .views((store) => ({
    get doesProjectTextFieldContainErrors(): boolean {
      return (
        store.rpcError === UpdateProjectDetailsRPCErrors.InvalidProjectName ||
        store.rpcError === UpdateProjectDetailsRPCErrors.ProjectNameNotUnique ||
        store.rpcError ===
          UpdateProjectDetailsRPCErrors.InvalidProjectNameLength
      );
    },
    get getDateRangePickerInput(): DateRangePickerInput {
      return {
        startDate: store.startDate ?? null,
        endDate: store.endDate ?? null,
      };
    },
    get isSaveChangesButtonDisabled(): boolean {
      return (
        store.projectName.trim() === EMPTY_CHARACTER ||
        store.isRPCLoading ||
        store.endDate === null ||
        store.startDate === null ||
        store.rpcError !== null
      );
    },
    get doesStoreContainRPCError(): boolean {
      return store.rpcError !== null;
    },
  }))
  .actions((store) => ({
    setProjectDateRangeValue: (
      newDateRangePickerInput: DateRangePickerInput,
    ): void => {
      store.startDate = newDateRangePickerInput.startDate;
      store.endDate = newDateRangePickerInput.endDate;
    },
    clearRPCError: (): void => {
      store.rpcError = null;
    },
    setupProjectDetails: (
      projectName: string,
      startDate: Date | null,
      endDate: Date | null,
      projectId: string | undefined,
    ): void => {
      store.projectName = projectName;
      store.startDate = startDate;
      store.endDate = endDate;
      store.projectId = projectId;
    },
    resetStore: (): void => {
      store.projectName = EMPTY_CHARACTER;
      store.startDate = null;
      store.endDate = null;
      store.rpcError = null;
      store.isRPCLoading = false;
      store.isUpdateProjectDetailsErrorDialogVisible = false;
      store.projectId = undefined;
    },
    setIsUpdateProjectDetailsErrorDialogVisible: (
      isUpdateProjectDetailsErrorDialogVisible: boolean,
    ): void => {
      store.isUpdateProjectDetailsErrorDialogVisible =
        isUpdateProjectDetailsErrorDialogVisible;
    },
    isProjectNameValid: (): boolean => {
      try {
        new ProjectName(store.projectName.trim());
        const regex = new RegExp(projectAndSurveyNameRegex);
        if (!regex.test(store.projectName)) {
          store.rpcError = UpdateProjectDetailsRPCErrors.InvalidProjectName;
          return false;
        }
        return true;
      } catch (e) {
        store.rpcError = UpdateProjectDetailsRPCErrors.InvalidProjectNameLength;
        return false;
      }
    },
  }))
  .actions((store) => ({
    resetErrorDialog: (): void => {
      store.clearRPCError();
      store.setIsUpdateProjectDetailsErrorDialogVisible(false);
    },
    setProjectName: (projectName: string): void => {
      store.projectName = projectName;
    },
    setStartDate: (startDate: DatePickerInput): void => {
      store.startDate = startDate ? new Date(startDate.toDateString()) : null;
    },
    setEndDate: (endDate: DatePickerInput): void => {
      store.startDate = endDate ? new Date(endDate.toDateString()) : null;
    },
    updateProjectDetails: flow(function* (projectId: string) {
      store.isRPCLoading = true;
      try {
        const apiClient = getAPIClient(store);
        const request = new UpdateProjectDetailsRPC.Request(
          new ProjectName(store.projectName),
          getFormatedLeoTimeStamp(store.startDate),
          getFormatedLeoTimeStamp(store.endDate),
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: UpdateProjectDetailsRPC.Response;
          error?: UpdateProjectDetailsRPC.Errors.Errors;
        } = yield useUpdateProjectDetailsRPCClient(apiClient).execute(request);
        if (response) {
          store.projectName = store.projectName.trim();
          const projectDetailsStore = getParentOfType(
            store,
            ProjectDetailsStore,
          );
          projectDetailsStore.setProjectName(store.projectName);
          projectDetailsStore.setProjectStartAndEndDates(
            store.startDate,
            store.endDate,
          );
        } else if (error) {
          switch (error.code) {
            case UpdateProjectDetailsRPCErrors.InvalidProjectId: {
              // Handled by the default response interceptor.
              break;
            }
            case UpdateProjectDetailsRPCErrors.InvalidProjectDates: {
              store.rpcError =
                UpdateProjectDetailsRPCErrors.InvalidProjectDates;
              break;
            }
            case UpdateProjectDetailsRPCErrors.ProjectAlreadyArchived: {
              store.setIsUpdateProjectDetailsErrorDialogVisible(true);
              store.rpcError =
                UpdateProjectDetailsRPCErrors.ProjectAlreadyArchived;
              break;
            }
            case UpdateProjectDetailsRPCErrors.InvalidProjectName: {
              store.rpcError = UpdateProjectDetailsRPCErrors.InvalidProjectName;
              break;
            }
            case UpdateProjectDetailsRPCErrors.ProjectNameNotUnique: {
              store.rpcError =
                UpdateProjectDetailsRPCErrors.ProjectNameNotUnique;
              break;
            }
            default: {
              console.error(
                `Unhandled error ${error} occured in updateProjectDetails in UpdateProjectDetailsStore.`,
              );
              store.setIsUpdateProjectDetailsErrorDialogVisible(true);
            }
          }
        }
      } catch (e) {
        store.setIsUpdateProjectDetailsErrorDialogVisible(true);
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }));

export const createUpdateProjectDetailsStore = (): Instance<
  typeof UpdateProjectDetailsStore
> => {
  return UpdateProjectDetailsStore.create();
};
