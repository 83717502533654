import { Instance, cast, flow, getRoot, types } from "mobx-state-tree";
import {
  FilterStore,
  createFilterStore,
} from "../../../components/filter/store/FilterStore";
import { AuditLogModel, createAuditLogModel } from "../models/AuditLogModel";
import { RootStore } from "../../root/store/RootStore";
import { DateRange, GetAuditLogsRPC } from "@pulse/pulse-rpcs";
import { EMPTY_LIST_LENGTH } from "@pulse/shared-components";
import { AuditLogsFilterNames } from "../pages/AuditLogsPage";
import {
  DEFAULT_ITEMS_PER_PAGE_IN_AUDIT_LOGS,
  SEVEN_DAYS_IN_MILLISECONDS,
} from "@pulse/shared-components/src/constants";
import { useGetAuditLogsRPCClient } from "../rpcs/RPC";
import { getAPIClient } from "../../networking/APIClient";
import { FilterType } from "../../../components/filter/model/FilterModel";

export enum GetAuditLogsErrors {
  InvalidPageIndex = "INVALID_PAGE_INDEX",
  InvalidDateRange = "INVALID_DATE_RANGE",
}

export interface AuditLogsFilters {
  emailAddress: string | undefined;
  eventName: string | undefined;
  dateRange: DateRange | undefined;
}

export const AuditLogsStore = types
  .model("AuditLogsStore", {
    filterStore: FilterStore,
    totalItems: types.maybeNull(types.number),
    auditLogs: types.array(AuditLogModel),
    rpcErrors: types.maybeNull(
      types.enumeration(
        "GetAuditLogsErrors",
        Object.values(GetAuditLogsErrors),
      ),
    ),
    isRPCLoading: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get doesStoreContainErrors(): boolean {
      return store.rpcErrors !== null;
    },
    get auditLogsFilters(): AuditLogsFilters {
      let emailAddress: string | undefined;
      let eventName: string | undefined;
      let dateRange: DateRange | undefined;
      if (store.filterStore.appliedFilters.length !== EMPTY_LIST_LENGTH) {
        store.filterStore.appliedFilters.forEach((filter) => {
          switch (filter.key) {
            case AuditLogsFilterNames.EmailAddress: {
              emailAddress = filter.openEndedFilterValue;
              break;
            }
            case AuditLogsFilterNames.EventName: {
              eventName = filter.openEndedFilterValue;
              break;
            }
            case AuditLogsFilterNames.DateRange: {
              if (
                filter.dateRangeFilterValue?.leoStartDate !== undefined &&
                filter.dateRangeFilterValue?.leoEndDate !== undefined
              ) {
                dateRange = new DateRange(
                  filter.dateRangeFilterValue?.leoStartDate,
                  filter.dateRangeFilterValue?.leoEndDate,
                );
              }
              break;
            }
          }
        });
      }
      return {
        emailAddress,
        eventName,
        dateRange,
      };
    },
  }))
  .actions((store) => ({
    resetStore: (): void => {
      store.auditLogs.clear();
      store.isRPCLoading = false;
      store.rpcErrors = null;
      store.filterStore.clearStore();
      store.totalItems = null;
    },
    addPastSevenDaysDateRangeFilter: (): void => {
      store.filterStore.addSelectedFilterKey(
        AuditLogsFilterNames.DateRange,
        FilterType.DateRange,
      );
      store.filterStore.setDateRangeFilterValue({
        startDate: new Date(Date.now() - SEVEN_DAYS_IN_MILLISECONDS),
        endDate: new Date(Date.now()),
      });
      store.filterStore.addCurrentSelectedFilter();
      store.filterStore.applyCurrentAddedFilters();
      store.filterStore.removeCurrentSelectedFilter();
    },
    getAuditLogs: flow(function* (pageIndex: number) {
      store.isRPCLoading = true;
      store.rpcErrors = null;
      try {
        const apiClient = getAPIClient(store);
        const auditLogsFilters = store.auditLogsFilters;
        const request = new GetAuditLogsRPC.Request(
          auditLogsFilters.dateRange,
          auditLogsFilters.emailAddress,
          auditLogsFilters.eventName,
          pageIndex,
          DEFAULT_ITEMS_PER_PAGE_IN_AUDIT_LOGS,
        );
        const {
          response,
          error,
        }: {
          response?: GetAuditLogsRPC.Response;
          error?: GetAuditLogsRPC.Errors.Errors;
        } = yield useGetAuditLogsRPCClient(apiClient).execute(request);

        if (response) {
          store.totalItems = response.totalItems;
          store.auditLogs = cast(
            response.logs.map((auditLog) => {
              return createAuditLogModel(auditLog);
            }),
          );
        } else if (error) {
          switch (error.code) {
            case GetAuditLogsErrors.InvalidDateRange: {
              store.rpcErrors = GetAuditLogsErrors.InvalidDateRange;
              break;
            }
            case GetAuditLogsErrors.InvalidPageIndex: {
              store.rpcErrors = GetAuditLogsErrors.InvalidPageIndex;
              break;
            }
            default: {
              /* This error is set here so that that page goes into an error state in case there is an
               *  unexpected RPC error. */
              store.rpcErrors = GetAuditLogsErrors.InvalidPageIndex;
              console.error(`Unexpected error occured: ${error}`);
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }));

export const createAuditLogsStore = (): Instance<typeof AuditLogsStore> => {
  return AuditLogsStore.create({
    filterStore: createFilterStore(),
  });
};
