import { Stack, Typography } from "@mui/material";
import { BorderStyle, EMPTY_CHARACTER } from "@pulse/shared-components";
import {
  Spacing,
  FoundationColorTokens,
  Typography as LeoTypography,
  AutoCompleteInputField,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useRef, useState } from "react";
import { SelectedBOUList } from "./SelectedBOUList";
import { AddProjectBOUStore } from "../../store/AddProjectBOUStore";

interface AddProjectBOUDialogChildProps {
  t: TFunction;
  spacing: Spacing;
  addProjectBOUStore: Instance<typeof AddProjectBOUStore>;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  border: BorderStyle;
}

export const AddProjectBOUDialogChild = observer(
  ({
    t,
    spacing,
    tokens,
    typography,
    border,
    addProjectBOUStore,
  }: AddProjectBOUDialogChildProps): React.ReactElement => {
    const [searchUserText, setSearchUserText] =
      useState<string>(EMPTY_CHARACTER);
    const isSearchUserFieldCleared = useRef(false);

    const BOUserList = observer((): React.ReactElement => {
      if (addProjectBOUStore.isSelectedBOUserListEmpty) {
        return (
          <Stack paddingBottom={spacing.spaceLG} gap={spacing.spaceSM}>
            <Typography {...typography.b2} color={tokens.labelLowEmphasis}>
              {t("projects.userAccess.addProjectBOUser.noUserSelected")}
            </Typography>
          </Stack>
        );
      } else {
        return (
          <Stack paddingBottom={spacing.spaceLG} gap={spacing.spaceSM}>
            {addProjectBOUStore.selectedBOUserDetails.map((selectedUser) => {
              return (
                <SelectedBOUList
                  key={selectedUser.boUserId}
                  boUserName={selectedUser.fullName}
                  boUserEmailId={selectedUser.emailAddress}
                  boUserId={selectedUser.boUserId}
                  addProjectBOUStore={addProjectBOUStore}
                  spacing={spacing}
                  typography={typography}
                  tokens={tokens}
                />
              );
            })}
          </Stack>
        );
      }
    });
    return (
      <Stack width="100%">
        <Stack padding={spacing.spaceLG} gap={spacing.spaceSM}>
          <Typography {...typography.b2} color={tokens.labelSubtle}>
            {t(
              "projects.userAccess.addProjectBOUser.addProjectBOUserDialogDescription",
            )}
          </Typography>
          <AutoCompleteInputField
            inputValue={searchUserText}
            disabled={addProjectBOUStore.isRPCLoading}
            placeholder={t(
              "projects.userAccess.addProjectBOUser.searchUsersPlaceholderText",
            )}
            label={EMPTY_CHARACTER}
            noOptionsText={
              "projects.userAccess.addProjectBOUser.noUserFoundText"
            }
            options={addProjectBOUStore.nonSelectedBOUserDetails.map(
              (boUserDetails) => {
                return {
                  id: boUserDetails.boUserId,
                  label: boUserDetails.searchBOUserDropdownText,
                };
              },
            )}
            onInputChange={(inputText) => {
              if (isSearchUserFieldCleared.current) {
                isSearchUserFieldCleared.current = false;
                setSearchUserText(EMPTY_CHARACTER);
                return;
              }
              setSearchUserText(inputText ? inputText : EMPTY_CHARACTER);
            }}
            onChange={(selectedItem) => {
              isSearchUserFieldCleared.current = true;
              setSearchUserText(EMPTY_CHARACTER);
              // This gets triggered when the user unselects an option, as in this case `selectedItemType` is undefined.
              if (selectedItem === null || selectedItem?.id === null) {
                console.warn(`BO user could not be found for ${selectedItem}`);
                return;
              }
              addProjectBOUStore.addSelectedBOUserId(selectedItem.id);
            }}
          />
        </Stack>
        <Stack padding={`0px ${spacing.spaceLG}`} gap={spacing.spaceMD}>
          <Stack
            padding={`${spacing.spaceXS} 0px`}
            borderBottom={border.default}
          >
            <Typography {...typography.sh3} color={tokens.label}>
              {t("projects.userAccess.addProjectBOUser.selectedUsers")}
            </Typography>
          </Stack>
          <BOUserList />
        </Stack>
      </Stack>
    );
  },
);
