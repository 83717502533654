import { Stack } from "@mui/material";
import { FULL_WIDTH, EMPTY_CHARACTER } from "@pulse/shared-components";
import {
  Spacing,
  DropdownItem,
  Button,
} from "@surya-digital/leo-reactjs-material-ui";
import { DropdownInputField } from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useState } from "react";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { PivotTableStore } from "../../store/PivotTableStore";

interface PivotTableRespondentColumnDropdownprops {
  t: TFunction;
  pivotTableStore: Instance<typeof PivotTableStore>;
  navigateTo: NavigateToFunctions;
  spacing: Spacing;
}

export const PivotTableRespondentColumnDropdown = observer(
  ({
    pivotTableStore,
    t,
    navigateTo,
    spacing,
  }: PivotTableRespondentColumnDropdownprops): React.ReactElement => {
    const [selectedRespondentColumn, setSelectedRespondentColumn] =
      useState<DropdownItem | null>(null);

    return (
      <Stack
        width={FULL_WIDTH}
        direction="row"
        padding={spacing.spaceLG}
        gap={spacing.spaceSM}
        justifyContent="flex-start"
        alignItems="center"
      >
        <DropdownInputField
          name="respondentColumns"
          label={EMPTY_CHARACTER}
          value={`${selectedRespondentColumn?.value ?? pivotTableStore.selectedColumn?.index}`}
          options={pivotTableStore.respondentColumnsDropdownOptions}
          onSelect={(selectedColumn: DropdownItem): void => {
            setSelectedRespondentColumn(selectedColumn);
          }}
          width="360px"
          placeholder={t("projects.dashboard.pivotTable.dropdownPlaceHolder")}
        />
        <Button
          label={t("projects.dashboard.viewResultsButtonText")}
          onClick={function (): void {
            pivotTableStore.setIsPivotDataFetched(false);
            pivotTableStore.setSelectedColumn(selectedRespondentColumn);
            processProjectParams(pivotTableStore.getPivotTableData, navigateTo);
            setSelectedRespondentColumn(null);
          }}
          name="view-result"
          size="large"
          variant="filled"
          disabled={
            pivotTableStore.selectedColumn?.name ===
              selectedRespondentColumn?.name ||
            selectedRespondentColumn === null
          }
        />
      </Stack>
    );
  },
);
