import { CircularProgress, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";

interface DialogLoaderContentProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  loaderText: string;
}

export const DialogLoaderContent = observer(
  ({
    spacing,
    tokens,
    typography,
    loaderText,
  }: DialogLoaderContentProps): React.ReactElement => {
    return (
      <Stack
        direction="column"
        padding={spacing.spaceXL}
        gap={spacing.spaceXS}
        alignItems="center"
        textAlign="center"
        width="100%"
      >
        <CircularProgress
          size="32px"
          style={{
            color: tokens.iconPrimary,
          }}
        />
        <Typography
          sx={{ ...typography.b1, color: tokens.labelPrimary, width: "472px" }}
        >
          {loaderText}
        </Typography>
      </Stack>
    );
  },
);
