import { observer } from "mobx-react";
import React from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import { TFunction } from "i18next";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Stack } from "@mui/material";
import {
  TextAreaInputField,
  TextInputField,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  MAX_CLOSED_SURVEY_ERROR_DESCRIPTION_TEXT_LENGTH,
  MAX_CLOSED_SURVEY_ERROR_TITLE_TEXT_LENGTH,
  MAX_MISCELLANEOUS_TEXT_LENGTH,
  MIN_MISCELLANEOUS_TEXT_LENGTH,
  MiscellaneousSettingsTextModel,
  ZERO_VALUE,
  showMaxCharacterCountIndicator,
} from "@pulse/shared-components";
import { Instance } from "mobx-state-tree";
import { BackOfficeSurveySettingsModel } from "../../models/BackOfficeSurveySettingsModel";
import { MAX_GENERIC_ERROR_MESSAGE_TITLE_LENGTH } from "@pulse/shared-components";
import { MAX_GENERIC_ERROR_MESSAGE_DESCRIPTION_LENGTH } from "@pulse/shared-components";

interface MiscellaneousMessagesSettingsProps {
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  miscellaneousSettingsTexts: Instance<typeof MiscellaneousSettingsTextModel>;
  updatedBackOfficeSurveySettings: Instance<
    typeof BackOfficeSurveySettingsModel
  >;
}

export const MiscellaneousMessagesSettings = observer(
  ({
    t,
    spacing,
    typography,
    tokens,
    miscellaneousSettingsTexts,
    updatedBackOfficeSurveySettings,
  }: MiscellaneousMessagesSettingsProps): React.ReactElement => {
    const miscellaneousSettingsTextFieldsProps = [
      {
        name: "singleSelectDropdownPleaseSelectOneText",
        value:
          miscellaneousSettingsTexts.singleSelectDropdownPleaseSelectOneText,
        onTextChange:
          miscellaneousSettingsTexts.setSingleSelectDropdownPleaseSelectOneText,
        label: t(
          "surveys.settings.miscellaneousMessagesSettings.singleSelectDropdownPleaseSelectOneTextTitle",
        ),
        placeholder: t(
          "surveys.settings.miscellaneousMessagesSettings.singleSelectDropdownPleaseSelectOneTextTitlePlaceholder",
        ),
        error:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
        showCharacterCountIndicator: (): boolean =>
          showMaxCharacterCountIndicator(
            MAX_MISCELLANEOUS_TEXT_LENGTH,
            miscellaneousSettingsTexts.singleSelectDropdownPleaseSelectOneText,
          ),
        isHelperTextVisible:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
      },
      {
        name: "singleSelectDropdownChooseOptionText",
        value: miscellaneousSettingsTexts.singleSelectDropdownChooseOptionText,
        onTextChange:
          miscellaneousSettingsTexts.setSingleSelectDropdownChooseOptionText,
        label: t(
          "surveys.settings.miscellaneousMessagesSettings.singleSelectDropdownChooseOptionTextTitle",
        ),
        placeholder: t(
          "surveys.settings.miscellaneousMessagesSettings.singleSelectDropdownChooseOptionTextTitlePlaceholder",
        ),
        error:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
        showCharacterCountIndicator: (): boolean =>
          showMaxCharacterCountIndicator(
            MAX_MISCELLANEOUS_TEXT_LENGTH,
            miscellaneousSettingsTexts.singleSelectDropdownChooseOptionText,
          ),
        isHelperTextVisible:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
      },
      {
        name: "allGridRowsNotAnsweredErrorText",
        value: miscellaneousSettingsTexts.allGridRowsNotAnsweredErrorText,
        onTextChange:
          miscellaneousSettingsTexts.setAllGridRowsNotAnsweredErrorText,
        label: t(
          "surveys.settings.miscellaneousMessagesSettings.allGridRowsNotAnsweredErrorTextTitle",
        ),
        placeholder: t(
          "surveys.settings.miscellaneousMessagesSettings.allGridRowsNotAnsweredErrorTextTitlePlaceholder",
        ),
        error:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
        showCharacterCountIndicator: (): boolean =>
          showMaxCharacterCountIndicator(
            MAX_MISCELLANEOUS_TEXT_LENGTH,
            miscellaneousSettingsTexts.allGridRowsNotAnsweredErrorText,
          ),
        isHelperTextVisible:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
      },
      {
        name: "allAttemptedGridRowsNotAnsweredErrorText",
        value:
          miscellaneousSettingsTexts.allAttemptedGridRowsNotAnsweredErrorText,
        onTextChange:
          miscellaneousSettingsTexts.setAllAttemptedGridRowsNotAnsweredErrorText,
        label: t(
          "surveys.settings.miscellaneousMessagesSettings.allAttemptedGridRowsNotAnsweredErrorTextTitle",
        ),
        placeholder: t(
          "surveys.settings.miscellaneousMessagesSettings.allAttemptedGridRowsNotAnsweredErrorTextTitlePlaceholder",
        ),
        error:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
        showCharacterCountIndicator: (): boolean =>
          showMaxCharacterCountIndicator(
            MAX_MISCELLANEOUS_TEXT_LENGTH,
            miscellaneousSettingsTexts.allAttemptedGridRowsNotAnsweredErrorText,
          ),
        isHelperTextVisible:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
      },
      {
        name: "areAllRankingOptionsMandatoryText",
        value: miscellaneousSettingsTexts.areAllRankingOptionsMandatoryText,
        onTextChange:
          miscellaneousSettingsTexts.setAreAllRankingOptionsMandatoryText,
        label: t(
          "surveys.settings.miscellaneousMessagesSettings.areAllRankingOptionsMandatoryTextTitle",
        ),
        placeholder: t(
          "surveys.settings.miscellaneousMessagesSettings.areAllRankingOptionsMandatoryTextTitlePlaceholder",
        ),
        error:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
        showCharacterCountIndicator: (): boolean =>
          showMaxCharacterCountIndicator(
            MAX_MISCELLANEOUS_TEXT_LENGTH,
            miscellaneousSettingsTexts.areAllRankingOptionsMandatoryText,
          ),
        isHelperTextVisible:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
      },
      {
        name: "allRankingOptionsMandatory",
        value: miscellaneousSettingsTexts.allRankingOptionsMandatory,
        onTextChange: miscellaneousSettingsTexts.setAllRankingOptionsMandatory,
        label: t(
          "surveys.settings.miscellaneousMessagesSettings.allRankingOptionsMandatoryTitle",
        ),
        placeholder: t(
          "surveys.settings.miscellaneousMessagesSettings.allRankingOptionsMandatoryTitlePlaceholder",
        ),
        error:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
        showCharacterCountIndicator: (): boolean =>
          showMaxCharacterCountIndicator(
            MAX_MISCELLANEOUS_TEXT_LENGTH,
            miscellaneousSettingsTexts.allRankingOptionsMandatory,
          ),
        isHelperTextVisible:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
      },
      {
        name: "singleSelectSliderTooltipText",
        value: miscellaneousSettingsTexts.singleSelectSliderTooltipText,
        onTextChange:
          miscellaneousSettingsTexts.setSingleSelectSliderTooltipText,
        label: t(
          "surveys.settings.miscellaneousMessagesSettings.singleSelectSliderTooltipTextTitle",
        ),
        placeholder: t(
          "surveys.settings.miscellaneousMessagesSettings.singleSelectSliderTooltipTextTitlePlaceholder",
        ),
        error:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
        showCharacterCountIndicator: (): boolean =>
          showMaxCharacterCountIndicator(
            MAX_MISCELLANEOUS_TEXT_LENGTH,
            miscellaneousSettingsTexts.singleSelectSliderTooltipText,
          ),
        isHelperTextVisible:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
      },
      {
        name: "mandatoryQuestionErrorText",
        value: miscellaneousSettingsTexts.mandatoryQuestionErrorText,
        onTextChange: miscellaneousSettingsTexts.setMandatoryQuestionErrorText,
        label: t(
          "surveys.settings.miscellaneousMessagesSettings.mandatoryQuestionErrorTextTitle",
        ),
        placeholder: t(
          "surveys.settings.miscellaneousMessagesSettings.mandatoryQuestionErrorTextTitlePlaceholder",
        ),
        error:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
        showCharacterCountIndicator: (): boolean =>
          showMaxCharacterCountIndicator(
            MAX_MISCELLANEOUS_TEXT_LENGTH,
            miscellaneousSettingsTexts.mandatoryQuestionErrorText,
          ),
        isHelperTextVisible:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
      },
      {
        name: "multiSelectAndRankingMaxSelectionText",
        value: miscellaneousSettingsTexts.multiSelectAndRankingMaxSelectionText,
        onTextChange:
          miscellaneousSettingsTexts.setMultiSelectAndRankingMaxSelectionText,
        label: t(
          "surveys.settings.miscellaneousMessagesSettings.multiSelectAndRankingMaxSelectionTextTitle",
        ),
        placeholder: t(
          "surveys.settings.miscellaneousMessagesSettings.multiSelectAndRankingMaxSelectionTextTitlePlaceholder",
        ),
        error:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
        showCharacterCountIndicator: (): boolean =>
          showMaxCharacterCountIndicator(
            MAX_MISCELLANEOUS_TEXT_LENGTH,
            miscellaneousSettingsTexts.multiSelectAndRankingMaxSelectionText,
          ),
        isHelperTextVisible:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
      },
      {
        name: "questionRequiredText",
        value: miscellaneousSettingsTexts.questionRequiredText,
        onTextChange: miscellaneousSettingsTexts.setQuestionRequiredText,
        label: t(
          "surveys.settings.miscellaneousMessagesSettings.questionRequiredTextTitle",
        ),
        placeholder: t(
          "surveys.settings.miscellaneousMessagesSettings.questionRequiredTextTitlePlaceholder",
        ),
        error:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
        showCharacterCountIndicator: (): boolean =>
          showMaxCharacterCountIndicator(
            MAX_MISCELLANEOUS_TEXT_LENGTH,
            miscellaneousSettingsTexts.questionRequiredText,
          ),
        isHelperTextVisible:
          updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
            .isMiscellaneousTextInvalid,
      },
    ];

    return (
      <SettingsContainer
        settingHeadingText={t(
          "surveys.settings.miscellaneousMessagesSettings.miscellaneousMessagesTitleText",
        )}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
      >
        <Stack gap={spacing.spaceLG} width="400px">
          <TextInputField
            name="genericErrorMessageTitle"
            type={"text"}
            value={
              updatedBackOfficeSurveySettings.surveySettings
                .genericErrorMessageTitleText
            }
            onTextChange={(errorText: string) => {
              updatedBackOfficeSurveySettings.surveySettings.setGenericErrorMessageTitleText(
                errorText.slice(
                  ZERO_VALUE,
                  MAX_GENERIC_ERROR_MESSAGE_TITLE_LENGTH,
                ),
              );
              updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.clearError();
            }}
            label={t(
              "surveys.settings.miscellaneousMessagesSettings.genericErrorMessageTitleLabelText",
            )}
            placeholder={t(
              "surveys.settings.miscellaneousMessagesSettings.genericErrorMessageTitlePlaceholderText",
            )}
            required={true}
            error={updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.isMiscellaneousTextInvalid(
              updatedBackOfficeSurveySettings.surveySettings
                .genericErrorMessageTitleText,
            )}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_GENERIC_ERROR_MESSAGE_TITLE_LENGTH,
                updatedBackOfficeSurveySettings.surveySettings
                  .genericErrorMessageTitleText,
              )
            }
            helperText={
              updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.isMiscellaneousTextInvalid(
                updatedBackOfficeSurveySettings.surveySettings
                  .genericErrorMessageTitleText,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_MISCELLANEOUS_TEXT_LENGTH,
                  })
                : undefined
            }
            maxCharacterLength={MAX_GENERIC_ERROR_MESSAGE_TITLE_LENGTH}
          />
          <TextAreaInputField
            name="genericErrorMessageDescription"
            value={
              updatedBackOfficeSurveySettings.surveySettings
                .genericErrorMessageDescriptionText
            }
            onTextChange={(inputString) => {
              updatedBackOfficeSurveySettings.surveySettings.setGenericErrorMessageDescriptionText(
                inputString.slice(
                  ZERO_VALUE,
                  MAX_GENERIC_ERROR_MESSAGE_DESCRIPTION_LENGTH,
                ),
              );
              updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.clearError();
            }}
            error={updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.isMiscellaneousTextInvalid(
              updatedBackOfficeSurveySettings.surveySettings
                .genericErrorMessageDescriptionText,
            )}
            label={t(
              "surveys.settings.miscellaneousMessagesSettings.genericErrorMessageDescriptionLabelText",
            )}
            placeholder={t(
              "surveys.settings.miscellaneousMessagesSettings.genericErrorMessageDescriptionPlaceholderText",
            )}
            required={true}
            maxCharacterLength={MAX_GENERIC_ERROR_MESSAGE_DESCRIPTION_LENGTH}
            helperText={
              updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.isMiscellaneousTextInvalid(
                updatedBackOfficeSurveySettings.surveySettings
                  .genericErrorMessageDescriptionText,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_MISCELLANEOUS_TEXT_LENGTH,
                  })
                : undefined
            }
            showCharacterCountIndicator={(): boolean => {
              return showMaxCharacterCountIndicator(
                MAX_GENERIC_ERROR_MESSAGE_DESCRIPTION_LENGTH,
                updatedBackOfficeSurveySettings.surveySettings
                  .genericErrorMessageDescriptionText,
              );
            }}
          />
          <TextInputField
            name="titleText"
            value={updatedBackOfficeSurveySettings.surveyClosedMessageTitleText}
            type="text"
            onTextChange={(errorTextTitle) => {
              updatedBackOfficeSurveySettings.setSurveyClosedMessageTitleText(
                errorTextTitle.slice(
                  ZERO_VALUE,
                  MAX_CLOSED_SURVEY_ERROR_TITLE_TEXT_LENGTH,
                ),
              );
              updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.clearError();
            }}
            label={t(
              "surveys.settings.miscellaneousMessagesSettings.surveyCloseMessageTitleLabelText",
            )}
            placeholder={t(
              "surveys.settings.miscellaneousMessagesSettings.surveyCloseMessageTitlePlaceholderText",
            )}
            required={true}
            error={updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.isMiscellaneousTextInvalid(
              updatedBackOfficeSurveySettings.surveyClosedMessageTitleText,
            )}
            showCharacterCountIndicator={(): boolean => {
              return showMaxCharacterCountIndicator(
                MAX_CLOSED_SURVEY_ERROR_TITLE_TEXT_LENGTH,
                updatedBackOfficeSurveySettings.surveyClosedMessageTitleText,
              );
            }}
            helperText={
              updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.isMiscellaneousTextInvalid(
                updatedBackOfficeSurveySettings.surveyClosedMessageTitleText,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_MISCELLANEOUS_TEXT_LENGTH,
                  })
                : undefined
            }
            maxCharacterLength={MAX_CLOSED_SURVEY_ERROR_TITLE_TEXT_LENGTH}
          />
          <TextAreaInputField
            name="descriptionText"
            value={
              updatedBackOfficeSurveySettings.surveyClosedMessageDescriptionText
            }
            onTextChange={(errorTextDescription) => {
              updatedBackOfficeSurveySettings.setSurveyClosedMessageDescriptionText(
                errorTextDescription.slice(
                  ZERO_VALUE,
                  MAX_CLOSED_SURVEY_ERROR_DESCRIPTION_TEXT_LENGTH,
                ),
              );
              updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.clearError();
            }}
            label={t(
              "surveys.settings.miscellaneousMessagesSettings.surveyCloseMessageDescriptionLabelText",
            )}
            placeholder={t(
              "surveys.settings.miscellaneousMessagesSettings.surveyCloseMessageDescriptionPlaceholderText",
            )}
            required={true}
            error={updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.isMiscellaneousTextInvalid(
              updatedBackOfficeSurveySettings.surveyClosedMessageDescriptionText,
            )}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_CLOSED_SURVEY_ERROR_DESCRIPTION_TEXT_LENGTH,
                updatedBackOfficeSurveySettings.surveyClosedMessageDescriptionText,
              )
            }
            helperText={
              updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.isMiscellaneousTextInvalid(
                updatedBackOfficeSurveySettings.surveyClosedMessageDescriptionText,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_MISCELLANEOUS_TEXT_LENGTH,
                  })
                : undefined
            }
            maxCharacterLength={MAX_CLOSED_SURVEY_ERROR_DESCRIPTION_TEXT_LENGTH}
          />
          {miscellaneousSettingsTextFieldsProps.map(
            (miscellaneousSettingsTextField, index) => {
              const getHelpetText = (): string | undefined => {
                if (
                  miscellaneousSettingsTextField.isHelperTextVisible(
                    miscellaneousSettingsTextField.value,
                  )
                ) {
                  return t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_MISCELLANEOUS_TEXT_LENGTH,
                  });
                }
                return undefined;
              };

              return (
                <TextInputField
                  key={index}
                  name={miscellaneousSettingsTextField.name}
                  value={miscellaneousSettingsTextField.value}
                  type={"text"}
                  onTextChange={function (value: string): void {
                    miscellaneousSettingsTextField.onTextChange(
                      value.slice(ZERO_VALUE, MAX_MISCELLANEOUS_TEXT_LENGTH),
                    );
                    updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.clearError();
                  }}
                  label={miscellaneousSettingsTextField.label}
                  placeholder={miscellaneousSettingsTextField.placeholder}
                  required={true}
                  error={miscellaneousSettingsTextField.error(
                    miscellaneousSettingsTextField.value,
                  )}
                  showCharacterCountIndicator={
                    miscellaneousSettingsTextField.showCharacterCountIndicator
                  }
                  maxCharacterLength={MAX_MISCELLANEOUS_TEXT_LENGTH}
                  helperText={getHelpetText()}
                />
              );
            },
          )}
        </Stack>
      </SettingsContainer>
    );
  },
);
