import { Instance, types } from "mobx-state-tree";
import {
  SpocUserDetailsModel,
  createSpocUserDetailsModel,
} from "../../models/SpocUserDetailsModel";
import { EmbedDetail } from "@pulse/pulse-rpcs";

export const EmbedDetailsModel = types.model("EmbedDetailsModel", {
  id: types.string,
  embedName: types.string,
  embedCode: types.string,
  addedOn: types.Date,
  spocs: types.array(SpocUserDetailsModel),
});

export const createEmbedDetailsModel = (
  embedDetail: EmbedDetail,
): Instance<typeof EmbedDetailsModel> => {
  return EmbedDetailsModel.create({
    id: embedDetail.id.uuid,
    embedName: embedDetail.name.name,
    embedCode: embedDetail.code.code,
    addedOn: new Date(embedDetail.addedOn.date),
    spocs: embedDetail.spocs.map((spocUserDetail) => {
      return createSpocUserDetailsModel(spocUserDetail);
    }),
  });
};
