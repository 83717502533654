import { Instance, cast, flow, getRoot, types } from "mobx-state-tree";
import {
  ProjectDetailsStore,
  createProjectDetailsStore,
} from "./ProjectDetailsStore";
import {
  ProjectDetailsModel,
  createProjectDetailsModel,
} from "../models/ProjectDetailsModel";
import { TableSortOption } from "@surya-digital/leo-reactjs-material-ui";
import { getAPIClient } from "../../networking/APIClient";
import {
  ArchiveProjectRPC,
  CreateNewProjectRPC,
  GetAllActiveProjectsRPC,
  GetAllActiveProjectsSortOrder,
  ProjectItemsPerPage,
  ProjectPageIndex,
  ProjectSortOrder,
  ProjectName,
  GetActiveSpocProjectsSortOrder,
  GetActiveSpocProjectsRPC,
  GetArchivedSpocProjectsSortOrder,
  GetArchivedSpocProjectsRPC,
  CloneProjectRPC,
  GetAllArchivedProjectsSortOrder,
  GetArchivedResearcherProjectsSortOrder,
  GetArchivedResearcherProjectsRPC,
  GetAllArchivedProjectsRPC,
  GetActiveResearcherProjectsSortOrder,
  GetActiveResearcherProjectsRPC,
} from "@pulse/pulse-rpcs";
import { LeoDate, LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useArchiveProjectRPCClientImpl,
  useCloneProjectRPCClientImpl,
  useCreateNewProjectRPCClientImpl,
  useGetAllActiveProjectsRPCClient,
  useGetActiveSpocProjectsRPCClient,
  useGetAllArchivedProjectsRPCClient,
  useGetArchivedSpocProjectsRPCClient,
  useGetArchivedResearcherProjectsRPCClient,
  useGetActiveResearcherProjectsRPCClient,
} from "../rpcs/RPC";
import { RootStore } from "../../root/store/RootStore";
import {
  FilterStore,
  createFilterStore,
} from "../../../components/filter/store/FilterStore";
import {
  CommonErrors,
  DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE,
  DEFAULT_PROJECT_LIST_SORT_INDEX,
  EMPTY_CHARACTER,
  EMPTY_LIST_LENGTH,
  ZERO_VALUE,
  projectAndSurveyNameRegex,
} from "@pulse/shared-components";
import { ProjectListHeaderEnum } from "../components/projectList/ProjectListComponent";
import { GetAllActiveProjectsSortOrderEnums } from "@pulse/pulse-rpcs";
import { ProjectListTabs } from "../pages/ProjectsPage";
import { getFormatedLeoTimeStamp } from "../../utils/DateUtils";
import {
  ProjectIdAndNameModel,
  createProjectIdAndNameModel,
} from "../models/ProjectIdAndNameModel";
import {
  AuditLogsStore,
  createAuditLogsStore,
} from "../../auditLogs/store/AuditLogsStore";
import {
  ProfileStore,
  createProfileStore,
} from "../../profileSettings/store/ProfileStore";

export enum GetProjectsListRPCErrors {
  InvalidPageIndex = "INVALID_PAGE_INDEX",
  InvalidDateRange = "INVALID_DATE_RANGE",
}

// This enum is for the errors returned from the RPC.
export enum CloneCreateNewProjectRPCErrors {
  ProjectNameNotUnique = "PROJECT_NAME_NOT_UNIQUE",
  InvalidProjectName = "INVALID_PROJECT_NAME",
  InvalidProjectDates = "INVALID_PROJECT_DATES",
}

// This enum is for the validation errors done only on the front-end.
export enum ProjectNameValidationErrors {
  InvalidProjectNameLength = "INVALID_PROJECT_NAME_LENGTH",
  InvalidProjectName = "INVALID_PROJECT_NAME",
}

export enum CloneCreateNewProjectDialogState {
  CloneCreateNewProject = "CREATE_NEW_PROJECT",
  Error = "ERROR",
}

export enum ArchiveProjectRPCErrors {
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
}

export enum ArchiveProjectValidationErrors {
  InvalidProjectName = "INVALID_PROJECT_NAME",
}

export interface ProjectsListFilters {
  projectName: string | null | undefined;
  creator: string | null | undefined;
  surveys: string | null | undefined;
  createdOnStartDate: LeoDate | null | undefined;
  createdOnEndDate: LeoDate | null | undefined;
  lastModifiedStartDate: LeoDate | null | undefined;
  lastModifiedEndDate: LeoDate | null | undefined;
}

export interface ProjectListSortOptions {
  columnName: GetAllActiveProjectsSortOrderEnums.ColumnName.ColumnName;
  order: ProjectSortOrder.ProjectSortOrder;
}

export const ProjectsRootStore = types
  .model("ProjectsRootStore", {
    projectId: types.maybeNull(types.string),
    projectDetailsStore: ProjectDetailsStore,
    filterStore: FilterStore,
    activeProjectsList: types.array(ProjectDetailsModel),
    archivedProjectsList: types.array(ProjectDetailsModel),
    totalItems: types.optional(types.number, ZERO_VALUE),
    rpcErrors: types.maybeNull(
      types.union(
        types.enumeration(
          "GetProjectsListRPCErrors",
          Object.values(GetProjectsListRPCErrors),
        ),
        types.enumeration(
          "CreateNewProjectRPCErrors",
          Object.values(CloneCreateNewProjectRPCErrors),
        ),
        types.enumeration(
          "ArchiveProjectRPCErrors",
          Object.values(ArchiveProjectRPCErrors),
        ),
      ),
    ),
    cloneCreateNewProjectDialogState: types.optional(
      types.enumeration(
        "CloneCreateNewProjectDialogState",
        Object.values(CloneCreateNewProjectDialogState),
      ),
      CloneCreateNewProjectDialogState.CloneCreateNewProject,
    ),
    projectNameValidationErrors: types.maybeNull(
      types.enumeration(
        "ProjectNameValidationErrors",
        Object.values(ProjectNameValidationErrors),
      ),
    ),
    archiveProjectValidationErrors: types.maybeNull(
      types.enumeration(
        "ArchiveProjectValidationErrors",
        Object.values(ArchiveProjectValidationErrors),
      ),
    ),
    isRPCLoading: types.optional(types.boolean, false),
    tabIndex: types.optional(types.number, ProjectListTabs.ActiveProjects),
    isGetProjectListRPCCalled: types.optional(types.boolean, false),
    // This is added to the store to fix the issue of the project list re-rendering when the dialog box is open.
    isCreateNewProjectDialogOpen: types.optional(types.boolean, false),
    selectedProjectToArchive: types.maybeNull(ProjectIdAndNameModel),
    archivedProjectName: types.optional(types.string, EMPTY_CHARACTER),
    isArchiveProjectTextFieldChanged: types.optional(types.boolean, false),
    auditLogsStore: AuditLogsStore,
    profileStore: ProfileStore,
  })
  .views((store) => ({
    get isArchiveProjectButtonDisabled(): boolean {
      return (
        store.rpcErrors !== null ||
        (store.archivedProjectName === EMPTY_CHARACTER &&
          !store.isArchiveProjectTextFieldChanged) ||
        store.archiveProjectValidationErrors !== null
      );
    },
    get doesStoreContainValidationErrors(): boolean {
      return store.projectNameValidationErrors !== null;
    },
    get isCreateNewProjectDialogErrorState(): boolean {
      return (
        store.cloneCreateNewProjectDialogState ===
        CloneCreateNewProjectDialogState.Error
      );
    },
    get isCreateProjectSecondaryButtonDisabled(): boolean {
      return store.isRPCLoading;
    },
    get isCreateProjectPrimaryButtonDisabled(): boolean {
      return (
        store.projectDetailsStore.projectName.trim() === EMPTY_CHARACTER ||
        store.isRPCLoading ||
        store.projectDetailsStore.projectEndDate === null ||
        store.projectDetailsStore.projectStartDate === null
      );
    },
    get doesProjectTextFieldContainErrors(): boolean {
      return (
        store.rpcErrors === CloneCreateNewProjectRPCErrors.InvalidProjectName ||
        store.rpcErrors === CloneCreateNewProjectRPCErrors.ProjectNameNotUnique
      );
    },
    get doesStoreContainCloneCreateProjectErrors(): boolean {
      return (
        store.rpcErrors ===
          CloneCreateNewProjectRPCErrors.ProjectNameNotUnique ||
        store.rpcErrors === CloneCreateNewProjectRPCErrors.InvalidProjectName ||
        store.rpcErrors === CloneCreateNewProjectRPCErrors.InvalidProjectDates
      );
    },
    get doesStoreContainProjectListErrors(): boolean {
      return (
        store.rpcErrors === GetProjectsListRPCErrors.InvalidDateRange ||
        store.rpcErrors === GetProjectsListRPCErrors.InvalidPageIndex
      );
    },
    get doesStoreContainArchiveProjectErrors(): boolean {
      return store.rpcErrors === ArchiveProjectRPCErrors.ProjectAlreadyArchived;
    },
    get doesStoreContainArchiveProjectValidationErrors(): boolean {
      return store.archiveProjectValidationErrors !== null;
    },
    get isActiveProjectsListEmpty(): boolean {
      return store.activeProjectsList.length === EMPTY_LIST_LENGTH;
    },
    get isArchivedProjectsListEmpty(): boolean {
      return store.archivedProjectsList.length === EMPTY_LIST_LENGTH;
    },
    getProjectNameByProjectId(
      isArchivedList: boolean,
      projectId: string,
    ): string | undefined {
      const projectList = isArchivedList
        ? store.archivedProjectsList
        : store.activeProjectsList;
      return projectList.find((project) => {
        return project.projectId === projectId;
      })?.projectName;
    },
    isPaginatedListVisible(isArchivedList: boolean): boolean {
      const isProjectListEmpty = isArchivedList
        ? store.archivedProjectsList.length === EMPTY_LIST_LENGTH
        : store.activeProjectsList.length === EMPTY_LIST_LENGTH;
      if (store.isGetProjectListRPCCalled) {
        return !(
          store.filterStore.appliedFilters.length === EMPTY_LIST_LENGTH &&
          isProjectListEmpty
        );
      } else {
        return true;
      }
    },
    projectListSortOptions(
      sort: TableSortOption | undefined,
    ): ProjectListSortOptions {
      let columnName =
        GetAllActiveProjectsSortOrderEnums.ColumnName.ColumnName.CREATED_ON;
      if (sort === undefined) {
        return {
          columnName,
          order: ProjectSortOrder.ProjectSortOrder.DESCENDING,
        };
      }
      switch (sort.id) {
        case ProjectListHeaderEnum.LastModified: {
          columnName =
            GetAllActiveProjectsSortOrderEnums.ColumnName.ColumnName
              .LAST_MODIFIED;
          break;
        }
        case ProjectListHeaderEnum.Project: {
          columnName =
            GetAllActiveProjectsSortOrderEnums.ColumnName.ColumnName
              .PROJECT_NAME;
          break;
        }
      }
      return {
        columnName,
        order:
          sort.order === "asc"
            ? ProjectSortOrder.ProjectSortOrder.ASCENDING
            : ProjectSortOrder.ProjectSortOrder.DESCENDING,
      };
    },
    get projectListFilters(): ProjectsListFilters {
      let projectName: string | null | undefined = null;
      let creator: string | null | undefined = null;
      let surveys: string | null | undefined = null;
      let createdOnEndDate: LeoDate | null | undefined = null;
      let createdOnStartDate: LeoDate | null | undefined = null;
      let lastModifiedEndDate: LeoDate | null | undefined = null;
      let lastModifiedStartDate: LeoDate | null | undefined = null;
      if (store.filterStore.appliedFilters.length !== EMPTY_LIST_LENGTH) {
        store.filterStore.appliedFilters.forEach((filter) => {
          switch (filter.key) {
            case ProjectListHeaderEnum.Project: {
              projectName = filter.openEndedFilterValue;
              break;
            }
            case ProjectListHeaderEnum.Creator: {
              creator = filter.openEndedFilterValue;
              break;
            }
            case ProjectListHeaderEnum.Surveys: {
              surveys = filter.openEndedFilterValue;
              break;
            }
            case ProjectListHeaderEnum.CreatedOn: {
              if (filter.dateRangeFilterValue?.startDate !== null) {
                createdOnStartDate = filter.dateRangeFilterValue?.leoStartDate;
              }
              if (filter.dateRangeFilterValue?.endDate !== null) {
                createdOnEndDate = filter.dateRangeFilterValue?.leoEndDate;
              }
              break;
            }
            case ProjectListHeaderEnum.LastModified: {
              if (filter.dateRangeFilterValue?.startDate !== null) {
                lastModifiedStartDate =
                  filter.dateRangeFilterValue?.leoStartDate;
              }
              if (filter.dateRangeFilterValue?.endDate !== null) {
                lastModifiedEndDate = filter.dateRangeFilterValue?.leoEndDate;
              }
              break;
            }
          }
        });
      }
      return {
        projectName,
        creator,
        surveys,
        createdOnEndDate,
        createdOnStartDate,
        lastModifiedEndDate,
        lastModifiedStartDate,
      };
    },
  }))
  .actions((store) => ({
    resetTabIndex: (): void => {
      store.tabIndex = ProjectListTabs.ActiveProjects;
    },
    isArchivedProjectNameValid: (): boolean => {
      if (
        store.archivedProjectName !==
        store.selectedProjectToArchive?.projectName
      ) {
        store.archiveProjectValidationErrors =
          ArchiveProjectValidationErrors.InvalidProjectName;
        return false;
      } else {
        store.archiveProjectValidationErrors = null;
        return true;
      }
    },
    clearArchiveProjectDetails: (): void => {
      store.rpcErrors = null;
      store.archivedProjectName = EMPTY_CHARACTER;
      store.selectedProjectToArchive = null;
      store.archiveProjectValidationErrors = null;
    },
    setArchivedProjectName: (archivedProjectName: string): void => {
      store.archivedProjectName = archivedProjectName;
    },
    setSelectedProjectDetails: (
      projectId: string,
      projectName: string,
    ): void => {
      store.selectedProjectToArchive = createProjectIdAndNameModel(
        projectId,
        projectName,
      );
    },
    setSelectedProjectDetailsByProjectId: (
      projectId: string,
    ): Instance<typeof ProjectDetailsModel> | undefined => {
      const selectedProject = store.activeProjectsList.find((project) => {
        return project.projectId === projectId;
      });
      if (selectedProject === undefined) {
        console.error(`No project found for project Id: ${projectId}`);
        return selectedProject;
      }
      store.selectedProjectToArchive = createProjectIdAndNameModel(
        selectedProject.projectId,
        selectedProject.projectName,
      );
    },
    resetProjectNameValidationErrors: (): void => {
      store.projectNameValidationErrors = null;
    },
    isProjectNameValid: (): boolean => {
      try {
        new ProjectName(store.projectDetailsStore.projectName.trim());
        const regex = new RegExp(projectAndSurveyNameRegex);
        if (!regex.test(store.projectDetailsStore.projectName)) {
          store.projectNameValidationErrors =
            ProjectNameValidationErrors.InvalidProjectName;
          return false;
        }
        return true;
      } catch (e) {
        store.projectNameValidationErrors =
          ProjectNameValidationErrors.InvalidProjectNameLength;
        return false;
      }
    },
    setIsCreateNewProjectDialogOpen: (
      isCreateNewProjectDialogOpen: boolean,
    ): void => {
      store.isCreateNewProjectDialogOpen = isCreateNewProjectDialogOpen;
    },
    clearRpcErrors: (): void => {
      store.rpcErrors = null;
    },
    clearArchiveProjectValidationErrors: (): void => {
      store.archiveProjectValidationErrors = null;
    },
    clearProjectCreationDetailsAndRpcErrors: (): void => {
      store.projectDetailsStore.setProjectName(EMPTY_CHARACTER);
      store.projectDetailsStore.setProjectDateRangeValue({
        startDate: null,
        endDate: null,
      });
      store.rpcErrors = null;
      store.cloneCreateNewProjectDialogState =
        CloneCreateNewProjectDialogState.CloneCreateNewProject;
    },
    resetIsGetProjectListRPCCalled(): void {
      store.isGetProjectListRPCCalled = false;
    },
    setTabIndex(tabIndex: number): void {
      store.tabIndex = tabIndex;
    },
    getAllActiveProjects: flow(function* (
      pageIndex: number,
      sort?: TableSortOption,
    ) {
      store.isRPCLoading = true;
      const sortOption = [
        new GetAllActiveProjectsSortOrder(
          DEFAULT_PROJECT_LIST_SORT_INDEX,
          store.projectListSortOptions(sort).columnName,
          store.projectListSortOptions(sort).order,
        ),
      ];

      try {
        const apiClient = getAPIClient(store);
        const projectListFilters = store.projectListFilters;
        const request = new GetAllActiveProjectsRPC.Request(
          sortOption,
          projectListFilters.projectName,
          projectListFilters.creator,
          projectListFilters.surveys,
          projectListFilters.createdOnStartDate,
          projectListFilters.createdOnEndDate,
          projectListFilters.lastModifiedStartDate,
          projectListFilters.lastModifiedEndDate,
          new ProjectItemsPerPage(DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE),
          new ProjectPageIndex(pageIndex),
        );
        const {
          response,
          error,
        }: {
          response?: GetAllActiveProjectsRPC.Response;
          error?: GetAllActiveProjectsRPC.Errors.Errors;
        } = yield useGetAllActiveProjectsRPCClient(apiClient).execute(request);
        if (response) {
          store.activeProjectsList = cast(
            response.getAllActiveProjectsPaginationResponse.map(
              (paginationResponse) => {
                return createProjectDetailsModel(
                  paginationResponse.projectId.uuid,
                  paginationResponse.creator,
                  paginationResponse.projectName,
                  paginationResponse.surveys,
                  paginationResponse.createdOn.timestamp,
                  paginationResponse.lastModified.timestamp,
                );
              },
            ),
          );
          store.totalItems = response.totalItems;
        } else if (error) {
          switch (error.code) {
            case GetProjectsListRPCErrors.InvalidDateRange: {
              store.rpcErrors = GetProjectsListRPCErrors.InvalidDateRange;
              break;
            }
            case GetProjectsListRPCErrors.InvalidPageIndex: {
              store.rpcErrors = GetProjectsListRPCErrors.InvalidPageIndex;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        store.isGetProjectListRPCCalled = true;
      }
    }),
    getAllArchivedProjects: flow(function* (
      pageIndex: number,
      sort?: TableSortOption,
    ) {
      try {
        const apiClient = getAPIClient(store);
        const sortOption = [
          new GetAllArchivedProjectsSortOrder(
            DEFAULT_PROJECT_LIST_SORT_INDEX,
            store.projectListSortOptions(sort).columnName,
            store.projectListSortOptions(sort).order,
          ),
        ];
        const projectListFilters = store.projectListFilters;
        const request = new GetAllArchivedProjectsRPC.Request(
          sortOption,
          projectListFilters.projectName,
          projectListFilters.creator,
          projectListFilters.surveys,
          projectListFilters.createdOnStartDate,
          projectListFilters.createdOnEndDate,
          projectListFilters.lastModifiedStartDate,
          projectListFilters.lastModifiedEndDate,

          new ProjectItemsPerPage(DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE),
          new ProjectPageIndex(pageIndex),
        );
        const {
          response,
          error,
        }: {
          response?: GetAllArchivedProjectsRPC.Response;
          error?: GetAllArchivedProjectsRPC.Errors.Errors;
        } =
          yield useGetAllArchivedProjectsRPCClient(apiClient).execute(request);
        if (response) {
          store.archivedProjectsList = cast(
            response.getAllArchivedProjectsPaginationResponse.map(
              (paginationResponse) => {
                return createProjectDetailsModel(
                  paginationResponse.projectId.uuid,
                  paginationResponse.creator,
                  paginationResponse.projectName,
                  paginationResponse.surveys,
                  paginationResponse.createdOn.timestamp,
                  paginationResponse.lastModified.timestamp,
                );
              },
            ),
          );
          store.totalItems = response.totalItems;
        } else if (error) {
          switch (error.code) {
            case GetProjectsListRPCErrors.InvalidDateRange: {
              store.rpcErrors = GetProjectsListRPCErrors.InvalidDateRange;
              break;
            }
            case GetProjectsListRPCErrors.InvalidPageIndex: {
              store.rpcErrors = GetProjectsListRPCErrors.InvalidPageIndex;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        store.isGetProjectListRPCCalled = true;
      }
    }),
    getArchivedResearcherProjects: flow(function* (
      pageIndex: number,
      boUserId: string,
      sort?: TableSortOption,
    ) {
      try {
        const apiClient = getAPIClient(store);
        const sortOption = [
          new GetArchivedResearcherProjectsSortOrder(
            DEFAULT_PROJECT_LIST_SORT_INDEX,
            store.projectListSortOptions(sort).columnName,
            store.projectListSortOptions(sort).order,
          ),
        ];
        const projectListFilters = store.projectListFilters;
        const request = new GetArchivedResearcherProjectsRPC.Request(
          sortOption,
          projectListFilters.projectName,
          projectListFilters.creator,
          projectListFilters.surveys,
          projectListFilters.createdOnStartDate,
          projectListFilters.createdOnEndDate,
          projectListFilters.lastModifiedStartDate,
          projectListFilters.lastModifiedEndDate,
          boUserId,
          new ProjectItemsPerPage(DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE),
          new ProjectPageIndex(pageIndex),
        );
        const {
          response,
          error,
        }: {
          response?: GetArchivedResearcherProjectsRPC.Response;
          error?: GetArchivedResearcherProjectsRPC.Errors.Errors;
        } =
          yield useGetArchivedResearcherProjectsRPCClient(apiClient).execute(
            request,
          );
        if (response) {
          store.archivedProjectsList = cast(
            response.getArchivedResearcherProjectsPaginationResponse.map(
              (paginationResponse) => {
                return createProjectDetailsModel(
                  paginationResponse.projectId.uuid,
                  paginationResponse.creator,
                  paginationResponse.projectName,
                  paginationResponse.surveys,
                  paginationResponse.createdOn.timestamp,
                  paginationResponse.lastModified.timestamp,
                );
              },
            ),
          );
          store.totalItems = response.totalItems;
        } else if (error) {
          switch (error.code) {
            case GetProjectsListRPCErrors.InvalidDateRange: {
              store.rpcErrors = GetProjectsListRPCErrors.InvalidDateRange;
              break;
            }
            case GetProjectsListRPCErrors.InvalidPageIndex: {
              store.rpcErrors = GetProjectsListRPCErrors.InvalidPageIndex;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        store.isGetProjectListRPCCalled = true;
      }
    }),
    getActiveResearcherProjects: flow(function* (
      pageIndex: number,
      boUserId: string,
      sort?: TableSortOption,
    ) {
      store.isRPCLoading = true;
      const sortOption = [
        new GetActiveResearcherProjectsSortOrder(
          DEFAULT_PROJECT_LIST_SORT_INDEX,
          store.projectListSortOptions(sort).columnName,
          store.projectListSortOptions(sort).order,
        ),
      ];

      try {
        const apiClient = getAPIClient(store);
        const projectListFilters = store.projectListFilters;
        const request = new GetActiveResearcherProjectsRPC.Request(
          sortOption,
          projectListFilters.projectName,
          projectListFilters.creator,
          projectListFilters.surveys,
          projectListFilters.createdOnStartDate,
          projectListFilters.createdOnEndDate,
          projectListFilters.lastModifiedStartDate,
          projectListFilters.lastModifiedEndDate,
          boUserId,
          new ProjectItemsPerPage(DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE),
          new ProjectPageIndex(pageIndex),
        );
        const {
          response,
          error,
        }: {
          response?: GetActiveResearcherProjectsRPC.Response;
          error?: GetActiveResearcherProjectsRPC.Errors.Errors;
        } =
          yield useGetActiveResearcherProjectsRPCClient(apiClient).execute(
            request,
          );
        if (response) {
          store.activeProjectsList = cast(
            response.getActiveResearcherProjectsPaginationResponse.map(
              (paginationResponse) => {
                return createProjectDetailsModel(
                  paginationResponse.projectId.uuid,
                  paginationResponse.creator,
                  paginationResponse.projectName,
                  paginationResponse.surveys,
                  paginationResponse.createdOn.timestamp,
                  paginationResponse.lastModified.timestamp,
                );
              },
            ),
          );
          store.totalItems = response.totalItems;
        } else if (error) {
          switch (error.code) {
            case GetProjectsListRPCErrors.InvalidDateRange: {
              store.rpcErrors = GetProjectsListRPCErrors.InvalidDateRange;
              break;
            }
            case GetProjectsListRPCErrors.InvalidPageIndex: {
              store.rpcErrors = GetProjectsListRPCErrors.InvalidPageIndex;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        store.isGetProjectListRPCCalled = true;
      }
    }),
    getActiveSpocProjects: flow(function* (
      pageIndex: number,
      spocId: string,
      sort?: TableSortOption,
    ) {
      store.isRPCLoading = true;
      const sortOption = [
        new GetActiveSpocProjectsSortOrder(
          DEFAULT_PROJECT_LIST_SORT_INDEX,
          store.projectListSortOptions(sort).columnName,
          store.projectListSortOptions(sort).order,
        ),
      ];

      try {
        const apiClient = getAPIClient(store);
        const projectListFilters = store.projectListFilters;
        const request = new GetActiveSpocProjectsRPC.Request(
          sortOption,
          projectListFilters.projectName,
          projectListFilters.creator,
          projectListFilters.surveys,
          projectListFilters.createdOnStartDate,
          projectListFilters.createdOnEndDate,
          projectListFilters.lastModifiedStartDate,
          projectListFilters.lastModifiedEndDate,
          spocId,
          new ProjectItemsPerPage(DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE),
          new ProjectPageIndex(pageIndex),
        );
        const {
          response,
          error,
        }: {
          response?: GetActiveSpocProjectsRPC.Response;
          error?: GetActiveSpocProjectsRPC.Errors.Errors;
        } = yield useGetActiveSpocProjectsRPCClient(apiClient).execute(request);
        if (response) {
          store.activeProjectsList = cast(
            response.getActiveSpocProjectsPaginationResponse.map(
              (paginationResponse) => {
                return createProjectDetailsModel(
                  paginationResponse.projectId.uuid,
                  paginationResponse.creator,
                  paginationResponse.projectName,
                  paginationResponse.surveys,
                  paginationResponse.createdOn.timestamp,
                  paginationResponse.lastModified.timestamp,
                );
              },
            ),
          );
          store.totalItems = response.totalItems;
        } else if (error) {
          switch (error.code) {
            case GetProjectsListRPCErrors.InvalidDateRange: {
              store.rpcErrors = GetProjectsListRPCErrors.InvalidDateRange;
              break;
            }
            case GetProjectsListRPCErrors.InvalidPageIndex: {
              store.rpcErrors = GetProjectsListRPCErrors.InvalidPageIndex;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        store.isGetProjectListRPCCalled = true;
      }
    }),
    getArchivedSpocProjects: flow(function* (
      pageIndex: number,
      spocId: string,
      sort?: TableSortOption,
    ) {
      store.isRPCLoading = true;
      const sortOption = [
        new GetArchivedSpocProjectsSortOrder(
          DEFAULT_PROJECT_LIST_SORT_INDEX,
          store.projectListSortOptions(sort).columnName,
          store.projectListSortOptions(sort).order,
        ),
      ];

      try {
        const apiClient = getAPIClient(store);
        const projectListFilters = store.projectListFilters;
        const request = new GetArchivedSpocProjectsRPC.Request(
          sortOption,
          projectListFilters.projectName,
          projectListFilters.creator,
          projectListFilters.surveys,
          projectListFilters.createdOnStartDate,
          projectListFilters.createdOnEndDate,
          projectListFilters.lastModifiedStartDate,
          projectListFilters.lastModifiedEndDate,
          spocId,
          new ProjectItemsPerPage(DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE),
          new ProjectPageIndex(pageIndex),
        );
        const {
          response,
          error,
        }: {
          response?: GetArchivedSpocProjectsRPC.Response;
          error?: GetArchivedSpocProjectsRPC.Errors.Errors;
        } =
          yield useGetArchivedSpocProjectsRPCClient(apiClient).execute(request);
        if (response) {
          store.archivedProjectsList = cast(
            response.getArchivedSpocProjectsPaginationResponse.map(
              (paginationResponse) => {
                return createProjectDetailsModel(
                  paginationResponse.projectId.uuid,
                  paginationResponse.creator,
                  paginationResponse.projectName,
                  paginationResponse.surveys,
                  paginationResponse.createdOn.timestamp,
                  paginationResponse.lastModified.timestamp,
                );
              },
            ),
          );
          store.totalItems = response.totalItems;
        } else if (error) {
          switch (error.code) {
            case GetProjectsListRPCErrors.InvalidDateRange: {
              store.rpcErrors = GetProjectsListRPCErrors.InvalidDateRange;
              break;
            }
            case GetProjectsListRPCErrors.InvalidPageIndex: {
              store.rpcErrors = GetProjectsListRPCErrors.InvalidPageIndex;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        store.isGetProjectListRPCCalled = true;
      }
    }),
    createNewProject: flow(function* () {
      store.isRPCLoading = true;
      try {
        const apiClient = getAPIClient(store);
        const request = new CreateNewProjectRPC.Request(
          new ProjectName(store.projectDetailsStore.projectName),
          getFormatedLeoTimeStamp(store.projectDetailsStore.projectStartDate),
          getFormatedLeoTimeStamp(store.projectDetailsStore.projectEndDate),
        );
        const {
          response,
          error,
        }: {
          response?: CreateNewProjectRPC.Response;
          error?: CreateNewProjectRPC.Errors.Errors;
        } = yield useCreateNewProjectRPCClientImpl(apiClient).execute(request);

        if (response) {
          store.projectId = response.projectId.uuid;
        } else if (error) {
          switch (error.code) {
            case CloneCreateNewProjectRPCErrors.InvalidProjectDates: {
              store.rpcErrors =
                CloneCreateNewProjectRPCErrors.InvalidProjectDates;
              break;
            }
            case CloneCreateNewProjectRPCErrors.InvalidProjectName: {
              store.rpcErrors =
                CloneCreateNewProjectRPCErrors.InvalidProjectName;
              break;
            }
            case CloneCreateNewProjectRPCErrors.ProjectNameNotUnique: {
              store.rpcErrors =
                CloneCreateNewProjectRPCErrors.ProjectNameNotUnique;
              break;
            }
            default: {
              store.cloneCreateNewProjectDialogState =
                CloneCreateNewProjectDialogState.Error;
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    cloneProject: flow(function* () {
      store.isRPCLoading = true;
      try {
        const apiClient = getAPIClient(store);
        const request = new CloneProjectRPC.Request(
          new ProjectName(store.projectDetailsStore.projectName),
          getFormatedLeoTimeStamp(store.projectDetailsStore.projectStartDate),
          getFormatedLeoTimeStamp(store.projectDetailsStore.projectEndDate),
          new LeoUUID(store.projectDetailsStore.cloneProjectId),
        );
        const {
          response,
          error,
        }: {
          response?: CloneProjectRPC.Response;
          error?: CloneProjectRPC.Errors.Errors;
        } = yield useCloneProjectRPCClientImpl(apiClient).execute(request);

        if (response) {
          store.projectId = response.projectId.uuid;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              break;
            case CloneCreateNewProjectRPCErrors.InvalidProjectDates: {
              store.rpcErrors =
                CloneCreateNewProjectRPCErrors.InvalidProjectDates;
              break;
            }
            case CloneCreateNewProjectRPCErrors.InvalidProjectName: {
              store.rpcErrors =
                CloneCreateNewProjectRPCErrors.InvalidProjectName;
              break;
            }
            case CloneCreateNewProjectRPCErrors.ProjectNameNotUnique: {
              store.rpcErrors =
                CloneCreateNewProjectRPCErrors.ProjectNameNotUnique;
              break;
            }
            default: {
              store.cloneCreateNewProjectDialogState =
                CloneCreateNewProjectDialogState.Error;
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    archiveProject: flow(function* () {
      store.isRPCLoading = true;
      try {
        const apiClient = getAPIClient(store);
        const request = new ArchiveProjectRPC.Request(
          new LeoUUID(store.selectedProjectToArchive?.projectId),
        );
        const {
          response,
          error,
        }: {
          response?: ArchiveProjectRPC.Response;
          error?: ArchiveProjectRPC.Errors.Errors;
        } = yield useArchiveProjectRPCClientImpl(apiClient).execute(request);

        if (response) {
          return;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              break;
            }
            case ArchiveProjectRPCErrors.ProjectAlreadyArchived: {
              store.rpcErrors = ArchiveProjectRPCErrors.ProjectAlreadyArchived;
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        store.isArchiveProjectTextFieldChanged = false;
      }
    }),
  }));

export const createProjectsRootStore = (): Instance<
  typeof ProjectsRootStore
> => {
  return ProjectsRootStore.create({
    projectDetailsStore: createProjectDetailsStore(),
    filterStore: createFilterStore(),
    auditLogsStore: createAuditLogsStore(),
    profileStore: createProfileStore(),
  });
};
