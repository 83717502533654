import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import { SurveyDetailsModel } from "../../../surveys/models/SurveyDetailsModel";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { BorderStyle } from "@pulse/shared-components";
import { TFunction } from "i18next";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { SurveyDetailListElement } from "./SurveyDetailListElement";

export interface SurveyDetailsPaneProps {
  listOfSurveyDetails: Instance<typeof SurveyDetailsModel>[];
  spacing: Spacing;
  border: BorderStyle;
  borderRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  t: TFunction;
  navigateTo: NavigateToFunctions;
  projectId: string;
  setSelectedSurveyId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedSurveyName: React.Dispatch<React.SetStateAction<string | null>>;
  setIsCloneSurveyDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SurveyDetailsPane = observer(
  ({
    listOfSurveyDetails,
    spacing,
    border,
    borderRadius,
    tokens,
    typography,
    t,
    navigateTo,
    projectId,
    setSelectedSurveyId,
    setSelectedSurveyName,
    setIsCloneSurveyDialogOpen,
  }: SurveyDetailsPaneProps): React.ReactElement => {
    return (
      <>
        {listOfSurveyDetails.map((surveyDetails) => (
          <SurveyDetailListElement
            key={surveyDetails.surveyId}
            surveyDetails={surveyDetails}
            spacing={spacing}
            border={border}
            borderRadius={borderRadius}
            tokens={tokens}
            typography={typography}
            t={t}
            navigateTo={navigateTo}
            projectId={projectId}
            setSelectedSurveyId={setSelectedSurveyId}
            setSelectedSurveyName={setSelectedSurveyName}
            setIsCloneSurveyDialogOpen={setIsCloneSurveyDialogOpen}
          />
        ))}
      </>
    );
  },
);
