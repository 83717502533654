import React from "react";
import { Stack } from "@mui/material";
import { observer } from "mobx-react";
import {
  FoundationColorTokens,
  RadioButtonColorTokens,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { Instance } from "mobx-state-tree";
import { TFunction } from "i18next";
import { GridColumnQuestionV1Model } from "../../models/GridColumnQuestionV1Model";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";
import { SingleSelectOptionRadioButton } from "../singleSelectOptionComponents/SingleSelectOptionRadioButton";
import { SingleSelectOptionTextInput } from "../singleSelectOptionComponents/SingleSelectOptionTextInput";
import { SingleSelectOptionCodeInput } from "../singleSelectOptionComponents/SingleSelectOptionCodeInput";
import { ChoiceListButtons } from "../ChoiceListButtons";

interface GridSingleSelectChoiceOptionsProps {
  index: number;
  tokens: FoundationColorTokens<string>;
  gridColumnQuestionV1Model: Instance<typeof GridColumnQuestionV1Model>;
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
  t: TFunction;
  radioButtonTokens: RadioButtonColorTokens<string>;
  spacing: Spacing;
}

export const GridSingleSelectChoiceOptions = observer(
  ({
    index,
    tokens,
    gridColumnQuestionV1Model,
    questionDetailsStore,
    t,
    radioButtonTokens,
    spacing,
  }: GridSingleSelectChoiceOptionsProps): React.ReactElement => {
    return (
      <Stack direction="row" gap={spacing.spaceSM} alignItems="center">
        <SingleSelectOptionRadioButton
          value={index}
          checked={gridColumnQuestionV1Model.singleChoice.gridSingleSelectQuestionV1Model.isDefaultOptionIndex(
            index,
          )}
          onChange={(e): void => {
            gridColumnQuestionV1Model.singleChoice.setDefaultOptionIndex(
              Number(e.target.value),
            );
          }}
          onClick={(_e): void => {
            gridColumnQuestionV1Model.singleChoice.setDefaultOptionIndex(
              undefined,
            );
          }}
          tokens={tokens}
          radioButtonTokens={radioButtonTokens}
        />
        <SingleSelectOptionTextInput
          width={undefined}
          value={
            gridColumnQuestionV1Model.singleChoice
              .gridSingleSelectQuestionV1Model.options[index].option
          }
          onTextChange={(inputString: string) => {
            questionDetailsStore.setFieldValidations(null);
            gridColumnQuestionV1Model.singleChoice.setFieldOptionText(
              inputString,
              index,
            );
          }}
          t={t}
          error={false}
          isDisabled={false}
        />

        <SingleSelectOptionCodeInput
          width="132px"
          value={
            gridColumnQuestionV1Model.singleChoice
              .gridSingleSelectQuestionV1Model.options[index].code
          }
          error={gridColumnQuestionV1Model.singleChoice.doesOptionCodeContainErrors(
            index,
          )}
          onTextChange={(inputString: string) => {
            questionDetailsStore.setDuplicateOptionCode(null);
            questionDetailsStore.setFieldValidations(null);
            gridColumnQuestionV1Model.singleChoice.setCode(inputString, index);
          }}
          t={t}
          questionDetailsStore={questionDetailsStore}
          isDisabled={false}
        />
        <ChoiceListButtons
          paddingTop={undefined}
          border={undefined}
          spacing={spacing}
          isAddNewFieldButtonDisabled={
            gridColumnQuestionV1Model.singleChoice.isAddNewFieldButtonDisabled
          }
          addNewFields={() =>
            gridColumnQuestionV1Model.singleChoice.addNewField(
              {
                option: EMPTY_CHARACTER,
                code: EMPTY_CHARACTER,
              },
              index,
            )
          }
          index={index}
          isChevronUpDisabled={
            gridColumnQuestionV1Model.singleChoice.isChevronUpDisabled
          }
          isChevronDownDisabled={
            gridColumnQuestionV1Model.singleChoice.isChevronDownDisabled
          }
          moveFieldUp={gridColumnQuestionV1Model.singleChoice.moveFieldUp}
          moveFieldDown={gridColumnQuestionV1Model.singleChoice.moveFieldDown}
          removeField={gridColumnQuestionV1Model.singleChoice.removeField}
          isDeleteButtonDisabled={
            gridColumnQuestionV1Model.singleChoice.isDeleteButtonDisabled
          }
        />
      </Stack>
    );
  },
);
