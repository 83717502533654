import { observer } from "mobx-react";
import React from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import {
  Spacing,
  FoundationColorTokens,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { TextInputField } from "@surya-digital/leo-reactjs-material-ui";
import { Stack } from "@mui/material";
import { BackOfficeSurveySettingsModel } from "../../models/BackOfficeSurveySettingsModel";
import {
  MAX_BUTTON_TEXT_LENGTH,
  MIN_BUTTON_TEXT_LENGTH,
  ZERO_VALUE,
  showMaxCharacterCountIndicator,
} from "@pulse/shared-components";

interface DefaultButtonTextSettingsProps {
  updatedBackOfficeSurveySettings: Instance<
    typeof BackOfficeSurveySettingsModel
  >;
  clearError: () => void;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

export const DefaultButtonTextSettings = observer(
  ({
    updatedBackOfficeSurveySettings,
    clearError,
    t,
    spacing,
    typography,
    tokens,
  }: DefaultButtonTextSettingsProps): React.ReactElement => {
    const surveySettings = updatedBackOfficeSurveySettings.surveySettings;
    const updatedBackOfficeSurveySettingsUIStore =
      updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore;
    return (
      <SettingsContainer
        settingHeadingText={t(
          "surveys.settings.defaultButtonTitleSettings.defaultButtonTitlesTitleText",
        )}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
      >
        <Stack width="400px" gap={spacing.spaceLG}>
          <TextInputField
            name="startButtonText"
            value={surveySettings.startButtonText}
            type="text"
            onTextChange={(buttonText: string) => {
              surveySettings.setStartButtonText(
                buttonText.slice(ZERO_VALUE, MAX_BUTTON_TEXT_LENGTH),
              );
              clearError();
            }}
            label={t(
              "surveys.settings.defaultButtonTitleSettings.startButtonTitleText",
            )}
            placeholder={t(
              "surveys.settings.defaultButtonTitleSettings.startButtonPlaceholderText",
            )}
            required={true}
            error={updatedBackOfficeSurveySettingsUIStore.isButtonTextErrored(
              surveySettings.startButtonText,
            )}
            maxCharacterLength={MAX_BUTTON_TEXT_LENGTH}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_BUTTON_TEXT_LENGTH,
                surveySettings.startButtonText,
              )
            }
            helperText={
              updatedBackOfficeSurveySettingsUIStore.isButtonTextErrored(
                surveySettings.startButtonText,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_BUTTON_TEXT_LENGTH,
                  })
                : undefined
            }
          />
          <TextInputField
            name="continueButtonText"
            value={surveySettings.continueButtonText}
            type="text"
            onTextChange={(buttonText: string) => {
              surveySettings.setContinueButtonText(
                buttonText.slice(ZERO_VALUE, MAX_BUTTON_TEXT_LENGTH),
              );
              clearError();
            }}
            label={t(
              "surveys.settings.defaultButtonTitleSettings.continueButtonTitleText",
            )}
            placeholder={t(
              "surveys.settings.defaultButtonTitleSettings.continueButtonPlaceholderText",
            )}
            required={true}
            error={updatedBackOfficeSurveySettingsUIStore.isButtonTextErrored(
              surveySettings.continueButtonText,
            )}
            maxCharacterLength={MAX_BUTTON_TEXT_LENGTH}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_BUTTON_TEXT_LENGTH,
                surveySettings.continueButtonText,
              )
            }
            helperText={
              updatedBackOfficeSurveySettingsUIStore.isButtonTextErrored(
                surveySettings.continueButtonText,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_BUTTON_TEXT_LENGTH,
                  })
                : undefined
            }
          />
          <TextInputField
            name="nextPageButtonText"
            value={surveySettings.nextButtonText}
            type="text"
            onTextChange={(buttonText: string) => {
              surveySettings.setNextButtonText(
                buttonText.slice(ZERO_VALUE, MAX_BUTTON_TEXT_LENGTH),
              );
              clearError();
            }}
            label={t(
              "surveys.settings.defaultButtonTitleSettings.nextPageButtonTitleText",
            )}
            placeholder={t(
              "surveys.settings.defaultButtonTitleSettings.nextPageButtonPlaceholderText",
            )}
            required={true}
            error={updatedBackOfficeSurveySettingsUIStore.isButtonTextErrored(
              surveySettings.nextButtonText,
            )}
            maxCharacterLength={MAX_BUTTON_TEXT_LENGTH}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_BUTTON_TEXT_LENGTH,
                surveySettings.nextButtonText,
              )
            }
            helperText={
              updatedBackOfficeSurveySettingsUIStore.isButtonTextErrored(
                surveySettings.nextButtonText,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_BUTTON_TEXT_LENGTH,
                  })
                : undefined
            }
          />
          <TextInputField
            name="previousPageButtonText"
            value={surveySettings.backButtonText}
            type="text"
            onTextChange={(buttonText: string) => {
              surveySettings.setBackButtonText(
                buttonText.slice(ZERO_VALUE, MAX_BUTTON_TEXT_LENGTH),
              );
              clearError();
            }}
            label={t(
              "surveys.settings.defaultButtonTitleSettings.previousPageButtonTitleText",
            )}
            placeholder={t(
              "surveys.settings.defaultButtonTitleSettings.previousPageButtonPlaceholderText",
            )}
            required={true}
            error={updatedBackOfficeSurveySettingsUIStore.isButtonTextErrored(
              surveySettings.backButtonText,
            )}
            maxCharacterLength={MAX_BUTTON_TEXT_LENGTH}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_BUTTON_TEXT_LENGTH,
                surveySettings.backButtonText,
              )
            }
            helperText={
              updatedBackOfficeSurveySettingsUIStore.isButtonTextErrored(
                surveySettings.backButtonText,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_BUTTON_TEXT_LENGTH,
                  })
                : undefined
            }
          />
          <TextInputField
            name="submitSurveyButtonText"
            value={
              updatedBackOfficeSurveySettings.submitConfirmationDialogProperties
                .buttonText
            }
            type="text"
            onTextChange={(buttonText: string) => {
              updatedBackOfficeSurveySettings.submitConfirmationDialogProperties.setButtonText(
                buttonText.slice(ZERO_VALUE, MAX_BUTTON_TEXT_LENGTH),
              );
              clearError();
            }}
            label={t(
              "surveys.settings.defaultButtonTitleSettings.doneButtonTitleText",
            )}
            placeholder={t(
              "surveys.settings.defaultButtonTitleSettings.doneButtonPlaceholderText",
            )}
            required={true}
            error={updatedBackOfficeSurveySettings.submitConfirmationDialogProperties.isButtonTextErrored(
              updatedBackOfficeSurveySettingsUIStore.isSaveButtonClicked,
            )}
            maxCharacterLength={MAX_BUTTON_TEXT_LENGTH}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_BUTTON_TEXT_LENGTH,
                surveySettings.backButtonText,
              )
            }
            helperText={
              updatedBackOfficeSurveySettings.submitConfirmationDialogProperties.isButtonTextErrored(
                updatedBackOfficeSurveySettingsUIStore.isSaveButtonClicked,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_BUTTON_TEXT_LENGTH,
                  })
                : undefined
            }
          />
          <TextInputField
            name="restartSurveyButtonText"
            value={
              updatedBackOfficeSurveySettings.restartSurveyDialogProperties
                .buttonText
            }
            type="text"
            onTextChange={(buttonText: string) => {
              updatedBackOfficeSurveySettings.restartSurveyDialogProperties.setButtonText(
                buttonText.slice(ZERO_VALUE, MAX_BUTTON_TEXT_LENGTH),
              );
              clearError();
            }}
            label={t(
              "surveys.settings.defaultButtonTitleSettings.restartButtonTitleText",
            )}
            placeholder={t(
              "surveys.settings.defaultButtonTitleSettings.restartButtonPlaceholderText",
            )}
            required={true}
            error={updatedBackOfficeSurveySettings.restartSurveyDialogProperties.isButtonTextErrored(
              updatedBackOfficeSurveySettingsUIStore.isSaveButtonClicked,
            )}
            maxCharacterLength={MAX_BUTTON_TEXT_LENGTH}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_BUTTON_TEXT_LENGTH,
                surveySettings.backButtonText,
              )
            }
            helperText={
              updatedBackOfficeSurveySettings.restartSurveyDialogProperties.isButtonTextErrored(
                updatedBackOfficeSurveySettingsUIStore.isSaveButtonClicked,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_BUTTON_TEXT_LENGTH,
                  })
                : undefined
            }
          />
          <TextInputField
            name="resetAnswerButtonText"
            value={surveySettings.resetAnswerButtonText}
            type="text"
            onTextChange={(buttonText: string) => {
              surveySettings.setResetAnswerButtonText(
                buttonText.slice(ZERO_VALUE, MAX_BUTTON_TEXT_LENGTH),
              );
              clearError();
            }}
            label={t(
              "surveys.settings.defaultButtonTitleSettings.resetAnswerButtonTitleText",
            )}
            placeholder={t(
              "surveys.settings.defaultButtonTitleSettings.resetAnswerButtonPlaceholderText",
            )}
            required={true}
            error={updatedBackOfficeSurveySettingsUIStore.isButtonTextErrored(
              surveySettings.resetAnswerButtonText,
            )}
            maxCharacterLength={MAX_BUTTON_TEXT_LENGTH}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_BUTTON_TEXT_LENGTH,
                surveySettings.backButtonText,
              )
            }
            helperText={
              updatedBackOfficeSurveySettingsUIStore.isButtonTextErrored(
                surveySettings.resetAnswerButtonText,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_BUTTON_TEXT_LENGTH,
                  })
                : undefined
            }
          />
          <TextInputField
            name="saveButtonText"
            value={
              updatedBackOfficeSurveySettings.saveDraftDialogProperties
                .buttonText
            }
            type="text"
            onTextChange={(buttonText: string) => {
              updatedBackOfficeSurveySettings.saveDraftDialogProperties.setButtonText(
                buttonText.slice(ZERO_VALUE, MAX_BUTTON_TEXT_LENGTH),
              );
              clearError();
            }}
            label={t(
              "surveys.settings.defaultButtonTitleSettings.saveButtonTitleText",
            )}
            placeholder={t(
              "surveys.settings.defaultButtonTitleSettings.saveButtonPlaceholderText",
            )}
            required={true}
            error={updatedBackOfficeSurveySettings.saveDraftDialogProperties.isButtonTextErrored(
              updatedBackOfficeSurveySettingsUIStore.isSaveButtonClicked,
            )}
            maxCharacterLength={MAX_BUTTON_TEXT_LENGTH}
            showCharacterCountIndicator={(): boolean => {
              const saveButtonText =
                updatedBackOfficeSurveySettings.saveDraftDialogProperties
                  .buttonText;
              if (saveButtonText) {
                return showMaxCharacterCountIndicator(
                  MAX_BUTTON_TEXT_LENGTH,
                  saveButtonText,
                );
              }
              return false;
            }}
            helperText={
              updatedBackOfficeSurveySettings.saveDraftDialogProperties.isButtonTextErrored(
                updatedBackOfficeSurveySettingsUIStore.isSaveButtonClicked,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_BUTTON_TEXT_LENGTH,
                  })
                : undefined
            }
          />
        </Stack>
      </SettingsContainer>
    );
  },
);
