import {
  Instance,
  applySnapshot,
  cast,
  getSnapshot,
  types,
} from "mobx-state-tree";
import {
  FilterModel,
  FilterType,
  createFilterModel,
} from "../model/FilterModel";
import { EMPTY_CHARACTER, EMPTY_LIST_LENGTH } from "@pulse/shared-components";
import {
  AutoCompleteItem,
  DateRangePickerInput,
} from "@surya-digital/leo-reactjs-material-ui";
import { Filter } from "../components/FilterComponent";
import { createDateRangeFilterModel } from "../model/DateRangeFilterModel";

export const FilterStore = types
  .model("FilterStore", {
    appliedFilters: types.array(FilterModel),
    currentAddedFilters: types.array(FilterModel),
    currentSelectedFilter: FilterModel,
  })
  .views((store) => ({
    get areNoFiltersApplied(): boolean {
      return store.appliedFilters.length === EMPTY_LIST_LENGTH;
    },
    get areNoFiltersAdded(): boolean {
      return store.currentAddedFilters.length === EMPTY_LIST_LENGTH;
    },
    get isApplyFilterButtonDisabled(): boolean {
      return (
        JSON.stringify(store.currentAddedFilters) ===
        JSON.stringify(store.appliedFilters)
      );
    },
    get isFilterAlreadyAdded(): boolean {
      const appliedFilter = store.currentAddedFilters.find(
        (filter) => filter.key === store.currentSelectedFilter.key,
      );
      return appliedFilter !== undefined;
    },
    isNoFilterValueAdded(filters: Filter[]): boolean {
      if (
        store.currentSelectedFilter.key === undefined ||
        store.currentSelectedFilter.key === EMPTY_CHARACTER
      ) {
        return true;
      }
      if (
        filters.find(
          (filter) => filter.key === store.currentSelectedFilter.key,
        ) === undefined
      ) {
        return false;
      }
      switch (store.currentSelectedFilter.filterType) {
        case FilterType.OpenEnded: {
          return (
            store.currentSelectedFilter.openEndedFilterValue ===
              EMPTY_CHARACTER ||
            store.currentSelectedFilter.openEndedFilterValue === undefined
          );
        }
        case FilterType.MultiSelect: {
          return (
            store.currentSelectedFilter.multiSelectFilterValue.length ===
            EMPTY_LIST_LENGTH
          );
        }
        case FilterType.DateRange: {
          return (
            store.currentSelectedFilter.dateRangeFilterValue === null ||
            store.currentSelectedFilter.dateRangeFilterValue.startDate ===
              null ||
            store.currentSelectedFilter.dateRangeFilterValue.endDate === null
          );
        }
        default:
          return false;
      }
    },
    isMaxFilterLimitReached(maxFilterLimit: number | undefined): boolean {
      return (
        maxFilterLimit !== undefined &&
        store.currentAddedFilters.length >= maxFilterLimit
      );
    },
    get isDateRangeCurrentSelectedField(): boolean {
      return store.currentSelectedFilter.filterType === FilterType.DateRange;
    },
    get isNoFilterValueTypeSelected(): boolean {
      return (
        store.currentSelectedFilter.key === undefined ||
        store.currentSelectedFilter.key === EMPTY_CHARACTER
      );
    },
  }))
  .actions((store) => ({
    clearStore: (): void => {
      store.appliedFilters.clear();
      store.currentAddedFilters.clear();
      store.currentSelectedFilter = createFilterModel(undefined);
    },
    removeAddedFilter: (index: number): void => {
      store.currentAddedFilters.splice(index, 1);
    },
    addSelectedFilterKey: (
      filterKey: string | undefined,
      filterType: FilterType,
    ): void => {
      store.currentSelectedFilter.key = filterKey;
      store.currentSelectedFilter.filterType = filterType;
    },
    addCurrentSelectedFilter: (): void => {
      const newlyAddedFilters = [
        ...store.currentAddedFilters,
        store.currentSelectedFilter,
      ];
      store.currentAddedFilters = cast(
        <Instance<typeof FilterModel>[]>(
          JSON.parse(JSON.stringify(newlyAddedFilters))
        ),
      );
    },
    setOpenEndedFilterValue: (filterValue: string): void => {
      store.currentSelectedFilter.openEndedFilterValue = filterValue;
      store.currentSelectedFilter.multiSelectFilterValue.clear();
      store.currentSelectedFilter.dateRangeFilterValue = null;
    },
    setMultiSelectFilterValue: (filterValue: AutoCompleteItem[]): void => {
      store.currentSelectedFilter.multiSelectFilterValue = cast(
        filterValue.map((value) => {
          return {
            id: value.id,
            label: value.label,
          };
        }),
      );
      store.currentSelectedFilter.openEndedFilterValue = undefined;
      store.currentSelectedFilter.dateRangeFilterValue = null;
    },
    setDateRangeFilterValue: (
      newDateRangePickerInput: DateRangePickerInput,
    ): void => {
      store.currentSelectedFilter.dateRangeFilterValue =
        createDateRangeFilterModel(
          newDateRangePickerInput.startDate,
          newDateRangePickerInput.endDate,
        );
      store.currentSelectedFilter.openEndedFilterValue = undefined;
      store.currentSelectedFilter.multiSelectFilterValue.clear();
    },
    removeAllFilters: (): void => {
      store.appliedFilters.clear();
      store.currentAddedFilters.clear();
    },
    removeCurrentSelectedFilter: (): void => {
      store.currentSelectedFilter = createFilterModel(undefined);
    },
    applyCurrentAddedFilters: (): void => {
      const appliedFilters = getSnapshot(store.currentAddedFilters);
      applySnapshot(store.appliedFilters, appliedFilters);
    },
  }));

export const createFilterStore = (): Instance<typeof FilterStore> => {
  return FilterStore.create({
    currentSelectedFilter: createFilterModel(undefined),
  });
};
