import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import {
  AlertCircle,
  Dialog,
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
  TableReloadHandle,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { ErrorSuccessDialogContent } from "../ErrorSuccessDialogContent";
import {
  EmbedStore,
  DeleteEmbedError,
  DeleteEmbedDialogState,
} from "../../store/EmbedStore";
import { Box, Stack, Typography } from "@mui/material";
import { ICON_SIZE } from "@pulse/shared-components/src/constants";

interface DeleteEmbedDialogProps {
  isDeleteEmbedDialogVisible: boolean;
  setIsDeleteEmbedDialogVisible: (isDialogVisible: boolean) => void;
  t: TFunction;
  embedStore: Instance<typeof EmbedStore>;
  navigateTo: NavigateToFunctions;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  spacing: Spacing;
  embedTableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const DeleteEmbedDialog = observer(
  ({
    isDeleteEmbedDialogVisible,
    setIsDeleteEmbedDialogVisible,
    t,
    embedStore,
    navigateTo,
    tokens,
    typography,
    spacing,
    embedTableRef,
  }: DeleteEmbedDialogProps): React.ReactElement => {
    const errorSuccessText = (): string => {
      switch (embedStore.rpcAndValidationError) {
        case DeleteEmbedError.InvalidEmbedId:
          return t("projects.reports.embeds.deleteEmbed.dialogErrorText");
        case DeleteEmbedError.ProjectAlreadyArchived:
          return t("common.projectAlreadyArchivedErrorText");
        default:
          return t("projects.reports.embeds.deleteEmbed.dialogErrorText");
      }
    };

    const DeleteEmbedDialogContent = observer((): React.ReactElement => {
      switch (embedStore.deleteEmbedDialogState) {
        case DeleteEmbedDialogState.DeleteEmbed:
          return (
            <Stack
              direction="row"
              width="100%"
              alignItems="flex-start"
              gap={spacing.spaceXS}
              maxWidth={"520px"}
            >
              {
                <Box minWidth={ICON_SIZE.default}>
                  <AlertCircle
                    color={tokens.iconError}
                    width={ICON_SIZE.default}
                    height={ICON_SIZE.default}
                  />
                </Box>
              }
              <Typography {...typography.b2} color={tokens.labelError}>
                {t("projects.reports.embeds.deleteEmbed.dialogDescription")}
              </Typography>
            </Stack>
          );

        case DeleteEmbedDialogState.Error:
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={errorSuccessText()}
            />
          );
      }
    });

    const secondaryButtonText = (): string => {
      switch (embedStore.deleteEmbedDialogState) {
        case DeleteEmbedDialogState.DeleteEmbed:
          return t("common.cancel");
        case DeleteEmbedDialogState.Error:
          return t("common.close");
      }
    };

    return (
      <Dialog
        open={isDeleteEmbedDialogVisible}
        title={t("projects.reports.embeds.deleteEmbed.dialogTitleText")}
        isSecondaryButtonDisabled={embedStore.isRPCLoading}
        secondaryButtonText={secondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsDeleteEmbedDialogVisible(false);
          embedStore.resetDeleteEmbedDialog();
          embedTableRef.current?.reload();
        }}
        primaryButtonText={
          embedStore.isDeleteEmbedDialogState
            ? t("projects.reports.embeds.deleteEmbed.dialogTitleText")
            : undefined
        }
        primaryButtonColor="destructive"
        onPrimaryButtonClick={async () => {
          await processProjectParams(embedStore.deleteEmbed, navigateTo);
          if (!embedStore.doesEmbedStoreContainRPCError) {
            setIsDeleteEmbedDialogVisible(false);
            embedStore.resetDeleteEmbedDialog();
            embedTableRef.current?.reload();
          }
        }}
        disableBackdropClick={true}
        contentPadding={spacing.spaceLG}
      >
        <DeleteEmbedDialogContent />
      </Dialog>
    );
  },
);
