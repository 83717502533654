import { Stack, Typography } from "@mui/material";
import {
  DEFAULT_GRID_ROW_SIZE,
  MAX_GRID_COLUMNS,
} from "@pulse/shared-components";
import {
  Spacing,
  FoundationColorTokens,
  Banner,
  Dialog,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React, { useState, useEffect } from "react";
import { GridQuestionV1Store } from "../../store/GridQuestionV1Store";
import { NumericStepper } from "../NumericStepper";
import { Instance } from "mobx-state-tree";
import { observer } from "mobx-react";

export interface GridSizeCustomisationDialogProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  t: TFunction;
  gridQuestionV1Store: Instance<typeof GridQuestionV1Store>;
  setIsGridCustomisationDialogOpen: (
    isGridCustomisationDialogOpen: boolean,
  ) => void;
  isGridCustomisationDialogOpen: boolean;
}

export const GridSizeCustomisationDialog = observer(
  ({
    isGridCustomisationDialogOpen,
    setIsGridCustomisationDialogOpen,
    t,
    spacing,
    tokens,
    typography,
    gridQuestionV1Store,
  }: GridSizeCustomisationDialogProps): React.ReactElement => {
    const [gridRowSize, setGridRowSize] = useState<number>(
      DEFAULT_GRID_ROW_SIZE,
    );
    const [gridColumnSize, setGridColumnSize] = useState<number[]>([]);

    useEffect(() => {
      setGridColumnSize(gridQuestionV1Store.gridColumnSizeCustomisations);
      setGridRowSize(gridQuestionV1Store.gridRowSize);
    }, [
      gridQuestionV1Store.gridColumnSizeCustomisations,
      gridQuestionV1Store.gridRowSize,
    ]);

    return (
      <Dialog
        open={isGridCustomisationDialogOpen}
        title={t("surveys.addSurveyQuestion.grid.customiseGrid")}
        primaryButtonText={t("common.saveChanges")}
        onPrimaryButtonClick={async (): Promise<void> => {
          gridQuestionV1Store.setGridColumnSizeCustomisations(
            gridColumnSize,
            gridRowSize,
          );
          gridQuestionV1Store.resetFieldValidationsAndErrors();
          setIsGridCustomisationDialogOpen(false);
        }}
        secondaryButtonText={t("common.cancel")}
        onSecondaryButtonClick={(): void => {
          setIsGridCustomisationDialogOpen(false);
        }}
        disableBackdropClick={true}
        isPrimaryButtonDisabled={
          gridQuestionV1Store.isGridLimitExceededInSizeDialog(
            gridRowSize,
            gridColumnSize,
          ) ||
          gridQuestionV1Store.isPrimaryButtonDisabled(
            gridColumnSize,
            gridRowSize,
          )
        }
        width="560px"
      >
        <Stack gap={spacing.spaceLG} width="100%">
          {gridQuestionV1Store.isGridLimitExceededInSizeDialog(
            gridRowSize,
            gridColumnSize,
          ) && (
            <Banner
              message={t(
                "surveys.addSurveyQuestion.grid.totalGridSizeExceeded",
                {
                  maxGridSize: MAX_GRID_COLUMNS,
                },
              )}
              severity="error"
              width="100%"
            />
          )}
          {gridQuestionV1Store.isRowOptionVisible && (
            // The width is set to 184px to match designs though it is supposed to hug the numeric stepper.
            // The numeric stepper takes 188px of width
            // i.e 2px of the left and right borders + 2px of the dividers between the minus icon and the input field, and the input field and the plus icon.
            // Figma link: https://www.figma.com/design/USl8dEzHVxs10KzHoEx56w/Pulse-Revamp?node-id=16664-243311&t=g8MM4FIyHIg9Q0GT-4
            <Stack gap={spacing.spaceXXS} width="184px">
              <Typography {...typography.sh3} color={tokens.label}>
                {t("surveys.addSurveyQuestion.grid.row")}
              </Typography>
              <NumericStepper
                isMinusIconDisabled={gridRowSize <= 1}
                isPlusIconDisabled={gridRowSize >= MAX_GRID_COLUMNS}
                onPlusIconClick={(): void => {
                  setGridRowSize(gridRowSize + 1);
                }}
                onMinusIconClick={(): void => {
                  setGridRowSize(gridRowSize - 1);
                }}
                onInputChange={function (
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                ): void {
                  const inputNumber = Number(event.target.value);

                  if (Number.isSafeInteger(inputNumber)) {
                    setGridRowSize(inputNumber);
                  }
                }}
                value={gridRowSize}
                isError={false}
                spacing={spacing}
                tokens={tokens}
              />
            </Stack>
          )}
          {gridQuestionV1Store.gridColumnQuestions.map((_, index) => {
            return (
              // The width is set to 184px to match designs though it is supposed to hug the numeric stepper.
              // The numeric stepper takes 188px of width
              // i.e 2px of the left and right borders + 2px of the dividers between the minus icon and the input field, and the input field and the plus icon.
              // Figma link: https://www.figma.com/design/USl8dEzHVxs10KzHoEx56w/Pulse-Revamp?node-id=16664-243311&t=g8MM4FIyHIg9Q0GT-4
              <Stack key={index} gap={spacing.spaceXXS} width="184px">
                <Typography {...typography.sh3} color={tokens.label}>
                  {t("surveys.addSurveyQuestion.grid.column", {
                    index: index + 1,
                  })}
                </Typography>
                <NumericStepper
                  isMinusIconDisabled={gridColumnSize[index] <= 1}
                  isPlusIconDisabled={gridColumnSize[index] >= MAX_GRID_COLUMNS}
                  onPlusIconClick={(): void => {
                    const updatedColumnSize = [...gridColumnSize];
                    updatedColumnSize[index] = updatedColumnSize[index] + 1;
                    setGridColumnSize(updatedColumnSize);
                  }}
                  onMinusIconClick={(): void => {
                    const updatedColumnSize = [...gridColumnSize];
                    updatedColumnSize[index] = updatedColumnSize[index] - 1;
                    setGridColumnSize(updatedColumnSize);
                  }}
                  onInputChange={function (
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                  ): void {
                    const inputNumber = Number(event.target.value);

                    if (Number.isSafeInteger(inputNumber)) {
                      const updatedColumnSize = [...gridColumnSize];
                      updatedColumnSize[index] = inputNumber;
                      setGridColumnSize(updatedColumnSize);
                    }
                  }}
                  value={gridColumnSize[index]}
                  isError={false}
                  spacing={spacing}
                  tokens={tokens}
                />
              </Stack>
            );
          })}
        </Stack>
      </Dialog>
    );
  },
);
