import { Box, Typography } from "@mui/material";
import { BorderStyle } from "@pulse/shared-components";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";

export interface QuestionItemOrderProps {
  spacing: Spacing;
  isItemSelected: boolean;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  typography: LeoTypography;
  order: number;
  cornerRadius: CornerRadius;
}

export const QuestionItemOrder = ({
  border,
  tokens,
  spacing,
  isItemSelected,
  typography,
  order,
  cornerRadius,
}: QuestionItemOrderProps): React.ReactElement => {
  return (
    <Box
      borderRadius={cornerRadius.radiusMax}
      padding={`${spacing.spaceXXS} 0px`}
      height={"24px"}
      minWidth={"32px"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      border={isItemSelected ? "none" : border.primarySubtle}
      sx={{
        background: isItemSelected
          ? tokens.backgroundPrimary
          : tokens.backgroundPrimarySubtle,
      }}
    >
      <Typography
        {...typography.s3}
        color={isItemSelected ? tokens.labelOnSurface : tokens.labelPrimary}
      >
        {order}
      </Typography>
    </Box>
  );
};
