import React, { createContext, useContext } from "react";
import { Instance, types } from "mobx-state-tree";
import { removePersistedUserPrivileges } from "../../store/user/UserPrivileges";
import {
  NetworkingStore,
  createNetworkingStore,
} from "@pulse/shared-components";
import { NetworkingError } from "@pulse/shared-components";
import {
  ProjectsRootStore,
  createProjectsRootStore,
} from "../../projects/store/ProjectsRootStore";
import { SignInStore, createSignInStore } from "../../auth/store/SignInStore";
import {
  BOUserStore,
  createBOUserStore,
} from "../../manageUsers/store/BOUserStore";

// Mobx-react recommends injection of stores using React.createContext as compared to using the build in Provider / inject.
// For more info - https://github.com/mobxjs/mobx-react#provider-and-inject

export const RootStore = types
  .model("RootStore", {
    networkingStore: NetworkingStore,
    projectsRootStore: ProjectsRootStore,
    boUserStore: BOUserStore,
    signInStore: SignInStore,
  })
  .actions((store) => ({
    // This clears the networking store only when the error is not UNAUTHENTICATED since we are in the sign-in page.
    clearNetworkingErrors: (): void => {
      if (
        store.networkingStore.errorStore.error !==
        NetworkingError.Unauthenticated
      ) {
        store.networkingStore.errorStore.removeError();
      }
    },
    // This is added to replicate the sign out. This will removed from here in the future.
    signOutUser(): void {
      removePersistedUserPrivileges();
    },
  }));

const RootStoreContext = createContext<null | Instance<typeof RootStore>>(null);

export const RootStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const rootStore = RootStore.create(
    {
      networkingStore: createNetworkingStore(),
      projectsRootStore: createProjectsRootStore(),
      boUserStore: createBOUserStore(),
      signInStore: createSignInStore(),
    },
    {
      baseURL: import.meta.env.VITE_APP_API_CLIENT_BASE_URL,
    },
  );

  return (
    <RootStoreContext.Provider value={rootStore}>
      {children}
    </RootStoreContext.Provider>
  );
};

export function useRootStore(): Instance<typeof RootStore> {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}
