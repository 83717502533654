import { Stack, Typography } from "@mui/material";
import {
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { NumericStepper } from "./NumericStepper";
import { useQuestionDetailsStore } from "../store/hooks";
import { useTranslation } from "react-i18next";
import { useBorder } from "@pulse/shared-components";

export const MaxSelectionField = observer((): React.ReactElement => {
  const spacing = useSpacing();
  const typography = useTypography();
  const questionDetailsStore = useQuestionDetailsStore();
  const { t } = useTranslation();
  const border = useBorder();
  const tokens = useFoundationColorTokens();
  const multiChoiceQuestionV1Store =
    questionDetailsStore.updatedQuestionDetails.multiChoiceQuestionV1Store;
  const rankingQuestionV1Store =
    questionDetailsStore.updatedQuestionDetails.rankingQuestionV1Store;

  const isPlusIconDisabled = (): boolean => {
    if (questionDetailsStore.isMultiChoiceTypeQuestion) {
      return multiChoiceQuestionV1Store.isMaxSelectionIncrementDisabled();
    } else {
      return rankingQuestionV1Store.isMaxSelectionIncrementDisabled;
    }
  };

  const isMinusIconDisabled = (): boolean => {
    if (questionDetailsStore.isMultiChoiceTypeQuestion) {
      return multiChoiceQuestionV1Store.isMaxSelectionDecrementDisabled;
    } else {
      return rankingQuestionV1Store.isMaxSelectionDecrementDisabled;
    }
  };

  return (
    <Stack
      margin={`0 ${spacing.space2XL} ${spacing.space2XL} ${spacing.space2XL}`}
      width={"600px"}
    >
      <Typography
        {...typography.sh3}
        borderBottom={border.default}
        paddingBottom={spacing.spaceSM}
        marginBottom={spacing.spaceSM}
        color={tokens.label}
      >
        {t("surveys.addSurveyQuestion.multiChoice.maxSelectionTitle")}
      </Typography>
      <Stack width="200px" flexDirection={"row"}>
        <NumericStepper
          isPlusIconDisabled={isPlusIconDisabled()}
          onPlusIconClick={(): void => {
            questionDetailsStore.setFieldValidations(null);
            questionDetailsStore.isMultiChoiceTypeQuestion
              ? multiChoiceQuestionV1Store.incrementMaxSelection()
              : rankingQuestionV1Store.incrementMaxSelection();
          }}
          isMinusIconDisabled={isMinusIconDisabled()}
          onMinusIconClick={function (): void {
            questionDetailsStore.setFieldValidations(null);
            questionDetailsStore.isMultiChoiceTypeQuestion
              ? multiChoiceQuestionV1Store.decrementMaxSelection()
              : rankingQuestionV1Store.decrementMaxSelection();
          }}
          onInputChange={function (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ): void {
            const inputNumber = Number(event.target.value);
            questionDetailsStore.setFieldValidations(null);

            if (Number.isSafeInteger(inputNumber)) {
              questionDetailsStore.isMultiChoiceTypeQuestion
                ? multiChoiceQuestionV1Store.setMaxSelection(inputNumber)
                : rankingQuestionV1Store.setMaxSelection(inputNumber);
            }
          }}
          value={
            questionDetailsStore.isMultiChoiceTypeQuestion
              ? multiChoiceQuestionV1Store.multiChoiceQuestionV1Model
                  .maxSelection
              : rankingQuestionV1Store.rankingQuestionV1Model.maxSelection
          }
          isError={questionDetailsStore.validateMaxSelection}
          isInputFieldDisabled={
            !questionDetailsStore.isMultiChoiceTypeQuestion &&
            rankingQuestionV1Store.rankingQuestionV1Model.areAllOptionsMandatory
          }
          spacing={spacing}
          tokens={tokens}
        />
      </Stack>
    </Stack>
  );
});
