import { Stack, Typography } from "@mui/material";
import {
  MAX_SUBMISSION_DATA_SET_SIZE,
  getFormattedDateString,
} from "@pulse/shared-components";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import React from "react";
import { SurveyDetailsModel } from "../../../surveys/models/SurveyDetailsModel";
import { Instance } from "mobx-state-tree";
import { LineChart } from "../surveyResults/LineChart";
import { EmptyListPane } from "../../../surveys/components/EmptyListPane";

export interface SurveyListElementChartProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  t: TFunction;
  surveyDetails: Instance<typeof SurveyDetailsModel>;
}

export const SurveyListElementChart = observer(
  ({
    spacing,
    tokens,
    typography,
    t,
    surveyDetails,
  }: SurveyListElementChartProps): React.ReactElement => {
    if (surveyDetails.isNoSubmissionDataPresent) {
      return (
        <Stack textAlign="center" height="100%" width="592px">
          <EmptyListPane
            headingText={t("projects.dashboard.noDataTitle")}
            subHeadingText={t("projects.dashboard.noSurveyResultsDescription")}
          />
        </Stack>
      );
    } else {
      return (
        <>
          <Stack direction="row" justifyContent="center" gap={spacing.spaceXXS}>
            <Typography sx={{ ...typography.s2, color: tokens.labelSubtle }}>
              {surveyDetails.minAndMaxDateString}
            </Typography>
            {surveyDetails.hasMaxDataLimitReached && (
              <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
                {t("projects.dashboard.lastDataSetsText", {
                  days: MAX_SUBMISSION_DATA_SET_SIZE,
                })}
              </Typography>
            )}
          </Stack>
          <LineChart
            labels={surveyDetails.submissionData.map((value) =>
              // We only need to display the first 5 letters of the date string, therefore we have sliced it upto but not including index 6.
              getFormattedDateString(value.submissionDate).slice(0, 6),
            )}
            totalSubmissionData={surveyDetails.submissionData.map(
              (value) => value.totalSubmissions,
            )}
            t={t}
            lineChartCustomisation={{
              isColorGradientRequired: true,
              isTickRotationRequired: false,
            }}
          />
        </>
      );
    }
  },
);
