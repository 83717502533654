import { Stack } from "@mui/material";
import { t } from "i18next";
import { DownloadIcon, Plus } from "lucide-react";
import { SectionHeaderProps } from "../../../components/SectionHeader";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../routes/RoutesHelper";
import React from "react";
import { Instance } from "mobx-state-tree";
import { Button, useSpacing } from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import { ProjectDetailsStore } from "../store/ProjectDetailsStore";
import { DownloadReportsDialogState } from "../store/ReportStore";
import { SignInStore } from "../../auth/store/SignInStore";
import { UserPrivileges } from "../../store/user/UserPrivileges";

export const getReportSectionHeader = (
  setIsDownloadReportsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  projectStore: Instance<typeof ProjectDetailsStore>,
  navigateTo: NavigateToFunctions,
  signInStore: Instance<typeof SignInStore>,
  setIsAddNewEmbedDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
): SectionHeaderProps => {
  const spacing = useSpacing();
  const ActionElement = observer((): React.ReactElement => {
    return (
      <Stack direction="row" gap={spacing.spaceSM}>
        {signInStore.isPrivilegeGranted(UserPrivileges.viewSurveyReport) && (
          <Button
            name="downloadReports"
            size="medium"
            variant="outlined-color"
            onClick={async (): Promise<void> => {
              projectStore.reportStore.setDownloadReportsDialogState(
                DownloadReportsDialogState.FetchingSurveyNames,
              );
              setIsDownloadReportsDialogOpen(true);
              await processProjectParams(
                projectStore.getProjectSurveyNames,
                navigateTo,
              );
            }}
            label={t("projects.reports.downloadReports")}
            icon={<DownloadIcon />}
            iconPosition="leading"
          />
        )}
        {signInStore.isPrivilegeGranted(UserPrivileges.processEmbed) && (
          <Button
            name="addNewEmbed"
            size="medium"
            variant="outlined-color"
            onClick={async () => {
              setIsAddNewEmbedDialogOpen(true);
              await processProjectParams(
                projectStore.getProjectSpocDetails,
                navigateTo,
              );
            }}
            label={t("projects.reports.embeds.addNewEmbed")}
            icon={<Plus />}
            iconPosition="leading"
          />
        )}
      </Stack>
    );
  });
  return {
    title: t("projects.reports.reports"),
    actionElement: <ActionElement />,
    prefersLargeTitle: true,
  };
};
