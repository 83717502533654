import { Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  HUNDRED_PERCENTAGE_VALUE,
  PRIMARY_COLORS,
  SINGLE_SPACING,
  SURVEY_RESULTS_BREAKDOWN_BAR_WIDTH_IN_PX,
  VIEWED_SURVEY_LINK_COLOR_TOKEN,
  ZERO_PERCENTAGE_VALUE,
  ZERO_VALUE,
} from "@pulse/shared-components";
import {
  CornerRadius,
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import { SurveyDataModel } from "../../models/SurveyDataModel";
import { SurveyLinkBreakdownTable } from "./SurveyLinkBreakdownTable";
import { EmptyListPane } from "../../../surveys/components/EmptyListPane";

export interface SurveyResultsPaneProps {
  borderRadius: CornerRadius;
  border: BorderStyle;
  t: TFunction;
  surveyDataModel: Instance<typeof SurveyDataModel> | null;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  spacing: Spacing;
}

export const SurveyResultsPane = observer(
  ({
    border,
    borderRadius,
    t,
    surveyDataModel,
    tokens,
    typography,
    spacing,
  }: SurveyResultsPaneProps): React.ReactElement => {
    const SurveyBreakdownBar = ({
      linkPercentage,
      color,
    }: {
      linkPercentage: number;
      color: string;
    }): React.ReactElement => {
      return (
        <Stack
          bgcolor={color}
          borderRadius={borderRadius.radiusXXS}
          // Here, the width is computed based on the percentage of links and the total width of the breakdown bar.
          width={`${
            SURVEY_RESULTS_BREAKDOWN_BAR_WIDTH_IN_PX *
            (linkPercentage / HUNDRED_PERCENTAGE_VALUE)
          }px`}
        />
      );
    };

    return (
      <Stack
        maxWidth="464px"
        padding={`${spacing.spaceXL} ${spacing.space2XL}`}
        gap={spacing.spaceXL}
        borderRight={border.default}
      >
        <Typography sx={{ ...typography.sh1, color: tokens.label }}>
          {t("projects.dashboard.surveyResultsTitle")}
        </Typography>
        <Typography sx={{ ...typography.h3, color: tokens.label }}>
          {surveyDataModel ? surveyDataModel.totalLinks : ZERO_VALUE}
          {SINGLE_SPACING}
          <Typography
            component="span"
            sx={{ ...typography.b1, color: tokens.labelSubtle }}
          >
            {t("projects.dashboard.invitationsText")}
          </Typography>
        </Typography>
        {surveyDataModel ? (
          <Stack gap={spacing.spaceXS}>
            <Typography
              sx={{ ...typography.ol1, color: tokens.labelLowEmphasis }}
            >
              {t("projects.dashboard.surveyBreakdownText")}
            </Typography>
            <Stack height="8px" direction="row" gap={spacing.spaceXXS}>
              {surveyDataModel.submittedLinks.percentage !==
                ZERO_PERCENTAGE_VALUE && (
                <SurveyBreakdownBar
                  linkPercentage={surveyDataModel.submittedLinks.percentage}
                  color={PRIMARY_COLORS[500]}
                />
              )}
              {surveyDataModel.lastPageLinks.percentage !==
                ZERO_PERCENTAGE_VALUE && (
                <SurveyBreakdownBar
                  linkPercentage={surveyDataModel.lastPageLinks.percentage}
                  color={PRIMARY_COLORS[400]}
                />
              )}
              {surveyDataModel.inProgressLinks.percentage !==
                ZERO_PERCENTAGE_VALUE && (
                <SurveyBreakdownBar
                  linkPercentage={surveyDataModel.inProgressLinks.percentage}
                  color={PRIMARY_COLORS[300]}
                />
              )}
              {surveyDataModel.startedLinks.percentage !==
                ZERO_PERCENTAGE_VALUE && (
                <SurveyBreakdownBar
                  linkPercentage={surveyDataModel.startedLinks.percentage}
                  color={PRIMARY_COLORS[200]}
                />
              )}
              {surveyDataModel.viewedLinks.percentage !==
                ZERO_PERCENTAGE_VALUE && (
                <SurveyBreakdownBar
                  linkPercentage={surveyDataModel.viewedLinks.percentage}
                  color={VIEWED_SURVEY_LINK_COLOR_TOKEN}
                />
              )}
              {surveyDataModel.notStartedLinks.percentage !==
                ZERO_PERCENTAGE_VALUE && (
                <SurveyBreakdownBar
                  linkPercentage={surveyDataModel.notStartedLinks.percentage}
                  color={PRIMARY_COLORS[100]}
                />
              )}
            </Stack>
          </Stack>
        ) : (
          <Stack textAlign="center">
            <EmptyListPane
              headingText={t("projects.dashboard.noDataTitle")}
              subHeadingText={t(
                "projects.dashboard.noSurveyBreakdownDescription",
              )}
            />
          </Stack>
        )}

        {surveyDataModel && (
          <SurveyLinkBreakdownTable
            t={t}
            typography={typography}
            tokens={tokens}
            spacing={spacing}
            surveyDataModel={surveyDataModel}
            radius={borderRadius}
          />
        )}
      </Stack>
    );
  },
);
