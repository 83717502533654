import React from "react";

export const FeedbackInsightsLogo = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 2720 1281"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M424.945 418.967H641.622C643.745 429.588 644.809 440.392 644.809 451.362C644.809 463.047 643.745 474.199 641.622 484.821H502.482V574.572H612.944C615.068 585.907 616.131 596.876 616.131 607.498C616.131 618.833 615.068 629.985 612.944 640.954H502.482V800.807C489.022 802.931 476.276 803.993 464.244 803.993C451.142 803.993 438.039 802.931 424.945 800.807V418.967Z"
        fill="#0060AE"
      />
      <path
        d="M854.044 634.051C853.33 617.056 848.642 603.341 839.971 592.894C831.291 582.454 819.168 577.226 803.592 577.226C785.884 577.226 772.433 582.189 763.231 592.096C754.02 602.012 748.535 615.994 746.768 634.051H854.044ZM746.236 686.627C747.647 707.87 755.083 723.27 768.541 732.83C781.993 742.389 799.693 747.168 821.648 747.168C833.68 747.168 845.987 746.024 858.558 743.717C871.121 741.419 883.071 738.14 894.405 733.891C898.297 740.979 901.658 749.476 904.495 759.383C907.325 769.299 908.918 779.922 909.275 791.247C881.659 801.869 850.501 807.179 815.807 807.179C790.316 807.179 768.45 803.637 750.22 796.558C731.981 789.479 717.11 779.655 705.61 767.085C694.101 754.52 685.603 739.56 680.119 722.208C674.625 704.867 671.887 685.921 671.887 665.383C671.887 645.203 674.716 626.268 680.384 608.559C686.044 590.86 694.458 575.46 705.61 562.357C716.763 549.262 730.57 538.906 747.034 531.289C763.496 523.679 782.697 519.87 804.655 519.87C823.773 519.87 840.767 523.239 855.637 529.962C870.507 536.69 883.162 545.894 893.609 557.577C904.047 569.26 911.93 583.16 917.241 599.266C922.552 615.38 925.207 632.64 925.207 651.045C925.207 657.775 924.941 664.414 924.411 670.96C923.88 677.516 923.257 682.735 922.552 686.627H746.236Z"
        fill="#0060AE"
      />
      <path
        d="M1152.5 634.051C1151.79 617.056 1147.1 603.341 1138.43 592.894C1129.75 582.454 1117.62 577.226 1102.05 577.226C1084.34 577.226 1070.89 582.189 1061.69 592.096C1052.48 602.012 1046.99 615.994 1045.22 634.051H1152.5ZM1044.69 686.627C1046.1 707.87 1053.54 723.27 1067 732.83C1080.45 742.389 1098.15 747.168 1120.1 747.168C1132.14 747.168 1144.44 746.024 1157.01 743.717C1169.58 741.419 1181.53 738.14 1192.86 733.891C1196.75 740.979 1200.12 749.476 1202.95 759.383C1205.78 769.299 1207.38 779.922 1207.73 791.247C1180.12 801.869 1148.96 807.179 1114.26 807.179C1088.77 807.179 1066.91 803.637 1048.68 796.558C1030.44 789.479 1015.57 779.655 1004.07 767.085C992.558 754.52 984.06 739.56 978.576 722.208C973.082 704.867 970.344 685.921 970.344 665.383C970.344 645.203 973.173 626.268 978.841 608.559C984.5 590.86 992.915 575.46 1004.07 562.357C1015.22 549.262 1029.03 538.906 1045.49 531.289C1061.95 523.679 1081.15 519.87 1103.11 519.87C1122.23 519.87 1139.22 523.239 1154.09 529.962C1168.96 536.69 1181.62 545.894 1192.07 557.577C1202.5 569.26 1210.39 583.16 1215.7 599.266C1221.01 615.38 1223.66 632.64 1223.66 651.045C1223.66 657.775 1223.4 664.414 1222.87 670.96C1222.34 677.516 1221.71 682.735 1221.01 686.627H1044.69Z"
        fill="#0060AE"
      />
      <path
        d="M1447.78 583.069C1440.69 581.302 1434.94 580.148 1430.52 579.616C1426.09 579.085 1420.51 578.821 1413.79 578.821C1402.1 578.821 1391.92 581.209 1383.25 585.989C1374.57 590.769 1367.4 597.142 1361.74 605.108C1356.08 613.074 1351.92 622.459 1349.26 633.254C1346.61 644.059 1345.28 655.294 1345.28 666.977C1345.28 681.498 1347.05 693.796 1350.59 703.886C1354.13 713.977 1359 722.035 1365.19 728.05C1371.38 734.074 1378.74 738.407 1387.23 741.061C1395.73 743.717 1404.93 745.044 1414.85 745.044C1420.87 745.044 1426.35 744.778 1431.31 744.248C1436.27 743.717 1441.75 742.571 1447.78 740.796V583.069ZM1447.78 412.594C1454.15 411.531 1460.43 410.826 1466.63 410.47C1472.82 410.121 1478.93 409.938 1484.95 409.938C1490.97 409.938 1497.17 410.121 1503.54 410.47C1509.91 410.826 1516.28 411.531 1522.66 412.594V791.778C1506.01 796.741 1489.11 800.542 1471.94 803.197C1454.76 805.851 1433.97 807.179 1409.54 807.179C1390.77 807.179 1372.8 804.607 1355.64 799.479C1338.46 794.35 1323.32 786.203 1310.23 775.051C1297.13 763.898 1286.77 749.476 1279.16 731.768C1271.54 714.069 1267.74 692.469 1267.74 666.977C1267.74 646.797 1271.01 627.77 1277.57 609.887C1284.11 592.013 1293.5 576.522 1305.71 563.418C1317.93 550.325 1332.71 539.969 1350.06 532.35C1367.4 524.742 1386.7 520.934 1407.95 520.934C1414.32 520.934 1420.96 521.198 1427.86 521.729C1434.76 522.261 1441.4 523.414 1447.78 525.182V412.594Z"
        fill="#0060AE"
      />
      <path
        d="M1669.23 741.328C1673.48 742.747 1677.99 743.634 1682.77 743.982C1687.55 744.339 1692.41 744.514 1697.38 744.514C1720.39 744.514 1738.44 737.793 1751.54 724.333C1764.64 710.883 1771.19 689.813 1771.19 661.135C1771.19 635.645 1767.12 616.26 1758.98 602.983C1750.83 589.706 1737.02 583.069 1717.56 583.069C1703.39 583.069 1691.8 587.765 1682.77 597.142C1673.74 606.527 1669.23 621.305 1669.23 641.486V741.328ZM1594.35 412.594C1607.09 410.47 1619.48 409.407 1631.52 409.407C1643.91 409.407 1656.48 410.47 1669.23 412.594V552.798C1674.54 543.238 1683.38 535.362 1695.78 529.164C1708.17 522.974 1722.34 519.871 1738.27 519.871C1754.2 519.871 1768.98 522.708 1782.61 528.368C1796.24 534.035 1808.01 542.624 1817.93 554.125C1827.84 565.635 1835.63 580.063 1841.29 597.407C1846.95 614.758 1849.79 635.296 1849.79 659.011C1849.79 682.03 1846.25 702.65 1839.17 720.88C1832.08 739.12 1821.82 754.603 1808.37 767.349C1794.91 780.095 1778.45 789.92 1758.98 796.823C1739.5 803.729 1717.56 807.179 1693.13 807.179C1685.33 807.179 1677.1 806.823 1668.43 806.118C1659.75 805.405 1650.99 804.433 1642.14 803.197C1633.29 801.952 1624.79 800.367 1616.65 798.417C1608.5 796.476 1601.07 794.259 1594.35 791.778V412.594Z"
        fill="#0060AE"
      />
      <path
        d="M2045.76 674.943C2041.51 674.239 2036.9 673.616 2031.95 673.084C2026.99 672.554 2022.56 672.288 2018.67 672.288C2001.32 672.288 1987.6 675.391 1977.51 681.582C1967.42 687.78 1962.38 697.779 1962.38 711.587C1962.38 720.799 1964.32 727.875 1968.22 732.83C1972.11 737.793 1976.8 741.419 1982.29 743.717C1987.78 746.024 1993.62 747.435 1999.82 747.964C2006.01 748.496 2011.41 748.762 2016.02 748.762C2021.33 748.762 2026.64 748.322 2031.95 747.435C2037.26 746.554 2041.86 745.759 2045.76 745.044V674.943ZM2045.76 620.242C2045.76 611.745 2044.34 604.842 2041.51 599.53C2038.67 594.221 2034.78 590.063 2029.82 587.051C2024.86 584.047 2018.75 582.006 2011.5 580.944C2004.24 579.881 1996.18 579.35 1987.34 579.35C1968.22 579.35 1947.86 583.069 1926.27 590.503C1921.3 581.301 1917.59 572.629 1915.11 564.48C1912.63 556.34 1911.39 546.608 1911.39 535.272C1926.97 529.962 1942.55 526.069 1958.13 523.588C1973.7 521.115 1988.22 519.87 2001.68 519.87C2038.49 519.87 2067.26 528.724 2087.98 546.425C2108.69 564.131 2119.04 592.454 2119.04 631.395V790.716C2106.65 794.617 2091.6 798.334 2073.9 801.869C2056.2 805.404 2036.2 807.179 2013.89 807.179C1995.83 807.179 1979.28 805.586 1964.24 802.401C1949.18 799.213 1936.26 793.903 1925.47 786.468C1914.66 779.032 1906.35 769.474 1900.51 757.79C1894.67 746.106 1891.75 731.768 1891.75 714.773C1891.75 697.779 1895.37 683.532 1902.63 672.022C1909.88 660.521 1919.27 651.31 1930.78 644.406C1942.28 637.503 1955.12 632.549 1969.28 629.538C1983.44 626.532 1997.6 625.022 2011.77 625.022C2022.03 625.022 2033.36 625.553 2045.76 626.617V620.242Z"
        fill="#0060AE"
      />
      <path
        d="M2370.24 736.016C2374.48 743.103 2378.02 751.335 2380.86 760.711C2383.69 770.097 2385.11 781.157 2385.11 793.903C2370.94 799.571 2357.66 803.196 2345.28 804.79C2332.88 806.383 2319.96 807.179 2306.51 807.179C2283.49 807.179 2263.49 803.545 2246.5 796.294C2229.5 789.04 2215.43 779.032 2204.28 766.286C2193.12 753.542 2184.71 738.406 2179.05 720.881C2173.38 703.355 2170.55 684.328 2170.55 663.792C2170.55 643.61 2173.29 624.849 2178.79 607.498C2184.27 590.155 2192.59 574.928 2203.75 561.825C2214.9 548.732 2228.79 538.458 2245.43 531.023C2262.07 523.588 2281.36 519.871 2303.32 519.871C2311.1 519.871 2318.27 520.053 2324.83 520.402C2331.38 520.758 2337.66 521.465 2343.68 522.526C2349.7 523.588 2355.71 525.006 2361.74 526.774C2367.76 528.55 2374.48 530.849 2381.92 533.679C2381.92 541.828 2380.86 550.938 2378.73 561.028C2376.61 571.12 2373.24 580.771 2368.64 589.972C2358.02 586.437 2348.73 584.047 2340.76 582.802C2332.79 581.566 2323.68 580.943 2313.41 580.943C2291.45 580.943 2274.91 588.114 2263.76 602.452C2252.6 616.791 2247.03 637.237 2247.03 663.792C2247.03 692.469 2253.04 713.364 2265.08 726.456C2277.12 739.56 2293.41 746.106 2313.94 746.106C2319.25 746.106 2324.12 746.024 2328.55 745.841C2332.97 745.667 2337.31 745.228 2341.56 744.514C2345.81 743.808 2350.14 742.746 2354.57 741.327C2358.99 739.916 2364.21 738.14 2370.24 736.016Z"
        fill="#0060AE"
      />
      <path
        d="M2518.93 656.886L2586.9 526.243C2600.71 524.12 2614.7 523.057 2628.86 523.057C2642.31 523.057 2655.41 524.12 2668.16 526.243L2598.06 653.169L2679.84 800.806C2665.32 802.93 2651.34 803.993 2637.89 803.993C2625.49 803.993 2612.4 802.93 2598.59 800.806L2518.93 656.886ZM2433.43 412.594C2445.46 410.47 2457.86 409.407 2470.6 409.407C2483.35 409.407 2495.91 410.47 2508.31 412.594V800.806C2495.91 802.93 2483.35 803.993 2470.6 803.993C2457.86 803.993 2445.46 802.93 2433.43 800.806V412.594Z"
        fill="#0060AE"
      />
      <path
        d="M191.859 171.184V171.218H617.29L617.244 171.184H480.199H191.859Z"
        fill="#EA1F27"
      />
      <path
        d="M775.905 329.8L645.034 198.962H631.792H219.625L350.475 329.8H638.815H775.905Z"
        fill="black"
      />
      <path
        d="M199.617 631.158V642.998L329.984 773.389V638.133V349.282L199.617 218.938V631.158Z"
        fill="black"
      />
      <path
        d="M617.29 171.184L493.418 47.322L617.244 171.184H617.29Z"
        fill="#EA1F27"
      />
      <path
        d="M493.42 47.3225L486.421 40.3571H473.179H61.0117L68.0107 47.3225L191.861 171.184H480.202H617.246L493.42 47.3225Z"
        fill="#EC1C24"
      />
      <path
        d="M47.999 67.2869L41 60.2991V472.536V484.377L47.999 491.376L171.367 614.773V190.666L47.999 67.2869Z"
        fill="#EC1C24"
      />
      <path
        d="M1662.69 1171.6H1634.07V949.868H1662.69V1171.6Z"
        fill="#EC1C24"
      />
      <path
        d="M1715 1055.47C1715 1039.02 1714.67 1025.53 1713.68 1012.37H1739.34L1740.98 1038.69H1741.64C1749.54 1023.56 1767.96 1008.76 1794.28 1008.76C1816.32 1008.76 1850.54 1021.91 1850.54 1076.53V1171.6H1821.58V1079.81C1821.58 1054.16 1812.04 1032.77 1784.74 1032.77C1765.66 1032.77 1750.86 1046.26 1745.92 1062.38C1744.6 1066 1743.95 1070.93 1743.95 1075.87V1171.6H1715V1055.47Z"
        fill="#EC1C24"
      />
      <path
        d="M1893.63 1141.99C1902.18 1147.59 1917.32 1153.51 1931.79 1153.51C1952.85 1153.51 1962.71 1142.98 1962.71 1129.82C1962.71 1116 1954.49 1108.44 1933.11 1100.54C1904.48 1090.34 1891 1074.55 1891 1055.47C1891 1029.81 1911.72 1008.76 1945.94 1008.76C1962.06 1008.76 1976.2 1013.36 1985.09 1018.63L1977.85 1039.68C1971.6 1035.73 1960.08 1030.47 1945.28 1030.47C1928.17 1030.47 1918.63 1040.34 1918.63 1052.18C1918.63 1065.34 1928.17 1071.26 1948.9 1079.16C1976.53 1089.69 1990.68 1103.5 1990.68 1127.19C1990.68 1155.15 1968.97 1174.89 1931.13 1174.89C1913.7 1174.89 1897.58 1170.61 1886.39 1164.03L1893.63 1141.99Z"
        fill="#EC1C24"
      />
      <path
        d="M2027.85 1012.37H2056.8V1171.6H2027.85V1012.37ZM2060.09 967.634C2060.42 977.502 2053.19 985.398 2041.67 985.398C2031.47 985.398 2024.23 977.502 2024.23 967.634C2024.23 957.435 2031.8 949.54 2042.33 949.54C2053.18 949.54 2060.09 957.435 2060.09 967.634Z"
        fill="#EC1C24"
      />
      <path
        d="M2212.08 1075.21C2212.08 1070.28 2211.75 1066 2210.44 1062.05C2205.17 1045.27 2191.03 1031.45 2169.97 1031.45C2142.34 1031.45 2122.6 1054.81 2122.6 1091.66C2122.6 1122.91 2138.39 1148.9 2169.64 1148.9C2187.41 1148.9 2203.53 1137.72 2209.78 1119.29C2211.42 1114.36 2212.08 1108.77 2212.08 1103.83V1075.21ZM2242.02 1012.37C2241.36 1023.89 2240.7 1036.72 2240.7 1056.13V1148.57C2240.7 1185.09 2233.47 1207.46 2218 1221.28C2202.54 1235.75 2180.17 1240.36 2160.1 1240.36C2141.02 1240.36 2119.97 1235.75 2107.14 1227.2L2114.37 1205.16C2124.9 1211.74 2141.35 1217.66 2161.09 1217.66C2190.7 1217.66 2212.41 1202.2 2212.41 1162.06V1144.3H2211.75C2202.87 1159.1 2185.76 1170.94 2161.09 1170.94C2121.61 1170.94 2093.32 1137.39 2093.32 1093.3C2093.32 1039.35 2128.52 1008.76 2165.04 1008.76C2192.67 1008.76 2207.8 1023.23 2214.71 1036.39H2215.37L2216.69 1012.37H2242.02Z"
        fill="#EC1C24"
      />
      <path
        d="M2288.73 938.026H2317.68V1037.38H2318.34C2322.94 1029.15 2330.18 1021.91 2339.07 1016.98C2347.62 1012.05 2357.82 1008.76 2368.67 1008.76C2390.06 1008.76 2424.27 1021.91 2424.27 1076.86V1171.6H2395.32V1080.14C2395.32 1054.48 2385.78 1032.77 2358.47 1032.77C2339.72 1032.77 2324.92 1045.93 2319.65 1061.72C2318.01 1065.67 2317.68 1069.95 2317.68 1075.54V1171.6H2288.73V938.026Z"
        fill="#EC1C24"
      />
      <path
        d="M2504.87 966.646V1012.37H2546.32V1034.42H2504.87V1120.28C2504.87 1140.02 2510.46 1151.2 2526.58 1151.2C2534.15 1151.2 2539.74 1150.22 2543.36 1149.23L2544.68 1170.94C2539.09 1173.25 2530.2 1174.89 2519.02 1174.89C2505.53 1174.89 2494.67 1170.61 2487.76 1162.72C2479.54 1154.17 2476.58 1140.02 2476.58 1121.27V1034.42H2451.91V1012.37H2476.58V974.213L2504.87 966.646Z"
        fill="#EC1C24"
      />
      <path
        d="M2574.94 1141.99C2583.5 1147.59 2598.63 1153.51 2613.1 1153.51C2634.16 1153.51 2644.03 1142.98 2644.03 1129.82C2644.03 1116 2635.8 1108.44 2614.42 1100.54C2585.8 1090.34 2572.31 1074.55 2572.31 1055.47C2572.31 1029.81 2593.03 1008.76 2627.25 1008.76C2643.37 1008.76 2657.52 1013.36 2666.4 1018.63L2659.16 1039.68C2652.91 1035.73 2641.4 1030.47 2626.59 1030.47C2609.49 1030.47 2599.94 1040.34 2599.94 1052.18C2599.94 1065.34 2609.49 1071.26 2630.21 1079.16C2657.84 1089.69 2671.99 1103.5 2671.99 1127.19C2671.99 1155.15 2650.28 1174.89 2612.45 1174.89C2595.01 1174.89 2578.89 1170.61 2567.7 1164.03L2574.94 1141.99Z"
        fill="#EC1C24"
      />
    </svg>
  );
};
