import { Stack, Typography } from "@mui/material";
import { BorderStyle, EMPTY_CHARACTER } from "@pulse/shared-components";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import { GridColumnQuestionV1Model } from "../../models/GridColumnQuestionV1Model";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";
import { CommonChoiceOptionsLayout } from "../CommonChoiceOptionsLayout";
import React from "react";
import { NumericStepper } from "../NumericStepper";
import { MAXIMUM_GRID_COLUMN_OPTIONS } from "@pulse/shared-components";

export interface GridMultiChoiceQuestionProps {
  spacing: Spacing;
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
  gridColumnQuestionV1Model: Instance<typeof GridColumnQuestionV1Model>;
  t: TFunction;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
}

export const GridMultiChoiceQuestion = observer(
  ({
    typography,
    spacing,
    t,
    gridColumnQuestionV1Model,
    questionDetailsStore,
    border,
    tokens,
  }: GridMultiChoiceQuestionProps): React.ReactElement => {
    const MaxSelectionField = observer((): React.ReactElement => {
      return (
        <Stack width="200px" flexDirection={"row"}>
          <NumericStepper
            isPlusIconDisabled={gridColumnQuestionV1Model.multiChoice.isMaxSelectionIncrementDisabled(
              MAXIMUM_GRID_COLUMN_OPTIONS,
            )}
            onPlusIconClick={(): void => {
              questionDetailsStore.setFieldValidations(null);
              gridColumnQuestionV1Model.multiChoice.incrementMaxSelection();
            }}
            isMinusIconDisabled={
              gridColumnQuestionV1Model.multiChoice
                .isMaxSelectionDecrementDisabled
            }
            onMinusIconClick={function (): void {
              questionDetailsStore.setFieldValidations(null);
              gridColumnQuestionV1Model.multiChoice.decrementMaxSelection();
            }}
            onInputChange={function (
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ): void {
              const inputNumber = Number(event.target.value);
              questionDetailsStore.setFieldValidations(null);

              if (Number.isSafeInteger(inputNumber)) {
                gridColumnQuestionV1Model.multiChoice.setMaxSelection(
                  Number(event.target.value),
                );
              }
            }}
            value={
              gridColumnQuestionV1Model.multiChoice.multiChoiceQuestionV1Model
                .maxSelection
            }
            isError={gridColumnQuestionV1Model.multiChoice.validateMaxSelection}
            spacing={spacing}
            tokens={tokens}
          />
        </Stack>
      );
    });

    return (
      <Stack gap={spacing.spaceXL}>
        <Stack padding={`${spacing.spaceSM} 0px`} borderBottom={border.default}>
          <Typography
            sx={{
              ...typography.sh3,
              color: tokens.label,
            }}
          >
            {t("surveys.addSurveyQuestion.singleChoice.choiceOptions")}
          </Typography>
        </Stack>
        <Stack spacing={spacing.spaceSM}>
          {gridColumnQuestionV1Model.multiChoice.multiChoiceQuestionV1Model.options.map(
            (_field, index) => {
              return (
                <CommonChoiceOptionsLayout
                  key={index}
                  optionTextWidth={undefined}
                  optionCodeWidth="132px"
                  t={t}
                  index={index}
                  spacing={spacing}
                  options={
                    gridColumnQuestionV1Model.multiChoice
                      .multiChoiceQuestionV1Model.options
                  }
                  isAddNewFieldButtonDisabled={gridColumnQuestionV1Model.multiChoice.isAddNewFieldButtonDisabled(
                    MAXIMUM_GRID_COLUMN_OPTIONS,
                  )}
                  isDeleteButtonDisabled={
                    gridColumnQuestionV1Model.multiChoice.isDeleteButtonDisabled
                  }
                  setFieldValidations={questionDetailsStore.setFieldValidations}
                  doesOptionContainErrors={
                    gridColumnQuestionV1Model.multiChoice
                      .doesOptionContainErrors
                  }
                  addNewField={() =>
                    gridColumnQuestionV1Model.multiChoice.addNewField(
                      {
                        option: EMPTY_CHARACTER,
                        code: EMPTY_CHARACTER,
                      },
                      index,
                    )
                  }
                  doesOptionCodeContainErrors={
                    gridColumnQuestionV1Model.multiChoice
                      .doesOptionCodeContainErrors
                  }
                  isOptionCodeNotUnique={
                    questionDetailsStore.isOptionCodeNotUnique
                  }
                  setDuplicateOptionCode={
                    questionDetailsStore.setDuplicateOptionCode
                  }
                  setCode={gridColumnQuestionV1Model.multiChoice.setCode}
                  isChevronUpDisabled={
                    gridColumnQuestionV1Model.multiChoice.isChevronUpDisabled
                  }
                  isChevronDownDisabled={
                    gridColumnQuestionV1Model.multiChoice.isChevronDownDisabled
                  }
                  moveFieldUp={
                    gridColumnQuestionV1Model.multiChoice.moveFieldUp
                  }
                  moveFieldDown={
                    gridColumnQuestionV1Model.multiChoice.moveFieldDown
                  }
                  removeField={
                    gridColumnQuestionV1Model.multiChoice.removeField
                  }
                  setFieldOptionText={
                    gridColumnQuestionV1Model.multiChoice.setFieldOptionText
                  }
                />
              );
            },
          )}
        </Stack>
        <Stack gap={spacing.spaceSM} width={"600px"}>
          <Typography
            {...typography.sh3}
            borderBottom={border.default}
            paddingBottom={spacing.spaceSM}
            marginBottom={spacing.spaceSM}
            color={tokens.label}
          >
            {t("surveys.addSurveyQuestion.multiChoice.maxSelectionTitle")}
          </Typography>

          <MaxSelectionField />
        </Stack>
      </Stack>
    );
  },
);
