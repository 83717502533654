import { Instance, getParentOfType, types } from "mobx-state-tree";
import { SingleChoicePresets } from "../store/SingleChoiceQuestionV1Store";
import {
  EMPTY_CHARACTER,
  OpenEndedFieldModel,
  GridColumnQuestionTypeV1Enum,
  PresetScales,
  createOpenEndedFieldModel,
  doesCodeContainAllowedCharacters,
} from "@pulse/shared-components";
import {
  GridSingleChoiceQuestionV1Model,
  createGridSingleChoiceQuestionV1Model,
} from "./GridSingleChoiceQuestionV1Model";
import { GridQuestionV1Store } from "../store/GridQuestionV1Store";
import {
  FieldValidations,
  QuestionDetailsStore,
} from "../store/QuestionDetailsStore";
import {
  MultiChoiceQuestionV1Store,
  createMultiChoiceQuestionV1Store,
} from "../store/MultiChoiceQuestionV1Store";
import {
  Code,
  GridColumnQuestionTypeV1,
  GridColumnV1,
  GridMultiChoiceQuestionV1,
  GridOpenEndedQuestionV1,
  OpenEndedFieldV1,
  SurveyLocalizedText,
} from "@pulse/pulse-rpcs";
import { GridSize } from "@pulse/pulse-rpcs";
import { DEFAULT_GRID_COLUMN_SIZE } from "@pulse/shared-components";

export const GridColumnQuestionV1Model = types
  .model("GridColumnQuestionV1Model", {
    columnHeader: types.string,
    columnCode: types.string,
    questionType: types.enumeration(
      Object.values(GridColumnQuestionTypeV1Enum),
    ),
    openEndedSingleEntry: types.optional(
      OpenEndedFieldModel,
      createOpenEndedFieldModel,
    ),
    singleChoice: types.optional(
      GridSingleChoiceQuestionV1Model,
      createGridSingleChoiceQuestionV1Model,
    ),
    singleChoicePresetScale: types.optional(types.number, 3),
    singleChoicePresetType: types.maybe(
      types.enumeration(Object.values(SingleChoicePresets)),
    ),
    multiChoice: types.optional(
      MultiChoiceQuestionV1Store,
      createMultiChoiceQuestionV1Store,
    ),
    gridColumnSize: types.optional(types.number, DEFAULT_GRID_COLUMN_SIZE),
  })
  .views((store) => ({
    get doesColumnCodeContainErrors(): boolean {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );
      return (
        (store.columnCode.trim() === EMPTY_CHARACTER &&
          questionDetailsStore.fieldValidations ===
            FieldValidations.UnfilledOptionField) ||
        store.columnCode.trim() === questionDetailsStore.duplicateOptionCode
      );
    },
    get gridColumnV1Object(): GridColumnV1 {
      const columnCode = new Code(store.columnCode);
      const columnHeader =
        store.columnHeader !== EMPTY_CHARACTER
          ? new SurveyLocalizedText(store.columnHeader)
          : null;
      const gridColumnSize = new GridSize(
        store.gridColumnSize ? store.gridColumnSize : 1,
      );
      let questionType: GridColumnQuestionTypeV1.GridColumnQuestionTypeV1;
      switch (store.questionType) {
        case GridColumnQuestionTypeV1Enum.MultiChoice:
          questionType = new GridColumnQuestionTypeV1.MultiChoice(
            new GridMultiChoiceQuestionV1(
              store.multiChoice.optionV1ArrayObject,
              store.multiChoice.multiChoiceQuestionV1Model.maxSelection,
            ),
          );
          break;
        case GridColumnQuestionTypeV1Enum.SingleChoice:
          questionType = new GridColumnQuestionTypeV1.SingleChoice(
            store.singleChoice.gridSingleSelectQuestionV1Model.gridSingleChoiceQuestionV1Object,
          );
          break;
        case GridColumnQuestionTypeV1Enum.OpenEnded:
          questionType = new GridColumnQuestionTypeV1.OpenEnded(
            new GridOpenEndedQuestionV1(
              new OpenEndedFieldV1(
                store.openEndedSingleEntry.fieldTitle !== EMPTY_CHARACTER
                  ? new SurveyLocalizedText(
                      store.openEndedSingleEntry.fieldTitle,
                    )
                  : null,
                store.openEndedSingleEntry.fieldHint !== EMPTY_CHARACTER
                  ? new SurveyLocalizedText(
                      store.openEndedSingleEntry.fieldHint,
                    )
                  : null,
                new Code(store.openEndedSingleEntry.code),
              ),
            ),
          );
      }
      return new GridColumnV1(
        columnHeader,
        columnCode,
        questionType,
        gridColumnSize,
      );
    },
  }))
  .actions((store) => ({
    resetFieldValidationsAndErrors: (): void => {
      const gridQuestionStore = <Instance<typeof GridQuestionV1Store>>(
        getParentOfType(store, GridQuestionV1Store)
      );
      gridQuestionStore.resetFieldValidationsAndErrors();
    },
    resetChoicePresetFields: (): void => {
      store.singleChoicePresetScale = PresetScales.LowPresetScale;
      store.singleChoicePresetType = undefined;
    },
  }))
  .actions((store) => ({
    setSingleChoicePresetScale: (scale: number): void => {
      store.singleChoicePresetScale = scale;
    },
    setSingleChoicePresetType: (
      singleChoicePresetType: string | undefined,
    ): void => {
      store.singleChoicePresetType = <SingleChoicePresets>(
        singleChoicePresetType
      );
    },
    setColumnHeader: (inputString: string): void => {
      store.columnHeader = inputString;
    },
    setColumnCode: (inputString: string): void => {
      store.columnCode = inputString;
    },
    setQuestionType: (questionType: string): void => {
      switch (questionType) {
        case GridColumnQuestionTypeV1Enum.OpenEnded:
          store.questionType = GridColumnQuestionTypeV1Enum.OpenEnded;
          break;
        case GridColumnQuestionTypeV1Enum.SingleChoice:
          store.questionType = GridColumnQuestionTypeV1Enum.SingleChoice;
          break;
        case GridColumnQuestionTypeV1Enum.MultiChoice:
          store.questionType = GridColumnQuestionTypeV1Enum.MultiChoice;
          break;
      }
    },
    setGridColumnSize: (gridColumnSize: number): void => {
      store.gridColumnSize = gridColumnSize;
    },
    setOpenEndedFieldTitle: (fieldTitle: string): void => {
      store.resetFieldValidationsAndErrors();
      store.openEndedSingleEntry.fieldTitle = fieldTitle;
    },
    setOpenEndedFieldHint: (fieldHint: string): void => {
      store.resetFieldValidationsAndErrors();
      store.openEndedSingleEntry.fieldHint = fieldHint;
    },
    setOpenEndedFieldCode: (code: string): void => {
      if (doesCodeContainAllowedCharacters(code)) {
        store.resetFieldValidationsAndErrors();
        store.openEndedSingleEntry.code = code;
      }
    },
  }))
  .views((store) => ({
    get isSingleChoicePresetScaleLow(): boolean {
      return store.singleChoicePresetScale === PresetScales.LowPresetScale;
    },
    get isSingleChoicePresetScaleNps(): boolean {
      return store.singleChoicePresetScale === PresetScales.NpsPresetScale;
    },
    get isPresetUndefined(): boolean {
      return store.singleChoicePresetType === undefined;
    },
    get isOpenEnded(): boolean {
      return store.questionType === GridColumnQuestionTypeV1Enum.OpenEnded;
    },
    get isSingleChoice(): boolean {
      return store.questionType === GridColumnQuestionTypeV1Enum.SingleChoice;
    },
    get isMultiChoice(): boolean {
      return store.questionType === GridColumnQuestionTypeV1Enum.MultiChoice;
    },
    get doesFieldCodeContainErrors(): boolean {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );
      return (
        (store.openEndedSingleEntry.code.trim() === EMPTY_CHARACTER &&
          questionDetailsStore.fieldValidations ===
            FieldValidations.UnfilledOptionField) ||
        store.openEndedSingleEntry.code.trim() ===
          questionDetailsStore.duplicateOptionCode
      );
    },
  }));

export const createGridColumnQuestionV1Model = (): Instance<
  typeof GridColumnQuestionV1Model
> => {
  return GridColumnQuestionV1Model.create({
    columnCode: EMPTY_CHARACTER,
    columnHeader: EMPTY_CHARACTER,
    openEndedSingleEntry: createOpenEndedFieldModel(),
    questionType: GridColumnQuestionTypeV1Enum.OpenEnded,
  });
};
