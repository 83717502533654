import { Box, ListItemButton, Stack, Typography } from "@mui/material";
import React, { SetStateAction } from "react";
import {
  ChevronRight,
  Dialog,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { QuestionType } from "@pulse/pulse-rpcs";
import {
  EMPTY_CHARACTER,
  getEllipsizedTextProperties,
} from "@pulse/shared-components";
import { observer } from "mobx-react";
import { useQuestionDetailsStore } from "../store/hooks";
import {
  getQuestionTypeIcon,
  getQuestionTypeTypography,
} from "../../utils/QuestionTypeIconAndTypographyUtils";
import { useSurveyStore } from "../../store/Hooks";

export const QuestionTypeItem = observer(
  ({
    questionTypelist,
    setIsAddQuestionDialogVisible,
    setSelectedQuestionItemId,
    addChildQuestion,
    selectedQuestionItemId,
  }: {
    questionTypelist: QuestionType.QuestionType[];
    setIsAddQuestionDialogVisible: (value: SetStateAction<boolean>) => void;
    setSelectedQuestionItemId: (value: SetStateAction<string | null>) => void;
    addChildQuestion: boolean;
    selectedQuestionItemId: string | null;
  }): React.ReactElement => {
    const typography = useTypography();
    const tokens = useFoundationColorTokens();
    const surveyStore = useSurveyStore();
    const questionDetailsStore = useQuestionDetailsStore();
    const spacing = useSpacing();
    const cornerRadius = useCornerRadius();
    const { t } = useTranslation();

    const setupNewQuestion = (
      questionType: QuestionType.QuestionType,
      questionNumber: number,
    ): void => {
      questionDetailsStore.setQuestionType(questionType);
      questionDetailsStore.updatedQuestionDetails.basicQuestionDetails.setQuestionCode(
        `Q${questionNumber}`,
      );
      surveyStore.addNewQuestion(
        `Q${questionNumber}`,
        EMPTY_CHARACTER,
        questionType,
        questionNumber,
        false,
        true,
        questionDetailsStore.parentQuestionId,
        false,
      );
    };

    const openEndedFieldPlaceHolder = t(
      "surveys.addSurveyQuestion.openEnded.hintPlaceholder",
    );

    return (
      <Stack gap={spacing.spaceLG} width={"100%"}>
        <Stack gap={spacing.spaceXXS}>
          {questionTypelist.map((questionType, index) => {
            return (
              <ListItemButton
                key={index}
                onClick={async (): Promise<void> => {
                  if (addChildQuestion) {
                    questionDetailsStore.setParentQuestionId(
                      selectedQuestionItemId,
                    );
                  } else {
                    questionDetailsStore.resetChildAndParentQuestionIds();
                  }
                  setIsAddQuestionDialogVisible(false);
                  setSelectedQuestionItemId(null);
                  questionDetailsStore.clearStore(t);

                  const questionNumber = surveyStore.newQuestionOrder;
                  switch (questionType) {
                    case QuestionType.QuestionType.OPEN_ENDED: {
                      questionDetailsStore.updatedQuestionDetails.openEndedFieldV1Store.setFieldHint(
                        openEndedFieldPlaceHolder,
                        // This number indicates the index of the option array element that needs to be changed.
                        0,
                      );
                      setupNewQuestion(
                        QuestionType.QuestionType.OPEN_ENDED,
                        questionNumber,
                      );
                      break;
                    }
                    case QuestionType.QuestionType.SINGLE_CHOICE: {
                      setupNewQuestion(
                        QuestionType.QuestionType.SINGLE_CHOICE,
                        questionNumber,
                      );
                      break;
                    }
                    case QuestionType.QuestionType.MULTIPLE_CHOICE: {
                      setupNewQuestion(
                        QuestionType.QuestionType.MULTIPLE_CHOICE,
                        questionNumber,
                      );
                      break;
                    }
                    case QuestionType.QuestionType.RANKING: {
                      setupNewQuestion(
                        QuestionType.QuestionType.RANKING,
                        questionNumber,
                      );
                      break;
                    }
                    case QuestionType.QuestionType.GRID: {
                      questionDetailsStore.updatedQuestionDetails.gridQuestionV1Store.gridColumnQuestions[0].setColumnCode(
                        "Col1",
                      );
                      questionDetailsStore.updatedQuestionDetails.gridQuestionV1Store.gridColumnQuestions[0].setOpenEndedFieldHint(
                        openEndedFieldPlaceHolder,
                      );
                      setupNewQuestion(
                        QuestionType.QuestionType.GRID,
                        questionNumber,
                      );
                      break;
                    }
                    case QuestionType.QuestionType.MESSAGE: {
                      setupNewQuestion(
                        QuestionType.QuestionType.MESSAGE,
                        questionNumber,
                      );
                      break;
                    }
                    case QuestionType.QuestionType.GROUP: {
                      setupNewQuestion(
                        QuestionType.QuestionType.GROUP,
                        questionNumber,
                      );
                      break;
                    }
                  }
                  if (
                    questionDetailsStore.questionType !==
                    QuestionType.QuestionType.GROUP
                  ) {
                    // This is done to ensure that the save button is enabled for a group question.
                    questionDetailsStore.saveInitialQuestionDetails();
                  }
                }}
                sx={{
                  padding: "0px",
                  borderRadius: cornerRadius.radiusXS,
                  "&:hover": {
                    background: tokens.backgroundHovered,
                  },
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={spacing.spaceXS}
                  padding={spacing.spaceSM}
                  width="100%"
                  flexShrink="unset"
                >
                  {getQuestionTypeIcon(questionType, tokens.icon, true)}
                  <Typography
                    sx={{
                      ...typography.b1,
                      ...getEllipsizedTextProperties(),
                      color: tokens.label,
                    }}
                    flexGrow={1}
                  >
                    {getQuestionTypeTypography(questionType, t)}
                  </Typography>
                  <Box minHeight={"24px"} minWidth={"24px"} display={"flex"}>
                    <ChevronRight color={tokens.icon} />
                  </Box>
                </Stack>
              </ListItemButton>
            );
          })}
        </Stack>
      </Stack>
    );
  },
);

export const SelectQuestionTypeDialog = observer(
  ({
    isAddQuestionDialogVisible,
    setIsAddQuestionDialogVisible,
    setSelectedQuestionItemId,
    hideGroupQuestionType,
    selectedQuestionItemId,
  }: {
    isAddQuestionDialogVisible: boolean;
    setIsAddQuestionDialogVisible: (value: SetStateAction<boolean>) => void;
    setSelectedQuestionItemId: (value: SetStateAction<string | null>) => void;
    hideGroupQuestionType: boolean;
    selectedQuestionItemId: string | null;
  }): React.ReactElement => {
    const { t } = useTranslation();

    const QuestionTypes = (): React.ReactElement => {
      const questionTypes = [
        QuestionType.QuestionType.OPEN_ENDED,
        QuestionType.QuestionType.SINGLE_CHOICE,
        QuestionType.QuestionType.MULTIPLE_CHOICE,
        QuestionType.QuestionType.GRID,
        QuestionType.QuestionType.RANKING,
      ];
      if (!hideGroupQuestionType) {
        questionTypes.push(QuestionType.QuestionType.GROUP);
      }
      questionTypes.push(QuestionType.QuestionType.MESSAGE);
      return (
        <QuestionTypeItem
          questionTypelist={questionTypes}
          setIsAddQuestionDialogVisible={setIsAddQuestionDialogVisible}
          setSelectedQuestionItemId={setSelectedQuestionItemId}
          addChildQuestion={hideGroupQuestionType}
          selectedQuestionItemId={selectedQuestionItemId}
        />
      );
    };

    return (
      <Dialog
        open={isAddQuestionDialogVisible}
        title={t("surveys.addSurveyQuestion.selectQuestionType")}
        onClose={() => {
          setIsAddQuestionDialogVisible(false);
        }}
        showCloseButton={true}
        disableBackdropClick
      >
        {QuestionTypes()}
      </Dialog>
    );
  },
);
