import { Stack, Typography } from "@mui/material";
import React, { SetStateAction } from "react";
import { MousePointerClickIcon } from "../../../assets/icons/MousePointerClickIcon";
import {
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import {
  ICON_SIZE,
  IconContainer,
  NetworkingError,
  getEllipsizedTextProperties,
  getIconProps,
} from "@pulse/shared-components";
import { CommonQuestionConfigurationDetailsPane } from "./CommonQuestionConfigurationDetailsPane";
import { observer } from "mobx-react";
import { useQuestionDetailsStore } from "../store/hooks";
import { useRouteNavigator } from "../../../routes/RoutesHelper";
import { useSurveyStore } from "../../store/Hooks";
import { DeleteQuestionRPCErrorDialog } from "./commonQuestionConfigurationDetailsComponents/DeleteQuestionRPCErrorDialog";

export const QuestionConfigurationPane = observer(
  ({
    setSelectedItemQuestionId,
    setIsMessageBoxVisible,
    setMessageBoxQuestionItemId,
    selectedItemQuestionId,
    setShowAddOrUpdateSuccessfulDialog,
  }: {
    setSelectedItemQuestionId: React.Dispatch<
      React.SetStateAction<string | null>
    >;
    setIsMessageBoxVisible: (value: SetStateAction<boolean>) => void;
    setMessageBoxQuestionItemId: (value: SetStateAction<string | null>) => void;
    selectedItemQuestionId: string | null;
    setShowAddOrUpdateSuccessfulDialog: React.Dispatch<
      React.SetStateAction<boolean>
    >;
  }): React.ReactElement => {
    const tokens = useFoundationColorTokens();
    const typography = useTypography();
    const { t } = useTranslation();
    const navigateTo = useRouteNavigator();
    const surveyStore = useSurveyStore();
    const questionDetailsStore = useQuestionDetailsStore();
    const spacing = useSpacing();

    const getUnselectedItemsDescription = (): React.ReactElement => {
      return (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          height={"100%"}
          gap={spacing.spaceSM}
        >
          {IconContainer(
            <MousePointerClickIcon
              {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
            />,
          )}
          <Stack alignItems={"center"} justifyContent={"center"}>
            <Typography
              sx={{ ...typography.sh3, color: tokens.labelLowEmphasis }}
            >
              {t("surveys.addSurveyQuestion.questionNotSelected")}
            </Typography>
            <Typography
              sx={{
                ...typography.b2,
                ...getEllipsizedTextProperties(),
                alignItems: "center",
                color: tokens.labelLowEmphasis,
              }}
            >
              {t("surveys.addSurveyQuestion.questionNotSelectedDescription")}
            </Typography>
          </Stack>
        </Stack>
      );
    };

    const getSelectedItemConfigurationPane = (): React.ReactElement => {
      let selectedQuestion = surveyStore.questionList.find(
        (question) =>
          question.surveyQuestionDetails.questionId === selectedItemQuestionId,
      );
      if (selectedQuestion === undefined) {
        selectedQuestion = surveyStore.newlyAddedQuestion;
      }

      if (questionDetailsStore.rpcErrors === NetworkingError.InternalError) {
        navigateTo.internalServerError();
      }
      if (selectedQuestion) {
        return (
          <CommonQuestionConfigurationDetailsPane
            setSelectedItemQuestionId={setSelectedItemQuestionId}
            selectedQuestionItemId={selectedItemQuestionId}
            setIsMessageBoxVisible={setIsMessageBoxVisible}
            setMessageBoxQuestionItemId={setMessageBoxQuestionItemId}
            setShowAddOrUpdateSuccessfulDialog={
              setShowAddOrUpdateSuccessfulDialog
            }
          />
        );
      } else {
        return getUnselectedItemsDescription();
      }
    };

    return (
      <Stack flexGrow={1}>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          height={"100%"}
          gap={spacing.spaceSM}
        >
          {/* This component must not be rendered as a react component, but using a function call. This is because the re-rendering of the component causes an input field to lose focus on text change, when the empty field banner component is shown. */}
          {getSelectedItemConfigurationPane()}
          <DeleteQuestionRPCErrorDialog
            t={t}
            isRPCErrorDialogOpen={questionDetailsStore.isRPCErrorDialogOpen}
            setRPCErrors={questionDetailsStore.setRPCErrors}
            navigateTo={navigateTo}
            rpcErrors={questionDetailsStore.rpcErrors}
          />
        </Stack>
      </Stack>
    );
  },
);
