import { Stack, Typography } from "@mui/material";
import {
  RTETextValidationError,
  RichTextToolbarButtons,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuestionDetailsStore } from "../../store/hooks";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";
import {
  EMPTY_CHARACTER,
  MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
  MAX_QUESTION_TEXT_CHARACTER_LIMIT,
  showMaxCharacterCountIndicator,
  richTextTypography,
  RICH_TEXT_MARKDOWN_PROPS,
  getIconProps,
  ICON_SIZE,
  ZERO_VALUE,
  PRIMARY_FONT,
  RICH_TEXT_DEFAULT_FONT_COLOR,
} from "@pulse/shared-components";
import { InfoIcon } from "lucide-react";
import { TextInputField } from "@surya-digital/leo-reactjs-material-ui";
import { CustomWidthTooltip } from "../CustomWidthToolTip";
import { SurveySettingsEnums } from "@pulse/pulse-rpcs";
import { RTERegularTextType } from "@pulse/shared-components";

export const BasicQuestionInformationTab = observer((): React.ReactElement => {
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const { t } = useTranslation();
  const questionDetailsStore = useQuestionDetailsStore();

  const richTextButtonsProps: RichTextToolbarButtons = {
    ...RICH_TEXT_MARKDOWN_PROPS,
    undo: true,
    redo: true,
    copy: true,
    cut: true,
  };

  const TooltipDescription = (): React.ReactNode => {
    const B3Typography = ({ text }: { text: string }): React.ReactElement => {
      return <Typography {...typography.b3}>{text}</Typography>;
    };
    return (
      <Stack>
        <B3Typography
          text={t(
            "surveys.addSurveyQuestion.markdownTooltip.questionDescription",
          )}
        />
        <B3Typography
          text={t(
            "surveys.addSurveyQuestion.markdownTooltip.italicsDescription",
          )}
        />
        <B3Typography
          text={t(
            "surveys.addSurveyQuestion.markdownTooltip.strikethroughDescription",
          )}
        />
      </Stack>
    );
  };

  return (
    <Stack style={{ margin: `0px ${spacing.space2XL}`, gap: spacing.spaceXL }}>
      <Typography sx={{ ...typography.sh1, color: tokens.label }}>
        {t("surveys.addSurveyQuestion.basicInfo")}
      </Typography>
      <Stack style={{ gap: spacing.spaceLG }}>
        <Stack width="600px">
          <TextInputField
            name="questionCode"
            value={
              questionDetailsStore.updatedQuestionDetails.basicQuestionDetails
                .questionCode
            }
            placeholder={t("surveys.addSurveyQuestion.enterQuestionCode")}
            type="text"
            error={questionDetailsStore.doesQuestionCodeFieldContainErrors}
            label={t("surveys.addSurveyQuestion.questionCode")}
            required={true}
            onTextChange={(inputString: string) => {
              questionDetailsStore.setFieldValidations(null);
              questionDetailsStore.updatedQuestionDetails.basicQuestionDetails.setQuestionCode(
                inputString.slice(
                  ZERO_VALUE,
                  MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
                ),
              );
            }}
            maxCharacterLength={MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
                questionDetailsStore.updatedQuestionDetails.basicQuestionDetails
                  .questionCode,
              )
            }
          />
        </Stack>
        <Stack flexDirection={"row"} gap={spacing.spaceSM}>
          <Stack width="600px">
            <TextInputField
              name="question"
              value={
                questionDetailsStore.updatedQuestionDetails.basicQuestionDetails
                  .question
              }
              placeholder={
                questionDetailsStore.isMessageQuestion
                  ? t("surveys.addSurveyQuestion.enterMessage")
                  : t("surveys.addSurveyQuestion.enterQuestion")
              }
              type="text"
              label={
                questionDetailsStore.isMessageQuestion
                  ? t("surveys.questionTypes.message")
                  : t("surveys.addSurveyQuestion.question")
              }
              onTextChange={(inputString: string) => {
                questionDetailsStore.setFieldValidations(null);
                questionDetailsStore.updatedQuestionDetails.basicQuestionDetails.setQuestionString(
                  inputString.slice(
                    ZERO_VALUE,
                    MAX_QUESTION_TEXT_CHARACTER_LIMIT,
                  ),
                );
              }}
              error={questionDetailsStore.doesQuestionFieldContainErrors}
              maxCharacterLength={MAX_QUESTION_TEXT_CHARACTER_LIMIT}
              showCharacterCountIndicator={(): boolean =>
                showMaxCharacterCountIndicator(
                  MAX_QUESTION_TEXT_CHARACTER_LIMIT,
                  questionDetailsStore.updatedQuestionDetails
                    .basicQuestionDetails.question ?? EMPTY_CHARACTER,
                )
              }
            />
          </Stack>
          <CustomWidthTooltip
            title={TooltipDescription()}
            sx={{ marginTop: "36px" }}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: tokens.backgroundSubtle,
                  color: tokens.labelSubtle,
                },
              },
            }}
          >
            <Typography>
              <InfoIcon
                {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.medium)}
              />
            </Typography>
          </CustomWidthTooltip>
        </Stack>
        <Stack width="600px">
          <RichTextEditor
            name={t("surveys.addSurveyQuestion.questionDescription")}
            typography={richTextTypography(
              PRIMARY_FONT,
              SurveySettingsEnums.FontSizeScale.FontSizeScale.DEFAULT_SCALE,
              RICH_TEXT_DEFAULT_FONT_COLOR,
              true,
              RTERegularTextType.BACK_OFFICE,
            )}
            mode={"editable"}
            placeholder={
              questionDetailsStore.isMessageQuestion
                ? t("surveys.addSurveyQuestion.enterMessageDescription")
                : t("surveys.addSurveyQuestion.enterQuestionDescription")
            }
            label={
              questionDetailsStore.isMessageQuestion
                ? t("surveys.addSurveyQuestion.messageDescription")
                : t("surveys.addSurveyQuestion.questionDescription")
            }
            initialValue={
              questionDetailsStore.initialQuestionDetails.basicQuestionDetails
                .questionDescription
            }
            minHeight="96px"
            valueType="markdown"
            toolbarButtons={{ ...richTextButtonsProps }}
            supports={{ ...RICH_TEXT_MARKDOWN_PROPS }}
            onChange={(
              inputString: string,
              _error?: RTETextValidationError,
            ): void => {
              if (
                inputString !==
                questionDetailsStore.updatedQuestionDetails.basicQuestionDetails
                  .questionDescription
              ) {
                questionDetailsStore.updatedQuestionDetails.basicQuestionDetails.rteFileServiceModel.setIsFileUploadError(
                  false,
                );
              }
              questionDetailsStore.updatedQuestionDetails.basicQuestionDetails.setQuestionDescriptionString(
                inputString,
              );
            }}
            attachmentConfig={
              questionDetailsStore.updatedQuestionDetails.basicQuestionDetails
                .rteFileServiceModel.richTextAttachmentProps
            }
            helperText={
              questionDetailsStore.updatedQuestionDetails.basicQuestionDetails
                .rteFileServiceModel.isRTEFileServiceError
                ? t("errors.rteFileServiceErrorHelperText")
                : undefined
            }
            error={
              questionDetailsStore.updatedQuestionDetails.basicQuestionDetails
                .rteFileServiceModel.isRTEFileServiceError
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
});
