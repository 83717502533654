import { Stack, Typography } from "@mui/material";
import {
  FoundationColorTokens,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  AutoCompleteInputField,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";
import { ReportStore } from "../../store/ReportStore";
import { EMPTY_CHARACTER } from "@pulse/shared-components";

interface DownloadReportsChooseSurveyComponentProps {
  projectStore: Instance<typeof ProjectDetailsStore>;
  reportStore: Instance<typeof ReportStore>;
  typography: LeoTypography;
  spacing: Spacing;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
}

export const DownloadReportsChooseSurveyComponent = observer(
  ({
    projectStore,
    reportStore,
    typography,
    spacing,
    t,
    tokens,
  }: DownloadReportsChooseSurveyComponentProps): React.ReactElement => {
    return (
      <Stack gap={spacing.spaceMD} width="100%">
        <Stack gap={spacing.spaceXXS}>
          <Typography
            sx={{ ...typography.s1, color: tokens.label, width: "520px" }}
          >
            {t("projects.reports.chooseSurveyText")}
          </Typography>
          <Typography
            sx={{ ...typography.b2, color: tokens.labelSubtle, width: "520px" }}
          >
            {t("projects.reports.chooseSurveyDescriptionText")}
          </Typography>
        </Stack>
        <AutoCompleteInputField
          id="SelectSurvey"
          label={EMPTY_CHARACTER}
          placeholder={t("projects.reports.selectSurveyText")}
          options={projectStore.surveyIdAndNames.map((surveyIdAndName) => {
            return {
              label: surveyIdAndName.surveyName,
              id: surveyIdAndName.surveyId,
            };
          })}
          disabled={reportStore.isRPCLoading}
          onChange={(survey): void => {
            if (survey && survey.id) {
              reportStore.setSelectedSurvey(survey.id, survey.label);
            } else {
              reportStore.resetSelectedSurvey();
              // This else block also gets triggered when the user unselects an option, as in this case `survey` is null and `survey.id` is undefined.
              // As per the discussion here: https://surya-digital.slack.com/archives/C06EPPC2Y1E/p1712747738387459, this happens because the Leo component calls this onSelect on every change.
              console.warn("Survey and Survey Id not found.");
            }
          }}
          value={
            reportStore.selectedSurvey
              ? {
                  label: reportStore.selectedSurvey.surveyName,
                  id: reportStore.selectedSurvey.surveyId,
                }
              : undefined
          }
        />
      </Stack>
    );
  },
);
