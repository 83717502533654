import { observer } from "mobx-react";
import React from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import { TFunction } from "i18next";
import {
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TextInputField } from "@surya-digital/leo-reactjs-material-ui";
import { Stack } from "@mui/material";
import {
  MAX_SURVEY_NAME_LENGTH,
  ZERO_VALUE,
  showMaxCharacterCountIndicator,
} from "@pulse/shared-components";

interface SurveyNameSettingsProps {
  setSurveyName: (surveyName: string) => void;
  surveyName: string;
  doesUpdateSurveyNameTextFieldContainError: boolean;
  surveyNameHelperText: string | undefined;
  t: TFunction;
  spacing: Spacing;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
}

export const SurveyNameSettings = observer(
  ({
    setSurveyName,
    surveyName,
    doesUpdateSurveyNameTextFieldContainError,
    surveyNameHelperText,
    t,
    spacing,
    typography,
    tokens,
  }: SurveyNameSettingsProps): React.ReactElement => {
    return (
      <SettingsContainer
        settingHeadingText={t(
          "surveys.settings.surveyNameSettings.surveyNameTitleText",
        )}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
      >
        <Stack width="400px">
          <TextInputField
            name="surveyName"
            value={surveyName}
            required={true}
            type="text"
            onTextChange={(input) =>
              setSurveyName(input.slice(ZERO_VALUE, MAX_SURVEY_NAME_LENGTH))
            }
            label={t("surveys.settings.surveyNameSettings.surveyNameTitleText")}
            placeholder={t(
              "surveys.settings.surveyNameSettings.surveyNameTextboxPlaceholderText",
            )}
            error={doesUpdateSurveyNameTextFieldContainError}
            helperText={
              doesUpdateSurveyNameTextFieldContainError
                ? surveyNameHelperText
                : undefined
            }
            maxCharacterLength={MAX_SURVEY_NAME_LENGTH}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(MAX_SURVEY_NAME_LENGTH, surveyName)
            }
          />
        </Stack>
      </SettingsContainer>
    );
  },
);
