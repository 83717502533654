import { Stack, Typography } from "@mui/material";
import { ICON_SIZE, getIconProps } from "@pulse/shared-components";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { InfoIcon } from "lucide-react";
import { observer } from "mobx-react";
import React from "react";

export interface DialogErrorContentProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  errorText: string;
}

export const DialogErrorContent = observer(
  ({
    spacing,
    tokens,
    typography,
    errorText,
  }: DialogErrorContentProps): React.ReactElement => {
    return (
      <Stack
        alignItems="center"
        alignSelf="center"
        gap={spacing.spaceXS}
        padding={spacing.spaceXL}
        width={"100%"}
      >
        <InfoIcon {...getIconProps(tokens.iconError, ICON_SIZE.large)} />
        <Typography {...typography.b1} color={tokens.labelError} align="center">
          {errorText}
        </Typography>
      </Stack>
    );
  },
);
