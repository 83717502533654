import { Stack } from "@mui/material";
import {
  TextInputField,
  Spacing,
  AutoCompleteInputField,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import { BOUserStore } from "../store/BOUserStore";
import {
  MAX_USERNAME_CHAR_LIMIT,
  MIN_USERNAME_CHAR_LIMIT,
} from "@pulse/shared-components";
import { BoUserRole } from "@pulse/pulse-rpcs";
import { getTitleCase } from "../../projects/utils/StringUtils";

interface AddBOUserChildComponentProps {
  t: TFunction;
  spacing: Spacing;
  boUserStore: Instance<typeof BOUserStore>;
}

export const AddBOUserChildComponent = observer(
  ({
    t,
    boUserStore,
    spacing,
  }: AddBOUserChildComponentProps): React.ReactElement => {
    const addBOUserStore = boUserStore.addBOUserStore;

    return (
      <Stack gap={spacing.spaceLG}>
        <Stack direction="row" gap={spacing.spaceLG}>
          <TextInputField
            name={t("common.firstNameLabel")}
            value={addBOUserStore.boUserDetails.firstName}
            type="text"
            onTextChange={(firstName: string): void => {
              addBOUserStore.setIsAddBOButtonClicked(false);
              addBOUserStore.boUserDetails.setFirstName(firstName);
            }}
            placeholder={t("common.firstNamePlaceholder")}
            label={t("common.firstNameLabel")}
            disabled={addBOUserStore.isRPCLoading}
            required={true}
            error={addBOUserStore.isFirstNameInvalid}
            helperText={
              addBOUserStore.isFirstNameInvalid
                ? t("common.invalidFirstNameLengthHelperText", {
                    minFirstNameLength: MIN_USERNAME_CHAR_LIMIT,
                    maxFirstNameLength: MAX_USERNAME_CHAR_LIMIT,
                  })
                : undefined
            }
          />
          <TextInputField
            name={t("common.lastNameLabel")}
            value={addBOUserStore.boUserDetails.lastName}
            type="text"
            onTextChange={(lastName: string): void => {
              addBOUserStore.setIsAddBOButtonClicked(false);
              addBOUserStore.boUserDetails.setLastName(lastName);
            }}
            placeholder={t("common.lastNamePlaceholder")}
            label={t("common.lastNameLabel")}
            disabled={addBOUserStore.isRPCLoading}
            required={true}
            error={addBOUserStore.isLastNameInvalid}
            helperText={
              addBOUserStore.isLastNameInvalid
                ? t("common.invalidLastNameLengthHelperText", {
                    minLastNameLength: MIN_USERNAME_CHAR_LIMIT,
                    maxLastNameLength: MAX_USERNAME_CHAR_LIMIT,
                  })
                : undefined
            }
          />
        </Stack>
        <TextInputField
          name={t("common.enterEmailAddressLabel")}
          value={addBOUserStore.emailAddress}
          type="text"
          onTextChange={(emailAddress: string): void => {
            addBOUserStore.setEmailAddress(emailAddress);
          }}
          placeholder={t("common.enterEmailAddressPlaceHolder")}
          label={t("common.enterEmailAddressLabel")}
          disabled={addBOUserStore.isRPCLoading}
          required={true}
          error={addBOUserStore.isEmailAddressInvalid}
          helperText={
            addBOUserStore.isEmailAddressInvalid
              ? t("common.invalidEmailAddressHelperText")
              : undefined
          }
        />
        <AutoCompleteInputField
          id="SelectRole"
          label={t("manageUsers.role")}
          placeholder={t("manageUsers.rolePlaceholder")}
          options={Object.values(BoUserRole.BoUserRole).map((role) => {
            return {
              id: role,
              label: getTitleCase(role),
            };
          })}
          disabled={addBOUserStore.isRPCLoading}
          onChange={(role): void => {
            if (role && role.id) {
              addBOUserStore.boUserDetails.setRole(
                BoUserRole.BoUserRole[
                  role.id as keyof typeof BoUserRole.BoUserRole
                ],
              );
            } else {
              addBOUserStore.boUserDetails.resetRole();
              // This else block also gets triggered when the user unselects an option, as in this case `role` is null and `role.id` is undefined.
              // As per the discussion here: https://surya-digital.slack.com/archives/C06EPPC2Y1E/p1712747738387459, this happens because the Leo component calls this onSelect on every change.
              console.warn("Role and Role Id not found.");
            }
          }}
          value={
            addBOUserStore.boUserDetails.selectedRole
              ? {
                  label: addBOUserStore.boUserDetails.selectedRole,
                  id: addBOUserStore.boUserDetails.selectedRole,
                }
              : undefined
          }
          required={true}
        />
      </Stack>
    );
  },
);
