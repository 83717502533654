import {
  Spacing,
  FoundationColorTokens,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import React from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import { SettingsCheckboxComponent } from "./SettingsCheckboxComponent";
import { Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  MAX_SURVEY_INFORMATION_DIALOG_DESCRIPTION_TEXT_LENGTH,
} from "@pulse/shared-components";
import { SettingsRichTextEditor } from "./SettingsRichTextEditor";
import { Instance } from "mobx-state-tree";
import { RTEFileServiceModel } from "../../../projects/models/RTEFileServiceModel";

interface InfoDialogCustomisationSettingsProps {
  isInfoButtonShown: boolean;
  setIsInfoButtonShown: (isInfoButtonShown: boolean) => void;
  infoDialogDescription: string;
  setInfoDialogDescription: (infoDialogDescription: string) => void;
  isInfoDescriptionErrored: boolean;
  clearError: () => void;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  rteFileService: Instance<typeof RTEFileServiceModel>;
  updatedText: string;
}

export const InfoDialogCustomisationSettings = observer(
  ({
    isInfoButtonShown,
    setIsInfoButtonShown,
    infoDialogDescription,
    setInfoDialogDescription,
    isInfoDescriptionErrored,
    clearError,
    t,
    spacing,
    typography,
    tokens,
    border,
    rteFileService,
    updatedText,
  }: InfoDialogCustomisationSettingsProps): React.ReactElement => {
    return (
      <SettingsContainer
        settingHeadingText={t(
          "surveys.settings.infoDialogCustomisationSettings.infoDialogCustomisationTitleText",
        )}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
      >
        <Stack gap={spacing.spaceLG} width="720px">
          <SettingsCheckboxComponent
            isCheckboxSelected={isInfoButtonShown}
            setIsCheckboxSelected={setIsInfoButtonShown}
            checkboxlabel={t(
              "surveys.settings.infoDialogCustomisationSettings.infoDialogCheckboxLabelText",
            )}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
          />
          <Typography
            {...typography.sh3}
            py={spacing.spaceSM}
            borderBottom={border.default}
            color={tokens.label}
            width="100%"
          >
            {t(
              "surveys.settings.dialogCustomisationCommonTexts.dialogCustomisationTitleText",
            )}
          </Typography>
          <SettingsRichTextEditor
            label={t(
              "surveys.settings.dialogCustomisationCommonTexts.dialogDescriptionLabelText",
            )}
            placeholder={t(
              "surveys.settings.dialogCustomisationCommonTexts.dialogDescriptionPlaceholderText",
            )}
            rteText={infoDialogDescription}
            setRTEText={(rteText: string) => {
              setInfoDialogDescription(rteText);
              if (rteText !== updatedText) {
                rteFileService.setIsFileUploadError(false);
              }
              clearError();
            }}
            isError={isInfoDescriptionErrored}
            t={t}
            maxRTETextLength={
              MAX_SURVEY_INFORMATION_DIALOG_DESCRIPTION_TEXT_LENGTH
            }
            attachmentConfig={rteFileService.richTextAttachmentProps}
            isRTEFileServiceHelperTextVisible={
              rteFileService.isRTEFileServiceError
            }
          />
        </Stack>
      </SettingsContainer>
    );
  },
);
