import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  useFoundationColorTokens,
  useRadioButtonColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { PropsWithChildren } from "react";
import { useSingleChoiceQuestionV1Store } from "../store/hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { NPSOptions } from "./singleSelectOptionComponents/NPSOptions";
import { getRadioButtonStyleProps, useBorder } from "@pulse/shared-components";
import {
  SingleChoiceDisplayTypeV1Enum,
  CardDirection,
} from "@pulse/shared-components";
import { SingleChoiceDisplayTypeV1 } from "@pulse/pulse-rpcs";

interface OptionHeadingAndContentProps extends PropsWithChildren {
  optionTitle: string;
}

export const CardOrNPSOptions = observer((): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const radioButtonTokens = useRadioButtonColorTokens();
  const singleChoiceStore = useSingleChoiceQuestionV1Store();
  const typography = useTypography();
  const spacing = useSpacing();
  const border = useBorder();
  const { t } = useTranslation();
  const radioButtonStyleProps = getRadioButtonStyleProps(
    tokens,
    radioButtonTokens,
    spacing,
  );

  const OptionHeadingAndContent = observer(
    ({
      optionTitle,
      children,
    }: OptionHeadingAndContentProps): React.ReactElement => {
      return (
        <Stack width="600px" gap={spacing.spaceSM}>
          <Typography
            sx={{
              ...typography.sh3,
              padding: `${spacing.spaceSM} 0px`,
              borderBottom: border.default,
            }}
          >
            {optionTitle}
          </Typography>
          {children}
        </Stack>
      );
    },
  );

  switch (
    singleChoiceStore.singleSelectQuestionV1Model.singleChoiceDisplayType
  ) {
    case SingleChoiceDisplayTypeV1Enum.Card: {
      return (
        <Stack gap={spacing.spaceXL}>
          <OptionHeadingAndContent
            optionTitle={t(
              "surveys.addSurveyQuestion.singleChoice.cardDirection",
            )}
          >
            <RadioGroup
              row
              onChange={(e) => {
                singleChoiceStore.setCardDirectionAndTextAlignment(
                  e.target.value,
                );
              }}
            >
              <FormControlLabel
                value={CardDirection.Horizontal}
                label={t("surveys.addSurveyQuestion.singleChoice.horizontal")}
                control={<Radio {...radioButtonStyleProps} />}
                checked={
                  singleChoiceStore.singleSelectQuestionV1Model.isHorizontal
                }
              />
              <FormControlLabel
                value={CardDirection.Vertical}
                label={t("surveys.addSurveyQuestion.singleChoice.vertical")}
                checked={
                  singleChoiceStore.singleSelectQuestionV1Model.isVertical
                }
                control={<Radio {...radioButtonStyleProps} />}
              />
            </RadioGroup>
          </OptionHeadingAndContent>
          {singleChoiceStore.singleSelectQuestionV1Model.isHorizontal && (
            <OptionHeadingAndContent
              optionTitle={t(
                "surveys.addSurveyQuestion.singleChoice.textAlignment",
              )}
            >
              <RadioGroup
                row
                onChange={(e) => {
                  singleChoiceStore.setTextAlignment(e.target.value);
                }}
              >
                <FormControlLabel
                  value={
                    SingleChoiceDisplayTypeV1.CardEnums.CardDirection
                      .HorizontalEnums.TextAlignment.TextAlignment.LEFT
                  }
                  label={t("surveys.addSurveyQuestion.singleChoice.left")}
                  checked={
                    singleChoiceStore.singleSelectQuestionV1Model.isLeftAligned
                  }
                  control={<Radio {...radioButtonStyleProps} />}
                />
                <FormControlLabel
                  value={
                    SingleChoiceDisplayTypeV1.CardEnums.CardDirection
                      .HorizontalEnums.TextAlignment.TextAlignment.CENTER
                  }
                  label={t("surveys.addSurveyQuestion.singleChoice.center")}
                  control={<Radio {...radioButtonStyleProps} />}
                  checked={
                    singleChoiceStore.singleSelectQuestionV1Model
                      .isCenterAligned
                  }
                />
                <FormControlLabel
                  value={
                    SingleChoiceDisplayTypeV1.CardEnums.CardDirection
                      .HorizontalEnums.TextAlignment.TextAlignment.RIGHT
                  }
                  label={t("surveys.addSurveyQuestion.singleChoice.right")}
                  checked={
                    singleChoiceStore.singleSelectQuestionV1Model.isRightAligned
                  }
                  control={<Radio {...radioButtonStyleProps} />}
                />
              </RadioGroup>
            </OptionHeadingAndContent>
          )}
        </Stack>
      );
    }
    case SingleChoiceDisplayTypeV1Enum.DefaultNps: {
      return (
        <Stack width="600px">
          <NPSOptions
            border={border}
            typography={typography}
            t={t}
            spacing={spacing}
            checkBoxOnChange={(e): void => {
              singleChoiceStore.setNPSSelection(e);
            }}
            checkBoxState={
              singleChoiceStore.singleSelectQuestionV1Model
                .isNPSSelectionColored
            }
          />
        </Stack>
      );
    }
    default:
      return <></>;
  }
});
