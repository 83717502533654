import { Stack, Typography } from "@mui/material";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuestionDetailsStore } from "../store/hooks";
import { MaxSelectionField } from "./MaxSelectionField";
import { ConfirmationDialogComponent } from "@pulse/shared-components";
import { useBorder } from "@pulse/shared-components";
import { AddQuestionButtons } from "./commonQuestionConfigurationDetailsComponents/AddQuestionButtons";
import { BasicQuestionInformationTab } from "./commonQuestionConfigurationDetailsComponents/BasicQuestionInformationTab";
import { QuestionAttributes } from "./commonQuestionConfigurationDetailsComponents/QuestionAttributes";
import { SelectedItemConfigurationPane } from "./commonQuestionConfigurationDetailsComponents/SelectedItemConfigurationPane";
import { QuestionDetailsRPCErrorDialog } from "./commonQuestionConfigurationDetailsComponents/QuestionDetailsRPCErrorDialog";
import { AddQuestionErrorDialog } from "./commonQuestionConfigurationDetailsComponents/AddQuestionErrorDialog";
import { SurveyIsLiveConfirmationDialog } from "./commonQuestionConfigurationDetailsComponents/SurveyIsLiveConfirmationDialog";
import { EmptyFieldSnackbar } from "./commonQuestionConfigurationDetailsComponents/EmptyFieldSnackbar";
import { useSurveyStore } from "../../store/Hooks";
import { DeleteQuestionConfirmationDialog } from "./commonQuestionConfigurationDetailsComponents/DeleteQuestionConfirmationDialog";
import { useRouteNavigator } from "../../../routes/RoutesHelper";
import { GridSizeCustomisation } from "./gridQuestionComponents/GridSizeCustomisation";

export const CommonQuestionConfigurationDetailsPane = observer(
  ({
    setSelectedItemQuestionId,
    selectedQuestionItemId,
    setIsMessageBoxVisible,
    setMessageBoxQuestionItemId,
    setShowAddOrUpdateSuccessfulDialog,
  }: {
    setSelectedItemQuestionId: React.Dispatch<
      React.SetStateAction<string | null>
    >;
    selectedQuestionItemId: string | null;
    setIsMessageBoxVisible: (value: SetStateAction<boolean>) => void;
    setMessageBoxQuestionItemId: (value: SetStateAction<string | null>) => void;
    setShowAddOrUpdateSuccessfulDialog: React.Dispatch<
      React.SetStateAction<boolean>
    >;
  }): React.ReactElement => {
    const tokens = useFoundationColorTokens();
    const spacing = useSpacing();
    const typography = useTypography();
    const { t } = useTranslation();
    const questionDetailsStore = useQuestionDetailsStore();
    const surveyStore = useSurveyStore();
    const [
      showSurveyLiveConfirmationDialog,
      setShowSurveyLiveConfirmationDialog,
    ] = useState(false);
    const questionConfigurationElementRef = useRef<HTMLDivElement>(null);
    const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
    const [isDeleteQuestionDialogOpen, setIsDeleteQuestionDialogOpen] =
      useState(false);
    const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
    const border = useBorder();
    const borderRadius = useCornerRadius();
    const navigateTo = useRouteNavigator();

    useEffect(() => {
      questionConfigurationElementRef.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, [selectedQuestionItemId, surveyStore.newlyAddedQuestion]);

    return (
      <Stack height="100%" width="100%">
        <ConfirmationDialogComponent
          isDialogOpen={isResetDialogOpen}
          title={t("surveys.addSurveyQuestion.resetOptionsTitle")}
          description={t("surveys.addSurveyQuestion.resetOptionsDescription")}
          primaryButtonText={t("surveys.addSurveyQuestion.resetOptionsButton")}
          secondaryButtonText={t("common.cancel")}
          secondaryButtonCallback={(): void => {
            setIsResetDialogOpen(false);
          }}
          primaryButtonCallBack={async (): Promise<void> => {
            questionDetailsStore.updatedQuestionDetails.multiChoiceQuestionV1Store.resetOptions();
            questionDetailsStore.updatedQuestionDetails.singleChoiceQuestionV1Store.resetOptions();
            questionDetailsStore.updatedQuestionDetails.openEndedFieldV1Store.resetOptions();
            questionDetailsStore.updatedQuestionDetails.rankingQuestionV1Store.resetOptions();
            questionDetailsStore.updatedQuestionDetails.gridQuestionV1Store.resetGridRowsAndColumns();
            setIsResetDialogOpen(false);
          }}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            height: "64px",
            padding: `${spacing.spaceMD} ${spacing.spaceXL}`,
            borderBottom: border.default,
            position: "sticky",
          }}
        >
          <Typography sx={{ ...typography.sh3, color: tokens.label }}>
            {t("surveys.addSurveyQuestion.questionNumber", {
              index: selectedQuestionItemId
                ? surveyStore.findQuestionById(selectedQuestionItemId)
                    ?.surveyQuestionDetails.order
                : surveyStore.newQuestionOrder,
            })}
          </Typography>
          <AddQuestionButtons
            setIsDeleteQuestionDialogOpen={setIsDeleteQuestionDialogOpen}
            setShowAddOrUpdateSuccessfulDialog={
              setShowAddOrUpdateSuccessfulDialog
            }
            setShowSurveyLiveConfirmationDialog={
              setShowSurveyLiveConfirmationDialog
            }
            setSelectedItemQuestionId={setSelectedItemQuestionId}
            selectedQuestionItemId={selectedQuestionItemId}
            isGroupQuestion={questionDetailsStore.isGroupQuestion}
            setIsSaveButtonClicked={setIsSaveButtonClicked}
          />
        </Stack>
        <Stack
          overflow="auto"
          height="100%"
          ref={questionConfigurationElementRef}
        >
          <EmptyFieldSnackbar
            questionDetailsStore={questionDetailsStore}
            t={t}
            isSaveButtonClicked={isSaveButtonClicked}
            setIsSaveButtonClicked={setIsSaveButtonClicked}
          />
          <Stack
            style={{
              margin: `${spacing.space2XL} 0px`,
              gap: `${spacing.space2XL}`,
            }}
          >
            <BasicQuestionInformationTab />
            <SelectedItemConfigurationPane
              setIsResetDialogOpen={setIsResetDialogOpen}
              setSelectedItemQuestionId={setSelectedItemQuestionId}
              selectedQuestionItemId={selectedQuestionItemId}
              setIsMessageBoxVisible={setIsMessageBoxVisible}
              setMessageBoxQuestionItemId={setMessageBoxQuestionItemId}
            />
            {questionDetailsStore.isGridQuestion && (
              <GridSizeCustomisation
                spacing={spacing}
                tokens={tokens}
                typography={typography}
                t={t}
                border={border}
                borderRadius={borderRadius}
                gridQuestionV1Store={
                  questionDetailsStore.updatedQuestionDetails
                    .gridQuestionV1Store
                }
              />
            )}
            <QuestionAttributes />
          </Stack>
          {questionDetailsStore.isMaxSelectionFieldVisible && (
            <MaxSelectionField />
          )}
        </Stack>
        <QuestionDetailsRPCErrorDialog />
        <AddQuestionErrorDialog />
        <SurveyIsLiveConfirmationDialog
          showSurveyLiveConfirmationDialog={showSurveyLiveConfirmationDialog}
          setShowSurveyLiveConfirmationDialog={
            setShowSurveyLiveConfirmationDialog
          }
          setShowAddOrUpdateSuccessfulDialog={
            setShowAddOrUpdateSuccessfulDialog
          }
          setSelectedItemQuestionId={setSelectedItemQuestionId}
          selectedQuestionItemId={selectedQuestionItemId}
          tokens={tokens}
          t={t}
          navigateTo={navigateTo}
          questionDetailsStore={questionDetailsStore}
          spacing={spacing}
        />
        <DeleteQuestionConfirmationDialog
          isDeleteQuestionDialogOpen={isDeleteQuestionDialogOpen}
          setIsDeleteQuestionDialogOpen={setIsDeleteQuestionDialogOpen}
          t={t}
          questionDetailsStore={questionDetailsStore}
          selectedQuestionItemId={selectedQuestionItemId}
          setSelectedItemQuestionId={setSelectedItemQuestionId}
        />
      </Stack>
    );
  },
);
