import { flow, getRoot, Instance, types } from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import {
  ArchiveBOUserRPC,
  SendPasswordResetLinkToBOUserRPC,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { RootStore } from "../../root/store/RootStore";
import {
  useArchiveBOUserRPCClientImpl,
  useSendPasswordResetLinkToBOUserRPCClientImpl,
} from "../rpcs/RPC";

export enum SendPasswordResetLinkDialogState {
  SendPasswordResetLink = "SendPasswordResetLink",
  Error = "Error",
  Success = "Success",
}

export enum ArchiveBOUserDialogState {
  ArchiveBOUser = "ArchiveBOUser",
  Error = "Error",
}

export enum SendPasswordResetLinkToBOUserRPCError {
  InvalidBOUserId = "INVALID_BO_USER_ID",
  SendEmailFailed = "SEND_EMAIL_FAILED",
  EmailAlreadySent = "EMAIL_ALREADY_SENT",
  ResetPasswordLimitReached = "RESET_PASSWORD_LIMIT_REACHED",
}

export enum ArchiveBOUserRPCError {
  InvalidBOUserId = "INVALID_BO_USER_ID",
  UnableToArchiveBOUser = "UNABLE_TO_ARCHIVE_BO_USER",
  CannotArchiveSelf = "CANNOT_ARCHIVE_SELF",
}

export const SendPasswordResetLinkArchiveBOUserStore = types
  .model("SendPasswordResetLinkStore", {
    sendPasswordResetLinkDialogState: types.optional(
      types.enumeration(
        "SendPasswordResetLinkDialogState",
        Object.values(SendPasswordResetLinkDialogState),
      ),
      SendPasswordResetLinkDialogState.SendPasswordResetLink,
    ),
    archiveBOUserDialogState: types.optional(
      types.enumeration(
        "ArchiveBOUserDialogState",
        Object.values(ArchiveBOUserDialogState),
      ),
      ArchiveBOUserDialogState.ArchiveBOUser,
    ),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          "SendPasswordResetLinkToBOUserRPCError",
          Object.values(SendPasswordResetLinkToBOUserRPCError),
        ),
        types.enumeration(
          "ArchiveBOUserRPCError",
          Object.values(ArchiveBOUserRPCError),
        ),
      ),
    ),
    isRPCLoading: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get isInvalidBOUserIdError(): boolean {
      return (
        store.rpcError === SendPasswordResetLinkToBOUserRPCError.InvalidBOUserId
      );
    },
    get doesStoreContainError(): boolean {
      return store.rpcError !== null;
    },
  }))
  .actions((store) => ({
    resetStoreData: (): void => {
      store.rpcError = null;
      store.isRPCLoading = false;
      store.sendPasswordResetLinkDialogState =
        SendPasswordResetLinkDialogState.SendPasswordResetLink;
      store.archiveBOUserDialogState = ArchiveBOUserDialogState.ArchiveBOUser;
    },
    sendPasswordResetLink: flow(function* (boUserId: string) {
      try {
        store.rpcError = null;
        store.isRPCLoading = true;
        const apiClient = getAPIClient(store);
        const request = new SendPasswordResetLinkToBOUserRPC.Request(
          new LeoUUID(boUserId),
        );
        const {
          response,
          error,
        }: {
          response?: SendPasswordResetLinkToBOUserRPC.Response;
          error?: SendPasswordResetLinkToBOUserRPC.Errors.Errors;
        } =
          yield useSendPasswordResetLinkToBOUserRPCClientImpl(
            apiClient,
          ).execute(request);
        if (response) {
          store.sendPasswordResetLinkDialogState =
            SendPasswordResetLinkDialogState.Success;
        } else if (error) {
          store.sendPasswordResetLinkDialogState =
            SendPasswordResetLinkDialogState.Error;
          switch (error.code) {
            case SendPasswordResetLinkToBOUserRPCError.InvalidBOUserId:
              store.rpcError =
                SendPasswordResetLinkToBOUserRPCError.InvalidBOUserId;
              break;
            case SendPasswordResetLinkToBOUserRPCError.SendEmailFailed:
              store.rpcError =
                SendPasswordResetLinkToBOUserRPCError.SendEmailFailed;
              break;
            case SendPasswordResetLinkToBOUserRPCError.EmailAlreadySent: {
              store.rpcError =
                SendPasswordResetLinkToBOUserRPCError.EmailAlreadySent;
              break;
            }
            case SendPasswordResetLinkToBOUserRPCError.ResetPasswordLimitReached: {
              store.rpcError =
                SendPasswordResetLinkToBOUserRPCError.ResetPasswordLimitReached;
              break;
            }
            default:
              console.error(`Unhandled RPCError ${error.code}`);
              break;
          }
        }
      } catch (e) {
        store.sendPasswordResetLinkDialogState =
          SendPasswordResetLinkDialogState.Error;
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
      store.isRPCLoading = false;
    }),
    archiveBOUser: flow(function* (boUserId: string) {
      try {
        store.rpcError = null;
        store.isRPCLoading = true;
        const apiClient = getAPIClient(store);
        const request = new ArchiveBOUserRPC.Request(new LeoUUID(boUserId));
        const {
          response,
          error,
        }: {
          response?: ArchiveBOUserRPC.Response;
          error?: ArchiveBOUserRPC.Errors.Errors;
        } = yield useArchiveBOUserRPCClientImpl(apiClient).execute(request);
        if (response) {
          // We do not need to perform any action in case of success.
        } else if (error) {
          store.archiveBOUserDialogState = ArchiveBOUserDialogState.Error;
          switch (error.code) {
            case ArchiveBOUserRPCError.InvalidBOUserId:
              store.rpcError = ArchiveBOUserRPCError.InvalidBOUserId;
              break;
            case ArchiveBOUserRPCError.UnableToArchiveBOUser:
              store.rpcError = ArchiveBOUserRPCError.UnableToArchiveBOUser;
              break;
            case ArchiveBOUserRPCError.CannotArchiveSelf:
              store.rpcError = ArchiveBOUserRPCError.CannotArchiveSelf;
              break;
            default:
              console.error(`Unhandled RPCError ${error.code}`);
              break;
          }
        }
      } catch (e) {
        store.archiveBOUserDialogState = ArchiveBOUserDialogState.Error;
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
      store.isRPCLoading = false;
    }),
  }));

export const createSendPasswordResetLinkArchiveBOUserStore = (): Instance<
  typeof SendPasswordResetLinkArchiveBOUserStore
> => {
  return SendPasswordResetLinkArchiveBOUserStore.create();
};
