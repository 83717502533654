import React from "react";
import { SurveyDetailsModel } from "../../../surveys/models/SurveyDetailsModel";
import { Instance } from "mobx-state-tree";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { BorderStyle, PRIMARY_COLORS } from "@pulse/shared-components";
import { TFunction } from "i18next";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { Stack, Typography } from "@mui/material";
import { DividerComponent } from "../../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";
import {
  Button,
  Chip,
  EllipsisTypography,
  IconButton,
} from "@surya-digital/leo-reactjs-material-ui";
import { getSurveyStatusChipProps } from "../../../utils/getSurveyStatusChipProps";
import { Copy } from "lucide-react";
import { LinkInfo } from "./LinkInfo";
import { SurveyListElementChart } from "./SurveyListElementChart";

export interface SurveyDetailListElementProps {
  surveyDetails: Instance<typeof SurveyDetailsModel>;
  spacing: Spacing;
  border: BorderStyle;
  borderRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  t: TFunction;
  navigateTo: NavigateToFunctions;
  projectId: string;
  setSelectedSurveyId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedSurveyName: React.Dispatch<React.SetStateAction<string | null>>;
  setIsCloneSurveyDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SurveyDetailListElement = ({
  surveyDetails,
  spacing,
  border,
  borderRadius,
  tokens,
  typography,
  t,
  navigateTo,
  projectId,
  setSelectedSurveyId,
  setSelectedSurveyName,
  setIsCloneSurveyDialogOpen,
}: SurveyDetailListElementProps): React.ReactElement => {
  return (
    <Stack
      borderRadius={borderRadius.radiusXS}
      border={border.default}
      width="fit-content"
      divider={<DividerComponent width="100%" orientation="horizontal" />}
    >
      <Stack
        gap={spacing.spaceXXS}
        padding={`${spacing.spaceMD} ${spacing.spaceXL}`}
      >
        <EllipsisTypography
          width="624px"
          textColor="label"
          maxLines={1}
          typography={{ ...typography.sh2 }}
        >
          {surveyDetails.surveyName}
        </EllipsisTypography>
        <Stack direction="row" gap={spacing.spaceXXS}>
          <Typography
            {...typography.b2}
            color={tokens.labelSubtle}
            width="56px"
          >
            {t("projects.surveyDetails.creatorText")}
          </Typography>
          <EllipsisTypography
            textColor="labelSubtle"
            maxLines={1}
            typography={{ ...typography.b2 }}
            width="564px"
          >
            {t("projects.surveyDetails.creatorName", {
              creatorName: surveyDetails.creatorName,
            })}
          </EllipsisTypography>
        </Stack>
      </Stack>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          padding={`${spacing.spaceSM} ${spacing.spaceXL}`}
          bgcolor={tokens.backgroundSubtle}
        >
          <LinkInfo
            title={t("projects.surveyDetails.totalLinksText")}
            linkNumber={surveyDetails.totalLinks}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
          />
          <LinkInfo
            title={t("surveyLinkStatus.submitted")}
            linkNumber={surveyDetails.submittedLinks}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
          />
          <LinkInfo
            title={t("surveyLinkStatus.inProgress")}
            linkNumber={surveyDetails.inProgressLinks}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
          />
          <LinkInfo
            title={t("surveyLinkStatus.notStarted")}
            linkNumber={surveyDetails.notStartedLinks}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          padding={`${spacing.spaceLG} ${spacing.space3XL}`}
        >
          <Chip {...getSurveyStatusChipProps(surveyDetails.surveyStatus, t)} />
          {!surveyDetails.isNoSubmissionDataPresent && (
            <Stack direction="row" gap={spacing.spaceXXS} alignItems="center">
              <Stack
                height="8px"
                width="8px"
                borderRadius={borderRadius.radiusMax}
                bgcolor={PRIMARY_COLORS[400]}
              />
              <Typography {...typography.b2} color={tokens.labelSubtle}>
                {t("surveyLinkStatus.submitted")}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          height="330px"
          padding={`${spacing.spaceLG} ${spacing.space3XL}`}
          alignItems="center"
          gap={spacing.spaceXS}
          marginBottom={spacing.space3XL}
        >
          <SurveyListElementChart
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            t={t}
            surveyDetails={surveyDetails}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        padding={`${spacing.spaceMD} ${spacing.spaceXL}`}
      >
        <IconButton
          name="clone"
          variant="outlined-neutral"
          size="medium"
          icon={<Copy />}
          onClick={() => {
            setSelectedSurveyId(surveyDetails.surveyId);
            setSelectedSurveyName(surveyDetails.surveyName);
            setIsCloneSurveyDialogOpen(true);
          }}
        />
        <Button
          onClick={() => {
            navigateTo.surveyDetails(projectId, surveyDetails.surveyId);
          }}
          name="filter"
          label={t("projects.surveyDetails.viewSurveyTitle")}
          variant="outlined-neutral"
          size="medium"
        />
      </Stack>
    </Stack>
  );
};
