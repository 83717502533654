import { Popover, Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  MAX_GROUP_CHILD_QUESTIONS,
} from "@pulse/shared-components";
import {
  FoundationColorTokens,
  Shadow,
  Spacing,
  Typography as LeoTypography,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import React, { PropsWithChildren, useState } from "react";
import { observer } from "mobx-react";
import { ManageQuestionsStore } from "../../store/ManageQuestionsStore";
import { TFunction } from "i18next";

interface ChildQuestionPopoverProps extends PropsWithChildren {
  t: TFunction;
  anchorElement: HTMLButtonElement | null;
  setAnchorElement: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  manageQuestionsStore: Instance<typeof ManageQuestionsStore>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  shadow: Shadow;
  typography: LeoTypography;
  parentQuestionId: string;
  selectedChildQuestionId: string | undefined;
  setSelectedChildQuestionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const ChildQuestionPopover = observer(
  ({
    t,
    anchorElement,
    setAnchorElement,
    manageQuestionsStore,
    spacing,
    border,
    cornerRadius,
    tokens,
    shadow,
    typography,
    parentQuestionId,
    selectedChildQuestionId,
    setSelectedChildQuestionId,
  }: ChildQuestionPopoverProps): React.ReactElement => {
    const [isGroupQuestionPresent, setIsGroupQuestionPresent] = useState(false);
    const GroupQuestions = observer((): React.ReactElement => {
      return (
        <Stack>
          {manageQuestionsStore.manageQuestionsList.map((question, index) => {
            if (
              question.surveyQuestionDetails.isGroupQuestion &&
              question.surveyQuestionDetails.childQuestionIds.length <
                MAX_GROUP_CHILD_QUESTIONS &&
              !question.surveyQuestionDetails.isProvidedQuestionIdSameAsCurrentQuestionId(
                parentQuestionId,
              )
            ) {
              setIsGroupQuestionPresent(true);
              return (
                <Stack
                  key={index}
                  padding={`${spacing.spaceSM} ${spacing.spaceMD}`}
                  gap={spacing.spaceXS}
                  onClick={(): void => {
                    if (selectedChildQuestionId) {
                      manageQuestionsStore.moveChildQuestionIntoGroupQuestion(
                        parentQuestionId,
                        question.surveyQuestionDetails.questionId,
                        selectedChildQuestionId,
                      );
                      setSelectedChildQuestionId(undefined);
                      setAnchorElement(null);
                    }
                  }}
                  sx={{
                    ":hover": {
                      backgroundColor: tokens.backgroundHovered,
                    },
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <Typography {...typography.b1} color={tokens.label}>
                    {question.surveyQuestionDetails.questionCode}
                  </Typography>
                </Stack>
              );
            }
          })}
          <Stack
            padding={`${spacing.spaceSM} ${spacing.spaceMD}`}
            gap={spacing.spaceXS}
            onClick={(): void => {
              if (selectedChildQuestionId) {
                manageQuestionsStore.moveChildQuestionOutOfGroupQuestion(
                  parentQuestionId,
                  selectedChildQuestionId,
                );
                setSelectedChildQuestionId(undefined);
                setAnchorElement(null);
              }
            }}
            borderTop={isGroupQuestionPresent ? border.default : undefined}
            sx={{
              ":hover": {
                backgroundColor: tokens.backgroundHovered,
              },
              cursor: "pointer",
              width: "100%",
            }}
          >
            <Typography {...typography.b1} color={tokens.label}>
              {t("surveys.manageQuestions.moveOutOfGroup")}
            </Typography>
          </Stack>
        </Stack>
      );
    });

    return (
      <Popover
        open={anchorElement !== null}
        anchorEl={anchorElement}
        onClose={(): void => {
          setAnchorElement(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        sx={{
          "& .MuiPopover-paper": {
            width: "360px",
            boxShadow: shadow.level3,
            border: border.subtle,
            borderRadius: cornerRadius.radiusXS,
            padding: `${spacing.spaceXS} 0`,
          },
        }}
      >
        <GroupQuestions />
      </Popover>
    );
  },
);
