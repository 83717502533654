import React from "react";
import { Stack } from "@mui/material";
import { observer } from "mobx-react";
import {
  FoundationColorTokens,
  RadioButtonColorTokens,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { SingleChoiceQuestionV1Store } from "../store/SingleChoiceQuestionV1Store";
import { Instance } from "mobx-state-tree";
import { TFunction } from "i18next";
import { QuestionDetailsStore } from "../store/QuestionDetailsStore";
import { ChoiceListButtons } from "./ChoiceListButtons";
import { SingleSelectOptionCodeInput } from "./singleSelectOptionComponents/SingleSelectOptionCodeInput";
import { SingleSelectOptionTextInput } from "./singleSelectOptionComponents/SingleSelectOptionTextInput";
import { SingleSelectOptionRadioButton } from "./singleSelectOptionComponents/SingleSelectOptionRadioButton";

interface SingleSelectChoiceOptionsProps {
  index: number;
  tokens: FoundationColorTokens<string>;
  singleChoiceStore: Instance<typeof SingleChoiceQuestionV1Store>;
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
  t: TFunction;
  radioButtonTokens: RadioButtonColorTokens<string>;
  spacing: Spacing;
}

export const SingleSelectChoiceOptions = observer(
  ({
    index,
    tokens,
    singleChoiceStore,
    questionDetailsStore,
    t,
    radioButtonTokens,
    spacing,
  }: SingleSelectChoiceOptionsProps): React.ReactElement => {
    return (
      <Stack direction="row" gap={spacing.spaceSM} alignItems="center">
        <SingleSelectOptionRadioButton
          value={index}
          checked={singleChoiceStore.singleSelectQuestionV1Model.isDefaultOptionIndex(
            index,
          )}
          onChange={(e): void => {
            singleChoiceStore.setDefaultOptionIndex(Number(e.target.value));
          }}
          onClick={(_e): void => {
            singleChoiceStore.setDefaultOptionIndex(undefined);
          }}
          tokens={tokens}
          radioButtonTokens={radioButtonTokens}
        />
        <SingleSelectOptionTextInput
          width="404px"
          value={
            singleChoiceStore.singleSelectQuestionV1Model.options[index].option
          }
          onTextChange={(inputString: string) => {
            questionDetailsStore.setFieldValidations(null);
            singleChoiceStore.setFieldOptionText(inputString, index);
          }}
          error={false}
          isDisabled={false}
          t={t}
        />

        <SingleSelectOptionCodeInput
          width="132px"
          value={
            singleChoiceStore.singleSelectQuestionV1Model.options[index].code
          }
          error={singleChoiceStore.doesOptionCodeContainErrors(index)}
          onTextChange={(inputString: string) => {
            questionDetailsStore.setDuplicateOptionCode(null);
            questionDetailsStore.setFieldValidations(null);
            singleChoiceStore.setCode(inputString, index);
          }}
          t={t}
          questionDetailsStore={questionDetailsStore}
          isDisabled={false}
        />

        <ChoiceListButtons
          paddingTop={undefined}
          border={undefined}
          spacing={spacing}
          isAddNewFieldButtonDisabled={
            singleChoiceStore.isAddNewFieldButtonDisabled
          }
          addNewFields={() =>
            singleChoiceStore.addNewField(
              {
                option: EMPTY_CHARACTER,
                code: EMPTY_CHARACTER,
              },
              index,
            )
          }
          index={index}
          isChevronUpDisabled={singleChoiceStore.isChevronUpDisabled}
          isChevronDownDisabled={singleChoiceStore.isChevronDownDisabled}
          moveFieldUp={singleChoiceStore.moveFieldUp}
          moveFieldDown={singleChoiceStore.moveFieldDown}
          removeField={singleChoiceStore.removeField}
          isDeleteButtonDisabled={singleChoiceStore.isDeleteButtonDisabled}
        />
      </Stack>
    );
  },
);
