import { APIClient } from "@surya-digital/tedwig";
import { Instance, flow, getRoot, types } from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import { ReopenSurveyLinksRPC, RespondentSelection } from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useReopenSurveyLinksRPCClientImpl } from "../rpcs/RPC";
import { RootStore } from "../../root/store/RootStore";
import { CommonErrors } from "@pulse/shared-components";

export enum ReOpenSurveyLinkRPCError {
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  DeleteColumnFilter = "DELETED_COLUMN_FILTER",
  InvalidRespondentIdFound = "INVALID_RESPONDENT_ID_FOUND",
  SurveyIsClosed = "SURVEY_IS_CLOSED",
}

export enum ReOpenSurveyLinkDialogState {
  Error = "Error",
  Success = "Success",
  ReOpenSurveyLink = "ReOpenSurveyLink",
}

export const ReOpenSurveyLinkStore = types
  .model("ReOpenSurveyLinkStore", {
    isRPCLoading: types.optional(types.boolean, false),
    rpcError: types.maybeNull(
      types.enumeration(
        "ReOpenSurveyLinkRPCError",
        Object.values(ReOpenSurveyLinkRPCError),
      ),
    ),
    reOpenSurveyLinkDialogState: types.optional(
      types.enumeration(
        "ReOpenSurveyLink",
        Object.values(ReOpenSurveyLinkDialogState),
      ),
      ReOpenSurveyLinkDialogState.ReOpenSurveyLink,
    ),
  })
  .views((store) => ({
    get shouldTableReloadOnDialogClose(): boolean {
      return (
        store.reOpenSurveyLinkDialogState ===
          ReOpenSurveyLinkDialogState.Success ||
        store.rpcError === ReOpenSurveyLinkRPCError.DeleteColumnFilter ||
        store.rpcError === ReOpenSurveyLinkRPCError.InvalidRespondentIdFound
      );
    },
  }))
  .actions((store) => ({
    resetStore: (): void => {
      store.isRPCLoading = false;
      store.reOpenSurveyLinkDialogState =
        ReOpenSurveyLinkDialogState.ReOpenSurveyLink;
      store.rpcError = null;
    },
    reOpenSurveyLink: flow(function* (
      projectId: string,
      surveyId: string,
      respondentSelection: RespondentSelection.RespondentSelection,
    ) {
      try {
        store.isRPCLoading = true;
        const apiClient: APIClient = getAPIClient(store);

        const request = new ReopenSurveyLinksRPC.Request(
          respondentSelection,
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
        );
        const {
          response,
          error,
        }: {
          response?: ReopenSurveyLinksRPC.Response;
          error?: ReopenSurveyLinksRPC.Errors.Errors;
        } = yield useReopenSurveyLinksRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.reOpenSurveyLinkDialogState =
            ReOpenSurveyLinkDialogState.Success;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId: {
              break;
            }
            case ReOpenSurveyLinkRPCError.DeleteColumnFilter: {
              store.rpcError = ReOpenSurveyLinkRPCError.DeleteColumnFilter;
              break;
            }
            case ReOpenSurveyLinkRPCError.InvalidRespondentIdFound: {
              store.rpcError =
                ReOpenSurveyLinkRPCError.InvalidRespondentIdFound;
              break;
            }
            case ReOpenSurveyLinkRPCError.ProjectAlreadyArchived: {
              store.rpcError = ReOpenSurveyLinkRPCError.ProjectAlreadyArchived;
              break;
            }
            case ReOpenSurveyLinkRPCError.SurveyIsClosed: {
              store.rpcError = ReOpenSurveyLinkRPCError.SurveyIsClosed;
              break;
            }
            default: {
              console.error(`Unhandled error ${error.code}`);
              break;
            }
          }
          store.reOpenSurveyLinkDialogState = ReOpenSurveyLinkDialogState.Error;
        }
      } catch (e) {
        store.reOpenSurveyLinkDialogState =
          ReOpenSurveyLinkDialogState.ReOpenSurveyLink;
        store.rpcError = null;
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }));

export const createReOpenSurveyLinkStore = (): Instance<
  typeof ReOpenSurveyLinkStore
> => {
  return ReOpenSurveyLinkStore.create();
};
