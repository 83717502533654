import { Stack, Typography } from "@mui/material";
import React from "react";
import { observer } from "mobx-react";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { ValidateRespondentDialogButtonProps } from "./ValidateRespondentColumnDialog";
import { AutoCompleteInputField } from "@surya-digital/leo-reactjs-material-ui";

export const UniqueColumnSelectionInputField = observer(
  ({
    respondentUploadStore,
    spacing,
    t,
    tokens,
    typography,
  }: ValidateRespondentDialogButtonProps): React.ReactElement => {
    return (
      <Stack spacing={spacing.spaceMD}>
        <Stack spacing={spacing.spaceXXS}>
          <Typography sx={{ ...typography.s1, color: tokens.label }}>
            {t("projects.respondentList.chooseUniqueColumnTitle")}
          </Typography>
          <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
            {t("projects.respondentList.chooseUniqueColumnDescription")}
          </Typography>
        </Stack>
        <AutoCompleteInputField
          id="selectUniqueColumn"
          label={EMPTY_CHARACTER}
          placeholder={t("projects.respondentList.selectUniqueColumn")}
          options={respondentUploadStore?.uniqueColumnList.map(
            (column, index) => {
              return {
                label: column,
                id: index.toString(),
              };
            },
          )}
          onChange={(column): void => {
            respondentUploadStore?.setSelectedUniqueColumn(
              column ? column.label : EMPTY_CHARACTER,
            );
          }}
        />
      </Stack>
    );
  },
);
