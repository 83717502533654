import React from "react";
import { FileUploadComponent } from "../../../components/fileUpload/FileUploadComponent";
import { Stack, Typography } from "@mui/material";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../routes/RoutesHelper";
import { Instance } from "mobx-state-tree";
import {
  GetPresignedDocumentUploadURLForRespondentsErrors,
  RespondentUploadStore,
} from "../store/RespondentUploadStore";
import {
  EMPTY_CHARACTER,
  ICON_SIZE,
  MAX_RESPONDENTS_FILE_SIZE_IN_BYTES,
  MIN_FILE_SIZE_IN_BYTES,
} from "@pulse/shared-components";
import { getUploadErrorDescriptions } from "../../utils/FileUploadUtils";
import { TFunction } from "i18next";
import {
  AlertCircle,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { FileUploadedDescription } from "../../../components/fileUpload/FileUploadedDescription";
import { UploadValidationLoader } from "../../../components/fileUpload/UploadValidationLoader";
import { getIconProps } from "@pulse/shared-components";

export interface RespondentUploadPaneProps {
  respondentUploadStore: Instance<typeof RespondentUploadStore> | null;
  navigateTo: NavigateToFunctions;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

export const RespondentUploadPane = ({
  respondentUploadStore,
  navigateTo,
  t,
  spacing,
  typography,
  tokens,
}: RespondentUploadPaneProps): React.ReactElement => {
  const iconProps = getIconProps(tokens.iconError, ICON_SIZE.default);

  const getUploadErrors = (): React.ReactElement => {
    let errorDescription: string = EMPTY_CHARACTER;
    if (respondentUploadStore?.validationErrors) {
      errorDescription = getUploadErrorDescriptions(
        respondentUploadStore?.validationErrors,
        t,
        MAX_RESPONDENTS_FILE_SIZE_IN_BYTES,
        MIN_FILE_SIZE_IN_BYTES,
      );
    } else if (respondentUploadStore?.rpcErrors) {
      switch (respondentUploadStore?.rpcErrors) {
        case GetPresignedDocumentUploadURLForRespondentsErrors.RespondentUpdateInProgress: {
          errorDescription = t(
            "projects.respondentList.respondentUpdateProgressDescription",
          );
          break;
        }
        default: {
          errorDescription = t(
            "common.uploadDialog.unexpectedErrorDescription",
          );
        }
      }
    }

    return (
      <Stack gap={spacing.spaceXXS}>
        <Typography {...typography.s2} color={tokens.label}>
          {t("projects.respondentList.fileDetails")}
        </Typography>
        <Typography {...typography.b2} color={tokens.label}>
          {respondentUploadStore?.fileName}
        </Typography>
        <Stack direction="row" gap={spacing.spaceXXS}>
          <AlertCircle {...iconProps} />
          <Typography {...typography.b2} color={tokens.labelError}>
            {errorDescription}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const fileUploadHandler = (file: File): Promise<void> => {
    respondentUploadStore?.setFileName(file.name);
    return processProjectParams(async (projectId) => {
      await respondentUploadStore?.uploadCSVFile(file, projectId);
    }, navigateTo);
  };
  if (respondentUploadStore?.isRPCLoading) {
    return (
      <UploadValidationLoader
        fileName={respondentUploadStore?.fileName}
        t={t}
        typography={typography}
        tokens={tokens}
        spacing={spacing}
      />
    );
  } else if (
    respondentUploadStore?.validationErrors ??
    respondentUploadStore?.rpcErrors
  ) {
    if (respondentUploadStore?.isFileInvalid) {
      return (
        <Stack gap={spacing.spaceMD}>
          <FileUploadComponent fileUploadHandler={fileUploadHandler} />
          <Stack gap={spacing.spaceXXS}>
            <Typography {...typography.s2} color={tokens.label}>
              {t("common.uploadDialog.errorsFoundText")}
            </Typography>
            <Typography
              {...typography.b2}
              color={tokens.labelError}
              whiteSpace="pre-line"
            >
              {respondentUploadStore.invalidFileDetails}
            </Typography>
          </Stack>
        </Stack>
      );
    }
    return (
      <Stack gap={spacing.spaceMD}>
        <FileUploadComponent fileUploadHandler={fileUploadHandler} />
        {getUploadErrors()}
      </Stack>
    );
  } else {
    if (respondentUploadStore?.hasFileUploaded) {
      return (
        <FileUploadedDescription
          fileName={respondentUploadStore?.fileName}
          t={t}
          typography={typography}
          tokens={tokens}
          spacing={spacing}
        />
      );
    } else {
      return <FileUploadComponent fileUploadHandler={fileUploadHandler} />;
    }
  }
};
