import {
  Instance,
  cast,
  clone,
  flow,
  getParentOfType,
  getRoot,
  types,
} from "mobx-state-tree";
import {
  BackOfficeSurveySettingsModel,
  createBackOfficeSurveySettingsModel,
} from "../models/BackOfficeSurveySettingsModel";
import {
  CommonErrors,
  NetworkingError,
  projectAndSurveyNameRegex,
} from "@pulse/shared-components";
import {
  BackOfficeSurveySettings,
  CloseSurveyRPC,
  GetSurveySettingsRPC,
  MiscellaneousSettingsText,
  ReOpenSurveyRPC,
  ResetClientLogoRPC,
  SurveyLanguage,
  SurveyLanguageCode,
  SurveyLocalizedText,
  SurveyName,
  SurveySettingDialogProperties,
  SurveySettings,
  SurveyStatus,
  ThemedImage,
  UpdateSurveySettingsRPC,
  UpdateSurveySettingsRPCClientImpl,
} from "@pulse/pulse-rpcs";
import { RootStore } from "../../root/store/RootStore";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "../../networking/APIClient";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useCloseSurveyClientImpl,
  useGetSurveySettingsClientImpl,
  useReOpenSurveyClientImpl,
  useResetClientLogoRPCClientImpl,
  useUpdateSurveySettingsClientImpl,
} from "../rpcs/RPC";
import { SurveyStore } from "./SurveyStore";
import {
  ThemeWithHexCodesModel,
  createThemeWithHexCodesModel,
} from "../models/ThemeWithHexCodesModel";
import {
  UploadClientLogoStore,
  createUploadClientLogoStore,
} from "./UploadClientLogoStore";

export enum CloseSurveyRPCError {
  SurveyIsNotActive = "SURVEY_IS_NOT_ACTIVE",
}

export enum ReOpenSurveyRPCError {
  SurveyIsNotClosed = "SURVEY_IS_NOT_CLOSED",
}

export enum CommonProjectAlreadyArchivedRPCError {
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
}

export enum UpdateSurveySettingsError {
  SurveyIsClosed = "SURVEY_IS_CLOSED",
  SurveyNameNotUnique = "SURVEY_NAME_NOT_UNIQUE",
  InvalidSurveyName = "INVALID_SURVEY_NAME",
  InvalidSurveyNameLength = "INVALID_SURVEY_NAME_LENGTH",
}

export const BackOfficeSurveySettingsStore = types
  .model("BackOfficeSurveySettingsStore", {
    initialBackOfficeSurveySettings: types.maybeNull(
      BackOfficeSurveySettingsModel,
    ),
    updatedBackOfficeSurveySettings: types.maybeNull(
      BackOfficeSurveySettingsModel,
    ),
    supportedThemes: types.array(ThemeWithHexCodesModel),
    supportedFonts: types.array(types.string),
    isCloseReOpenSurveyRPCLoading: types.optional(types.boolean, false),
    isGetSurveySettingsRPCLoading: types.optional(types.boolean, false),
    isResetClientLogoLoading: types.optional(types.boolean, false),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          "CloseSurveyRPCError",
          Object.values(CloseSurveyRPCError),
        ),
        types.enumeration(
          "ReOpenSurveyRPCError",
          Object.values(ReOpenSurveyRPCError),
        ),
        types.enumeration(
          "UpdateSurveySettingsError",
          Object.values(UpdateSurveySettingsError),
        ),
        types.enumeration(
          "CommonRPCError",
          Object.values(CommonProjectAlreadyArchivedRPCError),
        ),
        types.enumeration("NetworkingError", Object.values(NetworkingError)),
      ),
    ),
    isUpdateSurveySettingsRPCLoading: types.optional(types.boolean, false),
    isUpdateSurveySettingsErrorDialogVisible: types.optional(
      types.boolean,
      false,
    ),
    // As mentioned here: https://mobx-state-tree.js.org/intro/getting-started#how-to-define-the-reference,
    // Sometimes initializing can lead to circular references that may use a model before it's declared.
    // To overcome this we can use types.late as shown below.
    uploadClientLogoStore: types.late(() => UploadClientLogoStore),
  })
  .views((store) => ({
    get isSaveChangesSettingsButtonDisabled(): boolean {
      return (
        store.isGetSurveySettingsRPCLoading ||
        JSON.stringify(store.initialBackOfficeSurveySettings) ===
          JSON.stringify(store.updatedBackOfficeSurveySettings) ||
        store.uploadClientLogoStore.fileUploadError !== null
      );
    },
    get doesUpdateSurveyNameTextFieldContainError(): boolean {
      return (
        ((store.rpcError === UpdateSurveySettingsError.InvalidSurveyName ||
          store.rpcError === UpdateSurveySettingsError.SurveyNameNotUnique ||
          store.rpcError ===
            UpdateSurveySettingsError.InvalidSurveyNameLength) &&
          store.updatedBackOfficeSurveySettings?.backOfficeSurveySettingsUIStore
            .isSaveButtonClicked) ??
        false
      );
    },
  }))
  .actions((store) => ({
    clearRPCError: (): void => {
      store.rpcError = null;
    },
    setRPCError: (rpcError: CommonProjectAlreadyArchivedRPCError): void => {
      store.rpcError = rpcError;
    },
    validateSurveyName: (): boolean => {
      if (store.updatedBackOfficeSurveySettings === null) {
        return false;
      }
      try {
        new SurveyName(store.updatedBackOfficeSurveySettings.surveyName.trim());
        const regex = new RegExp(projectAndSurveyNameRegex);
        if (!regex.test(store.updatedBackOfficeSurveySettings.surveyName)) {
          store.updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.setIsErrorSnackbarVisible(
            true,
          );
          store.rpcError = UpdateSurveySettingsError.InvalidSurveyName;
          return false;
        }
        return true;
      } catch (e) {
        store.updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.setIsErrorSnackbarVisible(
          true,
        );
        store.rpcError = UpdateSurveySettingsError.InvalidSurveyNameLength;
        return false;
      }
    },
    setIsUpdateSurveySettingsErrorDialogVisible: (
      isUpdateSurveySettingsErrorDialogVisible: boolean,
    ): void => {
      store.isUpdateSurveySettingsErrorDialogVisible =
        isUpdateSurveySettingsErrorDialogVisible;
    },
    deserializeBackOfficeSurveySettings: (
      backOfficeSurveySettings: BackOfficeSurveySettings,
    ): void => {
      getParentOfType(store, SurveyStore).setSurveyName(
        backOfficeSurveySettings.surveyName.name,
      );
      store.initialBackOfficeSurveySettings =
        createBackOfficeSurveySettingsModel(backOfficeSurveySettings);
      store.updatedBackOfficeSurveySettings = clone(
        store.initialBackOfficeSurveySettings,
      );
    },
  }))
  .actions((store) => ({
    getSurveySettings: flow(function* (surveyId: string, projectId: string) {
      try {
        store.clearRPCError();
        store.isGetSurveySettingsRPCLoading = true;
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetSurveySettingsRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: GetSurveySettingsRPC.Response;
          error?: GetSurveySettingsRPC.Errors.Errors;
        } = yield useGetSurveySettingsClientImpl(apiClient).execute(request);
        if (response) {
          store.supportedThemes = cast(
            response.supportedThemes.map((theme) =>
              createThemeWithHexCodesModel(theme),
            ),
          );
          store.supportedFonts = cast(response.supportedFonts);
          store.deserializeBackOfficeSurveySettings(
            response.backOfficeSurveySettings,
          );
          const surveyStore = getParentOfType(store, SurveyStore);
          surveyStore.setSurveyStatus(response.surveyStatus);
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              // These errors are caught and handled in defaultResponseInterceptors.
              break;
            default:
              console.error(
                `Unhandled error ${error} in getSurveySettings action in SurveySettingsStore.`,
              );
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in getSurveySettings action in SurveySettingsStore`,
          );
        }
      } finally {
        store.isGetSurveySettingsRPCLoading = false;
      }
    }),
    updateSurveySettings: flow(function* (surveyId: string, projectId: string) {
      if (store.updatedBackOfficeSurveySettings === null) {
        console.error("Updated BackOfficeSurveySettings found to be null.");
        return;
      }
      try {
        store.isUpdateSurveySettingsRPCLoading = true;
        const apiClient = getAPIClient(store);
        const surveySettingsStore =
          store.updatedBackOfficeSurveySettings.surveySettings;
        const updatedBackOfficeSurveySettings =
          store.updatedBackOfficeSurveySettings;
        const clientLogo = surveySettingsStore.themedImage
          ? new ThemedImage(
              new URL(surveySettingsStore.themedImage.lightImageURL),
              surveySettingsStore.themedImage.darkImageURL
                ? new URL(surveySettingsStore.themedImage.darkImageURL)
                : undefined,
            )
          : null;
        const languageList = surveySettingsStore.languageList.map(
          (language) =>
            new SurveyLanguage(
              new LeoUUID(language.languageId),
              new SurveyLanguageCode(language.languageCode),
            ),
        );
        const submitConfirmationDialogPropertiesStore =
          updatedBackOfficeSurveySettings.submitConfirmationDialogProperties;
        const restartSurveyDialogPropertiesStore =
          updatedBackOfficeSurveySettings.restartSurveyDialogProperties;
        const saveDraftDialogPropertiesStore =
          updatedBackOfficeSurveySettings.saveDraftDialogProperties;
        const surveyAlreadySubmittedDialogProperties =
          updatedBackOfficeSurveySettings.surveySettings
            .surveyAlreadySubmittedDialogTexts;
        const languageSelectionDialogProperties =
          updatedBackOfficeSurveySettings.surveySettings
            .languageSelectionDialogTexts;

        const languageSelectionDialogSecondaryActionText =
          languageSelectionDialogProperties.secondaryActionText;
        if (languageSelectionDialogSecondaryActionText === undefined) {
          console.error(
            "languageSelectionDialogSecondaryActionText cannot be undefined in this flow",
          );
          return;
        }
        const surveySettings = new SurveySettings(
          clientLogo,
          languageList,
          new SurveyLanguageCode(surveySettingsStore.selectedLanguageCode),
          surveySettingsStore.font,
          surveySettingsStore.fontSizeScale,
          surveySettingsStore.isLanguageSelectionOptionPersistent,
          surveySettingsStore.isLanguageSelectionDisplayedOnStart,
          surveySettingsStore.isAutoProgressEnabled,
          surveySettingsStore.isSingleSelectAnswerResetAllowed,
          surveySettingsStore.isProgressPercentageShown,
          surveySettingsStore.isIpTracked,
          surveySettingsStore.isRespondentConsentRequired,
          surveySettingsStore.isResponseResetAllowed,
          new SurveyLocalizedText(surveySettingsStore.startButtonText),
          new SurveyLocalizedText(
            surveySettingsStore.genericErrorMessageTitleText,
          ),
          new SurveyLocalizedText(
            surveySettingsStore.genericErrorMessageDescriptionText,
          ),
          new SurveyLocalizedText(surveySettingsStore.continueButtonText),
          new SurveyLocalizedText(surveySettingsStore.nextButtonText),
          new SurveyLocalizedText(surveySettingsStore.backButtonText),
          new SurveySettingDialogProperties(
            new SurveyLocalizedText(
              surveyAlreadySubmittedDialogProperties.titleText,
            ),
            new SurveyLocalizedText(
              surveyAlreadySubmittedDialogProperties.descriptionText,
            ),
            new SurveyLocalizedText(
              surveyAlreadySubmittedDialogProperties.primaryActionText,
            ),
          ),
          new SurveySettingDialogProperties(
            new SurveyLocalizedText(
              languageSelectionDialogProperties.titleText,
            ),
            new SurveyLocalizedText(
              languageSelectionDialogProperties.descriptionText,
            ),
            new SurveyLocalizedText(
              languageSelectionDialogProperties.primaryActionText,
            ),
            new SurveyLocalizedText(languageSelectionDialogSecondaryActionText),
          ),
          new MiscellaneousSettingsText(
            new SurveyLocalizedText(
              updatedBackOfficeSurveySettings.surveySettings.miscellaneousSurveySettingsText.singleSelectDropdownChooseOptionText,
            ),
            new SurveyLocalizedText(
              updatedBackOfficeSurveySettings.surveySettings.miscellaneousSurveySettingsText.singleSelectDropdownPleaseSelectOneText,
            ),
            new SurveyLocalizedText(
              updatedBackOfficeSurveySettings.surveySettings.miscellaneousSurveySettingsText.mandatoryQuestionErrorText,
            ),
            new SurveyLocalizedText(
              updatedBackOfficeSurveySettings.surveySettings.miscellaneousSurveySettingsText.allGridRowsNotAnsweredErrorText,
            ),
            new SurveyLocalizedText(
              updatedBackOfficeSurveySettings.surveySettings.miscellaneousSurveySettingsText.allAttemptedGridRowsNotAnsweredErrorText,
            ),
            new SurveyLocalizedText(
              updatedBackOfficeSurveySettings.surveySettings.miscellaneousSurveySettingsText.allRankingOptionsMandatory,
            ),
            new SurveyLocalizedText(
              updatedBackOfficeSurveySettings.surveySettings.miscellaneousSurveySettingsText.singleSelectSliderTooltipText,
            ),
            new SurveyLocalizedText(
              updatedBackOfficeSurveySettings.surveySettings.miscellaneousSurveySettingsText.multiSelectAndRankingMaxSelectionText,
            ),
            new SurveyLocalizedText(
              updatedBackOfficeSurveySettings.surveySettings.miscellaneousSurveySettingsText.areAllRankingOptionsMandatoryText,
            ),
            new SurveyLocalizedText(
              updatedBackOfficeSurveySettings.surveySettings.miscellaneousSurveySettingsText.questionRequiredText,
            ),
          ),
          new SurveyLocalizedText(
            updatedBackOfficeSurveySettings.surveySettings.resetAnswerButtonText,
          ),
          updatedBackOfficeSurveySettings.surveySettings.isRatingScaleOptionVisible,
          updatedBackOfficeSurveySettings.surveySettings.isFIThankYouPageLogoShown,
        );
        const submitConfirmationDialogProperties =
          new SurveySettingDialogProperties(
            new SurveyLocalizedText(
              submitConfirmationDialogPropertiesStore.titleText,
            ),
            new SurveyLocalizedText(
              submitConfirmationDialogPropertiesStore.descriptionText,
            ),
            new SurveyLocalizedText(
              submitConfirmationDialogPropertiesStore.primaryActionText,
            ),
            submitConfirmationDialogPropertiesStore.secondaryActionText
              ? new SurveyLocalizedText(
                  submitConfirmationDialogPropertiesStore.secondaryActionText,
                )
              : null,
            submitConfirmationDialogPropertiesStore.buttonText
              ? new SurveyLocalizedText(
                  submitConfirmationDialogPropertiesStore.buttonText,
                )
              : null,
          );
        const saveDraftDialogProperties = new SurveySettingDialogProperties(
          new SurveyLocalizedText(saveDraftDialogPropertiesStore.titleText),
          new SurveyLocalizedText(
            saveDraftDialogPropertiesStore.descriptionText,
          ),
          new SurveyLocalizedText(
            saveDraftDialogPropertiesStore.primaryActionText,
          ),
          undefined,
          saveDraftDialogPropertiesStore.buttonText
            ? new SurveyLocalizedText(saveDraftDialogPropertiesStore.buttonText)
            : null,
        );
        const restartSurveyDialogProperties = new SurveySettingDialogProperties(
          new SurveyLocalizedText(restartSurveyDialogPropertiesStore.titleText),
          new SurveyLocalizedText(
            restartSurveyDialogPropertiesStore.descriptionText,
          ),
          new SurveyLocalizedText(
            restartSurveyDialogPropertiesStore.primaryActionText,
          ),
          restartSurveyDialogPropertiesStore.secondaryActionText
            ? new SurveyLocalizedText(
                restartSurveyDialogPropertiesStore.secondaryActionText,
              )
            : null,
          restartSurveyDialogPropertiesStore.buttonText
            ? new SurveyLocalizedText(
                restartSurveyDialogPropertiesStore.buttonText,
              )
            : null,
        );
        const infoDialogProperties = new SurveySettingDialogProperties(
          new SurveyLocalizedText(
            updatedBackOfficeSurveySettings.surveyInformationDialogProperties.titleText,
          ),
          new SurveyLocalizedText(
            updatedBackOfficeSurveySettings.surveyInformationDialogProperties.descriptionText,
          ),
          new SurveyLocalizedText(
            updatedBackOfficeSurveySettings.surveyInformationDialogProperties.primaryActionText,
          ),
        );
        const backOfficeSurveySettings = new BackOfficeSurveySettings(
          surveySettings,
          new SurveyName(updatedBackOfficeSurveySettings.surveyName),
          updatedBackOfficeSurveySettings.selectedSurveyTheme,
          updatedBackOfficeSurveySettings.isSubmitConfirmationRequired,
          submitConfirmationDialogProperties,
          updatedBackOfficeSurveySettings.isRestartAllowed,
          restartSurveyDialogProperties,
          updatedBackOfficeSurveySettings.isSaveDraftAllowed,
          saveDraftDialogProperties,
          updatedBackOfficeSurveySettings.isInfoButtonShown,
          infoDialogProperties,
          updatedBackOfficeSurveySettings.isWelcomePageShown,
          new SurveyLocalizedText(
            updatedBackOfficeSurveySettings.welcomePageText,
          ),
          new SurveyLocalizedText(
            updatedBackOfficeSurveySettings.thankYouPageText,
          ),
          new SurveyLocalizedText(
            updatedBackOfficeSurveySettings.surveyClosedMessageTitleText,
          ),
          new SurveyLocalizedText(
            updatedBackOfficeSurveySettings.surveyClosedMessageDescriptionText,
          ),
          new LeoUUID(updatedBackOfficeSurveySettings.clientLightThemeLogoId),
          new LeoUUID(updatedBackOfficeSurveySettings.clientDarkThemeLogoId),
        );
        const request = new UpdateSurveySettingsRPCClientImpl.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
          backOfficeSurveySettings,
        );
        const {
          response,
          error,
        }: {
          response?: UpdateSurveySettingsRPC.Response;
          error?: UpdateSurveySettingsRPC.Errors.Errors;
        } = yield useUpdateSurveySettingsClientImpl(apiClient).execute(request);
        if (response) {
          store.deserializeBackOfficeSurveySettings(
            response.backOfficeSurveySettings,
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId: {
              break;
            }
            case CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived: {
              store.rpcError =
                CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived;
              store.isUpdateSurveySettingsErrorDialogVisible = true;
              break;
            }
            case UpdateSurveySettingsError.SurveyIsClosed: {
              store.rpcError = UpdateSurveySettingsError.SurveyIsClosed;
              store.isUpdateSurveySettingsErrorDialogVisible = true;
              break;
            }
            case UpdateSurveySettingsError.InvalidSurveyName: {
              store.updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.setIsErrorSnackbarVisible(
                true,
              );
              store.rpcError = UpdateSurveySettingsError.InvalidSurveyName;
              break;
            }
            case UpdateSurveySettingsError.SurveyNameNotUnique: {
              store.updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.setIsErrorSnackbarVisible(
                true,
              );
              store.rpcError = UpdateSurveySettingsError.SurveyNameNotUnique;
              break;
            }
            case UpdateSurveySettingsError.InvalidSurveyNameLength: {
              store.updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore.setIsErrorSnackbarVisible(
                true,
              );
              store.rpcError =
                UpdateSurveySettingsError.InvalidSurveyNameLength;
              break;
            }
            default: {
              console.error(
                `Unhandled error ${error} in updateSurveySettings action in SurveySettingsStore.`,
              );
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in updateSurveySettings action in SurveySettingsStore`,
          );
        }
      } finally {
        store.isUpdateSurveySettingsRPCLoading = false;
      }
    }),
    closeSurvey: flow(function* (surveyId: string, projectId: string) {
      try {
        store.clearRPCError();
        store.isCloseReOpenSurveyRPCLoading = true;
        const apiClient: APIClient = getAPIClient(store);
        const request = new CloseSurveyRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: CloseSurveyRPC.Response;
          error?: CloseSurveyRPC.Errors.Errors;
        } = yield useCloseSurveyClientImpl(apiClient).execute(request);
        if (response) {
          store.deserializeBackOfficeSurveySettings(
            response.backOfficeSurveySettings,
          );
          getParentOfType(store, SurveyStore).setSurveyStatus(
            SurveyStatus.SurveyStatus.CLOSED,
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              // These errors are caught and handled in defaultResponseinterceptors.
              break;
            case CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived:
              store.rpcError =
                CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived;
              break;
            case CloseSurveyRPCError.SurveyIsNotActive:
              store.rpcError = CloseSurveyRPCError.SurveyIsNotActive;
              break;
            default:
              console.error(
                `Unhandled error ${error} in closeSurvey action in SurveySettingsStore.`,
              );
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in closeSurvey action in SurveySettingsStore`,
          );
        }
      } finally {
        store.isCloseReOpenSurveyRPCLoading = false;
      }
    }),
    reOpenSurvey: flow(function* (surveyId: string, projectId: string) {
      try {
        store.clearRPCError();
        store.isCloseReOpenSurveyRPCLoading = true;
        const apiClient: APIClient = getAPIClient(store);
        const request = new ReOpenSurveyRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: ReOpenSurveyRPC.Response;
          error?: ReOpenSurveyRPC.Errors.Errors;
        } = yield useReOpenSurveyClientImpl(apiClient).execute(request);
        if (response) {
          store.deserializeBackOfficeSurveySettings(
            response.backOfficeSurveySettings,
          );
          getParentOfType(store, SurveyStore).setSurveyStatus(
            SurveyStatus.SurveyStatus.ACTIVE,
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              // These errors are caught and handled in defaultResponseinterceptors.
              break;
            case CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived:
              store.rpcError =
                CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived;
              break;
            case ReOpenSurveyRPCError.SurveyIsNotClosed:
              store.rpcError = ReOpenSurveyRPCError.SurveyIsNotClosed;
              break;
            default:
              console.error(
                `Unhandled error ${error} in reOpenSurvey action in SurveySettingsStore.`,
              );
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in reOpenSurvey action in SurveySettingsStore`,
          );
        }
      } finally {
        store.isCloseReOpenSurveyRPCLoading = false;
      }
    }),
    resetClientLogo: flow(function* (
      surveyId: string,
      projectId: string,
      isDarktheme: boolean,
    ) {
      try {
        store.clearRPCError();
        store.isResetClientLogoLoading = true;
        const apiClient: APIClient = getAPIClient(store);
        const logoTheme = isDarktheme
          ? ResetClientLogoRPC.RequestEnums.LogoType.LogoType.DARK_THEME
          : ResetClientLogoRPC.RequestEnums.LogoType.LogoType.LIGHT_THEME;
        const request = new ResetClientLogoRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
          logoTheme,
        );
        const {
          response,
          error,
        }: {
          response?: ResetClientLogoRPC.Response;
          error?: ResetClientLogoRPC.Errors.Errors;
        } = yield useResetClientLogoRPCClientImpl(apiClient).execute(request);
        if (response) {
          switch (logoTheme) {
            case ResetClientLogoRPC.RequestEnums.LogoType.LogoType
              .LIGHT_THEME: {
              store.updatedBackOfficeSurveySettings?.setLightThemeLogoId(
                response.imageDocumentId.uuid,
              );
              store.updatedBackOfficeSurveySettings?.surveySettings.themedImage?.setLightImageURL(
                response.imageURL.href,
              );
              break;
            }
            case ResetClientLogoRPC.RequestEnums.LogoType.LogoType.DARK_THEME: {
              store.updatedBackOfficeSurveySettings?.setDarkThemeLogoId(
                response.imageDocumentId.uuid,
              );
              store.updatedBackOfficeSurveySettings?.surveySettings.themedImage?.setDarkImageURL(
                response.imageURL.href,
              );
              break;
            }
            default: {
              console.error(`Unhandled response logo type &{logoTheme} found`);
            }
          }
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              // These errors are caught and handled in defaultResponseinterceptors.
              break;
            case CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived:
              store.rpcError =
                CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived;
              store.isUpdateSurveySettingsErrorDialogVisible = true;
              break;
            default:
              console.error(
                `Unhandled error ${error} in reOpenSurvey action in SurveySettingsStore.`,
              );
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in reOpenSurvey action in SurveySettingsStore`,
          );
        }
      } finally {
        store.isResetClientLogoLoading = false;
      }
    }),
  }));

export const createBackOfficeSurveySettingsStore = (): Instance<
  typeof BackOfficeSurveySettingsStore
> => {
  return BackOfficeSurveySettingsStore.create({
    uploadClientLogoStore: createUploadClientLogoStore(),
  });
};
