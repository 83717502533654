import { Typography } from "@mui/material";
import { SINGLE_SPACING } from "@pulse/shared-components";
import { TypographyProperties } from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";

interface NestedTypographyComponentProps {
  textColor: string;
  startText: string | undefined;
  middleBoldText: string | undefined;
  endText: string | undefined;
  startAndEndTextTypography: TypographyProperties;
  boldTextTypography: TypographyProperties;
  isSpan: boolean;
}

/**
 * Creates a NestedTypography component.
 * @param textColor - Color of the text being shown.
 * @param startText - Starting text which has `startAndEndTextTypography`.
 * @param middleBoldText - Middle text which has bold typography.
 * @param endText - Ending Text which has `startAndEndTextTypography`.
 * @param startAndEndTextTypography - The typography of the starting and ending texts.
 * @param boldTextTypography - The typography of the middle text.
 * @param isSpan - Toggles the parent typography component in using p tag or span
 * @returns A NestedTypography component
 *
 * This figma link provides an illustration of the design:
 * https://www.figma.com/file/USl8dEzHVxs10KzHoEx56w/Pulse-Revamp?type=design&node-id=7372-83638&mode=design&t=QaRFZQIirUOojoZ3-4
 *
 * @example
 *   <NestedTypographyComponent
        textColor={tokens.labelError}
        startText={"This is a "}
        middleBoldText={"Bold"}
        endText={" Text to be shown."}
        startAndEndTextTypography={typography.b2}
        boldTextTypography={typography.s2}
        isSpan={false}
      />
 */

export const NestedTypographyComponent = observer(
  ({
    textColor,
    startText,
    middleBoldText,
    endText,
    startAndEndTextTypography,
    boldTextTypography,
    isSpan,
  }: NestedTypographyComponentProps): React.ReactElement => {
    return (
      <Typography
        {...(isSpan && { component: "span" })}
        sx={{
          ...startAndEndTextTypography,
          color: textColor,
          width: "492px",
        }}
      >
        {startText}
        {SINGLE_SPACING}
        <Typography
          // This is required since by default Typography uses the <p> tag and <p> tag cannot be a descendant of another <p> tag.
          component={"span"}
          sx={{
            ...boldTextTypography,
            color: textColor,
            maxWidth: "472px",
            display: "inline-block",
          }}
        >
          {middleBoldText}
        </Typography>
        {SINGLE_SPACING}
        {endText}
      </Typography>
    );
  },
);
