import {
  BOUsersSelection,
  DeleteProjectBOUsersRPC,
  TableFilter,
} from "@pulse/pulse-rpcs";
import {
  Instance,
  flow,
  getParentOfType,
  getRoot,
  types,
} from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import { ManageBOUserStore } from "./ManageBOUserStore";
import { CommonErrors } from "@pulse/shared-components";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { RootStore } from "../../root/store/RootStore";
import { useDeleteProjectBOUsersRPCClientImpl } from "../rpcs/RPC";

export enum DeleteProjectBOUsersRPCErrors {
  InvalidBOUserId = "INVALID_BO_USER_ID",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
}

export enum DeleteProjectBOUserDialogState {
  DeleteProjectBOUserState = "DELETE_PROJECT_BO_USER_STATE",
  ErrorState = "ERROR_STATE",
  SuccessState = "SUCCESS_STATE",
}

export const DeleteProjectBOUserStore = types
  .model("DeleteProjectBOUserStore", {
    isRPCLoading: types.optional(types.boolean, false),
    rpcErrors: types.maybeNull(
      types.enumeration(
        "DeleteProjectBOUsersRPCErrors",
        Object.values(DeleteProjectBOUsersRPCErrors),
      ),
    ),
    deleteProjectBOUserDialogState: types.optional(
      types.enumeration(
        "DeleteProjectBOUserDialogState",
        Object.values(DeleteProjectBOUserDialogState),
      ),
      DeleteProjectBOUserDialogState.DeleteProjectBOUserState,
    ),
  })
  .actions((store) => ({
    setRemoveProjectBOUserDialogState: (
      deleteProjectBOUserDialogState: DeleteProjectBOUserDialogState,
    ): void => {
      store.deleteProjectBOUserDialogState = deleteProjectBOUserDialogState;
    },
  }))
  .actions((store) => ({
    deleteProjectBOUsers: flow(function* (
      projectId: string,
      selectedBOUserId: string | undefined,
    ) {
      try {
        store.isRPCLoading = true;
        store.rpcErrors = null;
        const apiClient = getAPIClient(store);
        const manageBOUserStore = getParentOfType(store, ManageBOUserStore);
        const requestFilters: TableFilter[] = [];
        manageBOUserStore.applyFilters(requestFilters);
        let boUserSelection;
        if (selectedBOUserId) {
          boUserSelection = new BOUsersSelection.SomeRowsSelected([
            new LeoUUID(selectedBOUserId),
          ]);
        } else {
          boUserSelection = manageBOUserStore.multiSelectTableStore
            .areAllRowsSelected
            ? new BOUsersSelection.AllRowsSelected([...requestFilters])
            : new BOUsersSelection.SomeRowsSelected(
                manageBOUserStore.multiSelectTableStore.selectedRowIds.map(
                  (id: string | null | undefined) => new LeoUUID(id),
                ),
              );
        }
        const request: DeleteProjectBOUsersRPC.Request =
          new DeleteProjectBOUsersRPC.Request(
            new LeoUUID(projectId),
            boUserSelection,
          );
        const {
          response,
          error,
        }: {
          response?: DeleteProjectBOUsersRPC.Response;
          error?: DeleteProjectBOUsersRPC.Errors.Errors;
        } =
          yield useDeleteProjectBOUsersRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          store.deleteProjectBOUserDialogState =
            DeleteProjectBOUserDialogState.SuccessState;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              // This error is handled in default response interceptor.
              break;
            }
            case DeleteProjectBOUsersRPCErrors.InvalidBOUserId: {
              store.deleteProjectBOUserDialogState =
                DeleteProjectBOUserDialogState.ErrorState;
              store.rpcErrors = DeleteProjectBOUsersRPCErrors.InvalidBOUserId;
              break;
            }
            case DeleteProjectBOUsersRPCErrors.ProjectAlreadyArchived: {
              store.deleteProjectBOUserDialogState =
                DeleteProjectBOUserDialogState.ErrorState;
              store.rpcErrors =
                DeleteProjectBOUsersRPCErrors.ProjectAlreadyArchived;
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error ${e} in deleteProjectBOUsers action.`);
        }
      } finally {
        const manageBOUserStore = getParentOfType(store, ManageBOUserStore);

        store.isRPCLoading = false;
        manageBOUserStore.multiSelectTableStore.unselectAllRows();
        manageBOUserStore.manageProjectBOUserListFilter.clearStore();
      }
    }),
  }));

export const createDeleteProjectBOUserStore = (): Instance<
  typeof DeleteProjectBOUserStore
> => {
  return DeleteProjectBOUserStore.create();
};
