import React from "react";
import { Instance } from "mobx-state-tree";
import {
  TranslationUploadStore,
  UploadTranslationsDialogState,
} from "../../store/TranslationUploadStore";
import { observer } from "mobx-react";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../../routes/RoutesHelper";
import { TFunction } from "i18next";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  ICON_SIZE,
  MAX_TRANSLATIONS_FILE_SIZE_IN_BYTES,
  MIN_FILE_SIZE_IN_BYTES,
  getIconProps,
} from "@pulse/shared-components";
import { getUploadErrorDescriptions } from "../../../utils/FileUploadUtils";
import { UploadValidationLoader } from "../../../../components/fileUpload/UploadValidationLoader";
import { Stack, Typography } from "@mui/material";
import { FileUploadComponent } from "../../../../components/fileUpload/FileUploadComponent";
import { AlertCircle } from "lucide-react";
import { FileUploadedDescription } from "../../../../components/fileUpload/FileUploadedDescription";

export interface TranslationUploadPaneProps {
  translationUploadStore: Instance<typeof TranslationUploadStore>;
  navigateTo: NavigateToFunctions;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

export const TranslationUploadPane = observer(
  ({
    translationUploadStore,
    navigateTo,
    t,
    spacing,
    typography,
    tokens,
  }: TranslationUploadPaneProps): React.ReactElement => {
    const iconProps = getIconProps(tokens.iconError, ICON_SIZE.default);

    const UploadErrors = (): React.ReactElement => {
      const getErrorDescriptions = (): String => {
        if (translationUploadStore.validationErrors) {
          return getUploadErrorDescriptions(
            translationUploadStore.validationErrors,
            t,
            MAX_TRANSLATIONS_FILE_SIZE_IN_BYTES,
            MIN_FILE_SIZE_IN_BYTES,
          );
        }
        return t("common.uploadDialog.unexpectedErrorDescription");
      };

      return (
        <Stack spacing={spacing.spaceXXS}>
          <Typography sx={{ ...typography.b2, color: tokens.label }}>
            {translationUploadStore.fileName}
          </Typography>
          <Stack direction="row" gap={spacing.spaceXXS}>
            <AlertCircle {...iconProps} />
            <Typography sx={{ ...typography.b2, color: tokens.labelError }}>
              {getErrorDescriptions()}
            </Typography>
          </Stack>
        </Stack>
      );
    };

    const fileUploadHandler = async (file: File): Promise<void> => {
      translationUploadStore.setFileName(file.name);
      await processSurveyParams(async (surveyId, projectId) => {
        await translationUploadStore.uploadCSVFile(file, surveyId, projectId);
      }, navigateTo);
    };

    switch (translationUploadStore.uploadTranslationsDialogState) {
      case UploadTranslationsDialogState.UploadSuccessful: {
        return (
          <FileUploadedDescription
            fileName={translationUploadStore.fileName}
            t={t}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
          />
        );
      }
      case UploadTranslationsDialogState.ValidationErrorFound: {
        if (translationUploadStore.isFileInvalid) {
          return (
            <Stack spacing={spacing.spaceMD}>
              <FileUploadComponent fileUploadHandler={fileUploadHandler} />
              <Stack gap={spacing.spaceXXS}>
                <Typography sx={{ ...typography.s2, color: tokens.label }}>
                  {t("common.uploadDialog.errorsFoundText")}
                </Typography>
                <Typography
                  sx={{
                    ...typography.b2,
                    color: tokens.labelError,
                    whiteSpace: "pre-line",
                  }}
                >
                  {translationUploadStore.invalidFileDetails}
                </Typography>
              </Stack>
            </Stack>
          );
        } else {
          return (
            <Stack spacing={spacing.spaceMD}>
              <FileUploadComponent fileUploadHandler={fileUploadHandler} />
              {<UploadErrors />}
            </Stack>
          );
        }
      }
      case UploadTranslationsDialogState.UploadingFile: {
        return (
          <UploadValidationLoader
            fileName={translationUploadStore.fileName}
            t={t}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
          />
        );
      }
      default: {
        return <FileUploadComponent fileUploadHandler={fileUploadHandler} />;
      }
    }
  },
);
