import { Stack } from "@mui/material";
import {
  Spacing,
  TextInputField,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { ChoiceListButtons } from "./ChoiceListButtons";
import {
  EMPTY_CHARACTER,
  MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
  MAX_OPTION_TEXT_CHARACTER_LIMIT,
  ZERO_VALUE,
  showMaxCharacterCountIndicator,
} from "@pulse/shared-components";
import { QuestionOptionsModel } from "@pulse/shared-components/src/models/QuestionOptionsModel";
import { Instance } from "mobx-state-tree";
import { FieldValidations } from "../store/QuestionDetailsStore";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

interface CommonChoiceOptionsLayoutProps {
  t: TFunction;
  index: number;
  spacing: Spacing;
  options: Instance<typeof QuestionOptionsModel>[];
  isAddNewFieldButtonDisabled: boolean;
  isDeleteButtonDisabled: boolean;
  setFieldValidations: (fieldValidations: FieldValidations | null) => void;
  doesOptionContainErrors(index: number): boolean;
  setFieldOptionText: (fieldOptionText: string, index: number) => void;
  addNewField: () => void;
  doesOptionCodeContainErrors(index: number): boolean;
  isOptionCodeNotUnique(optionCode: string): boolean;
  setDuplicateOptionCode: (duplicateOptionCode: string | null) => void;
  setCode: (code: string, index: number) => void;
  isChevronUpDisabled(index: number): boolean;
  isChevronDownDisabled(index: number): boolean;
  moveFieldUp: (index: number) => void;
  moveFieldDown: (index: number) => void;
  removeField: (index: number) => void;
  optionTextWidth: string | undefined;
  optionCodeWidth: string | undefined;
}

export const CommonChoiceOptionsLayout = observer(
  ({
    t,
    index,
    spacing,
    options,
    isAddNewFieldButtonDisabled,
    isDeleteButtonDisabled,
    setFieldOptionText,
    setFieldValidations,
    doesOptionContainErrors,
    addNewField,
    doesOptionCodeContainErrors,
    isOptionCodeNotUnique,
    setDuplicateOptionCode,
    setCode,
    isChevronUpDisabled,
    isChevronDownDisabled,
    moveFieldDown,
    moveFieldUp,
    removeField,
    optionCodeWidth,
    optionTextWidth,
  }: CommonChoiceOptionsLayoutProps): React.ReactElement => {
    const OptionField = observer((): React.ReactElement => {
      return (
        <TextInputField
          width={optionTextWidth}
          minWidth={optionTextWidth}
          name="option"
          value={options[index].option}
          label={EMPTY_CHARACTER}
          error={doesOptionContainErrors(index)}
          placeholder={t("surveys.addSurveyQuestion.enterOption")}
          type="text"
          onTextChange={(inputString: string) => {
            setFieldValidations(null);
            setFieldOptionText(
              inputString.slice(ZERO_VALUE, MAX_OPTION_TEXT_CHARACTER_LIMIT),
              index,
            );
          }}
          maxCharacterLength={MAX_OPTION_TEXT_CHARACTER_LIMIT}
          showCharacterCountIndicator={(): boolean =>
            showMaxCharacterCountIndicator(
              MAX_OPTION_TEXT_CHARACTER_LIMIT,
              options[index].option,
            )
          }
        />
      );
    });

    const CodeField = observer((): React.ReactElement => {
      return (
        <TextInputField
          name="code"
          width={optionCodeWidth}
          minWidth={optionCodeWidth}
          value={options[index].code}
          label={EMPTY_CHARACTER}
          placeholder={t("surveys.addSurveyQuestion.enterCode")}
          type="text"
          error={doesOptionCodeContainErrors(index)}
          helperText={
            isOptionCodeNotUnique(options[index].code)
              ? t("surveys.addSurveyQuestion.enterUniqueCode")
              : undefined
          }
          onTextChange={(inputString: string): void => {
            setDuplicateOptionCode(null);
            setFieldValidations(null);
            setCode(
              inputString.slice(
                ZERO_VALUE,
                MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
              ),
              index,
            );
          }}
          maxCharacterLength={MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT}
          showCharacterCountIndicator={(): boolean =>
            showMaxCharacterCountIndicator(
              MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
              options[index].code,
            )
          }
        />
      );
    });

    return (
      <Stack direction="row" gap={spacing.spaceSM} alignItems="center">
        <OptionField />
        <CodeField />
        <ChoiceListButtons
          paddingTop={undefined}
          border={undefined}
          spacing={spacing}
          isAddNewFieldButtonDisabled={isAddNewFieldButtonDisabled}
          addNewFields={addNewField}
          index={index}
          isChevronUpDisabled={isChevronUpDisabled}
          isChevronDownDisabled={isChevronDownDisabled}
          moveFieldUp={moveFieldUp}
          moveFieldDown={moveFieldDown}
          removeField={removeField}
          isDeleteButtonDisabled={isDeleteButtonDisabled}
        />
      </Stack>
    );
  },
);
