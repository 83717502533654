import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  TableReloadHandle,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React, { useEffect } from "react";
import {
  EditBOUserDialogState,
  EditBOUserRPCError,
  EditBOUserStore,
} from "../store/EditBOUserStore";
import { DialogErrorContent } from "../../../components/DialogErrorContent";
import { EditBOUserChildComponent } from "./EditBOUserChildComponent";
import { BoUserRole } from "@pulse/pulse-rpcs";
import { observer } from "mobx-react";

interface EditBOUserDialogProps {
  isEditBOUserDialogOpen: boolean;
  setIsEditBOUserDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  spacing: Spacing;
  editBOUserStore: Instance<typeof EditBOUserStore>;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  boUserId: string;
  firstName: string;
  lastName: string;
  role: BoUserRole.BoUserRole;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const EditBOUserDialog = observer(
  ({
    editBOUserStore,
    isEditBOUserDialogOpen,
    setIsEditBOUserDialogOpen,
    spacing,
    t,
    tokens,
    typography,
    boUserId,
    firstName,
    lastName,
    role,
    tableRef,
  }: EditBOUserDialogProps): React.ReactElement => {
    useEffect(() => {
      if (editBOUserStore.boUserDetails.areValuesUnchanged()) {
        editBOUserStore.boUserDetails.setFirstName(firstName);
        editBOUserStore.boUserDetails.setLastName(lastName);
        editBOUserStore.boUserDetails.setRole(role);
      }
      return () => {
        editBOUserStore.resetStoreData();
      };
    }, []);

    const getPrimaryButtonText = (): string | undefined => {
      switch (editBOUserStore.editBOUserDialogState) {
        case EditBOUserDialogState.Error: {
          return undefined;
        }
        case EditBOUserDialogState.EditBOUser: {
          return t("common.saveChanges");
        }
      }
    };

    const getSecondaryButtonText = (): string | undefined => {
      switch (editBOUserStore.editBOUserDialogState) {
        case EditBOUserDialogState.Error: {
          return t("common.close");
        }
        case EditBOUserDialogState.EditBOUser: {
          return t("common.cancel");
        }
      }
    };

    const getErrorText = (): string => {
      switch (editBOUserStore.rpcError) {
        case EditBOUserRPCError.InvalidRoleId:
        case EditBOUserRPCError.UnableToUpdateBOUser: {
          return t("manageUsers.editBOUserDialog.errorTexts.unexpectedError");
        }
        default: {
          return t("manageUsers.editBOUserDialog.errorTexts.unexpectedError");
        }
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (editBOUserStore.editBOUserDialogState) {
        case EditBOUserDialogState.EditBOUser: {
          return (
            <EditBOUserChildComponent
              t={t}
              editBOUserStore={editBOUserStore}
              spacing={spacing}
            />
          );
        }
        case EditBOUserDialogState.Error: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
      }
    };

    return (
      <Dialog
        open={isEditBOUserDialogOpen}
        title={t("manageUsers.editBOUserDialog.editBOU")}
        contentPadding={spacing.spaceLG}
        disableBackdropClick={true}
        primaryButtonText={getPrimaryButtonText()}
        secondaryButtonText={getSecondaryButtonText()}
        isPrimaryButtonDisabled={
          editBOUserStore.boUserDetails.areValuesUnchanged(
            firstName,
            lastName,
            role,
          ) || editBOUserStore.boUserDetails.areUserDetailFieldsEmpty
        }
        onPrimaryButtonClick={async (): Promise<void> => {
          editBOUserStore.setIsEditBOButtonClicked(true);
          await editBOUserStore.editBOUser(boUserId);
          if (
            !editBOUserStore.rpcError &&
            !editBOUserStore.areTextFieldsErrored
          ) {
            setIsEditBOUserDialogOpen(false);
            tableRef.current?.reload();
          }
        }}
        isSecondaryButtonDisabled={editBOUserStore.isRPCLoading}
        onSecondaryButtonClick={() => {
          setIsEditBOUserDialogOpen(false);
          editBOUserStore.setIsEditBOButtonClicked(false);
        }}
        width="560px"
      >
        {/*
        getDialogChild() does is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
