import { Stack, Typography } from "@mui/material";
import {
  Banner,
  Button,
  Dialog,
  Download,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { observer } from "mobx-react";

interface UploadFileDialogProps {
  title: string;
  description: string;
  note: string;
  isUploadFileDialogVisible: boolean;
  primaryDownloadButtonText: string;
  primaryDownloadButtonOnClick: () => Promise<void>;
  secondaryDowloadButtonText?: string;
  secondaryDownloadButtonOnClick?: () => Promise<void>;
  primaryDialogButtonText: string;
  primaryDialogButtonOnClick: () => Promise<void>;
  isPrimaryDialogButtonDisabled: boolean;
  secondaryDialogButtonText: string;
  secondaryDialogButtonOnClick: () => void;
  isSecondaryDialogButtonDisabled: boolean;
  fileUploadPane: React.ReactElement;
  errorBannerText: string | undefined;
}

/**
 * Creates an UploadFileDialog.
 * @param title - It's the title of the Dialog box.
 * @param description - The description to be displayed at the top of the component.
 * @param isUploadFileDialogVisible - It's a boolean that determines whether the dialog box is displayed.
 * @param primaryDownloadButtonText - The text to be displayed for the primary download button.
 * @param primaryDownloadButtonOnClick - The callback to be called when the primary download button is clicked.
 * @param secondaryDowloadButtonText - The text to be displayed for the secondary download button.
 * @param secondaryDownloadButtonOnClick - The callback to be called when the secondary download button is clicked.
 * @param primaryDialogButtonText - The text for the primary button of the dialog box.
 * @param primaryDialogButtonOnClick - It's the callback to be called when the primary dialog button is clicked.
 * @param isPrimaryDialogButtonDisabled - It's a boolean that determines whether the primary dialog button is disabled.
 * @param secondaryDialogButtonText - The text for the secondary button of the dialog box.
 * @param secondaryDialogButtonOnClick - It's the callback to be called when the secondary dialog button is clicked.
 * @param isSecondaryDialogButtonDisabled - It's a boolean that determines whether the secondary dialog button is disabled.
 * @param fileUploadPane - It's the React element to be displayed at the botton of the Dialog box.
 * @param errorbannerText - This is the string to be displayed at the top with a banner, in case there is an error.
 * @returns A UploadFileDialog component
 *
 * @example
 *   const uploadFileDialog = <UploadFileDialog
        setIsUploadFileDialogVisible={setIsUploadDialogVisible}
        isUploadFileDialogVisible={isUploadDialogVisible}
        title={"UploadCSV"}
        description={"Upload CSV Description"}
        primaryDownloadButtonText={"Download Template"}
        primaryDownloadButtonOnClick={downloadButtonOnClick}
        primaryDialogButtonText={"Proceed"}
        primaryDialogButtonOnClick={dialogButtonOnClick}
        secondaryDialogButtonText={"Cancel"}
        isPrimaryDialogButtonDisabled={true}
        fileUploadPane={<UploadPane/>}
        isSecondaryDialogButtonDisabled={false}
        errorBannerText={"Error has been found"}
      />
 */
export const UploadFileDialog = observer(
  ({
    title,
    description,
    note,
    isUploadFileDialogVisible,
    primaryDownloadButtonText,
    primaryDownloadButtonOnClick,
    secondaryDowloadButtonText,
    secondaryDownloadButtonOnClick,
    primaryDialogButtonText,
    primaryDialogButtonOnClick,
    isPrimaryDialogButtonDisabled,
    secondaryDialogButtonText,
    secondaryDialogButtonOnClick,
    isSecondaryDialogButtonDisabled,
    fileUploadPane,
    errorBannerText,
  }: UploadFileDialogProps): React.ReactElement => {
    const typography = useTypography();
    const tokens = useFoundationColorTokens();
    const spacing = useSpacing();
    const [isDownloadButtonLoading, setIsDownloadButtonLoading] =
      useState(false);
    const [
      isSecondaryDownloadButtonLoading,
      setIsSecondaryDownloadButtonLoading,
    ] = useState(false);

    return (
      <Dialog
        open={isUploadFileDialogVisible}
        title={title}
        secondaryButtonText={secondaryDialogButtonText}
        contentPadding={spacing.spaceLG}
        isSecondaryButtonDisabled={isSecondaryDialogButtonDisabled}
        onSecondaryButtonClick={secondaryDialogButtonOnClick}
        isPrimaryButtonDisabled={isPrimaryDialogButtonDisabled}
        onPrimaryButtonClick={primaryDialogButtonOnClick}
        primaryButtonText={primaryDialogButtonText}
        width="560px"
      >
        <Stack gap={spacing.spaceLG}>
          {errorBannerText && (
            <Banner message={errorBannerText} severity="error" width="496px" />
          )}
          <Stack gap={spacing.spaceSM}>
            <Typography {...typography.b2} color={tokens.labelSubtle}>
              {description}
            </Typography>

            <Typography {...typography.b2} color={tokens.labelSubtle}>
              {note}
            </Typography>

            <Stack direction={"row"} gap={spacing.spaceSM}>
              <Button
                name="primaryDownload"
                label={primaryDownloadButtonText}
                icon={<Download />}
                iconPosition="leading"
                size="small"
                variant="outlined-neutral"
                loading={isDownloadButtonLoading}
                disabled={isDownloadButtonLoading}
                onClick={async (): Promise<void> => {
                  setIsDownloadButtonLoading(true);
                  await primaryDownloadButtonOnClick();
                  setIsDownloadButtonLoading(false);
                }}
              />
              {secondaryDownloadButtonOnClick && secondaryDowloadButtonText && (
                <Button
                  name="secondaryDownload"
                  label={secondaryDowloadButtonText}
                  icon={<Download />}
                  iconPosition="leading"
                  size="small"
                  variant="outlined-neutral"
                  loading={isSecondaryDownloadButtonLoading}
                  disabled={isSecondaryDownloadButtonLoading}
                  onClick={async (): Promise<void> => {
                    setIsSecondaryDownloadButtonLoading(true);
                    await secondaryDownloadButtonOnClick();
                    setIsSecondaryDownloadButtonLoading(false);
                  }}
                />
              )}
            </Stack>
          </Stack>
          {fileUploadPane}
        </Stack>
      </Dialog>
    );
  },
);
