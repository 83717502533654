import {
  Instance,
  cast,
  getParent,
  getParentOfType,
  types,
} from "mobx-state-tree";
import {
  EMPTY_CHARACTER,
  MAX_OPEN_ENDED_FIELD_OPTIONS,
  OpenEndedFieldModel,
} from "@pulse/shared-components";
import { FieldValidations, QuestionDetailsStore } from "./QuestionDetailsStore";
import { QuestionDetailsModel } from "../models/QuestionDetailsModel";
import { doesCodeContainAllowedCharacters } from "@pulse/shared-components";

export const OpenEndedFieldV1Store = types
  .model("OpenEndedFieldV1Store", {
    openEndedField: types.array(OpenEndedFieldModel),
    isChoiceResetAllowed: types.optional(types.boolean, false),
    isMultiLineTextEntryAllowed: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    resetFieldValidationsAndErrors: (): void => {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );
      questionDetailsStore.setFieldValidations(null);
      questionDetailsStore.setRPCErrors(null);
      questionDetailsStore.setDuplicateOptionCode(null);
    },
  }))
  .actions((store) => ({
    resetOptions: (): void => {
      store.openEndedField = cast([
        {
          fieldTitle: EMPTY_CHARACTER,
          fieldHint: EMPTY_CHARACTER,
          code: EMPTY_CHARACTER,
        },
      ]);
      store.resetFieldValidationsAndErrors();
    },
  }))
  .actions((store) => ({
    setIsChoiceResetAllowed: (isChoiceResetAllowed: boolean): void => {
      store.isChoiceResetAllowed = isChoiceResetAllowed;
    },
    setIsMultiLineTextEntryAllowed: (
      isMultiLineTextEntryAllowed: boolean,
    ): void => {
      store.isMultiLineTextEntryAllowed = isMultiLineTextEntryAllowed;
    },
    setFieldTitle: (fieldTitle: string, index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.openEndedField[index].fieldTitle = fieldTitle;
    },
    setFieldHint: (fieldHint: string, index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.openEndedField[index].fieldHint = fieldHint;
    },
    setCode: (code: string, index: number): void => {
      if (doesCodeContainAllowedCharacters(code)) {
        store.resetFieldValidationsAndErrors();
        store.openEndedField[index].code = code;
      }
    },
    addNewField: (
      openEndedField: Instance<typeof OpenEndedFieldModel>,
      index: number,
    ): void => {
      store.resetFieldValidationsAndErrors();
      store.openEndedField.splice(index + 1, 0, openEndedField);
    },
    moveFieldUp: (index: number): void => {
      const openEndedFieldArrayCopy = [...store.openEndedField];
      const replacedElement = openEndedFieldArrayCopy[index - 1];
      openEndedFieldArrayCopy[index - 1] = openEndedFieldArrayCopy[index];
      openEndedFieldArrayCopy[index] = replacedElement;
      store.openEndedField.replace(openEndedFieldArrayCopy);
    },
    moveFieldDown: (index: number): void => {
      const openEndedFieldArrayCopy = [...store.openEndedField];
      const replacedElement = openEndedFieldArrayCopy[index + 1];
      openEndedFieldArrayCopy[index + 1] = openEndedFieldArrayCopy[index];
      openEndedFieldArrayCopy[index] = replacedElement;
      store.openEndedField.replace(openEndedFieldArrayCopy);
    },
    removeField: (index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.openEndedField.splice(index, 1);
    },
    validateFields(): void {
      const questionDetailsStore = getParentOfType(store, QuestionDetailsStore);
      store.openEndedField.forEach((openEndedFieldElement) => {
        if (openEndedFieldElement.code.trim() === EMPTY_CHARACTER) {
          questionDetailsStore.setFieldValidations(
            FieldValidations.UnfilledOptionField,
          );
        }
      });
    },
    validateInitialFieldCodes(
      initialQuestionDetails: Instance<typeof store>,
    ): void {
      const questionDetailsStore = getParentOfType(store, QuestionDetailsStore);
      const updatedFieldCodes = store.openEndedField.map((openEndedField) => {
        return openEndedField.code.trim();
      });
      const initialFieldCodes = initialQuestionDetails.openEndedField.map(
        (openEndedField) => {
          return openEndedField.code;
        },
      );
      if (
        JSON.stringify(updatedFieldCodes) !== JSON.stringify(initialFieldCodes)
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.QuestionAndOptionCodesCannotBeUpdated,
        );
      }
    },
  }))
  .views((store) => ({
    get openEndedFieldCount(): number {
      return store.openEndedField.length;
    },
    get isResetDisabled(): boolean {
      if (store.openEndedField.length === 1) {
        return (
          store.openEndedField[0].code === EMPTY_CHARACTER &&
          store.openEndedField[0].fieldTitle === EMPTY_CHARACTER &&
          store.openEndedField[0].fieldHint === EMPTY_CHARACTER
        );
      }
      return false;
    },
    doesFieldCodeContainErrors(index: number): boolean {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );
      return (
        (store.openEndedField[index].code.trim() === EMPTY_CHARACTER &&
          questionDetailsStore.fieldValidations ===
            FieldValidations.UnfilledOptionField) ||
        store.openEndedField[index].code.trim() ===
          questionDetailsStore.duplicateOptionCode
      );
    },
    get isAddNewFieldButtonDisabled(): boolean {
      return store.openEndedField.length >= MAX_OPEN_ENDED_FIELD_OPTIONS;
    },
    isChevronDownDisabled(index: number): boolean {
      return index === store.openEndedField.length - 1;
    },
    get isDeleteButtonDisabled(): boolean {
      return store.openEndedField.length === 1;
    },
    isChevronUpDisabled(index: number): boolean {
      return index === 0;
    },
  }));

export const createOpenEndedFieldV1Store = (): Instance<
  typeof OpenEndedFieldV1Store
> => {
  return OpenEndedFieldV1Store.create({
    openEndedField: [
      {
        fieldTitle: EMPTY_CHARACTER,
        fieldHint: EMPTY_CHARACTER,
        code: EMPTY_CHARACTER,
      },
    ],
  });
};
