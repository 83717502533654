import { Instance, types } from "mobx-state-tree";
import { AuditLog } from "@pulse/pulse-rpcs";
import {
  SINGLE_SPACING,
  getFormattedDateString,
} from "@pulse/shared-components";

export const AuditLogModel = types
  .model("AuditLogModel", {
    emailAddress: types.string,
    eventName: types.string,
    timeStamp: types.Date,
  })
  .views((store) => ({
    get auditLogDateTime(): string {
      return (
        getFormattedDateString(store.timeStamp) +
        SINGLE_SPACING +
        ("0" + store.timeStamp.getHours()).slice(-2) +
        ":" +
        ("0" + store.timeStamp.getMinutes()).slice(-2)
      );
    },
  }));

export const createAuditLogModel = (
  auditLog: AuditLog,
): Instance<typeof AuditLogModel> => {
  return AuditLogModel.create({
    emailAddress: auditLog.emailAddress.email,
    eventName: auditLog.eventName,
    timeStamp: new Date(auditLog.timestamp.timestamp),
  });
};
