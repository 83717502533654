import { Button } from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { QuestionDetailsStore } from "../store/QuestionDetailsStore";
import { ViewIcon } from "lucide-react";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";

export const PreviewSurveyButton = ({
  isAddQuestionPage,
  t,
  questionDetailsStore,
  isButtonDisabled,
  navigateTo,
}: {
  isAddQuestionPage: boolean;
  t: TFunction;
  isButtonDisabled: boolean;
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
  navigateTo: NavigateToFunctions;
}): React.ReactElement => {
  return (
    <Button
      name="previewSurvey"
      variant={isAddQuestionPage ? "outlined-color" : "outlined-neutral"}
      label={t("surveys.addSurveyQuestion.previewSurvey")}
      icon={<ViewIcon />}
      iconPosition="leading"
      size="medium"
      disabled={isButtonDisabled}
      onClick={async (): Promise<void> => {
        const surveyId = new URLSearchParams(location.search).get("surveyId");
        const projectId = new URLSearchParams(location.search).get("projectId");
        if (surveyId === null || projectId === null) {
          navigateTo.pageNotFound();
        } else {
          await questionDetailsStore.getGeneratedPreviewLink(
            surveyId,
            projectId,
          );
          if (questionDetailsStore.previewSurveyLink) {
            const newWindow = window.open(
              questionDetailsStore.previewSurveyLink,
              "_blank",
            );
            if (newWindow) {
              newWindow.focus();
            } else {
              console.error("newWindow cannot be null");
            }
          }
        }
      }}
    />
  );
};
