import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  FoundationColorTokens,
  RadioButtonColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import { TFunction } from "i18next";
import { GridColumnQuestionV1Model } from "../../models/GridColumnQuestionV1Model";
import {
  GridSingleChoiceDisplayTypeV1Enum,
  getRadioButtonStyleProps,
} from "@pulse/shared-components";

interface GridSingleChoiceDisplayRadioComponentProps {
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  radioButtonTokens: RadioButtonColorTokens<string>;
  gridColumnQuestionV1Model: Instance<typeof GridColumnQuestionV1Model>;
  t: TFunction;
  isNPSSelected: boolean;
}

export const GridSingleChoiceDisplayRadioComponent = observer(
  ({
    typography,
    spacing,
    tokens,
    t,
    radioButtonTokens,
    gridColumnQuestionV1Model,
    isNPSSelected,
  }: GridSingleChoiceDisplayRadioComponentProps): React.ReactElement => {
    const radioButtonStyleProps = getRadioButtonStyleProps(
      tokens,
      radioButtonTokens,
      spacing,
    );

    return (
      <Stack gap={spacing.spaceMD} width={"580px"}>
        <RadioGroup
          onChange={(e): void => {
            gridColumnQuestionV1Model.singleChoice.setSingleChoiceDisplayType(
              e.target.value,
              t,
            );
            gridColumnQuestionV1Model.resetFieldValidationsAndErrors();
            if (
              gridColumnQuestionV1Model.singleChoice
                .gridSingleSelectQuestionV1Model.isNpsBorderColor ||
              gridColumnQuestionV1Model.singleChoice
                .gridSingleSelectQuestionV1Model.isNpsPreFilledColor
            ) {
              gridColumnQuestionV1Model.singleChoice.setNPSSelection(true);
            } else if (
              gridColumnQuestionV1Model.singleChoice
                .gridSingleSelectQuestionV1Model.isDefaultNps
            ) {
              gridColumnQuestionV1Model.singleChoice.setNPSSelection(false);
            }
          }}
        >
          <Stack direction="row" gap={spacing.spaceMD} flexWrap={"wrap"}>
            <Stack minWidth="240px">
              <Typography {...typography.sh3} marginBottom={spacing.spaceSM}>
                {t("surveys.addSurveyQuestion.singleChoice.selection")}
              </Typography>
              <FormControlLabel
                value={GridSingleChoiceDisplayTypeV1Enum.RadioButton}
                label={t("surveys.addSurveyQuestion.singleChoice.radioButtons")}
                control={<Radio {...radioButtonStyleProps} />}
                checked={
                  gridColumnQuestionV1Model.singleChoice
                    .gridSingleSelectQuestionV1Model.isRadio
                }
              />
              <FormControlLabel
                value={GridSingleChoiceDisplayTypeV1Enum.Dropdown}
                label={t("surveys.addSurveyQuestion.singleChoice.dropdown")}
                control={<Radio {...radioButtonStyleProps} />}
                checked={
                  gridColumnQuestionV1Model.singleChoice
                    .gridSingleSelectQuestionV1Model.isDropdown
                }
              />
            </Stack>
            <Stack minWidth="240px">
              <Typography {...typography.sh3} marginBottom={spacing.spaceSM}>
                {t("surveys.addSurveyQuestion.singleChoice.slider")}
              </Typography>
              <FormControlLabel
                value={GridSingleChoiceDisplayTypeV1Enum.DefaultSlider}
                label={t(
                  "surveys.addSurveyQuestion.singleChoice.defaultSlider",
                )}
                control={<Radio {...radioButtonStyleProps} />}
                checked={
                  gridColumnQuestionV1Model.singleChoice
                    .gridSingleSelectQuestionV1Model.isDefaultSlider
                }
              />
              <FormControlLabel
                value={GridSingleChoiceDisplayTypeV1Enum.RygSlider}
                label={t("surveys.addSurveyQuestion.singleChoice.rygSlider")}
                control={<Radio {...radioButtonStyleProps} />}
                checked={
                  gridColumnQuestionV1Model.singleChoice
                    .gridSingleSelectQuestionV1Model.isRygSlider
                }
              />
            </Stack>
            <Stack minWidth="240px">
              <Typography {...typography.sh3} marginBottom={spacing.spaceSM}>
                {t("surveys.addSurveyQuestion.singleChoice.ratingScale")}
              </Typography>
              <FormControlLabel
                value={GridSingleChoiceDisplayTypeV1Enum.DefaultScale}
                label={t("surveys.addSurveyQuestion.singleChoice.defaultScale")}
                control={<Radio {...radioButtonStyleProps} />}
                checked={
                  gridColumnQuestionV1Model.singleChoice
                    .gridSingleSelectQuestionV1Model.isDefaultScale
                }
              />
              <FormControlLabel
                value={GridSingleChoiceDisplayTypeV1Enum.ScaleWithText}
                label={t(
                  "surveys.addSurveyQuestion.singleChoice.scaleWithText",
                )}
                control={<Radio {...radioButtonStyleProps} />}
                checked={
                  gridColumnQuestionV1Model.singleChoice
                    .gridSingleSelectQuestionV1Model.isScaleWithText
                }
              />
              <FormControlLabel
                value={GridSingleChoiceDisplayTypeV1Enum.NeutralScale}
                label={t("surveys.addSurveyQuestion.singleChoice.neutralScale")}
                control={<Radio {...radioButtonStyleProps} />}
                checked={
                  gridColumnQuestionV1Model.singleChoice
                    .gridSingleSelectQuestionV1Model.isNeutralScale
                }
              />
            </Stack>
            {isNPSSelected && (
              <Stack minWidth="240px">
                <Typography {...typography.sh3} marginBottom={spacing.spaceSM}>
                  {t("surveys.addSurveyQuestion.singleChoice.netPromoterScore")}
                </Typography>
                <FormControlLabel
                  value={GridSingleChoiceDisplayTypeV1Enum.DefaultNps}
                  label={t("surveys.addSurveyQuestion.singleChoice.defaultNPS")}
                  control={<Radio {...radioButtonStyleProps} />}
                  checked={
                    gridColumnQuestionV1Model.singleChoice
                      .gridSingleSelectQuestionV1Model.isDefaultNps
                  }
                />
                <FormControlLabel
                  value={GridSingleChoiceDisplayTypeV1Enum.NpsPreFilledColor}
                  label={t(
                    "surveys.addSurveyQuestion.singleChoice.npsPrefilledColor",
                  )}
                  control={<Radio {...radioButtonStyleProps} />}
                  checked={
                    gridColumnQuestionV1Model.singleChoice
                      .gridSingleSelectQuestionV1Model.isNpsPreFilledColor
                  }
                />
                <FormControlLabel
                  value={GridSingleChoiceDisplayTypeV1Enum.NpsBorderColor}
                  label={t(
                    "surveys.addSurveyQuestion.singleChoice.npsBorderColor",
                  )}
                  control={<Radio {...radioButtonStyleProps} />}
                  checked={
                    gridColumnQuestionV1Model.singleChoice
                      .gridSingleSelectQuestionV1Model.isNpsBorderColor
                  }
                />
              </Stack>
            )}
          </Stack>
        </RadioGroup>
      </Stack>
    );
  },
);
