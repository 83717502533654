import { Stack } from "@mui/material";
import { Spacing } from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  CornerRadius,
  FoundationColorTokens,
  IconButton,
  Shadow,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import { BorderStyle } from "@pulse/shared-components";
import { FolderInput } from "lucide-react";
import { SurveyQuestionModel } from "../../models/SurveyQuestionModel";
import { ManageQuestionsStore } from "../../store/ManageQuestionsStore";
import { ChildQuestionPopover } from "./ChildQuestionPopover";
import { TFunction } from "i18next";

interface ChildQuestionsButtonsProps {
  t: TFunction;
  spacing: Spacing;
  manageQuestionsStore: Instance<typeof ManageQuestionsStore>;
  childQuestion: Instance<typeof SurveyQuestionModel>;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  shadow: Shadow;
  typography: Typography;
  questionId: string;
}

export const ChildQuestionsButtons = ({
  t,
  spacing,
  manageQuestionsStore,
  childQuestion,
  border,
  cornerRadius,
  shadow,
  tokens,
  typography,
  questionId,
}: ChildQuestionsButtonsProps): React.ReactElement => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(
    null,
  );
  const [selectedChildQuestionId, setSelectedChildQuestionId] = useState<
    string | undefined
  >();
  return (
    <Stack gap={spacing.spaceXS} flexDirection="row">
      <IconButton
        name={"moveUp"}
        size={"medium"}
        icon={<ChevronUp />}
        variant={"plain-neutral"}
        disabled={manageQuestionsStore.isMovingChildQuestionUpDisabled(
          childQuestion.surveyQuestionDetails.questionId,
        )}
        onClick={(): void => {
          manageQuestionsStore.moveChildQuestionUp(
            questionId,
            childQuestion.surveyQuestionDetails.questionId,
          );
        }}
      />
      <IconButton
        name={"moveDown"}
        size={"medium"}
        icon={<ChevronDown />}
        variant={"plain-neutral"}
        disabled={manageQuestionsStore.isMovingChildQuestionDownDisabled(
          questionId,
          childQuestion.surveyQuestionDetails.questionId,
        )}
        onClick={(): void => {
          manageQuestionsStore.moveChildQuestionDown(
            questionId,
            childQuestion.surveyQuestionDetails.questionId,
          );
        }}
      />
      {
        <>
          <IconButton
            name={`${childQuestion.surveyQuestionDetails.questionId}`}
            size={"medium"}
            icon={<FolderInput />}
            variant={"plain-neutral"}
            onClick={(): void => {
              const element = document.getElementsByName(
                `${childQuestion.surveyQuestionDetails.questionId}`,
              )[0] as HTMLButtonElement;
              setAnchorElement(element);
              setSelectedChildQuestionId(
                childQuestion.surveyQuestionDetails.questionId,
              );
            }}
          />
          <ChildQuestionPopover
            t={t}
            anchorElement={anchorElement}
            setAnchorElement={setAnchorElement}
            manageQuestionsStore={manageQuestionsStore}
            spacing={spacing}
            border={border}
            cornerRadius={cornerRadius}
            tokens={tokens}
            shadow={shadow}
            typography={typography}
            selectedChildQuestionId={selectedChildQuestionId}
            parentQuestionId={questionId}
            setSelectedChildQuestionId={setSelectedChildQuestionId}
          />
        </>
      }
    </Stack>
  );
};
