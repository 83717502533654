import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useEffect } from "react";
import {
  TranslationUpdateStatus,
  TranslationsStore,
} from "../../store/TranslationsStore";
import { Stack } from "@mui/material";
import {
  FoundationColorTokens,
  LoadingIndicator,
  Typography as LeoTypography,
  Spacing,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { ScheduleJobInProgressState } from "../../../../components/ScheduleJobInProgressState";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../../routes/RoutesHelper";
import { EmptyListPane } from "../EmptyListPane";
import { TranslationDataTabs } from "./TranslationDataTabs";
import { BorderStyle } from "@pulse/shared-components";
import { ErrorComponent } from "../../../../components/ErrorComponent";
import { Upload } from "lucide-react";

export interface TranslationsPaneProps {
  translationStore: Instance<typeof TranslationsStore>;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  spacing: Spacing;
  navigateTo: NavigateToFunctions;
  border: BorderStyle;
  borderRadius: CornerRadius;
}

export const TranslationsPane = observer(
  ({
    translationStore,
    t,
    tokens,
    typography,
    spacing,
    navigateTo,
    border,
    borderRadius,
  }: TranslationsPaneProps): React.ReactElement => {
    const getAllTranslations = async (): Promise<void> => {
      await processSurveyParams(async (surveyId, projectId) => {
        await translationStore.getAllTranslations(surveyId, projectId);
      }, navigateTo);
    };

    useEffect(() => {
      return () => {
        translationStore.resetStore();
      };
    }, []);

    useEffect(() => {
      getAllTranslations();
    }, [translationStore.selectedLanguage]);

    switch (translationStore.translationUpdateStatus) {
      case TranslationUpdateStatus.IsLoading: {
        return (
          <Stack height="492px" width="100%" justifyContent="center">
            <LoadingIndicator
              isLoading={translationStore.isRPCLoading}
              variant="container"
              loadingText={t("common.detailsLoadingState")}
            />
          </Stack>
        );
      }
      case TranslationUpdateStatus.IsError: {
        return (
          <ErrorComponent
            errorMessage={t(
              "surveys.translations.fetchTranslationsUnexpectedError",
            )}
            resolveButtonText={t("common.refresh")}
            resolveButtonCallback={getAllTranslations}
          />
        );
      }
      case TranslationUpdateStatus.NoTranslationsFound: {
        return (
          <EmptyListPane
            headingText={t("surveys.translations.noTranslationsAddedTitle")}
            subHeadingText={t(
              "surveys.translations.noTranslationsAddedDescription",
            )}
            helperButtonText={t("surveys.translations.uploadTranslationsText")}
            helperButtonCallback={async () => {
              translationStore.translationUploadStore.setIsTranslationDialogVisible(
                true,
              );
            }}
            helperButtonIcon={<Upload />}
          />
        );
      }
      case TranslationUpdateStatus.InProgress: {
        return (
          <ScheduleJobInProgressState
            t={t}
            tokens={tokens}
            spacing={spacing}
            typography={typography}
            refreshButtonOnClick={getAllTranslations}
            descriptionText={t("surveys.translations.inProgressDescription")}
          />
        );
      }
      case TranslationUpdateStatus.TranslationData: {
        return (
          <Stack width="100%" height="100%" alignItems="center" overflow="auto">
            <TranslationDataTabs
              translationsStore={translationStore}
              border={border}
              borderRadius={borderRadius}
              typography={typography}
              tokens={tokens}
              t={t}
              spacing={spacing}
            />
          </Stack>
        );
      }
    }
  },
);
