import React from "react";
import { FileUploadComponent } from "../../../components/fileUpload/FileUploadComponent";
import { Stack, Typography } from "@mui/material";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../routes/RoutesHelper";
import { Instance } from "mobx-state-tree";
import {
  EMPTY_CHARACTER,
  ICON_SIZE,
  MAX_SURVEY_RULES_FILE_SIZE_IN_BYTES,
  MIN_FILE_SIZE_IN_BYTES,
} from "@pulse/shared-components";
import { getUploadErrorDescriptions } from "../../utils/FileUploadUtils";
import { TFunction } from "i18next";
import {
  AlertCircle,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { SurveyRulesUploadStore } from "../store/SurveyRulesUploadStore";
import { observer } from "mobx-react";
import { UploadValidationLoader } from "../../../components/fileUpload/UploadValidationLoader";
import { FileUploadedDescription } from "../../../components/fileUpload/FileUploadedDescription";
import { getIconProps } from "@pulse/shared-components";

export interface SurveyRulesUploadPaneProps {
  surveyRulesUploadStore: Instance<typeof SurveyRulesUploadStore>;
  navigateTo: NavigateToFunctions;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

export const SurveyRulesUploadPane = observer(
  ({
    surveyRulesUploadStore,
    navigateTo,
    t,
    spacing,
    typography,
    tokens,
  }: SurveyRulesUploadPaneProps): React.ReactElement => {
    const iconProps = getIconProps(tokens.iconError, ICON_SIZE.default);

    const getUploadErrors = (): React.ReactElement => {
      let errorDescription: string = EMPTY_CHARACTER;
      if (surveyRulesUploadStore.validationErrors) {
        errorDescription = getUploadErrorDescriptions(
          surveyRulesUploadStore.validationErrors,
          t,
          MAX_SURVEY_RULES_FILE_SIZE_IN_BYTES,
          MIN_FILE_SIZE_IN_BYTES,
        );
      } else if (surveyRulesUploadStore.rpcError) {
        switch (surveyRulesUploadStore.rpcError) {
          default: {
            errorDescription = t(
              "common.uploadDialog.unexpectedErrorDescription",
            );
          }
        }
      }

      return (
        <Stack spacing={spacing.spaceXXS}>
          <Typography sx={{ ...typography.b2, color: tokens.label }}>
            {surveyRulesUploadStore.fileName}
          </Typography>
          <Stack direction="row" gap={spacing.spaceXXS}>
            <AlertCircle {...iconProps} />
            <Typography sx={{ ...typography.b2, color: tokens.labelError }}>
              {errorDescription}
            </Typography>
          </Stack>
        </Stack>
      );
    };

    const fileUploadHandler = (file: File): Promise<void> => {
      surveyRulesUploadStore.setFileName(file.name);
      processSurveyParams(async (surveyId, projectId) => {
        await surveyRulesUploadStore.uploadCSVFile(file, surveyId, projectId);
      }, navigateTo);
      return Promise.resolve();
    };

    if (surveyRulesUploadStore.isRPCLoading) {
      return (
        <UploadValidationLoader
          fileName={surveyRulesUploadStore.fileName}
          t={t}
          tokens={tokens}
          typography={typography}
          spacing={spacing}
        />
      );
    } else if (
      surveyRulesUploadStore.validationErrors ??
      surveyRulesUploadStore.rpcError
    ) {
      if (surveyRulesUploadStore.isFileInvalid) {
        return (
          <Stack spacing={spacing.spaceMD}>
            <FileUploadComponent fileUploadHandler={fileUploadHandler} />
            <Stack gap={spacing.spaceXXS}>
              <Typography sx={{ ...typography.s2, color: tokens.label }}>
                {t("common.uploadDialog.errorsFoundText")}
              </Typography>
              <Typography
                sx={{
                  ...typography.b2,
                  color: tokens.labelError,
                  whiteSpace: "pre-line",
                }}
              >
                {surveyRulesUploadStore.invalidFileDetails}
              </Typography>
            </Stack>
          </Stack>
        );
      }
      return (
        <Stack spacing={spacing.spaceMD}>
          <FileUploadComponent fileUploadHandler={fileUploadHandler} />
          {getUploadErrors()}
        </Stack>
      );
    } else {
      if (surveyRulesUploadStore.hasFileUploaded) {
        return (
          <FileUploadedDescription
            fileName={surveyRulesUploadStore.fileName}
            t={t}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
          />
        );
      } else {
        return <FileUploadComponent fileUploadHandler={fileUploadHandler} />;
      }
    }
  },
);
