import { Stack, Typography } from "@mui/material";
import {
  ICON_SIZE,
  SINGLE_SPACING,
  getIconProps,
} from "@pulse/shared-components";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Check } from "lucide-react";
import { observer } from "mobx-react";
import React from "react";
import { NestedTypographyComponent } from "../NestedTypographyComponent";

interface AssignRespondentSuccessProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  t: TFunction;
  respondentCount: number;
  surveyName: string;
}

export const AssignRespondentSuccess = observer(
  ({
    spacing,
    tokens,
    typography,
    t,
    respondentCount,
    surveyName,
  }: AssignRespondentSuccessProps): React.ReactElement => {
    return (
      <Stack
        padding={spacing.spaceXL}
        gap={spacing.spaceXS}
        alignItems="center"
        textAlign="center"
      >
        <Check {...getIconProps(tokens.iconSuccess, ICON_SIZE.large)} />
        <Typography
          sx={{
            ...typography.s1,
            color: tokens.labelSuccess,
            width: "472px",
          }}
        >
          {t("projects.assignToSurvey.respondentCount", { respondentCount })}
          {SINGLE_SPACING}
          <NestedTypographyComponent
            textColor={tokens.labelSuccess}
            startText={t("projects.assignToSurvey.dialogSuccessText")}
            middleBoldText={t("projects.assignToSurvey.surveyName", {
              surveyName,
            })}
            endText={t("projects.assignToSurvey.successfullyText")}
            startAndEndTextTypography={typography.b1}
            boldTextTypography={typography.s1}
            isSpan={true}
          />
        </Typography>
      </Stack>
    );
  },
);
