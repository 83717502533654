import { observer } from "mobx-react";
import React from "react";
import { Instance } from "mobx-state-tree";
import { RadioProps, Stack } from "@mui/material";
import {
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";
import { ReportStore } from "../../store/ReportStore";
import { DownloadReportsChooseSurveyComponent } from "./DownloadReportsChooseSurveyComponent";
import { DownloadReportsPreferences } from "./DownloadReportsPreferences";

interface DownloadReportsDialogOptionsProps {
  projectStore: Instance<typeof ProjectDetailsStore>;
  reportStore: Instance<typeof ReportStore>;
  typography: LeoTypography;
  spacing: Spacing;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  radioProps: RadioProps;
}

export const DownloadReportsDialogOptions = observer(
  ({
    projectStore,
    reportStore,
    typography,
    spacing,
    t,
    tokens,
    radioProps,
  }: DownloadReportsDialogOptionsProps): React.ReactElement => {
    return (
      <Stack gap={spacing.spaceLG} width="100%" padding={spacing.spaceLG}>
        <DownloadReportsChooseSurveyComponent
          projectStore={projectStore}
          reportStore={reportStore}
          typography={typography}
          spacing={spacing}
          t={t}
          tokens={tokens}
        />
        <DownloadReportsPreferences
          reportStore={reportStore}
          typography={typography}
          spacing={spacing}
          t={t}
          tokens={tokens}
          radioProps={radioProps}
        />
      </Stack>
    );
  },
);
