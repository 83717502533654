import React from "react";
import { useTypography } from "@surya-digital/leo-reactjs-material-ui";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const QuestionOptionsTooltipDescription = (): React.ReactNode => {
  const typography = useTypography();
  const { t } = useTranslation();

  const B3Typography = ({ text }: { text: string }): React.ReactElement => {
    return <Typography {...typography.b3}>{text}</Typography>;
  };

  return (
    <Stack>
      <B3Typography
        text={t(
          "surveys.addSurveyQuestion.markdownTooltip.questionOptionsDescription",
        )}
      />
      <B3Typography
        text={t("surveys.addSurveyQuestion.markdownTooltip.italicsDescription")}
      />
      <B3Typography
        text={t(
          "surveys.addSurveyQuestion.markdownTooltip.strikethroughDescription",
        )}
      />
      <B3Typography
        text={t("surveys.addSurveyQuestion.markdownTooltip.boldDescription")}
      />
      <B3Typography
        text={t("surveys.addSurveyQuestion.markdownTooltip.escapeMarkdown")}
      />
    </Stack>
  );
};
