import { Stack, Typography } from "@mui/material";
import {
  ICON_SIZE,
  getEllipsizedTextProperties,
  getIconProps,
} from "@pulse/shared-components";
import {
  Password,
  HidePassword,
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { GitForkIcon } from "lucide-react";
import {
  getQuestionTypeIcon,
  getQuestionTypeTypography,
} from "../../../utils/QuestionTypeIconAndTypographyUtils";
import { DividerComponent } from "../commonQuestionConfigurationDetailsComponents/DividerComponent";
import { QuestionItemProps } from "./QuestionItem";
import { TFunction } from "i18next";

export interface QuestionItemFooterProps {
  questionItem: QuestionItemProps;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  t: TFunction;
  spacing: Spacing;
}

export const QuestionItemFooter = ({
  questionItem,
  tokens,
  typography,
  t,
  spacing,
}: QuestionItemFooterProps): React.ReactElement => {
  const defaultIconProps = {
    ...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.default),
  };

  const IsRuleAppliedIcon = (): React.ReactElement => {
    return (
      <Stack direction="row" gap={spacing.spaceXXS} alignItems="center">
        <GitForkIcon {...defaultIconProps} />
      </Stack>
    );
  };

  const IsVisibleByDefaultIcon = (): React.ReactElement => {
    return (
      <Stack>
        {questionItem.isVisibleByDefault ? (
          <Password {...defaultIconProps} />
        ) : (
          <HidePassword {...defaultIconProps} />
        )}
      </Stack>
    );
  };

  return (
    <Stack
      direction={"row"}
      divider={<DividerComponent height="12px" orientation="vertical" />}
      height="20px"
      alignItems="center"
      gap={spacing.spaceXS}
    >
      <Stack direction="row" gap={spacing.spaceXXS}>
        {getQuestionTypeIcon(questionItem.questionType, tokens.iconSubtle)}
        <Typography
          sx={{
            ...typography.b2,
            color: tokens.labelSubtle,
            ...getEllipsizedTextProperties(),
          }}
        >
          {getQuestionTypeTypography(questionItem.questionType, t)}
        </Typography>
      </Stack>
      {questionItem.isRuleApplied && <IsRuleAppliedIcon />}
      <IsVisibleByDefaultIcon />
    </Stack>
  );
};
