import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { SetStateAction } from "react";
import { HelpCircle } from "lucide-react";
import {
  Dialog,
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { ErrorSuccessDialogContent } from "../ErrorSuccessDialogContent";
import {
  DownloadRespondentCSVDialogState,
  GetPresignedDownloadURLForRespondentsWithFiltersErrors,
} from "../../store/RespondentDownloadStore";
import { Stack, Typography } from "@mui/material";
import { ICON_SIZE, getIconProps } from "@pulse/shared-components";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";

interface DownloadRespondentCSVDialogProps {
  isDownloadRespondentCSVDialogVisible: boolean;
  setIsDownloadRespondentCSVDialogVisible: React.Dispatch<
    SetStateAction<boolean>
  >;
  t: TFunction;
  projectStore: Instance<typeof ProjectDetailsStore>;
  navigateTo: NavigateToFunctions;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  spacing: Spacing;
}

export const DownloadRespondentCSVDialog = observer(
  ({
    isDownloadRespondentCSVDialogVisible,
    setIsDownloadRespondentCSVDialogVisible,
    t,
    projectStore,
    navigateTo,
    tokens,
    typography,
    spacing,
  }: DownloadRespondentCSVDialogProps): React.ReactElement => {
    const downloadCSVText = t("projects.respondentList.downloadCSV");

    const getErrorText = (): string => {
      switch (projectStore.respondentStore.respondentDownloadStore.rpcErrors) {
        case GetPresignedDownloadURLForRespondentsWithFiltersErrors.DeletedColumnFilter:
          return t("common.deletedColumnFilterErrorText");
        default:
          return t("projects.downloadRespondentCSV.downloadErrorText");
      }
    };

    const DownloadRespondentCSVDialogContent = observer(
      (): React.ReactElement => {
        switch (
          projectStore.respondentStore.respondentDownloadStore
            .downloadRespondentCSVDialogState
        ) {
          case DownloadRespondentCSVDialogState.DownloadConfirmation:
            return (
              <Stack gap={spacing.spaceXS} direction="row">
                <HelpCircle {...getIconProps(tokens.icon, ICON_SIZE.default)} />
                <Typography
                  sx={{ ...typography.b2 }}
                  color={tokens.label}
                  width={"492px"}
                >
                  {t("projects.downloadRespondentCSV.dialogDescriptionText")}
                </Typography>
              </Stack>
            );
          case DownloadRespondentCSVDialogState.SuccessState:
            return (
              <ErrorSuccessDialogContent
                spacing={spacing}
                tokens={tokens}
                typography={typography}
                errorSuccessText={t(
                  "projects.downloadRespondentCSV.downloadSuccessText",
                )}
                isError={false}
              />
            );

          case DownloadRespondentCSVDialogState.ErrorState:
            return (
              <ErrorSuccessDialogContent
                spacing={spacing}
                tokens={tokens}
                typography={typography}
                isError={true}
                errorSuccessText={getErrorText()}
              />
            );
        }
      },
    );

    const getSecondaryButtonText = (): string => {
      switch (
        projectStore.respondentStore.respondentDownloadStore
          .downloadRespondentCSVDialogState
      ) {
        case DownloadRespondentCSVDialogState.DownloadConfirmation:
          return t("common.cancel");
        case DownloadRespondentCSVDialogState.SuccessState:
          return t("common.done");
        case DownloadRespondentCSVDialogState.ErrorState:
          return t("common.close");
      }
    };

    return (
      <Dialog
        open={isDownloadRespondentCSVDialogVisible}
        title={downloadCSVText}
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsDownloadRespondentCSVDialogVisible(false);
          projectStore.respondentStore.respondentDownloadStore.resetRespondentCSVDialog();
        }}
        primaryButtonText={
          projectStore.respondentStore.respondentDownloadStore
            .isDownloadRespondentsCSVPrimaryButtonVisible
            ? downloadCSVText
            : undefined
        }
        primaryButtonColor="primary"
        onPrimaryButtonClick={async () => {
          await processProjectParams((projectId) => {
            return projectStore.respondentStore.getPresignedDownloadURLForRespondents(
              projectId,
              projectStore.projectName,
            );
          }, navigateTo);
        }}
        isSecondaryButtonDisabled={
          projectStore.respondentStore.respondentDownloadStore.isRPCLoading
        }
        isPrimaryButtonDisabled={
          projectStore.respondentStore.respondentDownloadStore.isRPCLoading
        }
        disableBackdropClick
      >
        <DownloadRespondentCSVDialogContent />
      </Dialog>
    );
  },
);
