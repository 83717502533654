import { Instance, getParentOfType, types } from "mobx-state-tree";
import {
  SurveySettingsModel,
  createSurveySettingsModel,
  SurveySettingsDialogModel,
  createSurveySettingsDialogModel,
  FontSizeScale,
} from "@pulse/shared-components";
import { BackOfficeSurveySettings, SurveyTheme } from "@pulse/pulse-rpcs";
import {
  BackOfficeSurveySettingsUIStore,
  createBackOfficeSurveySettingsUIStore,
} from "../store/BackOfficeSurveySettingsUIStore";
import { BackOfficeSurveySettingsStore } from "../store/BackOfficeSurveySettingsStore";
import {
  createRTEFileServiceModel,
  RTEFileServiceModel,
} from "../../projects/models/RTEFileServiceModel";

export const BackOfficeSurveySettingsModel = types
  .model({
    surveySettings: SurveySettingsModel,
    surveyName: types.string,
    selectedSurveyTheme: types.enumeration(
      Object.values(SurveyTheme.SurveyTheme),
    ),
    isSubmitConfirmationRequired: types.boolean,
    submitConfirmationDialogProperties: SurveySettingsDialogModel,
    isRestartAllowed: types.boolean,
    restartSurveyDialogProperties: SurveySettingsDialogModel,
    isSaveDraftAllowed: types.boolean,
    saveDraftDialogProperties: SurveySettingsDialogModel,
    isInfoButtonShown: types.boolean,
    surveyInformationDialogProperties: SurveySettingsDialogModel,
    isWelcomePageShown: types.boolean,
    welcomePageText: types.string,
    thankYouPageText: types.string,
    closedSurveyErrorTextTitle: types.string,
    closedSurveyErrorTextDescription: types.string,
    surveyClosedMessageTitleText: types.string,
    surveyClosedMessageDescriptionText: types.string,
    backOfficeSurveySettingsUIStore: BackOfficeSurveySettingsUIStore,
    clientLightThemeLogoId: types.string,
    clientDarkThemeLogoId: types.string,
    infoDialogDescriptionTextRTEFileService: RTEFileServiceModel,
    welcomePageTextRTEFileService: RTEFileServiceModel,
    thankYouPageTextRTEFileService: RTEFileServiceModel,
  })
  .actions((store) => ({
    setLightThemeLogoId(lightThemeLogoId: string): void {
      store.clientLightThemeLogoId = lightThemeLogoId;
    },
    setDarkThemeLogoId(darkThemeLogoId: string): void {
      store.clientDarkThemeLogoId = darkThemeLogoId;
    },
    setSelectedLanguageCode(selectedLanguageCode: string): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setSelectedLanguageCode(selectedLanguageCode);
    },
    setIsLanguageSelectionOptionPersistent(
      isLanguageSelectionOptionPersistent: boolean,
    ): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setIsLanguageSelectionOptionPersistent(
        isLanguageSelectionOptionPersistent,
      );
    },
    setIsLanguageSelectionDisplayedOnStart(
      isLanguageSelectionDisplayedOnStart: boolean,
    ): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setIsLanguageSelectionDisplayedOnStart(
        isLanguageSelectionDisplayedOnStart,
      );
    },
    setIsAutoProgressEnabled(isAutoProgressEnabled: boolean): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setIsAutoProgressEnabled(isAutoProgressEnabled);
    },
    setIsProgressPercentageShown(isProgressPercentageShown: boolean): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setIsProgressPercentageShown(
        isProgressPercentageShown,
      );
    },
    setIsIpTracked(doNotTrackIp: boolean): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setIsIpTracked(doNotTrackIp);
    },
    setIsRespondentConsentRequired(isRespondentConsentRequired: boolean): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setIsRespondentConsentRequired(
        isRespondentConsentRequired,
      );
    },
    setSelectedFont(font: string): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setSelectedFont(font);
    },
    setSelectedFontSize(fontSizeScale: FontSizeScale): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setSelectedFontSize(fontSizeScale);
    },
    setStartButtonText(startButtonText: string): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setStartButtonText(startButtonText);
    },
    setGenericErrorMessageTitleText(
      genericErrorMessageTitleText: string,
    ): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setGenericErrorMessageTitleText(
        genericErrorMessageTitleText,
      );
    },
    setGenericErrorMessageDescriptionText(
      genericErrorMessageDescriptionText: string,
    ): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setGenericErrorMessageDescriptionText(
        genericErrorMessageDescriptionText,
      );
    },
    setContinueButtonText(continueButtonText: string): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setContinueButtonText(continueButtonText);
    },
    setNextButtonText(nextButtonText: string): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setNextButtonText(nextButtonText);
    },
    setBackButtonText(backButtonText: string): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveySettings.setBackButtonText(backButtonText);
    },
    setSurveyName(surveyName: string): void {
      getParentOfType(store, BackOfficeSurveySettingsStore).clearRPCError();
      store.surveyName = surveyName;
    },
    setSelectedSurveyTheme(surveyTheme: SurveyTheme.SurveyTheme): void {
      store.selectedSurveyTheme = surveyTheme;
    },
    setIsSubmitConfirmationRequired(
      isSubmitConfirmationRequired: boolean,
    ): void {
      store.isSubmitConfirmationRequired = isSubmitConfirmationRequired;
    },
    setIsRestartAllowed(isRestartAllowed: boolean): void {
      store.isRestartAllowed = isRestartAllowed;
    },
    setIsSaveDraftAllowed(isSaveDraftAllowed: boolean): void {
      store.isSaveDraftAllowed = isSaveDraftAllowed;
    },
    setIsInfoButtonShown(isInfoButtonShown: boolean): void {
      store.isInfoButtonShown = isInfoButtonShown;
    },
    setSurveyClosedMessageTitleText(
      surveyClosedMessageTitleText: string,
    ): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveyClosedMessageTitleText = surveyClosedMessageTitleText;
    },
    setSurveyClosedMessageDescriptionText(
      surveyClosedMessageDescriptionText: string,
    ): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.surveyClosedMessageDescriptionText =
        surveyClosedMessageDescriptionText;
    },
    setIsWelcomePageShown(isWelcomePageShown: boolean): void {
      store.isWelcomePageShown = isWelcomePageShown;
    },
    setWelcomePageText(welcomePageText: string): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.welcomePageText = welcomePageText;
    },
    setThankYouPageText(thankYouPageText: string): void {
      store.backOfficeSurveySettingsUIStore.clearError();
      store.thankYouPageText = thankYouPageText;
    },
    setIsRatingScaleOptionVisible(isRatingScaleOptionVisible: boolean): void {
      store.surveySettings.isRatingScaleOptionVisible =
        isRatingScaleOptionVisible;
    },
  }));

export const createBackOfficeSurveySettingsModel = (
  backOfficeSurveySettings: BackOfficeSurveySettings,
): Instance<typeof BackOfficeSurveySettingsModel> => {
  return BackOfficeSurveySettingsModel.create({
    surveySettings: createSurveySettingsModel(
      backOfficeSurveySettings.surveySettings,
    ),
    backOfficeSurveySettingsUIStore: createBackOfficeSurveySettingsUIStore(),
    isInfoButtonShown: backOfficeSurveySettings.isInfoButtonShown,
    isRestartAllowed: backOfficeSurveySettings.isRestartAllowed,
    isSaveDraftAllowed: backOfficeSurveySettings.isSaveDraftAllowed,
    isSubmitConfirmationRequired:
      backOfficeSurveySettings.isSubmitConfirmationRequired,
    isWelcomePageShown: backOfficeSurveySettings.isWelcomePageShown,
    closedSurveyErrorTextDescription:
      backOfficeSurveySettings.closedSurveyErrorTextDescription.text,
    closedSurveyErrorTextTitle:
      backOfficeSurveySettings.closedSurveyErrorTextTitle.text,
    restartSurveyDialogProperties: createSurveySettingsDialogModel(
      backOfficeSurveySettings.restartSurveyDialogProperties,
    ),
    saveDraftDialogProperties: createSurveySettingsDialogModel(
      backOfficeSurveySettings.saveDraftDialogProperties,
    ),
    selectedSurveyTheme: backOfficeSurveySettings.selectedSurveyTheme,
    submitConfirmationDialogProperties: createSurveySettingsDialogModel(
      backOfficeSurveySettings.submitConfirmationDialogProperties,
    ),
    surveyClosedMessageDescriptionText:
      backOfficeSurveySettings.closedSurveyErrorTextDescription.text,
    surveyClosedMessageTitleText:
      backOfficeSurveySettings.closedSurveyErrorTextTitle.text,
    surveyInformationDialogProperties: createSurveySettingsDialogModel(
      backOfficeSurveySettings.surveyInformationDialogProperties,
    ),
    surveyName: backOfficeSurveySettings.surveyName.name,
    thankYouPageText: backOfficeSurveySettings.thankYouPageText.text,
    welcomePageText: backOfficeSurveySettings.welcomePageText.text,
    clientDarkThemeLogoId:
      backOfficeSurveySettings.clientDarkThemeLogoDocumentId.uuid,
    clientLightThemeLogoId:
      backOfficeSurveySettings.clientLightThemeLogoDocumentId.uuid,
    infoDialogDescriptionTextRTEFileService: createRTEFileServiceModel(),
    thankYouPageTextRTEFileService: createRTEFileServiceModel(),
    welcomePageTextRTEFileService: createRTEFileServiceModel(),
  });
};
