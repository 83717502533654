import { Stack, Tooltip, Typography } from "@mui/material";
import {
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuestionDetailsStore } from "../../store/hooks";
import { Checkbox } from "@surya-digital/leo-reactjs-material-ui";
import {
  ICON_SIZE,
  getCheckBoxState,
  getIconProps,
} from "@pulse/shared-components";
import { InfoIcon } from "lucide-react";

export const QuestionAttributes = observer((): React.ReactElement => {
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const { t } = useTranslation();
  const questionDetailsStore = useQuestionDetailsStore();

  return (
    <Stack style={{ margin: `0px ${spacing.space2XL}`, gap: spacing.spaceXL }}>
      <Typography sx={{ ...typography.sh1, color: tokens.label }}>
        {t("surveys.addSurveyQuestion.questionAttributes")}
      </Typography>
      <Stack spacing={spacing.spaceMD}>
        {questionDetailsStore.isMandatoryQuestionCheckboxVisible && (
          <Stack direction="row" gap={spacing.spaceXS}>
            <Checkbox
              state={getCheckBoxState(
                questionDetailsStore.isParentQuestionMandatory
                  ? true
                  : questionDetailsStore.updatedQuestionDetails
                      .basicQuestionDetails.isMandatory,
              )}
              disabled={questionDetailsStore.isParentQuestionMandatory}
              label={t("surveys.addSurveyQuestion.indicateMandatory")}
              onChange={(isMandatory: boolean): void => {
                questionDetailsStore.updatedQuestionDetails.basicQuestionDetails.setIsMandatory(
                  isMandatory,
                );
              }}
            />
            {questionDetailsStore.isParentQuestionMandatory && (
              <Tooltip
                title={t(
                  "surveys.addSurveyQuestion.mandatoryQuestionTooltipText",
                )}
              >
                <InfoIcon
                  {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.medium)}
                />
              </Tooltip>
            )}
          </Stack>
        )}
        <Checkbox
          state={getCheckBoxState(
            questionDetailsStore.updatedQuestionDetails.basicQuestionDetails
              .isVisibleByDefault,
          )}
          label={t("surveys.addSurveyQuestion.makeQuestionVisible")}
          onChange={(isVisibleByDefault: boolean): void => {
            questionDetailsStore.updatedQuestionDetails.basicQuestionDetails.setIsVisibleByDefault(
              isVisibleByDefault,
            );
          }}
        />
        {questionDetailsStore.isAllOptionMandatoryCheckboxVisible && (
          <Checkbox
            state={getCheckBoxState(
              questionDetailsStore.updatedQuestionDetails.rankingQuestionV1Store
                .rankingQuestionV1Model.areAllOptionsMandatory,
            )}
            label={t("surveys.addSurveyQuestion.indicateAllOptionsMandatory")}
            onChange={(areAllOptionsMandatory: boolean): void => {
              questionDetailsStore.updatedQuestionDetails.rankingQuestionV1Store.setAreAllOptionsMandatory(
                areAllOptionsMandatory,
              );
            }}
          />
        )}
        {questionDetailsStore.isGridQuestion && (
          <Checkbox
            state={getCheckBoxState(
              questionDetailsStore.updatedQuestionDetails.gridQuestionV1Store
                .areAllRowOptionsMandatoryIfRowAttempted,
            )}
            label={t(
              "surveys.addSurveyQuestion.grid.indicateAllRowOptionsMandatory",
            )}
            onChange={(
              areAllRowOptionsMandatoryIfRowAttempted: boolean,
            ): void => {
              questionDetailsStore.updatedQuestionDetails.gridQuestionV1Store.setAreAllRowsOptionsMandatory(
                areAllRowOptionsMandatoryIfRowAttempted,
              );
            }}
          />
        )}
        {questionDetailsStore.isGridQuestion &&
          questionDetailsStore.updatedQuestionDetails
            .isResponseResetAllowed && (
            <Checkbox
              state={getCheckBoxState(
                questionDetailsStore.updatedQuestionDetails.gridQuestionV1Store
                  .isChoiceResetAllowed,
              )}
              label={t("surveys.addSurveyQuestion.allowChoiceReset")}
              onChange={
                questionDetailsStore.updatedQuestionDetails.gridQuestionV1Store
                  .setIsChoiceResetAllowed
              }
            />
          )}
        {questionDetailsStore.isSingleSelectQuestion &&
          questionDetailsStore.updatedQuestionDetails
            .isResponseResetAllowed && (
            <Checkbox
              state={getCheckBoxState(
                questionDetailsStore.updatedQuestionDetails
                  .singleChoiceQuestionV1Store.singleSelectQuestionV1Model
                  .isChoiceResetAllowed,
              )}
              label={t("surveys.addSurveyQuestion.allowChoiceReset")}
              onChange={
                questionDetailsStore.updatedQuestionDetails
                  .singleChoiceQuestionV1Store.setIsChoiceResetAllowed
              }
            />
          )}
        {questionDetailsStore.isMultiChoiceTypeQuestion &&
          questionDetailsStore.updatedQuestionDetails
            .isResponseResetAllowed && (
            <Checkbox
              state={getCheckBoxState(
                questionDetailsStore.updatedQuestionDetails
                  .multiChoiceQuestionV1Store.multiChoiceQuestionV1Model
                  .isChoiceResetAllowed,
              )}
              label={t("surveys.addSurveyQuestion.allowChoiceReset")}
              onChange={
                questionDetailsStore.updatedQuestionDetails
                  .multiChoiceQuestionV1Store.setIsChoiceResetAllowed
              }
            />
          )}
        {questionDetailsStore.isOpenEndedTypeQuestion && (
          <>
            {questionDetailsStore.updatedQuestionDetails
              .isResponseResetAllowed && (
              <Checkbox
                state={getCheckBoxState(
                  questionDetailsStore.updatedQuestionDetails
                    .openEndedFieldV1Store.isChoiceResetAllowed,
                )}
                label={t(
                  "surveys.addSurveyQuestion.allowOpenEndedResponseReset",
                )}
                onChange={
                  questionDetailsStore.updatedQuestionDetails
                    .openEndedFieldV1Store.setIsChoiceResetAllowed
                }
              />
            )}
            <Checkbox
              state={getCheckBoxState(
                questionDetailsStore.updatedQuestionDetails
                  .openEndedFieldV1Store.isMultiLineTextEntryAllowed,
              )}
              label={t("surveys.addSurveyQuestion.allowMultiLineTextEntry")}
              onChange={
                questionDetailsStore.updatedQuestionDetails
                  .openEndedFieldV1Store.setIsMultiLineTextEntryAllowed
              }
            />
          </>
        )}
      </Stack>
    </Stack>
  );
});
