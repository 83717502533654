import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { LoadingIndicator } from "@surya-digital/leo-reactjs-material-ui";
import { useSignInStore } from "../store/hook";
import { SignInError } from "../store/SignInStore";
import { useRouteNavigator } from "../../../routes/RoutesHelper";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { navigateOrGetNetworkErrorPage } from "../../../utility/navigateOrGetNetworkErrorPage";
import { useRootStore } from "../../root/store/RootStore";
import { useKeycloak } from "keycloak-react-web";

export const SignInPage = observer((): React.ReactElement => {
  const { initialized, keycloak } = useKeycloak();
  const navigateTo = useRouteNavigator();
  const signInStore = useSignInStore();
  const errorStore = useNetworkingErrorStore();
  const rootStore = useRootStore();

  const handleSignInError = (): void => {
    switch (signInStore.error) {
      case SignInError.InvalidAccessToken:
        console.error("Keycloak access token not present");
        navigateTo.pageNotFound();
        break;
      case SignInError.KeycloakNotFound:
        console.error("Keycloak not present");
        navigateTo.pageNotFound();
        break;
    }
  };

  const errorPage = navigateOrGetNetworkErrorPage(
    errorStore.error,
    navigateTo,
    errorStore.removeError,
  );

  const logOutUser = useCallback(async (): Promise<void> => {
    await keycloak.logout({
      redirectUri: `${window.location.protocol}//${window.location.host}`,
    });
  }, []);

  if (errorPage) {
    if (errorStore.isAuthError) {
      errorStore.removeError();
      rootStore.signOutUser();
      logOutUser();
    } else {
      errorStore.removeError();
      return errorPage;
    }
  }

  const signInUser = async (): Promise<void> => {
    await signInStore.signInUser();
    if (signInStore.error) {
      handleSignInError();
    } else {
      navigateTo.root();
    }
  };

  useMemo(async (): Promise<void> => {
    if (initialized && keycloak?.authenticated === true) {
      await signInUser();
    } else if (initialized && keycloak?.authenticated === false) {
      if (keycloak) {
        await keycloak?.login();
      }
    }
  }, [keycloak?.authenticated === true, initialized]);

  return (
    <>
      <LoadingIndicator
        isLoading={initialized === false && keycloak?.authenticated === false}
      />
    </>
  );
});
