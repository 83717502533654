import { Stack } from "@mui/material";
import { Instance } from "mobx-state-tree";
import React from "react";
import {
  CornerRadius,
  Shadow,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { DividerComponent } from "../commonQuestionConfigurationDetailsComponents/DividerComponent";
import { QuestionnaireListItem } from "../QuestionnaireListItem";
import { observer } from "mobx-react";
import { ManageQuestionsStore } from "../../store/ManageQuestionsStore";
import { MAX_GROUP_CHILD_QUESTIONS } from "@pulse/shared-components";

interface ManageQuestionsDialogChildProps {
  manageQuestionsStore: Instance<typeof ManageQuestionsStore>;
  cornerRadius: CornerRadius;
  shadow: Shadow;
  spacing: Spacing;
}

export const ManageQuestionsDialogChild = observer(
  ({
    manageQuestionsStore,
    cornerRadius,
    shadow,
    spacing,
  }: ManageQuestionsDialogChildProps): React.ReactElement => {
    return (
      <Stack
        width="936px"
        borderRadius={cornerRadius.radiusXS}
        divider={<DividerComponent width="100%" orientation="horizontal" />}
      >
        {manageQuestionsStore.manageQuestionsList.map((question, index) => {
          if (
            question.surveyQuestionDetails.isGroupQuestion &&
            question.surveyQuestionDetails.childQuestionIds.length <
              MAX_GROUP_CHILD_QUESTIONS
          ) {
            manageQuestionsStore.setDoesGroupQuestionExistInManageQuestionsList(
              true,
            );
          }
          return (
            !manageQuestionsStore.isChildQuestionInManageQuestionList(
              question.surveyQuestionDetails.questionId,
            ) && (
              <Stack
                key={index}
                flexDirection={"row"}
                justifyContent="space-between"
                padding={spacing.spaceLG}
              >
                <QuestionnaireListItem
                  question={question.surveyQuestionDetails.question}
                  questionCode={question.surveyQuestionDetails.questionCode}
                  questionType={question.surveyQuestionDetails.questionType}
                  order={question.surveyQuestionDetails.order}
                  isRuleApplied={question.isRuleApplied}
                  questionId={question.surveyQuestionDetails.questionId}
                  isVisibleByDefault={question.isVisibleByDefault}
                  childQuestions={question.surveyQuestionDetails.childQuestionIds.map(
                    (childQuestionId) =>
                      manageQuestionsStore.findQuestionById(childQuestionId),
                  )}
                  shadow={shadow}
                  manageQuestionsStore={manageQuestionsStore}
                  questionDetails={question}
                  questionIndex={index}
                />
              </Stack>
            )
          );
        })}
      </Stack>
    );
  },
);
