import { Stack } from "@mui/material";
import {
  DATE_FORMAT,
  MIN_USERNAME_CHAR_LIMIT,
  MAX_USERNAME_CHAR_LIMIT,
  FULL_WIDTH,
} from "@pulse/shared-components";
import {
  Spacing,
  DatePickerInput,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  TextInputField,
  DatePickerField,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import {
  AddNewSPoCStore,
  ExpiryDateFieldError,
} from "../../store/AddNewSPoCStore";

interface AddNewSPoCChildProps {
  addNewSPoCStoreProp: Instance<typeof AddNewSPoCStore>;
  t: TFunction;
  spacing: Spacing;
}

export const AddNewSPoCChild = observer(
  ({
    addNewSPoCStoreProp,
    spacing,
    t,
  }: AddNewSPoCChildProps): React.ReactElement => {
    const getExpiryDateFieldHelperText = (): string | undefined => {
      switch (addNewSPoCStoreProp.expiryDateFieldError) {
        case ExpiryDateFieldError.InvalidDate: {
          return t("projects.userAccess.addNewSPoC.invalidDateErrorHelperText");
        }
        case ExpiryDateFieldError.InvalidExpiryDate: {
          return t(
            "projects.userAccess.addNewSPoC.invalidExpiryDateErrorHelperText",
          );
        }
      }
    };

    return (
      <Stack width={FULL_WIDTH} gap={spacing.spaceLG}>
        <TextInputField
          name={"emailAddress"}
          value={addNewSPoCStoreProp.emailAddress}
          type={"text"}
          onTextChange={(emailAddress: string): void => {
            addNewSPoCStoreProp.setEmailAddress(emailAddress);
          }}
          placeholder={t("common.enterEmailAddressPlaceHolder")}
          disabled={addNewSPoCStoreProp.isRPCLoading}
          label={t("common.enterEmailAddressLabel")}
          required={true}
          error={addNewSPoCStoreProp.isEmailAddressInvalid}
          helperText={
            addNewSPoCStoreProp.isEmailAddressInvalid
              ? t("common.invalidEmailAddressHelperText")
              : undefined
          }
        />
        <Stack direction="row" width={FULL_WIDTH} gap={spacing.spaceLG}>
          <DatePickerField
            label={t("projects.userAccess.addNewSPoC.expiryDateLabel")}
            value={addNewSPoCStoreProp.datePickerInput}
            onChange={(value: DatePickerInput): void => {
              addNewSPoCStoreProp.setExpiryDate(value);
            }}
            onError={(error): void => {
              error
                ? addNewSPoCStoreProp.setExpiryDateFieldError(
                    ExpiryDateFieldError.InvalidDate,
                  )
                : addNewSPoCStoreProp.setExpiryDateFieldError(null);
            }}
            placeholder={t(
              "projects.userAccess.addNewSPoC.expiryDatePlaceholder",
            )}
            required={true}
            disabled={addNewSPoCStoreProp.isRPCLoading}
            format={DATE_FORMAT}
            error={addNewSPoCStoreProp.isExpiryDateFieldErrored}
            helperText={getExpiryDateFieldHelperText()}
            disableFuture={false}
            minDate={new Date()}
          />
          {/*
              An empty <Stack /> is added below to maintain the design,
              as shown here: https://www.figma.com/design/USl8dEzHVxs10KzHoEx56w/Pulse-Revamp?node-id=10325-234058&t=HhqLGVLv8NkgLLb0-4
          */}
          <Stack width={FULL_WIDTH} />
        </Stack>
        <Stack direction="row" width={FULL_WIDTH} gap={spacing.spaceLG}>
          <TextInputField
            name={"firstName"}
            value={addNewSPoCStoreProp.firstName}
            type={"text"}
            onTextChange={(firstName: string): void => {
              addNewSPoCStoreProp.setFirstName(firstName);
            }}
            placeholder={t("common.firstNamePlaceholder")}
            label={t("common.firstNameLabel")}
            disabled={addNewSPoCStoreProp.isRPCLoading}
            required={true}
            error={addNewSPoCStoreProp.isFirstNameInvalid}
            helperText={
              addNewSPoCStoreProp.isFirstNameInvalid
                ? t("common.invalidFirstNameLengthHelperText", {
                    minFirstNameLength: MIN_USERNAME_CHAR_LIMIT,
                    maxFirstNameLength: MAX_USERNAME_CHAR_LIMIT,
                  })
                : undefined
            }
          />
          <TextInputField
            name={"lastName"}
            value={addNewSPoCStoreProp.lastName}
            type={"text"}
            onTextChange={function (lastName: string): void {
              addNewSPoCStoreProp.setLastName(lastName);
            }}
            placeholder={t("common.lastNamePlaceholder")}
            label={t("common.lastNameLabel")}
            disabled={addNewSPoCStoreProp.isRPCLoading}
            required={true}
            error={addNewSPoCStoreProp.isLastNameInvalid}
            helperText={
              addNewSPoCStoreProp.isLastNameInvalid
                ? t("common.invalidLastNameLengthHelperText", {
                    minLastNameLength: MIN_USERNAME_CHAR_LIMIT,
                    maxLastNameLength: MAX_USERNAME_CHAR_LIMIT,
                  })
                : undefined
            }
          />
        </Stack>
      </Stack>
    );
  },
);
