import { Instance } from "mobx-state-tree";
import React, { useEffect, useState } from "react";
import {
  ConfirmationDialogState,
  UserAccessStore,
} from "../../../store/UserAccessStore";
import { Stack, Typography } from "@mui/material";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  InformationDialogComponent,
  MAX_USERNAME_CHAR_LIMIT,
  MIN_USERNAME_CHAR_LIMIT,
  SuccessfulDialogComponent,
} from "@pulse/shared-components";
import { observer } from "mobx-react";
import { TFunction } from "i18next";
import {
  Button,
  LoadingIndicator,
  TextInputField,
} from "@surya-digital/leo-reactjs-material-ui";
import { ChangePasswordDialog } from "./ChangePasswordDialog";
import {
  NavigateToFunctions,
  processSPoCParams,
} from "../../../../../routes/RoutesHelper";
import { DeleteSPoCDialog } from "./DeleteSPoCDialog";
import { RESET_PASSWORD_EMAIL_WAIT_DURATION_IN_MINUTES } from "@pulse/shared-components";

interface StyledStackProps {
  label: string;
  childComponent: React.ReactElement;
  spacing: Spacing;
  border: BorderStyle;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

const StyledStack = ({
  childComponent,
  label,
  border,
  spacing,
  tokens,
  typography,
}: StyledStackProps): React.ReactElement => {
  return (
    <Stack
      borderBottom={border.default}
      py={spacing.space2XL}
      gap={spacing.space4XL}
      direction="row"
    >
      <Typography width="360px" {...typography.s1} color={tokens.label}>
        {label}
      </Typography>
      {childComponent}
    </Stack>
  );
};

interface SPoCSettingsProps {
  userAccessStore: Instance<typeof UserAccessStore>;
  spacing: Spacing;
  border: BorderStyle;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  navigateTo: NavigateToFunctions;
}

export const SPoCSettings = observer(
  ({
    userAccessStore,
    spacing,
    border,
    t,
    tokens,
    typography,
    navigateTo,
  }: SPoCSettingsProps): React.ReactElement => {
    useEffect(() => {
      userAccessStore.setupUpdatedSelectedSPoCDetails();
    }, []);

    const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] =
      useState(false);
    const [isDeleteSPoCDialogOpen, setIsDeleteSPoCDialogOpen] = useState(false);

    const getSuccessfulConfirmationDialogTitle = (): string => {
      switch (userAccessStore.confirmationDialogState) {
        case ConfirmationDialogState.ChangedSPoCNameSuccessfully: {
          return t("common.saveChanges");
        }
        case ConfirmationDialogState.ResetPasswordSuccessfully: {
          return t("projects.spocRespondent.settings.resetPassword");
        }
        default: {
          return EMPTY_CHARACTER;
        }
      }
    };

    const getErrorConfirmationDialogTitle = (): string => {
      switch (userAccessStore.confirmationDialogState) {
        case ConfirmationDialogState.ChangeSpocNameProjectAlreadyArchived: {
          return t("common.failedToSaveChanges");
        }
        default: {
          return t("projects.spocRespondent.settings.resetPassword");
        }
      }
    };

    const getSuccessfulConfirmationDialogDescription = (): string => {
      switch (userAccessStore.confirmationDialogState) {
        case ConfirmationDialogState.ChangedSPoCNameSuccessfully: {
          return t(
            "projects.spocRespondent.settings.changesSavedSuccessfullyDescription",
          );
        }
        case ConfirmationDialogState.ResetPasswordSuccessfully: {
          return t("projects.spocRespondent.settings.authEmailSent");
        }
        default: {
          return EMPTY_CHARACTER;
        }
      }
    };

    const getErrorConfirmationDialogDescription = (): string => {
      switch (userAccessStore.confirmationDialogState) {
        case ConfirmationDialogState.ChangeSpocNameProjectAlreadyArchived: {
          return t("common.projectAlreadyArchivedErrorText");
        }
        case ConfirmationDialogState.UnexpectedError: {
          return t("errors.internalServerErrorDescription");
        }
        case ConfirmationDialogState.EmailAlreadySent: {
          return t("errors.resetPasswordEmailAlreadySent", {
            resetEmailWaitDurationInMinutes:
              RESET_PASSWORD_EMAIL_WAIT_DURATION_IN_MINUTES,
          });
        }
        case ConfirmationDialogState.ResetPasswordLimitReached: {
          return t("errors.resetPasswordLimitReached");
        }
        default: {
          return EMPTY_CHARACTER;
        }
      }
    };

    return (
      <Stack width="100%" px={spacing.space2XL}>
        <LoadingIndicator isLoading={userAccessStore.isRPCLoading} />
        <ChangePasswordDialog
          isChangePasswordDialogOpen={isChangePasswordDialogOpen}
          setIsChangePasswordDialogOpen={setIsChangePasswordDialogOpen}
          t={t}
          userAccessStore={userAccessStore}
          spacing={spacing}
          typography={typography}
          navigateTo={navigateTo}
          tokens={tokens}
        />
        <SuccessfulDialogComponent
          showSuccessfulDialogComponent={
            userAccessStore.isSuccessfulConfirmationDialogOpen
          }
          title={getSuccessfulConfirmationDialogTitle()}
          secondaryButtonText={t("common.done")}
          onSecondaryButtonClick={(): void => {
            userAccessStore.setIsConfirmationDialogVisible(false);
          }}
          description={getSuccessfulConfirmationDialogDescription()}
          descriptionTextWidth="520px"
        />
        <InformationDialogComponent
          isDialogOpen={userAccessStore.isErrorConfirmationDialogOpen}
          title={getErrorConfirmationDialogTitle()}
          description={getErrorConfirmationDialogDescription()}
          resolveButtonCallBack={(): void => {
            userAccessStore.setIsConfirmationDialogVisible(false);
          }}
          buttonText={t("common.close")}
          width="560px"
        />
        {isDeleteSPoCDialogOpen && (
          <DeleteSPoCDialog
            isDeleteSPoCDialogOpen={isDeleteSPoCDialogOpen}
            t={t}
            spacing={spacing}
            userAccessStore={userAccessStore}
            setIsDeleteSPoCDialogOpen={setIsDeleteSPoCDialogOpen}
            navigateTo={navigateTo}
            tokens={tokens}
            typography={typography}
            navigateOnSuccess={true}
            tableRef={undefined}
          />
        )}
        <StyledStack
          label={t("projects.spocRespondent.settings.name")}
          childComponent={
            <Stack gap={spacing.spaceLG} direction="row" width="444px">
              <TextInputField
                id={t("common.firstNameLabel")}
                name={t("common.firstNameLabel")}
                value={userAccessStore.updatedSelectedSPoCDetails?.firstName}
                type={"text"}
                onTextChange={(firstName: string): void => {
                  userAccessStore.updatedSelectedSPoCDetails?.setFirstName(
                    firstName,
                  );
                }}
                placeholder={t("common.firstNamePlaceholder")}
                label={t("common.firstNameLabel")}
                error={
                  userAccessStore.isUpdateSPoCNameErrorVisible &&
                  userAccessStore.isFirstNameInvalid
                }
                helperText={
                  userAccessStore.isUpdateSPoCNameErrorVisible &&
                  userAccessStore.isFirstNameInvalid
                    ? t("common.invalidFirstNameLengthHelperText", {
                        minFirstNameLength: MIN_USERNAME_CHAR_LIMIT,
                        maxFirstNameLength: MAX_USERNAME_CHAR_LIMIT,
                      })
                    : undefined
                }
              />
              <TextInputField
                id={t("common.lastNameLabel")}
                name={t("common.lastNameLabel")}
                value={userAccessStore.updatedSelectedSPoCDetails?.lastName}
                type={"text"}
                onTextChange={(lastName: string): void => {
                  userAccessStore.updatedSelectedSPoCDetails?.setLastName(
                    lastName,
                  );
                }}
                placeholder={t("common.lastNamePlaceholder")}
                label={t("common.lastNamePlaceholder")}
                error={
                  userAccessStore.isUpdateSPoCNameErrorVisible &&
                  userAccessStore.isLastNameInvalid
                }
                helperText={
                  userAccessStore.isUpdateSPoCNameErrorVisible &&
                  userAccessStore.isLastNameInvalid
                    ? t("common.invalidLastNameLengthHelperText", {
                        minLastNameLength: MIN_USERNAME_CHAR_LIMIT,
                        maxLastNameLength: MAX_USERNAME_CHAR_LIMIT,
                      })
                    : undefined
                }
              />
            </Stack>
          }
          spacing={spacing}
          border={border}
          typography={typography}
          tokens={tokens}
        />
        <StyledStack
          label={t("projects.spocRespondent.settings.passwordControl")}
          childComponent={
            <Stack gap={spacing.spaceLG} direction="row">
              <Button
                label={t("projects.spocRespondent.settings.resetPassword")}
                name={t("projects.spocRespondent.settings.resetPassword")}
                onClick={(): void => {
                  userAccessStore.setIsConfirmationDialogVisible(true);
                  processSPoCParams(
                    userAccessStore.resetSPoCPassword,
                    navigateTo,
                  );
                }}
                size={"medium"}
                variant={"outlined-neutral"}
              />
              <Button
                label={t("projects.spocRespondent.settings.changePassword")}
                name={t("projects.spocRespondent.settings.changePassword")}
                onClick={(): void => {
                  setIsChangePasswordDialogOpen(true);
                }}
                size={"medium"}
                variant={"outlined-neutral"}
              />
            </Stack>
          }
          spacing={spacing}
          border={border}
          typography={typography}
          tokens={tokens}
        />
        <StyledStack
          label={t("projects.spocRespondent.settings.removeSPoC")}
          childComponent={
            <Stack gap={spacing.spaceLG} direction="row">
              <Button
                label={t("projects.spocRespondent.settings.removeSPoC")}
                name={t("projects.spocRespondent.settings.removeSPoC")}
                onClick={(): void => {
                  setIsDeleteSPoCDialogOpen(true);
                }}
                size={"medium"}
                variant={"filled"}
                color={"destructive"}
              />
            </Stack>
          }
          spacing={spacing}
          border={border}
          typography={typography}
          tokens={tokens}
        />
      </Stack>
    );
  },
);
