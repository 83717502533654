import { Stack, Typography } from "@mui/material";
import { AlertCircle } from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { ValidateRespondentDialogButtonProps } from "./ValidateRespondentColumnDialog";
import { CheckIcon } from "lucide-react";
import { ValidateUniqueColumnAndUpdateRespondentErrors } from "../../store/RespondentUploadStore";
import { ICON_SIZE, getIconProps } from "@pulse/shared-components";

export const ValidationResultComponent = observer(
  ({
    respondentUploadStore,
    spacing,
    t,
    tokens,
    typography,
  }: ValidateRespondentDialogButtonProps): React.ReactElement => {
    let validationResultDialogDescription: string;
    let validationResultDialogDescriptionColor: string;
    let validationResultDialogIcon: JSX.Element;

    if (respondentUploadStore.rpcErrors) {
      validationResultDialogIcon = (
        <AlertCircle {...getIconProps(tokens.iconError, ICON_SIZE.large)} />
      );
      validationResultDialogDescriptionColor = tokens.labelError;
      switch (respondentUploadStore.rpcErrors) {
        case ValidateUniqueColumnAndUpdateRespondentErrors.InvalidFile: {
          validationResultDialogDescription = t(
            "projects.respondentList.invalidFileErrorDescription",
          );
          break;
        }
        case ValidateUniqueColumnAndUpdateRespondentErrors.InvalidUniqueColumnName: {
          validationResultDialogDescription = t(
            "projects.respondentList.invalidUniqueColumnErrorDescription",
          );
          break;
        }
        case ValidateUniqueColumnAndUpdateRespondentErrors.DuplicateRecordFound: {
          // Here, we show unexpected error since the server must not return this error at this point because:
          // - The respectUniqueColumn would be set to false.
          // - Or there were no duplicate records found at all to lead to this dialog box.
          validationResultDialogDescription = t(
            "common.uploadDialog.unexpectedErrorDescription",
          );
          break;
        }
        case ValidateUniqueColumnAndUpdateRespondentErrors.RespondentUpdateInProgress: {
          validationResultDialogDescription = t(
            "projects.respondentList.inProgressDescription",
          );
          break;
        }
        case ValidateUniqueColumnAndUpdateRespondentErrors.ProjectArchived: {
          validationResultDialogDescription = t(
            "projects.respondentList.projectArchivedDescription",
          );
          break;
        }
        case ValidateUniqueColumnAndUpdateRespondentErrors.RespondentColumnLimitReached: {
          validationResultDialogDescription = t(
            "projects.respondentList.columnLimitReachedErrorDescription",
          );
          break;
        }
        case ValidateUniqueColumnAndUpdateRespondentErrors.UnknownFile:
        default: {
          validationResultDialogDescription = t(
            "common.uploadDialog.unexpectedErrorDescription",
          );
          break;
        }
      }
    } else {
      validationResultDialogDescription = t(
        "projects.respondentList.fileUploadedSuccessfullyDescription",
      );
      validationResultDialogDescriptionColor = tokens.labelSuccess;
      validationResultDialogIcon = (
        <CheckIcon {...getIconProps(tokens.iconSuccess, ICON_SIZE.large)} />
      );
    }

    return (
      <Stack
        alignItems="center"
        spacing={spacing.spaceXS}
        padding={spacing.spaceXL}
        width="100%"
        textAlign="center"
      >
        {validationResultDialogIcon}
        <Typography
          sx={{
            ...typography.b1,
            color: validationResultDialogDescriptionColor,
          }}
        >
          {validationResultDialogDescription}
        </Typography>
      </Stack>
    );
  },
);
