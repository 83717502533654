import { Button, Stack } from "@mui/material";
import {
  useSpacing,
  IconButton,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { TopNavDropDown } from "./TopNavDropDown";
import { useSignInStore } from "../modules/store/Hooks";
import { useRouteNavigator } from "../routes/RoutesHelper";
import { UsersIcon } from "../assets/icons/UsersIcon";
import { HistoryIcon } from "lucide-react";
import { UserPrivileges } from "../modules/store/user/UserPrivileges";
import { FeedbackInsightsLogo } from "../assets/Feedback-Insights";
import { getUserFullName } from "@pulse/shared-components";
import { observer } from "mobx-react";

export interface TopNavBarProps {
  isManageUsersLogoEnabled: boolean;
  isAuditLogsLogoEnabled: boolean;
  onLogoClickCallback?: () => void;
}

/**
 * Creates a Top Nav Bar component for the Pulse project.
 * @param onLogoClickCallback - The callback that is called when the logo is clicked. If not provided, user is navigated to the projects screen.
 * @returns - TopNavBar component
 * @example
 *      <TopNavBar/>
 */

export const TopNavBar = observer(
  ({
    onLogoClickCallback,
    isManageUsersLogoEnabled = true,
    isAuditLogsLogoEnabled = true,
  }: TopNavBarProps): React.ReactElement => {
    const navigateTo = useRouteNavigator();
    const signInStore = useSignInStore();
    const spacing = useSpacing();
    const tokens = useFoundationColorTokens();

    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding={`${spacing.spaceMD} ${spacing.space2XL}`}
        width="100%"
        height="80px"
      >
        <Button
          style={{ padding: 0 }}
          onClick={() => {
            if (onLogoClickCallback) {
              onLogoClickCallback();
            } else {
              navigateTo.project();
            }
          }}
        >
          <FeedbackInsightsLogo width="102px" height="48px" />
        </Button>

        <Stack
          direction="row"
          alignItems="center"
          height="40px"
          spacing={spacing.spaceLG}
        >
          <Stack direction="row" alignItems="center" spacing={spacing.spaceSM}>
            {signInStore.isPrivilegeGranted(UserPrivileges.viewAuditLogs) && (
              <IconButton
                icon={
                  <HistoryIcon
                    color={
                      isAuditLogsLogoEnabled ? tokens.icon : tokens.iconPrimary
                    }
                  />
                }
                name="auditLogs"
                variant="plain-neutral"
                size="medium"
                onClick={() => {
                  navigateTo.auditLogs();
                }}
                disabled={!isAuditLogsLogoEnabled}
              />
            )}
            {signInStore.isPrivilegeGranted(UserPrivileges.viewManageUsers) && (
              <IconButton
                icon={
                  <UsersIcon
                    color={
                      isManageUsersLogoEnabled
                        ? tokens.iconPrimary
                        : tokens.icon
                    }
                  />
                }
                name="manageUsers"
                variant="plain-neutral"
                size="medium"
                onClick={() => {
                  navigateTo.manageUsers();
                }}
                disabled={!isManageUsersLogoEnabled}
              />
            )}
          </Stack>
          <TopNavDropDown
            userName={getUserFullName(signInStore.userName)}
            signInStore={signInStore}
          />
        </Stack>
      </Stack>
    );
  },
);
