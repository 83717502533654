import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  CornerRadius,
  Snackbar,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { SurveyNameSettings } from "./SurveyNameSettings";
import { ChangeLogoSettings } from "./ChangeLogoSettings";
import { ThemingSettings } from "./ThemingSettings";
import { DividerComponent } from "../commonQuestionConfigurationDetailsComponents/DividerComponent";
import { LanguagePreferencesSettings } from "./LanguagePreferencesSettings";
import { SurveyControlPreferencesSettings } from "./SurveyControlPreferencesSettings";
import { FontPreferencesSettings } from "./FontPreferencesSettings";
import { DialogCustomisationSettings } from "./DialogCustomisationSettings";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  MIN_SURVEY_NAME_LENGTH,
  PROJECT_SURVEY_NAME_VALIDATIONS,
} from "@pulse/shared-components";
import { InfoDialogCustomisationSettings } from "./InfoDialogCustomisationSettings";
import { MiscellaneousMessagesSettings } from "./MiscellaneousMessagesSettings";
import { DefaultButtonTextSettings } from "./DefaultButtonTextSettings";
import { WelcomePageCustomisation } from "./WelcomePageCustomisation";
import { ThankYouPageCustomisation } from "./ThankYouPageCustomisation";
import {
  Dialog,
  LoadingIndicator,
} from "@surya-digital/leo-reactjs-material-ui";
import { ErrorSuccessDialogContent } from "../../../projects/components/ErrorSuccessDialogContent";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { CloseReopenSurveySection } from "./CloseReopenSurveySection";
import { SurveyStore } from "../../store/SurveyStore";
import {
  CommonProjectAlreadyArchivedRPCError,
  UpdateSurveySettingsError,
} from "../../store/BackOfficeSurveySettingsStore";

interface SurveySettingsPaneProps {
  surveyStore: Instance<typeof SurveyStore>;
  spacing: Spacing;
  t: TFunction;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  borderRadius: CornerRadius;
  navigateTo: NavigateToFunctions;
  surveySettingsPageRef: React.RefObject<HTMLDivElement>;
}

export const SurveySettingsPane = observer(
  ({
    surveyStore,
    spacing,
    t,
    typography,
    tokens,
    border,
    borderRadius,
    navigateTo,
    surveySettingsPageRef,
  }: SurveySettingsPaneProps): React.ReactElement => {
    const updatedBackOfficeSurveySettings =
      surveyStore.backOfficeSurveySettingsStore.updatedBackOfficeSurveySettings;
    const initialBackOfficeSurveySettings =
      surveyStore.backOfficeSurveySettingsStore.initialBackOfficeSurveySettings;
    const updatedBackOfficeSurveySettingsUIStore =
      updatedBackOfficeSurveySettings?.backOfficeSurveySettingsUIStore;
    const surveySettings = updatedBackOfficeSurveySettings?.surveySettings;

    useEffect(() => {
      return () => {
        surveyStore.clearRpcErrors();
        surveyStore.backOfficeSurveySettingsStore.uploadClientLogoStore.resetFileUploadError();
      };
    }, []);

    const getSurveyNameTextFieldHelperText = (): string | undefined => {
      switch (surveyStore.backOfficeSurveySettingsStore.rpcError) {
        case UpdateSurveySettingsError.InvalidSurveyName: {
          return t("surveys.surveyNameHelperText.invalidSurveyName", {
            allowedSpecialCharacters:
              PROJECT_SURVEY_NAME_VALIDATIONS.allowedSpecialCharacters,
          });
        }
        case UpdateSurveySettingsError.InvalidSurveyNameLength: {
          return t("surveys.settings.genricHelperText", {
            minCharacterLength: MIN_SURVEY_NAME_LENGTH,
          });
        }
        case UpdateSurveySettingsError.SurveyNameNotUnique: {
          return t("surveys.surveyNameHelperText.surveyNameNotUnique");
        }
      }
    };

    const getSaveChangesErrorDialogText = (): string => {
      switch (surveyStore.backOfficeSurveySettingsStore.rpcError) {
        case CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived:
          return t(
            "surveys.settings.saveChanges.saveChangesProjectAlreadyArchivedErrorText",
          );
        case UpdateSurveySettingsError.SurveyIsClosed:
          return t(
            "surveys.settings.saveChanges.saveChangesSurveyClosedErrorText",
          );
        default:
          console.error(
            `No error text set for error ${surveyStore.backOfficeSurveySettingsStore.rpcError} `,
          );
          return EMPTY_CHARACTER;
      }
    };

    return surveyStore.backOfficeSurveySettingsStore
      .isGetSurveySettingsRPCLoading ? (
      <LoadingIndicator
        isLoading={
          surveyStore.backOfficeSurveySettingsStore
            .isGetSurveySettingsRPCLoading
        }
      />
    ) : updatedBackOfficeSurveySettingsUIStore &&
      initialBackOfficeSurveySettings &&
      surveySettings ? (
      <Stack
        px={spacing.space2XL}
        width="100%"
        height="100%"
        alignItems="start"
        overflow="auto"
        divider={<DividerComponent orientation="horizontal" width="100%" />}
        ref={surveySettingsPageRef}
      >
        {surveyStore.backOfficeSurveySettingsStore.isResetClientLogoLoading && (
          <LoadingIndicator
            isLoading={
              surveyStore.backOfficeSurveySettingsStore.isResetClientLogoLoading
            }
          />
        )}
        <Snackbar
          message={t("surveys.settings.errorBannerText")}
          severity="error"
          onClose={(): void => {
            updatedBackOfficeSurveySettingsUIStore.setIsErrorSnackbarVisible(
              false,
            );
          }}
          isOpen={updatedBackOfficeSurveySettingsUIStore.isErrorSnackbarVisible}
          isDismissible={true}
        />
        <SurveyNameSettings
          surveyName={updatedBackOfficeSurveySettings.surveyName}
          setSurveyName={(surveyName: string) => {
            updatedBackOfficeSurveySettings.setSurveyName(surveyName);
            surveyStore.clearRpcErrors();
            updatedBackOfficeSurveySettingsUIStore.clearError();
          }}
          doesUpdateSurveyNameTextFieldContainError={
            surveyStore.backOfficeSurveySettingsStore
              .doesUpdateSurveyNameTextFieldContainError
          }
          surveyNameHelperText={getSurveyNameTextFieldHelperText()}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
        />
        {updatedBackOfficeSurveySettings &&
          updatedBackOfficeSurveySettings.surveySettings.themedImage && (
            <ChangeLogoSettings
              t={t}
              spacing={spacing}
              typography={typography}
              tokens={tokens}
              border={border}
              lightThemeLogoUrl={
                updatedBackOfficeSurveySettings.surveySettings.themedImage
                  ?.lightImageURL
              }
              darkThemeLogoUrl={
                updatedBackOfficeSurveySettings.surveySettings.themedImage
                  ?.darkImageURL
              }
              cornerRadius={borderRadius}
              navigateTo={navigateTo}
              backOfficeSurveySettingsStore={
                surveyStore.backOfficeSurveySettingsStore
              }
              updateClientDarkThemeLogoURL={
                updatedBackOfficeSurveySettings.surveySettings.themedImage
                  .setDarkImageURL
              }
              updateClientLightThemeLogoURL={
                updatedBackOfficeSurveySettings.surveySettings.themedImage
                  .setLightImageURL
              }
              updateClientLightThemeLogoId={
                updatedBackOfficeSurveySettings.setLightThemeLogoId
              }
              updateClientDarkThemeLogoId={
                updatedBackOfficeSurveySettings.setDarkThemeLogoId
              }
              setIsErrorBannerVisible={
                updatedBackOfficeSurveySettings.backOfficeSurveySettingsUIStore
                  .setIsErrorSnackbarVisible
              }
            />
          )}
        <ThemingSettings
          setSelectedSurveyTheme={
            updatedBackOfficeSurveySettings.setSelectedSurveyTheme
          }
          selectedSurveyTheme={
            updatedBackOfficeSurveySettings.selectedSurveyTheme
          }
          supportedThemes={
            surveyStore.backOfficeSurveySettingsStore.supportedThemes
          }
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          borderRadius={borderRadius}
        />
        <LanguagePreferencesSettings
          setSelectedLanguageCode={
            updatedBackOfficeSurveySettings.setSelectedLanguageCode
          }
          selectedLanguageId={surveySettings.selectedLanguageId}
          languageList={surveySettings.languageList}
          isLanguageSelectionOptionPersistent={
            surveySettings.isLanguageSelectionOptionPersistent
          }
          isLanguageSelectionDisplayedOnStart={
            surveySettings.isLanguageSelectionDisplayedOnStart
          }
          setIsLanguageSelectionOptionPersistent={
            updatedBackOfficeSurveySettings.setIsLanguageSelectionOptionPersistent
          }
          setIsLanguageSelectionDisplayedOnStart={
            updatedBackOfficeSurveySettings.setIsLanguageSelectionDisplayedOnStart
          }
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
        />
        <SurveyControlPreferencesSettings
          isAutoProgressEnabled={surveySettings.isAutoProgressEnabled}
          setIsAutoProgressEnabled={
            updatedBackOfficeSurveySettings.setIsAutoProgressEnabled
          }
          isProgressPercentageShown={surveySettings.isProgressPercentageShown}
          setIsProgressPercentageShown={
            updatedBackOfficeSurveySettings.setIsProgressPercentageShown
          }
          isIpTracked={surveySettings.isIpTracked}
          setIsIpTracked={updatedBackOfficeSurveySettings.setIsIpTracked}
          isResponseResetAllowed={surveySettings.isResponseResetAllowed}
          setIsResponseResetAllowed={surveySettings.setIsResponseResetAllowed}
          isRespondentConsentRequired={
            surveySettings.isRespondentConsentRequired
          }
          setIsRespondentConsentRequired={
            updatedBackOfficeSurveySettings.setIsRespondentConsentRequired
          }
          isRatingScaleOptionVisible={surveySettings.isRatingScaleOptionVisible}
          setIsRatingScaleOptionVisible={
            updatedBackOfficeSurveySettings.setIsRatingScaleOptionVisible
          }
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
        />
        <FontPreferencesSettings
          selectedFont={surveySettings.font}
          fontsList={surveyStore.backOfficeSurveySettingsStore.supportedFonts}
          setSelectedFont={updatedBackOfficeSurveySettings.setSelectedFont}
          selectedFontSize={surveySettings.fontSizeScale}
          setSelectedFontSize={
            updatedBackOfficeSurveySettings.setSelectedFontSize
          }
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
        />
        <DialogCustomisationSettings
          checkBoxProps={{
            settingsCheckboxLabelText: t(
              "surveys.settings.surveySubmissionCustomisationSettings.submitConfirmationCheckboxLabelText",
            ),
            settingsCheckboxDescriptionText: t(
              "surveys.settings.surveySubmissionCustomisationSettings.submitConfirmationCheckboxDescriptionText",
            ),
            isCheckboxSelected:
              updatedBackOfficeSurveySettings.isSubmitConfirmationRequired,
            setIsCheckboxSelected:
              updatedBackOfficeSurveySettings.setIsSubmitConfirmationRequired,
          }}
          settingsTitleText={t(
            "surveys.settings.surveySubmissionCustomisationSettings.surveySubmissionCustomisationTitleText",
          )}
          surveySettingsDialogModel={
            updatedBackOfficeSurveySettings.submitConfirmationDialogProperties
          }
          isSecondaryButtonPresent={true}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          border={border}
          clearError={updatedBackOfficeSurveySettingsUIStore.clearError}
          isSaveButtonClicked={
            updatedBackOfficeSurveySettingsUIStore.isSaveButtonClicked
          }
        />
        <DialogCustomisationSettings
          checkBoxProps={{
            settingsCheckboxLabelText: t(
              "surveys.settings.surveyRestartCustomisationSettings.allowRestartCheckboxLabelText",
            ),
            settingsCheckboxDescriptionText: t(
              "surveys.settings.surveyRestartCustomisationSettings.allowRestartCheckboxDescriptionText",
            ),
            isCheckboxSelected:
              updatedBackOfficeSurveySettings.isRestartAllowed,
            setIsCheckboxSelected:
              updatedBackOfficeSurveySettings.setIsRestartAllowed,
          }}
          settingsTitleText={t(
            "surveys.settings.surveyRestartCustomisationSettings.surveyRestartCustomisationTitleText",
          )}
          surveySettingsDialogModel={
            updatedBackOfficeSurveySettings.restartSurveyDialogProperties
          }
          isSecondaryButtonPresent={true}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          border={border}
          clearError={updatedBackOfficeSurveySettingsUIStore.clearError}
          isSaveButtonClicked={
            updatedBackOfficeSurveySettingsUIStore.isSaveButtonClicked
          }
        />
        <DialogCustomisationSettings
          checkBoxProps={{
            settingsCheckboxLabelText: t(
              "surveys.settings.saveDraftCustomisationSettings.allowSaveDraftCheckboxLabelText",
            ),
            settingsCheckboxDescriptionText: t(
              "surveys.settings.saveDraftCustomisationSettings.allowSaveDraftCheckboxDescriptionText",
            ),
            isCheckboxSelected:
              updatedBackOfficeSurveySettings.isSaveDraftAllowed,
            setIsCheckboxSelected:
              updatedBackOfficeSurveySettings.setIsSaveDraftAllowed,
          }}
          settingsTitleText={t(
            "surveys.settings.saveDraftCustomisationSettings.saveDraftCustomisationTitleText",
          )}
          surveySettingsDialogModel={
            updatedBackOfficeSurveySettings.saveDraftDialogProperties
          }
          isSecondaryButtonPresent={false}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          border={border}
          clearError={updatedBackOfficeSurveySettingsUIStore.clearError}
          isSaveButtonClicked={
            updatedBackOfficeSurveySettingsUIStore.isSaveButtonClicked
          }
        />
        <DialogCustomisationSettings
          settingsTitleText={t(
            "surveys.settings.surveyAlreadySubmittedDialogCustomisationSettings.surveyAlreadySubmittedDialogCustomisationTitleText",
          )}
          surveySettingsDialogModel={
            updatedBackOfficeSurveySettings.surveySettings
              .surveyAlreadySubmittedDialogTexts
          }
          isSecondaryButtonPresent={false}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          border={border}
          clearError={updatedBackOfficeSurveySettingsUIStore.clearError}
          isSaveButtonClicked={
            updatedBackOfficeSurveySettingsUIStore.isSaveButtonClicked
          }
        />
        <DialogCustomisationSettings
          settingsTitleText={t(
            "surveys.settings.languageSelectionDialogCustomisationSettings.languageSelectionDialogCustomisationTitleText",
          )}
          surveySettingsDialogModel={
            updatedBackOfficeSurveySettings.surveySettings
              .languageSelectionDialogTexts
          }
          isSecondaryButtonPresent={true}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          border={border}
          clearError={updatedBackOfficeSurveySettingsUIStore.clearError}
          isSaveButtonClicked={
            updatedBackOfficeSurveySettingsUIStore.isSaveButtonClicked
          }
        />
        <InfoDialogCustomisationSettings
          isInfoButtonShown={updatedBackOfficeSurveySettings.isInfoButtonShown}
          setIsInfoButtonShown={
            updatedBackOfficeSurveySettings.setIsInfoButtonShown
          }
          infoDialogDescription={
            initialBackOfficeSurveySettings.surveyInformationDialogProperties
              .descriptionText
          }
          setInfoDialogDescription={
            updatedBackOfficeSurveySettings.surveyInformationDialogProperties
              .setDescriptionText
          }
          isInfoDescriptionErrored={updatedBackOfficeSurveySettingsUIStore.isInfoDialogDescriptionErrored(
            updatedBackOfficeSurveySettings.surveyInformationDialogProperties
              .descriptionText,
          )}
          clearError={updatedBackOfficeSurveySettingsUIStore.clearError}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          border={border}
          rteFileService={
            updatedBackOfficeSurveySettings.infoDialogDescriptionTextRTEFileService
          }
          updatedText={
            updatedBackOfficeSurveySettings.surveyInformationDialogProperties
              .descriptionText
          }
        />
        <MiscellaneousMessagesSettings
          miscellaneousSettingsTexts={
            surveySettings.miscellaneousSurveySettingsText
          }
          updatedBackOfficeSurveySettings={updatedBackOfficeSurveySettings}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
        />
        <DefaultButtonTextSettings
          updatedBackOfficeSurveySettings={updatedBackOfficeSurveySettings}
          clearError={updatedBackOfficeSurveySettingsUIStore.clearError}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
        />
        <WelcomePageCustomisation
          isWelcomePageShown={
            updatedBackOfficeSurveySettings.isWelcomePageShown
          }
          setIsWelcomePageShown={
            updatedBackOfficeSurveySettings.setIsWelcomePageShown
          }
          welcomePageText={initialBackOfficeSurveySettings.welcomePageText}
          setWelcomePageText={
            updatedBackOfficeSurveySettings.setWelcomePageText
          }
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          isWelcomePageTextErrored={updatedBackOfficeSurveySettingsUIStore.isWelcomePageTextErrored(
            updatedBackOfficeSurveySettings.welcomePageText,
          )}
          rteFileService={
            updatedBackOfficeSurveySettings.welcomePageTextRTEFileService
          }
          updatedWelcomePageText={
            updatedBackOfficeSurveySettings.welcomePageText
          }
        />
        <ThankYouPageCustomisation
          thankYouPageText={initialBackOfficeSurveySettings.thankYouPageText}
          setThankYouPageText={
            updatedBackOfficeSurveySettings.setThankYouPageText
          }
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          isThankYouPageTextErrored={updatedBackOfficeSurveySettingsUIStore.isThankYouPageTextErrored(
            updatedBackOfficeSurveySettings.thankYouPageText,
          )}
          isFIThankYouPageLogoShown={
            updatedBackOfficeSurveySettings.surveySettings
              .isFIThankYouPageLogoShown
          }
          setIsFIThankYouPageLogoShown={
            updatedBackOfficeSurveySettings.surveySettings
              .setIsFIThankYouPageLogoShown
          }
          rteFileService={
            updatedBackOfficeSurveySettings.thankYouPageTextRTEFileService
          }
          updatedThankYouPageText={
            updatedBackOfficeSurveySettings.thankYouPageText
          }
        />
        <CloseReopenSurveySection
          navigateTo={navigateTo}
          surveyStore={surveyStore}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          backOfficeSurveySettingsStore={
            surveyStore.backOfficeSurveySettingsStore
          }
        />
        {surveyStore.backOfficeSurveySettingsStore
          .isUpdateSurveySettingsRPCLoading && (
          <LoadingIndicator
            isLoading={
              surveyStore.backOfficeSurveySettingsStore
                .isUpdateSurveySettingsRPCLoading
            }
          />
        )}
        {surveyStore.backOfficeSurveySettingsStore
          .isUpdateSurveySettingsErrorDialogVisible && (
          <Dialog
            open={
              surveyStore.backOfficeSurveySettingsStore
                .isUpdateSurveySettingsErrorDialogVisible
            }
            title={t(
              "surveys.settings.saveChanges.saveChangesErrorDialogTitle",
            )}
            secondaryButtonText={t("common.close")}
            onSecondaryButtonClick={() =>
              surveyStore.backOfficeSurveySettingsStore.setIsUpdateSurveySettingsErrorDialogVisible(
                false,
              )
            }
          >
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={getSaveChangesErrorDialogText()}
            />
          </Dialog>
        )}
      </Stack>
    ) : (
      <></>
    );
  },
);
