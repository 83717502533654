import {
  Instance,
  flow,
  getParentOfType,
  getRoot,
  types,
} from "mobx-state-tree";
import { SurveyQuestionModel } from "../models/SurveyQuestionModel";
import {
  GroupChildQuestionOrder,
  ManageSurveyQuestionsRPC,
  Order,
  QuestionOrder,
  QuestionType,
} from "@pulse/pulse-rpcs";
import {
  BASE_QUESTION_ORDER,
  CommonErrors,
  EMPTY_CHARACTER,
  NetworkingError,
} from "@pulse/shared-components";
import { SurveyStore } from "./SurveyStore";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "../../networking/APIClient";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useManageSurveyQuestionsRPCClientImpl } from "../rpcs/RPC";
import { RootStore } from "../../root/store/RootStore";

export enum ManageSurveyQuestionsError {
  SurveyIsClosed = "SURVEY_IS_CLOSED",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  SurveyIsLive = "SURVEY_IS_LIVE",
  OutDatedQuestionsFound = "OUTDATED_QUESTIONS_FOUND",
  InvalidQuestionsFound = "INVALID_QUESTION_ORDERS",
}

export const ManageQuestionsStore = types
  .model("ManageQuestionsStore", {
    manageQuestionsList: types.array(SurveyQuestionModel),
    doesGroupQuestionExistInManageQuestionsList: types.optional(
      types.boolean,
      false,
    ),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          "ManageSurveyQuestionsError",
          Object.values(ManageSurveyQuestionsError),
        ),
        types.enumeration("NetworkingError", Object.values(NetworkingError)),
      ),
    ),
    isRPCLoading: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get isOutdateQuestionError(): boolean {
      return (
        store.rpcError === ManageSurveyQuestionsError.OutDatedQuestionsFound
      );
    },
    get isSubmitButtonDisabled(): boolean {
      const surveyStore = getParentOfType(store, SurveyStore);
      return (
        JSON.stringify(surveyStore.questionList) ===
        JSON.stringify(store.manageQuestionsList)
      );
    },
    get doesStoreContainError(): boolean {
      return store.rpcError !== null;
    },
    findQuestionById: (
      questionId: string,
    ): Instance<typeof SurveyQuestionModel> | undefined => {
      const questionDetails: Instance<typeof SurveyQuestionModel> | undefined =
        store.manageQuestionsList.find((question) => {
          return question.surveyQuestionDetails.questionId === questionId;
        });
      if (questionDetails === undefined) {
        console.error(`Invalid Question ID ${questionId}`);
      }
      return questionDetails;
    },
    findIndexInManageQuestionsListByQuestionId: (
      questionId: string,
    ): number => {
      return store.manageQuestionsList.findIndex(
        (question) => question.surveyQuestionDetails.questionId === questionId,
      );
    },
    get lastQuestionOrder(): number {
      let index = 0;
      store.manageQuestionsList.map((question) => {
        if (
          question.surveyQuestionDetails.parentQuestionId === null ||
          question.surveyQuestionDetails.parentQuestionId === EMPTY_CHARACTER
        ) {
          index++;
        }
      });
      return index;
    },
  }))
  .views((store) => ({
    isMovingQuestionUpDisabled(index: number): boolean {
      return (
        store.manageQuestionsList[index].surveyQuestionDetails.order ===
        BASE_QUESTION_ORDER
      );
    },
    isMovingQuestionDownDisabled(questionId: string): boolean {
      const questionOrder =
        store.findQuestionById(questionId)?.surveyQuestionDetails.order;
      return questionOrder === store.lastQuestionOrder;
    },
    isMovingChildQuestionUpDisabled(childQuestionId: string): boolean {
      const childQuestion = store.findQuestionById(childQuestionId);
      return childQuestion?.surveyQuestionDetails.order === BASE_QUESTION_ORDER;
    },
    isMovingChildQuestionDownDisabled(
      parentQuestionId: string,
      childQuestionId: string,
    ): boolean {
      const parentQuestion = store.findQuestionById(parentQuestionId);
      if (parentQuestion) {
        const lastChildQuestionIndex =
          parentQuestion.surveyQuestionDetails.childQuestionIds.length - 1;
        return (
          parentQuestion.surveyQuestionDetails.childQuestionIds[
            lastChildQuestionIndex
          ] === childQuestionId
        );
      } else {
        return false;
      }
    },
    isQuestionGroupType(questionId: string): boolean {
      const currentQuestion = store.findQuestionById(questionId);
      return (
        currentQuestion?.surveyQuestionDetails.questionType ===
        QuestionType.QuestionType.GROUP
      );
    },
    isChildQuestionInManageQuestionList: (questionId: string): boolean => {
      return (
        store.manageQuestionsList.find(
          (question) =>
            question.surveyQuestionDetails.questionId === questionId &&
            question.surveyQuestionDetails.parentQuestionId !== null,
        ) !== undefined
      );
    },
  }))
  .actions((store) => ({
    setDoesGroupQuestionExistInManageQuestionsList: (
      doesGroupQuestionExist: boolean,
    ): void => {
      store.doesGroupQuestionExistInManageQuestionsList =
        doesGroupQuestionExist;
    },
    updateManageQuestionsListOrder: (): void => {
      let index = BASE_QUESTION_ORDER;
      store.manageQuestionsList.map((question) => {
        if (
          question.surveyQuestionDetails.parentQuestionId === null ||
          question.surveyQuestionDetails.parentQuestionId === EMPTY_CHARACTER
        ) {
          question.surveyQuestionDetails.order = index++;
        }
      });
    },
    updateChildQuestionOrdersInGroupQuestion: (
      groupQuestionId: string,
    ): void => {
      const groupQuestion = store.findQuestionById(groupQuestionId);
      if (groupQuestion) {
        let newOrder = BASE_QUESTION_ORDER;
        groupQuestion.surveyQuestionDetails.childQuestionIds.map(
          (questionId) => {
            const childQuestionOfOldParent = store.findQuestionById(questionId);
            if (childQuestionOfOldParent) {
              childQuestionOfOldParent.surveyQuestionDetails.order = newOrder++;
            }
          },
        );
      }
    },
  }))
  .actions((store) => ({
    setManageQuestionsList: (): void => {
      const surveyStore = getParentOfType(store, SurveyStore);
      const filteredQuestionList = surveyStore.questionList.filter(
        (question) =>
          question.surveyQuestionDetails.questionId !== EMPTY_CHARACTER,
      );
      store.manageQuestionsList = JSON.parse(
        JSON.stringify(filteredQuestionList),
      );
      let order = BASE_QUESTION_ORDER;
      store.manageQuestionsList.map((question) => {
        if (
          question.surveyQuestionDetails.parentQuestionId === null ||
          question.surveyQuestionDetails.parentQuestionId === EMPTY_CHARACTER
        ) {
          if (
            question.surveyQuestionDetails.questionType ===
            QuestionType.QuestionType.GROUP
          ) {
            store.updateChildQuestionOrdersInGroupQuestion(
              question.surveyQuestionDetails.questionId,
            );
          }
          question.surveyQuestionDetails.order = order++;
        }
      });
    },
    resetManageQuestionsList: (): void => {
      store.manageQuestionsList.clear();
    },
    resetRPCError: (): void => {
      store.rpcError = null;
    },
    moveParentQuestionUp: (oldOrder: number, questionId: string): void => {
      const questionWithLowerOrder = store.manageQuestionsList.find(
        (question) =>
          question.surveyQuestionDetails.order === oldOrder - 1 &&
          (question.surveyQuestionDetails.parentQuestionId === null ||
            question.surveyQuestionDetails.parentQuestionId ===
              EMPTY_CHARACTER),
      );
      const questionToBeUpdated = store.findQuestionById(questionId);
      if (questionWithLowerOrder && questionToBeUpdated) {
        [
          questionWithLowerOrder.surveyQuestionDetails.order,
          questionToBeUpdated.surveyQuestionDetails.order,
        ] = [
          questionToBeUpdated.surveyQuestionDetails.order,
          questionWithLowerOrder.surveyQuestionDetails.order,
        ];

        const questionWithLowerOrderIndexInManageQuestionList =
          store.findIndexInManageQuestionsListByQuestionId(
            questionWithLowerOrder.surveyQuestionDetails.questionId,
          );
        const questionToBeUpdatedIndexInManageQuestionList =
          store.findIndexInManageQuestionsListByQuestionId(
            questionToBeUpdated.surveyQuestionDetails.questionId,
          );
        const questionToBeUpdatedCopy = JSON.parse(
          JSON.stringify(questionToBeUpdated),
        );
        const questionWithLowerOrderCopy = JSON.parse(
          JSON.stringify(questionWithLowerOrder),
        );

        store.manageQuestionsList[
          questionWithLowerOrderIndexInManageQuestionList
        ] = questionToBeUpdatedCopy;
        store.manageQuestionsList[
          questionToBeUpdatedIndexInManageQuestionList
        ] = questionWithLowerOrderCopy;
      }
    },
    moveParentQuestionDown: (oldOrder: number, questionId: string): void => {
      const questionWithHigherOrder = store.manageQuestionsList.find(
        (question) =>
          question.surveyQuestionDetails.order === oldOrder + 1 &&
          (question.surveyQuestionDetails.parentQuestionId === null ||
            question.surveyQuestionDetails.parentQuestionId ===
              EMPTY_CHARACTER),
      );
      const questionToBeUpdated = store.findQuestionById(questionId);
      if (questionWithHigherOrder && questionToBeUpdated) {
        [
          questionWithHigherOrder.surveyQuestionDetails.order,
          questionToBeUpdated.surveyQuestionDetails.order,
        ] = [
          questionToBeUpdated.surveyQuestionDetails.order,
          questionWithHigherOrder.surveyQuestionDetails.order,
        ];

        const questionWithHigherOrderIndexInManageQuestionList =
          store.findIndexInManageQuestionsListByQuestionId(
            questionWithHigherOrder.surveyQuestionDetails.questionId,
          );
        const questionToBeUpdatedIndexInManageQuestionList =
          store.findIndexInManageQuestionsListByQuestionId(
            questionToBeUpdated.surveyQuestionDetails.questionId,
          );
        const questionToBeUpdatedCopy = JSON.parse(
          JSON.stringify(questionToBeUpdated),
        );
        const questionWithHigherOrderCopy = JSON.parse(
          JSON.stringify(questionWithHigherOrder),
        );

        store.manageQuestionsList[
          questionWithHigherOrderIndexInManageQuestionList
        ] = questionToBeUpdatedCopy;
        store.manageQuestionsList[
          questionToBeUpdatedIndexInManageQuestionList
        ] = questionWithHigherOrderCopy;
      }
    },
    moveChildQuestionUp: (
      parentQuestionId: string,
      childQuestionId: string,
    ): void => {
      const parentQuestion = store.findQuestionById(parentQuestionId);
      const childQuestion = store.findQuestionById(childQuestionId);
      if (parentQuestion && childQuestion) {
        if (
          parentQuestion.surveyQuestionDetails.childQuestionIds.length ===
            BASE_QUESTION_ORDER ||
          childQuestion.surveyQuestionDetails.order === BASE_QUESTION_ORDER
        ) {
          return;
        }
        const previousChildQuestionIndex =
          parentQuestion.surveyQuestionDetails.childQuestionIds.findIndex(
            (questionId) =>
              questionId === childQuestion.surveyQuestionDetails.questionId,
          ) - 1;
        const PreviousChildQuestion = store.findQuestionById(
          parentQuestion.surveyQuestionDetails.childQuestionIds[
            previousChildQuestionIndex
          ],
        );
        if (PreviousChildQuestion) {
          PreviousChildQuestion.surveyQuestionDetails.order++;
          childQuestion.surveyQuestionDetails.order--;
        }

        [
          parentQuestion.surveyQuestionDetails.childQuestionIds[
            previousChildQuestionIndex
          ],
          parentQuestion.surveyQuestionDetails.childQuestionIds[
            previousChildQuestionIndex + 1
          ],
        ] = [
          parentQuestion.surveyQuestionDetails.childQuestionIds[
            previousChildQuestionIndex + 1
          ],
          parentQuestion.surveyQuestionDetails.childQuestionIds[
            previousChildQuestionIndex
          ],
        ];
      }
    },
    moveChildQuestionDown: (
      parentQuestionId: string,
      childQuestionId: string,
    ): void => {
      const parentQuestion = store.findQuestionById(parentQuestionId);
      const childQuestion = store.findQuestionById(childQuestionId);
      if (parentQuestion && childQuestion) {
        const totalChildQuestions =
          parentQuestion.surveyQuestionDetails.childQuestionIds.length;
        if (
          totalChildQuestions === BASE_QUESTION_ORDER ||
          childQuestion.surveyQuestionDetails.questionId ===
            parentQuestion.surveyQuestionDetails.childQuestionIds[
              totalChildQuestions - 1
            ]
        ) {
          return;
        }
        const nextChildQuestionIndex =
          parentQuestion.surveyQuestionDetails.childQuestionIds.findIndex(
            (questionId) =>
              questionId === childQuestion.surveyQuestionDetails.questionId,
          ) + 1;
        const nextChildQuestion = store.findQuestionById(
          parentQuestion.surveyQuestionDetails.childQuestionIds[
            nextChildQuestionIndex
          ],
        );
        if (nextChildQuestion) {
          nextChildQuestion.surveyQuestionDetails.order--;
          childQuestion.surveyQuestionDetails.order++;
        }

        [
          parentQuestion.surveyQuestionDetails.childQuestionIds[
            nextChildQuestionIndex
          ],
          parentQuestion.surveyQuestionDetails.childQuestionIds[
            nextChildQuestionIndex - 1
          ],
        ] = [
          parentQuestion.surveyQuestionDetails.childQuestionIds[
            nextChildQuestionIndex - 1
          ],
          parentQuestion.surveyQuestionDetails.childQuestionIds[
            nextChildQuestionIndex
          ],
        ];
      }
    },
    moveChildQuestionIntoGroupQuestion: (
      oldParentQuestionId: string,
      newParentQuestionId: string,
      childQuestionId: string,
    ): void => {
      const oldParentQuestion = store.findQuestionById(oldParentQuestionId);
      const newParentQuestion = store.findQuestionById(newParentQuestionId);
      const childQuestion = store.findQuestionById(childQuestionId);
      if (oldParentQuestion && newParentQuestion && childQuestion) {
        const childQuestionIndexInOldParent =
          oldParentQuestion.surveyQuestionDetails.childQuestionIds.findIndex(
            (questionId) => questionId === childQuestionId,
          );
        oldParentQuestion.surveyQuestionDetails.childQuestionIds.splice(
          childQuestionIndexInOldParent,
          1,
        );
        store.updateChildQuestionOrdersInGroupQuestion(oldParentQuestionId);
        childQuestion.surveyQuestionDetails.order =
          newParentQuestion.surveyQuestionDetails.childQuestionIds.length + 1;
        childQuestion.surveyQuestionDetails.parentQuestionId =
          newParentQuestionId;
        newParentQuestion.surveyQuestionDetails.childQuestionIds.push(
          childQuestionId,
        );
      }
    },
    moveChildQuestionOutOfGroupQuestion: (
      groupQuestionId: string,
      childQuestionId: string,
    ): void => {
      const groupQuestion = store.findQuestionById(groupQuestionId);
      const childQuestion = store.findQuestionById(childQuestionId);
      if (groupQuestion && childQuestion) {
        const childQuestionIndexInOldParent =
          groupQuestion.surveyQuestionDetails.childQuestionIds.findIndex(
            (questionId) => questionId === childQuestionId,
          );
        groupQuestion.surveyQuestionDetails.childQuestionIds.splice(
          childQuestionIndexInOldParent,
          1,
        );
        store.updateChildQuestionOrdersInGroupQuestion(groupQuestionId);
        childQuestion.surveyQuestionDetails.parentQuestionId = null;
        childQuestion.surveyQuestionDetails.order = store.lastQuestionOrder;
      }
    },
    moveIntoGroupQuestion: (
      parentQuestionId: string,
      childQuestionId: string,
    ): void => {
      const childQuestion = store.findQuestionById(childQuestionId);

      if (childQuestion) {
        childQuestion.surveyQuestionDetails.parentQuestionId = parentQuestionId;
        store.manageQuestionsList.map((question) => {
          if (
            question.surveyQuestionDetails.order >
              childQuestion.surveyQuestionDetails.order &&
            (question.surveyQuestionDetails.parentQuestionId === null ||
              question.surveyQuestionDetails.parentQuestionId ===
                EMPTY_CHARACTER)
          ) {
            question.surveyQuestionDetails.order--;
          }
        });
        store
          .findQuestionById(parentQuestionId)
          ?.surveyQuestionDetails.childQuestionIds.push(childQuestionId);
        store.updateChildQuestionOrdersInGroupQuestion(parentQuestionId);
      } else {
        console.error(
          `Question id ${childQuestionId} does not exist in the list.`,
        );
      }
      store.updateManageQuestionsListOrder();
    },
    updateQuestionsOrder: flow(function* (surveyId: string, projectId: string) {
      const surveyStore = getParentOfType(store, SurveyStore);
      store.isRPCLoading = true;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const questionOrder: QuestionOrder[] = [];
        store.manageQuestionsList.map((question) => {
          if (
            question.surveyQuestionDetails.parentQuestionId === null ||
            question.surveyQuestionDetails.parentQuestionId === EMPTY_CHARACTER
          ) {
            if (
              question.surveyQuestionDetails.questionType ===
              QuestionType.QuestionType.GROUP
            ) {
              questionOrder.push(
                new QuestionOrder(
                  new LeoUUID(question.surveyQuestionDetails.questionId),
                  new Order(question.surveyQuestionDetails.order),
                  question.surveyQuestionDetails.childQuestionIds.map(
                    (childQuestionId) => {
                      return new GroupChildQuestionOrder(
                        new LeoUUID(childQuestionId),
                        new Order(
                          // If the question is not found with the childQuestionId then we pass 0 as order which will result in failure of Order object creation and will be caught in catch block.
                          store.findQuestionById(childQuestionId)
                            ?.surveyQuestionDetails.order ?? 0,
                        ),
                      );
                    },
                  ),
                ),
              );
            } else {
              questionOrder.push(
                new QuestionOrder(
                  new LeoUUID(question.surveyQuestionDetails.questionId),
                  new Order(question.surveyQuestionDetails.order),
                  [],
                ),
              );
            }
          }
        });
        const request = new ManageSurveyQuestionsRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
          questionOrder,
        );
        const {
          response,
          error,
        }: {
          response?: ManageSurveyQuestionsRPC.Response;
          error?: ManageSurveyQuestionsRPC.Errors.Errors;
        } =
          yield useManageSurveyQuestionsRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          surveyStore.setSurveyQuestions(
            response.surveyDetailsAndQuestions.surveyQuestionDetails,
          );
          surveyStore.setSurveyName(
            response.surveyDetailsAndQuestions.surveyStatusAndName.surveyName
              .name,
          );
          surveyStore.setSurveyStatus(
            response.surveyDetailsAndQuestions.surveyStatusAndName.surveyStatus,
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case ManageSurveyQuestionsError.InvalidQuestionsFound:
              store.rpcError = ManageSurveyQuestionsError.InvalidQuestionsFound;
              break;
            case ManageSurveyQuestionsError.OutDatedQuestionsFound:
              store.rpcError =
                ManageSurveyQuestionsError.OutDatedQuestionsFound;
              break;
            case ManageSurveyQuestionsError.ProjectAlreadyArchived:
              store.rpcError =
                ManageSurveyQuestionsError.ProjectAlreadyArchived;
              break;
            case ManageSurveyQuestionsError.SurveyIsClosed:
              store.rpcError = ManageSurveyQuestionsError.SurveyIsClosed;
              break;
            case ManageSurveyQuestionsError.SurveyIsLive:
              store.rpcError = ManageSurveyQuestionsError.SurveyIsLive;
              break;
            default:
              store.rpcError = null;
              console.error(
                `Unhandled error ${error} found from ManageSurveyQuestionsRPC.`,
              );
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} found in ManageSurveyQuestionsRPC.`,
          );
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }));

export const createManageQuestionsStore = (): Instance<
  typeof ManageQuestionsStore
> => {
  return ManageQuestionsStore.create();
};
