import { Box, Stack, Typography } from "@mui/material";
import { BorderStyle, PRIMARY_COLORS } from "@pulse/shared-components";
import {
  Spacing,
  FoundationColorTokens,
  Typography as LeoTypography,
  CornerRadius,
  Button,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React, { useState } from "react";
import { GridQuestionV1Store } from "../../store/GridQuestionV1Store";
import { observer } from "mobx-react";
import { MAX_GRID_COLUMNS } from "@pulse/shared-components";
import { GridSizeCustomisationDialog } from "./GridSizeCustomisationDialog";
import { Circle } from "lucide-react";

export interface GridSizeRepresentationProps {
  gridQuestionV1Store: Instance<typeof GridQuestionV1Store>;
  border: BorderStyle;
  borderRadius: CornerRadius;
}

export const GridSizeRepresentation = observer(
  ({
    gridQuestionV1Store,
    border,
    borderRadius,
  }: GridSizeRepresentationProps): React.ReactElement => {
    // This is the width that needs to be given between 2 points on the grid size representation.
    // Reference: https://www.figma.com/design/USl8dEzHVxs10KzHoEx56w/Pulse-Revamp?node-id=16664-137971&t=g8MM4FIyHIg9Q0GT-4
    const gridSizePerUnit = 60;
    const firstGridBorderRadius = `${borderRadius.radiusXS} 0px  0px ${borderRadius.radiusXS}`;
    return (
      <>
        {gridQuestionV1Store.isRowOptionVisible && (
          <Stack
            width={`${gridSizePerUnit * gridQuestionV1Store.gridRowSize}px`}
            bgcolor={PRIMARY_COLORS[300]}
            borderRadius={firstGridBorderRadius}
            borderRight={border.borderHighEmphasis}
          />
        )}
        {gridQuestionV1Store.gridColumnSizeCustomisations.map(
          (gridSize, index) => {
            let cornerRadius;
            let borderRight: string | undefined = border.borderHighEmphasis;
            const widthPerSizeCount: number = gridSizePerUnit;
            if (gridSize === 0 || index > MAX_GRID_COLUMNS) {
              return <></>;
            }

            if (
              index === gridQuestionV1Store.gridColumnQuestions.length - 1 &&
              gridQuestionV1Store.isMaxGridWidthReached
            ) {
              cornerRadius = `0px ${borderRadius.radiusXS} ${borderRadius.radiusXS} 0px`;
              borderRight = undefined;
            }
            if (index === 0 && !gridQuestionV1Store.isRowOptionVisible) {
              if (
                gridQuestionV1Store.isSingleGridColumnPresent &&
                gridQuestionV1Store.isMaxGridWidthReached
              ) {
                cornerRadius = borderRadius.radiusXS;
              } else {
                cornerRadius = firstGridBorderRadius;
              }
            }

            return (
              <Stack
                key={index}
                width={`${widthPerSizeCount * gridSize}px`}
                bgcolor={PRIMARY_COLORS[200]}
                borderRadius={cornerRadius}
                borderRight={borderRight}
              />
            );
          },
        )}
      </>
    );
  },
);

const GridSizeCircleContainer = ({
  tokens,
}: {
  tokens: FoundationColorTokens<string>;
}): React.ReactElement => {
  return (
    <Stack
      position="absolute"
      justifyContent="space-between"
      direction="row"
      width="100%"
      height="100%"
    >
      {[...Array(MAX_GRID_COLUMNS + 1).fill(0)].map((_, index) => {
        if (index === 0 || index === MAX_GRID_COLUMNS) {
          return <Box key={index}></Box>;
        }
        return (
          <Stack
            width="1px"
            height="100%"
            alignItems="center"
            justifyContent="center"
            key={index}
          >
            <Circle width="8px" height="8px" fill={tokens.background} />
          </Stack>
        );
      })}
    </Stack>
  );
};

export interface GridSizeCustomisationProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  t: TFunction;
  border: BorderStyle;
  borderRadius: CornerRadius;
  gridQuestionV1Store: Instance<typeof GridQuestionV1Store>;
}

export const GridSizeCustomisation = observer(
  ({
    spacing,
    tokens,
    typography,
    t,
    border,
    borderRadius,
    gridQuestionV1Store,
  }: GridSizeCustomisationProps): React.ReactElement => {
    const [isGridCustomisationDialogOpen, setIsGridCustomisationDialogOpen] =
      useState(false);

    return (
      <Stack
        paddingBottom={spacing.space2XL}
        margin={`0px ${spacing.space2XL}`}
        gap={spacing.spaceXL}
        borderBottom={border.default}
      >
        <Typography {...typography.sh1} color={tokens.label}>
          {t("surveys.addSurveyQuestion.grid.gridSizeCustomisation")}
        </Typography>
        <Stack direction="row" gap={spacing.spaceMD}>
          <Stack
            borderRadius={borderRadius.radiusXS}
            border={border.borderHighEmphasis}
            width="600px"
            bgcolor={tokens.backgroundSubtle}
            direction="row"
            position="relative"
          >
            {gridQuestionV1Store.isMaxGridWidthExceeded ? (
              <></>
            ) : (
              <GridSizeRepresentation
                gridQuestionV1Store={gridQuestionV1Store}
                border={border}
                borderRadius={borderRadius}
              />
            )}
            <GridSizeCircleContainer tokens={tokens} />
          </Stack>
          <Button
            label={t("surveys.addSurveyQuestion.grid.customise")}
            onClick={() => {
              setIsGridCustomisationDialogOpen(true);
            }}
            name="gridSizeButton"
            size="medium"
            variant="outlined-neutral"
          />
          {isGridCustomisationDialogOpen && (
            <GridSizeCustomisationDialog
              setIsGridCustomisationDialogOpen={
                setIsGridCustomisationDialogOpen
              }
              isGridCustomisationDialogOpen={isGridCustomisationDialogOpen}
              t={t}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              gridQuestionV1Store={gridQuestionV1Store}
            />
          )}
        </Stack>
      </Stack>
    );
  },
);
