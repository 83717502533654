import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Router as AppRouter } from "./routes/Routes";
import "@pulse/shared-components/src/i18n/config";
import { ThemeConfig } from "@surya-digital/leo-reactjs-material-ui";
import { darkTheme, lightTheme } from "@pulse/shared-components";
import { RootStoreProvider } from "./modules/root/store/RootStore";
import "@pulse/shared-components/src/App.css";
import { Stack } from "@mui/material";

export function App(): JSX.Element {
  return (
    <Stack>
      <ThemeConfig currentTheme="light" themes={[lightTheme(), darkTheme()]}>
        <RootStoreProvider>
          <Router>
            <AppRouter />
          </Router>
        </RootStoreProvider>
      </ThemeConfig>
    </Stack>
  );
}
