import {
  ClickAwayListener,
  ListItemButton,
  Stack,
  Typography,
  Collapse,
  List,
} from "@mui/material";
import {
  BorderStyle,
  DROPDOWN_ANIMATION_TIMING_IN_MS,
} from "@pulse/shared-components";
import {
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  TableReloadHandle,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Circle, ChevronDown, ChevronUp } from "lucide-react";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useState } from "react";
import { RespondentStore } from "../store/RespondentStore";
import { getIconProps } from "@pulse/shared-components";
import { ICON_SIZE } from "@pulse/shared-components";

export interface RespondentTitleActionElementProps {
  t: TFunction;
  border: BorderStyle;
  boxShadow: Shadow;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
  borderRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  respondentStore: Instance<typeof RespondentStore>;
  typography: LeoTypography;
  spacing: Spacing;
}

export const RespondentTitleActionElement = observer(
  ({
    tokens,
    border,
    borderRadius,
    boxShadow,
    t,
    tableRef,
    respondentStore,
    typography,
    spacing,
  }: RespondentTitleActionElementProps): React.ReactElement => {
    const [isDropDownVisible, setIsDropDownVisible] = useState(false);

    const handleDropDownClick = (): void => {
      setIsDropDownVisible(!isDropDownVisible);
    };

    const handleDropDownOptionClick = (): void => {
      if (!respondentStore.isPaginatedListVisible) {
        respondentStore.resetIsGetRespondentsRPCCalled();
      } else {
        tableRef.current?.reload();
      }
      handleDropDownClick();
      respondentStore.setIsRespondentUpdateStatusInProgress(false);
      respondentStore.multiSelectTableStore.unselectAllRows();
    };

    const circleColour = respondentStore.isLiveRespondentsSelected
      ? tokens.backgroundSuccess
      : tokens.backgroundInfo;

    const liveOrTestTypographyColour = respondentStore.isLiveRespondentsSelected
      ? tokens.labelSuccess
      : tokens.labelInfo;

    const listItemPadding = `${spacing.spaceSM} ${spacing.spaceMD}`;

    return (
      <ClickAwayListener
        onClickAway={function (): void {
          setIsDropDownVisible(false);
        }}
      >
        <List style={{ zIndex: 1, padding: 0 }}>
          <ListItemButton
            sx={{
              ":hover": {
                backgroundColor: tokens.backgroundHovered,
              },
              borderRadius: borderRadius.radiusXS,
              padding: 0,
              marginLeft: spacing.spaceXS,
              justifyContent: "center",
            }}
            onClick={handleDropDownClick}
          >
            <Stack
              borderRadius={borderRadius.radiusXS}
              padding={`${spacing.spaceXS} ${spacing.spaceMD}`}
              gap={spacing.spaceXXS}
              border={border.buttonBorderOutlinedNeutral}
              width="fit-content"
              direction="row"
              height="40px"
              alignItems="center"
            >
              <Circle
                {...getIconProps(circleColour, ICON_SIZE.extraSmall)}
                fill={circleColour}
              />

              <Typography
                sx={{ ...typography.bt2 }}
                color={liveOrTestTypographyColour}
              >
                {respondentStore.isLiveRespondentsSelected
                  ? t("projects.respondentList.live")
                  : t("projects.respondentList.test")}
              </Typography>
              {isDropDownVisible ? (
                <ChevronUp {...getIconProps(tokens.icon, ICON_SIZE.default)} />
              ) : (
                <ChevronDown
                  {...getIconProps(tokens.icon, ICON_SIZE.default)}
                />
              )}
            </Stack>
          </ListItemButton>
          <Collapse
            in={isDropDownVisible}
            timeout={DROPDOWN_ANIMATION_TIMING_IN_MS}
            style={{
              position: "absolute",
              width: "max-content",
              left: 0,
              top: "50px",
              background: tokens.backgroundElevatedLevel2,
            }}
          >
            <List
              style={{
                boxShadow: boxShadow.level3,
                borderRadius: borderRadius.radiusXS,
                border: border.subtle,
                width: "200px",
                padding: `${spacing.spaceXS} 0px`,
              }}
            >
              <ListItemButton
                onClick={() => {
                  respondentStore.setIsLiveRespondentsSelected(false);
                  handleDropDownOptionClick();
                }}
                sx={{
                  padding: listItemPadding,
                  backgroundColor: !respondentStore.isLiveRespondentsSelected
                    ? tokens.backgroundSelected
                    : tokens.backgroundElevatedLevel2,
                  ":hover": tokens.backgroundHoveredElevated,
                }}
              >
                {t("projects.respondentList.test")}
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  respondentStore.setIsLiveRespondentsSelected(true);
                  handleDropDownOptionClick();
                }}
                sx={{
                  padding: listItemPadding,
                  backgroundColor: respondentStore.isLiveRespondentsSelected
                    ? tokens.backgroundSelected
                    : tokens.backgroundElevatedLevel2,
                  ":hover": tokens.backgroundHoveredElevated,
                }}
              >
                {t("projects.respondentList.live")}
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </ClickAwayListener>
    );
  },
);
