import { Stack, Typography } from "@mui/material";
import { ICON_SIZE, getIconProps } from "@pulse/shared-components";
import {
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { AlertCircle } from "lucide-react";
import { observer } from "mobx-react";
import React from "react";

interface RespondentsAlreadyAssignedErrorDialogContentProps {
  typography: LeoTypography;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  respondentsSelected: number | undefined;
  respondentsAssigned: number | undefined;
  respondentsNotAssigned: number | undefined;
}

export const RespondentsAlreadyAssignedErrorDialogContent = observer(
  ({
    typography,
    spacing,
    tokens,
    t,
    respondentsSelected,
    respondentsAssigned,
    respondentsNotAssigned,
  }: RespondentsAlreadyAssignedErrorDialogContentProps): React.ReactElement => {
    const RespondentInformation = observer(
      ({
        respondentType,
        respondentCount,
      }: {
        respondentType: string;
        respondentCount: number | undefined;
      }): React.ReactElement => {
        return (
          <Stack
            gap={spacing.spaceSM}
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                ...typography.b1,
                color: tokens.labelSubtle,
                width: "240px",
              }}
            >
              {respondentType}
            </Typography>
            <Typography
              sx={{
                ...typography.s1,
                color: tokens.label,
                minWidth: "22px",
              }}
            >
              {respondentCount}
            </Typography>
          </Stack>
        );
      },
    );

    return (
      <Stack gap={spacing.spaceMD} width="520px">
        <Stack direction="row" gap={spacing.spaceXS}>
          <AlertCircle
            {...getIconProps(tokens.iconWarning, ICON_SIZE.default)}
          />
          <Typography
            sx={{
              ...typography.b2,
              color: tokens.labelWarning,
              width: "492px",
            }}
          >
            {t("projects.assignToSurvey.respondentAlreadyAssignedWarningText")}
          </Typography>
        </Stack>
        <Stack py={spacing.spaceXS} gap={spacing.spaceSM} width="fit-content">
          <RespondentInformation
            respondentType={t("projects.assignToSurvey.respondentsSelected")}
            respondentCount={respondentsSelected}
          />
          <RespondentInformation
            respondentType={t("projects.assignToSurvey.respondentsAssigned")}
            respondentCount={respondentsAssigned}
          />
          <RespondentInformation
            respondentType={t("projects.assignToSurvey.respondentsNotAssigned")}
            respondentCount={respondentsNotAssigned}
          />
        </Stack>
      </Stack>
    );
  },
);
