import {
  useFoundationColorTokens,
  useTypography,
  useSpacing,
  TextInputField,
  Button,
  Dialog,
  LoadingIndicator,
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSignInStore } from "../../store/Hooks";
import { observer } from "mobx-react";
import { useProfileStore } from "../store/Hooks";
import { DetailSectionComponent } from "../../../components/DetailSectionComponent";
import { ProfileStore, UpdateUserNameErrors } from "../store/ProfileStore";
import { Stack } from "@mui/material";
import { SettingsContainer } from "../../../components/SettingsContainer";
import { DividerComponent } from "../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";
import { ErrorSuccessDialogContent } from "../../projects/components/ErrorSuccessDialogContent";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { navigateOrGetNetworkErrorPage } from "../../../utility/navigateOrGetNetworkErrorPage";
import { useRouteNavigator } from "../../../routes/RoutesHelper";
import { ResetUserPasswordDialog } from "../components/ResetUserPasswordDialog";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import {
  MAX_USERNAME_CHAR_LIMIT,
  MIN_USERNAME_CHAR_LIMIT,
} from "@pulse/shared-components/src/constants";

interface ResetPasswordProps {
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  profileStore: Instance<typeof ProfileStore>;
}

const ResetPassword = observer(
  ({
    t,
    spacing,
    typography,
    tokens,
    profileStore,
  }: ResetPasswordProps): React.ReactElement => {
    const [
      isResetUserPasswordDialogVisible,
      setIsResetUserPasswordDialogVisible,
    ] = useState(false);

    return (
      <>
        <Button
          name="resetPassword"
          label={t("common.profileSettings.resetPassword")}
          onClick={() => {
            setIsResetUserPasswordDialogVisible(true);
          }}
          size="medium"
          variant="outlined-neutral"
        />
        {isResetUserPasswordDialogVisible && (
          <ResetUserPasswordDialog
            isResetUserPasswordDialogVisible={isResetUserPasswordDialogVisible}
            setIsResetUserPasswordDialogVisible={
              setIsResetUserPasswordDialogVisible
            }
            t={t}
            spacing={spacing}
            profileStore={profileStore}
            typography={typography}
            tokens={tokens}
          />
        )}
      </>
    );
  },
);

export const ProfileSettingsPage = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const spacing = useSpacing();
  const signInStore = useSignInStore();
  const profileStore = useProfileStore();
  const errorStore = useNetworkingErrorStore();
  const navigateTo = useRouteNavigator();

  useEffect(() => {
    profileStore.resetStore();
  }, []);

  const errorPage = navigateOrGetNetworkErrorPage(
    errorStore.error,
    navigateTo,
    errorStore.removeError,
  );

  if (errorPage) {
    errorStore.removeError();
    return errorPage;
  }

  const getFirstNameTextFieldHelperText = (): string | undefined => {
    switch (profileStore.rpcError) {
      case UpdateUserNameErrors.InvalidFirstName:
        return t("common.invalidFirstNameLengthHelperText", {
          minFirstNameLength: MIN_USERNAME_CHAR_LIMIT,
          maxFirstNameLength: MAX_USERNAME_CHAR_LIMIT,
        });
    }
  };

  const getLastNameTextFieldHelperText = (): string | undefined => {
    switch (profileStore.rpcError) {
      case UpdateUserNameErrors.InvalidLastName:
        return t("common.invalidLastNameLengthHelperText", {
          minLastNameLength: MIN_USERNAME_CHAR_LIMIT,
          maxLastNameLength: MAX_USERNAME_CHAR_LIMIT,
        });
    }
  };

  const getErrorDialogTitle = (): string | undefined => {
    switch (profileStore.rpcError) {
      case UpdateUserNameErrors.UnableToUpdateUserName:
        return t("common.failedToSaveChanges");
    }
  };

  const getSaveChangesErrorDialogText = (): string => {
    switch (profileStore.rpcError) {
      case UpdateUserNameErrors.UnableToUpdateUserName:
        return t("common.profileSettings.genericErrorDialogText");
      default:
        return t("common.profileSettings.genericErrorDialogText");
    }
  };

  const ActionElement = observer((): React.ReactElement => {
    return (
      <Button
        label={t("common.saveChangesButtonText")}
        name={t("common.saveChangesButtonText")}
        variant="filled"
        size="medium"
        onClick={async (): Promise<void> => {
          if (profileStore.isUserNameValid()) {
            await profileStore.updateUserName();
            signInStore.setUserName(
              profileStore.userName.firstName,
              profileStore.userName.lastName,
            );
          }
        }}
        disabled={
          profileStore.isSaveChangesButtonDisabled ||
          profileStore.userName.firstAndLastName ===
            signInStore.userName.firstAndLastName
        }
      />
    );
  });

  const ProfileSettingsPane = observer(() => {
    return (
      <>
        <Stack
          px={spacing.space2XL}
          width="100%"
          height="100%"
          alignItems="start"
          overflow="auto"
          divider={<DividerComponent orientation="horizontal" width="100%" />}
        >
          {profileStore.isUpdateUsernameRPCLoading && (
            <LoadingIndicator
              isLoading={profileStore.isUpdateUsernameRPCLoading}
              variant="overlay"
            />
          )}
          <SettingsContainer
            settingHeadingText={t("common.profileSettings.name")}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
          >
            <Stack width="444px" gap={spacing.spaceLG} direction="row">
              <TextInputField
                name="firstName"
                value={profileStore.userName.firstName}
                onTextChange={(value) => {
                  profileStore.resetRPCError();
                  profileStore.setFirstName(value);
                }}
                label={t("common.firstNameLabel")}
                type="text"
                placeholder={t("common.firstNamePlaceholder")}
                helperText={getFirstNameTextFieldHelperText()}
                error={profileStore.doesFirstNameTextFieldContainErrors}
                required={true}
              />
              <TextInputField
                name="lastName"
                value={profileStore.userName.lastName}
                onTextChange={(value) => {
                  profileStore.resetRPCError();
                  profileStore.setLastName(value);
                }}
                label={t("common.lastNameLabel")}
                type="text"
                placeholder={t("common.lastNamePlaceholder")}
                helperText={getLastNameTextFieldHelperText()}
                error={profileStore.doesLastNameTextFieldContainErrors}
                required={true}
              />
            </Stack>
          </SettingsContainer>
          <SettingsContainer
            settingHeadingText={t("common.profileSettings.resetPassword")}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
          >
            <ResetPassword
              spacing={spacing}
              profileStore={profileStore}
              t={t}
              tokens={tokens}
              typography={typography}
            />
          </SettingsContainer>
        </Stack>
        {profileStore.isUpdateUsernameErrorDialogVisible && (
          <Dialog
            open={profileStore.isUpdateUsernameErrorDialogVisible}
            title={getErrorDialogTitle()}
            secondaryButtonText={t("common.close")}
            onSecondaryButtonClick={() => {
              profileStore.resetErrorDialog();
              profileStore.resetStore();
            }}
          >
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={getSaveChangesErrorDialogText()}
            />
          </Dialog>
        )}
      </>
    );
  });

  return (
    <DetailSectionComponent
      mainHeaderProps={{
        title: t("common.profileSettings.profileSettingsTitle"),
        prefersLargeTitle: true,
        actionElement: <ActionElement />,
        backButtonCallback: (): void => {
          navigateTo.project();
        },
      }}
      isOverflowHidden={false}
      isChildHeightVariable={false}
      isManageUsersLogoVisible={true}
      isAuditLogsLogoVisible={true}
    >
      <ProfileSettingsPane />
    </DetailSectionComponent>
  );
});
