import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { Instance, types } from "mobx-state-tree";

export const AutoCompleteItemModel = types.model("AutoCompleteItemModel", {
  id: types.string,
  name: types.string,
});

export const createAutoCompleteItemModel = (
  id: string | null,
  name: string,
): Instance<typeof AutoCompleteItemModel> => {
  if (id) {
    return AutoCompleteItemModel.create({
      id,
      name,
    });
  } else {
    console.error("This flow is developer error");
    return AutoCompleteItemModel.create({
      id: EMPTY_CHARACTER,
      name,
    });
  }
};
