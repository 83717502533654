import { Instance } from "mobx-state-tree";
import React from "react";
import { BorderStyle } from "@pulse/shared-components";
import { Stack } from "@mui/material";
import {
  CornerRadius,
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { TranslationDataListHeader } from "./TranslationDataListHeader";
import { TranslationDataListElement } from "./TranslationDataListElement";
import { TranslationsStore } from "../../store/TranslationsStore";
import { observer } from "mobx-react";

export interface TranslationDataListProps {
  translationsStore: Instance<typeof TranslationsStore>;
  border: BorderStyle;
  borderRadius: CornerRadius;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  spacing: Spacing;
}

export const TranslationDataList = observer(
  ({
    translationsStore,
    border,
    borderRadius,
    typography,
    tokens,
    t,
    spacing,
  }: TranslationDataListProps): React.ReactElement => {
    /* The layout is not structured the exact way as the design because, that will require us to map through
     *  selectedTranslationText twice, reducing the efficiency drastically. There are also other issues
     *  with regards to the individual container heights that pop-up when the figma design structure is
     *  used.
     */
    return (
      <Stack
        width="1376px"
        border={border.default}
        borderRadius={borderRadius.radiusXS}
        alignItems="center"
        justifyContent="center"
      >
        <TranslationDataListHeader
          baseLanguageCode={translationsStore.baseLanguage?.languageCode}
          translatedLanguageCode={
            translationsStore.translatedLanguage?.languageCode
          }
          border={border}
          typography={typography}
          tokens={tokens}
          t={t}
          spacing={spacing}
        />
        {translationsStore.selectedTranslationText.map(
          (translationText, index) => {
            return (
              <TranslationDataListElement
                key={translationText.translationId}
                border={border}
                typography={typography}
                tokens={tokens}
                spacing={spacing}
                baseLanguageText={translationText.baseLanguageText}
                translatedLanguageText={translationText.translatedLanguageText}
                isBackgroundColorDark={index % 2 === 0}
                isLastElement={translationsStore.isLastTranslationElement(
                  index,
                )}
                borderRadius={borderRadius}
              />
            );
          },
        )}
      </Stack>
    );
  },
);
