import { Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import {
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { BorderStyle, getCheckBoxState } from "@pulse/shared-components";
import { TFunction } from "i18next";
import { Checkbox } from "@surya-digital/leo-reactjs-material-ui";
import React from "react";

export interface NPSOptionsProps {
  typography: LeoTypography;
  spacing: Spacing;
  border: BorderStyle;
  t: TFunction;
  checkBoxState: boolean;
  checkBoxOnChange: (value: boolean) => void;
}
export const NPSOptions = observer(
  ({
    t,
    typography,
    spacing,
    border,
    checkBoxOnChange,
    checkBoxState,
  }: NPSOptionsProps): React.ReactElement => {
    return (
      <Stack gap={spacing.spaceSM}>
        <Typography
          sx={{
            ...typography.sh3,
            padding: `${spacing.spaceSM} 0px`,
            borderBottom: border.default,
          }}
        >
          {t("surveys.addSurveyQuestion.singleChoice.npsSettings")}
        </Typography>
        <Stack padding={`${spacing.spaceXS} 0px`}>
          <Checkbox
            label={t(
              "surveys.addSurveyQuestion.singleChoice.showSelectionInColor",
            )}
            state={getCheckBoxState(checkBoxState)}
            onChange={checkBoxOnChange}
          />
        </Stack>
      </Stack>
    );
  },
);
