import { BOUserDetails } from "@pulse/pulse-rpcs";
import {
  NAME_AND_EMAIL_SEPARATOR,
  SINGLE_SPACING,
} from "@pulse/shared-components";
import { Instance, types } from "mobx-state-tree";

export const BOUsersDetailsModel = types
  .model("BOUsersDetailsModel", {
    fullName: types.string,
    emailAddress: types.string,
    boUserId: types.string,
  })
  .views((store) => ({
    get searchBOUserDropdownText(): string {
      return (
        store.fullName +
        SINGLE_SPACING +
        NAME_AND_EMAIL_SEPARATOR +
        SINGLE_SPACING +
        store.emailAddress
      );
    },
  }));

export const createBOUsersDetailsModel = (
  boUserDetails: BOUserDetails,
): Instance<typeof BOUsersDetailsModel> => {
  return BOUsersDetailsModel.create({
    emailAddress: boUserDetails.emailId.email,
    fullName:
      boUserDetails.userName.firstName +
      SINGLE_SPACING +
      boUserDetails.userName.lastName,
    boUserId: boUserDetails.boUserId.uuid,
  });
};
