import { PRIMARY_COLORS } from "@pulse/shared-components";
import React from "react";
import { Bar } from "react-chartjs-2";
import { scales, options } from "./SurveySubmissionDataPane";
import { TFunction } from "i18next";

export interface BarChartProps {
  labels: string[];
  submissionData: number[];
  t: TFunction;
}

export const BarChart = ({
  labels,
  submissionData,
  t,
}: BarChartProps): React.ReactElement => {
  return (
    <Bar
      options={{
        scales: {
          ...scales,
        },
        ...options,
      }}
      data={{
        labels,
        datasets: [
          {
            xAxisID: "xAxis",
            yAxisID: "yAxis",
            label: t("projects.dashboard.dailySubmissionsText"),
            data: submissionData,
            backgroundColor: PRIMARY_COLORS[300],
          },
        ],
      }}
    />
  );
};
