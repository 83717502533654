import { GetRespondentDetailsRPC, SurveyLinkStatus } from "@pulse/pulse-rpcs";
import { ChipProps, TableHeader } from "@surya-digital/leo-reactjs-material-ui";
import i18next from "i18next";
import { Instance } from "mobx-state-tree";
import { RespondentColumnDetailsModel } from "../projects/models/RespondentColumnDetailsModel";
import { RespondentFilters } from "../projects/components/RespondentsPane";
import { EMPTY_CHARACTER } from "@pulse/shared-components";

const getColumnHeaders = (
  respondentColumns: Instance<typeof RespondentColumnDetailsModel>[],
): {
  columnId: string;
  label: string;
  width: string;
}[] => {
  return respondentColumns.map((column) => {
    return {
      columnId: `column${column.index}`,
      label: column.name,
      width: "280px",
    };
  });
};

export const getRespondentListHeaders = (
  respondentColumns: Instance<typeof RespondentColumnDetailsModel>[],
): TableHeader => {
  return [
    {
      columnId: GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.ID,
      label: i18next.t("projects.respondentList.columnNames.id"),
      width: "120px",
      sortable: true,
    },
    {
      columnId:
        GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.FIRST_NAME,
      label: i18next.t("projects.respondentList.columnNames.firstName"),
      width: "184px",
      sortable: true,
    },
    {
      columnId:
        GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.LAST_NAME,
      label: i18next.t("projects.respondentList.columnNames.lastName"),
      width: "184px",
      sortable: true,
    },
    {
      columnId:
        GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.EMAIL_ID,
      label: i18next.t("projects.respondentList.columnNames.emailAddress"),
      width: "208px",
      sortable: true,
    },
    {
      columnId: "PhoneNumber",
      label: i18next.t("projects.respondentList.columnNames.phoneNumber"),
      width: "200px",
    },
    {
      columnId: "SurveyName",
      label: i18next.t("projects.respondentList.columnNames.survey"),
      width: "280px",
    },
    {
      columnId: "SurveyStatus",
      label: i18next.t("projects.respondentList.columnNames.surveyStatus"),
      width: "200px",
    },
  ]
    .concat(getColumnHeaders(respondentColumns))
    .concat({
      columnId: "IndividualRespondentAction",
      label: EMPTY_CHARACTER,
      width: "80px",
    });
};

export const getSurveyLinkStatusChipProps = (
  surveyStatus: SurveyLinkStatus.SurveyLinkStatus,
): ChipProps => {
  switch (surveyStatus) {
    case SurveyLinkStatus.SurveyLinkStatus.SUBMITTED:
      return {
        color: "greenSubtle",
        label: i18next.t("surveyLinkStatus.submitted"),
      };
    case SurveyLinkStatus.SurveyLinkStatus.NOT_ASSIGNED:
      return {
        color: "neutral",
        label: i18next.t("surveyLinkStatus.unassigned"),
      };
    case SurveyLinkStatus.SurveyLinkStatus.NOT_STARTED:
      return {
        color: "redSubtle",
        label: i18next.t("surveyLinkStatus.notStarted"),
      };
    case SurveyLinkStatus.SurveyLinkStatus.VIEWED:
      return {
        color: "blueSubtle",
        label: i18next.t("surveyLinkStatus.viewed"),
      };
    case SurveyLinkStatus.SurveyLinkStatus.STARTED:
      return {
        color: "blueSubtle",
        label: i18next.t("surveyLinkStatus.started"),
      };
    case SurveyLinkStatus.SurveyLinkStatus.LAST_PAGE:
      return {
        color: "blueSubtle",
        label: i18next.t("surveyLinkStatus.lastPage"),
      };
    case SurveyLinkStatus.SurveyLinkStatus.IN_PROGRESS:
      return {
        color: "blueSubtle",
        label: i18next.t("surveyLinkStatus.inProgress"),
      };
    case SurveyLinkStatus.SurveyLinkStatus.ASSIGNING:
      return {
        color: "redSubtle",
        label: i18next.t("surveyLinkStatus.assigning"),
      };
  }
};

export const filterList: { key: string; label: string }[] = [
  {
    key: RespondentFilters.Id,
    label: i18next.t("projects.respondentList.columnNames.id"),
  },
  {
    key: RespondentFilters.FirstName,
    label: i18next.t("projects.respondentList.columnNames.firstName"),
  },
  {
    key: RespondentFilters.LastName,
    label: i18next.t("projects.respondentList.columnNames.lastName"),
  },
  {
    key: RespondentFilters.EmailAddress,
    label: i18next.t("projects.respondentList.columnNames.emailAddress"),
  },
  {
    key: RespondentFilters.PhoneNumber,
    label: i18next.t("projects.respondentList.columnNames.phoneNumber"),
  },
  {
    key: RespondentFilters.SurveyStatus,
    label: i18next.t("projects.respondentList.columnNames.surveyStatus"),
  },
];
