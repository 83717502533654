import {
  EMPTY_CHARACTER,
  REGEX_FOR_EMAIL_VALIDATION,
} from "@pulse/shared-components";
import { Instance, flow, getRoot, types } from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import { AddBOUserRPC, KeycloakEmail, UserName } from "@pulse/pulse-rpcs";
import { useAddBOUserRPCClientImpl } from "../rpcs/RPC";
import { RootStore } from "../../root/store/RootStore";
import {
  BOUserDetailsModel,
  createBOUserDetailsModel,
} from "../../models/BOUserDetailsModel";

export enum AddBOUserDialogState {
  AddBOUser = "AddBOUser",
  Error = "Error",
  FetchingRoles = "FetchingRoles",
}

export enum AddBOUserRPCError {
  InvalidRoleId = "INVALID_ROLE_ID",
  BOUserAlreadyExists = "BO_USER_ALREADY_EXISTS",
  SPoCCannotBeBOUser = "SPOC_CANNOT_BE_BO_USER",
  UnableToAddBOUser = "UNABLE_TO_ADD_BO_USER",
}

export const AddBOUserStore = types
  .model("AddBOUserStore", {
    boUserDetails: BOUserDetailsModel,
    emailAddress: types.optional(types.string, EMPTY_CHARACTER),
    addBOUserDialogState: types.optional(
      types.enumeration(
        "AddBOUserDialogState",
        Object.values(AddBOUserDialogState),
      ),
      AddBOUserDialogState.AddBOUser,
    ),
    rpcError: types.maybeNull(
      types.enumeration("AddBOUserRPCError", Object.values(AddBOUserRPCError)),
    ),
    isRPCLoading: types.optional(types.boolean, false),
    isAddBOButtonClicked: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get isPrimaryButtonDisabled(): boolean {
      return (
        store.emailAddress === EMPTY_CHARACTER ||
        store.boUserDetails.areUserDetailFieldsEmpty
      );
    },
    get isFirstNameInvalid(): boolean {
      return (
        store.boUserDetails.isFirstNameInvalid && store.isAddBOButtonClicked
      );
    },
    get isLastNameInvalid(): boolean {
      return (
        store.boUserDetails.isLastNameInvalid && store.isAddBOButtonClicked
      );
    },
    get isEmailAddressInvalid(): boolean {
      try {
        new KeycloakEmail(store.emailAddress);
        const regex = new RegExp(REGEX_FOR_EMAIL_VALIDATION);
        if (!regex.test(store.emailAddress)) {
          return true && store.isAddBOButtonClicked;
        }
        return false;
      } catch (e) {
        return true && store.isAddBOButtonClicked;
      }
    },
    get isBOUserAlreadyExistsError(): boolean {
      return store.rpcError === AddBOUserRPCError.BOUserAlreadyExists;
    },
  }))
  .views((store) => ({
    get areTextFieldsErrored(): boolean {
      return (
        store.isEmailAddressInvalid ||
        store.isFirstNameInvalid ||
        store.isLastNameInvalid
      );
    },
  }))
  .actions((store) => ({
    resetStoreData: (): void => {
      store.boUserDetails.resetStore();
      store.emailAddress = EMPTY_CHARACTER;
      store.isRPCLoading = false;
      store.addBOUserDialogState = AddBOUserDialogState.FetchingRoles;
      store.rpcError = null;
    },
    setIsAddBOButtonClicked: (isButtonClicked: boolean): void => {
      store.isAddBOButtonClicked = isButtonClicked;
    },
    setDialogState: (dialogState: AddBOUserDialogState): void => {
      store.addBOUserDialogState = dialogState;
    },
    setEmailAddress: (emailAddress: string): void => {
      store.isAddBOButtonClicked = false;
      store.emailAddress = emailAddress;
    },
    addBOUser: flow(function* () {
      store.rpcError = null;
      if (
        store.areTextFieldsErrored ||
        store.boUserDetails.selectedRole === null
      ) {
        return;
      }
      try {
        store.isRPCLoading = true;
        const apiClient = getAPIClient(store);
        const request = new AddBOUserRPC.Request(
          new UserName(
            store.boUserDetails.firstName,
            store.boUserDetails.lastName,
          ),
          new KeycloakEmail(store.emailAddress),
          store.boUserDetails.selectedRole,
        );
        const {
          response,
          error,
        }: {
          response?: AddBOUserRPC.Response;
          error?: AddBOUserRPC.Errors.Errors;
        } = yield useAddBOUserRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.boUserDetails.setFirstName(
            store.boUserDetails.firstName.trim(),
          );
          store.boUserDetails.setLastName(store.boUserDetails.lastName.trim());
        } else if (error) {
          store.addBOUserDialogState = AddBOUserDialogState.Error;
          switch (error.code) {
            case AddBOUserRPCError.BOUserAlreadyExists:
              store.rpcError = AddBOUserRPCError.BOUserAlreadyExists;
              break;
            case AddBOUserRPCError.InvalidRoleId:
              store.rpcError = AddBOUserRPCError.InvalidRoleId;
              break;
            case AddBOUserRPCError.SPoCCannotBeBOUser:
              store.rpcError = AddBOUserRPCError.SPoCCannotBeBOUser;
              break;
            case AddBOUserRPCError.UnableToAddBOUser:
              store.rpcError = AddBOUserRPCError.UnableToAddBOUser;
              break;
            default:
              console.error(`Unhandled RPCError ${error.code}`);
              break;
          }
        }
      } catch (e) {
        store.addBOUserDialogState = AddBOUserDialogState.Error;
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
      store.isRPCLoading = false;
      store.isAddBOButtonClicked = false;
    }),
  }));

export const createAddBOUserStore = (): Instance<typeof AddBOUserStore> => {
  return AddBOUserStore.create({ boUserDetails: createBOUserDetailsModel() });
};
