import { GetAuditLogsRPC, GetAuditLogsRPCClientImpl } from "@pulse/pulse-rpcs";
import { MOCK } from "@pulse/shared-components";
import { APIClient } from "@surya-digital/tedwig";
import { MockGetAuditLogsRPCClientImpl } from "./MockGetAuditLogsRPCClient";

export const useGetAuditLogsRPCClient = (
  apiClient: APIClient,
): GetAuditLogsRPC => {
  if (MOCK.auditLogs) {
    return new MockGetAuditLogsRPCClientImpl();
  } else {
    return new GetAuditLogsRPCClientImpl(apiClient);
  }
};
