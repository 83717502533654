import {
  Table,
  TableHeader,
  TableRowItem,
  Typography as LeoTypography,
  ListX,
  FoundationColorTokens,
  IconButton,
  Spacing,
  Dialog,
  TableReloadHandle,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useRef, useState } from "react";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { StyledEllipsisTypography } from "../../../../components/StyledEllipsisTypography";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  IconContainer,
} from "@pulse/shared-components";
import { Stack } from "@mui/material";
import { getFormattedDateString } from "@pulse/shared-components";
import { UserPrivileges } from "../../../store/user/UserPrivileges";
import { SignInStore } from "../../../auth/store/SignInStore";
import { UserCog, Trash2Icon } from "lucide-react";
import { DialogType } from "../spocManagement/AssignAndViewSpocsDialog";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";
import { AssignAndViewSpocSelection } from "../spocManagement/AssignRespondentSelection";
import { ICON_SIZE } from "@pulse/shared-components";
import { DeleteEmbedDialog } from "./DeleteEmbedDialog";

export enum EmbedListHeaderEnum {
  EmbedName = "Embed Name",
  CreatedOn = "Created On",
  EmbedAction = "Embed Action",
}

export interface EmbedTableProps {
  t: TFunction;
  navigateTo: NavigateToFunctions;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  signInStore: Instance<typeof SignInStore>;
  spacing: Spacing;
  projectDetailsStore: Instance<typeof ProjectDetailsStore>;
  border: BorderStyle;
  borderRadius: CornerRadius;
}

export const EmbedTable = observer(
  ({
    t,
    projectDetailsStore,
    navigateTo,
    typography,
    tokens,
    signInStore,
    spacing,
    border,
    borderRadius,
  }: EmbedTableProps): React.ReactElement => {
    const embedTableRef = useRef<TableReloadHandle | null>(null);
    const [isDeleteEmbedDialogVisible, setIsDeleteEmbedDialogVisible] =
      useState(false);
    const [isViewSpocsDialogVisible, setIsViewSpocsDialogVisible] =
      useState(false);
    const embedStore = projectDetailsStore.reportStore.embedStore;

    const getHeaders = (): TableHeader => {
      return [
        {
          columnId: EmbedListHeaderEnum.EmbedName,
          label: t("projects.reports.embeds.embedName"),
          width: "772px",
          sortable: false,
        },
        {
          columnId: EmbedListHeaderEnum.CreatedOn,
          label: t("projects.reports.embeds.addedOn"),
          width: "400px",
          sortable: false,
        },
        {
          columnId: EmbedListHeaderEnum.EmbedAction,
          label: EMPTY_CHARACTER,
          width: "fit-content",
        },
      ];
    };

    const getEmbedDetails = async (): Promise<string | TableRowItem[]> => {
      await processProjectParams(async (projectId) => {
        await embedStore.getEmbedDetails(projectId);
      }, navigateTo);

      return embedStore.embedDetails.map((embedDetails) => {
        const embedCells = [
          {
            data: (
              <StyledEllipsisTypography
                data={embedDetails.embedName}
                typography={typography}
                t={t}
              />
            ),
            columnId: EmbedListHeaderEnum.EmbedName,
          },
          {
            data: (
              <StyledEllipsisTypography
                data={getFormattedDateString(embedDetails.addedOn)}
                typography={typography}
                t={t}
              />
            ),
            columnId: EmbedListHeaderEnum.CreatedOn,
          },
          {
            columnId: EmbedListHeaderEnum.EmbedAction,
            data: (
              <Stack flexDirection="row" gap={spacing.spaceXS} minWidth="80px">
                {signInStore.isPrivilegeGranted(
                  UserPrivileges.processEmbed,
                ) && (
                  <IconButton
                    icon={<UserCog />}
                    color="primary"
                    name="viewSpocs"
                    variant="plain-neutral"
                    size="small"
                    onClick={() => {
                      embedStore.setSelectedEmbedSpocDetails(embedDetails.id);
                      setIsViewSpocsDialogVisible(true);
                    }}
                  />
                )}
                {signInStore.isPrivilegeGranted(
                  UserPrivileges.processEmbed,
                ) && (
                  <IconButton
                    icon={<Trash2Icon />}
                    color="destructive"
                    name="deleteButton"
                    variant="plain-color"
                    size="small"
                    onClick={() => {
                      embedStore.setSelectedEmbedSpocDetails(embedDetails.id);
                      setIsDeleteEmbedDialogVisible(true);
                    }}
                  />
                )}
              </Stack>
            ),
          },
        ];

        return {
          id: `${embedDetails.id}`,
          cells: embedCells,
        };
      });
    };
    return (
      <Stack width="100%" alignItems="center">
        {isViewSpocsDialogVisible && (
          <Dialog
            width="560px"
            open={isViewSpocsDialogVisible}
            title={t("projects.viewSpocs.dialogTitleText")}
            secondaryButtonText={t("common.close")}
            isSecondaryButtonDisabled={embedStore.isRPCLoading}
            onSecondaryButtonClick={() => {
              setIsViewSpocsDialogVisible(false);
              embedStore.clearSelectedEmbedDetails();
            }}
            disableBackdropClick={true}
            contentPadding={"0px"}
          >
            <AssignAndViewSpocSelection
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              t={t}
              border={border}
              dialogType={DialogType.ViewSpocs}
              projectDetailsStore={projectDetailsStore}
              searchSpocFieldPadding={spacing.spaceLG}
              searchSpocFieldGap={spacing.spaceXS}
              searchSpocFieldDescription={undefined}
              selectedSpocListPadding={spacing.spaceLG}
              selectedSpocListGap={spacing.spaceSM}
              selectedSpocElementPadding={undefined}
              noSpocsFoundContainerGap={spacing.spaceLG}
              noSpocsFoundContainerPadding={spacing.spaceSM}
              isSearchProjectSpocFieldDisabled={
                projectDetailsStore.reportStore.embedStore.isEmbedSpocListEmpty
              }
              matchedSpocs={embedStore.matchedEmbedSpocs}
              noSpocsFoundText={t(
                "projects.viewSpocs.noEmbedSpocsAssignedText",
              )}
              selectedRespondentOrEmbedName={
                embedStore.selectedEmbedDetails?.embedName
              }
              searchSpocFieldTitle={t("projects.viewSpocs.embedText")}
              isSpocListEmpty={embedStore.isEmbedSpocListEmpty}
            />
          </Dialog>
        )}
        {isDeleteEmbedDialogVisible && (
          <DeleteEmbedDialog
            isDeleteEmbedDialogVisible={isDeleteEmbedDialogVisible}
            setIsDeleteEmbedDialogVisible={setIsDeleteEmbedDialogVisible}
            t={t}
            embedStore={embedStore}
            navigateTo={navigateTo}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
            embedTableRef={embedTableRef}
          />
        )}
        <Table
          ref={embedTableRef}
          header={getHeaders()}
          onTableOptionsChange={getEmbedDetails}
          name="EmbedTable"
          viewOverrides={{
            empty: {
              icon: IconContainer(
                <ListX
                  width={ICON_SIZE.large}
                  height={ICON_SIZE.large}
                  color={tokens.iconLowEmphasis}
                />,
              ),
              message: t("projects.reports.embeds.embedSection.noEmbedsAdded"),
            },
            loading: { message: t("common.searchTableLoadingState") },
          }}
          styleOverrides={{
            background: tokens.backgroundElevatedLevel1,
            divider: "row",
            maxWidth: "1376px",
            border: "0px",
            borderRadius: `0px 0px ${borderRadius.radiusXS} ${borderRadius.radiusXS}`,
          }}
          leftPinnedColumnIds={[]}
          rightPinnedColumnIds={[]}
        />
      </Stack>
    );
  },
);
