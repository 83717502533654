import {
  CLIENT_LOGO_FILE_TYPE,
  MAX_CLIENT_LOGO_FILE_SIZE_IN_BYTES,
  MAX_FILE_NAME,
  MIN_CLIENT_LOGO_FILE_SIZE_IN_BYTES,
  MIN_FILE_NAME,
  SHA_256_LENGTH,
} from "@pulse/shared-components";
import {
  Instance,
  flow,
  getParentOfType,
  getRoot,
  types,
} from "mobx-state-tree";
import { getFileSHA, uploadFileToS3 } from "../../utils/FileUploadUtils";
import {
  FileAttributes,
  GetPresignedUploadURLForClientLogoRPC,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "../../networking/APIClient";
import { insertDocumentRecord } from "../../utils/FileDownloadUtils";
import { useGetPresignedUploadURLForClientLogoRPCClientImpl } from "../rpcs/RPC";
import { RootStore } from "../../root/store/RootStore";
import {
  BackOfficeSurveySettingsStore,
  CommonProjectAlreadyArchivedRPCError,
} from "./BackOfficeSurveySettingsStore";

export enum UploadClientLogoError {
  InvalidFileSHA = "InvalidFileSHA",
  InternalError = "InternalError",
  InvalidFileFormat = "InvalidFileFormat",
  InvalidFileName = "InvalidFileName",
  MaxFileSizeReached = "MaxFileSizeExceeded",
  MinFileSizeNotReached = "MinFileSizeNotReached",
}

export const UploadClientLogoStore = types
  .model("UploadClientLogoStore", {
    fileName: types.maybeNull(types.string),
    hasFileBeenUploaded: types.optional(types.boolean, false),
    fileUploadError: types.maybeNull(
      types.enumeration(
        "UploadClientLogoError",
        Object.values(UploadClientLogoError),
      ),
    ),
  })
  .actions((store) => ({
    setFileName: (filename: string): void => {
      store.fileName = filename;
    },
    resetFileUploadError: (): void => {
      store.fileUploadError = null;
    },
    getFileAttribute: flow(function* (file: File) {
      const backOfficeSurveySettingsStore = getParentOfType(
        store,
        BackOfficeSurveySettingsStore,
      );
      const setIsErrorBannerVisible = (): void => {
        backOfficeSurveySettingsStore.updatedBackOfficeSurveySettings?.backOfficeSurveySettingsUIStore.setIsErrorSnackbarVisible(
          true,
        );
      };
      if (!CLIENT_LOGO_FILE_TYPE.includes(file.type)) {
        store.fileUploadError = UploadClientLogoError.InvalidFileFormat;
        setIsErrorBannerVisible();
        return;
      }
      const sha256 = yield getFileSHA(file);
      try {
        if (sha256.length !== SHA_256_LENGTH) {
          store.fileUploadError = UploadClientLogoError.InvalidFileSHA;
          return;
        } else if (
          file.name.length < MIN_FILE_NAME ||
          file.name.length > MAX_FILE_NAME
        ) {
          store.fileUploadError = UploadClientLogoError.InvalidFileName;
          return;
        } else if (file.size > MAX_CLIENT_LOGO_FILE_SIZE_IN_BYTES) {
          store.fileUploadError = UploadClientLogoError.MaxFileSizeReached;
          setIsErrorBannerVisible();
          return;
        } else if (file.size < MIN_CLIENT_LOGO_FILE_SIZE_IN_BYTES) {
          store.fileUploadError = UploadClientLogoError.MinFileSizeNotReached;
          setIsErrorBannerVisible();
          return;
        }

        const fileAttribute = new FileAttributes(sha256, file.name, file.size);
        return fileAttribute;
      } catch (error) {
        store.fileUploadError = UploadClientLogoError.InternalError;
      }
    }),
  }))
  .actions((store) => ({
    uploadLogo: flow(function* (
      surveyId: string,
      projectId: string,
      file: File,
      updateImageUrl: (url: string) => void,
      updateLogoId: (logoId: string) => void,
    ) {
      try {
        const apiClient: APIClient = getAPIClient(store);
        const fileAttribute: FileAttributes | undefined =
          yield store.getFileAttribute(file);
        if (fileAttribute === undefined) {
          return;
        }
        const {
          response,
          error,
        }: {
          response?: GetPresignedUploadURLForClientLogoRPC.Response;
          error?: GetPresignedUploadURLForClientLogoRPC.Errors.Errors;
        } = yield useGetPresignedUploadURLForClientLogoRPCClientImpl(
          apiClient,
        ).execute(
          new GetPresignedUploadURLForClientLogoRPC.Request(
            fileAttribute,
            new LeoUUID(surveyId),
            new LeoUUID(projectId),
          ),
        );

        if (response) {
          try {
            yield uploadFileToS3(response.documentURL, file);
            const documentId = yield insertDocumentRecord(
              fileAttribute,
              apiClient,
            );
            updateLogoId(documentId);
            store.hasFileBeenUploaded = true;
            updateImageUrl(response.imageURL.href);
          } catch (e) {
            store.hasFileBeenUploaded = false;
            store.fileUploadError = UploadClientLogoError.InternalError;
          }
        } else if (error) {
          switch (error.code) {
            case CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived: {
              const backOfficeSurveySettingsStore = getParentOfType(
                store,
                BackOfficeSurveySettingsStore,
              );
              backOfficeSurveySettingsStore.setRPCError(
                CommonProjectAlreadyArchivedRPCError.ProjectAlreadyArchived,
              );
              backOfficeSurveySettingsStore.setIsUpdateSurveySettingsErrorDialogVisible(
                true,
              );

              break;
            }
            default:
              console.error(
                `Unhandled error ${error} found in upload logo action.`,
              );
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
  }));

export const createUploadClientLogoStore = (): Instance<
  typeof UploadClientLogoStore
> => {
  return UploadClientLogoStore.create();
};
