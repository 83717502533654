import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  TableReloadHandle,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useEffect, useState } from "react";
import { Instance } from "mobx-state-tree";
import { TFunction } from "i18next";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { NestedTypographyDestructiveComponent } from "../NestedTypographyDestructiveComponent";
import { ManageBOUserStore } from "../../store/ManageBOUserStore";
import {
  DeleteProjectBOUserDialogState,
  DeleteProjectBOUsersRPCErrors,
} from "../../store/DeleteProjectBOUsersStore";
import { ErrorSuccessDialogContent } from "../ErrorSuccessDialogContent";
import { observer } from "mobx-react";
import {
  TOTAL_COUNTDOWN_TIME_IN_SEC,
  COUNTDOWN_INTERVAL_IN_MS,
  END_OF_COUNTDOWN,
} from "@pulse/shared-components";
import { encloseStringWithinQuotes } from "@pulse/shared-components";

interface DeleteProjectBOUserDialogProps {
  isDeleteProjectBOUserDialogDialogOpen: boolean;
  setIsDeleteProjectBOUserDialogDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSelectedBOUser: React.Dispatch<
    React.SetStateAction<{ name: string; boUserId: string } | undefined>
  >;
  manageBOUserStore: Instance<typeof ManageBOUserStore>;
  spacing: Spacing;
  t: TFunction;
  selectedBOUser: { name: string; boUserId: string } | undefined;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
  navigateTo: NavigateToFunctions;
}

export const DeleteProjectBOUserDialog = observer(
  ({
    isDeleteProjectBOUserDialogDialogOpen,
    setIsDeleteProjectBOUserDialogDialogOpen,
    manageBOUserStore,
    spacing,
    t,
    selectedBOUser,
    tokens,
    typography,
    setSelectedBOUser,
    navigateTo,
    tableRef,
  }: DeleteProjectBOUserDialogProps): React.ReactElement => {
    const [primaryButtonCountDown, setPrimaryButtonCountDown] = useState(
      TOTAL_COUNTDOWN_TIME_IN_SEC,
    );
    const deleteProjectBOUserStore = manageBOUserStore.deleteProjectBOUserStore;

    useEffect(() => {
      return () => {
        deleteProjectBOUserStore.setRemoveProjectBOUserDialogState(
          DeleteProjectBOUserDialogState.DeleteProjectBOUserState,
        );
      };
    }, []);

    useEffect(() => {
      const interval = setInterval(() => {
        setPrimaryButtonCountDown(primaryButtonCountDown - 1);
      }, COUNTDOWN_INTERVAL_IN_MS);
      if (primaryButtonCountDown === END_OF_COUNTDOWN) clearInterval(interval);
      return () => clearInterval(interval);
    }, [primaryButtonCountDown]);

    const getPrimaryButtonText = (): string | undefined => {
      switch (deleteProjectBOUserStore.deleteProjectBOUserDialogState) {
        case DeleteProjectBOUserDialogState.DeleteProjectBOUserState: {
          if (primaryButtonCountDown !== END_OF_COUNTDOWN) {
            return t("projects.userAccess.removeProjectBOUser.remove", {
              primaryButtonCountdown: primaryButtonCountDown,
            });
          } else {
            return t("common.remove");
          }
        }
      }
    };

    const getSecondaryButtonText = (): string => {
      switch (deleteProjectBOUserStore.deleteProjectBOUserDialogState) {
        case DeleteProjectBOUserDialogState.DeleteProjectBOUserState: {
          return t("common.cancel");
        }
        case DeleteProjectBOUserDialogState.ErrorState: {
          return t("common.close");
        }
        case DeleteProjectBOUserDialogState.SuccessState: {
          return t("common.dismiss");
        }
      }
    };

    const getNestedTypographyDestructiveComponentProps = (): {
      middleBoldText: string;
      endText: string;
    } => {
      if (selectedBOUser) {
        return {
          middleBoldText: encloseStringWithinQuotes(selectedBOUser.name),
          endText: t(
            "projects.userAccess.removeProjectBOUser.removeUserDescription",
          ),
        };
      } else {
        return {
          middleBoldText: `${manageBOUserStore.multiSelectTableStore.totalSelectedItems(manageBOUserStore.totalItems)}`,
          endText: t(
            "projects.userAccess.removeProjectBOUser.removeMultipleUsersDescription",
          ),
        };
      }
    };

    const getDialogErrorText = (): string => {
      switch (deleteProjectBOUserStore.rpcErrors) {
        case DeleteProjectBOUsersRPCErrors.InvalidBOUserId: {
          return t(
            "projects.userAccess.removeProjectBOUser.unexpectedErrorDescription",
          );
        }
        case DeleteProjectBOUsersRPCErrors.ProjectAlreadyArchived: {
          return t("common.projectAlreadyArchivedErrorText");
        }
        default: {
          return t(
            "projects.userAccess.removeProjectBOUser.unexpectedErrorDescription",
          );
        }
      }
    };

    const DialogChild = observer((): React.ReactElement => {
      switch (deleteProjectBOUserStore.deleteProjectBOUserDialogState) {
        case DeleteProjectBOUserDialogState.DeleteProjectBOUserState: {
          return (
            <NestedTypographyDestructiveComponent
              tokens={tokens}
              typography={typography}
              startText={t(
                "projects.userAccess.removeProjectBOUser.areYouSureYouWantToRemove",
              )}
              noteText={t(
                "projects.userAccess.removeProjectBOUser.removeUserNote",
              )}
              {...getNestedTypographyDestructiveComponentProps()}
              spacing={spacing}
            />
          );
        }
        case DeleteProjectBOUserDialogState.ErrorState: {
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={getDialogErrorText()}
            />
          );
        }
        case DeleteProjectBOUserDialogState.SuccessState: {
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={false}
              errorSuccessText={t(
                "projects.userAccess.removeProjectBOUser.removeBOUserFromProjectSuccessText",
              )}
            />
          );
        }
      }
    });

    return (
      <Dialog
        open={isDeleteProjectBOUserDialogDialogOpen}
        contentPadding={spacing.spaceLG}
        disableBackdropClick={true}
        primaryButtonColor="destructive"
        width="560px"
        isSecondaryButtonDisabled={deleteProjectBOUserStore.isRPCLoading}
        isPrimaryButtonDisabled={primaryButtonCountDown !== END_OF_COUNTDOWN}
        onPrimaryButtonClick={async (): Promise<void> => {
          await processProjectParams(
            async (projectId: string): Promise<void> => {
              await deleteProjectBOUserStore.deleteProjectBOUsers(
                projectId,
                selectedBOUser?.boUserId,
              );
            },
            navigateTo,
          );
        }}
        onSecondaryButtonClick={() => {
          setSelectedBOUser(undefined);
          setIsDeleteProjectBOUserDialogDialogOpen(false);
          tableRef.current?.reload();
        }}
        primaryButtonText={getPrimaryButtonText()}
        title={t(
          "projects.userAccess.removeProjectBOUser.removeUserWithquestionMark",
        )}
        secondaryButtonText={getSecondaryButtonText()}
      >
        {<DialogChild />}
      </Dialog>
    );
  },
);
