import { Stack } from "@mui/material";
import { PresetScales } from "@pulse/shared-components";
import {
  DropdownInputField,
  DropdownItem,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { SingleChoicePresets } from "../store/SingleChoiceQuestionV1Store";
import { getCamelCaseFromSingleChoicePresets } from "../../projects/utils/StringUtils";

const getScaleOptions = (): DropdownItem[] => {
  const presetScales = Object.values(PresetScales).filter(
    (presetScale) => !isNaN(Number(presetScale)),
  );
  const sortedPresetScales = presetScales.sort(
    (value1, value2) =>
      presetScales.indexOf(value2) - presetScales.indexOf(value1),
  );
  return sortedPresetScales.map((presetScale): DropdownItem => {
    return {
      name: presetScale.toString(),
      value: presetScale.toString(),
    };
  });
};

export interface ChoicePresetFieldsProps {
  isSingleChoicePresetScaleLow: boolean;
  isSingleChoicePresetScaleNps: boolean;
  presetTypeValue: string | undefined;
  presetScaleValue: string;
  onSelectPresetType: (value: DropdownItem) => void;
  onSelectPresetScale: (value: DropdownItem) => void;
}

export const ChoicePresetFields = observer(
  ({
    isSingleChoicePresetScaleLow,
    isSingleChoicePresetScaleNps,
    presetScaleValue,
    presetTypeValue,
    onSelectPresetScale,
    onSelectPresetType,
  }: ChoicePresetFieldsProps): React.ReactElement => {
    const spacing = useSpacing();
    const { t } = useTranslation();

    const getPresetTypeOptions = (): DropdownItem[] => {
      let singleChoicePresets = Object.values(SingleChoicePresets);

      if (isSingleChoicePresetScaleLow) {
        singleChoicePresets = singleChoicePresets.filter(
          (singleChoicePreset) =>
            singleChoicePreset === SingleChoicePresets.GoodPoor ||
            singleChoicePreset === SingleChoicePresets.Value,
        );
      } else if (isSingleChoicePresetScaleNps) {
        singleChoicePresets = singleChoicePresets.filter(
          (singleChoicePreset) =>
            singleChoicePreset === SingleChoicePresets.Nps,
        );
      } else {
        singleChoicePresets = singleChoicePresets.filter(
          (singleChoicePreset) =>
            singleChoicePreset === SingleChoicePresets.Agreement ||
            singleChoicePreset === SingleChoicePresets.Satisfaction ||
            singleChoicePreset === SingleChoicePresets.Willingness ||
            singleChoicePreset === SingleChoicePresets.Likelihood,
        );
      }
      return singleChoicePresets.map((singleChoicePreset): DropdownItem => {
        return {
          name: t(
            `surveys.addSurveyQuestion.singleChoice.singleChoicePresets.${getCamelCaseFromSingleChoicePresets(singleChoicePreset)}`,
          ),
          value: singleChoicePreset,
        };
      });
    };

    return (
      <Stack width="100%" direction="row" gap={spacing.spaceLG}>
        <DropdownInputField
          width="120px"
          minWidth="120px"
          name={t("surveys.addSurveyQuestion.singleChoice.scale")}
          label={t("surveys.addSurveyQuestion.singleChoice.scale")}
          value={presetScaleValue}
          options={getScaleOptions()}
          onSelect={onSelectPresetScale}
        />
        <DropdownInputField
          name={t("surveys.addSurveyQuestion.singleChoice.presetType")}
          label={t("surveys.addSurveyQuestion.singleChoice.presetType")}
          value={presetTypeValue}
          placeholder={t(
            "surveys.addSurveyQuestion.singleChoice.singleChoicePresets.select",
          )}
          options={getPresetTypeOptions()}
          onSelect={onSelectPresetType}
        />
      </Stack>
    );
  },
);
