import { Instance, cast, getParentOfType, types } from "mobx-state-tree";
import {
  MAXIMUM_GRID_COLUMN_OPTIONS,
  MultiChoiceQuestionV1Model,
  QuestionOptionsModel,
  createMultiChoiceQuestionV1Model,
  createQuestionOptionsModel,
} from "@pulse/shared-components";
import { QuestionDetailsModel } from "../models/QuestionDetailsModel";
import {
  EMPTY_CHARACTER,
  MAX_MULTI_CHOICE_OPTIONS,
  MIN_MULTI_CHOICE_OPTIONS,
} from "@pulse/shared-components";
import { doesCodeContainAllowedCharacters } from "@pulse/shared-components";
import {
  Code,
  MultiChoiceQuestionV1,
  MultiChoiceQuestionV1DTO,
  OptionV1,
  SurveyLocalizedText,
} from "@pulse/pulse-rpcs";
import { QuestionDetailsStore, FieldValidations } from "./QuestionDetailsStore";

export const MultiChoiceQuestionV1Store = types
  .model("MultiChoiceQuestionV1Store", {
    multiChoiceQuestionV1Model: MultiChoiceQuestionV1Model,
  })
  .views((store) => ({
    get optionV1ArrayObject(): OptionV1[] {
      return store.multiChoiceQuestionV1Model.options.map((option) => {
        return new OptionV1(
          new Code(option.code),
          /*The validation of option being empty is done onClick of save button.
           Even if option.option is empty (Which will be a developer error) it will be caught in the try catch where serializedJSON is being called.
          */
          new SurveyLocalizedText(option.option),
        );
      });
    },
  }))
  .views((store) => ({
    get serializedJSON(): string {
      const options = store.optionV1ArrayObject;
      return JSON.stringify(
        new MultiChoiceQuestionV1(
          options,
          store.multiChoiceQuestionV1Model.maxSelection,
          store.multiChoiceQuestionV1Model.isChoiceResetAllowed,
        ).toDTO(),
      );
    },
    doesOptionCodeContainErrors(index: number): boolean {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );
      return (
        (store.multiChoiceQuestionV1Model.options[index].code.trim() ===
          EMPTY_CHARACTER &&
          questionDetailsStore.fieldValidations ===
            FieldValidations.UnfilledOptionField) ||
        store.multiChoiceQuestionV1Model.options[index].code.trim() ===
          questionDetailsStore.duplicateOptionCode
      );
    },
    doesOptionContainErrors(index: number): boolean {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );
      return (
        (store.multiChoiceQuestionV1Model.options[index].option.trim() ===
          EMPTY_CHARACTER &&
          questionDetailsStore.fieldValidations ===
            FieldValidations.UnfilledOptionField) ||
        store.multiChoiceQuestionV1Model.options[index].option.trim() ===
          questionDetailsStore.duplicateOptionCode
      );
    },
    isAddNewFieldButtonDisabled(
      maxMultiChoiceOptions: number = MAX_MULTI_CHOICE_OPTIONS,
    ): boolean {
      return (
        store.multiChoiceQuestionV1Model.options.length >= maxMultiChoiceOptions
      );
    },
    isChevronUpDisabled(index: number): boolean {
      return index === 0;
    },
    isChevronDownDisabled(index: number): boolean {
      return index === store.multiChoiceQuestionV1Model.options.length - 1;
    },
    get isDeleteButtonDisabled(): boolean {
      return store.multiChoiceQuestionV1Model.options.length === 1;
    },
    get isResetDisabled(): boolean {
      if (store.multiChoiceQuestionV1Model.options.length === 1) {
        return (
          store.multiChoiceQuestionV1Model.options[0].code ===
            EMPTY_CHARACTER &&
          store.multiChoiceQuestionV1Model.options[0].option === EMPTY_CHARACTER
        );
      }
      return false;
    },
    isMaxSelectionIncrementDisabled(
      maxMultiChoiceOptions: number = MAX_MULTI_CHOICE_OPTIONS,
    ): boolean {
      return (
        store.multiChoiceQuestionV1Model.maxSelection >= maxMultiChoiceOptions
      );
    },
    get isMaxSelectionDecrementDisabled(): boolean {
      return (
        store.multiChoiceQuestionV1Model.maxSelection <=
        MIN_MULTI_CHOICE_OPTIONS
      );
    },
    get validateMaxSelection(): boolean {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );
      if (
        questionDetailsStore.fieldValidations ===
          FieldValidations.MaxSelectionExceeded ||
        questionDetailsStore.fieldValidations ===
          FieldValidations.MaxSelectionCannotBeLessThanValidValue
      ) {
        if (
          store.multiChoiceQuestionV1Model.maxSelection >
          store.multiChoiceQuestionV1Model.options.length
        ) {
          return true;
        } else if (
          store.multiChoiceQuestionV1Model.maxSelection <
          MIN_MULTI_CHOICE_OPTIONS
        ) {
          return true;
        }
      }
      return false;
    },
  }))
  .actions((store) => ({
    setIsChoiceResetAllowed: (isChoiceResetAllowed: boolean): void => {
      store.multiChoiceQuestionV1Model.isChoiceResetAllowed =
        isChoiceResetAllowed;
    },
    resetFieldValidationsAndErrors: (): void => {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );
      questionDetailsStore.setFieldValidations(null);
      questionDetailsStore.setRPCErrors(null);
      questionDetailsStore.setDuplicateOptionCode(null);
    },
  }))
  .actions((store) => ({
    setMaxSelection: (value: number): void => {
      store.multiChoiceQuestionV1Model.maxSelection = value;
    },
    incrementMaxSelection: (): void => {
      store.multiChoiceQuestionV1Model.maxSelection++;
    },
    decrementMaxSelection: (): void => {
      store.multiChoiceQuestionV1Model.maxSelection--;
    },
    setFieldOptionText: (fieldOptionText: string, index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.multiChoiceQuestionV1Model.options[index].option = fieldOptionText;
    },
    setCode: (code: string, index: number): void => {
      if (doesCodeContainAllowedCharacters(code)) {
        store.resetFieldValidationsAndErrors();
        store.multiChoiceQuestionV1Model.options[index].code = code;
      }
    },
    addNewField: (
      multiChoiceOptionField: Instance<typeof QuestionOptionsModel>,
      index: number,
    ): void => {
      store.resetFieldValidationsAndErrors();
      store.multiChoiceQuestionV1Model.options.splice(
        index + 1,
        0,
        multiChoiceOptionField,
      );
      store.multiChoiceQuestionV1Model.maxSelection =
        store.multiChoiceQuestionV1Model.options.length;
    },
    moveFieldUp: (index: number): void => {
      const multiChoiceOptionFieldArrayCopy = [
        ...store.multiChoiceQuestionV1Model.options,
      ];
      const replacedElement = multiChoiceOptionFieldArrayCopy[index - 1];
      multiChoiceOptionFieldArrayCopy[index - 1] =
        multiChoiceOptionFieldArrayCopy[index];
      multiChoiceOptionFieldArrayCopy[index] = replacedElement;
      store.multiChoiceQuestionV1Model.options.replace(
        multiChoiceOptionFieldArrayCopy,
      );
    },
    moveFieldDown: (index: number): void => {
      const multiChoiceFieldArrayCopy = [
        ...store.multiChoiceQuestionV1Model.options,
      ];
      const replacedElement = multiChoiceFieldArrayCopy[index + 1];
      multiChoiceFieldArrayCopy[index + 1] = multiChoiceFieldArrayCopy[index];
      multiChoiceFieldArrayCopy[index] = replacedElement;
      store.multiChoiceQuestionV1Model.options.replace(
        multiChoiceFieldArrayCopy,
      );
    },
    removeField: (index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.multiChoiceQuestionV1Model.options.splice(index, 1);
      store.multiChoiceQuestionV1Model.maxSelection =
        store.multiChoiceQuestionV1Model.options.length;
    },
    resetOptions: (): void => {
      store.multiChoiceQuestionV1Model.options = cast([
        {
          code: EMPTY_CHARACTER,
          option: EMPTY_CHARACTER,
        },
      ]);
      store.resetFieldValidationsAndErrors();
    },
    deserializeJSON: (): void => {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParentOfType(store, QuestionDetailsModel)
      );
      const multiSelectQuestionV1DTO = <MultiChoiceQuestionV1DTO>(
        JSON.parse(questionDetailsModel.questionDetailsJSON)
      );
      const multiSelectQuestionV1 = MultiChoiceQuestionV1.fromDTO(
        multiSelectQuestionV1DTO,
      );
      store.multiChoiceQuestionV1Model.options = cast(
        multiSelectQuestionV1.options.map((multiSelectField) => {
          return QuestionOptionsModel.create({
            code: multiSelectField.code.code,
            option: multiSelectField.option.text,
          });
        }),
      );
      store.multiChoiceQuestionV1Model.maxSelection =
        multiSelectQuestionV1.maxSelectionAllowed;
    },
    validateFields: (): void => {
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParentOfType(store, QuestionDetailsStore)
      );

      if (
        store.multiChoiceQuestionV1Model.options.length >
        MAXIMUM_GRID_COLUMN_OPTIONS
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.MaxGridMultiChoiceOptionsCountReached,
        );
        return;
      }

      store.multiChoiceQuestionV1Model.options.forEach((option) => {
        if (
          option.code.trim() === EMPTY_CHARACTER ||
          option.option.trim() === EMPTY_CHARACTER
        ) {
          questionDetailsStore.setFieldValidations(
            FieldValidations.UnfilledOptionField,
          );
          return;
        }
      });
      if (
        store.multiChoiceQuestionV1Model.maxSelection >
        store.multiChoiceQuestionV1Model.options.length
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.MaxSelectionExceeded,
        );
      } else if (
        store.multiChoiceQuestionV1Model.maxSelection < MIN_MULTI_CHOICE_OPTIONS
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.MaxSelectionCannotBeLessThanValidValue,
        );
      }
    },
    validateInitialOptionCodes(
      initialQuestionDetails: Instance<typeof store>,
    ): void {
      const questionDetailsStore = getParentOfType(store, QuestionDetailsStore);
      const updatedOptionCodes = store.multiChoiceQuestionV1Model.options.map(
        (multiSelectOption) => {
          return multiSelectOption.code.trim();
        },
      );
      const initialOptionCodes =
        initialQuestionDetails.multiChoiceQuestionV1Model.options.map(
          (multiSelectOption) => {
            return multiSelectOption.code;
          },
        );
      if (
        JSON.stringify(updatedOptionCodes) !==
        JSON.stringify(initialOptionCodes)
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.QuestionAndOptionCodesCannotBeUpdated,
        );
      }
    },
  }));

export const createMultiChoiceQuestionV1Store = (): Instance<
  typeof MultiChoiceQuestionV1Store
> => {
  return MultiChoiceQuestionV1Store.create({
    multiChoiceQuestionV1Model: createMultiChoiceQuestionV1Model(undefined, [
      createQuestionOptionsModel(),
    ]),
  });
};
