import {
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import {
  ChevronDown,
  ChevronUp,
  useCornerRadius,
  useFoundationColorTokens,
  useShadow,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { Avatar } from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FeatureInDevelopmentSnackbar,
  getIconProps,
  useBorder,
} from "@pulse/shared-components";
import {
  CHEVRON_ICON_SIZE,
  DROPDOWN_ANIMATION_TIMING_IN_MS,
  ICON_SIZE,
} from "@pulse/shared-components/src/constants";
import { useRootStore } from "../modules/root/store/RootStore";
import { useRouteNavigator } from "../routes/RoutesHelper";
import { LogOutIcon, SettingsIcon } from "lucide-react";
import { Instance } from "mobx-state-tree";
import { SignInStore } from "../modules/auth/store/SignInStore";
import { UserPrivileges } from "../modules/store/user/UserPrivileges";
import { useKeycloak } from "keycloak-react-web";

interface ListItemProps extends React.PropsWithChildren {
  icon: React.ReactElement;
  callBack: () => void;
}

export interface TopNavDropDownProps {
  userName: string;
  signInStore: Instance<typeof SignInStore>;
}

export const TopNavDropDown = ({
  userName,
  signInStore,
}: TopNavDropDownProps): React.ReactElement => {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const tokens = useFoundationColorTokens();
  const boxShadow = useShadow();
  const navigateTo = useRouteNavigator();
  const store = useRootStore();
  const { t } = useTranslation();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const border = useBorder();
  const iconColorAndWidthProps = getIconProps(tokens.icon, ICON_SIZE.medium);
  const { keycloak } = useKeycloak();

  const ListItem = ({
    icon,
    callBack,
    children,
  }: ListItemProps): React.ReactElement => {
    return (
      <ListItemButton
        onClick={callBack}
        style={{ padding: `${spacing.spaceMD} ${spacing.spaceSM}` }}
      >
        <Stack direction="row" spacing={1}>
          {icon}
          <ListItemText>{children}</ListItemText>
        </Stack>
      </ListItemButton>
    );
  };

  const handleDropDownClick = (): void => {
    setIsDropDownVisible(!isDropDownVisible);
  };

  const signOutUser = async (): Promise<void> => {
    store.signOutUser();
    await keycloak.logout({
      redirectUri: `${window.location.protocol}//${window.location.host}`,
    });
  };

  return (
    <ClickAwayListener
      onClickAway={function (): void {
        setIsDropDownVisible(false);
      }}
    >
      <List style={{ zIndex: 1, padding: 0 }}>
        <ListItemButton
          sx={{
            ":hover": {
              backgroundColor: tokens.backgroundHovered,
            },
            borderRadius: cornerRadius.radiusXS,
            padding: 0,
            marginLeft: spacing.spaceXS,
            justifyContent: "center",
          }}
          onClick={handleDropDownClick}
        >
          <Stack direction="row" spacing={spacing.spaceXS} alignItems="center">
            <Avatar userName={userName} size="medium" />
            {isDropDownVisible ? (
              <ChevronUp height={CHEVRON_ICON_SIZE} width={CHEVRON_ICON_SIZE} />
            ) : (
              <ChevronDown
                height={CHEVRON_ICON_SIZE}
                width={CHEVRON_ICON_SIZE}
              />
            )}
          </Stack>
        </ListItemButton>
        <Collapse
          in={isDropDownVisible}
          timeout={DROPDOWN_ANIMATION_TIMING_IN_MS}
          style={{
            position: "absolute",
            width: "max-content",
            right: 0,
            top: "50px",
            background: tokens.backgroundElevatedLevel2,
          }}
        >
          <List
            style={{
              boxShadow: boxShadow.level3,
              borderRadius: cornerRadius.radiusXS,
              border: border.subtle,
              width: "200px",
              padding: `${spacing.spaceXS} 0px`,
            }}
          >
            {signInStore.isPrivilegeGranted(UserPrivileges.updateUser) && (
              <ListItem
                icon={<SettingsIcon {...iconColorAndWidthProps} />}
                callBack={() => {
                  navigateTo.profileSettings();
                }}
              >
                {t("common.profileSettings.profileSettingsTitle")}
              </ListItem>
            )}

            <ListItem
              icon={<LogOutIcon {...iconColorAndWidthProps} />}
              callBack={signOutUser}
            >
              {t("common.signOut")}
            </ListItem>
          </List>
        </Collapse>
        <FeatureInDevelopmentSnackbar
          isSnackbarVisible={isSnackbarVisible}
          setIsSnackbarVisible={setIsSnackbarVisible}
        />
      </List>
    </ClickAwayListener>
  );
};
