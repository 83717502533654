import { Stack, Typography } from "@mui/material";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import {
  useGridQuestionV1Store,
  useQuestionDetailsStore,
} from "../store/hooks";
import { observer } from "mobx-react";
import { ResetFieldsButton } from "./commonQuestionConfigurationDetailsComponents/ResetFieldsButton";
import { CommonChoiceOptionsLayout } from "./CommonChoiceOptionsLayout";
import { Tabs, TextInputField } from "@surya-digital/leo-reactjs-material-ui";
import {
  EMPTY_CHARACTER,
  MAX_GRID_ROW_AND_COLUMN_HEADER_TEXT_CHARACTER_LIMIT,
  ZERO_VALUE,
  showMaxCharacterCountIndicator,
  useBorder,
} from "@pulse/shared-components";
import { GridColumnQuestion } from "./gridQuestionComponents/GridColumnQuestion";
import { QuestionOptionsTitleWithTooltip } from "./QuestionOptionsTitleWithTooltip";

interface GridQuestionCustomisationPaneProps {
  setIsResetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum GridTabElements {
  ROW_QUESTIONS,
  COLUMN_QUESTIONS,
}

export const GridQuestionCustomisationPane = observer(
  ({
    setIsResetDialogOpen,
  }: GridQuestionCustomisationPaneProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const spacing = useSpacing();
    const gridQuestionV1Store = useGridQuestionV1Store();
    const tokens = useFoundationColorTokens();
    const borderRadius = useCornerRadius();
    const questionDetailsStore = useQuestionDetailsStore();
    const border = useBorder();

    const GridRowHeader = observer((): React.ReactElement => {
      return (
        <TextInputField
          name="rowHeader"
          value={gridQuestionV1Store.rowHeader}
          label={EMPTY_CHARACTER}
          placeholder={t(
            "surveys.addSurveyQuestion.grid.enterRowHeaderPlaceholderText",
          )}
          type="text"
          onTextChange={(inputString: string) => {
            questionDetailsStore.setFieldValidations(null);
            gridQuestionV1Store.setRowHeader(
              inputString.slice(
                ZERO_VALUE,
                MAX_GRID_ROW_AND_COLUMN_HEADER_TEXT_CHARACTER_LIMIT,
              ),
            );
          }}
          maxCharacterLength={
            MAX_GRID_ROW_AND_COLUMN_HEADER_TEXT_CHARACTER_LIMIT
          }
          showCharacterCountIndicator={(): boolean =>
            showMaxCharacterCountIndicator(
              MAX_GRID_ROW_AND_COLUMN_HEADER_TEXT_CHARACTER_LIMIT,
              gridQuestionV1Store.rowHeader,
            )
          }
        />
      );
    });

    const GridRowQuestions = observer((): React.ReactElement => {
      return (
        <>
          <Typography sx={{ ...typography.ol1 }} color={tokens.labelSubtle}>
            {t("surveys.addSurveyQuestion.grid.rowCustomizationTitle")}
          </Typography>
          <GridRowHeader />
          <Stack gap={spacing.spaceSM}>
            {gridQuestionV1Store.rowOptions.map((_field, index) => {
              return (
                <CommonChoiceOptionsLayout
                  key={index}
                  optionTextWidth={undefined}
                  optionCodeWidth="132px"
                  t={t}
                  index={index}
                  spacing={spacing}
                  options={gridQuestionV1Store.rowOptions}
                  isAddNewFieldButtonDisabled={
                    gridQuestionV1Store.isAddNewFieldButtonDisabledForRows
                  }
                  isDeleteButtonDisabled={
                    gridQuestionV1Store.isDeleteButtonDisabledForRows
                  }
                  setFieldValidations={questionDetailsStore.setFieldValidations}
                  doesOptionContainErrors={() => {
                    return false;
                  }}
                  addNewField={() =>
                    gridQuestionV1Store.addNewGridRow(
                      {
                        code: EMPTY_CHARACTER,
                        option: EMPTY_CHARACTER,
                      },
                      index,
                    )
                  }
                  doesOptionCodeContainErrors={
                    gridQuestionV1Store.doesRowOptionCodeContainErrors
                  }
                  isOptionCodeNotUnique={
                    questionDetailsStore.isOptionCodeNotUnique
                  }
                  setDuplicateOptionCode={
                    questionDetailsStore.setDuplicateOptionCode
                  }
                  setCode={gridQuestionV1Store.setRowCode}
                  isChevronUpDisabled={gridQuestionV1Store.isChevronUpDisabled}
                  isChevronDownDisabled={
                    gridQuestionV1Store.isChevronDownDisabledForRows
                  }
                  moveFieldUp={gridQuestionV1Store.moveGridRowUp}
                  moveFieldDown={gridQuestionV1Store.moveGridRowDown}
                  removeField={gridQuestionV1Store.removeGridRow}
                  setFieldOptionText={gridQuestionV1Store.setRowOptionText}
                />
              );
            })}
          </Stack>
        </>
      );
    });

    const GridTabElement = observer((): React.ReactElement => {
      const gridElementStackProps: CSSProperties = {
        gap: spacing.spaceXL,
        width: "800px",
        marginTop: spacing.spaceXL,
        borderRadius: borderRadius.radiusXS,
        backgroundColor: tokens.backgroundSubtle,
        padding: spacing.spaceXL,
      };
      switch (gridQuestionV1Store.gridTabIndex) {
        case GridTabElements.ROW_QUESTIONS:
          return (
            <Stack sx={{ ...gridElementStackProps }}>
              <GridRowQuestions />
            </Stack>
          );
        case GridTabElements.COLUMN_QUESTIONS:
          return (
            <>
              {gridQuestionV1Store.gridColumnQuestions.map((_field, index) => {
                return (
                  <GridColumnQuestion
                    key={index}
                    index={index}
                    gridElementStackProps={gridElementStackProps}
                  />
                );
              })}
            </>
          );
        default: {
          console.error(
            `Grid tab index value ${gridQuestionV1Store.gridTabIndex} is not valid.`,
          );
          return <></>;
        }
      }
    });

    const ChoiceCustomisationPane = observer((): React.ReactElement => {
      return (
        <Stack width="600px">
          <QuestionOptionsTitleWithTooltip
            title={t("surveys.questionsCustomisation")}
            spacing={spacing}
            tokens={tokens}
          />
          <Stack width={"800px"} borderBottom={border.default}>
            <Tabs
              tabs={[
                {
                  label: t("surveys.addSurveyQuestion.grid.rowsTitle"),
                },
                {
                  label: t("surveys.addSurveyQuestion.grid.columnsTitle"),
                },
              ]}
              selectedTabIndex={gridQuestionV1Store.gridTabIndex}
              onTabChange={(index: number): void => {
                gridQuestionV1Store.setGridTabIndex(index);
              }}
              disableBottomBorder={true}
            />
          </Stack>
          <GridTabElement />
          <ResetFieldsButton
            isResetDisabled={gridQuestionV1Store.isResetDisabled}
            setIsResetDialogOpen={setIsResetDialogOpen}
            t={t}
            spacing={spacing}
          />
        </Stack>
      );
    });

    return (
      <Stack gap={spacing.space2XL}>
        <ChoiceCustomisationPane />
      </Stack>
    );
  },
);
