import { Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import {
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";

export const LinkInfo = observer(
  ({
    title,
    linkNumber,
    typography,
    spacing,
    tokens,
  }: {
    title: string;
    linkNumber: number;
    typography: LeoTypography;
    spacing: Spacing;
    tokens: FoundationColorTokens<string>;
  }): React.ReactElement => {
    return (
      <Stack width="120px" gap={spacing.spaceXXS}>
        <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
          {title}
        </Typography>
        <Typography sx={{ ...typography.sh1, color: tokens.label }}>
          {linkNumber}
        </Typography>
      </Stack>
    );
  },
);
