import {
  AddNewEmbedRPC,
  AddNewEmbedRPCClientImpl,
  DeleteEmbedRPC,
  DeleteEmbedRPCClientImpl,
  GetEmbedsRPC,
  GetEmbedsRPCClientImpl,
} from "@pulse/pulse-rpcs";
import { MOCK } from "@pulse/shared-components";
import { APIClient } from "@surya-digital/tedwig";
import { MockAddNewEmbedRPCClientImpl } from "./MockAddNewEmbed";
import { MockGetEmbedsRPCClientImpl } from "./MockGetEmbeds";
import { MockDeleteEmbedRPCClientImpl } from "./MockDeleteEmbed";

export const useAddNewEmbedRPCClient = (
  apiClient: APIClient,
): AddNewEmbedRPC => {
  if (MOCK.reportDetails) {
    return new MockAddNewEmbedRPCClientImpl();
  } else {
    return new AddNewEmbedRPCClientImpl(apiClient);
  }
};

export const useGetEmbedsRPCClient = (apiClient: APIClient): GetEmbedsRPC => {
  if (MOCK.reportDetails) {
    return new MockGetEmbedsRPCClientImpl();
  } else {
    return new GetEmbedsRPCClientImpl(apiClient);
  }
};

export const useDeleteEmbedRPCClient = (
  apiClient: APIClient,
): DeleteEmbedRPC => {
  if (MOCK.reportDetails) {
    return new MockDeleteEmbedRPCClientImpl();
  } else {
    return new DeleteEmbedRPCClientImpl(apiClient);
  }
};
