import { Stack } from "@mui/material";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useEffect } from "react";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";
import { EmptyListPane } from "../../surveys/components/EmptyListPane";
import { TFunction } from "i18next";
import {
  CornerRadius,
  FoundationColorTokens,
  LoadingIndicator,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { SurveyDetailsPane } from "./surveyDetails/SurveyDetailsPane";
import { BorderStyle } from "@pulse/shared-components";
import { ErrorComponent } from "../../../components/ErrorComponent";
import { SurveyStore } from "../../surveys/store/SurveyStore";

export interface SurveyDetailsListProps {
  navigateTo: NavigateToFunctions;
  t: TFunction;
  setIsCreateSurveyDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  spacing: Spacing;
  border: BorderStyle;
  borderRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  setIsChildHeightVariable: React.Dispatch<React.SetStateAction<boolean>>;
  surveyStore: Instance<typeof SurveyStore>;
  setSelectedSurveyId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedSurveyName: React.Dispatch<React.SetStateAction<string | null>>;
  setIsCloneSurveyDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SurveyDetailsList = observer(
  ({
    navigateTo,
    t,
    setIsCreateSurveyDialogOpen,
    spacing,
    border,
    borderRadius,
    tokens,
    typography,
    setIsChildHeightVariable,
    surveyStore,
    setSelectedSurveyId,
    setSelectedSurveyName,
    setIsCloneSurveyDialogOpen,
  }: SurveyDetailsListProps): React.ReactElement => {
    useEffect(() => {
      setIsChildHeightVariable(false);
      if (
        !surveyStore.isRPCLoading &&
        !surveyStore.doesStoreContainError &&
        !surveyStore.isNoSurveyAdded
      ) {
        setIsChildHeightVariable(true);
      }
    }, [
      surveyStore.isRPCLoading,
      surveyStore.doesStoreContainError,
      surveyStore.isNoSurveyAdded,
    ]);

    if (surveyStore.isRPCLoading || surveyStore.projectId === undefined) {
      return (
        <LoadingIndicator
          isLoading={surveyStore.isRPCLoading}
          variant="overlay"
        />
      );
    } else if (surveyStore.doesStoreContainError) {
      return (
        <ErrorComponent
          errorMessage={t("projects.surveyDetails.unexpectedErrorText")}
          resolveButtonText={t("common.refresh")}
          resolveButtonCallback={navigateTo.reload}
        />
      );
    } else if (surveyStore.isNoSurveyAdded) {
      return (
        <EmptyListPane
          headingText={t("surveys.addSurvey.noSurveysCreatedTitle")}
          subHeadingText={t("surveys.addSurvey.noSurveysCreatedDescription")}
          helperButtonText={t("surveys.addSurvey.createSurveyButtonText")}
          helperButtonCallback={() => {
            setIsCreateSurveyDialogOpen(true);
          }}
        />
      );
    } else {
      return (
        <Stack
          width="1448px"
          gap={spacing.space2XL}
          padding={spacing.spaceXL}
          flexWrap="wrap"
          direction="row"
          height="100%"
          margin="auto"
        >
          <SurveyDetailsPane
            listOfSurveyDetails={surveyStore.surveyDetailsList}
            spacing={spacing}
            border={border}
            borderRadius={borderRadius}
            t={t}
            tokens={tokens}
            typography={typography}
            navigateTo={navigateTo}
            projectId={surveyStore.projectId}
            setSelectedSurveyId={setSelectedSurveyId}
            setSelectedSurveyName={setSelectedSurveyName}
            setIsCloneSurveyDialogOpen={setIsCloneSurveyDialogOpen}
          />
        </Stack>
      );
    }
  },
);
