import { Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  getEllipsizedTextProperties,
} from "@pulse/shared-components";
import {
  Typography as LeoTypography,
  FoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import { SurveyQuestionModel } from "../../models/SurveyQuestionModel";
import { QuestionItemFooter } from "./QuestionItemFooter";
import { t } from "i18next";
import React from "react";
import { observer } from "mobx-react";

export interface ChildQuestionItemComponentProps {
  childQuestionDetails: Instance<typeof SurveyQuestionModel>;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  isItemSelected: boolean;
  border: BorderStyle;
}

export const ChildQuestionItemComponent = observer(
  ({
    childQuestionDetails: childQuestionDetails,
    typography,
    tokens,
    isItemSelected,
    border,
  }: ChildQuestionItemComponentProps): React.ReactElement => {
    const spacing = useSpacing();
    return (
      <Stack
        padding={`${spacing.spaceMD} ${spacing.spaceXL} ${spacing.spaceMD} ${spacing.space4XL}`}
        width={"100%"}
        minHeight="108px"
        justifyContent="space-between"
        gap={spacing.spaceXXS}
        borderBottom={border.default}
        sx={{
          background: isItemSelected
            ? tokens.backgroundPrimarySubtle
            : tokens.backgroundElevatedLevel1,
          "&:hover": {
            background: !isItemSelected ? tokens.backgroundHovered : undefined,
          },
        }}
      >
        <Stack>
          {childQuestionDetails.surveyQuestionDetails.question && (
            <Typography
              sx={{
                ...typography.s1,
                ...getEllipsizedTextProperties(2),
                color: tokens.label,
              }}
            >
              {childQuestionDetails.surveyQuestionDetails.question}
            </Typography>
          )}
        </Stack>
        <QuestionItemFooter
          tokens={tokens}
          typography={typography}
          questionItem={{
            question: childQuestionDetails.surveyQuestionDetails.question,
            questionCode:
              childQuestionDetails.surveyQuestionDetails.questionCode,
            questionId: childQuestionDetails.surveyQuestionDetails.questionId,
            questionType:
              childQuestionDetails.surveyQuestionDetails.questionType,
            order: childQuestionDetails.surveyQuestionDetails.order,
            isRuleApplied: childQuestionDetails.isRuleApplied,
            isVisibleByDefault: childQuestionDetails.isVisibleByDefault,
          }}
          t={t}
          spacing={spacing}
        />
      </Stack>
    );
  },
);
