import { Instance } from "mobx-state-tree";
import React, { useEffect } from "react";
import {
  PivotTableColumns,
  PivotTableStore,
} from "../../store/PivotTableStore";
import { TFunction } from "i18next";
import {
  ContentCellOptions,
  FoundationColorTokens,
  ListX,
  LoadingIndicator,
  Spacing,
  Table,
  TableRowItem,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import { StyledEllipsisTypography } from "../../../../components/StyledEllipsisTypography";
import {
  EMPTY_CHARACTER,
  getIconProps,
  ICON_SIZE,
  IconContainer,
  RESPONSE_RATE_MAX_DECIMAL_PLACES,
} from "@pulse/shared-components";
import { Stack } from "@mui/material";
import { EmptyListPane } from "../../../surveys/components/EmptyListPane";

interface PivotTableProps {
  pivotTableStore: Instance<typeof PivotTableStore>;
  t: TFunction;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
}

export const PivotTable = observer(
  ({
    pivotTableStore,
    t,
    typography,
    tokens,
    spacing,
  }: PivotTableProps): React.ReactElement => {
    useEffect(() => {
      return () => {
        pivotTableStore.clearStore();
      };
    }, []);

    const getData = async (): Promise<string | TableRowItem[]> => {
      return pivotTableStore.pivotTableData.map((data, index) => {
        const pivotDataCells: ContentCellOptions[] = [
          {
            data: (
              <StyledEllipsisTypography
                data={data.respondentColumn}
                typography={typography}
                t={t}
              />
            ),
            columnId: pivotTableStore.selectedColumn?.name ?? EMPTY_CHARACTER,
          },
          {
            data: (
              <StyledEllipsisTypography
                data={`${data.total}`}
                typography={typography}
                t={t}
              />
            ),
            alignment: "right",
            columnId: PivotTableColumns.Total,
          },
          {
            data: (
              <StyledEllipsisTypography
                data={`${data.responseRate.toFixed(RESPONSE_RATE_MAX_DECIMAL_PLACES)}%`}
                typography={typography}
                t={t}
              />
            ),
            alignment: "right",
            columnId: PivotTableColumns.ResponseRate,
          },
          {
            data: (
              <StyledEllipsisTypography
                data={data.notAssigned}
                typography={typography}
                t={t}
              />
            ),
            alignment: "right",
            columnId: PivotTableColumns.NotAssigned,
          },
          {
            data: (
              <StyledEllipsisTypography
                data={data.notStarted}
                typography={typography}
                t={t}
              />
            ),
            alignment: "right",
            columnId: PivotTableColumns.NotStarted,
          },
          {
            data: (
              <StyledEllipsisTypography
                data={data.viewed}
                typography={typography}
                t={t}
              />
            ),
            alignment: "right",
            columnId: PivotTableColumns.Viewed,
          },
          {
            data: (
              <StyledEllipsisTypography
                data={data.started}
                typography={typography}
                t={t}
              />
            ),
            alignment: "right",
            columnId: PivotTableColumns.Started,
          },
          {
            data: (
              <StyledEllipsisTypography
                data={data.inProgress}
                typography={typography}
                t={t}
              />
            ),
            alignment: "right",
            columnId: PivotTableColumns.InProgress,
          },
          {
            data: (
              <StyledEllipsisTypography
                data={data.lastPage}
                typography={typography}
                t={t}
              />
            ),
            alignment: "right",
            columnId: PivotTableColumns.LastPage,
          },
          {
            data: (
              <StyledEllipsisTypography
                data={data.submitted}
                typography={typography}
                t={t}
              />
            ),
            alignment: "right",
            columnId: PivotTableColumns.Submitted,
          },
        ];
        return { id: `${index}`, cells: pivotDataCells };
      });
    };
    return (
      <Stack width="100%" minHeight="112px" paddingBottom={spacing.spaceLG}>
        {pivotTableStore.isRPCLoading ? (
          <LoadingIndicator isLoading={true} variant="container" />
        ) : pivotTableStore.isPivotDataFetched ? (
          <Table
            name="pivotTable"
            header={pivotTableStore.tableColumnHeaders(t)}
            onTableOptionsChange={getData}
            styleOverrides={{
              background: tokens.backgroundElevatedLevel1,
              divider: "row",
              margin: `0 0 0 ${spacing.spaceLG}`,
            }}
            viewOverrides={{
              empty: {
                icon: IconContainer(
                  <ListX
                    {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
                  />,
                ),
                message: t("projects.dashboard.pivotTable.emptyStateText"),
              },
              loading: { message: t("common.searchTableLoadingState") },
            }}
            leftPinnedColumnIds={[
              pivotTableStore.selectedColumn?.name ?? EMPTY_CHARACTER,
            ]}
            rightPinnedColumnIds={[]}
          />
        ) : (
          <EmptyListPane
            headingText={t("projects.dashboard.pivotTable.emptyStateText")}
          />
        )}
      </Stack>
    );
  },
);
