import { Stack } from "@mui/material";
import { Spacing } from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  CornerRadius,
  FoundationColorTokens,
  IconButton,
  Shadow,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import { GroupQuestionPopover } from "./GroupQuestionPopover";
import { BorderStyle } from "@pulse/shared-components";
import { FolderInput } from "lucide-react";
import { SurveyQuestionModel } from "../../models/SurveyQuestionModel";
import { ManageQuestionsStore } from "../../store/ManageQuestionsStore";

interface ParentQuestionsButtonsProps {
  spacing: Spacing;
  manageQuestionsStore: Instance<typeof ManageQuestionsStore>;
  question: Instance<typeof SurveyQuestionModel>;
  index: number;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  shadow: Shadow;
  typography: Typography;
}

export const ParentQuestionsButtons = ({
  spacing,
  index,
  manageQuestionsStore,
  question,
  border,
  cornerRadius,
  shadow,
  tokens,
  typography,
}: ParentQuestionsButtonsProps): React.ReactElement => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(
    null,
  );
  const [selectedChildQuestionId, setSelectedChildQuestionId] = useState<
    string | undefined
  >();
  return (
    <Stack gap={spacing.spaceXS} flexDirection="row">
      <IconButton
        name={"moveUp"}
        size={"medium"}
        icon={<ChevronUp />}
        variant={"plain-neutral"}
        disabled={manageQuestionsStore.isMovingQuestionUpDisabled(index)}
        onClick={(): void => {
          manageQuestionsStore.moveParentQuestionUp(
            question.surveyQuestionDetails.order,
            question.surveyQuestionDetails.questionId,
          );
        }}
      />
      <IconButton
        name={"moveDown"}
        size={"medium"}
        icon={<ChevronDown />}
        variant={"plain-neutral"}
        disabled={manageQuestionsStore.isMovingQuestionDownDisabled(
          question.surveyQuestionDetails.questionId,
        )}
        onClick={(): void => {
          manageQuestionsStore.moveParentQuestionDown(
            question.surveyQuestionDetails.order,
            question.surveyQuestionDetails.questionId,
          );
        }}
      />
      {!manageQuestionsStore.isQuestionGroupType(
        question.surveyQuestionDetails.questionId,
      ) &&
        manageQuestionsStore.doesGroupQuestionExistInManageQuestionsList && (
          <>
            <IconButton
              name={`${question.surveyQuestionDetails.questionId}`}
              size={"medium"}
              icon={<FolderInput />}
              variant={"plain-neutral"}
              onClick={(): void => {
                const element = document.getElementsByName(
                  `${question.surveyQuestionDetails.questionId}`,
                )[0] as HTMLButtonElement;
                setAnchorElement(element);
                setSelectedChildQuestionId(
                  question.surveyQuestionDetails.questionId,
                );
              }}
            />
            <GroupQuestionPopover
              anchorElement={anchorElement}
              setAnchorElement={setAnchorElement}
              manageQuestionsStore={manageQuestionsStore}
              spacing={spacing}
              border={border}
              cornerRadius={cornerRadius}
              tokens={tokens}
              shadow={shadow}
              typography={typography}
              selectedChildQuestionId={selectedChildQuestionId}
              setSelectedChildQuestionId={setSelectedChildQuestionId}
            />
          </>
        )}
    </Stack>
  );
};
