import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React, { useEffect } from "react";
import {
  SendPasswordResetLinkDialogState,
  SendPasswordResetLinkArchiveBOUserStore,
  SendPasswordResetLinkToBOUserRPCError,
} from "../store/SendPasswordResetLinkArchiveBOUserStore";
import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  TableReloadHandle,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { DialogErrorContent } from "../../../components/DialogErrorContent";
import { Stack, Typography } from "@mui/material";
import { HelpCircle } from "lucide-react";
import { getIconProps, ICON_SIZE } from "@pulse/shared-components";
import { observer } from "mobx-react";
import { DialogSuccessContent } from "../../../components/DialogSuccessContent";
import { RESET_PASSWORD_EMAIL_WAIT_DURATION_IN_MINUTES } from "@pulse/shared-components";

interface SendPasswordResetLinkDialogProps {
  isSendPasswordResetLinkDialogOpen: boolean;
  setIsSendPasswordResetLinkDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  t: TFunction;
  spacing: Spacing;
  sendPasswordResetLinkArchiveBOUserStore: Instance<
    typeof SendPasswordResetLinkArchiveBOUserStore
  >;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  boUserId: string;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const SendPasswordResetLinkDialog = observer(
  ({
    boUserId,
    isSendPasswordResetLinkDialogOpen,
    setIsSendPasswordResetLinkDialogOpen,
    sendPasswordResetLinkArchiveBOUserStore,
    spacing,
    t,
    tokens,
    typography,
    tableRef,
  }: SendPasswordResetLinkDialogProps): React.ReactElement => {
    useEffect(() => {
      return () => {
        sendPasswordResetLinkArchiveBOUserStore.resetStoreData();
      };
    }, []);

    const getPrimaryButtonText = (): string | undefined => {
      switch (
        sendPasswordResetLinkArchiveBOUserStore.sendPasswordResetLinkDialogState
      ) {
        case SendPasswordResetLinkDialogState.Error: {
          return undefined;
        }
        case SendPasswordResetLinkDialogState.SendPasswordResetLink: {
          return t("manageUsers.sendPasswordResetDialog.sendResetLink");
        }
      }
    };

    const getSecondaryButtonText = (): string | undefined => {
      switch (
        sendPasswordResetLinkArchiveBOUserStore.sendPasswordResetLinkDialogState
      ) {
        case SendPasswordResetLinkDialogState.Error: {
          return t("common.close");
        }
        case SendPasswordResetLinkDialogState.SendPasswordResetLink: {
          return t("common.cancel");
        }
        case SendPasswordResetLinkDialogState.Success: {
          return t("common.done");
        }
      }
    };

    const getErrorText = (): string => {
      switch (sendPasswordResetLinkArchiveBOUserStore.rpcError) {
        case SendPasswordResetLinkToBOUserRPCError.InvalidBOUserId:
        case SendPasswordResetLinkToBOUserRPCError.SendEmailFailed: {
          return t(
            "manageUsers.sendPasswordResetDialog.errorTexts.unexpectedError",
          );
        }
        case SendPasswordResetLinkToBOUserRPCError.EmailAlreadySent: {
          return t("errors.resetPasswordEmailAlreadySent", {
            resetEmailWaitDurationInMinutes:
              RESET_PASSWORD_EMAIL_WAIT_DURATION_IN_MINUTES,
          });
        }
        case SendPasswordResetLinkToBOUserRPCError.ResetPasswordLimitReached: {
          return t("errors.resetPasswordLimitReached");
        }
        default: {
          return t(
            "manageUsers.sendPasswordResetDialog.errorTexts.unexpectedError",
          );
        }
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (
        sendPasswordResetLinkArchiveBOUserStore.sendPasswordResetLinkDialogState
      ) {
        case SendPasswordResetLinkDialogState.SendPasswordResetLink: {
          return (
            <Stack gap={spacing.spaceXS} direction="row">
              <HelpCircle {...getIconProps(tokens.icon, ICON_SIZE.default)} />
              <Typography
                sx={{ ...typography.b2 }}
                color={tokens.label}
                width={"492px"}
              >
                {t("manageUsers.sendPasswordResetDialog.dialogDescription")}
              </Typography>
            </Stack>
          );
        }
        case SendPasswordResetLinkDialogState.Error: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
        case SendPasswordResetLinkDialogState.Success: {
          return (
            <DialogSuccessContent
              spacing={spacing}
              typography={typography}
              tokens={tokens}
              description={t(
                "manageUsers.sendPasswordResetDialog.successMessage",
              )}
            />
          );
        }
      }
    };

    return (
      <Dialog
        open={isSendPasswordResetLinkDialogOpen}
        title={t("manageUsers.sendPasswordResetDialog.sendAuthenticationEmail")}
        contentPadding={spacing.spaceLG}
        disableBackdropClick={true}
        primaryButtonText={getPrimaryButtonText()}
        secondaryButtonText={getSecondaryButtonText()}
        onPrimaryButtonClick={async (): Promise<void> => {
          await sendPasswordResetLinkArchiveBOUserStore.sendPasswordResetLink(
            boUserId,
          );
        }}
        isSecondaryButtonDisabled={
          sendPasswordResetLinkArchiveBOUserStore.isRPCLoading
        }
        onSecondaryButtonClick={() => {
          setIsSendPasswordResetLinkDialogOpen(false);
          if (sendPasswordResetLinkArchiveBOUserStore.isInvalidBOUserIdError) {
            tableRef.current?.reload();
          }
          sendPasswordResetLinkArchiveBOUserStore.resetStoreData();
        }}
        width="560px"
      >
        {/*
        getDialogChild() does is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
