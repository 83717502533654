import { observer } from "mobx-react";
import React from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import {
  Spacing,
  FoundationColorTokens,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Stack, Typography } from "@mui/material";
import { SettingsCheckboxComponent } from "./SettingsCheckboxComponent";
import { SettingsRichTextEditor } from "./SettingsRichTextEditor";
import { MAX_WELCOME_PAGE_TEXT_LENGTH } from "@pulse/shared-components";
import { Instance } from "mobx-state-tree";
import { RTEFileServiceModel } from "../../../projects/models/RTEFileServiceModel";

interface WelcomePageCustomisationProps {
  isWelcomePageShown: boolean;
  setIsWelcomePageShown: (isWelcomePageShown: boolean) => void;
  welcomePageText: string;
  setWelcomePageText: (welcomePageText: string) => void;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  isWelcomePageTextErrored: boolean;
  rteFileService: Instance<typeof RTEFileServiceModel>;
  updatedWelcomePageText: string;
}

export const WelcomePageCustomisation = observer(
  ({
    isWelcomePageShown,
    setIsWelcomePageShown,
    welcomePageText,
    setWelcomePageText,
    t,
    spacing,
    typography,
    tokens,
    isWelcomePageTextErrored,
    rteFileService,
    updatedWelcomePageText,
  }: WelcomePageCustomisationProps): React.ReactElement => {
    return (
      <SettingsContainer
        settingHeadingText={t(
          "surveys.settings.welcomePageCustomisationSettings.welcomePageCustomisationTitleText",
        )}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
        tooltipChild={
          <Typography {...typography.b3} color={tokens.labelLowEmphasis}>
            {t("surveys.settings.newLineDescription")}
          </Typography>
        }
      >
        <Stack gap={spacing.spaceLG} width="720px">
          <SettingsCheckboxComponent
            isCheckboxSelected={isWelcomePageShown}
            setIsCheckboxSelected={setIsWelcomePageShown}
            checkboxlabel={t(
              "surveys.settings.welcomePageCustomisationSettings.showWelcomePageCheckboxLabelText",
            )}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
          />
          <SettingsRichTextEditor
            label={t(
              "surveys.settings.welcomePageCustomisationSettings.surveyWelcomePageRteLabelText",
            )}
            placeholder={t(
              "surveys.settings.welcomePageCustomisationSettings.surveyWelcomePageRtePlaceholderText",
            )}
            rteText={welcomePageText}
            setRTEText={(rteText: string) => {
              if (rteText !== updatedWelcomePageText) {
                rteFileService.setIsFileUploadError(false);
              }
              setWelcomePageText(rteText);
            }}
            isError={isWelcomePageTextErrored}
            t={t}
            maxRTETextLength={MAX_WELCOME_PAGE_TEXT_LENGTH}
            attachmentConfig={rteFileService.richTextAttachmentProps}
            isRTEFileServiceHelperTextVisible={
              rteFileService.isRTEFileServiceError
            }
          />
        </Stack>
      </SettingsContainer>
    );
  },
);
