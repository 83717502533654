import { Instance, types } from "mobx-state-tree";

export const SurveyLinkBreakdownModel = types.model(
  "SurveyLinkBreakdownModel",
  {
    volume: types.number,
    percentage: types.number,
  },
);

export const createSurveyLinkBreakdownModel = (
  volume: number,
  percentage: number,
): Instance<typeof SurveyLinkBreakdownModel> => {
  return SurveyLinkBreakdownModel.create({
    volume,
    percentage,
  });
};
