import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { UploadIcon } from "lucide-react";
import {
  EMPTY_CHARACTER,
  ICON_SIZE,
  getIconProps,
  useBorder,
} from "@pulse/shared-components";

export interface FileUploadComponentProps {
  fileUploadHandler: (file: File) => Promise<void>;
}

/**
 * Creates a FileUploadComponent that can accept files through Drag-and-Drop or selection.
 * @param fileUploadHandler - It's a callback that is fired once the file has been successfully received by the component.
 * @returns A FileUpload component
 *
 * @example
 *   const fileUploadHandler = (file: File): Promise<void> => {
 *    return processProjectParams(async (projectId) => {
 *      await store.uploadCSVFile(file, projectId);
 *    }, navigateTo);
 *   };
 *   <FileUploadComponent
 *    fileUploadHandler={fileUploadHandler}
 *   />
 */
export const FileUploadComponent = observer(
  ({ fileUploadHandler }: FileUploadComponentProps): React.ReactElement => {
    const tokens = useFoundationColorTokens();
    const typography = useTypography();
    const borderRadius = useCornerRadius();
    const [dragActive, setDragActive] = useState(false);
    const { t } = useTranslation();
    const border = useBorder();
    const spacing = useSpacing();

    // This is the event that is fired when a dragged item enters the specified HTML element, hence we are setting the dragActive to true.
    const onDragEnter = (
      event: React.DragEvent<HTMLDivElement> | React.DragEvent<HTMLFormElement>,
    ): void => {
      event.preventDefault();
      event.stopPropagation();
      setDragActive(true);
    };
    // This is the event that is fired when a dragged item is dragged over the specified HTML element, hence we are setting the dragActive to true.
    const onDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
      event.preventDefault();
      event.stopPropagation();
      setDragActive(true);
    };
    // This is the event that is fired when a dragged item leaves the specified HTML element, hence we are setting the dragActive to false.
    const onDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
      event.preventDefault();
      event.stopPropagation();
      setDragActive(false);
    };
    const handleFileUpload = (_file: File): Promise<void> => {
      return fileUploadHandler(_file);
    };
    // Since this is a drop event, the drag event is no longer happening and hence dragActive is set to false. We will handle the file upload in this function.
    const onDrop = (event: React.DragEvent<HTMLDivElement>): void => {
      event.preventDefault();
      event.stopPropagation();
      setDragActive(false);
      if (event.dataTransfer && event.dataTransfer.files[0]) {
        const _file = event.dataTransfer.files[0];
        handleFileUpload(_file);
      }
    };
    // This function gets triggered when the user uses the input field to upload the file ( by clicking on the label in place of using drag and drop).
    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      if (event.target.files && event.target.files.length) {
        const _file = event.target.files[0];
        handleFileUpload(_file);
      }
    };
    // Since we are not using the form to submit the file for upload, hence we are not handling the onSubmit function.
    const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
    };
    return (
      <form id="form-file-upload" onDragEnter={onDragEnter} onSubmit={onSubmit}>
        <input
          type="file"
          // Since we are only allowing the csv type as of now, hence the accept value is set to csv.
          accept=".csv"
          id="input-file-upload"
          // Since we are only allowing one file to be selected, hence multiple is set to false.
          multiple={false}
          onChange={onChange}
          // Since we don't require the default UI for input field hence the display is set to none.
          style={{ display: "none" }}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dragActive ? "drag-active" : EMPTY_CHARACTER}
        >
          <Stack
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
            spacing={spacing.spaceSM}
            style={{
              backgroundColor: tokens.backgroundPrimarySubtle,
              borderRadius: borderRadius.radiusXS,
              border: border.primaryDashed,
              alignItems: "center",
              padding: spacing.spaceXL,
              minHeight: "124px",
            }}
          >
            <UploadIcon {...getIconProps(tokens.icon, ICON_SIZE.medium)} />
            <Stack alignItems={"center"} spacing={spacing.spaceXXS}>
              <Typography sx={{ ...typography.b2, marginTop: spacing.spaceSM }}>
                {`${t("projects.respondentList.dragAndDrop")} `}
                <Typography
                  // This is required since by default Typography uses the <p> tag and <p> tag cannot be a descendant of another <p> tag.
                  component={"span"}
                  sx={{
                    textDecoration: "underline",
                    ...typography.bt2,
                    color: tokens.labelPrimary,
                  }}
                  display="inline"
                >
                  {t("projects.respondentList.browse")}
                </Typography>
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  ...typography.c1,
                  color: tokens.labelLowEmphasis,
                  marginTop: spacing.spaceSM,
                  marginBottom: spacing.spaceXL,
                }}
              >
                {t("projects.respondentList.fileFormatCSV")}
              </Typography>
            </Stack>
          </Stack>
        </label>
      </form>
    );
  },
);
