import {
  LINE_CHART_GRADIENT_COLORS,
  PRIMARY_COLORS,
} from "@pulse/shared-components";
import React from "react";
import { Line } from "react-chartjs-2";
import { scales, options } from "./SurveySubmissionDataPane";
import { TFunction } from "i18next";
import { ChartArea, Color } from "chart.js";

interface LineChartCustomisation {
  isColorGradientRequired: boolean;
  isTickRotationRequired: boolean;
}
export interface LineChartProps {
  labels: string[];
  totalSubmissionData: number[];
  t: TFunction;
  lineChartCustomisation: LineChartCustomisation;
}

export const LineChart = ({
  labels,
  totalSubmissionData,
  t,
  lineChartCustomisation,
}: LineChartProps): React.ReactElement => {
  const getGradient = (
    ctx: CanvasRenderingContext2D,
    chartArea: ChartArea,
  ): Color => {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top,
    );
    gradient.addColorStop(0.5, LINE_CHART_GRADIENT_COLORS.dark);
    gradient.addColorStop(0, LINE_CHART_GRADIENT_COLORS.light);
    return gradient;
  };
  const tickCustomisation = lineChartCustomisation.isTickRotationRequired
    ? undefined
    : {
        xAxis: {
          ticks: {
            minRotation: 0,
          },
        },
      };
  return (
    <Line
      options={{
        scales: {
          ...scales,
          ...tickCustomisation,
        },
        ...options,
      }}
      data={{
        labels,
        datasets: [
          {
            label: t("projects.dashboard.totalSubmissionsText"),
            xAxisID: "xAxis",
            yAxisID: "yAxis",
            data: totalSubmissionData,
            borderColor: PRIMARY_COLORS[400],
            pointBackgroundColor: PRIMARY_COLORS[400],
            backgroundColor: (context): Color | undefined => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
              if (!chartArea) return;
              return getGradient(ctx, chartArea);
            },
            fill: lineChartCustomisation.isColorGradientRequired,
          },
        ],
      }}
    />
  );
};
