import { SurveySettingsEnums } from "@pulse/pulse-rpcs";
import {
  MIN_RTE_TEXT_LENGTH,
  PRIMARY_FONT,
  RICH_TEXT_DEFAULT_FONT_COLOR,
  RICH_TEXT_MARKDOWN_PROPS,
  richTextTypography,
  showMaxCharacterCountIndicator,
} from "@pulse/shared-components";
import { RTERegularTextType } from "@pulse/shared-components";
import {
  RichTextAttachmentConfig,
  RichTextToolbarButtons,
} from "@surya-digital/leo-reactjs-material-ui";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import React, { useState } from "react";

interface SettingsRichTextEditorProps {
  label: string;
  placeholder: string;
  rteText: string;
  setRTEText: (rteText: string) => void;
  isError: boolean;
  t: TFunction;
  maxRTETextLength: number;
  attachmentConfig: RichTextAttachmentConfig | undefined;
  isRTEFileServiceHelperTextVisible: boolean;
}

export const SettingsRichTextEditor = observer(
  ({
    label,
    placeholder,
    rteText,
    setRTEText,
    isError,
    t,
    maxRTETextLength,
    attachmentConfig,
    isRTEFileServiceHelperTextVisible,
  }: SettingsRichTextEditorProps): React.ReactElement => {
    const [updatedRTEText, setUpdatedRTEText] = useState(rteText);
    const richTextButtonsProps: RichTextToolbarButtons = {
      ...RICH_TEXT_MARKDOWN_PROPS,
      checkedList: true,
      underline: true,
      undo: true,
      redo: true,
      copy: true,
      cut: true,
      paste: true,
    };

    const getHelperText = (): string | undefined => {
      if (isRTEFileServiceHelperTextVisible) {
        return t("errors.rteFileServiceErrorHelperText");
      } else if (isError) {
        return t("surveys.settings.rteHelperText", {
          minCharacterLength: MIN_RTE_TEXT_LENGTH,
          maxCharacterLength: maxRTETextLength,
        });
      }
    };

    return (
      <RichTextEditor
        name="rteEditor"
        label={label}
        placeholder={placeholder}
        typography={richTextTypography(
          PRIMARY_FONT,
          SurveySettingsEnums.FontSizeScale.FontSizeScale.DEFAULT_SCALE,
          RICH_TEXT_DEFAULT_FONT_COLOR,
          true,
          RTERegularTextType.BACK_OFFICE,
        )}
        mode="editable"
        valueType="markdown"
        supports={richTextButtonsProps}
        initialValue={rteText}
        onChange={(text) => {
          setRTEText(text);
          setUpdatedRTEText(text);
        }}
        required={true}
        maxHeight="288px"
        minHeight="288px"
        error={isError || isRTEFileServiceHelperTextVisible}
        toolbarButtons={richTextButtonsProps}
        maxCharacterLength={maxRTETextLength}
        showCharacterCount={(_characterCount: number): boolean =>
          showMaxCharacterCountIndicator(maxRTETextLength, updatedRTEText)
        }
        helperText={getHelperText()}
        attachmentConfig={attachmentConfig}
      />
    );
  },
);
