import {
  Instance,
  cast,
  getParent,
  getParentOfType,
  types,
} from "mobx-state-tree";
import {
  RankingQuestionOptionsModel,
  RankingQuestionV1Model,
  createRankingQuestionOptionsModel,
  createRankingQuestionV1Model,
} from "@pulse/shared-components";
import { QuestionDetailsModel } from "../models/QuestionDetailsModel";
import { FieldValidations, QuestionDetailsStore } from "./QuestionDetailsStore";
import {
  EMPTY_CHARACTER,
  MAX_RANKING_OPTIONS,
  MIN_RANKING_OPTIONS,
} from "@pulse/shared-components";
import { doesCodeContainAllowedCharacters } from "@pulse/shared-components";
import {
  Code,
  OptionV1,
  RankingQuestionV1,
  SurveyLocalizedText,
} from "@pulse/pulse-rpcs";
import {
  RankingQuestionV1DTO,
  RankingQuestionV1Enums,
} from "@pulse/pulse-rpcs";

export const RankingQuestionV1Store = types
  .model("RankingQuestionV1Store", {
    rankingQuestionV1Model: RankingQuestionV1Model,
  })
  .views((store) => ({
    get serializedJSON(): string {
      const options = store.rankingQuestionV1Model.options.map((option) => {
        return new OptionV1(
          new Code(option.code),
          new SurveyLocalizedText(option.option),
        );
      });

      if (store.rankingQuestionV1Model.areAllOptionsMandatory) {
        return JSON.stringify(
          new RankingQuestionV1(
            options,
            new RankingQuestionV1Enums.MandatoryCheck.AllOptionsMandatory(),
          ).toDTO(),
        );
      } else {
        return JSON.stringify(
          new RankingQuestionV1(
            options,
            new RankingQuestionV1Enums.MandatoryCheck.AllOptionsNotMandatory(
              store.rankingQuestionV1Model.maxSelection,
            ),
          ).toDTO(),
        );
      }
    },
    doesOptionCodeContainErrors(index: number): boolean {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );
      return (
        (store.rankingQuestionV1Model.options[index].code.trim() ===
          EMPTY_CHARACTER &&
          questionDetailsStore.fieldValidations ===
            FieldValidations.UnfilledOptionField) ||
        store.rankingQuestionV1Model.options[index].code.trim() ===
          questionDetailsStore.duplicateOptionCode
      );
    },
    doesOptionContainErrors(index: number): boolean {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );
      return (
        store.rankingQuestionV1Model.options[index].option.trim() ===
          EMPTY_CHARACTER &&
        questionDetailsStore.fieldValidations ===
          FieldValidations.UnfilledOptionField
      );
    },
    get isAddNewFieldButtonDisabled(): boolean {
      return store.rankingQuestionV1Model.options.length >= MAX_RANKING_OPTIONS;
    },
    isChevronUpDisabled(index: number): boolean {
      return index === 0;
    },
    isChevronDownDisabled(index: number): boolean {
      return index === store.rankingQuestionV1Model.options.length - 1;
    },
    get isDeleteButtonDisabled(): boolean {
      return store.rankingQuestionV1Model.options.length === 1;
    },
    get isResetDisabled(): boolean {
      if (store.rankingQuestionV1Model.options.length === 1) {
        return (
          store.rankingQuestionV1Model.options[0].code === EMPTY_CHARACTER &&
          store.rankingQuestionV1Model.options[0].option === EMPTY_CHARACTER
        );
      }
      return false;
    },
    get isMaxSelectionIncrementDisabled(): boolean {
      return (
        store.rankingQuestionV1Model.maxSelection >= MAX_RANKING_OPTIONS ||
        store.rankingQuestionV1Model.areAllOptionsMandatory
      );
    },
    get isMaxSelectionDecrementDisabled(): boolean {
      return (
        store.rankingQuestionV1Model.maxSelection <= MIN_RANKING_OPTIONS ||
        store.rankingQuestionV1Model.areAllOptionsMandatory
      );
    },
  }))
  .actions((store) => ({
    resetFieldValidationsAndErrors: (): void => {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );
      questionDetailsStore.setFieldValidations(null);
      questionDetailsStore.setRPCErrors(null);
      questionDetailsStore.setDuplicateOptionCode(null);
    },
  }))
  .actions((store) => ({
    setAreAllOptionsMandatory: (areAllOptionsMandatory: boolean): void => {
      store.rankingQuestionV1Model.maxSelection = MIN_RANKING_OPTIONS;
      store.rankingQuestionV1Model.areAllOptionsMandatory =
        areAllOptionsMandatory;
    },
    setMaxSelection: (value: number): void => {
      store.rankingQuestionV1Model.maxSelection = value;
    },
    incrementMaxSelection: (): void => {
      store.rankingQuestionV1Model.maxSelection++;
    },
    decrementMaxSelection: (): void => {
      store.rankingQuestionV1Model.maxSelection--;
    },
    setFieldOptionText: (fieldOptionText: string, index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.rankingQuestionV1Model.options[index].option = fieldOptionText;
    },
    setCode: (code: string, index: number): void => {
      if (doesCodeContainAllowedCharacters(code)) {
        store.resetFieldValidationsAndErrors();
        store.rankingQuestionV1Model.options[index].code = code;
      }
    },
    addNewField: (
      rankingOptionField: Instance<typeof RankingQuestionOptionsModel>,
      index: number,
    ): void => {
      store.resetFieldValidationsAndErrors();
      store.rankingQuestionV1Model.options.splice(
        index + 1,
        0,
        rankingOptionField,
      );
      store.rankingQuestionV1Model.maxSelection =
        store.rankingQuestionV1Model.options.length;
    },
    moveFieldUp: (index: number): void => {
      const rankingOptionFieldArrayCopy = [
        ...store.rankingQuestionV1Model.options,
      ];
      const replacedElement = rankingOptionFieldArrayCopy[index - 1];
      rankingOptionFieldArrayCopy[index - 1] =
        rankingOptionFieldArrayCopy[index];
      rankingOptionFieldArrayCopy[index] = replacedElement;
      store.rankingQuestionV1Model.options.replace(rankingOptionFieldArrayCopy);
    },
    moveFieldDown: (index: number): void => {
      const rankingChoiceFieldArrayCopy = [
        ...store.rankingQuestionV1Model.options,
      ];
      const replacedElement = rankingChoiceFieldArrayCopy[index + 1];
      rankingChoiceFieldArrayCopy[index + 1] =
        rankingChoiceFieldArrayCopy[index];
      rankingChoiceFieldArrayCopy[index] = replacedElement;
      store.rankingQuestionV1Model.options.replace(rankingChoiceFieldArrayCopy);
    },
    removeField: (index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.rankingQuestionV1Model.options.splice(index, 1);
      store.rankingQuestionV1Model.maxSelection =
        store.rankingQuestionV1Model.options.length;
    },
    resetOptions: (): void => {
      store.rankingQuestionV1Model.options = cast([
        {
          code: EMPTY_CHARACTER,
          option: EMPTY_CHARACTER,
        },
      ]);
      store.resetFieldValidationsAndErrors();
    },
    deserializeJSON: (): void => {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const rankingQuestionV1DTO = <RankingQuestionV1DTO>(
        JSON.parse(questionDetailsModel.questionDetailsJSON)
      );
      const rankingQuestionV1 = RankingQuestionV1.fromDTO(rankingQuestionV1DTO);
      if (
        rankingQuestionV1.mandatoryCheck instanceof
        RankingQuestionV1Enums.MandatoryCheck.AllOptionsMandatory
      ) {
        store.rankingQuestionV1Model.areAllOptionsMandatory = true;
      } else if (
        rankingQuestionV1.mandatoryCheck instanceof
        RankingQuestionV1Enums.MandatoryCheck.AllOptionsNotMandatory
      ) {
        store.rankingQuestionV1Model.maxSelection =
          rankingQuestionV1.mandatoryCheck.maxSelectionAllowed;
      }

      store.rankingQuestionV1Model.options = cast(
        rankingQuestionV1.options.map((rankingField) => {
          return createRankingQuestionOptionsModel(
            rankingField.code.code,
            rankingField.option.text,
          );
        }),
      );
    },
    validateFields: (): void => {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );

      store.rankingQuestionV1Model.options.forEach((option) => {
        if (
          option.code.trim() === EMPTY_CHARACTER ||
          option.option.trim() === EMPTY_CHARACTER
        ) {
          questionDetailsStore.setFieldValidations(
            FieldValidations.UnfilledOptionField,
          );
          return;
        }
      });
      if (
        store.rankingQuestionV1Model.maxSelection >
        store.rankingQuestionV1Model.options.length
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.MaxSelectionExceeded,
        );
      } else if (
        store.rankingQuestionV1Model.maxSelection < MIN_RANKING_OPTIONS
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.MaxSelectionCannotBeLessThanValidValue,
        );
      }
    },
    validateInitialOptionCodes(
      initialQuestionDetails: Instance<typeof store>,
    ): void {
      const questionDetailsStore = getParentOfType(store, QuestionDetailsStore);
      const updatedOptionCodes = store.rankingQuestionV1Model.options.map(
        (rankingOption) => {
          return rankingOption.code.trim();
        },
      );
      const initialOptionCodes =
        initialQuestionDetails.rankingQuestionV1Model.options.map(
          (rankingOption) => {
            return rankingOption.code;
          },
        );
      if (
        JSON.stringify(updatedOptionCodes) !==
        JSON.stringify(initialOptionCodes)
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.QuestionAndOptionCodesCannotBeUpdated,
        );
      }
    },
  }));

export const createRankingQuestionV1Store = (): Instance<
  typeof RankingQuestionV1Store
> => {
  return RankingQuestionV1Store.create({
    rankingQuestionV1Model: createRankingQuestionV1Model(undefined, []),
  });
};
