import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  Chip,
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
  ChipProps,
  DropdownItem,
  Banner,
  DropdownInputField,
  MultiAutoCompleteInputField,
  AutoCompleteItem,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { RespondentDetailTextInputField } from "./RespondentDetailTextInputField";
import { TFunction } from "i18next";
import {
  EMPTY_CHARACTER,
  MAX_RESPONDENT_COLUMN_LENGTH,
  MAX_RESPONDENT_EMAIL_ID_LENGTH,
  MAX_RESPONDENT_NAME_LENGTH,
  MAX_RESPONDENT_PHONE_NUMBER_LENGTH,
} from "@pulse/shared-components";
import { RespondentStore } from "../../store/RespondentStore";
import { Instance } from "mobx-state-tree";
import { observer } from "mobx-react";

interface EditChildComponentProps {
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  respondentStore: Instance<typeof RespondentStore>;
  surveyLinkStatusChipProps: ChipProps | undefined;
  areFieldsDisabled: boolean;
  childElementRef: React.RefObject<HTMLDivElement>;
}

export const EditChildComponent = observer(
  ({
    spacing,
    t,
    tokens,
    typography,
    respondentStore,
    surveyLinkStatusChipProps,
    areFieldsDisabled,
    childElementRef,
  }: EditChildComponentProps): React.ReactElement => {
    enum FieldIndex {
      FirstName,
      LastName,
      EmailId,
      PhoneNumber,
      BaseColumn,
    }

    const SurveyDropdown = observer((): React.ReactElement => {
      return (
        <Stack flexDirection="row" gap={spacing.spaceSM} width="100%">
          {/* We need to keep the typography in a container otherwise it will underflow. */}
          <Box>
            <Typography
              {...typography.s1}
              color={tokens.labelHighEmphasis}
              width="240px"
            >
              {t("projects.respondentList.columnNames.survey")}
            </Typography>
          </Box>
          <DropdownInputField
            name={EMPTY_CHARACTER}
            label={EMPTY_CHARACTER}
            isDisabled={areFieldsDisabled}
            placeholder={t(
              "projects.respondentList.editRespondentDetailsDialog.selectSurvey",
            )}
            value={
              respondentStore.selectedRespondentDetails?.selectedSurvey
                ?.surveyId
            }
            options={respondentStore.surveyList.map((survey) => {
              return { name: survey.surveyName, value: survey.surveyId };
            })}
            onSelect={(value: DropdownItem): void => {
              respondentStore.updateSelectedRespondentSurvey(value);
            }}
            width="100%"
          />
        </Stack>
      );
    });

    const MultiselectSPoC = observer((): React.ReactElement => {
      return (
        <Stack flexDirection="row" gap={spacing.spaceSM} width="100%">
          {/* We need to keep the typography in a container otherwise it will underflow. */}
          <Box>
            <Typography
              {...typography.s1}
              color={tokens.labelHighEmphasis}
              width="240px"
            >
              {t("projects.respondentList.editRespondentDetailsDialog.spoc")}
            </Typography>
          </Box>
          <MultiAutoCompleteInputField
            label={EMPTY_CHARACTER}
            isDisabled={areFieldsDisabled}
            options={respondentStore.projectSpocs.map((projectSPoC) => {
              return {
                id: projectSPoC.spocId,
                label: projectSPoC.userName.firstAndLastName,
              };
            })}
            selectType="checkbox"
            placeholder={t(
              "projects.respondentList.editRespondentDetailsDialog.selectSPoCs",
            )}
            value={respondentStore.selectedRespondentDetails?.selectedSPoCs.map(
              (selectedSPoC) => {
                return {
                  id: selectedSPoC.id,
                  label: selectedSPoC.name,
                };
              },
            )}
            onChange={(selectedSPoCs: AutoCompleteItem[]): void => {
              respondentStore.updateSelectedRespondentSPoCs(selectedSPoCs);
            }}
            noOptionsText={t(
              "projects.respondentList.editRespondentDetailsDialog.noSPoCFound",
            )}
          />
        </Stack>
      );
    });

    const getBannerErrorMessage = (): string => {
      if (respondentStore.isEditRespondentSurveyAlreadySubmittedError) {
        return t(
          "projects.respondentList.editRespondentDetailsDialog.errorMessages.surveyAlreadySubmittedErrorBannerText",
        );
      } else if (respondentStore.isEditRespondentAssignmentInProgressError) {
        return t(
          "projects.respondentList.editRespondentDetailsDialog.errorMessages.assignmentInProgressErrorBannerText",
        );
      } else {
        console.error(
          "This function should not be called if the error is anything other than `SURVEY_ALREADY_SUBMITTED` or `RESPONDENT_ASSIGNMENT_IN_PROGRESS` in edit respondents flow.",
        );
        return EMPTY_CHARACTER;
      }
    };

    return (
      <Stack
        gap={spacing.spaceLG}
        width="100%"
        padding={spacing.spaceLG}
        ref={childElementRef}
        height="100%"
        sx={{ overflowY: "auto" }}
      >
        {respondentStore.isEditRespondentErrorBannerShown && (
          <Banner
            message={getBannerErrorMessage()}
            severity={"error"}
            width="100%"
          />
        )}
        <Stack flexDirection="row" gap={spacing.spaceSM}>
          <Typography
            {...typography.s1}
            color={tokens.labelHighEmphasis}
            width="240px"
          >
            {t(
              "projects.respondentList.editRespondentDetailsDialog.surveyStatusLabel",
            )}
          </Typography>
          {surveyLinkStatusChipProps && <Chip {...surveyLinkStatusChipProps} />}
        </Stack>
        <SurveyDropdown />
        {respondentStore.isLiveRespondentsSelected && <MultiselectSPoC />}
        <Divider />
        <RespondentDetailTextInputField
          label={t("projects.respondentList.columnNames.firstName")}
          defaultValue={respondentStore.selectedRespondentFirstName}
          maxInputLength={MAX_RESPONDENT_NAME_LENGTH}
          callBack={respondentStore.updateSelectedRespondentFirstName}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          isFieldDisabled={areFieldsDisabled}
          setIsEditRespondentErrored={(isEditRespondentErrored: boolean) => {
            respondentStore.setIsEditRespondentErrored(
              FieldIndex.FirstName,
              isEditRespondentErrored,
            );
          }}
        />
        <RespondentDetailTextInputField
          label={t("projects.respondentList.columnNames.lastName")}
          defaultValue={respondentStore.selectedRespondentLastName}
          maxInputLength={MAX_RESPONDENT_NAME_LENGTH}
          callBack={respondentStore.updateSelectedRespondentLastName}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          isFieldDisabled={areFieldsDisabled}
          setIsEditRespondentErrored={(isEditRespondentErrored: boolean) => {
            respondentStore.setIsEditRespondentErrored(
              FieldIndex.LastName,
              isEditRespondentErrored,
            );
          }}
        />
        <RespondentDetailTextInputField
          label={t("projects.respondentList.columnNames.emailAddress")}
          defaultValue={respondentStore.selectedRespondentEmailAddress}
          maxInputLength={MAX_RESPONDENT_EMAIL_ID_LENGTH}
          callBack={respondentStore.updateSelectedRespondenEmailAddress}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          isFieldDisabled={areFieldsDisabled}
          setIsEditRespondentErrored={(isEditRespondentErrored: boolean) => {
            respondentStore.setIsEditRespondentErrored(
              FieldIndex.EmailId,
              isEditRespondentErrored,
            );
          }}
        />
        <RespondentDetailTextInputField
          label={t("projects.respondentList.columnNames.phoneNumber")}
          defaultValue={respondentStore.selectedRespondentPhoneNumber}
          maxInputLength={MAX_RESPONDENT_PHONE_NUMBER_LENGTH}
          callBack={respondentStore.updateSelectedRespondentPhoneNumber}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          isFieldDisabled={areFieldsDisabled}
          setIsEditRespondentErrored={(isEditRespondentErrored: boolean) => {
            respondentStore.setIsEditRespondentErrored(
              FieldIndex.PhoneNumber,
              isEditRespondentErrored,
            );
          }}
        />
        {respondentStore.respondentColumns.map((column, index) => {
          return (
            <RespondentDetailTextInputField
              key={index}
              label={column.name}
              defaultValue={respondentStore.selectedRespondentColumnData(
                `column${column.index}`,
              )}
              maxInputLength={MAX_RESPONDENT_COLUMN_LENGTH}
              callBack={(inputValue: string) => {
                respondentStore.updateSelectedRespondentColumnData(
                  `column${column.index}`,
                  inputValue,
                );
              }}
              t={t}
              spacing={spacing}
              typography={typography}
              tokens={tokens}
              isFieldDisabled={areFieldsDisabled}
              setIsEditRespondentErrored={(
                isEditRespondentErrored: boolean,
              ) => {
                respondentStore.setIsEditRespondentErrored(
                  FieldIndex.BaseColumn + column.index,
                  isEditRespondentErrored,
                );
              }}
            />
          );
        })}
      </Stack>
    );
  },
);
