import { PRIMARY_COLORS } from "@pulse/shared-components";
import { Typography } from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { Chart } from "react-chartjs-2";
import { options } from "./SurveySubmissionDataPane";
import { TFunction } from "i18next";

export interface BarAndLineChartProps {
  labels: string[];
  dailySubmissionData: number[];
  totalSubmissionData: number[];
  typography: Typography;
  t: TFunction;
}

export const BarAndLineChart = ({
  labels,
  dailySubmissionData,
  totalSubmissionData,
  typography,
  t,
}: BarAndLineChartProps): React.ReactElement => {
  return (
    <Chart
      type="bar"
      options={{
        scales: {
          xAxis: {
            grid: {
              display: false,
            },
          },
          y: {
            position: "left",
            ticks: {
              padding: 8,
              precision: 0,
            },
            title: {
              display: true,
              text: t("projects.dashboard.dailySubmissionsText"),
              font: {
                family: typography.s2.fontFamily,
                size: 14,
                weight: typography.s2.fontWeight,
                lineHeight: typography.s2.lineHeight,
              },
            },
          },
          line: {
            grid: {
              display: false,
            },
            beginAtZero: true,
            position: "right",
            ticks: {
              padding: 8,
              precision: 0,
            },
            title: {
              display: true,
              text: t("projects.dashboard.totalSubmissionsText"),
              font: {
                family: typography.s2.fontFamily,
                size: 14,
                weight: typography.s2.fontWeight,
                lineHeight: typography.s2.lineHeight,
              },
            },
          },
        },
        ...options,
      }}
      data={{
        labels,
        datasets: [
          {
            xAxisID: "xAxis",
            yAxisID: "line",
            label: t("projects.dashboard.totalSubmissionsText"),
            type: "line" as const,
            borderColor: PRIMARY_COLORS[400],
            pointBackgroundColor: PRIMARY_COLORS[400],
            data: totalSubmissionData,
          },
          {
            xAxisID: "xAxis",
            label: t("projects.dashboard.dailySubmissionsText"),
            type: "bar" as const,
            backgroundColor: PRIMARY_COLORS[300],
            data: dailySubmissionData,
          },
        ],
      }}
    />
  );
};
