import {
  Instance,
  cast,
  clone,
  flow,
  getParentOfType,
  getRoot,
  types,
} from "mobx-state-tree";
import {
  CommonErrors,
  EMBED_NAME_VALIDATIONS,
  EMPTY_LIST_LENGTH,
  IFRAME_REGEX,
  NetworkingError,
} from "@pulse/shared-components";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { ProjectDetailsStore } from "./ProjectDetailsStore";
import { getAPIClient } from "../../networking/APIClient";
import {
  AddNewEmbedRPC,
  DeleteEmbedRPC,
  EmbedCode,
  EmbedName,
  GetEmbedsRPC,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useAddNewEmbedRPCClient,
  useDeleteEmbedRPCClient,
  useGetEmbedsRPCClient,
} from "../../reports/rpcs/RPC";
import {
  EmbedDetailsModel,
  createEmbedDetailsModel,
} from "../../reports/models/EmbedDetailsModel";
import { RootStore } from "../../root/store/RootStore";
import { SpocUserDetailsModel } from "../../models/SpocUserDetailsModel";

export enum AddNewEmbedDialogState {
  AddNewEmbedSuccess = "Add New Embed Success",
  AddNewEmbedError = "Add New Embed Error",
  FetchingSPoCDetails = "Fetching SPoC Details",
  FetchingSPoCDetailsSuccess = "Fetching SPoC Details Success",
  FetchingSPoCDetailsError = "Fetching SPoC Details Error",
}

export enum AddNewEmbedError {
  InvalidSpocId = "INVALID_SPOC_ID",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  EmbedNameNotUnique = "EMBED_NAME_NOT_UNIQUE",
  MaximumEmbedCountReached = "MAXIMUM_EMBED_COUNT_REACHED",
  InvalidEmbedName = "INVALID_EMBED_NAME",
  InvalidEmbedNameLength = "INVALID_EMBED_NAME_LENGTH",
  InvalidEmbedCodeLength = "INVALID_EMBED_CODE_LENGTH",
  InvalidEmbedCode = "INVALID_EMBED_CODE",
}

export enum DeleteEmbedDialogState {
  DeleteEmbed = "Delete Embed",
  Error = "Error",
}

export enum DeleteEmbedError {
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  InvalidEmbedId = "INVALID_EMBED_ID",
}

export const EmbedStore = types
  .model("EmbedStore", {
    rpcAndValidationError: types.maybeNull(
      types.union(
        types.enumeration("addNewEmbedErrors", Object.values(AddNewEmbedError)),
        types.enumeration("networkErrors", Object.values(NetworkingError)),
        types.enumeration("deleteEmbedErrors", Object.values(DeleteEmbedError)),
      ),
    ),
    deleteEmbedDialogState: types.optional(
      types.enumeration(
        "deleteEmbedDialogState",
        Object.values(DeleteEmbedDialogState),
      ),
      DeleteEmbedDialogState.DeleteEmbed,
    ),
    addNewEmbedDialogState: types.optional(
      types.enumeration(
        "addNewEmbedDialogState",
        Object.values(AddNewEmbedDialogState),
      ),
      AddNewEmbedDialogState.FetchingSPoCDetails,
    ),
    embedName: types.optional(types.string, EMPTY_CHARACTER),
    embedCode: types.optional(types.string, EMPTY_CHARACTER),
    isRPCLoading: types.optional(types.boolean, false),
    isAssignToSelectedSpocsChecked: types.optional(types.boolean, false),
    embedDetails: types.array(EmbedDetailsModel),
    selectedEmbedDetails: types.maybeNull(EmbedDetailsModel),
    selectedEmbedDetailsView: types.maybeNull(EmbedDetailsModel),
    isEmbedsPaneLoading: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get isEmbedCodeInvalid(): boolean {
      return !IFRAME_REGEX.test(store.embedCode);
    },
    get doesEmbedNameTextFieldContainError(): boolean {
      return (
        store.rpcAndValidationError === AddNewEmbedError.InvalidEmbedName ||
        store.rpcAndValidationError ===
          AddNewEmbedError.InvalidEmbedNameLength ||
        store.rpcAndValidationError === AddNewEmbedError.EmbedNameNotUnique
      );
    },
    get doesEmbedCodeTextFieldContainError(): boolean {
      return (
        store.rpcAndValidationError === AddNewEmbedError.InvalidEmbedCode ||
        store.rpcAndValidationError === AddNewEmbedError.InvalidEmbedCodeLength
      );
    },
    get isEmbedDialogPrimaryButtonDisabled(): boolean {
      return (
        store.embedName === EMPTY_CHARACTER ||
        store.embedCode === EMPTY_CHARACTER
      );
    },
  }))
  .actions((store) => ({
    clearRPCError: (): void => {
      store.rpcAndValidationError = null;
    },
  }))
  .actions((store) => ({
    setIsEmbedsPaneLoading: (isEmbedsPaneLoading: boolean): void => {
      store.isEmbedsPaneLoading = isEmbedsPaneLoading;
    },
    setSelectedEmbedSpocDetails: (embedId: string): void => {
      const selectedEmbedDetails = store.embedDetails.find((embedDetails) => {
        return embedDetails.id === embedId;
      });
      if (selectedEmbedDetails) {
        store.selectedEmbedDetails = clone(selectedEmbedDetails);
      } else {
        console.error(`Invalid Embed Id ${embedId} found.`);
      }
    },
    setEmbedName: (embedName: string): void => {
      store.clearRPCError();
      store.embedName = embedName;
    },
    setEmbedCode: (embedCode: string): void => {
      store.clearRPCError();
      store.embedCode = embedCode;
    },
    setAssignToSelectedSpocs: (assignToSelectedSpocs: boolean): void => {
      store.isAssignToSelectedSpocsChecked = assignToSelectedSpocs;
    },
    resetAddNewEmbedDialog: (): void => {
      const projectDetailsStore = getParentOfType(store, ProjectDetailsStore);
      projectDetailsStore.clearProjectSpocs();
      projectDetailsStore.clearSelectedSpocs();
      projectDetailsStore.clearRPCError();
      store.embedCode = EMPTY_CHARACTER;
      store.embedName = EMPTY_CHARACTER;
      store.addNewEmbedDialogState = AddNewEmbedDialogState.FetchingSPoCDetails;
      store.rpcAndValidationError = null;
      store.isRPCLoading = false;
      store.isAssignToSelectedSpocsChecked = false;
    },
    resetDeleteEmbedDialog: (): void => {
      store.selectedEmbedDetails = null;
      store.deleteEmbedDialogState = DeleteEmbedDialogState.DeleteEmbed;
    },
    setAddNewEmbedDialogState: (
      addNewEmbedDialogState: AddNewEmbedDialogState,
    ): void => {
      store.addNewEmbedDialogState = addNewEmbedDialogState;
    },
    setDeleteEmbedDialogState: (
      deleteEmbedDialogState: DeleteEmbedDialogState,
    ): void => {
      store.deleteEmbedDialogState = deleteEmbedDialogState;
    },
    validateAndGetIsEmbedNameValid: (): boolean => {
      try {
        new EmbedName(store.embedName.trim());
        const regex = new RegExp(EMBED_NAME_VALIDATIONS.allowedCharactersRegex);
        if (!regex.test(store.embedName)) {
          store.rpcAndValidationError = AddNewEmbedError.InvalidEmbedName;
          return false;
        }
        return true;
      } catch (e) {
        store.rpcAndValidationError = AddNewEmbedError.InvalidEmbedNameLength;
        return false;
      }
    },
    validateAndGetIsEmbedCodeValid: (): boolean => {
      if (store.isEmbedCodeInvalid) {
        store.rpcAndValidationError = AddNewEmbedError.InvalidEmbedCode;
        return false;
      }
      try {
        new EmbedCode(store.embedCode.trim());
        return true;
      } catch (e) {
        store.rpcAndValidationError = AddNewEmbedError.InvalidEmbedCodeLength;
        return false;
      }
    },
  }))
  .actions((store) => ({
    resetEmbedDetailsView: (): void => {
      store.selectedEmbedDetailsView = null;
    },
    setEmbedDetailsViewById: (embedId: string): void => {
      const selectedEmbedDetails = store.embedDetails.find((embedDetails) => {
        return embedDetails.id === embedId;
      });
      if (selectedEmbedDetails !== undefined) {
        store.selectedEmbedDetailsView = clone(selectedEmbedDetails);
      } else {
        console.error(`Embed with id ${embedId} not found.`);
      }
    },
    validateNewEmbedInputFields: (): boolean => {
      return (
        store.validateAndGetIsEmbedNameValid() &&
        store.validateAndGetIsEmbedCodeValid()
      );
    },
    addNewEmbed: flow(function* (projectId: string) {
      try {
        store.isRPCLoading = true;
        const projectDetailsStore = getParentOfType(store, ProjectDetailsStore);
        const apiClient = getAPIClient(store);
        const request: AddNewEmbedRPC.Request = new AddNewEmbedRPC.Request(
          new EmbedName(store.embedName),
          new EmbedCode(store.embedCode),
          store.isAssignToSelectedSpocsChecked
            ? projectDetailsStore.selectedSpocIds.map(
                (spocId) => new LeoUUID(spocId),
              )
            : [],
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: AddNewEmbedRPC.Response;
          error?: AddNewEmbedRPC.Errors.Errors;
        } = yield useAddNewEmbedRPCClient(apiClient).execute(request);

        if (response) {
          store.embedDetails = cast(
            response.embedDetails.map((embedDetail) => {
              return createEmbedDetailsModel(embedDetail);
            }),
          );
          store.addNewEmbedDialogState =
            AddNewEmbedDialogState.AddNewEmbedSuccess;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              store.addNewEmbedDialogState =
                AddNewEmbedDialogState.AddNewEmbedError;
              break;
            case AddNewEmbedError.ProjectAlreadyArchived:
              store.addNewEmbedDialogState =
                AddNewEmbedDialogState.AddNewEmbedError;
              store.rpcAndValidationError =
                AddNewEmbedError.ProjectAlreadyArchived;
              break;
            case AddNewEmbedError.InvalidSpocId:
              store.addNewEmbedDialogState =
                AddNewEmbedDialogState.AddNewEmbedError;
              store.rpcAndValidationError = AddNewEmbedError.InvalidSpocId;
              break;
            case AddNewEmbedError.InvalidEmbedName:
              // We are returning here and not setting addNewEmbedDialogState to AddNewEmbedDialogState.AddNewEmbedError.
              // This is done because this error is handled through helper text.
              // Setting addNewEmbedDialogState to AddNewEmbedDialogState.AddNewEmbedError will render the error dialog.
              store.rpcAndValidationError = AddNewEmbedError.InvalidEmbedName;
              break;
            case AddNewEmbedError.EmbedNameNotUnique:
              // We are returning here and not setting addNewEmbedDialogState to AddNewEmbedDialogState.AddNewEmbedError.
              // This is done because this error is handled through helper text.
              // Setting addNewEmbedDialogState to AddNewEmbedDialogState.AddNewEmbedError will render the error dialog.
              store.rpcAndValidationError = AddNewEmbedError.EmbedNameNotUnique;
              break;
            case AddNewEmbedError.MaximumEmbedCountReached:
              store.addNewEmbedDialogState =
                AddNewEmbedDialogState.AddNewEmbedError;
              store.rpcAndValidationError =
                AddNewEmbedError.MaximumEmbedCountReached;
              break;
          }
        }
      } catch (e) {
        // If there is an error, we need to reset the add embed dialog, since the user can navigate back to this page.
        store.resetAddNewEmbedDialog();
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    getEmbedDetails: flow(function* (projectId: string) {
      try {
        store.isRPCLoading = true;

        const apiClient = getAPIClient(store);
        const request = new GetEmbedsRPC.Request(new LeoUUID(projectId));
        const {
          response,
          error,
        }: {
          response?: GetEmbedsRPC.Response;
          error?: GetEmbedsRPC.Errors.Errors;
        } = yield useGetEmbedsRPCClient(apiClient).execute(request);

        if (response) {
          store.embedDetails = cast(
            response.embedDetails.map((embedDetail) => {
              return createEmbedDetailsModel(embedDetail);
            }),
          );
          return response.projectDetails;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    clearSelectedEmbedDetails: (): void => {
      store.selectedEmbedDetails = null;
    },
    deleteEmbed: flow(function* (projectId: string) {
      try {
        store.isRPCLoading = true;

        const apiClient = getAPIClient(store);
        const request = new DeleteEmbedRPC.Request(
          new LeoUUID(store.selectedEmbedDetails?.id),
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: DeleteEmbedRPC.Response;
          error?: DeleteEmbedRPC.Errors.Errors;
        } = yield useDeleteEmbedRPCClient(apiClient).execute(request);

        if (response) {
          store.embedDetails = cast(
            response.embedDetails.map((embedDetail) => {
              return createEmbedDetailsModel(embedDetail);
            }),
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              // Handled in default response interceptor.
              break;
            case DeleteEmbedError.InvalidEmbedId:
              store.setDeleteEmbedDialogState(DeleteEmbedDialogState.Error);
              store.rpcAndValidationError = DeleteEmbedError.InvalidEmbedId;
              break;
            case DeleteEmbedError.ProjectAlreadyArchived:
              store.setDeleteEmbedDialogState(DeleteEmbedDialogState.Error);
              store.rpcAndValidationError =
                DeleteEmbedError.ProjectAlreadyArchived;
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          store.setDeleteEmbedDialogState(DeleteEmbedDialogState.Error);
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }))
  .views((store) => ({
    get isAddNewEmbedDialogPrimaryButtonVisible(): boolean {
      return (
        (store.rpcAndValidationError === null ||
          store.doesEmbedNameTextFieldContainError ||
          store.doesEmbedCodeTextFieldContainError) &&
        store.addNewEmbedDialogState ===
          AddNewEmbedDialogState.FetchingSPoCDetailsSuccess
      );
    },
    get doesEmbedStoreContainRPCError(): boolean {
      return store.rpcAndValidationError !== null;
    },
    get isInvalidEmbedCodeLength(): boolean {
      return (
        store.rpcAndValidationError === AddNewEmbedError.InvalidEmbedCodeLength
      );
    },
    get isEmbedDetailsEmpty(): boolean {
      return store.embedDetails.length === EMPTY_LIST_LENGTH;
    },
    get isValidEmbedView(): boolean {
      if (
        store.selectedEmbedDetailsView?.embedCode &&
        IFRAME_REGEX.test(store.selectedEmbedDetailsView?.embedCode)
      ) {
        return true;
      } else {
        return false;
      }
    },
    get isDeleteEmbedDialogState(): boolean {
      return (
        store.deleteEmbedDialogState === DeleteEmbedDialogState.DeleteEmbed
      );
    },
    matchedEmbedSpocs(
      spocName: string,
    ): Instance<typeof SpocUserDetailsModel>[] {
      return (
        store.selectedEmbedDetails?.spocs.filter((respondentSpoc) => {
          return respondentSpoc.userName.firstAndLastName
            .toLowerCase()
            .includes(spocName.toLowerCase().toString());
        }) ?? []
      );
    },
    get isEmbedSpocListEmpty(): boolean {
      return store.selectedEmbedDetails?.spocs.length === EMPTY_LIST_LENGTH;
    },
  }));

export const createEmbedStore = (): Instance<typeof EmbedStore> => {
  return EmbedStore.create();
};
