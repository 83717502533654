import React from "react";
import { FileUploadDescriptionProps } from "./FileUploadedDescription";
import { CircularProgress, Stack, Typography } from "@mui/material";

export const UploadValidationLoader = ({
  fileName,
  t,
  spacing,
  typography,
  tokens,
}: FileUploadDescriptionProps): React.ReactElement => {
  return (
    <Stack spacing={spacing.spaceXXS}>
      <Typography sx={{ ...typography.s2, color: tokens.label }}>
        {t("projects.respondentList.fileDetails")}
      </Typography>
      <Typography sx={{ ...typography.b2, color: tokens.label }}>
        {fileName}
      </Typography>
      <Stack direction="row" gap={spacing.spaceXXS}>
        <CircularProgress size="20px" style={{ color: tokens.iconPrimary }} />
        <Typography sx={{ ...typography.b2, color: tokens.labelPrimary }}>
          {t("projects.respondentList.validatingFile")}
        </Typography>
      </Stack>
    </Stack>
  );
};
