import {
  GetProjectSpocsPaginationResponse,
  GetSpocDetailsRPC,
} from "@pulse/pulse-rpcs";
import { EMPTY_CHARACTER, SINGLE_SPACING } from "@pulse/shared-components";
import { Instance, types } from "mobx-state-tree";

export const ManageSPoCsDetailsModel = types
  .model("ManageSPoCsDetails", {
    fullName: types.string,
    emailAddress: types.string,
    spocId: types.string,
    projectId: types.string,
    createdOn: types.Date,
    expiresOn: types.Date,
    firstName: types.optional(types.string, EMPTY_CHARACTER),
    lastName: types.optional(types.string, EMPTY_CHARACTER),
  })
  .views((store) => ({
    get spocFullName(): string {
      return store.firstName + SINGLE_SPACING + store.lastName;
    },
  }))
  .actions((store) => ({
    setFirstName: (firstName: string): void => {
      store.firstName = firstName;
    },
    setLastName: (lastName: string): void => {
      store.lastName = lastName;
    },
  }));

export const createManageSPoCsDetails = (
  spocDetails: GetProjectSpocsPaginationResponse | GetSpocDetailsRPC.Response,
): Instance<typeof ManageSPoCsDetailsModel> => {
  if (spocDetails instanceof GetProjectSpocsPaginationResponse) {
    return ManageSPoCsDetailsModel.create({
      createdOn: new Date(spocDetails.addedOn.timestamp),
      emailAddress: spocDetails.emailAddress,
      expiresOn: new Date(spocDetails.expiresOn.date),
      fullName: spocDetails.name,
      projectId: spocDetails.projectId.uuid,
      spocId: spocDetails.spocId.uuid,
    });
  } else {
    return ManageSPoCsDetailsModel.create({
      createdOn: new Date(spocDetails.addedOn.timestamp),
      emailAddress: spocDetails.spocDetails.emailId.email,
      expiresOn: new Date(spocDetails.expiresOn.date),
      fullName:
        spocDetails.spocDetails.userName.firstName +
        SINGLE_SPACING +
        spocDetails.spocDetails.userName.lastName,
      firstName: spocDetails.spocDetails.userName.firstName,
      lastName: spocDetails.spocDetails.userName.lastName,
      projectId: spocDetails.projectId.uuid,
      spocId: spocDetails.spocDetails.spocId.uuid,
    });
  }
};
