import { Stack } from "@mui/material";
import {
  Breadcrumb,
  BreadcrumbProps,
  Spacing,
  Tabs,
  TabsProps,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { Header, HeaderProps } from "./Header";
import {
  BorderStyle,
  RootContainer,
  useBorder,
} from "@pulse/shared-components";
import { SectionHeader, SectionHeaderProps } from "./SectionHeader";
import { TopNavBar } from "./TopNavBar";

interface DetailSectionComponentProps extends React.PropsWithChildren {
  mainHeaderProps: HeaderProps;
  tabsProps?: TabsProps;
  sectionHeaderProps?: SectionHeaderProps;
  breadcrumbProps?: BreadcrumbProps;
  isOverflowHidden: boolean;
  onLogoClickCallback?: () => void;
  isChildHeightVariable: boolean;
  isManageUsersLogoVisible: boolean;
  isAuditLogsLogoVisible: boolean;
}

/**
 * Creates a Details section component.
 * @param mainHeaderProps - Props of the header for the main header component.
 * @param tabsProps - Props of the tabs if tabs are required for the particular page.
 * @param sectionHeaderProps - Props of the section header, if the section also requires a header.
 * @param isBreadcrumbComponentRequired - It is a boolean value to indicate if the breadcrumb component needs to be displayed or not.
 * @param isOverflowHidden - This is a boolean required to indicate if the overflow propery of the child component should be set to hidden or not.
 * @param onLogoClickCallback - The callback that is called when the logo is clicked. If not provided, user is navigated to the projects screen.
 * @param isChildHeightVariable - This is a boolean that is used to indicate whether the child of DetailSectionComponent has a variable height. This needs to be set in case the child contains elements in a flex-wrap.
 * In screens where the scrollbar should be taken care of within the child component, this should be set to true
 * @returns A details section component after rendering the child component in the middle of the page.
 *
 * @example
 *   const primaryButton = (
    <Button
      name="primaryButton"
      variant="filled"
      size="medium"
      title="Button Label"
      onClick={(): void => {}}
      iconPosition="left"
    />
  );
    <DetailSectionComponent
      mainHeaderProps={{
        title: "Tata Consultants New 2023",
        icon: <Briefcase />,
        prefersLargeTitle: true,
        actionElement: primaryButton,
        chipProps: { label: "{Project Date Range}", color: "neutral" },
      }}
      tabsProps={{
        tabs: [
          {
            label: "First Tab",
          },
          {
            label: "Disabled Tab",
            isDisabled: true,
          },
        ],
        onTabChange: (): void => {},
        tabIndex: 0,
      }}
      sectionHeaderProps={{
        title: t("common.projectTitle"),
        actionElement: primaryButton,
      }}
    >
      <Typography>{"Not yet implemented."}</Typography>
    </DetailSectionComponent>
 */
interface MainHeaderProps {
  tabsProps?: TabsProps;
  breadcrumbProps?: BreadcrumbProps;
  spacing: Spacing;
  border: BorderStyle;
  mainHeaderProps: HeaderProps;
}

const MainHeader = ({
  border,
  breadcrumbProps,
  mainHeaderProps,
  spacing,
  tabsProps,
}: MainHeaderProps): React.ReactElement => {
  return (
    <Stack
      sx={{
        // The padding is set based on whether tabs are present or not.
        // In case tabs are present, the bottom border is aligned to the bottom of the tabs, hence we do not have bottom padding.
        padding: tabsProps
          ? `${spacing.spaceXL} ${spacing.space2XL} 0px ${spacing.space2XL}`
          : `${spacing.spaceXL} ${spacing.space2XL}`,
        gap: spacing.spaceMD,
        borderBottom: border.default,
      }}
    >
      {breadcrumbProps && <Breadcrumb {...breadcrumbProps} />}
      <Header {...mainHeaderProps} />
      {tabsProps && <Tabs {...tabsProps} />}
    </Stack>
  );
};

export const DetailSectionComponent = ({
  isChildHeightVariable,
  isOverflowHidden,
  mainHeaderProps,
  breadcrumbProps,
  children,
  onLogoClickCallback,
  sectionHeaderProps,
  tabsProps,
  isManageUsersLogoVisible,
  isAuditLogsLogoVisible,
}: DetailSectionComponentProps): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const spacing = useSpacing();

  const childComponent = (): React.ReactElement => {
    return (
      <Stack
        width={"100%"}
        height={isChildHeightVariable ? undefined : "100%"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ overflowY: isOverflowHidden ? "hidden" : "unset" }}
      >
        {children}
      </Stack>
    );
  };

  return (
    <RootContainer>
      <Stack position={"sticky"} zIndex={4}>
        <TopNavBar
          onLogoClickCallback={onLogoClickCallback}
          isManageUsersLogoEnabled={isManageUsersLogoVisible}
          isAuditLogsLogoEnabled={isAuditLogsLogoVisible}
        />
        <MainHeader
          breadcrumbProps={breadcrumbProps}
          spacing={spacing}
          border={border}
          mainHeaderProps={mainHeaderProps}
          tabsProps={tabsProps}
        />
        {/* TODO: Update this Page Header Leo component to use the Project specific section header component where text is centrally aligned to the action element.
         * Ticket Link: https://feedbackinsights.atlassian.net/browse/PUL2-24
         */}
      </Stack>
      {sectionHeaderProps && (
        <Stack
          position={"sticky"}
          // Zindex is set to 999 here to override the z index of the table component header and table pinned columns.
          zIndex={999}
          sx={{ top: 0, background: tokens.backgroundElevatedLevel1 }}
        >
          <SectionHeader {...sectionHeaderProps} />
        </Stack>
      )}
      {childComponent()}
    </RootContainer>
  );
};
