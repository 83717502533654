import {
  AddBOUserRPC,
  AddBOUserRPCClientImpl,
  ArchiveBOUserRPC,
  ArchiveBOUserRPCClientImpl,
  EditBOUserRPC,
  EditBOUserRPCClientImpl,
  GetBOUserRolesRPC,
  GetBOUserRolesRPCClientImpl,
  GetBOUsersRPC,
  GetBOUsersRPCClientImpl,
  SendPasswordResetLinkToBOUserRPC,
  SendPasswordResetLinkToBOUserRPCClientImpl,
} from "@pulse/pulse-rpcs";
import { MOCK } from "@pulse/shared-components";
import { APIClient } from "@surya-digital/tedwig";
import { MockAddBOUserRPCClientImpl } from "./MockAddBOUserRPCClientImpl";
import { MockGetBOUserRolesRPCClientImpl } from "./MockGetBOUserRolesRPCClientImpl";
import { MockEditBOUserRPCClientImpl } from "./MockEditBOUserRPCClientImpl";
import { MockGetBOUsersRPCClientImpl } from "./MockGetBOUsersRPCClientImpl";
import { MockSendPasswordResetLinkToBOUserRPCClientImpl } from "./MockSendPasswordResetLinkToBOUserRPCClientImpl";
import { MockArchiveBOUserRPCClientImpl } from "./MockArchiveBOUserRPCClientImpl";

export const useGetBOUsersRPCClient = (apiClient: APIClient): GetBOUsersRPC => {
  if (MOCK.boManagement) {
    return new MockGetBOUsersRPCClientImpl();
  } else {
    return new GetBOUsersRPCClientImpl(apiClient);
  }
};

export const useAddBOUserRPCClientImpl = (
  apiClient: APIClient,
): AddBOUserRPC => {
  if (MOCK.boManagement) {
    return new MockAddBOUserRPCClientImpl();
  } else {
    return new AddBOUserRPCClientImpl(apiClient);
  }
};

export const useGetBOUserRolesRPCClientImpl = (
  apiClient: APIClient,
): GetBOUserRolesRPC => {
  if (MOCK.boManagement) {
    return new MockGetBOUserRolesRPCClientImpl();
  } else {
    return new GetBOUserRolesRPCClientImpl(apiClient);
  }
};

export const useEditBOUserRPCClientImpl = (
  apiClient: APIClient,
): EditBOUserRPC => {
  if (MOCK.boManagement) {
    return new MockEditBOUserRPCClientImpl();
  } else {
    return new EditBOUserRPCClientImpl(apiClient);
  }
};

export const useSendPasswordResetLinkToBOUserRPCClientImpl = (
  apiClient: APIClient,
): SendPasswordResetLinkToBOUserRPC => {
  if (MOCK.boManagement) {
    return new MockSendPasswordResetLinkToBOUserRPCClientImpl();
  } else {
    return new SendPasswordResetLinkToBOUserRPCClientImpl(apiClient);
  }
};
export const useArchiveBOUserRPCClientImpl = (
  apiClient: APIClient,
): ArchiveBOUserRPC => {
  if (MOCK.boManagement) {
    return new MockArchiveBOUserRPCClientImpl();
  } else {
    return new ArchiveBOUserRPCClientImpl(apiClient);
  }
};
