import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React, { useEffect } from "react";
import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { DialogErrorContent } from "../../../components/DialogErrorContent";
import { Stack, Typography } from "@mui/material";
import { HelpCircle, Info } from "lucide-react";
import { getIconProps, ICON_SIZE } from "@pulse/shared-components";
import { observer } from "mobx-react";
import {
  ProfileStore,
  ResetUserPasswordRPCErrors,
  ResetUserPasswordDialogState,
} from "../store/ProfileStore";
import { RESET_PASSWORD_EMAIL_WAIT_DURATION_IN_MINUTES } from "@pulse/shared-components";

interface ResetUserPasswordDialogProps {
  isResetUserPasswordDialogVisible: boolean;
  setIsResetUserPasswordDialogVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  t: TFunction;
  spacing: Spacing;
  profileStore: Instance<typeof ProfileStore>;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

export const ResetUserPasswordDialog = observer(
  ({
    isResetUserPasswordDialogVisible,
    setIsResetUserPasswordDialogVisible,
    profileStore,
    spacing,
    t,
    tokens,
    typography,
  }: ResetUserPasswordDialogProps): React.ReactElement => {
    useEffect(() => {
      return () => {
        profileStore.resetResetUserPasswordDialog();
      };
    }, []);

    const getPrimaryButtonText = (): string | undefined => {
      switch (profileStore.resetUserPasswordDialogState) {
        case ResetUserPasswordDialogState.Error:
        case ResetUserPasswordDialogState.Success: {
          return undefined;
        }
        case ResetUserPasswordDialogState.ResetUserPassword: {
          return t("common.proceed");
        }
      }
    };

    const getSecondaryButtonText = (): string | undefined => {
      switch (profileStore.resetUserPasswordDialogState) {
        case ResetUserPasswordDialogState.Error: {
          return t("common.close");
        }
        case ResetUserPasswordDialogState.ResetUserPassword: {
          return t("common.cancel");
        }
        case ResetUserPasswordDialogState.Success: {
          return t("common.done");
        }
      }
    };

    const getErrorText = (): string => {
      switch (profileStore.rpcError) {
        case ResetUserPasswordRPCErrors.EmailAlreadySent: {
          return t("errors.resetPasswordEmailAlreadySent", {
            resetEmailWaitDurationInMinutes:
              RESET_PASSWORD_EMAIL_WAIT_DURATION_IN_MINUTES,
          });
        }
        case ResetUserPasswordRPCErrors.ResetPasswordLimitReached: {
          return t("errors.resetPasswordLimitReached");
        }
        case ResetUserPasswordRPCErrors.SendEmailFailed:
        default: {
          return t(
            "common.profileSettings.resetUserPasswordDialog.unexpectedErrorText",
          );
        }
      }
    };

    const getDialogTitle = (): string => {
      switch (profileStore.resetUserPasswordDialogState) {
        case ResetUserPasswordDialogState.Error: {
          return t("common.profileSettings.failedToSendEmail");
        }
        case ResetUserPasswordDialogState.ResetUserPassword: {
          return t(
            "common.profileSettings.resetUserPasswordDialog.sendAuthenticationEmail",
          );
        }
        case ResetUserPasswordDialogState.Success: {
          return t(
            "common.profileSettings.resetUserPasswordDialog.emailSentSuccessfully",
          );
        }
      }
    };

    const DialogChild = (): React.ReactElement => {
      switch (profileStore.resetUserPasswordDialogState) {
        case ResetUserPasswordDialogState.ResetUserPassword: {
          return (
            <Stack gap={spacing.spaceXS} direction="row">
              <HelpCircle {...getIconProps(tokens.icon, ICON_SIZE.default)} />
              <Typography {...typography.b2} color={tokens.label} width="492px">
                {t(
                  "common.profileSettings.resetUserPasswordDialog.dialogDescription",
                )}
              </Typography>
            </Stack>
          );
        }
        case ResetUserPasswordDialogState.Error: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
        case ResetUserPasswordDialogState.Success: {
          return (
            <Stack direction="row" gap={spacing.spaceXS} width="100%">
              <Info {...getIconProps(tokens.iconInfo, ICON_SIZE.default)} />
              <Typography
                {...typography.b2}
                color={tokens.labelInfo}
                width="492px"
              >
                {t(
                  "common.profileSettings.resetUserPasswordDialog.successText",
                )}
              </Typography>
            </Stack>
          );
        }
      }
    };

    return (
      <Dialog
        open={isResetUserPasswordDialogVisible}
        title={getDialogTitle()}
        contentPadding={spacing.spaceLG}
        disableBackdropClick={true}
        primaryButtonText={getPrimaryButtonText()}
        secondaryButtonText={getSecondaryButtonText()}
        onPrimaryButtonClick={async (): Promise<void> => {
          await profileStore.resetUserPassword();
        }}
        isSecondaryButtonDisabled={profileStore.isResetPasswordRPCLoading}
        onSecondaryButtonClick={() => {
          setIsResetUserPasswordDialogVisible(false);
          profileStore.resetResetUserPasswordDialog();
        }}
        width="560px"
      >
        {<DialogChild />}
      </Dialog>
    );
  },
);
