import React from "react";
import {
  FoundationColorTokens,
  Spacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Stack, Typography } from "@mui/material";
import { CustomWidthTooltip } from "./CustomWidthToolTip";
import { InfoIcon } from "lucide-react";
import { getIconProps, ICON_SIZE } from "@pulse/shared-components";
import { QuestionOptionsTooltipDescription } from "./QuestionOptionsTooltipDescription";

interface QuestionOptionsTitleWithTooltipProps {
  title: string;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  marginBottom?: string;
}

export const QuestionOptionsTitleWithTooltip = ({
  spacing,
  title,
  tokens,
  marginBottom,
}: QuestionOptionsTitleWithTooltipProps): React.ReactNode => {
  const typography = useTypography();

  return (
    <Stack flexDirection={"row"} gap={spacing.spaceSM} mb={marginBottom}>
      <Typography {...typography.sh1} color={tokens.label}>
        {title}
      </Typography>
      <CustomWidthTooltip
        title={<QuestionOptionsTooltipDescription />}
        sx={{ marginTop: spacing.spaceXXS }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: tokens.backgroundSubtle,
              color: tokens.labelSubtle,
            },
          },
        }}
      >
        <Typography>
          <InfoIcon
            {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.medium)}
          />
        </Typography>
      </CustomWidthTooltip>
    </Stack>
  );
};
