import React from "react";
import { NavigateToFunctions } from "../routes/RoutesHelper";
import { NetworkingError } from "@pulse/shared-components";

export const navigateOrGetNetworkErrorPage = (
  error: NetworkingError | null,
  navigateTo: NavigateToFunctions,
  removeError: () => void,
): React.ReactElement | void => {
  if (error) {
    switch (error) {
      case NetworkingError.InternalServerError:
      case NetworkingError.InvalidRequest:
      case NetworkingError.RPCError: {
        removeError();
        navigateTo.internalServerError();
        break;
      }
      case NetworkingError.Unauthorized:
      case NetworkingError.PageNotFound: {
        removeError();
        navigateTo.pageNotFound();
        break;
      }
      case NetworkingError.Unauthenticated:
      case NetworkingError.InvalidWT: {
        removeError();
        navigateTo.signIn();
        break;
      }
      case NetworkingError.TimeOut:
      case NetworkingError.NetworkError: {
        removeError();
        navigateTo.networkError();
        break;
      }
    }
  }
};
