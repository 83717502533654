import { GetBOUsersPaginationResponse } from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";

export const ManageBOUsersDetailsModel = types.model(
  "ManageBOUsersDetailsModel",
  {
    firstName: types.string,
    lastName: types.string,
    emailAddress: types.string,
    boUserId: types.string,
    createdOn: types.Date,
    role: types.string,
  },
);

export const createManageBOUsersDetailsModel = (
  boUserDetails: GetBOUsersPaginationResponse,
): Instance<typeof ManageBOUsersDetailsModel> => {
  return ManageBOUsersDetailsModel.create({
    createdOn: new Date(boUserDetails.addedOn.timestamp),
    emailAddress: boUserDetails.emailAddress,
    firstName: boUserDetails.firstName,
    lastName: boUserDetails.lastName,
    boUserId: boUserDetails.boUserId.uuid,
    role: boUserDetails.role,
  });
};
