import {
  Banner,
  FoundationColorTokens,
  IconButton,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Link } from "lucide-react";
import React, { useState } from "react";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../routes/RoutesHelper";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { SurveyStore } from "../modules/surveys/store/SurveyStore";
import { Box } from "@mui/material";
import { GENERATE_GENERIC_LINK_BANNER_LIFE_SPAN_IN_MS } from "@pulse/shared-components";

export interface GenerateGenericSurveyLinkProps {
  surveyStore: Instance<typeof SurveyStore>;
  navigateTo: NavigateToFunctions;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
}

export const GenerateGenericSurveyLink = ({
  navigateTo,
  t,
  spacing,
}: GenerateGenericSurveyLinkProps): React.ReactElement => {
  const [isSuccessBannerVisible, setIsSuccessBannerVisible] = useState(false);

  return (
    <>
      {isSuccessBannerVisible && (
        <Box
          position="absolute"
          top="24px"
          left="40%"
          padding={`${spacing.spaceXS} ${spacing.spaceMD}`}
        >
          <Banner
            severity="info"
            message={t(
              "surveys.generateGenericSurveyLink.surveyLinkCopiedToClipboard",
            )}
          />
        </Box>
      )}
      <IconButton
        name={"generateGenericSurveyLink"}
        size={"medium"}
        icon={<Link />}
        variant={"plain-neutral"}
        onClick={(): void => {
          processSurveyParams(
            async (surveyId: string, projectId: string): Promise<void> => {
              navigator.clipboard.writeText(
                `${import.meta.env.VITE_BASE_CUSTOMER_FACING_LINK}generic-link?projectId=${projectId}&surveyId=${surveyId}`,
              );
              setIsSuccessBannerVisible(true);
              setTimeout(() => {
                setIsSuccessBannerVisible(false);
              }, GENERATE_GENERIC_LINK_BANNER_LIFE_SPAN_IN_MS);
            },
            navigateTo,
          );
        }}
      />
    </>
  );
};
