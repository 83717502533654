import { observer } from "mobx-react";
import { OpenEndedFieldTextInputProps } from "./OpenEndedFieldHintInput";
import {
  MAX_FIELD_TITLE_CHARACTER_LIMIT,
  ZERO_VALUE,
  showMaxCharacterCountIndicator,
} from "@pulse/shared-components";
import React from "react";
import { TextInputField } from "@surya-digital/leo-reactjs-material-ui";

export const OpenEndedFieldTitleInput = observer(
  ({
    value,
    onTextChange,
    t,
    error,
    width,
  }: OpenEndedFieldTextInputProps): React.ReactElement => {
    return (
      <TextInputField
        name="fieldTitle"
        width={width}
        minWidth={width}
        value={value}
        placeholder={t("surveys.addSurveyQuestion.enterTitle")}
        type="text"
        label={t("surveys.addSurveyQuestion.openEnded.fieldTitle")}
        error={error}
        onTextChange={(inputValue) => {
          onTextChange(
            inputValue.slice(ZERO_VALUE, MAX_FIELD_TITLE_CHARACTER_LIMIT),
          );
        }}
        maxCharacterLength={MAX_FIELD_TITLE_CHARACTER_LIMIT}
        showCharacterCountIndicator={(): boolean =>
          showMaxCharacterCountIndicator(MAX_FIELD_TITLE_CHARACTER_LIMIT, value)
        }
      />
    );
  },
);
