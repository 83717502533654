import { Instance, types } from "mobx-state-tree";

export const MultiSelectFilterValueModel = types.model(
  "MultiSelectFilterValueModel",
  {
    id: types.maybeNull(types.string),
    label: types.string,
  },
);

export const createMultiSelectFilterValueModel = (
  id: string | null,
  label: string,
): Instance<typeof MultiSelectFilterValueModel> => {
  return MultiSelectFilterValueModel.create({
    id,
    label,
  });
};
