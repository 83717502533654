import { PivotTableRow } from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";

export const PivotTableDataModel = types.model("PivotTableDataModel", {
  respondentColumn: types.maybeNull(types.string),
  total: types.number,
  responseRate: types.number,
  notAssigned: types.number,
  notStarted: types.number,
  viewed: types.number,
  started: types.number,
  inProgress: types.number,
  lastPage: types.number,
  submitted: types.number,
});

export const createPivotTableDataModel = (
  pivotTableRow: PivotTableRow,
): Instance<typeof PivotTableDataModel> => {
  return PivotTableDataModel.create({
    respondentColumn: pivotTableRow.distinctColumnValue?.column,
    total: pivotTableRow.total,
    responseRate: pivotTableRow.responseRate,
    notAssigned: pivotTableRow.notAssignedCount.count,
    notStarted: pivotTableRow.notStartedCount.count,
    viewed: pivotTableRow.viewedCount.count,
    started: pivotTableRow.startedCount.count,
    inProgress: pivotTableRow.inProgressCount.count,
    lastPage: pivotTableRow.lastPageCount.count,
    submitted: pivotTableRow.submittedCount.count,
  });
};
