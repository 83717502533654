import React from "react";

import { Dialog } from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import {
  ChoicePresetFields,
  ChoicePresetFieldsProps,
} from "../ChoicePresetFields";
import { TFunction } from "i18next";

interface ChoicePresetDialogProps {
  isChoicePresetDialogOpen: boolean;
  isPrimaryButtonDisabled: boolean;
  t: TFunction;
  onPrimaryButtonClick: () => Promise<void>;
  onSecondaryButtonClick: () => void;
  choicePresetFieldsProps: ChoicePresetFieldsProps;
}

export const ChoicePresetDialog = observer(
  ({
    isChoicePresetDialogOpen,
    isPrimaryButtonDisabled,
    t,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    choicePresetFieldsProps,
  }: ChoicePresetDialogProps): React.ReactElement => {
    return (
      <Dialog
        open={isChoicePresetDialogOpen}
        title={t("surveys.addSurveyQuestion.singleChoice.choosePresetLiteral")}
        secondaryButtonText={t("common.cancel")}
        primaryButtonText={t(
          "surveys.addSurveyQuestion.singleChoice.choosePresetLiteral",
        )}
        isPrimaryButtonDisabled={isPrimaryButtonDisabled}
        onPrimaryButtonClick={onPrimaryButtonClick}
        isSecondaryButtonDisabled={false}
        onSecondaryButtonClick={onSecondaryButtonClick}
        disableBackdropClick
      >
        <ChoicePresetFields {...choicePresetFieldsProps} />
      </Dialog>
    );
  },
);
