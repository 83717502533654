import { Stack, Typography } from "@mui/material";
import {
  Button,
  Plus,
  cornerRadius,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuestionDetailsStore } from "../store/hooks";
import { observer } from "mobx-react";
import { SelectQuestionTypeDialog } from "./QuestionTypeItem";
import { GroupCustomisationQuestionItem } from "./questionItemComponents/GroupCustomisationQuestionItem";
import { useBorder } from "@pulse/shared-components";
import { useSurveyStore } from "../../store/Hooks";

export interface GroupQuestionCustomisationPaneProps {
  setSelectedQuestionItemId: (value: SetStateAction<string | null>) => void;
  selectedQuestionItemId: string | null;
  setIsMessageBoxVisible: (value: SetStateAction<boolean>) => void;
  setMessageBoxQuestionItemId: (value: SetStateAction<string | null>) => void;
}

export const GroupQuestionCustomisationPane = observer(
  ({
    setSelectedQuestionItemId,
    selectedQuestionItemId,
    setIsMessageBoxVisible,
    setMessageBoxQuestionItemId,
  }: GroupQuestionCustomisationPaneProps): React.ReactElement => {
    const typography = useTypography();
    const { t } = useTranslation();
    const tokens = useFoundationColorTokens();
    const spacing = useSpacing();
    const borderRadius = useCornerRadius();
    const questionDetailsStore = useQuestionDetailsStore();
    const surveyStore = useSurveyStore();
    const [isAddQuestionDialogVisible, setIsAddQuestionDialogVisible] =
      useState(false);
    const border = useBorder();

    const GroupQuestionCustomisationOptions = observer(
      (): React.ReactElement => {
        if (questionDetailsStore.isChildQuestionListEmpty) {
          return (
            <Stack
              padding={spacing.spaceXL}
              border={border.default}
              borderRadius={borderRadius.radiusXS}
              bgcolor={tokens.backgroundElevatedLevel1}
              width={"800px"}
            >
              <Typography
                sx={{ ...typography.b1, color: tokens.labelLowEmphasis }}
              >
                {t("surveys.addSurveyQuestion.group.noQuestionsAdded")}
              </Typography>
            </Stack>
          );
        } else {
          return (
            <Stack
              gap={spacing.spaceXL}
              padding={spacing.spaceXL}
              borderRadius={cornerRadius.radiusXS}
              bgcolor={tokens.backgroundElevatedLevel1}
              width="800px"
              border={border.default}
            >
              <Stack
                borderRadius={cornerRadius.radiusXXS}
                border={border.default}
                bgcolor={tokens.backgroundElevatedLevel1}
              >
                {questionDetailsStore.childQuestionIds.map(
                  (childQuestionId, index) => {
                    const childQuestionDetails =
                      surveyStore.findQuestionById(childQuestionId);
                    return (
                      childQuestionDetails && (
                        <GroupCustomisationQuestionItem
                          key={index}
                          setSelectedQuestionItemId={setSelectedQuestionItemId}
                          setIsMessageBoxVisible={setIsMessageBoxVisible}
                          setMessageBoxQuestionItemId={
                            setMessageBoxQuestionItemId
                          }
                          t={t}
                          questionDetailsStore={questionDetailsStore}
                          childQuestionDetails={childQuestionDetails}
                          typography={typography}
                          spacing={spacing}
                          tokens={tokens}
                          border={border}
                          isBorderBottomRendered={
                            index !==
                            questionDetailsStore.childQuestionIds.length - 1
                          }
                          borderRadius={borderRadius}
                        />
                      )
                    );
                  },
                )}
              </Stack>
            </Stack>
          );
        }
      },
    );

    return (
      <Stack gap={spacing.spaceXL} alignItems="flex-start">
        <Typography sx={{ ...typography.sh1, color: tokens.label }}>
          {t("surveys.questionsCustomisation")}
        </Typography>
        <GroupQuestionCustomisationOptions />
        <Button
          onClick={() => {
            setIsAddQuestionDialogVisible(true);
          }}
          name="addQuestion"
          label={t("surveys.addSurveyQuestion.group.addQuestion")}
          icon={<Plus />}
          iconPosition="leading"
          variant="outlined-neutral"
          size="medium"
          disabled={questionDetailsStore.isAddNewChildQuestionButtonDisabled}
        />
        <SelectQuestionTypeDialog
          setIsAddQuestionDialogVisible={setIsAddQuestionDialogVisible}
          isAddQuestionDialogVisible={isAddQuestionDialogVisible}
          hideGroupQuestionType={true}
          setSelectedQuestionItemId={setSelectedQuestionItemId}
          selectedQuestionItemId={selectedQuestionItemId}
        />
      </Stack>
    );
  },
);
