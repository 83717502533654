import { SurveyStatus } from "@pulse/pulse-rpcs";
import { ChipProps } from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";

export const getSurveyStatusChipProps = (
  surveyStatus: SurveyStatus.SurveyStatus,
  t: TFunction,
): ChipProps => {
  switch (surveyStatus) {
    case SurveyStatus.SurveyStatus.ACTIVE:
      return { label: t("surveyStatus.active"), color: "greenSubtle" };
    case SurveyStatus.SurveyStatus.DRAFT:
      return { label: t("surveyStatus.draft"), color: "neutral" };
    case SurveyStatus.SurveyStatus.CLOSED:
      return { label: t("surveyStatus.closed"), color: "redSubtle" };
  }
};
