import { Stack, Typography } from "@mui/material";
import { FULL_WIDTH, ICON_SIZE, getIconProps } from "@pulse/shared-components";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { CheckCircle2 } from "lucide-react";
import React from "react";

interface DialogSuccessContentProps {
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  description: string | undefined;
}

export const DialogSuccessContent = ({
  description,
  spacing,
  tokens,
  typography,
}: DialogSuccessContentProps): React.ReactElement => {
  return (
    <Stack
      alignItems="center"
      spacing={spacing.spaceXS}
      padding={spacing.spaceXL}
      width="100%"
      textAlign="center"
    >
      <CheckCircle2 {...getIconProps(tokens.iconSuccess, ICON_SIZE.large)} />
      <Typography
        sx={{
          ...typography.b1,
          color: tokens.labelSuccess,
          width: FULL_WIDTH,
        }}
      >
        {description}
      </Typography>
    </Stack>
  );
};
