import { Stack, Typography } from "@mui/material";
import {
  EMPTY_CHARACTER,
  GridColumnQuestionTypeV1Enum,
  MAX_GRID_ROW_AND_COLUMN_HEADER_TEXT_CHARACTER_LIMIT,
  MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
  ZERO_VALUE,
  createGridSingleSelectQuestionV1Model,
  createQuestionOptionsModel,
  showMaxCharacterCountIndicator,
  useBorder,
} from "@pulse/shared-components";
import {
  useTypography,
  useFoundationColorTokens,
  useSpacing,
  useRadioButtonColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { CSSProperties, useState } from "react";
import { observer } from "mobx-react";
import {
  useGridQuestionV1Store,
  useQuestionDetailsStore,
} from "../../store/hooks";
import { useTranslation } from "react-i18next";
import { ChoiceListButtons } from "../ChoiceListButtons";
import { GridColumnQuestionV1Model } from "../../models/GridColumnQuestionV1Model";
import { initializeNewOpenEndedField } from "../openEndedFieldComponents/OpenEndedField";
import { Instance } from "mobx-state-tree";
import { TFunction } from "i18next";
import { GridQuestionTypeOptions } from "./GridQuestionTypeOptions";
import { GridOpenEndedField } from "./GridOpenEndedField";
import { GridSingleChoiceDisplayTypeV1Enum } from "@pulse/shared-components";
import { GridSingleChoiceQuestion } from "./GridSingleChoiceQuestion";
import { initializeSingleChoiceNAOptionModel } from "../../store/QuestionDetailsStore";
import { GridMultiChoiceQuestion } from "./GridMultiChoiceQuestion";
import { createGridSingleChoiceQuestionV1Model } from "../../models/GridSingleChoiceQuestionV1Model";
import { createMultiChoiceQuestionV1Store } from "../../store/MultiChoiceQuestionV1Store";
import { TextInputField } from "@surya-digital/leo-reactjs-material-ui";

export const initializeGridColumn = (
  index: number,
  t: TFunction,
): Instance<typeof GridColumnQuestionV1Model> => {
  return GridColumnQuestionV1Model.create({
    columnCode: `Col${index + 1}`,
    columnHeader: EMPTY_CHARACTER,
    openEndedSingleEntry: initializeNewOpenEndedField(t),
    questionType: GridColumnQuestionTypeV1Enum.OpenEnded,
    singleChoice: createGridSingleChoiceQuestionV1Model(
      createGridSingleSelectQuestionV1Model(
        [createQuestionOptionsModel()],
        GridSingleChoiceDisplayTypeV1Enum.RadioButton,
        initializeSingleChoiceNAOptionModel(t),
        true,
      ),
    ),
    singleChoicePresetScale: 3,
    singleChoicePresetType: undefined,
    multiChoice: createMultiChoiceQuestionV1Store(),
  });
};

export const GridColumnQuestion = observer(
  ({
    index,
    gridElementStackProps,
  }: {
    index: number;
    gridElementStackProps: CSSProperties;
  }): React.ReactElement => {
    const questionDetailsStore = useQuestionDetailsStore();
    const typography = useTypography();
    const { t } = useTranslation();
    const tokens = useFoundationColorTokens();
    const radioButtonTokens = useRadioButtonColorTokens();
    const gridQuestionV1Store = useGridQuestionV1Store();
    const spacing = useSpacing();
    const [isChoicePresetDialogOpen, setIsChoicePresetDialogOpen] =
      useState(false);
    const border = useBorder();

    const gridColumnQuestion = gridQuestionV1Store.gridColumnQuestions[index];

    const ColumnHeaderInputField = observer((): React.ReactElement => {
      return (
        <TextInputField
          name="columnHeader"
          width={undefined}
          minWidth={undefined}
          value={gridColumnQuestion.columnHeader}
          label={EMPTY_CHARACTER}
          placeholder={t(
            "surveys.addSurveyQuestion.grid.enterColumnHeaderPlaceholderText",
          )}
          type="text"
          onTextChange={(inputString: string) => {
            questionDetailsStore.setFieldValidations(null);
            gridColumnQuestion.setColumnHeader(
              inputString.slice(
                ZERO_VALUE,
                MAX_GRID_ROW_AND_COLUMN_HEADER_TEXT_CHARACTER_LIMIT,
              ),
            );
          }}
          maxCharacterLength={
            MAX_GRID_ROW_AND_COLUMN_HEADER_TEXT_CHARACTER_LIMIT
          }
          showCharacterCountIndicator={(): boolean =>
            showMaxCharacterCountIndicator(
              MAX_GRID_ROW_AND_COLUMN_HEADER_TEXT_CHARACTER_LIMIT,
              gridColumnQuestion.columnHeader,
            )
          }
        />
      );
    });

    const ColumnCodeInputField = observer((): React.ReactElement => {
      const columnCodeWidth = "132px";
      return (
        <TextInputField
          name="columnCode"
          width={columnCodeWidth}
          minWidth={columnCodeWidth}
          value={gridColumnQuestion.columnCode}
          label={EMPTY_CHARACTER}
          helperText={
            questionDetailsStore.isOptionCodeNotUnique(
              gridColumnQuestion.columnCode,
            )
              ? t("surveys.addSurveyQuestion.enterUniqueCode")
              : undefined
          }
          error={questionDetailsStore.isOptionCodeNotUnique(
            gridQuestionV1Store.gridColumnQuestions[index].columnCode,
          )}
          placeholder={t("surveys.addSurveyQuestion.enterCode")}
          type="text"
          onTextChange={(inputString: string) => {
            questionDetailsStore.setDuplicateOptionCode(null);
            questionDetailsStore.setFieldValidations(null);
            gridColumnQuestion.setColumnCode(
              inputString.slice(
                ZERO_VALUE,
                MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
              ),
            );
          }}
          maxCharacterLength={MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT}
          showCharacterCountIndicator={(): boolean =>
            showMaxCharacterCountIndicator(
              MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
              gridColumnQuestion.columnCode,
            )
          }
        />
      );
    });

    return (
      <Stack key={index} sx={{ ...gridElementStackProps }}>
        <Typography
          sx={{
            ...typography.ol1,
            color: tokens.labelSubtle,
          }}
        >
          {t("surveys.addSurveyQuestion.grid.columnQuestionTitle", {
            index: index + 1,
          })}
        </Typography>

        <Stack direction="row" gap={spacing.spaceSM}>
          <ColumnHeaderInputField />
          <ColumnCodeInputField />
        </Stack>

        <GridQuestionTypeOptions
          spacing={spacing}
          typography={typography}
          t={t}
          index={index}
          gridQuestionV1Store={gridQuestionV1Store}
          tokens={tokens}
          radioButtonTokens={radioButtonTokens}
          border={border}
        />
        <Stack spacing={spacing.spaceLG}>
          {gridColumnQuestion.isOpenEnded && (
            <GridOpenEndedField
              borderBottom={border.default}
              t={t}
              spacing={spacing}
              questionDetailsStore={questionDetailsStore}
              gridColumnQuestionV1Model={gridColumnQuestion}
              typography={typography}
              tokens={tokens}
            />
          )}
          {gridColumnQuestion.isSingleChoice && (
            <GridSingleChoiceQuestion
              tokens={tokens}
              typography={typography}
              radioButtonTokens={radioButtonTokens}
              t={t}
              gridColumnQuestionV1Model={gridColumnQuestion}
              questionDetailsStore={questionDetailsStore}
              spacing={spacing}
              border={border}
              isChoicePresetDialogOpen={isChoicePresetDialogOpen}
              setIsChoicePresetDialogOpen={setIsChoicePresetDialogOpen}
            />
          )}
          {gridColumnQuestion.isMultiChoice && (
            <GridMultiChoiceQuestion
              typography={typography}
              t={t}
              gridColumnQuestionV1Model={gridColumnQuestion}
              questionDetailsStore={questionDetailsStore}
              spacing={spacing}
              border={border}
              tokens={tokens}
            />
          )}
        </Stack>
        <ChoiceListButtons
          paddingTop={spacing.spaceSM}
          border={border}
          spacing={spacing}
          isChevronDownDisabled={
            gridQuestionV1Store.isChevronDownDisabledForColumns
          }
          isAddNewFieldButtonDisabled={
            gridQuestionV1Store.isMaxGridWidthReached
          }
          isChevronUpDisabled={gridQuestionV1Store.isChevronUpDisabled}
          isDeleteButtonDisabled={
            gridQuestionV1Store.isDeleteButtonDisabledForColumns
          }
          moveFieldDown={gridQuestionV1Store.moveGridColumnDown}
          moveFieldUp={gridQuestionV1Store.moveGridColumnUp}
          removeField={gridQuestionV1Store.removeGridColumn}
          index={index}
          addNewFields={() => {
            gridQuestionV1Store.addNewGridColumn(
              initializeGridColumn(index + 1, t),
              index,
            );
          }}
        />
      </Stack>
    );
  },
);
