import { types, Instance } from "mobx-state-tree";

export const MessageQuestionV1Store = types
  .model("MessageQuestionV1Store", {})
  .views(() => ({
    get serializedJSON(): string {
      return JSON.stringify({});
    },
  }));

export const createMessageQuestionV1Store = (): Instance<
  typeof MessageQuestionV1Store
> => {
  return MessageQuestionV1Store.create({});
};
