import { Stack, Typography } from "@mui/material";
import { ICON_SIZE, getIconProps } from "@pulse/shared-components";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  AutoCompleteInputField,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import { RespondentStore } from "../../store/RespondentStore";
import { AlertCircle } from "lucide-react";

interface DeleteColumnDialogSelectionProps {
  t: TFunction;
  respondentStore: Instance<typeof RespondentStore>;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  typography: LeoTypography;
}

export const DeleteColumnDialogSelection = observer(
  ({
    t,
    respondentStore,
    tokens,
    spacing,
    typography,
  }: DeleteColumnDialogSelectionProps): React.ReactElement => {
    return (
      <Stack width="520px" gap={spacing.spaceLG}>
        <Stack direction="row" gap={spacing.spaceXS}>
          <AlertCircle {...getIconProps(tokens.iconError, ICON_SIZE.default)} />
          <Typography
            sx={{ ...typography.b2, color: tokens.labelError, width: "492px" }}
          >
            {t("projects.deleteRespondentColumn.dialogWarningText")}
          </Typography>
        </Stack>
        <AutoCompleteInputField
          id="SelectDeleteColumn"
          label={t("projects.deleteRespondentColumn.dialogLabelText")}
          required={true}
          placeholder={t(
            "projects.deleteRespondentColumn.dialogPlaceholderText",
          )}
          options={respondentStore.respondentColumns.map((column) => {
            return {
              label: column.name,
              id: column.index.toString(),
            };
          })}
          disabled={respondentStore.isRPCLoading}
          onChange={(column): void => {
            if (column && column.id) {
              respondentStore.setSelectedDeleteColumn(column.label, column.id);
            } else {
              respondentStore.resetSelectedDeleteColumn();
              // This else block also gets triggered when the user unselects an option, as in this case `column` is null and `column.id` is undefined.
              // As per the discussion here: https://surya-digital.slack.com/archives/C06EPPC2Y1E/p1712747738387459, this happens because the Leo component calls this onSelect on every change.
              console.warn("Column and Column Id not found.");
            }
          }}
          value={
            respondentStore.selectedDeleteColumn
              ? {
                  label: respondentStore.selectedDeleteColumn.name,
                  id: respondentStore.selectedDeleteColumn.index.toString(),
                }
              : undefined
          }
        />
      </Stack>
    );
  },
);
