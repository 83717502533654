import React from "react";
import { Stack, styled } from "@mui/material";
import {
  ChevronLeft,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  Chip,
  ChipProps,
  IconButton,
  useTypography,
  EllipsisTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  GenerateGenericSurveyLink,
  GenerateGenericSurveyLinkProps,
} from "./GenerateGenericSurveyLink";

export const RenderActionElements = (
  actionElement: React.ReactElement,
): React.ReactElement => {
  return <Stack maxWidth="50%">{actionElement}</Stack>;
};

export interface HeaderProps {
  title: string;
  generateGenericSurveyLinkProps?: GenerateGenericSurveyLinkProps;
  icon?: React.ReactElement<React.SVGProps<SVGSVGElement>>;
  prefersLargeTitle?: Boolean;
  actionElement?: React.ReactElement;
  chipProps?: ChipProps;
  backButtonCallback?: () => void;
}

/**
 * Creates a Header for the Pulse project.
 * @param title - Title of the header.
 * @param icon - Icon to be displayed on the component.
 * @param prefersLargeTitle - If true Heading4 is used as the typography for title, else SubHeading1 is used.
 * @param actionElement - React element to be rendered on the right side of the component.
 * @param chipProps - Props of a chip if a chip needs to be displayed beside the title.
 * @param backButtonCallback - This fuction is called when the back button is clicked on the component.
 * @returns - Header component
 * @example
 *      <Header
          title="Tata Consultants New 2023"
          icon={<Briefcase />}
          prefersLargeTitle={true}
          actionElement={
            <Button
              name="primaryButton"
              variant="filled"
              size="medium"
              title="Button Label"
              onClick={(): void => {}}
              iconPosition="left"
            />
          }
          chipProps={{ label: "Project Date Range", color: "neutral" }}
        />
 */

export function Header({
  title,
  icon,
  prefersLargeTitle,
  actionElement,
  chipProps,
  backButtonCallback,
  generateGenericSurveyLinkProps,
}: HeaderProps): React.ReactElement {
  const typography = useTypography();
  const tokens = useFoundationColorTokens();
  const spacing = useSpacing();

  const StyledStack = styled(Stack)(() => ({
    justifyContent: "space-between",
    width: "100%",
    minHeight: "48px",
    backgroundColor: tokens.background,
  }));

  return (
    <StyledStack
      direction="row"
      spacing={spacing.spaceSM}
      alignItems="flex-start"
    >
      <Stack direction="row" gap={spacing.spaceSM} alignItems="center">
        {icon && (
          <Stack
            height={prefersLargeTitle ? "48px" : "32px"}
            width="32px"
            justifyContent="center"
          >
            {React.cloneElement(icon, {
              color: tokens.iconSubtle,
              width: "32px",
              height: "32px",
            })}
          </Stack>
        )}
        {backButtonCallback && (
          <IconButton
            name="back"
            variant="outlined-neutral"
            size="large"
            icon={<ChevronLeft />}
            onClick={backButtonCallback}
          />
        )}
        <EllipsisTypography
          textColor="labelHighEmphasis"
          maxLines={2}
          typography={prefersLargeTitle ? typography.h4 : typography.sh1}
        >
          {title}
        </EllipsisTypography>
        {generateGenericSurveyLinkProps && (
          <GenerateGenericSurveyLink {...generateGenericSurveyLinkProps} />
        )}
        {chipProps && <Chip {...chipProps} />}
      </Stack>
      {actionElement && RenderActionElements(actionElement)}
    </StyledStack>
  );
}
