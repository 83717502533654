import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  Shadow,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  EMPTY_LIST_LENGTH,
  getEllipsizedTextProperties,
  useBorder,
} from "@pulse/shared-components";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { getStringDefaultIfUndefined } from "@pulse/shared-components";
import { QuestionItemProps } from "./questionItemComponents/QuestionItem";
import { QuestionnaireItemHeader } from "./questionItemComponents/QuestionnaireItemHeader";
import { Instance } from "mobx-state-tree";
import { SurveyQuestionModel } from "../models/SurveyQuestionModel";
import { ManageQuestionsStore } from "../store/ManageQuestionsStore";
import { ParentQuestionsButtons } from "./manageQuestionscomponents/ParentQuestionsButtons";
import { ChildQuestionsButtons } from "./manageQuestionscomponents/ChildQuestionsButtons";

interface QuestionnaireListItemProps extends QuestionItemProps {
  childQuestions: Instance<typeof SurveyQuestionModel | undefined>[];
  manageQuestionsStore: Instance<typeof ManageQuestionsStore> | undefined;
  shadow: Shadow;
  questionDetails: Instance<typeof SurveyQuestionModel> | undefined;
  questionIndex: number;
  questionId: string;
}

/**
 * Creates a List item for the Questionnaire screen
 * @param question - Question text to be displayed. Can be null.
 * @param questionCode - Question code that needs to be displayed.
 * @param questionType - Type of the question.
 * @param order - The order associated with the question.
 * @param isRuleApplied - This denotes whether the `Rule Applied` tag is displayed for the question.
 * @param isVisibleByDefault - This denotes whether the `Visible` or `Hidden` tag is displayed for the question.
 * @param isBorderRendered - This indicates whether a bottom border needs to be displayed for the question.
 * @returns A Questionnaire List item.
 */
export const QuestionnaireListItem = observer(
  ({
    question,
    questionCode,
    questionType,
    order,
    isRuleApplied,
    isVisibleByDefault,
    childQuestions,
    manageQuestionsStore,
    shadow,
    questionIndex,
    questionDetails,
    questionId,
  }: QuestionnaireListItemProps): React.ReactElement => {
    const tokens = useFoundationColorTokens();
    const typography = useTypography();
    const { t } = useTranslation();
    const border = useBorder();
    const spacing = useSpacing();
    const cornerRadius = useCornerRadius();

    const QuestionOrderElement = (): React.ReactElement => {
      return (
        <Stack
          borderRadius={cornerRadius.radiusMax}
          padding={`${spacing.spaceXXS} 0px`}
          height="24px"
          minWidth="32px"
          alignItems="center"
          justifyContent="center"
          border={border.primarySubtle}
          bgcolor={tokens.backgroundPrimarySubtle}
        >
          <Typography
            sx={{
              ...typography.s3,
              color: tokens.labelPrimary,
              ...getEllipsizedTextProperties(),
            }}
          >
            {order}
          </Typography>
        </Stack>
      );
    };

    const QuestionTextElement = ({
      questionText,
    }: {
      questionText: string | null;
    }): React.ReactElement => {
      return (
        <Stack minHeight={questionText ? "24px" : "auto"}>
          <Typography
            sx={{
              ...typography.s1,
              width: "fit-content",
              color: tokens.label,
              wordBreak: "break-word",
            }}
          >
            {getStringDefaultIfUndefined(questionText)}
          </Typography>
        </Stack>
      );
    };

    return (
      <Stack
        direction="column"
        padding={manageQuestionsStore ? undefined : spacing.spaceLG}
        gap={spacing.spaceLG}
        width="100%"
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          gap={spacing.spaceXS}
          width="100%"
        >
          <QuestionOrderElement />
          <Stack
            direction="row"
            gap={spacing.spaceSM}
            width="100%"
            justifyContent="space-between"
          >
            <Stack spacing={spacing.spaceXS}>
              <QuestionnaireItemHeader
                isRuleApplied={isRuleApplied}
                questionCode={questionCode}
                questionType={questionType}
                isVisibleByDefault={isVisibleByDefault}
                tokens={tokens}
                typography={typography}
                t={t}
                spacing={spacing}
              />
              <QuestionTextElement questionText={question} />
            </Stack>
            {manageQuestionsStore && questionDetails && (
              <ParentQuestionsButtons
                spacing={spacing}
                manageQuestionsStore={manageQuestionsStore}
                question={questionDetails}
                index={questionIndex}
                border={border}
                cornerRadius={cornerRadius}
                tokens={tokens}
                shadow={shadow}
                typography={typography}
              />
            )}
          </Stack>
        </Stack>
        {childQuestions.length > EMPTY_LIST_LENGTH && (
          <Stack gap={spacing.spaceSM} width="100%" paddingLeft="56px">
            {childQuestions.map((childQuestion, index) => {
              return (
                childQuestion && (
                  <Stack
                    key={index}
                    direction="row"
                    width="100%"
                    gap={spacing.spaceSM}
                    justifyContent="space-between"
                  >
                    <Stack spacing={spacing.spaceXS}>
                      <QuestionnaireItemHeader
                        isRuleApplied={childQuestion.isRuleApplied}
                        questionCode={
                          childQuestion.surveyQuestionDetails.questionCode
                        }
                        questionType={
                          childQuestion.surveyQuestionDetails.questionType
                        }
                        isVisibleByDefault={childQuestion.isVisibleByDefault}
                        tokens={tokens}
                        typography={typography}
                        t={t}
                        spacing={spacing}
                      />
                      <QuestionTextElement
                        questionText={
                          childQuestion.surveyQuestionDetails.question
                        }
                      />
                    </Stack>
                    {manageQuestionsStore !== undefined && (
                      <ChildQuestionsButtons
                        t={t}
                        spacing={spacing}
                        manageQuestionsStore={manageQuestionsStore}
                        childQuestion={childQuestion}
                        border={border}
                        cornerRadius={cornerRadius}
                        tokens={tokens}
                        shadow={shadow}
                        typography={typography}
                        questionId={questionId}
                      />
                    )}
                  </Stack>
                )
              );
            })}
          </Stack>
        )}
      </Stack>
    );
  },
);
