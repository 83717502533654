import { Stack, Typography } from "@mui/material";
import {
  useTypography,
  useSpacing,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChoiceDisplayRadioComponent } from "./ChoiceDisplayRadioComponent";
import {
  useQuestionDetailsStore,
  useSingleChoiceQuestionV1Store,
} from "../store/hooks";
import { observer } from "mobx-react";
import { CardOrNPSOptions } from "./CardOrNPSOptions";
import { ChoiceOptionsPane } from "./ChoiceOptionsPane";
import { NAOptionPane } from "./NAOptionPane";
import { useBorder } from "@pulse/shared-components";
import { ChoicePresetDialog } from "./commonQuestionConfigurationDetailsComponents/ChoicePresetDialog";
import { QuestionOptionsTitleWithTooltip } from "./QuestionOptionsTitleWithTooltip";

interface SingleSelectFieldCustomisationPaneProps {
  setIsResetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SingleSelectFieldCustomisationPane = observer(
  ({
    setIsResetDialogOpen,
  }: SingleSelectFieldCustomisationPaneProps): React.ReactElement => {
    const typography = useTypography();
    const { t } = useTranslation();
    const spacing = useSpacing();
    const border = useBorder();
    const singleChoiceStore = useSingleChoiceQuestionV1Store();
    const questionDetailsStore = useQuestionDetailsStore();
    const [isChoicePresetDialogOpen, setIsChoicePresetDialogOpen] =
      useState(false);
    const tokens = useFoundationColorTokens();

    const ChoiceDisplayTypePane = (): React.ReactElement => {
      return (
        <Stack
          gap={spacing.spaceXL}
          borderBottom={border.default}
          paddingBottom={spacing.space2XL}
        >
          <Typography sx={{ ...typography.sh1 }}>
            {t("surveys.addSurveyQuestion.singleChoice.choiceDisplayType")}
          </Typography>
          <ChoiceDisplayRadioComponent />
          <CardOrNPSOptions />
        </Stack>
      );
    };

    const ChoiceCustomisationPane = observer((): React.ReactElement => {
      return (
        <Stack gap={spacing.spaceXL}>
          <QuestionOptionsTitleWithTooltip
            title={t("surveys.choiceCustomisation")}
            spacing={spacing}
            tokens={tokens}
          />
          <ChoiceOptionsPane
            setIsResetDialogOpen={setIsResetDialogOpen}
            setIsChoicePresetDialogOpen={setIsChoicePresetDialogOpen}
          />
          {singleChoiceStore.singleSelectQuestionV1Model.isSliderOrScale && (
            <Stack width="600px">
              <NAOptionPane
                isNAOptionChecked={
                  singleChoiceStore.singleSelectQuestionV1Model.NAOption
                    .isNAOptionChecked
                }
                naCheckBoxOnChange={(e): void => {
                  questionDetailsStore.setFieldValidations(null);
                  singleChoiceStore.singleSelectQuestionV1Model.NAOption.setNAOptionCheckbox(
                    e,
                  );
                }}
                naOptionString={
                  singleChoiceStore.singleSelectQuestionV1Model.NAOption
                    .naOptionString
                }
                naOptionCode={
                  singleChoiceStore.singleSelectQuestionV1Model.NAOption
                    .naOptionCode
                }
                doesNAOptionTextContainErrors={
                  singleChoiceStore.doesNAOptionTextContainErrors
                }
                doesNAOptionCodeContainErrors={
                  singleChoiceStore.doesNAOptionCodeContainErrors
                }
                onNAOptionTextChange={(inputString: string) => {
                  questionDetailsStore.setFieldValidations(null);
                  singleChoiceStore.singleSelectQuestionV1Model.NAOption.setNAOptionString(
                    inputString,
                  );
                }}
                onNAOptionCodeChange={(inputString: string) => {
                  questionDetailsStore.setFieldValidations(null);
                  questionDetailsStore.setDuplicateOptionCode(null);
                  singleChoiceStore.singleSelectQuestionV1Model.NAOption.setNAOptionCode(
                    inputString,
                  );
                }}
                isNAOptionDisabled={
                  !singleChoiceStore.singleSelectQuestionV1Model.NAOption
                    .isNAOptionChecked
                }
                spacing={spacing}
                border={border}
                typography={typography}
                t={t}
                questionDetailsStore={questionDetailsStore}
              />
            </Stack>
          )}
        </Stack>
      );
    });

    return (
      <Stack style={{ gap: spacing.spaceXL }}>
        <ChoicePresetDialog
          isChoicePresetDialogOpen={isChoicePresetDialogOpen}
          onSecondaryButtonClick={(): void => {
            questionDetailsStore.resetChoicePresetFields();
            setIsChoicePresetDialogOpen(false);
          }}
          onPrimaryButtonClick={async (): Promise<void> => {
            singleChoiceStore.setOptionsBasedOnPreset(t);
            questionDetailsStore.resetChoicePresetFields();
            singleChoiceStore.setDefaultOptionIndex(undefined);
            setIsChoicePresetDialogOpen(false);
          }}
          isPrimaryButtonDisabled={questionDetailsStore.isPresetUndefined}
          t={t}
          choicePresetFieldsProps={{
            isSingleChoicePresetScaleLow:
              questionDetailsStore.isSingleChoicePresetScaleLow,
            isSingleChoicePresetScaleNps:
              questionDetailsStore.isSingleChoicePresetScaleNps,
            presetScaleValue:
              questionDetailsStore.singleChoicePresetScale.toString(),
            presetTypeValue: questionDetailsStore.singleChoicePresetType,
            onSelectPresetScale: (dropDownItem): void => {
              questionDetailsStore.setSingleChoicePresetScale(
                Number(dropDownItem.value),
              );
              questionDetailsStore.setSingleChoicePresetType(undefined);
            },
            onSelectPresetType: (value): void => {
              questionDetailsStore.setSingleChoicePresetType(value.value);
            },
          }}
        />
        <ChoiceDisplayTypePane />
        <ChoiceCustomisationPane />
      </Stack>
    );
  },
);
