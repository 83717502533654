import { observer } from "mobx-react";
import React from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import { TFunction } from "i18next";
import {
  Spacing,
  FoundationColorTokens,
  Typography as LeoTypography,
  DropdownItem,
} from "@surya-digital/leo-reactjs-material-ui";
import { Stack } from "@mui/material";
import { DropdownInputField } from "@surya-digital/leo-reactjs-material-ui";
import { EMPTY_CHARACTER, FontSizeScale } from "@pulse/shared-components";
import { getCamelCaseFromFontSizeScale } from "../../../projects/utils/StringUtils";

interface FontPreferencesSettingsProps {
  selectedFont: string;
  fontsList: string[];
  setSelectedFont: (selectedFont: string) => void;
  selectedFontSize: FontSizeScale;
  setSelectedFontSize: (slectedFontSize: FontSizeScale) => void;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

export const FontPreferencesSettings = observer(
  ({
    selectedFont,
    fontsList,
    setSelectedFont,
    selectedFontSize,
    setSelectedFontSize,
    t,
    spacing,
    typography,
    tokens,
  }: FontPreferencesSettingsProps): React.ReactElement => {
    return (
      <SettingsContainer
        settingHeadingText={t(
          "surveys.settings.fontPreferencesSettings.fontPreferencesTitleText",
        )}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
      >
        <Stack width="400px" gap={spacing.spaceLG}>
          <DropdownInputField
            name="font"
            label={t(
              "surveys.settings.fontPreferencesSettings.fontDropdownLabelText",
            )}
            placeholder={t(
              "surveys.settings.fontPreferencesSettings.fontDropdownPlaceholderText",
            )}
            value={selectedFont}
            options={fontsList.map((font) => {
              return { value: font, name: font };
            })}
            onSelect={(font: DropdownItem) => {
              setSelectedFont(font.value);
            }}
          />
          <DropdownInputField
            name="fontSize"
            label={t(
              "surveys.settings.fontPreferencesSettings.fontSizeDropdownLabelText",
            )}
            placeholder={t(
              "surveys.settings.fontPreferencesSettings.fontSizeDropdownPlaceholderText",
            )}
            value={selectedFontSize ?? EMPTY_CHARACTER}
            options={Object.keys(FontSizeScale).map((fontSize) => {
              return {
                value: fontSize,
                name: t(
                  `surveys.settings.fontPreferencesSettings.fontSizeDropdwonEnum.${getCamelCaseFromFontSizeScale(
                    FontSizeScale[fontSize as keyof typeof FontSizeScale],
                  )}`,
                ),
              };
            })}
            onSelect={(fontSize: DropdownItem) => {
              setSelectedFontSize(
                FontSizeScale[fontSize.value as keyof typeof FontSizeScale],
              );
            }}
          />
        </Stack>
      </SettingsContainer>
    );
  },
);
