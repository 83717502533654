import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  PaginatedViewOptions,
  ListItems,
  Avatar,
  List,
  ListX,
  ListReloadHandle,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useRef } from "react";
import { SignInStore } from "../../auth/store/SignInStore";
import { AuditLogsStore } from "../store/AuditLogsStore";
import { Stack, Typography } from "@mui/material";
import {
  DEFAULT_ITEMS_PER_PAGE_IN_AUDIT_LOGS,
  DEFAULT_PROJECT_LIST_SORT_INDEX,
  ICON_SIZE,
  IconContainer,
  NAME_AND_EMAIL_SEPARATOR,
  ZERO_VALUE,
  getIconProps,
} from "@pulse/shared-components";
import { UserPrivileges } from "../../store/user/UserPrivileges";
import { FilterComponent } from "../../../components/filter/components/FilterComponent";
import { AuditLogsFilterNames } from "../pages/AuditLogsPage";
import { FilterType } from "../../../components/filter/model/FilterModel";
import { ErrorComponent } from "../../../components/ErrorComponent";

export interface AuditLogsListProps {
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  spacing: Spacing;
  signInStore: Instance<typeof SignInStore>;
  auditLogsStore: Instance<typeof AuditLogsStore>;
}

export const AuditLogsList = observer(
  ({
    t,
    tokens,
    typography,
    spacing,
    signInStore,
    auditLogsStore,
  }: AuditLogsListProps): React.ReactElement => {
    const auditLogsListRef = useRef<ListReloadHandle | null>(null);

    const getAuditLogs = async (
      options: PaginatedViewOptions,
      setTotalItems: React.Dispatch<React.SetStateAction<number>>,
    ): Promise<string | ListItems> => {
      if (signInStore.isPrivilegeGranted(UserPrivileges.viewAuditLogs)) {
        await auditLogsStore.getAuditLogs(
          options.pageNumber
            ? options.pageNumber - 1
            : DEFAULT_PROJECT_LIST_SORT_INDEX,
        );
      }

      setTotalItems(auditLogsStore.totalItems ?? ZERO_VALUE);

      return auditLogsStore.auditLogs.map(
        ({ emailAddress, eventName, auditLogDateTime }, index) => {
          return (
            <Stack
              key={index}
              direction="row"
              padding={`${spacing.spaceXS} ${spacing.spaceXL}`}
              gap={spacing.spaceXS}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" gap={spacing.spaceXS} alignItems="center">
                <Avatar userName={emailAddress} size="small" />
                <Typography {...typography.s3} color={tokens.label}>
                  {emailAddress}
                </Typography>
                <Typography {...typography.b3} color={tokens.labelLowEmphasis}>
                  {NAME_AND_EMAIL_SEPARATOR}
                </Typography>
                <Typography {...typography.b3} color={tokens.labelLowEmphasis}>
                  {eventName}
                </Typography>
              </Stack>
              <Typography {...typography.b3} color={tokens.labelSubtle}>
                {auditLogDateTime}
              </Typography>
            </Stack>
          );
        },
      );
    };

    const filterList: { key: string; label: string }[] = [
      {
        key: AuditLogsFilterNames.EmailAddress,
        label: t("common.auditLogs.emailAddressFilterLabel"),
      },
      {
        key: AuditLogsFilterNames.EventName,
        label: t("common.auditLogs.eventNameFilterLabel"),
      },
      {
        key: AuditLogsFilterNames.DateRange,
        label: t("common.auditLogs.dateRangeFilterLabel"),
      },
    ];

    if (auditLogsStore.doesStoreContainErrors) {
      return (
        <ErrorComponent
          errorMessage={t("common.auditLogs.fetchAuditLogsUnexpectedError")}
          resolveButtonText={t("common.refresh")}
          resolveButtonCallback={async () => {
            await auditLogsStore.getAuditLogs(DEFAULT_PROJECT_LIST_SORT_INDEX);
          }}
        />
      );
    } else {
      return (
        <Stack
          height="100%"
          padding={spacing.spaceXL}
          gap={spacing.spaceLG}
          alignItems="center"
          minWidth="1376px"
          margin="auto"
        >
          <Stack width="100%">
            <FilterComponent
              isDisabled={auditLogsStore.isRPCLoading}
              store={auditLogsStore.filterStore}
              onApplyFiltersClick={async () => {
                auditLogsListRef.current?.reload();
              }}
              onRemoveFiltersClick={() => {
                auditLogsListRef.current?.reload();
              }}
              filters={filterList.map((column) => {
                if (column.key === AuditLogsFilterNames.DateRange) {
                  return {
                    ...column,
                    valueType: FilterType.DateRange,
                  };
                } else {
                  return {
                    ...column,
                    valueType: FilterType.OpenEnded,
                  };
                }
              })}
            />
          </Stack>

          <List
            name="auditLogs"
            ref={auditLogsListRef}
            viewOverrides={{
              empty: {
                icon: IconContainer(
                  <ListX
                    {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
                  />,
                ),
                message: t("common.auditLogs.noAuditLogsFoundTitle"),
              },
              loading: { message: t("common.searchTableLoadingState") },
            }}
            onListOptionsChange={getAuditLogs}
            paginationOption={{
              itemsPerPage: DEFAULT_ITEMS_PER_PAGE_IN_AUDIT_LOGS,
              position: "BOTH",
              getPageIndicatorText(startItem, endItem, totalItems): string {
                return t("common.paginationIndicationText", {
                  startItem,
                  endItem,
                  totalItems,
                });
              },
            }}
            styleOverrides={{
              width: "1376px",
            }}
          />
        </Stack>
      );
    }
  },
);
