import { Instance, types } from "mobx-state-tree";

export const SubmissionDataModel = types.model("SubmissionDataModel", {
  submissionDate: types.Date,
  dailySubmissions: types.number,
  totalSubmissions: types.number,
});

export const createSubmissionDataModel = (
  submissionDate: Date,
  dailySubmissions: number,
  totalSubmissions: number,
): Instance<typeof SubmissionDataModel> => {
  return SubmissionDataModel.create({
    submissionDate,
    dailySubmissions,
    totalSubmissions,
  });
};
