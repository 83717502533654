import { Respondent } from "@pulse/pulse-rpcs";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { SINGLE_SPACING } from "@pulse/shared-components/src/constants";
import { Instance, types } from "mobx-state-tree";

export const RespondentModel = types
  .model("RespondentModel", {
    id: types.number,
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    emailId: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    column0: types.maybeNull(types.string),
    column1: types.maybeNull(types.string),
    column2: types.maybeNull(types.string),
    column3: types.maybeNull(types.string),
    column4: types.maybeNull(types.string),
    column5: types.maybeNull(types.string),
    column6: types.maybeNull(types.string),
    column7: types.maybeNull(types.string),
    column8: types.maybeNull(types.string),
    column9: types.maybeNull(types.string),
    column10: types.maybeNull(types.string),
    column11: types.maybeNull(types.string),
    column12: types.maybeNull(types.string),
    column13: types.maybeNull(types.string),
    column14: types.maybeNull(types.string),
    column15: types.maybeNull(types.string),
    column16: types.maybeNull(types.string),
    column17: types.maybeNull(types.string),
    column18: types.maybeNull(types.string),
    column19: types.maybeNull(types.string),
    column20: types.maybeNull(types.string),
    column21: types.maybeNull(types.string),
    column22: types.maybeNull(types.string),
    column23: types.maybeNull(types.string),
    column24: types.maybeNull(types.string),
    column25: types.maybeNull(types.string),
    column26: types.maybeNull(types.string),
    column27: types.maybeNull(types.string),
    column28: types.maybeNull(types.string),
    column29: types.maybeNull(types.string),
    column30: types.maybeNull(types.string),
    column31: types.maybeNull(types.string),
    column32: types.maybeNull(types.string),
    column33: types.maybeNull(types.string),
    column34: types.maybeNull(types.string),
    column35: types.maybeNull(types.string),
    column36: types.maybeNull(types.string),
    column37: types.maybeNull(types.string),
    column38: types.maybeNull(types.string),
    column39: types.maybeNull(types.string),
    column40: types.maybeNull(types.string),
    column41: types.maybeNull(types.string),
    column42: types.maybeNull(types.string),
    column43: types.maybeNull(types.string),
    column44: types.maybeNull(types.string),
    column45: types.maybeNull(types.string),
    column46: types.maybeNull(types.string),
    column47: types.maybeNull(types.string),
    column48: types.maybeNull(types.string),
    column49: types.maybeNull(types.string),
    column50: types.maybeNull(types.string),
    column51: types.maybeNull(types.string),
    column52: types.maybeNull(types.string),
    column53: types.maybeNull(types.string),
    column54: types.maybeNull(types.string),
    column55: types.maybeNull(types.string),
    column56: types.maybeNull(types.string),
    column57: types.maybeNull(types.string),
    column58: types.maybeNull(types.string),
    column59: types.maybeNull(types.string),
    column60: types.maybeNull(types.string),
    column61: types.maybeNull(types.string),
    column62: types.maybeNull(types.string),
    column63: types.maybeNull(types.string),
    column64: types.maybeNull(types.string),
    column65: types.maybeNull(types.string),
    column66: types.maybeNull(types.string),
    column67: types.maybeNull(types.string),
    column68: types.maybeNull(types.string),
    column69: types.maybeNull(types.string),
    column70: types.maybeNull(types.string),
    column71: types.maybeNull(types.string),
    column72: types.maybeNull(types.string),
    column73: types.maybeNull(types.string),
    column74: types.maybeNull(types.string),
    column75: types.maybeNull(types.string),
    column76: types.maybeNull(types.string),
    column77: types.maybeNull(types.string),
    column78: types.maybeNull(types.string),
    column79: types.maybeNull(types.string),
    column80: types.maybeNull(types.string),
    column81: types.maybeNull(types.string),
    column82: types.maybeNull(types.string),
    column83: types.maybeNull(types.string),
    column84: types.maybeNull(types.string),
    column85: types.maybeNull(types.string),
    column86: types.maybeNull(types.string),
    column87: types.maybeNull(types.string),
    column88: types.maybeNull(types.string),
    column89: types.maybeNull(types.string),
    column90: types.maybeNull(types.string),
    column91: types.maybeNull(types.string),
    column92: types.maybeNull(types.string),
    column93: types.maybeNull(types.string),
    column94: types.maybeNull(types.string),
    column95: types.maybeNull(types.string),
    column96: types.maybeNull(types.string),
    column97: types.maybeNull(types.string),
    column98: types.maybeNull(types.string),
    column99: types.maybeNull(types.string),
  })
  .views((store) => ({
    get fullRespondentName(): string {
      return (
        (store.firstName ?? EMPTY_CHARACTER) +
        SINGLE_SPACING +
        (store.lastName ?? EMPTY_CHARACTER)
      );
    },
  }));

export const createRespondentModel = ({
  id,
  firstName,
  lastName,
  emailId,
  phoneNumber,
  column0,
  column1,
  column2,
  column3,
  column4,
  column5,
  column6,
  column7,
  column8,
  column9,
  column10,
  column11,
  column12,
  column13,
  column14,
  column15,
  column16,
  column17,
  column18,
  column19,
  column20,
  column21,
  column22,
  column23,
  column24,
  column25,
  column26,
  column27,
  column28,
  column29,
  column30,
  column31,
  column32,
  column33,
  column34,
  column35,
  column36,
  column37,
  column38,
  column39,
  column40,
  column41,
  column42,
  column43,
  column44,
  column45,
  column46,
  column47,
  column48,
  column49,
  column50,
  column51,
  column52,
  column53,
  column54,
  column55,
  column56,
  column57,
  column58,
  column59,
  column60,
  column61,
  column62,
  column63,
  column64,
  column65,
  column66,
  column67,
  column68,
  column69,
  column70,
  column71,
  column72,
  column73,
  column74,
  column75,
  column76,
  column77,
  column78,
  column79,
  column80,
  column81,
  column82,
  column83,
  column84,
  column85,
  column86,
  column87,
  column88,
  column89,
  column90,
  column91,
  column92,
  column93,
  column94,
  column95,
  column96,
  column97,
  column98,
  column99,
}: Respondent): Instance<typeof RespondentModel> => {
  return RespondentModel.create({
    id,
    firstName: firstName && firstName.name,
    lastName: lastName && lastName.name,
    emailId,
    phoneNumber,
    column0: column0 && column0.column,
    column1: column1 && column1.column,
    column2: column2 && column2.column,
    column3: column3 && column3.column,
    column4: column4 && column4.column,
    column5: column5 && column5.column,
    column6: column6 && column6.column,
    column7: column7 && column7.column,
    column8: column8 && column8.column,
    column9: column9 && column9.column,
    column10: column10 && column10.column,
    column11: column11 && column11.column,
    column12: column12 && column12.column,
    column13: column13 && column13.column,
    column14: column14 && column14.column,
    column15: column15 && column15.column,
    column16: column16 && column16.column,
    column17: column17 && column17.column,
    column18: column18 && column18.column,
    column19: column19 && column19.column,
    column20: column20 && column20.column,
    column21: column21 && column21.column,
    column22: column22 && column22.column,
    column23: column23 && column23.column,
    column24: column24 && column24.column,
    column25: column25 && column25.column,
    column26: column26 && column26.column,
    column27: column27 && column27.column,
    column28: column28 && column28.column,
    column29: column29 && column29.column,
    column30: column30 && column30.column,
    column31: column31 && column31.column,
    column32: column32 && column32.column,
    column33: column33 && column33.column,
    column34: column34 && column34.column,
    column35: column35 && column35.column,
    column36: column36 && column36.column,
    column37: column37 && column37.column,
    column38: column38 && column38.column,
    column39: column39 && column39.column,
    column40: column40 && column40.column,
    column41: column41 && column41.column,
    column42: column42 && column42.column,
    column43: column43 && column43.column,
    column44: column44 && column44.column,
    column45: column45 && column45.column,
    column46: column46 && column46.column,
    column47: column47 && column47.column,
    column48: column48 && column48.column,
    column49: column49 && column49.column,
    column50: column50 && column50.column,
    column51: column51 && column51.column,
    column52: column52 && column52.column,
    column53: column53 && column53.column,
    column54: column54 && column54.column,
    column55: column55 && column55.column,
    column56: column56 && column56.column,
    column57: column57 && column57.column,
    column58: column58 && column58.column,
    column59: column59 && column59.column,
    column60: column60 && column60.column,
    column61: column61 && column61.column,
    column62: column62 && column62.column,
    column63: column63 && column63.column,
    column64: column64 && column64.column,
    column65: column65 && column65.column,
    column66: column66 && column66.column,
    column67: column67 && column67.column,
    column68: column68 && column68.column,
    column69: column69 && column69.column,
    column70: column70 && column70.column,
    column71: column71 && column71.column,
    column72: column72 && column72.column,
    column73: column73 && column73.column,
    column74: column74 && column74.column,
    column75: column75 && column75.column,
    column76: column76 && column76.column,
    column77: column77 && column77.column,
    column78: column78 && column78.column,
    column79: column79 && column79.column,
    column80: column80 && column80.column,
    column81: column81 && column81.column,
    column82: column82 && column82.column,
    column83: column83 && column83.column,
    column84: column84 && column84.column,
    column85: column85 && column85.column,
    column86: column86 && column86.column,
    column87: column87 && column87.column,
    column88: column88 && column88.column,
    column89: column89 && column89.column,
    column90: column90 && column90.column,
    column91: column91 && column91.column,
    column92: column92 && column92.column,
    column93: column93 && column93.column,
    column94: column94 && column94.column,
    column95: column95 && column95.column,
    column96: column96 && column96.column,
    column97: column97 && column97.column,
    column98: column98 && column98.column,
    column99: column99 && column99.column,
  });
};
