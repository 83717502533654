import { Instance, flow, getRoot, types } from "mobx-state-tree";
import {
  FileAttributes,
  GetPresignedDownloadURLForSurveyRulesRPC,
  GetPresignedDownloadURLForSurveyRulesTemplateRPC,
  GetPresignedUploadURLForSurveyRulesRPC,
  ValidateAndUploadSurveyRulesRPC,
} from "@pulse/pulse-rpcs";
import {
  useGetPresignedDownloadURLForSurveyRulesClientImpl,
  useGetPresignedDownloadURLForSurveyRulesTemplateClientImpl,
  useGetPresignedUploadURLForSurveyRulesClientImpl,
  useValidateAndUploadSurveyRulesClientImpl,
} from "../rpcs/RPC";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  downloadFile,
  insertDocumentRecord,
} from "../../utils/FileDownloadUtils";
import {
  CSV_FILE_TYPE,
  CSV_SURVEY_RULES_BASE_COLUMNS,
  CommonErrors,
  EMPTY_CHARACTER,
  EXISTING_SURVEY_RULES_FILE_NAME,
  MAX_FILE_NAME,
  MAX_ROW_SIZE_FOR_SURVEY_RULES,
  MAX_SURVEY_RULES_FILE_SIZE_IN_BYTES,
  MIN_FILE_NAME,
  MIN_FILE_SIZE_IN_BYTES,
  NetworkingError,
  SHA_256_LENGTH,
  SURVEY_RULES_CSV_TEMPLATE_NAME,
  TEMPLATE_FILE_DELIMITER,
  getDelimitedFileName,
} from "@pulse/shared-components";
import {
  FileUploadError,
  getFileSHA,
  uploadFileToS3,
} from "../../utils/FileUploadUtils";
import { getAPIClient } from "../../networking/APIClient";
import { RootStore } from "../../root/store/RootStore";
import Papa, { ParseResult } from "papaparse";

export enum GetPresignedDownloadURLForSurveyRulesErrors {
  RulesRecordNotFound = "RULES_RECORD_NOT_FOUND",
}

export enum ValidateAndUploadSurveyRulesErrors {
  InvalidFile = "INVALID_FILE",
  UnknownFile = "UNKNOWN_FILE",
}

export const SurveyRulesUploadStore = types
  .model("SurveyRulesUploadStore", {
    fileName: types.maybe(types.string),
    isRPCLoading: types.boolean,
    documentId: types.maybe(types.string),
    hasFileUploaded: types.boolean,
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(Object.values(NetworkingError)),
        types.enumeration(
          Object.values(GetPresignedDownloadURLForSurveyRulesErrors),
        ),
        types.enumeration(Object.values(ValidateAndUploadSurveyRulesErrors)),
      ),
    ),
    validationErrors: types.maybeNull(
      types.enumeration(Object.values(FileUploadError)),
    ),
    invalidFileDetails: types.maybeNull(types.string),
    isFileValidating: types.optional(types.boolean, false),
    hasFileValidated: types.optional(types.boolean, false),
    isCSVDownloading: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get isFileInvalid(): boolean {
      return store.rpcError === ValidateAndUploadSurveyRulesErrors.InvalidFile;
    },
    get isValidateRuleButtonDisabled(): boolean {
      return !store.hasFileUploaded || store.rpcError !== null;
    },
    get isSurveyRulesUploadDialogSecondaryButtonDisabled(): boolean {
      return (
        store.isRPCLoading || store.isFileValidating || store.isCSVDownloading
      );
    },
  }))
  .actions((store) => ({
    setHasFileValidated: (hasFileValidated: boolean): void => {
      store.hasFileValidated = hasFileValidated;
    },
    clearErrorsAndFlags: (): void => {
      store.isRPCLoading = false;
      store.hasFileUploaded = false;
      store.validationErrors = null;
      store.rpcError = null;
      store.invalidFileDetails = null;
      store.isFileValidating = false;
      store.hasFileValidated = false;
    },
    clearRpcErrorsAndValidations: (): void => {
      store.validationErrors = null;
      store.rpcError = null;
    },
    setFileName: (fileName: string): void => {
      store.fileName = fileName;
    },
    getFileAttribute: flow(function* (file: File) {
      if (!CSV_FILE_TYPE.includes(file.type)) {
        store.validationErrors = FileUploadError.InvalidFileFormat;
        return;
      }
      const sha256: string = yield getFileSHA(file);

      const csvText: string = yield new Response(file).text();

      const parsedData: ParseResult<{ [key: string]: string }> = Papa.parse(
        csvText,
        { header: true },
      );

      const csvRows = parsedData.data;
      const csvBaseColumns = parsedData.meta.fields?.map((baseColumn) =>
        baseColumn.trim().toLocaleLowerCase(),
      );
      if (csvBaseColumns === undefined) {
        store.validationErrors = FileUploadError.InternalError;
        return;
      }

      if (csvRows.length - 1 > MAX_ROW_SIZE_FOR_SURVEY_RULES) {
        store.validationErrors = FileUploadError.MaxRowsExceeded;
        return;
      }

      if (csvBaseColumns.length > CSV_SURVEY_RULES_BASE_COLUMNS.length) {
        store.validationErrors = FileUploadError.MaxColumnsExceeded;
        return;
      }

      const doesNotContainsBaseColumns = CSV_SURVEY_RULES_BASE_COLUMNS.some(
        (baseColumn) => {
          return csvBaseColumns.indexOf(baseColumn) === -1;
        },
      );

      if (doesNotContainsBaseColumns) {
        store.validationErrors = FileUploadError.BaseColumnsNotFound;
        return;
      }

      try {
        if (sha256.length !== SHA_256_LENGTH) {
          store.validationErrors = FileUploadError.InvalidFileSHA;
          return;
        } else if (
          file.name.length < MIN_FILE_NAME ||
          file.name.length > MAX_FILE_NAME
        ) {
          store.validationErrors = FileUploadError.InvalidFileName;
          return;
        } else if (file.size > MAX_SURVEY_RULES_FILE_SIZE_IN_BYTES) {
          store.validationErrors = FileUploadError.MaxFileSizeReached;
          return;
        } else if (file.size < MIN_FILE_SIZE_IN_BYTES) {
          store.validationErrors = FileUploadError.MinFileSizeNotReached;
          return;
        }

        const fileAttribute = new FileAttributes(sha256, file.name, file.size);
        return fileAttribute;
      } catch (error) {
        console.error(error);
        store.validationErrors = FileUploadError.InternalError;
      }
    }),
  }))
  .actions((store) => ({
    uploadCSVFile: flow(function* (
      file: File,
      surveyId: string,
      projectId: string,
    ) {
      store.isRPCLoading = true;
      store.clearRpcErrorsAndValidations();
      try {
        const apiClient = getAPIClient(store);

        const fileAttribute: FileAttributes | undefined =
          yield store.getFileAttribute(file);

        if (fileAttribute === undefined) {
          return;
        }

        const {
          response,
          error,
        }: {
          response?: GetPresignedUploadURLForSurveyRulesRPC.Response;
          error?: GetPresignedUploadURLForSurveyRulesRPC.Errors.Errors;
        } = yield useGetPresignedUploadURLForSurveyRulesClientImpl(
          apiClient,
        ).execute(
          new GetPresignedUploadURLForSurveyRulesRPC.Request(
            fileAttribute,
            new LeoUUID(surveyId),
            new LeoUUID(projectId),
          ),
        );

        if (response) {
          try {
            yield uploadFileToS3(response.documentURL, file);
            store.documentId = yield insertDocumentRecord(
              fileAttribute,
              apiClient,
            );
            store.hasFileUploaded = true;
          } catch (e) {
            store.hasFileUploaded = false;
            store.validationErrors = FileUploadError.InternalError;
          }
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    downloadSurveyRulesTemplate: flow(function* () {
      try {
        store.isCSVDownloading = true;
        const apiClient = getAPIClient(store);
        const request =
          new GetPresignedDownloadURLForSurveyRulesTemplateRPC.Request();
        const {
          response,
          error,
        }: {
          response?: GetPresignedDownloadURLForSurveyRulesTemplateRPC.Response;
          error?: GetPresignedDownloadURLForSurveyRulesTemplateRPC.Errors.Errors;
        } =
          yield useGetPresignedDownloadURLForSurveyRulesTemplateClientImpl(
            apiClient,
          ).execute(request);

        if (response) {
          yield downloadFile(
            response.documentURL.href,
            SURVEY_RULES_CSV_TEMPLATE_NAME,
          );
        }
        if (error) {
          store.rpcError = NetworkingError.InternalError;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isCSVDownloading = false;
      }
    }),
    downloadSurveyExistingRules: flow(function* (
      surveyId: string,
      surveyName: string | undefined,
    ) {
      try {
        store.isCSVDownloading = true;
        const apiClient = getAPIClient(store);
        const request = new GetPresignedDownloadURLForSurveyRulesRPC.Request(
          new LeoUUID(surveyId),
        );

        let fileName = EMPTY_CHARACTER;
        if (surveyName) {
          fileName = getDelimitedFileName(surveyName) + TEMPLATE_FILE_DELIMITER;
        }

        const {
          response,
          error,
        }: {
          response?: GetPresignedDownloadURLForSurveyRulesRPC.Response;
          error?: GetPresignedDownloadURLForSurveyRulesRPC.Errors.Errors;
        } =
          yield useGetPresignedDownloadURLForSurveyRulesClientImpl(
            apiClient,
          ).execute(request);

        if (response) {
          yield downloadFile(
            response.documentURL.href,
            fileName + EXISTING_SURVEY_RULES_FILE_NAME,
          );
        }
        if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case GetPresignedDownloadURLForSurveyRulesErrors.RulesRecordNotFound: {
              store.rpcError =
                GetPresignedDownloadURLForSurveyRulesErrors.RulesRecordNotFound;
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isCSVDownloading = false;
      }
    }),
    validateAndUploadSurveyRules: flow(function* (
      surveyId: string,
      projectId: string,
    ) {
      store.isFileValidating = true;
      store.rpcError = null;
      store.invalidFileDetails = null;
      try {
        const apiClient = getAPIClient(store);
        const request = new ValidateAndUploadSurveyRulesRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
          new LeoUUID(store.documentId),
        );

        const {
          response,
          error,
        }: {
          response?: ValidateAndUploadSurveyRulesRPC.Response;
          error?: ValidateAndUploadSurveyRulesRPC.Errors.Errors;
        } =
          yield useValidateAndUploadSurveyRulesClientImpl(apiClient).execute(
            request,
          );

        if (response) {
          store.hasFileValidated = true;
          return;
        }
        if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case GetPresignedDownloadURLForSurveyRulesErrors.RulesRecordNotFound: {
              store.rpcError =
                GetPresignedDownloadURLForSurveyRulesErrors.RulesRecordNotFound;
              break;
            }
            case ValidateAndUploadSurveyRulesErrors.UnknownFile: {
              store.rpcError = ValidateAndUploadSurveyRulesErrors.UnknownFile;
              break;
            }
            case ValidateAndUploadSurveyRulesErrors.InvalidFile: {
              store.rpcError = ValidateAndUploadSurveyRulesErrors.InvalidFile;
              if (
                error instanceof
                ValidateAndUploadSurveyRulesRPC.Errors.InvalidFile
              ) {
                store.invalidFileDetails = error.invalidFileDetails;
              }
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isFileValidating = false;
      }
    }),
  }));

export const createSurveyRulesUploadStore = (): Instance<
  typeof SurveyRulesUploadStore
> => {
  return SurveyRulesUploadStore.create({
    isRPCLoading: false,
    hasFileUploaded: false,
  });
};
