import { SurveyTranslationText } from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";

export const SurveyTranslationTextModel = types.model("TranslationTextModel", {
  translationId: types.string,
  baseLanguageText: types.string,
  translatedLanguageText: types.maybeNull(types.string),
});

export const createSurveyTranslationTextModel = (
  surveyTranslationText: SurveyTranslationText,
): Instance<typeof SurveyTranslationTextModel> => {
  return SurveyTranslationTextModel.create({
    translationId: surveyTranslationText.translationId.uuid,
    baseLanguageText: surveyTranslationText.baseLanguageText,
    translatedLanguageText: surveyTranslationText.translatedLanguageText,
  });
};
