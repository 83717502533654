import { observer } from "mobx-react";
import React from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import { TFunction } from "i18next";
import {
  CornerRadius,
  DropdownItem,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { DropdownInputField } from "@surya-digital/leo-reactjs-material-ui";
import { Box, Stack } from "@mui/material";
import { Instance } from "mobx-state-tree";
import { ThemeWithHexCodesModel } from "../../models/ThemeWithHexCodesModel";
import { SurveyTheme } from "@pulse/pulse-rpcs";
import { getCamelCaseFromSurveyTheme } from "../../../projects/utils/StringUtils";

interface ThemingSettingsProps {
  setSelectedSurveyTheme: (
    selectedSurveyTheme: SurveyTheme.SurveyTheme,
  ) => void;
  selectedSurveyTheme: string | undefined;
  supportedThemes: Instance<typeof ThemeWithHexCodesModel>[];
  t: TFunction;
  spacing: Spacing;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  borderRadius: CornerRadius;
}

export const ThemingSettings = observer(
  ({
    setSelectedSurveyTheme,
    selectedSurveyTheme,
    supportedThemes,
    t,
    spacing,
    typography,
    tokens,
    borderRadius,
  }: ThemingSettingsProps): React.ReactElement => {
    return (
      <SettingsContainer
        settingHeadingText={t(
          "surveys.settings.themingSettings.themingTitleText",
        )}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
      >
        <Stack width="400px">
          <DropdownInputField
            name="surveyTheme"
            label={t(
              "surveys.settings.themingSettings.surveyThemeDropdownLabelText",
            )}
            placeholder={t(
              "surveys.settings.themingSettings.surveyThemeDropdownPlaceholderText",
            )}
            value={selectedSurveyTheme}
            options={supportedThemes.map((surveyTheme) => {
              return {
                value: surveyTheme.theme,
                name: t(
                  `surveys.settings.themingSettings.surveyThemeDropdownEnums.${getCamelCaseFromSurveyTheme(
                    SurveyTheme.SurveyTheme[
                      surveyTheme.theme as keyof typeof SurveyTheme.SurveyTheme
                    ],
                  )}`,
                ),
                image: (
                  <Box
                    borderRadius={borderRadius.radiusXL}
                    sx={{
                      backgroundColor: surveyTheme.primaryHexCode,
                    }}
                    marginRight={spacing.spaceXS}
                    width="24px"
                    height="24px"
                  />
                ),
              };
            })}
            onSelect={(surveyTheme: DropdownItem) => {
              setSelectedSurveyTheme(
                SurveyTheme.SurveyTheme[
                  surveyTheme.value as keyof typeof SurveyTheme.SurveyTheme
                ],
              );
            }}
          />
        </Stack>
      </SettingsContainer>
    );
  },
);
