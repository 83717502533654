import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { Instance, getParent, types } from "mobx-state-tree";
import { doesCodeContainAllowedCharacters } from "@pulse/shared-components";
import { QuestionDetailsStore } from "../store/QuestionDetailsStore";
import { QuestionDetailsModel } from "./QuestionDetailsModel";
import {
  createRTEFileServiceModel,
  RTEFileServiceModel,
} from "../../projects/models/RTEFileServiceModel";

export const BasicQuestionDetailsModel = types
  .model("BasicQuestionDetailsModel", {
    questionCode: types.string,
    question: types.optional(types.string, EMPTY_CHARACTER),
    questionDescription: types.optional(types.string, EMPTY_CHARACTER),
    rteFileServiceModel: RTEFileServiceModel,
    isMandatory: types.boolean,
    isVisibleByDefault: types.boolean,
  })
  .actions((store) => ({
    setQuestionCode: (questionCode: string): void => {
      if (doesCodeContainAllowedCharacters(questionCode)) {
        const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
          getParent(store)
        );
        const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
          getParent(questionDetailsModel)
        );
        questionDetailsStore.setFieldValidations(null);
        questionDetailsStore.setRPCErrors(null);
        store.questionCode = questionCode;
      }
    },
    setQuestionString: (question: string): void => {
      store.question = question;
    },
    setQuestionDescriptionString: (questionDescription: string): void => {
      store.questionDescription = questionDescription;
    },
    setIsMandatory: (isMandatory: boolean): void => {
      store.isMandatory = isMandatory;
    },
    setIsVisibleByDefault: (isVisibleByDefault: boolean): void => {
      store.isVisibleByDefault = isVisibleByDefault;
    },
  }));

export const createBasicQuestionDetailsModel = (): Instance<
  typeof BasicQuestionDetailsModel
> => {
  return BasicQuestionDetailsModel.create({
    questionCode: EMPTY_CHARACTER,
    isMandatory: false,
    isVisibleByDefault: true,
    rteFileServiceModel: createRTEFileServiceModel(),
  });
};
