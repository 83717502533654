import {
  Instance,
  cast,
  getParent,
  getParentOfType,
  types,
} from "mobx-state-tree";
import { QuestionOptionsModel } from "@pulse/shared-components/src/models/QuestionOptionsModel";
import {
  EMPTY_CHARACTER,
  MAXIMUM_SINGLE_SELECT_RATING_SCALE_SLIDER_OPTIONS,
  MAX_SINGLE_CHOICE_OPTIONS,
  MIN_SINGLE_CHOICE_OPTIONS,
  PresetScales,
  createCardDirectionV1Model,
} from "@pulse/shared-components";
import { QuestionDetailsModel } from "../models/QuestionDetailsModel";
import { FieldValidations, QuestionDetailsStore } from "./QuestionDetailsStore";
import { getLocalizedSingleChoiceQuestionOptions } from "../../utils/getLocalizedSingleChoiceQuestionOptions";
import { TFunction } from "i18next";
import { doesCodeContainAllowedCharacters } from "@pulse/shared-components";
import {
  SingleChoiceDisplayTypeV1Enum,
  SingleSelectQuestionV1Model,
  createSingleSelectQuestionV1Model,
  CardDirection,
} from "@pulse/shared-components";
import { SingleChoiceDisplayTypeV1 } from "@pulse/pulse-rpcs";
import { SINGLE_SELECT_NPS_OPTIONS_COUNT } from "@pulse/shared-components";

export enum SingleChoicePresets {
  Agreement = "Agreement",
  Satisfaction = "Satisfaction",
  Willingness = "Willingness",
  Likelihood = "Likelihood",
  Value = "Value",
  GoodPoor = "GoodPoor",
  Nps = "NetPromoterScore",
}

export const SingleChoiceQuestionV1Store = types
  .model("SingleChoiceQuestionV1Store", {
    singleSelectQuestionV1Model: SingleSelectQuestionV1Model,
  })
  .views((store) => ({
    get isResetDisabled(): boolean {
      if (
        store.singleSelectQuestionV1Model.options.length ===
        MIN_SINGLE_CHOICE_OPTIONS
      ) {
        return (
          store.singleSelectQuestionV1Model.options[0].code ===
            EMPTY_CHARACTER &&
          store.singleSelectQuestionV1Model.options[0].option ===
            EMPTY_CHARACTER
        );
      }
      return false;
    },
    get isAddNewFieldButtonDisabled(): boolean {
      return (
        store.singleSelectQuestionV1Model.isNPS ||
        store.singleSelectQuestionV1Model.options.length >=
          MAX_SINGLE_CHOICE_OPTIONS ||
        (store.singleSelectQuestionV1Model.isSliderOrScale &&
          store.singleSelectQuestionV1Model.options.length >=
            MAXIMUM_SINGLE_SELECT_RATING_SCALE_SLIDER_OPTIONS)
      );
    },
    isChevronDownDisabled(index: number): boolean {
      return index === store.singleSelectQuestionV1Model.options.length - 1;
    },
    get isDeleteButtonDisabled(): boolean {
      return (
        store.singleSelectQuestionV1Model.isNPS ||
        store.singleSelectQuestionV1Model.options.length ===
          MIN_SINGLE_CHOICE_OPTIONS
      );
    },
    isChevronUpDisabled(index: number): boolean {
      return index === 0;
    },
    doesOptionCodeContainErrors(index: number): boolean {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );
      return (
        (store.singleSelectQuestionV1Model.options[index].code.trim() ===
          EMPTY_CHARACTER &&
          questionDetailsStore.fieldValidations ===
            FieldValidations.UnfilledOptionField) ||
        store.singleSelectQuestionV1Model.options[index].code.trim() ===
          questionDetailsStore.duplicateOptionCode
      );
    },
    get doesNAOptionTextContainErrors(): boolean {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );
      return (
        store.singleSelectQuestionV1Model.NAOption.naOptionString.trim() ===
          EMPTY_CHARACTER &&
        questionDetailsStore.fieldValidations ===
          FieldValidations.UnfilledOptionField
      );
    },
    get doesNAOptionCodeContainErrors(): boolean {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );
      return (
        (store.singleSelectQuestionV1Model.NAOption.naOptionCode.trim() ===
          EMPTY_CHARACTER &&
          questionDetailsStore.fieldValidations ===
            FieldValidations.UnfilledOptionField) ||
        store.singleSelectQuestionV1Model.NAOption.naOptionCode.trim() ===
          questionDetailsStore.duplicateOptionCode
      );
    },
  }))
  .actions((store) => ({
    resetFieldValidationsAndErrors: (): void => {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );
      questionDetailsStore.setFieldValidations(null);
      questionDetailsStore.setRPCErrors(null);
      questionDetailsStore.setDuplicateOptionCode(null);
    },
    setIsChoiceResetAllowed: (isChoiceResetAllowed: boolean): void => {
      store.singleSelectQuestionV1Model.isChoiceResetAllowed =
        isChoiceResetAllowed;
    },
  }))
  .actions((store) => ({
    resetOptions: (): void => {
      store.singleSelectQuestionV1Model.options = cast([
        {
          code: EMPTY_CHARACTER,
          option: EMPTY_CHARACTER,
        },
      ]);
      store.singleSelectQuestionV1Model.defaultOptionIndex = undefined;
      store.resetFieldValidationsAndErrors();
    },
    setDefaultOptionIndex: (index: number | undefined): void => {
      store.singleSelectQuestionV1Model.defaultOptionIndex = index;
    },
  }))
  .actions((store) => ({
    setOptionsBasedOnPreset: (t: TFunction): void => {
      store.resetFieldValidationsAndErrors();
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );
      if (questionDetailsStore.singleChoicePresetType === undefined) {
        store.resetOptions();
      } else {
        const singleChoicePresetValues =
          getLocalizedSingleChoiceQuestionOptions(
            t,
            questionDetailsStore.singleChoicePresetType,
            questionDetailsStore.singleChoicePresetScale,
          );
        store.singleSelectQuestionV1Model.options = cast(
          singleChoicePresetValues?.map((option, index) => {
            return {
              option,
              code: store.singleSelectQuestionV1Model.isNPS
                ? index.toString()
                : (index + 1).toString(),
            };
          }),
        );
      }
    },
    setCardDirectionAndTextAlignment: (direction: string): void => {
      if (store.singleSelectQuestionV1Model.isCard) {
        store.singleSelectQuestionV1Model.cardDirection?.setCardDirection(
          <CardDirection>direction,
        );
        if (store.singleSelectQuestionV1Model.isHorizontal) {
          store.singleSelectQuestionV1Model.cardDirection?.setTextAlignment(
            SingleChoiceDisplayTypeV1.CardEnums.CardDirection.HorizontalEnums
              .TextAlignment.TextAlignment.LEFT,
          );
        } else {
          store.singleSelectQuestionV1Model.cardDirection?.setTextAlignment(
            null,
          );
        }
      }
    },
    setTextAlignment: (textAlignment: string): void => {
      if (store.singleSelectQuestionV1Model.isCard) {
        store.singleSelectQuestionV1Model.cardDirection?.setTextAlignment(
          <
            SingleChoiceDisplayTypeV1.CardEnums.CardDirection.HorizontalEnums.TextAlignment.TextAlignment
          >textAlignment,
        );
      }
    },
    setNPSSelection: (npsSelection: boolean): void => {
      store.singleSelectQuestionV1Model.isNPSSelectionColored = npsSelection;
    },
    setFieldOptionText: (fieldOptionText: string, index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.singleSelectQuestionV1Model.options[index].option = fieldOptionText;
    },
    setCode: (code: string, index: number): void => {
      if (doesCodeContainAllowedCharacters(code)) {
        store.resetFieldValidationsAndErrors();
        store.singleSelectQuestionV1Model.options[index].code = code;
      }
    },
    addNewField: (
      singleChoiceOptionField: Instance<typeof QuestionOptionsModel>,
      index: number,
    ): void => {
      store.resetFieldValidationsAndErrors();
      store.singleSelectQuestionV1Model.options.splice(
        index + 1,
        0,
        singleChoiceOptionField,
      );
      if (
        store.singleSelectQuestionV1Model.defaultOptionIndex !== undefined &&
        store.singleSelectQuestionV1Model.defaultOptionIndex > index
      ) {
        store.setDefaultOptionIndex(
          store.singleSelectQuestionV1Model.defaultOptionIndex + 1,
        );
      }
    },
    moveFieldUp: (index: number): void => {
      const singleChoiceOptionFieldArrayCopy = [
        ...store.singleSelectQuestionV1Model.options,
      ];
      const replacedElement = singleChoiceOptionFieldArrayCopy[index - 1];
      singleChoiceOptionFieldArrayCopy[index - 1] =
        singleChoiceOptionFieldArrayCopy[index];
      singleChoiceOptionFieldArrayCopy[index] = replacedElement;
      store.singleSelectQuestionV1Model.options.replace(
        singleChoiceOptionFieldArrayCopy,
      );
      if (store.singleSelectQuestionV1Model.defaultOptionIndex === index) {
        store.setDefaultOptionIndex(index - 1);
      } else if (
        store.singleSelectQuestionV1Model.defaultOptionIndex ===
        index - 1
      ) {
        store.setDefaultOptionIndex(index);
      }
    },
    moveFieldDown: (index: number): void => {
      const openEndedFieldArrayCopy = [
        ...store.singleSelectQuestionV1Model.options,
      ];
      const replacedElement = openEndedFieldArrayCopy[index + 1];
      openEndedFieldArrayCopy[index + 1] = openEndedFieldArrayCopy[index];
      openEndedFieldArrayCopy[index] = replacedElement;
      store.singleSelectQuestionV1Model.options.replace(
        openEndedFieldArrayCopy,
      );
      if (store.singleSelectQuestionV1Model.defaultOptionIndex === index) {
        store.setDefaultOptionIndex(index + 1);
      } else if (
        store.singleSelectQuestionV1Model.defaultOptionIndex ===
        index + 1
      ) {
        store.setDefaultOptionIndex(index);
      }
    },
    removeField: (index: number): void => {
      store.resetFieldValidationsAndErrors();
      store.singleSelectQuestionV1Model.options.splice(index, 1);
      if (store.singleSelectQuestionV1Model.defaultOptionIndex === index) {
        store.setDefaultOptionIndex(undefined);
      }
      if (
        store.singleSelectQuestionV1Model.defaultOptionIndex !== undefined &&
        store.singleSelectQuestionV1Model.defaultOptionIndex > index
      ) {
        store.setDefaultOptionIndex(
          store.singleSelectQuestionV1Model.defaultOptionIndex - 1,
        );
      }
    },
    validateFields: (): void => {
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );

      if (
        store.singleSelectQuestionV1Model.isSliderOrScale &&
        store.singleSelectQuestionV1Model.options.length >
          MAXIMUM_SINGLE_SELECT_RATING_SCALE_SLIDER_OPTIONS
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.MaxSliderRatingScaleCountReached,
        );
        return;
      }

      if (
        store.singleSelectQuestionV1Model.isNPS &&
        store.singleSelectQuestionV1Model.options.length !==
          SINGLE_SELECT_NPS_OPTIONS_COUNT
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.InvalidNPSOptionsCount,
        );
        return;
      }

      store.singleSelectQuestionV1Model.options.forEach((option) => {
        if (option.code.trim() === EMPTY_CHARACTER) {
          questionDetailsStore.setFieldValidations(
            FieldValidations.UnfilledOptionField,
          );
        }
      });

      if (store.singleSelectQuestionV1Model.isSliderOrScale) {
        if (store.singleSelectQuestionV1Model.NAOption.isNAOptionChecked) {
          if (
            store.singleSelectQuestionV1Model.NAOption.naOptionCode ===
              EMPTY_CHARACTER ||
            store.singleSelectQuestionV1Model.NAOption.naOptionString ===
              EMPTY_CHARACTER
          ) {
            questionDetailsStore.setFieldValidations(
              FieldValidations.UnfilledOptionField,
            );
          }
        }
      }
    },
    validateInitialOptionCodes(
      initialQuestionDetails: Instance<typeof store>,
    ): void {
      const questionDetailsStore = getParentOfType(store, QuestionDetailsStore);
      const updatedOptionCodes = store.singleSelectQuestionV1Model.options.map(
        (singleSelectOption) => {
          return singleSelectOption.code.trim();
        },
      );

      const initialOptionCodes =
        initialQuestionDetails.singleSelectQuestionV1Model.options.map(
          (singleSelectOption) => {
            return singleSelectOption.code;
          },
        );
      if (
        JSON.stringify(updatedOptionCodes) !==
          JSON.stringify(initialOptionCodes) ||
        store.singleSelectQuestionV1Model.NAOption.naOptionCode.trim() !==
          initialQuestionDetails.singleSelectQuestionV1Model.NAOption
            .naOptionCode
      ) {
        questionDetailsStore.setFieldValidations(
          FieldValidations.QuestionAndOptionCodesCannotBeUpdated,
        );
      }
    },
  }))
  .actions((store) => ({
    setSingleChoiceDisplayType: (
      singleChoiceDisplayType: string,
      t: TFunction,
    ): void => {
      switch (singleChoiceDisplayType) {
        case SingleChoiceDisplayTypeV1Enum.RadioButton:
          store.singleSelectQuestionV1Model.singleChoiceDisplayType =
            SingleChoiceDisplayTypeV1Enum.RadioButton;
          break;
        case SingleChoiceDisplayTypeV1Enum.Dropdown:
          store.singleSelectQuestionV1Model.singleChoiceDisplayType =
            SingleChoiceDisplayTypeV1Enum.Dropdown;
          break;
        case SingleChoiceDisplayTypeV1Enum.Card:
          store.singleSelectQuestionV1Model.singleChoiceDisplayType =
            SingleChoiceDisplayTypeV1Enum.Card;
          store.singleSelectQuestionV1Model.cardDirection =
            createCardDirectionV1Model(
              CardDirection.Horizontal,
              SingleChoiceDisplayTypeV1.CardEnums.CardDirection.HorizontalEnums
                .TextAlignment.TextAlignment.LEFT,
            );
          break;
        case SingleChoiceDisplayTypeV1Enum.DefaultSlider:
          store.singleSelectQuestionV1Model.singleChoiceDisplayType =
            SingleChoiceDisplayTypeV1Enum.DefaultSlider;
          break;
        case SingleChoiceDisplayTypeV1Enum.RygSlider:
          store.singleSelectQuestionV1Model.singleChoiceDisplayType =
            SingleChoiceDisplayTypeV1Enum.RygSlider;
          break;
        case SingleChoiceDisplayTypeV1Enum.DefaultScale:
          store.singleSelectQuestionV1Model.singleChoiceDisplayType =
            SingleChoiceDisplayTypeV1Enum.DefaultScale;
          break;
        case SingleChoiceDisplayTypeV1Enum.ScaleWithText:
          store.singleSelectQuestionV1Model.singleChoiceDisplayType =
            SingleChoiceDisplayTypeV1Enum.ScaleWithText;
          break;
        case SingleChoiceDisplayTypeV1Enum.NeutralScale:
          store.singleSelectQuestionV1Model.singleChoiceDisplayType =
            SingleChoiceDisplayTypeV1Enum.NeutralScale;
          break;
        case SingleChoiceDisplayTypeV1Enum.DefaultNps:
          store.singleSelectQuestionV1Model.singleChoiceDisplayType =
            SingleChoiceDisplayTypeV1Enum.DefaultNps;
          break;
        case SingleChoiceDisplayTypeV1Enum.NpsPreFilledColor:
          store.singleSelectQuestionV1Model.singleChoiceDisplayType =
            SingleChoiceDisplayTypeV1Enum.NpsPreFilledColor;
          break;
        case SingleChoiceDisplayTypeV1Enum.NpsBorderColor:
          store.singleSelectQuestionV1Model.singleChoiceDisplayType =
            SingleChoiceDisplayTypeV1Enum.NpsBorderColor;
          break;
      }
      const questionDetailsModel = <Instance<typeof QuestionDetailsModel>>(
        getParent(store)
      );
      const questionDetailsStore = <Instance<typeof QuestionDetailsStore>>(
        getParent(questionDetailsModel)
      );
      if (store.singleSelectQuestionV1Model.isNPS) {
        questionDetailsStore.setSingleChoicePresetScale(
          PresetScales.NpsPresetScale,
        );
        questionDetailsStore.setSingleChoicePresetType(SingleChoicePresets.Nps);
        store.setOptionsBasedOnPreset(t);
      }
    },
  }));

export const createSingleChoiceQuestionV1Store = (): Instance<
  typeof SingleChoiceQuestionV1Store
> => {
  return SingleChoiceQuestionV1Store.create({
    singleSelectQuestionV1Model: createSingleSelectQuestionV1Model(),
  });
};
