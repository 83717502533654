import { SurveyLanguage } from "@pulse/pulse-rpcs";
import {
  SurveyLanguageModel,
  createSurveyLanguageModel,
} from "@pulse/shared-components";
import { Instance, types } from "mobx-state-tree";

export const SurveyLanguageListWithTabIndexModel = types.model(
  "SurveyLanguageListWithTabIndexModel",
  {
    tabIndex: types.number,
    surveyLanguage: SurveyLanguageModel,
  },
);

export const createSurveyLanguageListWithTabIndexModel = (
  index: number,
  surveyLanguage: SurveyLanguage,
): Instance<typeof SurveyLanguageListWithTabIndexModel> => {
  return SurveyLanguageListWithTabIndexModel.create({
    tabIndex: index,
    surveyLanguage: createSurveyLanguageModel(surveyLanguage),
  });
};
