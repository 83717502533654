import React, { useCallback, useEffect } from "react";
import { DetailSectionComponent } from "../../../components/DetailSectionComponent";
import { useUserAccessStore } from "../../store/Hooks";
import { BriefcaseIcon, HomeIcon, UserSquare } from "lucide-react";
import {
  EMPTY_CHARACTER,
  ICON_SIZE,
  getIconProps,
  useBorder,
} from "@pulse/shared-components";
import {
  BreadcrumbProps,
  TabProps,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import {
  processProjectParams,
  processSPoCParams,
  useRouteNavigator,
} from "../../../routes/RoutesHelper";
import { Button } from "@surya-digital/leo-reactjs-material-ui";
import { navigateOrGetNetworkErrorPage } from "../../../utility/navigateOrGetNetworkErrorPage";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { observer } from "mobx-react";
import { SPoCRespondentTabs } from "../store/SPoCRespondentStore";
import { SectionHeaderProps } from "../../../components/SectionHeader";
import { HeaderProps } from "../../../components/Header";
import { SPoCRespondentTabElement } from "../components/userAccess/spocRespondent/SPoCRespondentTabElement";
import { getDefaultProjectDetailsTab } from "../utils/ProjectDetailsTabUtils";

export const SPoCRespondent = observer((): React.ReactElement => {
  const userAccessStore = useUserAccessStore();
  const tokens = useFoundationColorTokens();
  const { t } = useTranslation();
  const navigateTo = useRouteNavigator();
  const errorStore = useNetworkingErrorStore();
  const spacing = useSpacing();
  const spocRespondentStore = userAccessStore.spocRespondentStore;
  const getSPoCDetails = useCallback(async () => {
    await processSPoCParams(userAccessStore.getSpocDetails, navigateTo);
    spocRespondentStore.setSelectedTab(SPoCRespondentTabs.Respondents);
  }, []);
  const typography = useTypography();
  const border = useBorder();

  useEffect(() => {
    getSPoCDetails();
    return () => {
      spocRespondentStore.setSelectedTab(SPoCRespondentTabs.Respondents);
    };
  }, []);

  useEffect(() => {
    if (userAccessStore.isURLInvalid) {
      userAccessStore.resetStore();
      navigateTo.pageNotFound();
    }
  }, [userAccessStore.isURLInvalid]);

  const errorPage = navigateOrGetNetworkErrorPage(
    errorStore.error,
    navigateTo,
    errorStore.removeError,
  );

  if (errorPage) {
    errorStore.removeError();
    return errorPage;
  }

  const getSectionHeaderProps = (): SectionHeaderProps | undefined => {
    switch (spocRespondentStore.selectedTab) {
      case SPoCRespondentTabs.Respondents: {
        return {
          title: t("projects.spocRespondent.tabs.respondent"),
          prefersLargeTitle: true,
        };
      }
      case SPoCRespondentTabs.Settings: {
        return {
          title: t("projects.spocRespondent.tabs.settings"),
          prefersLargeTitle: true,
          actionElement: (
            <Button
              label={t("common.saveChanges")}
              name={t("common.saveChanges")}
              size={"medium"}
              variant={"filled"}
              disabled={userAccessStore.isSaveChangeButtonDisabled}
              onClick={async (): Promise<void> => {
                userAccessStore.setIsUpdateSPoCNameErrorVisible(true);
                userAccessStore.setIsConfirmationDialogVisible(true);
                await processSPoCParams(
                  userAccessStore.updateSPoCName,
                  navigateTo,
                );
              }}
            />
          ),
        };
      }
    }
  };

  const getSpocRespondentPageTabs = (): TabProps[] => {
    return [
      { label: t("projects.spocRespondent.tabs.respondent") },
      { label: t("projects.spocRespondent.tabs.settings") },
    ];
  };

  const getBreadcrumbsProps = (): BreadcrumbProps | undefined => {
    return {
      links: [
        {
          label: t("projects.projects"),
          icon: <HomeIcon />,
          onLabelClick: navigateTo.project,
        },
        {
          label: userAccessStore.spocRespondentStore.projectName,
          icon: <BriefcaseIcon />,
          onLabelClick: (): void => {
            processProjectParams(async (projectId): Promise<void> => {
              navigateTo.projectDetails(
                projectId,
                getDefaultProjectDetailsTab(t, navigateTo),
              );
            }, navigateTo);
          },
        },
      ],
      currentLabel:
        userAccessStore.initialSelectedSPoCDetails?.spocFullName ??
        EMPTY_CHARACTER,
      currentLabelIcon: <UserSquare />,
    };
  };

  const getMainHeaderProps = (): HeaderProps => {
    return {
      title:
        userAccessStore.initialSelectedSPoCDetails?.spocFullName ??
        EMPTY_CHARACTER,
      icon: (
        <UserSquare {...getIconProps(tokens.iconSubtle, ICON_SIZE.default)} />
      ),
      prefersLargeTitle: true,
    };
  };

  return (
    <DetailSectionComponent
      isManageUsersLogoVisible={true}
      isAuditLogsLogoVisible={true}
      mainHeaderProps={getMainHeaderProps()}
      isOverflowHidden={false}
      isChildHeightVariable={true}
      breadcrumbProps={getBreadcrumbsProps()}
      tabsProps={{
        tabs: getSpocRespondentPageTabs(),
        selectedTabIndex: spocRespondentStore.selectedTab,
        onTabChange: (index: number): void => {
          spocRespondentStore.setSelectedTab(index);
        },
        disableBottomBorder: true,
      }}
      sectionHeaderProps={getSectionHeaderProps()}
    >
      <SPoCRespondentTabElement
        selectedTab={spocRespondentStore.selectedTab}
        navigateTo={navigateTo}
        userAccessStore={userAccessStore}
        t={t}
        tokens={tokens}
        typography={typography}
        spacing={spacing}
        border={border}
      />
    </DetailSectionComponent>
  );
});
