import { LeoDate } from "@surya-digital/leo-ts-runtime";
import { Instance, types } from "mobx-state-tree";
import { getFormatedLeoDate } from "../../../modules/utils/DateUtils";

export const DateRangeFilterModel = types
  .model("DateRangeFilterModel", {
    startDate: types.maybeNull(types.Date),
    endDate: types.maybeNull(types.Date),
  })
  .views((store) => ({
    get leoStartDate(): LeoDate {
      return getFormatedLeoDate(store.startDate);
    },
    get leoEndDate(): LeoDate {
      return getFormatedLeoDate(store.endDate);
    },
  }));

export const createDateRangeFilterModel = (
  startDate: Date | null,
  endDate: Date | null,
): Instance<typeof DateRangeFilterModel> => {
  return DateRangeFilterModel.create({ startDate, endDate });
};
