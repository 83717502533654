import { Stack, Typography } from "@mui/material";
import {
  Spacing,
  FoundationColorTokens,
  Typography as LeoTypography,
  RadioButtonColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import { GridColumnQuestionV1Model } from "../../models/GridColumnQuestionV1Model";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";
import { NAOptionPane } from "../NAOptionPane";
import { ChoicePresetDialog } from "../commonQuestionConfigurationDetailsComponents/ChoicePresetDialog";
import { GridSingleChoiceOptionsPane } from "./GridSingleChoiceOptionsPane";
import React from "react";
import { BorderStyle } from "@pulse/shared-components";
import { NPSOptions } from "../singleSelectOptionComponents/NPSOptions";
import { GridSingleChoiceDisplayRadioComponent } from "./GridSingleChoiceDisplayRadioComponent";

export interface GridSingleChoiceQuestionProps {
  spacing: Spacing;
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
  gridColumnQuestionV1Model: Instance<typeof GridColumnQuestionV1Model>;
  t: TFunction;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  radioButtonTokens: RadioButtonColorTokens<string>;
  border: BorderStyle;
  setIsChoicePresetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isChoicePresetDialogOpen: boolean;
}

export const GridSingleChoiceQuestion = observer(
  ({
    tokens,
    t,
    typography,
    questionDetailsStore,
    gridColumnQuestionV1Model,
    radioButtonTokens,
    border,
    setIsChoicePresetDialogOpen,
    isChoicePresetDialogOpen,
    spacing,
  }: GridSingleChoiceQuestionProps): React.ReactElement => {
    const ChoiceDisplayTypePane = observer((): React.ReactElement => {
      return (
        <Stack gap={spacing.spaceLG} width="580px">
          <Stack
            padding={`${spacing.spaceSM} 0px`}
            borderBottom={border.default}
          >
            <Typography
              sx={{
                ...typography.sh3,
                color: tokens.label,
              }}
            >
              {t("surveys.addSurveyQuestion.singleChoice.choiceDisplayType")}
            </Typography>
          </Stack>
          <GridSingleChoiceDisplayRadioComponent
            spacing={spacing}
            typography={typography}
            t={t}
            gridColumnQuestionV1Model={gridColumnQuestionV1Model}
            radioButtonTokens={radioButtonTokens}
            tokens={tokens}
            isNPSSelected={questionDetailsStore.isNPSSelected(
              gridColumnQuestionV1Model,
            )}
          />
        </Stack>
      );
    });

    return (
      <>
        <ChoiceDisplayTypePane />
        {gridColumnQuestionV1Model.singleChoice.gridSingleSelectQuestionV1Model
          .isDefaultNps &&
          questionDetailsStore.isNPSSelected(gridColumnQuestionV1Model) && (
            <Stack width="580px">
              <NPSOptions
                typography={typography}
                spacing={spacing}
                border={border}
                checkBoxOnChange={(e): void => {
                  gridColumnQuestionV1Model.singleChoice.setNPSSelection(e);
                }}
                checkBoxState={
                  gridColumnQuestionV1Model.singleChoice
                    .gridSingleSelectQuestionV1Model.isNPSSelectionColored
                }
                t={t}
              />
            </Stack>
          )}
        <GridSingleChoiceOptionsPane
          setIsChoicePresetDialogOpen={setIsChoicePresetDialogOpen}
          tokens={tokens}
          typography={typography}
          questionDetailsStore={questionDetailsStore}
          gridColumnQuestionV1Model={gridColumnQuestionV1Model}
          spacing={spacing}
          border={border}
          radioButtonTokens={radioButtonTokens}
          t={t}
        />
        {gridColumnQuestionV1Model.singleChoice.gridSingleSelectQuestionV1Model
          .isSliderOrScale && (
          <Stack width="580px">
            <NAOptionPane
              isNAOptionChecked={
                gridColumnQuestionV1Model.singleChoice
                  .gridSingleSelectQuestionV1Model.NAOption.isNAOptionChecked
              }
              naCheckBoxOnChange={(e): void => {
                questionDetailsStore.setFieldValidations(null);
                gridColumnQuestionV1Model.singleChoice.gridSingleSelectQuestionV1Model.NAOption.setNAOptionCheckbox(
                  e,
                );
              }}
              naOptionString={
                gridColumnQuestionV1Model.singleChoice
                  .gridSingleSelectQuestionV1Model.NAOption.naOptionString
              }
              naOptionCode={
                gridColumnQuestionV1Model.singleChoice
                  .gridSingleSelectQuestionV1Model.NAOption.naOptionCode
              }
              doesNAOptionTextContainErrors={
                gridColumnQuestionV1Model.singleChoice
                  .doesNAOptionTextContainErrors
              }
              doesNAOptionCodeContainErrors={
                gridColumnQuestionV1Model.singleChoice
                  .doesNAOptionCodeContainErrors
              }
              onNAOptionTextChange={(inputString: string) => {
                questionDetailsStore.setFieldValidations(null);
                gridColumnQuestionV1Model.singleChoice.gridSingleSelectQuestionV1Model.NAOption.setNAOptionString(
                  inputString,
                );
              }}
              onNAOptionCodeChange={(inputString: string) => {
                questionDetailsStore.setFieldValidations(null);
                questionDetailsStore.setDuplicateOptionCode(null);
                gridColumnQuestionV1Model.singleChoice.gridSingleSelectQuestionV1Model.NAOption.setNAOptionCode(
                  inputString,
                );
              }}
              isNAOptionDisabled={
                !gridColumnQuestionV1Model.singleChoice
                  .gridSingleSelectQuestionV1Model.NAOption.isNAOptionChecked
              }
              spacing={spacing}
              border={border}
              typography={typography}
              t={t}
              questionDetailsStore={questionDetailsStore}
            />
          </Stack>
        )}
        <ChoicePresetDialog
          isChoicePresetDialogOpen={isChoicePresetDialogOpen}
          onSecondaryButtonClick={(): void => {
            gridColumnQuestionV1Model.resetChoicePresetFields();
            setIsChoicePresetDialogOpen(false);
          }}
          onPrimaryButtonClick={async (): Promise<void> => {
            gridColumnQuestionV1Model.singleChoice.setOptionsBasedOnPreset(t);
            gridColumnQuestionV1Model.resetChoicePresetFields();
            gridColumnQuestionV1Model.singleChoice.setDefaultOptionIndex(
              undefined,
            );
            setIsChoicePresetDialogOpen(false);
          }}
          isPrimaryButtonDisabled={gridColumnQuestionV1Model.isPresetUndefined}
          t={t}
          choicePresetFieldsProps={{
            isSingleChoicePresetScaleLow:
              gridColumnQuestionV1Model.isSingleChoicePresetScaleLow,
            isSingleChoicePresetScaleNps:
              gridColumnQuestionV1Model.isSingleChoicePresetScaleNps,
            presetScaleValue:
              gridColumnQuestionV1Model.singleChoicePresetScale.toString(),
            presetTypeValue: gridColumnQuestionV1Model.singleChoicePresetType,
            onSelectPresetScale: (dropDownItem): void => {
              gridColumnQuestionV1Model.setSingleChoicePresetScale(
                Number(dropDownItem.value),
              );
              gridColumnQuestionV1Model.setSingleChoicePresetType(undefined);
            },
            onSelectPresetType: (value): void => {
              gridColumnQuestionV1Model.setSingleChoicePresetType(value.value);
            },
          }}
        />
      </>
    );
  },
);
