import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { Stack, Typography } from "@mui/material";
import {
  Button,
  Plus,
  useTypography,
  ListX,
  useFoundationColorTokens,
  useSpacing,
  useShadow,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "../../../components/SectionHeader";
import { SelectQuestionTypeDialog } from "./QuestionTypeItem";
import { useQuestionDetailsStore } from "../store/hooks";
import {
  EMPTY_CHARACTER,
  ICON_SIZE,
  IconContainer,
  getIconProps,
  useBorder,
} from "@pulse/shared-components";
import { QuestionType } from "@pulse/pulse-rpcs";
import { ConfirmationDialogComponent } from "@pulse/shared-components";
import { QuestionItem } from "./questionItemComponents/QuestionItem";
import { useSurveyStore } from "../../store/Hooks";
import { ManageQuestionsDialog } from "./manageQuestionscomponents/ManageQuestionsDialog";
import { useRouteNavigator } from "../../../routes/RoutesHelper";
import { observer } from "mobx-react";

export interface QuestionsPaneProps {
  setSelectedQuestionItemId: (value: SetStateAction<string | null>) => void;
  selectedQuestionItemId: string | null;
  setIsMessageBoxVisible: (value: SetStateAction<boolean>) => void;
  setMessageBoxQuestionItemId: (value: SetStateAction<string | null>) => void;
  isMessageBoxVisible: boolean;
  messageBoxQuestionItemId: string | null;
}

export const QuestionsPane = observer(
  ({
    setSelectedQuestionItemId,
    selectedQuestionItemId,
    setIsMessageBoxVisible,
    setMessageBoxQuestionItemId,
    isMessageBoxVisible,
    messageBoxQuestionItemId,
  }: QuestionsPaneProps): React.ReactElement => {
    const { t } = useTranslation();
    const surveyStore = useSurveyStore();
    const questionDetailsStore = useQuestionDetailsStore();
    const tokens = useFoundationColorTokens();
    const typography = useTypography();
    const border = useBorder();
    const spacing = useSpacing();
    const shadow = useShadow();
    const [isAddQuestionDialogVisible, setIsAddQuestionDialogVisible] =
      useState(false);
    const [isAddQuestionMessageBoxVisible, setIsAddQuestionMessageBoxVisible] =
      useState(false);
    const questionsPaneElementRef = useRef<HTMLDivElement>(null);
    const [
      isManageQuestionsMessageDialogOpen,
      setIsManageQuestionsMessageDialogOpen,
    ] = useState(false);
    const [isManageQuestionsDialogOpen, setIsManageQuestionsDialogOpen] =
      useState(false);
    const navigateTo = useRouteNavigator();

    useEffect(() => {
      if (
        surveyStore.newlyAddedQuestion &&
        questionDetailsStore.isParentIdNull
      ) {
        const scrollHeight = questionsPaneElementRef.current?.scrollHeight;
        questionsPaneElementRef.current?.scrollTo({
          top: scrollHeight && scrollHeight,
          behavior: "smooth",
        });
      }
    }, [selectedQuestionItemId, surveyStore.newlyAddedQuestion]);

    const Header = observer((): React.ReactElement => {
      return (
        <Stack position="sticky">
          <SectionHeader
            title={t("surveys.addSurveyQuestion.questions")}
            actionElement={
              <Button
                variant="outlined-neutral"
                name="manageQuestions"
                size="small"
                label={t("surveys.addSurveyQuestion.manage")}
                disabled={surveyStore.isManageQuestionsDisabled}
                onClick={() => {
                  if (!questionDetailsStore.isSaveButtonDisabled) {
                    setIsManageQuestionsMessageDialogOpen(true);
                  } else {
                    setIsManageQuestionsDialogOpen(true);
                  }
                }}
              />
            }
          />
        </Stack>
      );
    });

    const EmptyQuestionList = (): React.ReactElement => {
      return (
        <Stack height={"100%"} alignItems={"center"} justifyContent={"center"}>
          {IconContainer(
            <ListX
              {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
            />,
          )}

          <Typography
            sx={{ ...typography.sh3 }}
            color={tokens.labelLowEmphasis}
          >
            {t("surveys.addSurveyQuestion.noQuestionsCreated")}
          </Typography>
          <Typography
            sx={{
              ...typography.b2,
              maxWidth: "224px",
              textAlign: "center",
              wordBreak: "break-word",
              color: tokens.labelLowEmphasis,
            }}
          >
            {t("surveys.addSurveyQuestion.noQuestionsCreatedDescription")}
          </Typography>
        </Stack>
      );
    };

    const QuestionList = observer((): React.ReactElement => {
      if (surveyStore.isQuestionListEmpty) {
        return <EmptyQuestionList />;
      } else {
        return (
          <Stack overflow="auto" height="100%" ref={questionsPaneElementRef}>
            {surveyStore.questionList.map((listItem, index) => {
              return (
                <QuestionItem
                  key={index}
                  questionItem={{
                    question: listItem.surveyQuestionDetails.question,
                    questionCode: listItem.surveyQuestionDetails.questionCode,
                    order: listItem.surveyQuestionDetails.order,
                    questionType: listItem.surveyQuestionDetails.questionType,
                    questionId: listItem.surveyQuestionDetails.questionId,
                    isRuleApplied: listItem.isRuleApplied,
                    isVisibleByDefault: listItem.isVisibleByDefault,
                  }}
                  setSelectedItemQuestionId={setSelectedQuestionItemId}
                  setIsMessageBoxVisible={setIsMessageBoxVisible}
                  setMessageBoxQuestionItemId={setMessageBoxQuestionItemId}
                  selectedQuestionItemId={selectedQuestionItemId}
                  t={t}
                  surveyStore={surveyStore}
                  questionDetails={questionDetailsStore}
                />
              );
            })}
          </Stack>
        );
      }
    });

    const Footer = (): React.ReactElement => {
      return (
        <Stack
          borderTop={border.default}
          padding={`${spacing.spaceMD} ${spacing.spaceXL}`}
          gap={spacing.spaceSM}
          position="sticky"
          width={"100%"}
        >
          <Button
            icon={<Plus />}
            iconPosition="leading"
            label={t("surveys.addSurveyQuestion.addNewQuestion")}
            variant="filled"
            size="medium"
            name="addNewQuestion"
            onClick={() => {
              if (!questionDetailsStore.isSaveButtonDisabled) {
                setIsAddQuestionMessageBoxVisible(true);
              } else {
                setIsAddQuestionDialogVisible(true);
              }
            }}
            disabled={surveyStore.isSurveyClosed}
            fullWidth={true}
          />
        </Stack>
      );
    };

    return (
      <Stack borderRight={border.default} height={"100%"} width={"400px"}>
        <ConfirmationDialogComponent
          isDialogOpen={isManageQuestionsMessageDialogOpen}
          title={t("surveys.addSurveyQuestion.unsavedChangesTitle")}
          description={t("surveys.manageQuestions.unsavedChangesDescription")}
          primaryButtonText={t("common.proceed")}
          secondaryButtonText={t("common.cancel")}
          secondaryButtonCallback={(): void => {
            setIsManageQuestionsMessageDialogOpen(false);
          }}
          primaryButtonCallBack={async (): Promise<void> => {
            surveyStore.removeUnsavedQuestion();
            setIsManageQuestionsMessageDialogOpen(false);
            setIsManageQuestionsDialogOpen(true);
          }}
        />
        {isManageQuestionsDialogOpen && (
          <ManageQuestionsDialog
            t={t}
            manageQuestionsStore={surveyStore.manageQuestionStore}
            isManageQuestionsDialogOpen={isManageQuestionsDialogOpen}
            setIsManageQuestionsDialogOpen={setIsManageQuestionsDialogOpen}
            spacing={spacing}
            tokens={tokens}
            shadow={shadow}
            typography={typography}
            navigateTo={navigateTo}
            getQuestionsOfSurvey={surveyStore.getQuestionsOfSurvey}
            setSelectedItemQuestionId={setSelectedQuestionItemId}
          />
        )}
        <Header />
        <QuestionList />
        <Footer />
        <SelectQuestionTypeDialog
          isAddQuestionDialogVisible={isAddQuestionDialogVisible}
          setIsAddQuestionDialogVisible={setIsAddQuestionDialogVisible}
          setSelectedQuestionItemId={setSelectedQuestionItemId}
          hideGroupQuestionType={false}
          selectedQuestionItemId={selectedQuestionItemId}
        />
        <ConfirmationDialogComponent
          isDialogOpen={isMessageBoxVisible}
          title={t("surveys.addSurveyQuestion.unsavedChangesTitle")}
          description={t("surveys.addSurveyQuestion.unsavedChangesDescription")}
          primaryButtonText={t("common.proceed")}
          secondaryButtonText={t("common.cancel")}
          secondaryButtonCallback={(): void => {
            setIsMessageBoxVisible(false);
          }}
          primaryButtonCallBack={(): Promise<void> => {
            questionDetailsStore.clearStore(t);
            const question =
              messageBoxQuestionItemId !== null
                ? surveyStore.findQuestionById(messageBoxQuestionItemId)
                : undefined;
            if (question) {
              switch (question.surveyQuestionDetails.questionType) {
                case QuestionType.QuestionType.OPEN_ENDED: {
                  questionDetailsStore.setQuestionType(
                    QuestionType.QuestionType.OPEN_ENDED,
                  );
                  break;
                }
                case QuestionType.QuestionType.SINGLE_CHOICE: {
                  questionDetailsStore.setQuestionType(
                    QuestionType.QuestionType.SINGLE_CHOICE,
                  );
                  break;
                }
                case QuestionType.QuestionType.MULTIPLE_CHOICE: {
                  questionDetailsStore.setQuestionType(
                    QuestionType.QuestionType.MULTIPLE_CHOICE,
                  );
                  break;
                }
                case QuestionType.QuestionType.RANKING: {
                  questionDetailsStore.setQuestionType(
                    QuestionType.QuestionType.RANKING,
                  );
                  break;
                }
                case QuestionType.QuestionType.MESSAGE: {
                  questionDetailsStore.setQuestionType(
                    QuestionType.QuestionType.MESSAGE,
                  );
                  break;
                }
                case QuestionType.QuestionType.GRID: {
                  questionDetailsStore.setQuestionType(
                    QuestionType.QuestionType.GRID,
                  );
                  break;
                }
                case QuestionType.QuestionType.GROUP: {
                  questionDetailsStore.setQuestionType(
                    QuestionType.QuestionType.GROUP,
                  );
                  break;
                }
              }
            }
            setIsMessageBoxVisible(false);
            setSelectedQuestionItemId(messageBoxQuestionItemId);

            if (
              messageBoxQuestionItemId &&
              messageBoxQuestionItemId !== EMPTY_CHARACTER
            ) {
              questionDetailsStore.getQuestionDetails(
                messageBoxQuestionItemId,
                t,
              );
            }
            return Promise.resolve();
          }}
        />
        <ConfirmationDialogComponent
          isDialogOpen={isAddQuestionMessageBoxVisible}
          title={t("surveys.addSurveyQuestion.unsavedChangesTitle")}
          description={t("surveys.addSurveyQuestion.unsavedChangesDescription")}
          primaryButtonText={t("common.proceed")}
          secondaryButtonText={t("common.cancel")}
          secondaryButtonCallback={(): void => {
            setIsAddQuestionMessageBoxVisible(false);
          }}
          primaryButtonCallBack={async (): Promise<void> => {
            setIsAddQuestionMessageBoxVisible(false);
            setIsAddQuestionDialogVisible(true);
          }}
        />
      </Stack>
    );
  },
);
