import { CSV_RESPONDENT_IGNORED_COLUMNS } from "@pulse/shared-components";
import {
  CSV_RESPONDENT_BASE_COLUMNS,
  EMPTY_CHARACTER,
  MAX_COLUMN_SIZE_FOR_RESPONDENTS,
} from "@pulse/shared-components";
import { SINGLE_MEGA_BYTE_IN_BYTES } from "@pulse/shared-components/src/constants";
import { TFunction } from "i18next";
import Papa, { ParseResult } from "papaparse";

export enum FileUploadError {
  MaxFileSizeReached = "MaxFileSizeExceeded",
  MinFileSizeNotReached = "MinFileSizeNotReached",
  BaseColumnsNotFound = "BaseColumnsNotFound",
  InvalidFileFormat = "InvalidFileFormat",
  InvalidFileName = "InvalidFileName",
  InvalidFileSHA = "InvalidFileSHA",
  MaxColumnsExceeded = "MaxColumnsExceeded",
  MaxRowsExceeded = "MaxRowsExceeded",
  InternalError = "InternalError",
}
/**
 * Validates the Respondents CSV file by parsing the CSV string.
 *
 * @param respondentsCsvFile - The Respondent CSV file in string form.
 *
 * @throws
 * MaxColumnsExceeded - This is thrown when the number of columns are exceeding the limit.
 * BaseColumnsNotFound - This is thrown when the columns don't contain the pre-defined base columns for Respondents.
 */
export const validateRespondentsCSVFile = async (
  respondentsCsvFile: File,
): Promise<string[]> => {
  const csvText = await new Response(respondentsCsvFile).text();
  const parsedData: ParseResult<{ [key: string]: string }> = Papa.parse(
    csvText,
    { header: true },
  );
  const csvColumns = parsedData.meta.fields?.filter((headerName) => {
    // This validation is done because, when header option is set to true in papaparse, it doesn't ignore
    // empty headers. Instead, it replaces them with _1, _2 and so on.
    return !/^_*[0-9]$/.test(headerName);
  });
  const csvRows = parsedData.data;

  if (csvColumns === undefined) {
    return Promise.reject(FileUploadError.InternalError);
  }
  if (csvColumns.length > MAX_COLUMN_SIZE_FOR_RESPONDENTS) {
    return Promise.reject(FileUploadError.MaxColumnsExceeded);
  } else {
    const lowerCaseCsvColumns = csvColumns.map((csvColumn) =>
      csvColumn.trim().toLowerCase(),
    );
    const doesNotContainsBaseColumns = CSV_RESPONDENT_BASE_COLUMNS.some(
      (baseColumn: string) => {
        return lowerCaseCsvColumns.indexOf(baseColumn) === -1;
      },
    );
    if (doesNotContainsBaseColumns) {
      return Promise.reject(FileUploadError.BaseColumnsNotFound);
    }
    if (
      csvRows.length - 1 >
      import.meta.env.VITE_MAX_ROW_SIZE_FOR_RESPONDENT_RECORDS
    ) {
      return Promise.reject(FileUploadError.MaxRowsExceeded);
    }
  }
  const filteredRespondentCSVColumns = csvColumns.filter((columnName) => {
    // CSV columns to be ignored: Survey, Survey Status and Survey Link and Id.
    const isRespondentCSVColumnIgnored =
      CSV_RESPONDENT_IGNORED_COLUMNS.includes(columnName.trim().toLowerCase());
    const isCSVColumnEmpty = columnName.trim() === EMPTY_CHARACTER;

    return !isRespondentCSVColumnIgnored && !isCSVColumnEmpty;
  });
  return filteredRespondentCSVColumns;
};

/**
 * Uploads the given file to the S3 bucket using the URL provided.
 *
 * @param url - The URL of the S3 bucket where the file needs to be uploaded.
 * @param file - The file that needs to be uploaded to the S3 bucket.
 *
 * @throws
 * InternalError - This is thrown when the upload is unsuccessful.
 */
export const uploadFileToS3 = async (url: URL, file: File): Promise<void> => {
  const fetchOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: file,
  };
  await fetch(url.href, fetchOptions).catch((_error) => {
    return Promise.reject(FileUploadError.InternalError);
  });
};

/**
 * Returns the Secure Hash Algorithm (SHA) of the given string.
 *
 * @param text - The text that needs to be converted to SHA.
 * @returns The SHA of the string passed.
 */
export const getFileSHA = async (file: File): Promise<string> => {
  const text = await new Response(file).text();
  const textAsBuffer = new TextEncoder().encode(text);
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(EMPTY_CHARACTER);
};

/**
 * Returns the description for the upload error provided for the file upload validation.
 *
 * @param fileUploadError - The upload error of the type FileUploadError.
 * @param t - The translation function of i18next.
 * @returns The error description for the upload error to be displayed in the file upload dialog.
 */
export const getUploadErrorDescriptions = (
  fileUploadError: FileUploadError,
  t: TFunction,
  maxFileSizeInBytes: number,
  minFileSizeInBytes: number,
): string => {
  switch (fileUploadError) {
    case FileUploadError.MaxColumnsExceeded: {
      return t("common.uploadDialog.columnLimitExceededDescription");
    }
    case FileUploadError.InvalidFileFormat: {
      return t("common.uploadDialog.invalidFileFormatDescription");
    }
    case FileUploadError.InvalidFileName: {
      return t("common.uploadDialog.invalidFileNameDescription");
    }
    case FileUploadError.MaxFileSizeReached: {
      return t("common.uploadDialog.maxFileSizeReachedDescription", {
        maxFileSize: maxFileSizeInBytes / SINGLE_MEGA_BYTE_IN_BYTES,
      });
    }
    case FileUploadError.MinFileSizeNotReached: {
      return t("common.uploadDialog.minFileSizeNotReachedDescription", {
        minFileSize: minFileSizeInBytes,
      });
    }
    case FileUploadError.MaxRowsExceeded: {
      return t("common.uploadDialog.maxRowsReachedDescription");
    }
    case FileUploadError.BaseColumnsNotFound: {
      return t("common.uploadDialog.missingBaseColumnsDescription");
    }
    default: {
      return t("common.uploadDialog.unexpectedErrorDescription");
    }
  }
};
