import { observer } from "mobx-react";
import { OpenEndedFieldTextInputProps } from "./OpenEndedFieldHintInput";
import {
  MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
  ZERO_VALUE,
  showMaxCharacterCountIndicator,
} from "@pulse/shared-components";
import React from "react";
import { TextInputField } from "@surya-digital/leo-reactjs-material-ui";

interface OpenEndedFieldCodeInputProps extends OpenEndedFieldTextInputProps {
  helperText: string | undefined;
}
export const OpenEndedFieldCodeInput = observer(
  ({
    value,
    t,
    error,
    onTextChange,
    helperText,
    width,
  }: OpenEndedFieldCodeInputProps): React.ReactElement => {
    return (
      <TextInputField
        name="code"
        width={width}
        minWidth={width}
        value={value}
        placeholder={t("surveys.addSurveyQuestion.enterCode")}
        type="text"
        label={t("surveys.addSurveyQuestion.openEnded.code")}
        required={true}
        error={error}
        helperText={helperText}
        onTextChange={(inputValue) => {
          onTextChange(
            inputValue.slice(
              ZERO_VALUE,
              MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
            ),
          );
        }}
        maxCharacterLength={MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT}
        showCharacterCountIndicator={(): boolean =>
          showMaxCharacterCountIndicator(
            MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT,
            value,
          )
        }
      />
    );
  },
);
