import React from "react";
import {
  Spacing,
  FoundationColorTokens,
  Typography,
  Dialog,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { PivotTableStore } from "../../store/PivotTableStore";
import { ErrorSuccessDialogContent } from "../ErrorSuccessDialogContent";

interface PivotErrorDialogProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  t: TFunction;
  pivotTableStore: Instance<typeof PivotTableStore>;
  navigateTo: NavigateToFunctions;
}

export const PivotErrorDialog = observer(
  ({
    spacing,
    tokens,
    typography,
    t,
    pivotTableStore,
    navigateTo,
  }: PivotErrorDialogProps): React.ReactElement => {
    return (
      <Dialog
        open={pivotTableStore.isErrorDialogVisible}
        title={t("projects.dashboard.pivotTable.pivotTableTitle")}
        secondaryButtonText={t("common.close")}
        onSecondaryButtonClick={async () => {
          pivotTableStore.clearStore();
          await processProjectParams(
            pivotTableStore.getRespondentColumnNames,
            navigateTo,
          );
        }}
      >
        <ErrorSuccessDialogContent
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          isError={true}
          errorSuccessText={t(
            "projects.dashboard.pivotTable.somethingWentWrongErrorDescription",
          )}
        />
      </Dialog>
    );
  },
);
