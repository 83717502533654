import { SpocUserDetails } from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import { UserNameModel, createUserNameModel } from "./UserNameModel";
import {
  NAME_AND_EMAIL_SEPARATOR,
  SINGLE_SPACING,
} from "@pulse/shared-components";

export const SpocUserDetailsModel = types
  .model("SpocUserDetailsModel", {
    spocId: types.string,
    userName: UserNameModel,
    emailId: types.string,
  })
  .views((store) => ({
    get searchSpocDropdownText(): string {
      return (
        store.userName.firstAndLastName +
        SINGLE_SPACING +
        NAME_AND_EMAIL_SEPARATOR +
        SINGLE_SPACING +
        store.emailId
      );
    },
  }));

export const createSpocUserDetailsModel = (
  spocUserDetails: SpocUserDetails,
): Instance<typeof SpocUserDetailsModel> => {
  return SpocUserDetailsModel.create({
    spocId: spocUserDetails.spocId.uuid,
    userName: createUserNameModel(
      spocUserDetails.userName.firstName,
      spocUserDetails.userName.lastName,
    ),
    emailId: spocUserDetails.emailId.email,
  });
};
