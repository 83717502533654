import { types, Instance } from "mobx-state-tree";
import { SurveyQuestionDetailsModel } from "./SurveyQuestionDetailsModel";
import { SurveyQuestionDetails } from "@pulse/pulse-rpcs";

export const SurveyQuestionModel = types.model("SurveyQuestionModel", {
  surveyQuestionDetails: SurveyQuestionDetailsModel,
  isRuleApplied: types.boolean,
  isVisibleByDefault: types.boolean,
  isMandatory: types.boolean,
});

export const createSurveyQuestionModel = ({
  questionId,
  questionCode,
  question,
  questionType,
  order,
  isRuleApplied,
  isQuestionVisible,
  isMandatory,
}: SurveyQuestionDetails): Instance<typeof SurveyQuestionModel> => {
  return SurveyQuestionModel.create({
    surveyQuestionDetails: {
      questionId: questionId.uuid,
      questionCode: questionCode.code,
      question: question && question?.text,
      questionType,
      order: order.order,
    },
    isRuleApplied,
    isVisibleByDefault: isQuestionVisible,
    isMandatory,
  });
};
