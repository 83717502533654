import React from "react";
import { ErrorSuccessDialogContent } from "../ErrorSuccessDialogContent";
import { observer } from "mobx-react";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import {
  AssignSpocsToRespondentsErrors,
  RespondentStore,
} from "../../store/RespondentStore";
import { TFunction } from "i18next";
import { MAX_RESPONDENT_SPOC_LIMIT } from "@pulse/shared-components";

export interface AssignRespondentsToSpocDialogErrorProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  respondentStore: Instance<typeof RespondentStore>;
  t: TFunction;
}

export const AssignRespondentsToSpocDialogError = observer(
  ({
    spacing,
    tokens,
    typography,
    respondentStore,
    t,
  }: AssignRespondentsToSpocDialogErrorProps): React.ReactElement => {
    const getErrorText = (): string => {
      switch (respondentStore.assignSpocsToRespondentsErrors) {
        case AssignSpocsToRespondentsErrors.DeletedColumnFilter: {
          return t("common.deletedColumnFilterErrorText");
        }
        case AssignSpocsToRespondentsErrors.ProjectAlreadyArchived: {
          return t(
            "projects.assignSpocsToRespondents.projectAlreadyArchivedText",
          );
        }
        case AssignSpocsToRespondentsErrors.MaximumRespondentSpocLimitReached: {
          return t(
            "projects.assignSpocsToRespondents.maximumRespondentSpocLimitText",
            { numberOfSpocs: MAX_RESPONDENT_SPOC_LIMIT },
          );
        }
        case AssignSpocsToRespondentsErrors.InvalidSpocId:
        case AssignSpocsToRespondentsErrors.InvalidRespondentIdFound:
        default: {
          return t(
            "projects.assignSpocsToRespondents.unexpectedErrorDescription",
          );
        }
      }
    };

    return (
      <ErrorSuccessDialogContent
        spacing={spacing}
        tokens={tokens}
        typography={typography}
        isError={true}
        errorSuccessText={getErrorText()}
      />
    );
  },
);
