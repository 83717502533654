import { Stack, Typography } from "@mui/material";
import {
  IconContainer,
  getEllipsizedTextProperties,
} from "@pulse/shared-components";
import {
  Button,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Clock } from "lucide-react";
import React from "react";

export interface ScheduleJobInProgressStateProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  typography: LeoTypography;
  refreshButtonOnClick: () => Promise<void>;
  descriptionText: string;
}

export const ScheduleJobInProgressState = ({
  spacing,
  tokens,
  t,
  typography,
  refreshButtonOnClick,
  descriptionText,
}: ScheduleJobInProgressStateProps): React.ReactElement => {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      height={"100%"}
      gap={spacing.spaceSM}
    >
      {IconContainer(
        <Clock width="32px" height="32px" color={tokens.iconLowEmphasis} />,
      )}
      <Stack alignItems={"center"} justifyContent={"center"}>
        <Typography sx={{ ...typography.sh3, color: tokens.labelLowEmphasis }}>
          {t("common.inProgressTitle")}
        </Typography>
        <Typography
          sx={{
            ...typography.b2,
            ...getEllipsizedTextProperties(),
            alignItems: "center",
            color: tokens.labelLowEmphasis,
          }}
        >
          {descriptionText}
        </Typography>
      </Stack>
      <Button
        name="refresh"
        variant="outlined-color"
        size="small"
        label={t("common.refresh")}
        onClick={refreshButtonOnClick}
      />
    </Stack>
  );
};
