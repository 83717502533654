import React from "react";
import { Stack, Typography } from "@mui/material";
import { getEllipsizedTextProperties } from "@pulse/shared-components";
import {
  EllipsisTypography,
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
  IconButton,
} from "@surya-digital/leo-reactjs-material-ui";
import { X } from "lucide-react";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import { AddProjectBOUStore } from "../../store/AddProjectBOUStore";

export interface SelectedBOUListProps {
  boUserName: string;
  boUserEmailId: string;
  boUserId: string;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  addProjectBOUStore: Instance<typeof AddProjectBOUStore>;
}

export const SelectedBOUList = observer(
  ({
    boUserName,
    boUserEmailId,
    boUserId,
    spacing,
    typography,
    tokens,
    addProjectBOUStore,
  }: SelectedBOUListProps): React.ReactElement => {
    return (
      <Stack direction="row" gap={spacing.spaceSM} width="100%">
        <Stack width="100%">
          <EllipsisTypography typography={typography.b1} textColor={"label"}>
            {boUserName}
          </EllipsisTypography>
          {/* Ideally, this should also be using Ellipsis typography component but doing so cuts off the
          leftmost letter for certain characters like "J" */}
          <Typography
            {...typography.b3}
            color={tokens.labelSubtle}
            // A padding of 2px is given here because so that the overflowed text is not hidden.
            paddingLeft={"2px"}
            sx={{ ...getEllipsizedTextProperties() }}
          >
            {boUserEmailId}
          </Typography>
        </Stack>

        <IconButton
          name="removeBOU"
          variant="plain-neutral"
          size="medium"
          icon={<X />}
          onClick={() => {
            addProjectBOUStore.removeSelectedBOUserId(boUserId);
          }}
          disabled={addProjectBOUStore.isRPCLoading}
        />
      </Stack>
    );
  },
);
