import { Stack, Typography } from "@mui/material";
import {
  Dialog,
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
  ChipProps,
  TableReloadHandle,
  LoadingIndicator,
} from "@surya-digital/leo-reactjs-material-ui";
import { Check, Info } from "lucide-react";
import { TFunction } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { Instance } from "mobx-state-tree";
import {
  EditRespondentDetailsError,
  RespondentStore,
} from "../../store/RespondentStore";
import { EditChildComponent } from "./EditChildComponent";
import { observer } from "mobx-react";
import {
  ICON_SIZE,
  MAX_RESPONDENT_SPOC_LIMIT,
  ZERO_VALUE,
  getIconProps,
} from "@pulse/shared-components";

export enum EditRespondentDialogState {
  Edit = "EDIT",
  Success = "SUCCESS",
  Error = "ERROR",
  Loading = "LOADING",
}
interface EditRespondentDetailsDialogProps {
  isEditRespondentDetailsDialogOpen: boolean;
  setIsEditRespondentDetailsDialogOpen: (isDialogVisible: boolean) => void;
  spacing: Spacing;
  t: TFunction;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  respondentStore: Instance<typeof RespondentStore>;
  surveyLinkStatusChipProps: ChipProps | undefined;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
  dialogState: EditRespondentDialogState;
}

export const EditRespondentDetailsDialog = observer(
  ({
    isEditRespondentDetailsDialogOpen,
    setIsEditRespondentDetailsDialogOpen,
    spacing,
    t,
    typography,
    tokens,
    respondentStore,
    surveyLinkStatusChipProps,
    tableRef,
    dialogState,
  }: EditRespondentDetailsDialogProps): React.ReactElement => {
    const [areFieldsDisabled, setAreFieldsDisabled] = useState(false);
    const childElementRef = useRef<HTMLDivElement>(null);
    const [
      isEditRespondentErrorDialogShown,
      setIsEditRespondentErrorDialogShown,
    ] = useState(false);
    const [
      isEditRespondentSuccessDialogShown,
      setIsEditRespondentSuccessDialogShown,
    ] = useState(false);

    useEffect(() => {
      if (respondentStore.isEditRespondentErrorBannerShown) {
        childElementRef.current?.scrollTo({
          top: ZERO_VALUE,
          behavior: "smooth",
        });
      }
    }, [respondentStore.isEditRespondentErrorBannerShown]);

    // This component should not be inside an observer as the observer will rerender the component and the focus will be lost from the input field.
    const ErrorChildComponent = (): React.ReactElement => {
      const getErrorText = (): string => {
        switch (respondentStore.editRespondentRPCError) {
          case EditRespondentDetailsError.NoSurveyQuestionsPresent:
            return t(
              "projects.respondentList.editRespondentDetailsDialog.errorMessages.noSurveyQuestionsPresentErrorText",
            );
          case EditRespondentDetailsError.ProjectArchived:
            return t(
              "projects.respondentList.editRespondentDetailsDialog.errorMessages.projectArchivedDescription",
            );
          case EditRespondentDetailsError.SurveyIsClosed:
            return t(
              "projects.respondentList.editRespondentDetailsDialog.errorMessages.surveyIsClosed",
            );
          case EditRespondentDetailsError.MaximumRespondentSPoCLimitReached:
            return t(
              "projects.respondentList.editRespondentDetailsDialog.errorMessages.maximumSPoCLimitReached",
              { maxRespondentSPoC: MAX_RESPONDENT_SPOC_LIMIT },
            );
          default:
            return t(
              "projects.respondentList.editRespondentDetailsDialog.errorMessages.genericErrorDialogText",
            );
        }
      };

      return (
        <Stack padding={spacing.spaceLG} width="100%">
          <Stack
            padding={spacing.spaceXL}
            gap={spacing.spaceXS}
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Info {...getIconProps(tokens.iconError, ICON_SIZE.large)} />
            <Typography {...typography.b1} color={tokens.labelError}>
              {getErrorText()}
            </Typography>
          </Stack>
        </Stack>
      );
    };

    const SuccessChildComponent = (): React.ReactElement => {
      return (
        <Stack padding={spacing.spaceLG} width="100%">
          <Stack
            padding={spacing.spaceXL}
            gap={spacing.spaceXS}
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Check {...getIconProps(tokens.iconSuccess, ICON_SIZE.large)} />
            <Typography {...typography.b1} color={tokens.labelSuccess}>
              {t(
                "projects.respondentList.editRespondentDetailsDialog.successDialogText",
              )}
            </Typography>
          </Stack>
        </Stack>
      );
    };

    // This component should not be inside an observer as the observer will rerender the component and the focus will be lost from the input field.
    const getDialogChild = (): React.ReactElement => {
      switch (dialogState) {
        case EditRespondentDialogState.Loading: {
          return (
            <Stack padding={spacing.spaceLG} width="100%">
              <LoadingIndicator
                isLoading={true}
                variant="container"
                loadingText={t("common.detailsLoadingState")}
              />
            </Stack>
          );
        }
        case EditRespondentDialogState.Edit: {
          return (
            <Stack height="480px" width="100%">
              <EditChildComponent
                t={t}
                spacing={spacing}
                typography={typography}
                tokens={tokens}
                respondentStore={respondentStore}
                surveyLinkStatusChipProps={surveyLinkStatusChipProps}
                areFieldsDisabled={areFieldsDisabled}
                childElementRef={childElementRef}
              />
            </Stack>
          );
        }
        case EditRespondentDialogState.Success: {
          return <SuccessChildComponent />;
        }
        case EditRespondentDialogState.Error: {
          return <ErrorChildComponent />;
        }
      }
    };

    const getSecondaryButtonText = (): string | undefined => {
      if (isEditRespondentSuccessDialogShown) {
        return t("common.done");
      } else if (isEditRespondentErrorDialogShown) {
        return t("common.close");
      } else if (isEditRespondentDetailsDialogOpen) {
        return t(
          "projects.respondentList.editRespondentDetailsDialog.secondaryButtonText",
        );
      } else {
        console.error(
          "There cannot be any other state for dialog than error, success or edit.",
        );
        return undefined;
      }
    };

    const getPrimaryButtonText = (): string | undefined => {
      if (
        isEditRespondentSuccessDialogShown ||
        isEditRespondentErrorDialogShown
      ) {
        return undefined;
      } else {
        return t(
          "projects.respondentList.editRespondentDetailsDialog.primaryButtonText",
        );
      }
    };

    return (
      <Dialog
        open={isEditRespondentDetailsDialogOpen}
        title={t("projects.respondentList.editRespondentDetailsDialog.title")}
        width="100%"
        contentPadding={`${ZERO_VALUE}`}
        primaryButtonText={getPrimaryButtonText()}
        onPrimaryButtonClick={async (): Promise<void> => {
          if (respondentStore.doesAnyFieldInEditRespondentContainError) {
            return;
          } else {
            setAreFieldsDisabled(true);
            await respondentStore.editRespondentDetails();
            respondentStore.multiSelectTableStore.unselectAllRows();
            setAreFieldsDisabled(false);
            if (
              respondentStore.editRespondentRPCError &&
              !respondentStore.isEditRespondentErrorBannerShown
            ) {
              setIsEditRespondentErrorDialogShown(true);
            } else if (respondentStore.isEditRespondentErrorBannerShown) {
              return;
            } else {
              setIsEditRespondentSuccessDialogShown(true);
            }
          }
        }}
        isPrimaryButtonDisabled={
          respondentStore.isEditRespondentPrimaryButtonDisabled
        }
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsEditRespondentDetailsDialogOpen(false);
          respondentStore.resetEditRespondentDialog();
          if (dialogState === EditRespondentDialogState.Success) {
            tableRef.current?.reload();
          }
          respondentStore.resetSelectedRespondentDetails();
        }}
        isSecondaryButtonDisabled={areFieldsDisabled}
      >
        {/*
        getDialogChild() does is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
