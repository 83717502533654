import { observer } from "mobx-react";
import React from "react";
import {
  InformationDialogComponent,
  NetworkingError,
} from "@pulse/shared-components";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import {
  DeleteQuestionErrors,
  EditSurveyQuestionErrors,
  GetQuestionDetailsErrors,
} from "../../store/QuestionDetailsStore";
import { TFunction } from "i18next";

interface DeleteQuestionRPCErrorDialogProps {
  navigateTo: NavigateToFunctions;
  t: TFunction;
  rpcErrors:
    | GetQuestionDetailsErrors
    | DeleteQuestionErrors
    | NetworkingError
    | EditSurveyQuestionErrors
    | null;
  isRPCErrorDialogOpen: boolean;
  setRPCErrors: (rpcErrors: EditSurveyQuestionErrors | null) => void;
}

export const DeleteQuestionRPCErrorDialog = observer(
  ({
    navigateTo,
    t,
    isRPCErrorDialogOpen,
    setRPCErrors,
    rpcErrors,
  }: DeleteQuestionRPCErrorDialogProps): React.ReactElement => {
    const failedToDeleteTitle = t("surveys.deleteQuestion.failedToDeleteTitle");
    const closeButtonText = t("common.close");

    switch (rpcErrors) {
      case DeleteQuestionErrors.InvalidQuestionId: {
        return (
          <InformationDialogComponent
            isDialogOpen={isRPCErrorDialogOpen}
            title={failedToDeleteTitle}
            description={t("surveys.deleteQuestion.unexpectedErrorDescription")}
            buttonText={t("surveys.addSurveyQuestion.reload")}
            resolveButtonCallBack={(): void => {
              navigateTo.reload();
            }}
          />
        );
      }
      case DeleteQuestionErrors.SurveyIsLive: {
        return (
          <InformationDialogComponent
            isDialogOpen={isRPCErrorDialogOpen}
            title={failedToDeleteTitle}
            description={t("surveys.deleteQuestion.surveyIsLiveDescription")}
            buttonText={closeButtonText}
            resolveButtonCallBack={(): void => {
              setRPCErrors(null);
            }}
          />
        );
      }
      case DeleteQuestionErrors.SurveyIsClosed: {
        return (
          <InformationDialogComponent
            isDialogOpen={isRPCErrorDialogOpen}
            title={failedToDeleteTitle}
            description={t("surveys.addSurveyQuestion.surveyClosedDescription")}
            buttonText={closeButtonText}
            resolveButtonCallBack={(): void => {
              setRPCErrors(null);
            }}
          />
        );
      }
      case DeleteQuestionErrors.ProjectAlreadyArchived: {
        return (
          <InformationDialogComponent
            isDialogOpen={isRPCErrorDialogOpen}
            title={failedToDeleteTitle}
            description={t(
              "surveys.addSurveyQuestion.projectArchivedDescription",
            )}
            buttonText={t("common.goToHome")}
            resolveButtonCallBack={(): void => {
              navigateTo.root();
            }}
          />
        );
      }
      default:
        return <></>;
    }
  },
);
