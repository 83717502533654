import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import React from "react";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";
import { GridColumnQuestionV1Model } from "../../models/GridColumnQuestionV1Model";
import { observer } from "mobx-react";
import { OpenEndedFieldTitleInput } from "../openEndedFieldComponents/OpenEndedFieldTitleInput";
import { OpenEndedFieldCodeInput } from "../openEndedFieldComponents/OpenEndedFieldCodeInput";
import { Stack, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { OpenEndedFieldHintInput } from "../openEndedFieldComponents/OpenEndedFieldHintInput";

interface GridOpenEndedFieldProps {
  spacing: Spacing;
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
  gridColumnQuestionV1Model: Instance<typeof GridColumnQuestionV1Model>;
  t: TFunction;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  borderBottom: string;
}

export const GridOpenEndedField = observer(
  ({
    spacing,
    questionDetailsStore,
    gridColumnQuestionV1Model,
    t,
    typography,
    tokens,
    borderBottom,
  }: GridOpenEndedFieldProps): React.ReactElement => {
    return (
      <Stack gap={spacing.spaceLG} width={"580px"}>
        <Stack borderBottom={borderBottom} padding={`${spacing.spaceSM} 0px`}>
          <Typography
            sx={{
              ...typography.sh3,
              color: tokens.label,
            }}
          >
            {t("surveys.addSurveyQuestion.grid.fieldConfiguration")}
          </Typography>
        </Stack>
        <Stack spacing={spacing.spaceLG} width="580px">
          <Stack direction="row" gap={spacing.spaceLG}>
            <OpenEndedFieldTitleInput
              value={gridColumnQuestionV1Model.openEndedSingleEntry.fieldTitle}
              onTextChange={(inputString: string) => {
                questionDetailsStore.setFieldValidations(null);
                gridColumnQuestionV1Model.setOpenEndedFieldTitle(inputString);
              }}
              t={t}
              width={undefined}
            />

            <OpenEndedFieldCodeInput
              width="132px"
              value={gridColumnQuestionV1Model.openEndedSingleEntry.code}
              onTextChange={(inputString: string) => {
                questionDetailsStore.setFieldValidations(null);
                gridColumnQuestionV1Model.setOpenEndedFieldCode(inputString);
              }}
              helperText={
                questionDetailsStore.isOptionCodeNotUnique(
                  gridColumnQuestionV1Model.openEndedSingleEntry.code,
                )
                  ? t("surveys.addSurveyQuestion.enterUniqueCode")
                  : EMPTY_CHARACTER
              }
              t={t}
              error={gridColumnQuestionV1Model.doesFieldCodeContainErrors}
            />
          </Stack>
          <OpenEndedFieldHintInput
            width={undefined}
            value={gridColumnQuestionV1Model.openEndedSingleEntry.fieldHint}
            onTextChange={(inputString: string) => {
              questionDetailsStore.setFieldValidations(null);
              gridColumnQuestionV1Model.setOpenEndedFieldHint(inputString);
            }}
            t={t}
          />
        </Stack>
      </Stack>
    );
  },
);
