import { SINGLE_SPACING } from "@pulse/shared-components";
import { Instance, types } from "mobx-state-tree";

export const UserNameModel = types
  .model("UserNameModel", {
    firstName: types.string,
    lastName: types.string,
  })
  .views((store) => ({
    get firstAndLastName(): string {
      return store.firstName + SINGLE_SPACING + store.lastName;
    },
  }));

export const createUserNameModel = (
  firstName: string,
  lastName: string,
): Instance<typeof UserNameModel> => {
  return UserNameModel.create({
    firstName,
    lastName,
  });
};
