import { Instance, types } from "mobx-state-tree";
import {
  BasicQuestionDetailsModel,
  createBasicQuestionDetailsModel,
} from "./BasicQuestionDetailsModel";
import {
  OpenEndedFieldV1Store,
  createOpenEndedFieldV1Store,
} from "../store/OpenEndedFieldV1Store";
import {
  SingleChoiceQuestionV1Store,
  createSingleChoiceQuestionV1Store,
} from "../store/SingleChoiceQuestionV1Store";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import {
  MultiChoiceQuestionV1Store,
  createMultiChoiceQuestionV1Store,
} from "../store/MultiChoiceQuestionV1Store";
import {
  RankingQuestionV1Store,
  createRankingQuestionV1Store,
} from "../store/RankingQuestionV1Store";
import {
  GridQuestionV1Store,
  createGridQuestionV1Store,
} from "../store/GridQuestionV1Store";
import {
  MessageQuestionV1Store,
  createMessageQuestionV1Store,
} from "../store/MessageQuestionV1Store";

export const QuestionDetailsModel = types.model("QuestionDetailsModel", {
  basicQuestionDetails: BasicQuestionDetailsModel,
  questionDetailsJSON: types.string,
  isResponseResetAllowed: types.optional(types.boolean, false),
  openEndedFieldV1Store: OpenEndedFieldV1Store,
  singleChoiceQuestionV1Store: SingleChoiceQuestionV1Store,
  multiChoiceQuestionV1Store: MultiChoiceQuestionV1Store,
  rankingQuestionV1Store: RankingQuestionV1Store,
  gridQuestionV1Store: GridQuestionV1Store,
  messageQuestionV1Store: MessageQuestionV1Store,
});

export const createQuestionDetailsModel = (): Instance<
  typeof QuestionDetailsModel
> => {
  return QuestionDetailsModel.create({
    basicQuestionDetails: createBasicQuestionDetailsModel(),
    questionDetailsJSON: EMPTY_CHARACTER,
    openEndedFieldV1Store: createOpenEndedFieldV1Store(),
    singleChoiceQuestionV1Store: createSingleChoiceQuestionV1Store(),
    multiChoiceQuestionV1Store: createMultiChoiceQuestionV1Store(),
    rankingQuestionV1Store: createRankingQuestionV1Store(),
    gridQuestionV1Store: createGridQuestionV1Store(),
    messageQuestionV1Store: createMessageQuestionV1Store(),
  });
};
