import {
  FoundationColorTokens,
  IconButton,
  Spacing,
  TableReloadHandle,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Edit } from "lucide-react";
import React, { useState } from "react";
import { EditBOUserDialog } from "./EditBOUserDialog";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { EditBOUserStore } from "../store/EditBOUserStore";
import { BoUserRole } from "@pulse/pulse-rpcs";

interface EditBOUserProps {
  t: TFunction;
  spacing: Spacing;
  editBOUserStore: Instance<typeof EditBOUserStore>;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  boUserId: string;
  firstName: string;
  lastName: string;
  role: BoUserRole.BoUserRole;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const EditBOUser = ({
  boUserId,
  editBOUserStore,
  spacing,
  t,
  tableRef,
  tokens,
  typography,
  firstName,
  lastName,
  role,
}: EditBOUserProps): React.ReactElement => {
  const [isEditBOUserDialogVisible, setIsEditBOUserDialogVisible] =
    useState(false);

  return (
    <>
      {isEditBOUserDialogVisible && (
        <EditBOUserDialog
          isEditBOUserDialogOpen={isEditBOUserDialogVisible}
          setIsEditBOUserDialogOpen={setIsEditBOUserDialogVisible}
          t={t}
          spacing={spacing}
          editBOUserStore={editBOUserStore}
          typography={typography}
          tokens={tokens}
          boUserId={boUserId}
          tableRef={tableRef}
          firstName={firstName}
          lastName={lastName}
          role={role}
        />
      )}
      <IconButton
        icon={<Edit />}
        name="editButton"
        variant="plain-neutral"
        size="small"
        onClick={() => {
          setIsEditBOUserDialogVisible(true);
        }}
      />
    </>
  );
};
