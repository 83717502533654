import React, { useEffect, useState } from "react";
import { DetailSectionComponent } from "../../../components/DetailSectionComponent";
import { Stack } from "@mui/material";
import { LoadingIndicator } from "@surya-digital/leo-reactjs-material-ui";
import { QuestionsPane } from "../components/QuestionsPane";
import { useTranslation } from "react-i18next";
import {
  ConfirmationDialogComponent,
  EMPTY_CHARACTER,
  FeatureInDevelopmentSnackbar,
  SuccessfulDialogComponent,
} from "@pulse/shared-components";
import { QuestionConfigurationPane } from "../components/QuestionConfigurationPane";
import { useQuestionDetailsStore } from "../store/hooks";
import { observer } from "mobx-react";
import { getSurveyStatusChipProps } from "../../utils/getSurveyStatusChipProps";
import {
  processSurveyParams,
  useRouteNavigator,
} from "../../../routes/RoutesHelper";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { navigateOrGetNetworkErrorPage } from "../../../utility/navigateOrGetNetworkErrorPage";
import { PreviewSurveyButton } from "../components/PreviewSurveyButton";
import { useSurveyStore } from "../../store/Hooks";

enum NavigateType {
  logo = "LOGO",
  back = "BACK",
}

export const AddQuestionsPage = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigateTo = useRouteNavigator();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [selectedItemQuestionId, setSelectedItemQuestionId] = useState<
    string | null
  >(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [messageBoxQuestionItemId, setMessageBoxQuestionItemId] = useState<
    string | null
  >(null);
  const surveyStore = useSurveyStore();
  const questionDetailsStore = useQuestionDetailsStore();
  const errorStore = useNetworkingErrorStore();
  const [navigateType, setNavigateType] = useState<NavigateType | null>(null);

  useEffect(() => {
    processSurveyParams(surveyStore.getQuestionsOfSurvey, navigateTo);
  }, [surveyStore.questionList]);

  useEffect(() => {
    if (selectedItemQuestionId && selectedItemQuestionId !== EMPTY_CHARACTER) {
      questionDetailsStore.getQuestionDetails(selectedItemQuestionId, t);
    }
  }, [selectedItemQuestionId]);

  const [showAddOrUpdateSuccessfulDialog, setShowAddOrUpdateSuccessfulDialog] =
    useState(false);

  useEffect(() => {
    return () => {
      setIsSnackbarVisible(false);
      setSelectedItemQuestionId(null);
      questionDetailsStore.clearStore(t);
      surveyStore.clearRpcErrors();
      errorStore.removeError();
    };
  }, []);

  const errorPage = navigateOrGetNetworkErrorPage(
    errorStore.error,
    navigateTo,
    errorStore.removeError,
  );

  if (errorPage) {
    errorStore.removeError();
    return errorPage;
  }

  return (
    <>
      <DetailSectionComponent
        isManageUsersLogoVisible={true}
        isAuditLogsLogoVisible={true}
        mainHeaderProps={{
          title: surveyStore.surveyName,
          prefersLargeTitle: true,
          chipProps:
            surveyStore.surveyStatus &&
            getSurveyStatusChipProps(surveyStore.surveyStatus, t),
          actionElement: (
            <PreviewSurveyButton
              isAddQuestionPage={true}
              t={t}
              questionDetailsStore={questionDetailsStore}
              isButtonDisabled={surveyStore.isQuestionListEmpty}
              navigateTo={navigateTo}
            />
          ),
          backButtonCallback: () => {
            if (questionDetailsStore.isSaveButtonDisabled) {
              navigateTo.parent();
            } else {
              setNavigateType(NavigateType.back);
            }
          },
        }}
        isChildHeightVariable={false}
        isOverflowHidden={true}
        onLogoClickCallback={() => {
          if (questionDetailsStore.isSaveButtonDisabled) {
            navigateTo.project();
          } else {
            setNavigateType(NavigateType.logo);
          }
        }}
      >
        <Stack direction={"row"} height={"100%"} width={"100%"}>
          <QuestionsPane
            setSelectedQuestionItemId={setSelectedItemQuestionId}
            selectedQuestionItemId={selectedItemQuestionId}
            setIsMessageBoxVisible={setIsMessageBoxVisible}
            setMessageBoxQuestionItemId={setMessageBoxQuestionItemId}
            isMessageBoxVisible={isMessageBoxVisible}
            messageBoxQuestionItemId={messageBoxQuestionItemId}
          />
          <QuestionConfigurationPane
            key={selectedItemQuestionId}
            setSelectedItemQuestionId={setSelectedItemQuestionId}
            setIsMessageBoxVisible={setIsMessageBoxVisible}
            setMessageBoxQuestionItemId={setMessageBoxQuestionItemId}
            selectedItemQuestionId={selectedItemQuestionId}
            setShowAddOrUpdateSuccessfulDialog={
              setShowAddOrUpdateSuccessfulDialog
            }
          />
        </Stack>
        <LoadingIndicator
          isLoading={
            surveyStore.isRPCLoading || questionDetailsStore.isRPCLoading
          }
        />
        <FeatureInDevelopmentSnackbar
          isSnackbarVisible={isSnackbarVisible}
          setIsSnackbarVisible={setIsSnackbarVisible}
        />
        <SuccessfulDialogComponent
          showSuccessfulDialogComponent={showAddOrUpdateSuccessfulDialog}
          onSecondaryButtonClick={() => {
            setShowAddOrUpdateSuccessfulDialog(false);
          }}
          title={t("surveys.addSurveyQuestion.successfulTitle")}
          description={t(
            "surveys.addSurveyQuestion.questionSavedSuccessfullyDescription",
          )}
          secondaryButtonText={t("common.done")}
          descriptionTextWidth={undefined}
        />
        <ConfirmationDialogComponent
          isDialogOpen={navigateType !== null}
          title={t("surveys.addSurveyQuestion.unsavedChangesTitle")}
          description={t(
            "surveys.addSurveyQuestion.navigateUnsavedChangesDescription",
          )}
          primaryButtonText={t("common.proceed")}
          secondaryButtonText={t("common.cancel")}
          secondaryButtonCallback={(): void => {
            setNavigateType(null);
          }}
          primaryButtonCallBack={(): Promise<void> => {
            switch (navigateType) {
              case NavigateType.back: {
                navigateTo.parent();
                break;
              }
              case NavigateType.logo: {
                navigateTo.project();
                break;
              }
              default: {
                break;
              }
            }
            return Promise.resolve();
          }}
        />
      </DetailSectionComponent>
    </>
  );
});
