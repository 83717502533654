import {
  ChangeSpocPasswordRPC,
  DeleteProjectSpocsRPC,
  GetProjectSpocsRPC,
  GetSpocDetailsRPC,
  Password,
  ResetSpocPasswordRPC,
  SpocManagementItemsPerPage,
  SpocManagementPageIndex,
  SpocManagementSortOrder,
  SpocSelection,
  TableFilter,
  UpdateSpocNameRPC,
  UserName,
} from "@pulse/pulse-rpcs";
import {
  GetProjectSpocsSortOrder,
  GetProjectSpocsSortOrderEnums,
} from "@pulse/pulse-rpcs";
import {
  CommonErrors,
  DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE,
  DEFAULT_PROJECT_LIST_SORT_INDEX,
  EMPTY_CHARACTER,
  EMPTY_LIST_LENGTH,
  MAX_USERNAME_CHAR_LIMIT,
  MAX_PASSWORD_CHAR_LIMIT,
  MIN_USERNAME_CHAR_LIMIT,
  MIN_PASSWORD_CHAR_LIMIT,
  ZERO_VALUE,
} from "@pulse/shared-components";
import {
  MultiSelectTableStore,
  TableSortOption,
  createMultiSelectTableStore,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance, cast, clone, flow, getRoot, types } from "mobx-state-tree";
import { ManageSPoCsListHeaderEnum } from "../components/userAccess/ManageSPoCsPane";
import { getAPIClient } from "../../networking/APIClient";
import { LeoDate, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { RootStore } from "../../root/store/RootStore";
import {
  useChangeSpocPasswordRPCClient,
  useDeleteProjectSpocsRPCClient,
  useGetProjectSpocsRPCClient,
  useGetSpocDetailsRPCClient,
  useResetSpocPasswordRPCClient,
  useUpdateSpocNameRPCClient,
} from "../rpcs/RPC";
import {
  ManageSPoCsDetailsModel,
  createManageSPoCsDetails,
} from "../models/ManageSPoCsDetailsModel";
import {
  FilterStore,
  createFilterStore,
} from "../../../components/filter/store/FilterStore";
import { AddNewSPoCStore, createAddNewSPoCStore } from "./AddNewSPoCStore";
import {
  SPoCRespondentStore,
  createSPoCRespondentStore,
} from "./SPoCRespondentStore";
import {
  ManageBOUserStore,
  createManageBOUserStore,
} from "./ManageBOUserStore";
import {
  AddProjectBOUStore as AddProjectBOUStore,
  createAddNewProjectBOUStore,
} from "./AddProjectBOUStore";
import { FIRST_AND_LAST_NAME_REGEX } from "@pulse/shared-components";

export enum UserAccessTabs {
  ManageBOU,
  ManageSPoCs,
}

export interface GetProjectSPoCsListSortOptions {
  columnName: GetProjectSpocsSortOrderEnums.ColumnName.ColumnName;
  order: SpocManagementSortOrder.SpocManagementSortOrder;
}

export enum CommonRPCError {
  InvalidSPoCId = "INVALID_SPOC_ID",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
}

export enum DeleteProjectSpocsRPCErrors {
  RespondentsAssignedToSpoc = "RESPONDENTS_ASSIGNED_TO_SPOC",
}

export enum ChangeSpocPasswordRPCError {
  InvalidPassword = "INVALID_PASSWORD",
  UnableToChangePassword = "UNABLE_TO_CHANGE_PASSWORD",
}

export enum ResetSpocPasswordRPCError {
  SendEmailFailed = "SEND_EMAIL_FAILED",
  EmailAlreadySent = "EMAIL_ALREADY_SENT",
  ResetPasswordLimitReached = "RESET_PASSWORD_LIMIT_REACHED",
}

export enum ChangePasswordDialogState {
  ChangePasswordState = "CHANGE_PASSWORD_STATE",
  ErrorState = "ERROR_STATE",
  SuccessState = "SUCCESS_STATE",
}

export enum DeleteSPoCDialogState {
  DeleteSPoCState = "DELETE_SPOC_STATE",
  ErrorState = "ERROR_STATE",
}

export interface ManageProjectSPoCsListFilters {
  userName: string | null | undefined;
  emailAddress: string | null | undefined;
  addedOnStartDate: LeoDate | null | undefined;
  addedOnEndDate: LeoDate | null | undefined;
  expiresOnStartDate: LeoDate | null | undefined;
  expiresOnEndDate: LeoDate | null | undefined;
}

export enum GetProjectSpocsRPCErrors {
  InvalidPageIndex = "INVALID_PAGE_INDEX",
  InvalidDateRange = "INVALID_DATE_RANGE",
}

export enum ConfirmationDialogState {
  ChangedSPoCNameSuccessfully = "CHANGED_SPOC_NAME_SUCCESSFULLY",
  ResetPasswordSuccessfully = "RESET_PASSWORD_SUCCESSFULLY",
  ChangeSpocNameProjectAlreadyArchived = "CHANGE_SPOC_NAME_PROJECT_ALREADY_ARCHIVED",
  ResetPasswordProjectAlreadyArchived = "RESET_PASSWORD_PROJECT_ALREADY_ARCHIVED",
  UnexpectedError = "UNEXPECTED_ERROR",
  EmailAlreadySent = "EMAIL_ALREADY_SENT",
  ResetPasswordLimitReached = "RESET_PASSWORD_LIMIT_REACHED",
}

export const UserAccessStore = types
  .model("UserAccess", {
    multiSelectTableStore: MultiSelectTableStore,
    selectedTab: types.optional(types.number, UserAccessTabs.ManageBOU),
    manageBOUserStore: ManageBOUserStore,
    isRPCLoading: types.optional(types.boolean, false),
    isChangeSPoCPasswordRPCLoading: types.optional(types.boolean, false),
    totalItems: types.optional(types.number, ZERO_VALUE),
    manageProjectSPoCsListFilter: FilterStore,
    projectsSPoCList: types.array(ManageSPoCsDetailsModel),
    rpcErrors: types.maybeNull(
      types.union(
        types.enumeration(
          "GetProjectSpocsRPCErrors",
          Object.values(GetProjectSpocsRPCErrors),
        ),
        types.enumeration(
          "ChangeSpocPasswordRPCError",
          Object.values(ChangeSpocPasswordRPCError),
        ),
        types.enumeration(
          "DeleteProjectSpocsRPCErrors",
          Object.values(DeleteProjectSpocsRPCErrors),
        ),
        types.enumeration("CommonRPCError", Object.values(CommonRPCError)),
      ),
    ),
    addNewSPoCStore: AddNewSPoCStore,
    addProjectBOUStore: AddProjectBOUStore,
    initialSelectedSPoCDetails: types.maybeNull(ManageSPoCsDetailsModel),
    updatedSelectedSPoCDetails: types.maybeNull(ManageSPoCsDetailsModel),
    isURLInvalid: types.optional(types.boolean, false),
    spocRespondentStore: SPoCRespondentStore,
    isUpdateSPoCNameErrorVisible: types.optional(types.boolean, false),
    newPassword: types.optional(types.string, EMPTY_CHARACTER),
    confirmNewPassword: types.optional(types.string, EMPTY_CHARACTER),
    changePasswordDialogState: types.optional(
      types.enumeration(
        "ChangePasswordDialogState",
        Object.values(ChangePasswordDialogState),
      ),
      ChangePasswordDialogState.ChangePasswordState,
    ),
    isChangePasswordDialogHelperTextsVisible: types.optional(
      types.boolean,
      false,
    ),
    confirmationDialogState: types.maybeNull(
      types.enumeration(
        "ConfirmationDialogState",
        Object.values(ConfirmationDialogState),
      ),
    ),
    isConfirmationDialogVisible: types.optional(types.boolean, false),
    deleteSPoCDialogState: types.optional(
      types.enumeration(
        "DeleteSPoCDialogState",
        Object.values(DeleteSPoCDialogState),
      ),
      DeleteSPoCDialogState.DeleteSPoCState,
    ),
  })
  .views((store) => ({
    get isEnteredPasswordsSame(): boolean {
      return store.newPassword === store.confirmNewPassword;
    },
    get isSPoCBulkSelected(): boolean {
      return store.multiSelectTableStore.selectedRowIds.length > ZERO_VALUE;
    },
    get isNoRPCError(): boolean {
      return store.rpcErrors === null;
    },
    get isInvalidPasswordError(): boolean {
      return store.rpcErrors === ChangeSpocPasswordRPCError.InvalidPassword;
    },
    get isSuccessfulConfirmationDialogOpen(): boolean {
      return (
        (store.confirmationDialogState ===
          ConfirmationDialogState.ChangedSPoCNameSuccessfully ||
          store.confirmationDialogState ===
            ConfirmationDialogState.ResetPasswordSuccessfully) &&
        store.isConfirmationDialogVisible
      );
    },
    get isErrorConfirmationDialogOpen(): boolean {
      return (
        (store.confirmationDialogState ===
          ConfirmationDialogState.ChangeSpocNameProjectAlreadyArchived ||
          store.confirmationDialogState ===
            ConfirmationDialogState.ResetPasswordProjectAlreadyArchived ||
          store.confirmationDialogState ===
            ConfirmationDialogState.EmailAlreadySent ||
          store.confirmationDialogState ===
            ConfirmationDialogState.ResetPasswordLimitReached ||
          store.confirmationDialogState ===
            ConfirmationDialogState.UnexpectedError) &&
        store.isConfirmationDialogVisible
      );
    },
  }))
  .views((store) => ({
    get doesStoreContainProjectListErrors(): boolean {
      return (
        store.rpcErrors === GetProjectSpocsRPCErrors.InvalidDateRange ||
        store.rpcErrors === GetProjectSpocsRPCErrors.InvalidPageIndex
      );
    },
    get isChangeSPoCPasswordDialogState(): boolean {
      return (
        store.changePasswordDialogState ===
        ChangePasswordDialogState.ChangePasswordState
      );
    },
    get isDeleteSPoCDialogState(): boolean {
      return (
        store.deleteSPoCDialogState === DeleteSPoCDialogState.DeleteSPoCState
      );
    },
    get isChangePasswordPrimaryButtonDisabled(): boolean {
      return (
        store.newPassword === EMPTY_CHARACTER ||
        store.confirmNewPassword === EMPTY_CHARACTER
      );
    },
    get isSaveChangeButtonDisabled(): boolean {
      return (
        JSON.stringify(store.initialSelectedSPoCDetails) ===
        JSON.stringify(store.updatedSelectedSPoCDetails)
      );
    },
    get isFirstNameInvalid(): boolean {
      const userNameRegex = new RegExp(FIRST_AND_LAST_NAME_REGEX);
      if (store.updatedSelectedSPoCDetails === null) {
        console.error(
          "updatedSelectedSPoCDetails cannot be null in this flow.",
        );
        return false;
      }
      return (
        store.updatedSelectedSPoCDetails.firstName.length >
          MAX_USERNAME_CHAR_LIMIT ||
        store.updatedSelectedSPoCDetails.firstName.length <
          MIN_USERNAME_CHAR_LIMIT ||
        !userNameRegex.test(store.updatedSelectedSPoCDetails.firstName)
      );
    },
    get isLastNameInvalid(): boolean {
      const userNameRegex = new RegExp(FIRST_AND_LAST_NAME_REGEX);
      if (store.updatedSelectedSPoCDetails === null) {
        console.error(
          "updatedSelectedSPoCDetails cannot be null in this flow.",
        );
        return false;
      }
      return (
        store.updatedSelectedSPoCDetails.lastName.length >
          MAX_USERNAME_CHAR_LIMIT ||
        store.updatedSelectedSPoCDetails.lastName.length <
          MIN_USERNAME_CHAR_LIMIT ||
        !userNameRegex.test(store.updatedSelectedSPoCDetails.lastName)
      );
    },
    get isNewPasswordInvalid(): boolean {
      return (
        (store.newPassword.length > MAX_PASSWORD_CHAR_LIMIT ||
          store.newPassword.length < MIN_PASSWORD_CHAR_LIMIT ||
          !store.isEnteredPasswordsSame ||
          store.isInvalidPasswordError) &&
        store.isChangePasswordDialogHelperTextsVisible
      );
    },
    get isConfirmNewPasswordInvalid(): boolean {
      return (
        (store.confirmNewPassword.length > MAX_PASSWORD_CHAR_LIMIT ||
          store.confirmNewPassword.length < MIN_PASSWORD_CHAR_LIMIT ||
          !store.isEnteredPasswordsSame ||
          store.isInvalidPasswordError) &&
        store.isChangePasswordDialogHelperTextsVisible
      );
    },
    manageProjectSPoCsListSortOptions(
      sort: TableSortOption | undefined,
    ): GetProjectSPoCsListSortOptions {
      let columnName =
        GetProjectSpocsSortOrderEnums.ColumnName.ColumnName.ADDED_ON;
      if (sort === undefined) {
        return {
          columnName,
          order: SpocManagementSortOrder.SpocManagementSortOrder.DESCENDING,
        };
      }
      switch (sort.id) {
        case ManageSPoCsListHeaderEnum.AddedOn: {
          columnName =
            GetProjectSpocsSortOrderEnums.ColumnName.ColumnName.ADDED_ON;
          break;
        }
        case ManageSPoCsListHeaderEnum.ExpiresOn: {
          columnName =
            GetProjectSpocsSortOrderEnums.ColumnName.ColumnName.EXPIRES_ON;
          break;
        }
      }
      return {
        columnName,
        order:
          sort.order === "asc"
            ? SpocManagementSortOrder.SpocManagementSortOrder.ASCENDING
            : SpocManagementSortOrder.SpocManagementSortOrder.DESCENDING,
      };
    },
    get projectSpocListFilters(): ManageProjectSPoCsListFilters {
      let userName: string | null | undefined = null;
      let emailAddress: string | null | undefined = null;
      let addedOnStartDate: LeoDate | null | undefined = null;
      let addedOnEndDate: LeoDate | null | undefined = null;
      let expiresOnStartDate: LeoDate | null | undefined = null;
      let expiresOnEndDate: LeoDate | null | undefined = null;
      if (
        store.manageProjectSPoCsListFilter.appliedFilters.length !==
        EMPTY_LIST_LENGTH
      ) {
        store.manageProjectSPoCsListFilter.appliedFilters.forEach((filter) => {
          switch (filter.key) {
            case ManageSPoCsListHeaderEnum.UserName: {
              userName = filter.openEndedFilterValue;
              break;
            }
            case ManageSPoCsListHeaderEnum.EmailAddress: {
              emailAddress = filter.openEndedFilterValue;
              break;
            }
            case ManageSPoCsListHeaderEnum.AddedOn: {
              if (filter.dateRangeFilterValue?.startDate !== null) {
                addedOnStartDate = filter.dateRangeFilterValue?.leoStartDate;
              }
              if (filter.dateRangeFilterValue?.endDate !== null) {
                addedOnEndDate = filter.dateRangeFilterValue?.leoEndDate;
              }
              break;
            }
            case ManageSPoCsListHeaderEnum.ExpiresOn: {
              if (filter.dateRangeFilterValue?.startDate !== null) {
                expiresOnStartDate = filter.dateRangeFilterValue?.leoStartDate;
              }
              if (filter.dateRangeFilterValue?.endDate !== null) {
                expiresOnEndDate = filter.dateRangeFilterValue?.leoEndDate;
              }
            }
          }
        });
      }
      return {
        userName,
        emailAddress,
        addedOnStartDate,
        addedOnEndDate,
        expiresOnStartDate,
        expiresOnEndDate,
      };
    },
  }))
  .actions((store) => ({
    resetSelectedTab: (): void => {
      store.selectedTab = UserAccessTabs.ManageBOU;
    },
    setIsURLInvalid: (isURLInvalid: boolean): void => {
      store.isURLInvalid = isURLInvalid;
    },
    setInitialSelectedSPoCDetails: (spocId: string): void => {
      const foundProjectSPoC = store.projectsSPoCList.find(
        (projectSPoC) => projectSPoC.spocId === spocId,
      );
      if (foundProjectSPoC) {
        store.initialSelectedSPoCDetails = clone(foundProjectSPoC);
      } else {
        console.error(`Invalid SPoC Id ${spocId} found.`);
      }
    },
    resetInitialSelectedSPoCDetails: (): void => {
      store.initialSelectedSPoCDetails = null;
    },
    getSpocDetails: flow(function* (projectId: string, spocId: string) {
      store.isRPCLoading = true;
      try {
        const apiClient = getAPIClient(store);
        const request = new GetSpocDetailsRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(spocId),
        );
        const {
          response,
          error,
        }: {
          response?: GetSpocDetailsRPC.Response;
          error?: GetSpocDetailsRPC.Errors.Errors;
        } = yield useGetSpocDetailsRPCClient(apiClient).execute(request);
        if (response) {
          store.initialSelectedSPoCDetails = createManageSPoCsDetails(response);
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              break;
            }
            case CommonRPCError.InvalidSPoCId: {
              store.isURLInvalid = true;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }))
  .actions((store) => ({
    resetStore: (): void => {
      store.multiSelectTableStore = createMultiSelectTableStore();
      store.isRPCLoading = false;
      store.totalItems = ZERO_VALUE;
      store.projectsSPoCList.clear();
      store.rpcErrors = null;
      store.selectedTab = UserAccessTabs.ManageBOU;
      store.initialSelectedSPoCDetails = null;
      store.updatedSelectedSPoCDetails = null;
      store.isURLInvalid = false;
    },
    resetChangePasswordProps: (): void => {
      store.changePasswordDialogState =
        ChangePasswordDialogState.ChangePasswordState;
      store.newPassword = EMPTY_CHARACTER;
      store.confirmNewPassword = EMPTY_CHARACTER;
      store.rpcErrors = null;
      store.isChangePasswordDialogHelperTextsVisible = false;
    },
    resetDeleteSPoCProps: (): void => {
      store.deleteSPoCDialogState = DeleteSPoCDialogState.DeleteSPoCState;
    },
    setIsUpdateSPoCNameErrorVisible: (
      isUpdateSPoCNameErrorVisible: boolean,
    ): void => {
      store.isUpdateSPoCNameErrorVisible = isUpdateSPoCNameErrorVisible;
    },
    setIsConfirmationDialogVisible: (
      isConfirmationDialogVisible: boolean,
    ): void => {
      store.isConfirmationDialogVisible = isConfirmationDialogVisible;
      if (!isConfirmationDialogVisible) {
        store.confirmationDialogState = null;
      }
    },
    setNewPassword: (newPassword: string): void => {
      store.rpcErrors = null;
      store.isChangePasswordDialogHelperTextsVisible = false;
      store.newPassword = newPassword;
    },
    setConfirmNewPassword: (confirmNewPassword: string): void => {
      store.rpcErrors = null;
      store.isChangePasswordDialogHelperTextsVisible = false;
      store.confirmNewPassword = confirmNewPassword;
    },
    setSelectedTab: (selectedTab: number): void => {
      store.selectedTab = selectedTab;
    },
    setupUpdatedSelectedSPoCDetails: (): void => {
      store.updatedSelectedSPoCDetails = clone(
        store.initialSelectedSPoCDetails,
      );
    },
    updateSPoCName: flow(function* (projectId: string, spocId: string) {
      if (store.isFirstNameInvalid || store.isLastNameInvalid) {
        return;
      } else {
        store.isUpdateSPoCNameErrorVisible = false;
      }
      store.isRPCLoading = true;
      try {
        if (store.updatedSelectedSPoCDetails === null) {
          console.error(
            "updatedSelectedSPoCDetails cannot be null in this flow.",
          );
          return;
        }
        const apiClient = getAPIClient(store);
        const request = new UpdateSpocNameRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(spocId),
          new UserName(
            store.updatedSelectedSPoCDetails.firstName,
            store.updatedSelectedSPoCDetails.lastName,
          ),
        );
        const {
          response,
          error,
        }: {
          response?: UpdateSpocNameRPC.Response;
          error?: UpdateSpocNameRPC.Errors.Errors;
        } = yield useUpdateSpocNameRPCClient(apiClient).execute(request);
        if (response) {
          store.updatedSelectedSPoCDetails?.setFirstName(
            store.updatedSelectedSPoCDetails.firstName.trim(),
          );
          store.updatedSelectedSPoCDetails?.setLastName(
            store.updatedSelectedSPoCDetails.lastName.trim(),
          );
          store.confirmationDialogState =
            ConfirmationDialogState.ChangedSPoCNameSuccessfully;
          store.initialSelectedSPoCDetails = clone(
            store.updatedSelectedSPoCDetails,
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              break;
            }
            case CommonRPCError.InvalidSPoCId: {
              store.isURLInvalid = true;
              break;
            }
            case CommonRPCError.ProjectAlreadyArchived: {
              store.confirmationDialogState =
                ConfirmationDialogState.ChangeSpocNameProjectAlreadyArchived;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    resetSPoCPassword: flow(function* (projectId: string, spocId: string) {
      store.isRPCLoading = true;
      try {
        const apiClient = getAPIClient(store);
        const request = new ResetSpocPasswordRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(spocId),
        );
        const {
          response,
          error,
        }: {
          response?: ResetSpocPasswordRPC.Response;
          error?: ResetSpocPasswordRPC.Errors.Errors;
        } = yield useResetSpocPasswordRPCClient(apiClient).execute(request);
        if (response) {
          store.confirmationDialogState =
            ConfirmationDialogState.ResetPasswordSuccessfully;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              break;
            }
            case CommonRPCError.InvalidSPoCId: {
              store.isURLInvalid = true;
              break;
            }
            case CommonRPCError.ProjectAlreadyArchived: {
              store.confirmationDialogState =
                ConfirmationDialogState.ResetPasswordProjectAlreadyArchived;
              break;
            }
            case ResetSpocPasswordRPCError.SendEmailFailed: {
              store.confirmationDialogState =
                ConfirmationDialogState.UnexpectedError;
              break;
            }
            case ResetSpocPasswordRPCError.EmailAlreadySent: {
              store.confirmationDialogState =
                ConfirmationDialogState.EmailAlreadySent;
              break;
            }
            case ResetSpocPasswordRPCError.ResetPasswordLimitReached: {
              store.confirmationDialogState =
                ConfirmationDialogState.ResetPasswordLimitReached;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    changeSPoCPassword: flow(function* (projectId: string, spocId: string) {
      store.isChangePasswordDialogHelperTextsVisible = true;
      if (store.isNewPasswordInvalid || store.isConfirmNewPasswordInvalid) {
        return;
      }
      store.isChangeSPoCPasswordRPCLoading = true;
      try {
        if (store.updatedSelectedSPoCDetails === null) {
          console.error(
            "updatedSelectedSPoCDetails cannot be null in this flow.",
          );
          return;
        }
        const apiClient = getAPIClient(store);
        const request = new ChangeSpocPasswordRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(spocId),
          new Password(store.newPassword),
        );
        const {
          response,
          error,
        }: {
          response?: ChangeSpocPasswordRPC.Response;
          error?: ChangeSpocPasswordRPC.Errors.Errors;
        } = yield useChangeSpocPasswordRPCClient(apiClient).execute(request);
        if (response) {
          store.changePasswordDialogState =
            ChangePasswordDialogState.SuccessState;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              break;
            }
            case CommonRPCError.InvalidSPoCId: {
              store.isURLInvalid = true;
              break;
            }
            case ChangeSpocPasswordRPCError.InvalidPassword: {
              store.rpcErrors = ChangeSpocPasswordRPCError.InvalidPassword;
              break;
            }
            case ChangeSpocPasswordRPCError.UnableToChangePassword: {
              store.rpcErrors =
                ChangeSpocPasswordRPCError.UnableToChangePassword;
              store.changePasswordDialogState =
                ChangePasswordDialogState.ErrorState;
              break;
            }
            case CommonRPCError.ProjectAlreadyArchived: {
              store.rpcErrors = CommonRPCError.ProjectAlreadyArchived;
              store.changePasswordDialogState =
                ChangePasswordDialogState.ErrorState;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isChangeSPoCPasswordRPCLoading = false;
      }
    }),
    deleteSPoC: flow(function* (projectId: string, spocId: string | undefined) {
      store.isRPCLoading = true;
      try {
        const apiClient = getAPIClient(store);

        let spocSelection;
        const requestFilters: TableFilter[] = [];
        if (spocId) {
          spocSelection = new SpocSelection.SomeRowsSelected([
            new LeoUUID(spocId),
          ]);
        } else {
          spocSelection = store.multiSelectTableStore.areAllRowsSelected
            ? new SpocSelection.AllRowsSelected([...requestFilters])
            : new SpocSelection.SomeRowsSelected(
                store.multiSelectTableStore.selectedRowIds.map(
                  (id) => new LeoUUID(id),
                ),
              );
        }
        const request = new DeleteProjectSpocsRPC.Request(
          new LeoUUID(projectId),
          spocSelection,
        );
        const {
          response,
          error,
        }: {
          response?: DeleteProjectSpocsRPC.Response;
          error?: DeleteProjectSpocsRPC.Errors.Errors;
        } = yield useDeleteProjectSpocsRPCClient(apiClient).execute(request);
        store.multiSelectTableStore.unselectAllRows();
        store.manageProjectSPoCsListFilter.clearStore();
        if (response) {
          // We don't need to do anything for positive response.
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              break;
            }
            case CommonRPCError.InvalidSPoCId: {
              store.isURLInvalid = true;
              break;
            }
            case CommonRPCError.ProjectAlreadyArchived: {
              store.rpcErrors = CommonRPCError.ProjectAlreadyArchived;
              store.deleteSPoCDialogState = DeleteSPoCDialogState.ErrorState;
              break;
            }
            case DeleteProjectSpocsRPCErrors.RespondentsAssignedToSpoc: {
              store.rpcErrors =
                DeleteProjectSpocsRPCErrors.RespondentsAssignedToSpoc;
              store.deleteSPoCDialogState = DeleteSPoCDialogState.ErrorState;
              break;
            }
            default:
              store.deleteSPoCDialogState = DeleteSPoCDialogState.ErrorState;
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        store.deleteSPoCDialogState = DeleteSPoCDialogState.ErrorState;
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
    getProjectSpocs: flow(function* (
      pageIndex: number,
      projectId: string,
      sort?: TableSortOption,
    ) {
      store.isRPCLoading = true;
      const sortOption = [
        new GetProjectSpocsSortOrder(
          DEFAULT_PROJECT_LIST_SORT_INDEX,
          store.manageProjectSPoCsListSortOptions(sort).columnName,
          store.manageProjectSPoCsListSortOptions(sort).order,
        ),
      ];

      try {
        const apiClient = getAPIClient(store);
        const manageProjectSPoCsListFilters = store.projectSpocListFilters;
        const request = new GetProjectSpocsRPC.Request(
          sortOption,
          manageProjectSPoCsListFilters.userName,
          manageProjectSPoCsListFilters.emailAddress,
          projectId,
          manageProjectSPoCsListFilters.addedOnStartDate,
          manageProjectSPoCsListFilters.addedOnEndDate,
          manageProjectSPoCsListFilters.expiresOnStartDate,
          manageProjectSPoCsListFilters.expiresOnEndDate,
          new SpocManagementItemsPerPage(DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE),
          new SpocManagementPageIndex(pageIndex),
        );
        const {
          response,
          error,
        }: {
          response?: GetProjectSpocsRPC.Response;
          error?: GetProjectSpocsRPC.Errors.Errors;
        } = yield useGetProjectSpocsRPCClient(apiClient).execute(request);
        if (response) {
          store.projectsSPoCList = cast(
            response.getProjectSpocsPaginationResponse.map(
              (paginationResponse) => {
                return createManageSPoCsDetails(paginationResponse);
              },
            ),
          );
          store.totalItems = response.totalItems;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId: {
              break;
            }
            case GetProjectSpocsRPCErrors.InvalidPageIndex: {
              store.rpcErrors = GetProjectSpocsRPCErrors.InvalidPageIndex;
              break;
            }
            default:
              console.error(`Unhandled error ${error.code}.`);
              break;
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }));

export const createUserAccessStore = (): Instance<typeof UserAccessStore> => {
  return UserAccessStore.create({
    manageProjectSPoCsListFilter: createFilterStore(),
    multiSelectTableStore: createMultiSelectTableStore(),
    addNewSPoCStore: createAddNewSPoCStore(),
    spocRespondentStore: createSPoCRespondentStore(),
    manageBOUserStore: createManageBOUserStore(),
    addProjectBOUStore: createAddNewProjectBOUStore(),
  });
};
