import { Instance, types } from "mobx-state-tree";
import { SurveyDetails, SurveyStatus } from "@pulse/pulse-rpcs";
import {
  SubmissionDataModel,
  createSubmissionDataModel,
} from "../../projects/models/SubmissionDataModel";
import {
  MAX_SUBMISSION_DATA_SET_SIZE,
  getFormattedDateString,
  getUserFullName,
} from "@pulse/shared-components";

export const SurveyDetailsModel = types
  .model("SurveyDetailsModel", {
    surveyId: types.string,
    surveyName: types.string,
    creatorName: types.string,
    totalLinks: types.number,
    submittedLinks: types.number,
    inProgressLinks: types.number,
    notStartedLinks: types.number,
    surveyStatus: types.enumeration(Object.values(SurveyStatus.SurveyStatus)),
    submissionData: types.array(SubmissionDataModel),
  })
  .views((store) => ({
    get minAndMaxDateString(): string {
      return (
        getFormattedDateString(store.submissionData[0].submissionDate) +
        " - " +
        getFormattedDateString(
          store.submissionData[store.submissionData.length - 1].submissionDate,
        )
      );
    },
    get hasMaxDataLimitReached(): boolean {
      return store.submissionData.length === MAX_SUBMISSION_DATA_SET_SIZE;
    },
    get isNoSubmissionDataPresent(): boolean {
      return store.submissionData.length === 0;
    },
  }));

export const createSurveyDetailsModel = (
  surveyDetails: SurveyDetails,
): Instance<typeof SurveyDetailsModel> => {
  return SurveyDetailsModel.create({
    surveyId: surveyDetails.id.uuid,
    surveyName: surveyDetails.name.name,
    creatorName: getUserFullName(surveyDetails.creator),
    totalLinks: surveyDetails.linkBreakdown.totalLinks,
    submittedLinks: surveyDetails.linkBreakdown.submittedLink.volume,
    inProgressLinks:
      surveyDetails.linkBreakdown.inProgressLink.volume +
      surveyDetails.linkBreakdown.lastPageLink.volume +
      surveyDetails.linkBreakdown.startedLink.volume,
    notStartedLinks: surveyDetails.linkBreakdown.notStartedLink.volume,
    surveyStatus: surveyDetails.status,
    submissionData: surveyDetails.submissionData.map((data) => {
      return createSubmissionDataModel(
        new Date(data.date.timestamp),
        data.dailySubmissions,
        data.totalSubmissions,
      );
    }),
  });
};
