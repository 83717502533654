import { observer } from "mobx-react";
import React from "react";
import { Stack, Typography } from "@mui/material";
import { AlertCircle, Check } from "lucide-react";
import { ICON_SIZE, getIconProps } from "@pulse/shared-components";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";

interface ErrorSuccessDialogContentProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  isError: boolean;
  errorSuccessText: string;
}

/**
 * Creates a inner content of a error or success dialog.
 * @param spacing - LeoComponent Spacing used for padding and gaps.
 * @param tokens - LeoComponent FoundationColorTokens used for colors.
 * @param typography - LeoComponent Typography used for fonts.
 * @param isError - isError toggles the color of the data to error or success.
 * @param errorSuccessText - The text to be shown below the icon.
 * @returns The inner content of a error or success dialog
 *
 * The ErrorSuccessDialogContent component renders a column stack with an icon and text.
 * Depending on the isError prop, the icon is either a check or an alert circle.
 * The text's colour is altered by the isError prop.
 *
 * These figma links provides an illustration of the design:
 * https://www.figma.com/file/USl8dEzHVxs10KzHoEx56w/Pulse-Revamp?type=design&node-id=1338-202011&mode=design&t=QaRFZQIirUOojoZ3-4
 * https://www.figma.com/file/USl8dEzHVxs10KzHoEx56w/Pulse-Revamp?type=design&node-id=949-238003&mode=design&t=QaRFZQIirUOojoZ3-4
 *
 * @example
 *   <ErrorSuccessDialogContent
        spacing={spacing}
        tokens={tokens}
        typography={typography}
        isError={true}
        errorSuccessText={"This is a error message"}
      />
 */

export const ErrorSuccessDialogContent = observer(
  ({
    spacing,
    tokens,
    typography,
    isError,
    errorSuccessText,
  }: ErrorSuccessDialogContentProps): React.ReactElement => {
    return (
      <Stack
        direction="column"
        padding={spacing.spaceXL}
        gap={spacing.spaceXS}
        alignItems="center"
        textAlign="center"
        width="100%"
      >
        {isError ? (
          <AlertCircle {...getIconProps(tokens.iconError, ICON_SIZE.large)} />
        ) : (
          <Check {...getIconProps(tokens.iconSuccess, ICON_SIZE.large)} />
        )}

        <Typography
          sx={{
            ...typography.b1,
            color: isError ? tokens.labelError : tokens.labelSuccess,
            width: "472px",
          }}
        >
          {errorSuccessText}
        </Typography>
      </Stack>
    );
  },
);
