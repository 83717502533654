import { Instance, getParentOfType, types } from "mobx-state-tree";
import { BackOfficeSurveySettingsModel } from "../models/BackOfficeSurveySettingsModel";
import {
  MAX_SURVEY_INFORMATION_DIALOG_DESCRIPTION_TEXT_LENGTH,
  MAX_THANK_YOU_PAGE_TEXT_LENGTH,
  MAX_WELCOME_PAGE_TEXT_LENGTH,
  MIN_BUTTON_TEXT_LENGTH,
  MIN_MISCELLANEOUS_TEXT_LENGTH,
  MIN_RTE_TEXT_LENGTH,
  SurveySettingsDialogModel,
} from "@pulse/shared-components";

export const BackOfficeSurveySettingsUIStore = types
  .model({
    isErrorSnackbarVisible: types.optional(types.boolean, false),
    isSaveButtonClicked: types.optional(types.boolean, false),
  })
  .views(() => ({
    getIsRTETextInvalid: (rteText: string, maxLength: number): boolean => {
      return rteText.length < MIN_RTE_TEXT_LENGTH || rteText.length > maxLength;
    },
    getIsMiscellaneousTextInvalid: (miscellaneousText: string): boolean => {
      return miscellaneousText.length < MIN_MISCELLANEOUS_TEXT_LENGTH;
    },
    getIsButtonTextInvalid: (buttonText: string): boolean => {
      return buttonText.length < MIN_BUTTON_TEXT_LENGTH;
    },
  }))
  .views((store) => ({
    isInfoDialogDescriptionErrored(text: string): boolean {
      return (
        store.isSaveButtonClicked &&
        store.getIsRTETextInvalid(
          text,
          MAX_SURVEY_INFORMATION_DIALOG_DESCRIPTION_TEXT_LENGTH,
        )
      );
    },
    isMiscellaneousTextInvalid(text: string): boolean {
      return (
        store.isSaveButtonClicked && store.getIsMiscellaneousTextInvalid(text)
      );
    },
    isButtonTextErrored(text: string): boolean {
      return store.isSaveButtonClicked && store.getIsButtonTextInvalid(text);
    },
    isWelcomePageTextErrored(text: string): boolean {
      return (
        store.isSaveButtonClicked &&
        store.getIsRTETextInvalid(text, MAX_WELCOME_PAGE_TEXT_LENGTH)
      );
    },
    isThankYouPageTextErrored(text: string): boolean {
      return (
        store.isSaveButtonClicked &&
        store.getIsRTETextInvalid(text, MAX_THANK_YOU_PAGE_TEXT_LENGTH)
      );
    },
  }))
  .actions((store) => ({
    setIsSaveButtonClicked: (isSaveButtonClicked: boolean): void => {
      store.isSaveButtonClicked = isSaveButtonClicked;
    },
    setIsErrorSnackbarVisible: (isErrorSnackbarVisible: boolean): void => {
      store.isErrorSnackbarVisible = isErrorSnackbarVisible;
    },
    validateDialogSettingsText: (
      dialogSettingsModel: Instance<typeof SurveySettingsDialogModel>,
      isSecondaryActionPresent: boolean,
      isButtonPresent: boolean,
    ): boolean => {
      if (dialogSettingsModel.isTitleTextErrored(store.isSaveButtonClicked)) {
        store.isErrorSnackbarVisible = true;
        return true;
      }
      if (
        dialogSettingsModel.isDescriptionTextErrored(store.isSaveButtonClicked)
      ) {
        store.isErrorSnackbarVisible = true;
        return true;
      }
      if (
        dialogSettingsModel.isPrimaryActionTextErrored(
          store.isSaveButtonClicked,
        )
      ) {
        store.isErrorSnackbarVisible = true;
        return true;
      }
      if (
        isSecondaryActionPresent &&
        dialogSettingsModel.isSecondaryActionTextErrored(
          store.isSaveButtonClicked,
        )
      ) {
        store.isErrorSnackbarVisible = true;
        return true;
      }
      if (
        isButtonPresent &&
        dialogSettingsModel.isButtonTextErrored(store.isSaveButtonClicked)
      ) {
        store.isErrorSnackbarVisible = true;
        return true;
      }
      return false;
    },
    rteTextValidatorWithMinMaxLength: (
      rteText: string,
      maxLength: number,
    ): boolean => {
      if (rteText.length < MIN_RTE_TEXT_LENGTH || rteText.length > maxLength) {
        store.isErrorSnackbarVisible = true;
        return true;
      }
      return false;
    },
    validateButtonText: (buttonText: string): boolean => {
      if (store.getIsButtonTextInvalid(buttonText)) {
        store.isErrorSnackbarVisible = true;
        return true;
      }
      return false;
    },
    validateMiscellaneousTexts: (miscellaneousText: string): boolean => {
      if (store.getIsMiscellaneousTextInvalid(miscellaneousText)) {
        store.isErrorSnackbarVisible = true;
        return true;
      }
      return false;
    },
    clearError: (): void => {
      store.isErrorSnackbarVisible = false;
      store.isSaveButtonClicked = false;
    },
  }))
  .actions((store) => ({
    areSurveySettingsValid: (): boolean => {
      const backOfficeSurveySettings = getParentOfType(
        store,
        BackOfficeSurveySettingsModel,
      );
      if (
        store.validateDialogSettingsText(
          backOfficeSurveySettings.submitConfirmationDialogProperties,
          true,
          true,
        )
      ) {
        return false;
      }
      if (
        store.validateDialogSettingsText(
          backOfficeSurveySettings.restartSurveyDialogProperties,
          true,
          true,
        )
      ) {
        return false;
      }
      if (
        store.validateDialogSettingsText(
          backOfficeSurveySettings.saveDraftDialogProperties,
          false,
          true,
        )
      ) {
        return false;
      }
      if (
        store.validateDialogSettingsText(
          backOfficeSurveySettings.surveySettings.languageSelectionDialogTexts,
          true,
          false,
        )
      ) {
        return false;
      }
      if (
        store.validateDialogSettingsText(
          backOfficeSurveySettings.surveySettings
            .surveyAlreadySubmittedDialogTexts,
          false,
          false,
        )
      ) {
        return false;
      }
      if (
        store.isInfoDialogDescriptionErrored(
          backOfficeSurveySettings.surveyInformationDialogProperties
            .descriptionText,
        )
      ) {
        store.isErrorSnackbarVisible = true;
        return false;
      }
      if (
        store.rteTextValidatorWithMinMaxLength(
          backOfficeSurveySettings.welcomePageText,
          MAX_WELCOME_PAGE_TEXT_LENGTH,
        )
      ) {
        return false;
      }
      if (
        store.rteTextValidatorWithMinMaxLength(
          backOfficeSurveySettings.thankYouPageText,
          MAX_THANK_YOU_PAGE_TEXT_LENGTH,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          backOfficeSurveySettings.surveyClosedMessageTitleText,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          backOfficeSurveySettings.surveyClosedMessageDescriptionText,
        )
      ) {
        return false;
      }
      const surveySettings = backOfficeSurveySettings.surveySettings;
      if (
        store.validateMiscellaneousTexts(
          surveySettings.genericErrorMessageTitleText,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          surveySettings.genericErrorMessageDescriptionText,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          surveySettings.miscellaneousSurveySettingsText
            .singleSelectDropdownChooseOptionText,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          surveySettings.miscellaneousSurveySettingsText
            .singleSelectDropdownPleaseSelectOneText,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          surveySettings.miscellaneousSurveySettingsText
            .mandatoryQuestionErrorText,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          surveySettings.miscellaneousSurveySettingsText
            .allGridRowsNotAnsweredErrorText,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          surveySettings.miscellaneousSurveySettingsText
            .allAttemptedGridRowsNotAnsweredErrorText,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          surveySettings.miscellaneousSurveySettingsText
            .allRankingOptionsMandatory,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          surveySettings.miscellaneousSurveySettingsText
            .singleSelectSliderTooltipText,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          surveySettings.miscellaneousSurveySettingsText
            .multiSelectAndRankingMaxSelectionText,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          surveySettings.miscellaneousSurveySettingsText
            .areAllRankingOptionsMandatoryText,
        )
      ) {
        return false;
      }
      if (
        store.validateMiscellaneousTexts(
          surveySettings.miscellaneousSurveySettingsText.questionRequiredText,
        )
      ) {
        return false;
      }
      if (store.validateButtonText(surveySettings.startButtonText)) {
        return false;
      }
      if (store.validateButtonText(surveySettings.resetAnswerButtonText)) {
        return false;
      }
      if (store.validateButtonText(surveySettings.continueButtonText)) {
        return false;
      }
      if (store.validateButtonText(surveySettings.nextButtonText)) {
        return false;
      }
      if (store.validateButtonText(surveySettings.backButtonText)) {
        return false;
      }
      return true;
    },
  }));

export const createBackOfficeSurveySettingsUIStore = (): Instance<
  typeof BackOfficeSurveySettingsUIStore
> => {
  return BackOfficeSurveySettingsUIStore.create();
};
