import {
  ResetUserPasswordRPC,
  ResetUserPasswordRPCClientImpl,
  UpdateUsernameRPC,
  UpdateUsernameRPCClientImpl,
} from "@pulse/pulse-rpcs";
import { MOCK } from "@pulse/shared-components";
import { APIClient } from "@surya-digital/tedwig";
import { MockUpdateUsernameRPCClientImpl } from "./MockUpdateUsernameRPCClient";
import { MockResetUserPasswordRPCClientImpl } from "./MockResetPasswordRPCClient";

export const useUpdateUsernameRPCClient = (
  apiClient: APIClient,
): UpdateUsernameRPC => {
  if (MOCK.auditLogs) {
    return new MockUpdateUsernameRPCClientImpl();
  } else {
    return new UpdateUsernameRPCClientImpl(apiClient);
  }
};

export const useResetUserPasswordRPCClient = (
  apiClient: APIClient,
): ResetUserPasswordRPC => {
  if (MOCK.auditLogs) {
    return new MockResetUserPasswordRPCClientImpl();
  } else {
    return new ResetUserPasswordRPCClientImpl(apiClient);
  }
};
