import { Stack, Typography } from "@mui/material";
import {
  ICON_SIZE,
  getEllipsizedTextProperties,
  getIconProps,
} from "@pulse/shared-components";
import {
  Password,
  HidePassword,
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { SVGProps } from "react";
import { GitForkIcon } from "lucide-react";
import {
  getQuestionTypeIcon,
  getQuestionTypeTypography,
} from "../../../utils/QuestionTypeIconAndTypographyUtils";
import { DividerComponent } from "../commonQuestionConfigurationDetailsComponents/DividerComponent";
import { TFunction } from "i18next";
import { QuestionType } from "@pulse/pulse-rpcs";

export interface QuestionnaireItemHeaderProps {
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  t: TFunction;
  questionCode: string;
  questionType: QuestionType.QuestionType;
  isRuleApplied: boolean;
  isVisibleByDefault: boolean;
  spacing: Spacing;
}

export const QuestionnaireItemHeader = ({
  tokens,
  typography,
  t,
  questionCode,
  questionType,
  isRuleApplied,
  isVisibleByDefault,
  spacing,
}: QuestionnaireItemHeaderProps): React.ReactElement => {
  const defaultIconProps: SVGProps<SVGSVGElement> = {
    width: "20px",
    height: "20px",
    color: tokens.iconLowEmphasis,
  };
  return (
    <Stack
      direction="row"
      divider={<DividerComponent height="16px" orientation="vertical" />}
      gap={spacing.spaceXS}
    >
      <Typography
        sx={{
          ...typography.b2,
          color: tokens.labelLowEmphasis,
          maxWidth: "50ch",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {questionCode}
      </Typography>
      <Stack direction="row" gap={spacing.spaceXXS} alignItems="center">
        {getQuestionTypeIcon(questionType, tokens.iconLowEmphasis)}
        <Typography
          sx={{
            ...typography.b2,
            color: tokens.labelLowEmphasis,
            maxWidth: "30ch",
            ...getEllipsizedTextProperties(),
          }}
        >
          {getQuestionTypeTypography(questionType, t)}
        </Typography>
      </Stack>
      {isRuleApplied ? (
        <Stack direction="row" gap={spacing.spaceXXS} alignItems="center">
          <GitForkIcon
            {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.default)}
          />
          <Typography
            sx={{
              ...typography.b2,
              color: tokens.labelLowEmphasis,
              maxWidth: "20ch",
              ...getEllipsizedTextProperties(),
            }}
          >
            {t("surveys.questionnaireList.ruleApplied")}
          </Typography>
        </Stack>
      ) : // null is being returned here, instead of empty component (<></>), in order to avoid displaying the divider when there is no rule appplied.
      null}

      {isVisibleByDefault ? (
        <Stack direction="row" gap={spacing.spaceXXS} alignItems="center">
          <Password {...defaultIconProps} />
          <Typography
            sx={{
              ...typography.b2,
              color: tokens.labelLowEmphasis,
              maxWidth: "20ch",
              ...getEllipsizedTextProperties(),
            }}
          >
            {t("surveys.questionnaireList.visible")}
          </Typography>
        </Stack>
      ) : (
        <Stack direction="row" gap={spacing.spaceXXS} alignItems="center">
          <HidePassword {...defaultIconProps} />
          <Typography
            sx={{
              ...typography.b2,
              color: tokens.labelLowEmphasis,
              maxWidth: "20ch",
              ...getEllipsizedTextProperties(),
            }}
          >
            {t("surveys.questionnaireList.hidden")}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
