import {
  Spacing,
  FoundationColorTokens,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import React from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import { SettingsCheckboxComponent } from "./SettingsCheckboxComponent";
import { Instance } from "mobx-state-tree";
import { Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  MAX_BUTTON_TEXT_LENGTH,
  MAX_DIALOG_DESCRIPTION_TEXT_LENGTH,
  MAX_DIALOG_TITLE_TEXT_LENGTH,
  MIN_BUTTON_TEXT_LENGTH,
  MIN_DIALOG_DESCRIPTION_TEXT_LENGTH,
  MIN_DIALOG_TITLE_TEXT_LENGTH,
  SurveySettingsDialogModel,
  ZERO_VALUE,
  showMaxCharacterCountIndicator,
} from "@pulse/shared-components";
import {
  TextAreaInputField,
  TextInputField,
} from "@surya-digital/leo-reactjs-material-ui";

interface CheckBoxProps {
  settingsCheckboxLabelText: string;
  settingsCheckboxDescriptionText: string;
  isCheckboxSelected: boolean;
  setIsCheckboxSelected: (isCheckboxSelected: boolean) => void;
}

interface DialogCustomisationSettingsProps {
  settingsTitleText: string;
  checkBoxProps?: CheckBoxProps;
  surveySettingsDialogModel: Instance<typeof SurveySettingsDialogModel>;
  isSecondaryButtonPresent: boolean;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  clearError: () => void;
  isSaveButtonClicked: boolean;
}

export const DialogCustomisationSettings = observer(
  ({
    settingsTitleText,
    checkBoxProps,
    surveySettingsDialogModel,
    isSecondaryButtonPresent,
    t,
    spacing,
    typography,
    tokens,
    border,
    clearError,
    isSaveButtonClicked,
  }: DialogCustomisationSettingsProps): React.ReactElement => {
    const primaryButtonLabelText = isSecondaryButtonPresent
      ? t(
          "surveys.settings.dialogCustomisationCommonTexts.dialogPrimaryActionLabelText",
        )
      : t(
          "surveys.settings.dialogCustomisationCommonTexts.dialogActionLabelText",
        );
    const primaryButtonPlaceholderText = isSecondaryButtonPresent
      ? t(
          "surveys.settings.dialogCustomisationCommonTexts.dialogPrimaryActionPlaceholderText",
        )
      : t(
          "surveys.settings.dialogCustomisationCommonTexts.dialogActionPlaceholderText",
        );
    return (
      <SettingsContainer
        settingHeadingText={settingsTitleText}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
      >
        <Stack gap={spacing.spaceLG} width="400px">
          {checkBoxProps && (
            <SettingsCheckboxComponent
              isCheckboxSelected={checkBoxProps.isCheckboxSelected}
              setIsCheckboxSelected={checkBoxProps.setIsCheckboxSelected}
              checkboxlabel={checkBoxProps.settingsCheckboxLabelText}
              checkboxDescription={
                checkBoxProps.settingsCheckboxDescriptionText
              }
              spacing={spacing}
              typography={typography}
              tokens={tokens}
            />
          )}
          <Typography
            {...typography.sh3}
            py={spacing.spaceSM}
            borderBottom={border.default}
            color={tokens.label}
            width="100%"
          >
            {t(
              "surveys.settings.dialogCustomisationCommonTexts.dialogCustomisationTitleText",
            )}
          </Typography>
          <TextInputField
            name="dialogTitle"
            value={surveySettingsDialogModel.titleText}
            type="text"
            onTextChange={(titleText: string) => {
              surveySettingsDialogModel.setTitleText(
                titleText.slice(ZERO_VALUE, MAX_DIALOG_TITLE_TEXT_LENGTH),
              );
              clearError();
            }}
            label={t(
              "surveys.settings.dialogCustomisationCommonTexts.dialogTitleLabelText",
            )}
            placeholder={t(
              "surveys.settings.dialogCustomisationCommonTexts.dialogTitlePlaceholderText",
            )}
            required={true}
            error={surveySettingsDialogModel.isTitleTextErrored(
              isSaveButtonClicked,
            )}
            helperText={
              surveySettingsDialogModel.isTitleTextErrored(isSaveButtonClicked)
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_DIALOG_TITLE_TEXT_LENGTH,
                  })
                : undefined
            }
            maxCharacterLength={MAX_DIALOG_TITLE_TEXT_LENGTH}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_DIALOG_TITLE_TEXT_LENGTH,
                surveySettingsDialogModel.titleText,
              )
            }
          />

          <TextAreaInputField
            name="dialogDescription"
            value={surveySettingsDialogModel.descriptionText}
            onTextChange={(descriptionText: string) => {
              surveySettingsDialogModel.setDescriptionText(
                descriptionText.slice(
                  ZERO_VALUE,
                  MAX_DIALOG_DESCRIPTION_TEXT_LENGTH,
                ),
              );
              clearError();
            }}
            label={t(
              "surveys.settings.dialogCustomisationCommonTexts.dialogDescriptionLabelText",
            )}
            placeholder={t(
              "surveys.settings.dialogCustomisationCommonTexts.dialogDescriptionPlaceholderText",
            )}
            required={true}
            error={surveySettingsDialogModel.isDescriptionTextErrored(
              isSaveButtonClicked,
            )}
            helperText={
              surveySettingsDialogModel.isDescriptionTextErrored(
                isSaveButtonClicked,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_DIALOG_DESCRIPTION_TEXT_LENGTH,
                  })
                : undefined
            }
            maxCharacterLength={MAX_DIALOG_DESCRIPTION_TEXT_LENGTH}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_DIALOG_DESCRIPTION_TEXT_LENGTH,
                surveySettingsDialogModel.descriptionText,
              )
            }
          />

          <TextInputField
            name="dialogPrimaryButton"
            value={surveySettingsDialogModel.primaryActionText}
            type="text"
            onTextChange={(primaryActionText: string) => {
              surveySettingsDialogModel.setPrimaryActionText(
                primaryActionText.slice(ZERO_VALUE, MAX_BUTTON_TEXT_LENGTH),
              );
              clearError();
            }}
            label={primaryButtonLabelText}
            placeholder={primaryButtonPlaceholderText}
            required={true}
            error={surveySettingsDialogModel.isPrimaryActionTextErrored(
              isSaveButtonClicked,
            )}
            helperText={
              surveySettingsDialogModel.isPrimaryActionTextErrored(
                isSaveButtonClicked,
              )
                ? t("surveys.settings.genricHelperText", {
                    minCharacterLength: MIN_BUTTON_TEXT_LENGTH,
                  })
                : undefined
            }
            maxCharacterLength={MAX_BUTTON_TEXT_LENGTH}
            showCharacterCountIndicator={(): boolean =>
              showMaxCharacterCountIndicator(
                MAX_BUTTON_TEXT_LENGTH,
                surveySettingsDialogModel.primaryActionText,
              )
            }
          />
          {isSecondaryButtonPresent && (
            <TextInputField
              name="dialogSecondaryButton"
              value={surveySettingsDialogModel.secondaryActionText}
              type="text"
              onTextChange={(secondaryActionText: string) => {
                surveySettingsDialogModel.setSecondaryActionText(
                  secondaryActionText.slice(ZERO_VALUE, MAX_BUTTON_TEXT_LENGTH),
                );
                clearError();
              }}
              label={t(
                "surveys.settings.dialogCustomisationCommonTexts.dialogSecondaryActionLabelText",
              )}
              placeholder={t(
                "surveys.settings.dialogCustomisationCommonTexts.dialogSecondaryActionPlaceholderText",
              )}
              required={true}
              error={surveySettingsDialogModel.isSecondaryActionTextErrored(
                isSaveButtonClicked,
              )}
              helperText={
                surveySettingsDialogModel.isSecondaryActionTextErrored(
                  isSaveButtonClicked,
                )
                  ? t("surveys.settings.genricHelperText", {
                      minCharacterLength: MIN_BUTTON_TEXT_LENGTH,
                    })
                  : undefined
              }
              maxCharacterLength={MAX_BUTTON_TEXT_LENGTH}
              showCharacterCountIndicator={(): boolean =>
                showMaxCharacterCountIndicator(
                  MAX_BUTTON_TEXT_LENGTH,
                  surveySettingsDialogModel.secondaryActionText,
                )
              }
            />
          )}
        </Stack>
      </SettingsContainer>
    );
  },
);
