import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { DetailSectionComponent } from "../../../components/DetailSectionComponent";
import {
  Button,
  Plus,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { FeatureInDevelopmentSnackbar } from "@pulse/shared-components";
import { ProjectListPane } from "../components/ProjectListPane";
import { useProjectsRootStore, useSignInStore } from "../../store/Hooks";
import { useRouteNavigator } from "../../../routes/RoutesHelper";
import { navigateOrGetNetworkErrorPage } from "../../../utility/navigateOrGetNetworkErrorPage";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { UserPrivileges } from "../../store/user/UserPrivileges";

export enum ProjectListTabs {
  ActiveProjects,
  ArchivedProjects,
}

export const ProjectsPage = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigateTo = useRouteNavigator();
  const projectsRootStore = useProjectsRootStore();
  const errorStore = useNetworkingErrorStore();
  const signInStore = useSignInStore();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const [isChildHeightVariable, setIsChildHeightVariable] = useState(false);

  useEffect(() => {
    errorStore.removeError();
    projectsRootStore.clearProjectCreationDetailsAndRpcErrors();
    projectsRootStore.resetTabIndex();
  }, []);

  // This useEffect was added as we need to reset the isGetProjectListRPCCalled boolean when we re-render the projects list.
  // Otherwise the GetProjectList RPC will only be called once.
  useEffect(() => {
    projectsRootStore.resetIsGetProjectListRPCCalled();
  }, [projectsRootStore.tabIndex]);

  const errorPage = navigateOrGetNetworkErrorPage(
    errorStore.error,
    navigateTo,
    errorStore.removeError,
  );

  if (errorPage) {
    errorStore.removeError();
    return errorPage;
  }

  const TabElement = observer(
    ({ index }: { index: number }): React.ReactElement => {
      switch (index) {
        case ProjectListTabs.ActiveProjects:
          return (
            <ProjectListPane
              projectsRootStore={projectsRootStore}
              isArchivedProjectList={false}
              t={t}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              navigateTo={navigateTo}
              setIsChildHeightVariable={setIsChildHeightVariable}
              signInStore={signInStore}
            />
          );
        case ProjectListTabs.ArchivedProjects:
          return (
            <ProjectListPane
              projectsRootStore={projectsRootStore}
              isArchivedProjectList={true}
              t={t}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              navigateTo={navigateTo}
              setIsChildHeightVariable={setIsChildHeightVariable}
              signInStore={signInStore}
            />
          );
        default:
          return <></>;
      }
    },
  );

  return (
    <DetailSectionComponent
      isManageUsersLogoVisible={true}
      isAuditLogsLogoVisible={true}
      mainHeaderProps={{
        title: t("projects.projects"),
        actionElement: signInStore.isPrivilegeGranted(
          UserPrivileges.processProject,
        ) ? (
          <Button
            name="createNewProject"
            label={t("projects.createNewProject")}
            icon={<Plus />}
            iconPosition="leading"
            size="medium"
            variant="filled"
            onClick={(): void => {
              projectsRootStore.setIsCreateNewProjectDialogOpen(true);
            }}
          />
        ) : undefined,
        prefersLargeTitle: true,
      }}
      tabsProps={{
        tabs: [
          { label: t("projects.activeProjectsTitle") },
          { label: t("projects.archivedProjectsTitle") },
        ],
        selectedTabIndex: projectsRootStore.tabIndex,
        onTabChange: (index: number): void => {
          setIsChildHeightVariable(false);
          projectsRootStore.setTabIndex(index);
        },
        disableBottomBorder: true,
      }}
      isChildHeightVariable={isChildHeightVariable}
      isOverflowHidden={false}
    >
      <TabElement index={projectsRootStore.tabIndex} />
      <FeatureInDevelopmentSnackbar
        isSnackbarVisible={isSnackbarVisible}
        setIsSnackbarVisible={setIsSnackbarVisible}
      />
    </DetailSectionComponent>
  );
});
