import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  TableReloadHandle,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import {
  ReOpenSurveyLinkDialogState,
  ReOpenSurveyLinkRPCError,
} from "../store/ReOpenSurveyLinkStore";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../routes/RoutesHelper";
import { DialogErrorContent } from "../../../components/DialogErrorContent";
import { ICON_SIZE, getIconProps } from "@pulse/shared-components";
import { DialogSuccessContent } from "../../../components/DialogSuccessContent";
import { Stack, Typography } from "@mui/material";
import { HelpCircle } from "lucide-react";
import { RespondentStore } from "../store/RespondentStore";

interface ReOpenSurveyLinkDialogProps {
  isReOpenSurveyLinkDialogVisible: boolean;
  setIsReOpenSurveyLinkDialogVisible: (
    newIsUnassignDialogVisible: boolean,
  ) => void;
  t: TFunction;
  spacing: Spacing;
  respondentStore: Instance<typeof RespondentStore>;
  navigateTo: NavigateToFunctions;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const ReOpenSurveyLinkDialog = observer(
  ({
    isReOpenSurveyLinkDialogVisible,
    setIsReOpenSurveyLinkDialogVisible,
    t,
    spacing,
    respondentStore,
    navigateTo,
    typography,
    tokens,
    tableRef,
  }: ReOpenSurveyLinkDialogProps): React.ReactElement => {
    const reOpenSurveyLinkStore = respondentStore.reOpenSurveyLinkStore;

    useEffect(() => {
      return () => {
        reOpenSurveyLinkStore.resetStore();
      };
    }, []);

    const getPrimaryButtonText = (): string | undefined => {
      switch (reOpenSurveyLinkStore.reOpenSurveyLinkDialogState) {
        case ReOpenSurveyLinkDialogState.ReOpenSurveyLink: {
          return t("projects.reOpenSurveyLink.reOpenLinks");
        }
        case ReOpenSurveyLinkDialogState.Error:
        case ReOpenSurveyLinkDialogState.Success: {
          return undefined;
        }
      }
    };

    const getSecondaryButtonText = (): string => {
      switch (reOpenSurveyLinkStore.reOpenSurveyLinkDialogState) {
        case ReOpenSurveyLinkDialogState.ReOpenSurveyLink: {
          return t("common.cancel");
        }
        case ReOpenSurveyLinkDialogState.Error: {
          return t("common.close");
        }
        case ReOpenSurveyLinkDialogState.Success:
          return t("common.done");
      }
    };

    const getErrorText = (): string => {
      switch (reOpenSurveyLinkStore.rpcError) {
        case ReOpenSurveyLinkRPCError.ProjectAlreadyArchived: {
          return t(
            "projects.reOpenSurveyLink.projectAlreadyArchivedErrorMessage",
          );
        }
        case ReOpenSurveyLinkRPCError.SurveyIsClosed: {
          return t("projects.reOpenSurveyLink.surveyClosedErrorMessage");
        }
        case ReOpenSurveyLinkRPCError.DeleteColumnFilter:
        case ReOpenSurveyLinkRPCError.InvalidRespondentIdFound: {
          return t("projects.reOpenSurveyLink.unexpectedErrorMessage");
        }
        default: {
          console.error(
            `Error message for error ${reOpenSurveyLinkStore.rpcError} not defined`,
          );
          return t("projects.reOpenSurveyLink.unexpectedErrorMessage");
        }
      }
    };

    const DialogChild = (): React.ReactElement => {
      switch (reOpenSurveyLinkStore.reOpenSurveyLinkDialogState) {
        case ReOpenSurveyLinkDialogState.ReOpenSurveyLink: {
          return (
            <Stack gap={spacing.spaceXS} direction="row">
              <HelpCircle {...getIconProps(tokens.icon, ICON_SIZE.default)} />
              <Typography
                sx={{ ...typography.b2 }}
                color={tokens.label}
                width={"492px"}
              >
                {t("projects.reOpenSurveyLink.dialogDescription")}
              </Typography>
            </Stack>
          );
        }
        case ReOpenSurveyLinkDialogState.Error: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
        case ReOpenSurveyLinkDialogState.Success: {
          return (
            <DialogSuccessContent
              spacing={spacing}
              typography={typography}
              tokens={tokens}
              description={t("projects.reOpenSurveyLink.successMessage")}
            />
          );
        }
      }
    };

    return (
      <Dialog
        title={t("projects.reOpenSurveyLink.reOpenLinks")}
        open={isReOpenSurveyLinkDialogVisible}
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsReOpenSurveyLinkDialogVisible(false);
          if (reOpenSurveyLinkStore.shouldTableReloadOnDialogClose) {
            tableRef.current?.reload();
          }
          reOpenSurveyLinkStore.resetStore();
          respondentStore.multiSelectTableStore.unselectAllRows();
        }}
        isSecondaryButtonDisabled={reOpenSurveyLinkStore.isRPCLoading}
        primaryButtonText={getPrimaryButtonText()}
        contentPadding={spacing.spaceLG}
        disableBackdropClick={true}
        onPrimaryButtonClick={async (): Promise<void> => {
          await processSurveyParams(
            async (surveyId: string, projectId: string): Promise<void> => {
              await reOpenSurveyLinkStore.reOpenSurveyLink(
                projectId,
                surveyId,
                respondentStore.respondentSelection,
              );
            },
            navigateTo,
          );
          respondentStore.clearFiltersAndRowSelection();
        }}
        width="560px"
      >
        <DialogChild />
      </Dialog>
    );
  },
);
