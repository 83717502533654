import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import { SurveyDataModel } from "../../models/SurveyDataModel";
import { TFunction } from "i18next";
import { Stack, Typography } from "@mui/material";
import { DividerComponent } from "../../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";
import {
  PRIMARY_COLORS,
  VIEWED_SURVEY_LINK_COLOR_TOKEN,
  getHundredthDecimalRoundedPercentageString,
} from "@pulse/shared-components";

export interface SurveyLinkBreakdownTableProps {
  t: TFunction;
  surveyDataModel: Instance<typeof SurveyDataModel>;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  spacing: Spacing;
  radius: CornerRadius;
}

export const SurveyLinkBreakdownTable = observer(
  ({
    t,
    surveyDataModel,
    tokens,
    typography,
    spacing,
    radius,
  }: SurveyLinkBreakdownTableProps): React.ReactElement => {
    const SurveyLinkTableRow = ({
      color,
      linkType,
      linkTotal,
      linkPercentage,
    }: {
      color: string;
      linkType: string;
      linkTotal: number;
      linkPercentage: string;
    }): React.ReactElement => {
      return (
        <Stack height="40px" direction="row" justifyContent="space-between">
          <Stack direction="row" gap={spacing.spaceXXS} alignItems="center">
            <Stack
              borderRadius={radius.radiusMax}
              bgcolor={color}
              width="12px"
              height="12px"
            />
            <Typography sx={{ ...typography.b2, color: tokens.label }}>
              {linkType}
            </Typography>
          </Stack>
          <Stack direction="row" gap={spacing.spaceXS} alignItems="center">
            <Typography sx={{ ...typography.b2, color: tokens.label }}>
              {linkTotal}
            </Typography>
            <Stack
              width="72px"
              height="24px"
              alignItems="center"
              justifyContent="center"
              borderRadius={radius.radiusXXS}
              bgcolor={tokens.backgroundSubtle}
            >
              <Typography sx={{ ...typography.b3, color: tokens.label }}>
                {`${linkPercentage}%`}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      );
    };

    return (
      <Stack
        width="100%"
        divider={<DividerComponent width="400px" orientation="horizontal" />}
      >
        <Stack
          height="44px"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
            {t("projects.dashboard.typeText")}
          </Typography>
          <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
            {t("projects.dashboard.volumeText")}
          </Typography>
        </Stack>
        <SurveyLinkTableRow
          linkType={t("surveyLinkStatus.submitted")}
          color={PRIMARY_COLORS[500]}
          linkTotal={surveyDataModel.submittedLinks.volume}
          linkPercentage={getHundredthDecimalRoundedPercentageString(
            surveyDataModel.submittedLinks.percentage,
          )}
        />
        <SurveyLinkTableRow
          linkType={t("surveyLinkStatus.lastPage")}
          color={PRIMARY_COLORS[400]}
          linkTotal={surveyDataModel.lastPageLinks.volume}
          linkPercentage={getHundredthDecimalRoundedPercentageString(
            surveyDataModel.lastPageLinks.percentage,
          )}
        />
        <SurveyLinkTableRow
          linkType={t("surveyLinkStatus.inProgress")}
          color={PRIMARY_COLORS[300]}
          linkTotal={surveyDataModel.inProgressLinks.volume}
          linkPercentage={getHundredthDecimalRoundedPercentageString(
            surveyDataModel.inProgressLinks.percentage,
          )}
        />
        <SurveyLinkTableRow
          linkType={t("surveyLinkStatus.started")}
          color={PRIMARY_COLORS[200]}
          linkTotal={surveyDataModel.startedLinks.volume}
          linkPercentage={getHundredthDecimalRoundedPercentageString(
            surveyDataModel.startedLinks.percentage,
          )}
        />
        <SurveyLinkTableRow
          linkType={t("surveyLinkStatus.viewed")}
          color={VIEWED_SURVEY_LINK_COLOR_TOKEN}
          linkTotal={surveyDataModel.viewedLinks.volume}
          linkPercentage={getHundredthDecimalRoundedPercentageString(
            surveyDataModel.viewedLinks.percentage,
          )}
        />
        <SurveyLinkTableRow
          linkType={t("surveyLinkStatus.notStarted")}
          color={PRIMARY_COLORS[100]}
          linkTotal={surveyDataModel.notStartedLinks.volume}
          linkPercentage={getHundredthDecimalRoundedPercentageString(
            surveyDataModel.notStartedLinks.percentage,
          )}
        />
      </Stack>
    );
  },
);
