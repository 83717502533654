import { Stack, Typography } from "@mui/material";
import {
  EMPTY_CHARACTER,
  OpenEndedFieldModel,
  useBorder,
} from "@pulse/shared-components";
import {
  useCornerRadius,
  useTypography,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { observer } from "mobx-react";
import {
  useOpenEndedFieldStore,
  useQuestionDetailsStore,
} from "../../store/hooks";
import { useTranslation } from "react-i18next";
import { ChoiceListButtons } from "../ChoiceListButtons";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { OpenEndedFieldHintInput } from "./OpenEndedFieldHintInput";
import { OpenEndedFieldTitleInput } from "./OpenEndedFieldTitleInput";
import { OpenEndedFieldCodeInput } from "./OpenEndedFieldCodeInput";

export const initializeNewOpenEndedField = (
  t: TFunction,
): Instance<typeof OpenEndedFieldModel> => {
  return OpenEndedFieldModel.create({
    fieldTitle: EMPTY_CHARACTER,
    fieldHint: t("surveys.addSurveyQuestion.openEnded.hintPlaceholder"),
    code: EMPTY_CHARACTER,
  });
};

export const OpenEndedField = observer(
  ({ index }: { index: number }): React.ReactElement => {
    const borderRadius = useCornerRadius();
    const questionDetailsStore = useQuestionDetailsStore();
    const typography = useTypography();
    const { t } = useTranslation();
    const tokens = useFoundationColorTokens();
    const openEndedFieldStore = useOpenEndedFieldStore();
    const spacing = useSpacing();
    const border = useBorder();

    return (
      <Stack
        key={index}
        style={{
          width: "580px",
          gap: spacing.spaceXL,
          padding: spacing.spaceXL,
          borderRadius: borderRadius.radiusXS,
          backgroundColor: tokens.backgroundSubtle,
        }}
      >
        <Typography
          sx={{
            ...typography.ol1,
            color: tokens.labelSubtle,
          }}
        >
          {t("surveys.addSurveyQuestion.openEnded.entry", {
            index: index + 1,
          })}
        </Typography>
        <Stack gap={spacing.spaceLG}>
          <Stack direction="row" gap={spacing.spaceLG}>
            <OpenEndedFieldTitleInput
              width={undefined}
              value={openEndedFieldStore.openEndedField[index].fieldTitle}
              onTextChange={(inputString: string) => {
                questionDetailsStore.setFieldValidations(null);
                openEndedFieldStore.setFieldTitle(inputString, index);
              }}
              t={t}
            />

            <OpenEndedFieldCodeInput
              width="132px"
              value={openEndedFieldStore.openEndedField[index].code}
              onTextChange={(inputString: string) => {
                questionDetailsStore.setFieldValidations(null);
                openEndedFieldStore.setCode(inputString, index);
              }}
              helperText={
                questionDetailsStore.isOptionCodeNotUnique(
                  openEndedFieldStore.openEndedField[index].code,
                )
                  ? t("surveys.addSurveyQuestion.enterUniqueCode")
                  : EMPTY_CHARACTER
              }
              t={t}
              error={openEndedFieldStore.doesFieldCodeContainErrors(index)}
            />
          </Stack>

          <OpenEndedFieldHintInput
            width="100%"
            value={openEndedFieldStore.openEndedField[index].fieldHint}
            onTextChange={(inputString: string) => {
              questionDetailsStore.setFieldValidations(null);
              openEndedFieldStore.setFieldHint(inputString, index);
            }}
            t={t}
          />
        </Stack>
        <ChoiceListButtons
          paddingTop={spacing.spaceSM}
          border={border}
          spacing={spacing}
          isChevronDownDisabled={openEndedFieldStore.isChevronDownDisabled}
          isAddNewFieldButtonDisabled={
            openEndedFieldStore.isAddNewFieldButtonDisabled
          }
          isChevronUpDisabled={openEndedFieldStore.isChevronUpDisabled}
          isDeleteButtonDisabled={openEndedFieldStore.isDeleteButtonDisabled}
          moveFieldDown={openEndedFieldStore.moveFieldDown}
          moveFieldUp={openEndedFieldStore.moveFieldUp}
          removeField={openEndedFieldStore.removeField}
          index={index}
          addNewFields={() => {
            openEndedFieldStore.addNewField(
              initializeNewOpenEndedField(t),
              index,
            );
          }}
        />
      </Stack>
    );
  },
);
