import { Stack, Typography } from "@mui/material";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React, { PropsWithChildren, ReactElement } from "react";
import { CustomWidthTooltip } from "../modules/surveys/components/CustomWidthToolTip";
import { InfoIcon } from "lucide-react";
import { getIconProps, ICON_SIZE } from "@pulse/shared-components";

interface SettingsContainerProps extends PropsWithChildren {
  settingHeadingText: string;
  settingSubHeadingText?: string;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  tooltipChild?: ReactElement;
}

export const SettingsContainer = observer(
  ({
    settingHeadingText: settingHeadingText,
    settingSubHeadingText: settingSubHeadingText,
    children,
    spacing,
    typography,
    tokens,
    tooltipChild,
  }: SettingsContainerProps): React.ReactElement => {
    return (
      <Stack
        py={spacing.space2XL}
        gap={spacing.space4XL}
        direction="row"
        width="100%"
      >
        <Stack gap={spacing.spaceXXS} width="360px">
          <Typography
            {...typography.s1}
            color={tokens.label}
            width="100%"
            gap={spacing.spaceSM}
            display="flex"
          >
            {settingHeadingText}
            {tooltipChild && (
              <CustomWidthTooltip title={tooltipChild}>
                {
                  <InfoIcon
                    {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.medium)}
                  />
                }
              </CustomWidthTooltip>
            )}
          </Typography>
          <Typography
            {...typography.b2}
            color={tokens.labelSubtle}
            width="100%"
            whiteSpace="pre-wrap"
          >
            {settingSubHeadingText}
          </Typography>
        </Stack>
        {children}
      </Stack>
    );
  },
);
