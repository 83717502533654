import { Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  ICON_SIZE,
  getIconProps,
  EMPTY_LIST_LENGTH,
} from "@pulse/shared-components";
import {
  AutoCompleteInputField,
  EllipsisTypography,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  SearchField,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import React, { useRef, useState } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { ListX } from "lucide-react";
import {
  DialogType,
  SearchSpocFieldProps,
  SelectedSpocListProps,
} from "./AssignAndViewSpocsDialog";
import { SpocUserDetailsModel } from "../../../models/SpocUserDetailsModel";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";
import { SelectedProjectSPoCList } from "./SelectedProjectSPoCList";
import { DeveloperErrorFlow } from "../../../../components/DeveloperErrorFlow";

export interface NoSpocsFoundProps {
  noSpocsFoundContainerPadding: string | undefined;
  noSpocsFoundContainerGap: string | undefined;
  noSpocsFoundText: string;
}

export interface AssignAndViewSpocSelectionProps
  extends NoSpocsFoundProps,
    SelectedSpocListProps,
    SearchSpocFieldProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  projectDetailsStore: Instance<typeof ProjectDetailsStore>;
  t: TFunction;
  border: BorderStyle;
  dialogType: DialogType;
  isSearchProjectSpocFieldDisabled: boolean;
  matchedSpocs:
    | ((searchSpocText: string) => Instance<typeof SpocUserDetailsModel>[])
    | undefined;
  selectedRespondentOrEmbedName: string | undefined;
  isSpocListEmpty: boolean;
}

export const AssignAndViewSpocSelection = observer(
  ({
    spacing,
    tokens,
    typography,
    projectDetailsStore,
    t,
    border,
    dialogType,
    searchSpocFieldGap,
    searchSpocFieldPadding,
    searchSpocFieldDescription,
    noSpocsFoundContainerGap,
    noSpocsFoundContainerPadding,
    isSearchProjectSpocFieldDisabled,
    selectedSpocListPadding,
    selectedSpocListGap,
    selectedSpocElementPadding,
    matchedSpocs,
    noSpocsFoundText,
    searchSpocFieldTitle,
    selectedRespondentOrEmbedName,
    isSpocListEmpty,
  }: AssignAndViewSpocSelectionProps): React.ReactElement => {
    const [searchSpocText, setSearchSpocText] =
      useState<string>(EMPTY_CHARACTER);
    const isSearchSpocFieldCleared = useRef(false);
    const isAssignSpocsDialog = dialogType === DialogType.AssignSpocs;

    const SpocList = observer(
      ({
        isSelectedSpocListEmpty,
        selectedSpocs,
      }: {
        isSelectedSpocListEmpty: boolean;
        selectedSpocs: Instance<typeof SpocUserDetailsModel>[];
      }): React.ReactElement => {
        if (isSpocListEmpty) {
          return (
            <Stack
              padding={noSpocsFoundContainerPadding}
              gap={noSpocsFoundContainerGap}
            >
              <Stack
                padding={spacing.spaceXL}
                gap={spacing.spaceXS}
                alignItems="center"
              >
                <ListX
                  {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
                />
                <Typography {...typography.b1} color={tokens.labelLowEmphasis}>
                  {noSpocsFoundText}
                </Typography>
              </Stack>
            </Stack>
          );
        } else if (isSelectedSpocListEmpty) {
          return (
            <Stack padding={selectedSpocElementPadding} gap={spacing.spaceSM}>
              <Typography {...typography.b2} color={tokens.labelLowEmphasis}>
                {isAssignSpocsDialog
                  ? t("projects.assignSpocsToRespondents.noSpocSelectedText")
                  : t("projects.viewSpocs.noSpocsFoundText")}
              </Typography>
            </Stack>
          );
        } else {
          return (
            <Stack padding={selectedSpocElementPadding} gap={spacing.spaceSM}>
              {selectedSpocs.map((selectedSpoc) => {
                return (
                  <SelectedProjectSPoCList
                    key={selectedSpoc.spocId}
                    spocName={selectedSpoc.userName.firstAndLastName}
                    spocEmailId={selectedSpoc.emailId}
                    spocId={selectedSpoc.spocId}
                    isRemoveSpocButtonVisible={isAssignSpocsDialog}
                    projectDetailsStore={projectDetailsStore}
                    spacing={spacing}
                    typography={typography}
                    tokens={tokens}
                  />
                );
              })}
            </Stack>
          );
        }
      },
    );

    return (
      <Stack width="100%">
        <Stack
          padding={searchSpocFieldPadding}
          gap={searchSpocFieldGap}
          borderBottom={!isAssignSpocsDialog ? border.default : undefined}
        >
          {searchSpocFieldDescription && (
            <Typography {...typography.b2} color={tokens.labelSubtle}>
              {searchSpocFieldDescription}
            </Typography>
          )}
          {isAssignSpocsDialog ? (
            <AutoCompleteInputField
              inputValue={searchSpocText}
              disabled={isSearchProjectSpocFieldDisabled}
              placeholder={t(
                "projects.assignSpocsToRespondents.searchSpocsPlaceholderText",
              )}
              label={EMPTY_CHARACTER}
              options={projectDetailsStore.nonSelectedProjectSpocs.map(
                (projectSpoc) => {
                  return {
                    id: projectSpoc.spocId,
                    label: projectSpoc.searchSpocDropdownText,
                  };
                },
              )}
              onInputChange={(inputText) => {
                if (isSearchSpocFieldCleared.current) {
                  isSearchSpocFieldCleared.current = false;
                  setSearchSpocText(EMPTY_CHARACTER);
                  return;
                }
                setSearchSpocText(inputText ? inputText : EMPTY_CHARACTER);
              }}
              onChange={(selectedItem) => {
                isSearchSpocFieldCleared.current = true;
                setSearchSpocText(EMPTY_CHARACTER);
                // This gets triggered when the user unselects an option, as in this case `selectedItemType` is undefined.
                if (selectedItem === null || selectedItem?.id === null) {
                  console.warn(`SPoC could not be found for ${selectedItem}`);
                  return;
                }
                projectDetailsStore.addSelectedSpocId(selectedItem.id);
              }}
            />
          ) : projectDetailsStore.respondentStore ? (
            <>
              <Stack gap={spacing.spaceXXS} direction="row" width="100%">
                <Typography
                  {...typography.s1}
                  color={tokens.label}
                  width="fit-content"
                  whiteSpace="nowrap"
                >
                  {searchSpocFieldTitle}
                </Typography>
                <EllipsisTypography
                  typography={typography.b1}
                  textColor={"label"}
                >
                  {selectedRespondentOrEmbedName ?? EMPTY_CHARACTER}
                </EllipsisTypography>
              </Stack>
              <SearchField
                name="searchSpocField"
                allowClearField={false}
                // This is not needed but is mandatory by the component.
                onClear={() => {}}
                value={searchSpocText}
                disabled={isSearchProjectSpocFieldDisabled}
                placeholder={t(
                  "projects.assignSpocsToRespondents.searchSpocsPlaceholderText",
                )}
                onTextChange={(inputText) => {
                  setSearchSpocText(inputText);
                }}
              />
            </>
          ) : (
            <DeveloperErrorFlow />
          )}
        </Stack>
        <Stack padding={selectedSpocListPadding} gap={selectedSpocListGap}>
          {isAssignSpocsDialog &&
            !projectDetailsStore.isProjectSpocListEmpty && (
              <Stack
                padding={`${spacing.spaceXS} 0px`}
                borderBottom={border.default}
              >
                <Typography {...typography.sh3} color={tokens.label}>
                  {t(
                    "projects.assignSpocsToRespondents.selectedSpocsTitleText",
                  )}
                </Typography>
              </Stack>
            )}
          {isAssignSpocsDialog ? (
            <SpocList
              isSelectedSpocListEmpty={
                projectDetailsStore.isSelectedSpocListEmpty
              }
              selectedSpocs={projectDetailsStore.selectedSpocs}
            />
          ) : projectDetailsStore.respondentStore && matchedSpocs ? (
            <SpocList
              isSelectedSpocListEmpty={
                matchedSpocs(searchSpocText)?.length === EMPTY_LIST_LENGTH
              }
              selectedSpocs={matchedSpocs(searchSpocText)}
            />
          ) : (
            <DeveloperErrorFlow />
          )}
        </Stack>
      </Stack>
    );
  },
);
