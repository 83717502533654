import { Instance, cast, flow, getRoot, types } from "mobx-state-tree";
import {
  AddBOUserDialogState,
  AddBOUserStore,
  createAddBOUserStore,
} from "./AddBOUserStore";
import { EditBOUserStore, createEditBOUserStore } from "./EditBOUserStore";
import {
  AutoCompleteItemModel,
  createAutoCompleteItemModel,
} from "../../models/AutoCompleteItemModel";
import { getAPIClient } from "../../networking/APIClient";
import { GetBOUserRolesRPC } from "@pulse/pulse-rpcs";
import { useGetBOUserRolesRPCClientImpl } from "../rpcs/RPC";
import { RootStore } from "../../root/store/RootStore";
import {
  createSendPasswordResetLinkArchiveBOUserStore,
  SendPasswordResetLinkArchiveBOUserStore,
} from "./SendPasswordResetLinkArchiveBOUserStore";

enum GetBOUserRolesRPCError {
  UnableToFetchRoles = "UNABLE_TO_FETCH_ROLES",
}

export const BOUserStore = types
  .model("BOUserStore", {
    addBOUserStore: AddBOUserStore,
    editBOUserStore: EditBOUserStore,
    sendPasswordResetLinkStore: SendPasswordResetLinkArchiveBOUserStore,
    roles: types.array(AutoCompleteItemModel),
  })
  .actions((store) => ({
    getRoles: flow(function* () {
      store.addBOUserStore.setDialogState(AddBOUserDialogState.FetchingRoles);
      try {
        const apiClient = getAPIClient(store);
        const request = new GetBOUserRolesRPC.Request();
        const {
          response,
          error,
        }: {
          response?: GetBOUserRolesRPC.Response;
          error?: GetBOUserRolesRPC.Errors.Errors;
        } = yield useGetBOUserRolesRPCClientImpl(apiClient).execute(request);
        if (response) {
          store.roles = cast(
            response.roles.map((role) => {
              return createAutoCompleteItemModel(role.id.uuid, role.role);
            }),
          );
          store.addBOUserStore.setDialogState(AddBOUserDialogState.AddBOUser);
        } else if (error) {
          store.addBOUserStore.setDialogState(AddBOUserDialogState.Error);
          switch (error.code) {
            case GetBOUserRolesRPCError.UnableToFetchRoles:
              break;
            default:
              console.error(`Unhandled RPCError ${error.code}`);
              break;
          }
        }
      } catch (e) {
        store.addBOUserStore.setDialogState(AddBOUserDialogState.Error);
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
  }));

export const createBOUserStore = (): Instance<typeof BOUserStore> => {
  return BOUserStore.create({
    addBOUserStore: createAddBOUserStore(),
    editBOUserStore: createEditBOUserStore(),
    sendPasswordResetLinkStore: createSendPasswordResetLinkArchiveBOUserStore(),
  });
};
