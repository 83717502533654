import React from "react";
import { RespondentList } from "./RespondentList";
import { SurveyLinkStatus } from "@pulse/pulse-rpcs";
import { FilterType } from "../../../components/filter/model/FilterModel";
import { FilterComponent } from "../../../components/filter/components/FilterComponent";
import { Stack } from "@mui/material";
import {
  FoundationColorTokens,
  LoadingIndicator,
  Spacing,
  TableReloadHandle,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import { RespondentStore } from "../store/RespondentStore";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

export interface RespondentsPaneProps {
  respondentStore: Instance<typeof RespondentStore>;
  isSurveyFilterShown: boolean;
  surveyFilterName: string | undefined;
  t: TFunction;
  spacing: Spacing;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export enum RespondentFilters {
  Id = "ID",
  FirstName = "First Name",
  LastName = "Last Name",
  EmailAddress = "Email Address",
  PhoneNumber = "Phone Number",
  Survey = "Survey",
  SurveyStatus = "Survey Status",
  CreatedAt = "Created At",
  ModifiedOn = "Modified On",
}

export const RespondentsPane = observer(
  ({
    respondentStore,
    isSurveyFilterShown,
    surveyFilterName,
    spacing,
    t,
    tableRef,
  }: RespondentsPaneProps): React.ReactElement => {
    const filterList: { key: string; label: string }[] = [
      {
        key: RespondentFilters.Id,
        label: t("projects.respondentList.columnNames.id"),
      },
      {
        key: RespondentFilters.FirstName,
        label: t("projects.respondentList.columnNames.firstName"),
      },
      {
        key: RespondentFilters.LastName,
        label: t("projects.respondentList.columnNames.lastName"),
      },
      {
        key: RespondentFilters.EmailAddress,
        label: t("projects.respondentList.columnNames.emailAddress"),
      },
      {
        key: RespondentFilters.PhoneNumber,
        label: t("projects.respondentList.columnNames.phoneNumber"),
      },
      {
        key: RespondentFilters.SurveyStatus,
        label: t("projects.respondentList.columnNames.surveyStatus"),
      },
    ];
    if (isSurveyFilterShown) {
      // Here the survey filter is being added to the 5th index as seen in the respondent columns.
      filterList.splice(5, 0, {
        key: RespondentFilters.Survey,
        label: t("projects.respondentList.columnNames.survey"),
      });
    }
    respondentStore.respondentColumns.map((respondentColumn) => {
      filterList.push({
        key: respondentColumn.name,
        label: respondentColumn.name,
      });
    });

    if (
      respondentStore.isRPCLoading &&
      !respondentStore.isGetRespondentDetailsRPCCalled
    ) {
      return (
        <LoadingIndicator
          isLoading={
            respondentStore.isRPCLoading &&
            !respondentStore.isGetRespondentDetailsRPCCalled
          }
          variant="overlay"
        />
      );
    }

    return (
      <Stack
        padding={spacing.spaceXL}
        gap={spacing.spaceLG}
        height="100%"
        minWidth="1570px"
        maxWidth="100%"
        margin="auto"
      >
        {respondentStore.isPaginatedListVisible && (
          <Stack width="100%">
            <FilterComponent
              isDisabled={
                respondentStore.isRPCLoading ||
                respondentStore.doesRespondentStoreContainError
              }
              store={respondentStore.filterStore}
              onApplyFiltersClick={async () => {
                respondentStore.respondentUIStore.setIsApplyFiltersClicked(
                  true,
                );
                tableRef.current?.reload();
              }}
              onRemoveFiltersClick={() => {
                respondentStore.respondentUIStore.setIsApplyFiltersClicked(
                  false,
                );
                tableRef.current?.reload();
              }}
              filters={filterList.map((column) => {
                if (column.key === RespondentFilters.Survey) {
                  return {
                    ...column,
                    valueType: FilterType.MultiSelect,
                    values: respondentStore.surveyList.map((survey) => {
                      return {
                        id: survey.surveyName,
                        label: survey.surveyName,
                      };
                    }),
                  };
                }
                if (column.key === RespondentFilters.SurveyStatus) {
                  return {
                    ...column,
                    valueType: FilterType.MultiSelect,
                    values: [
                      {
                        id: SurveyLinkStatus.SurveyLinkStatus.SUBMITTED,
                        label: t("surveyLinkStatus.submitted"),
                      },
                      {
                        id: SurveyLinkStatus.SurveyLinkStatus.NOT_ASSIGNED,
                        label: t("surveyLinkStatus.unassigned"),
                      },
                      {
                        id: SurveyLinkStatus.SurveyLinkStatus.NOT_STARTED,
                        label: t("surveyLinkStatus.notStarted"),
                      },
                      {
                        id: SurveyLinkStatus.SurveyLinkStatus.VIEWED,
                        label: t("surveyLinkStatus.viewed"),
                      },
                      {
                        id: SurveyLinkStatus.SurveyLinkStatus.STARTED,
                        label: t("surveyLinkStatus.started"),
                      },
                      {
                        id: SurveyLinkStatus.SurveyLinkStatus.LAST_PAGE,
                        label: t("surveyLinkStatus.lastPage"),
                      },
                      {
                        id: SurveyLinkStatus.SurveyLinkStatus.IN_PROGRESS,
                        label: t("surveyLinkStatus.inProgress"),
                      },
                    ],
                  };
                } else {
                  return {
                    ...column,
                    valueType: FilterType.OpenEnded,
                  };
                }
              })}
            />
          </Stack>
        )}
        <RespondentList
          respondentStore={respondentStore}
          surveyFilterName={surveyFilterName}
          tableRef={tableRef}
        />
      </Stack>
    );
  },
);
