import {
  Copy,
  FoundationColorTokens,
  IconButton,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { CloneCreateNewProjectDialog } from "./CloneCreateNewProjectDialog";
import { TFunction } from "i18next";
import { ProjectsRootStore } from "../../store/ProjectsRootStore";
import { Instance } from "mobx-state-tree";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { Box } from "@mui/material";

interface CloneProjectProps {
  projectName: string;
  projectId: string;
  t: TFunction;
  spacing: Spacing;
  typography: Typography;
  projectsRootStore: Instance<typeof ProjectsRootStore>;
  navigateTo: NavigateToFunctions;
  tokens: FoundationColorTokens<string>;
}

export const CloneProject = ({
  navigateTo,
  projectName,
  projectsRootStore,
  spacing,
  t,
  typography,
  tokens,
  projectId,
}: CloneProjectProps): React.ReactElement => {
  const [isCloneProjectDialogVisible, setIsCloneProjectDialogVisible] =
    useState(false);

  return (
    <Box onClick={(event) => event.stopPropagation()}>
      {isCloneProjectDialogVisible && (
        <CloneCreateNewProjectDialog
          isCloneCreateNewProjectDialogOpen={isCloneProjectDialogVisible}
          t={t}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          setIsCloneCreateNewProjectDialogOpen={setIsCloneProjectDialogVisible}
          projectsRootStore={projectsRootStore}
          navigateTo={navigateTo}
          cloneProjectDialogProps={{
            projectId,
            projectName,
          }}
        />
      )}
      <IconButton
        icon={<Copy />}
        color="primary"
        name="cloneButton"
        variant="plain-neutral"
        size="small"
        onClick={() => {
          setIsCloneProjectDialogVisible(true);
        }}
      />
    </Box>
  );
};
