import {
  FoundationColorTokens,
  IconButton,
  Spacing,
  TableReloadHandle,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { KeySquare } from "lucide-react";
import React, { useState } from "react";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { SendPasswordResetLinkDialog } from "./SendPasswordResetLinkDialog";
import { SendPasswordResetLinkArchiveBOUserStore } from "../store/SendPasswordResetLinkArchiveBOUserStore";

interface SendPasswordResetLinkProps {
  t: TFunction;
  spacing: Spacing;
  sendPasswordResetLinkArchiveBOUserStore: Instance<
    typeof SendPasswordResetLinkArchiveBOUserStore
  >;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  boUserId: string;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const SendPasswordResetLink = ({
  boUserId,
  sendPasswordResetLinkArchiveBOUserStore,
  spacing,
  t,
  tokens,
  typography,
  tableRef,
}: SendPasswordResetLinkProps): React.ReactElement => {
  const [
    isSendPasswordResetLinkDialogVisible,
    setIsSendPasswordResetLinkDialogVisible,
  ] = useState(false);

  return (
    <>
      {isSendPasswordResetLinkDialogVisible && (
        <SendPasswordResetLinkDialog
          isSendPasswordResetLinkDialogOpen={
            isSendPasswordResetLinkDialogVisible
          }
          setIsSendPasswordResetLinkDialogOpen={
            setIsSendPasswordResetLinkDialogVisible
          }
          t={t}
          spacing={spacing}
          sendPasswordResetLinkArchiveBOUserStore={
            sendPasswordResetLinkArchiveBOUserStore
          }
          typography={typography}
          tokens={tokens}
          boUserId={boUserId}
          tableRef={tableRef}
        />
      )}
      <IconButton
        icon={<KeySquare />}
        name="resetPassword"
        variant="plain-neutral"
        size="small"
        onClick={() => {
          setIsSendPasswordResetLinkDialogVisible(true);
        }}
      />
    </>
  );
};
