import {
  useFoundationColorTokens,
  useTypography,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSignInStore } from "../../store/Hooks";
import { observer } from "mobx-react";
import { useAuditLogsStore } from "../store/Hooks";
import { DetailSectionComponent } from "../../../components/DetailSectionComponent";
import { AuditLogsList } from "../components/AuditLogsList";
import { navigateOrGetNetworkErrorPage } from "../../../utility/navigateOrGetNetworkErrorPage";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { useRouteNavigator } from "../../../routes/RoutesHelper";

export enum AuditLogsFilterNames {
  EmailAddress = "Email Address",
  EventName = "Event Name",
  DateRange = "Date Range",
}

export const AuditLogsPage = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const spacing = useSpacing();
  const signInStore = useSignInStore();
  const auditLogsStore = useAuditLogsStore();
  const errorStore = useNetworkingErrorStore();
  const navigateTo = useRouteNavigator();

  useEffect(() => {
    auditLogsStore.addPastSevenDaysDateRangeFilter();
    return () => {
      auditLogsStore.resetStore();
    };
  }, []);

  const errorPage = navigateOrGetNetworkErrorPage(
    errorStore.error,
    navigateTo,
    errorStore.removeError,
  );

  if (errorPage) {
    errorStore.removeError();
    return errorPage;
  }

  return (
    <DetailSectionComponent
      mainHeaderProps={{
        title: t("common.auditLogs.title"),
        prefersLargeTitle: true,
        backButtonCallback: (): void => {
          navigateTo.project();
        },
      }}
      isOverflowHidden={false}
      isChildHeightVariable={
        auditLogsStore.doesStoreContainErrors ? false : true
      }
      isManageUsersLogoVisible={true}
      isAuditLogsLogoVisible={false}
    >
      <AuditLogsList
        t={t}
        tokens={tokens}
        typography={typography}
        spacing={spacing}
        signInStore={signInStore}
        auditLogsStore={auditLogsStore}
      />
    </DetailSectionComponent>
  );
});
