import { Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  MAX_SUBMISSION_DATA_SET_SIZE,
} from "@pulse/shared-components";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController,
  ScaleOptionsByType,
  CartesianScaleTypeRegistry,
  Filler,
} from "chart.js";
import { Instance } from "mobx-state-tree";

import { TFunction } from "i18next";
import { BarChart } from "./BarChart";
import { BarAndLineChart } from "./BarAndLineChart";
import { MenuButton } from "./MenuButton";
import { LineChart } from "./LineChart";
import { EmptyListPane } from "../../../surveys/components/EmptyListPane";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import {
  SubmissionDataGraphType,
  SurveyDataModel,
} from "../../models/SurveyDataModel";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Filler,
);

export const scales:
  | _DeepPartialObject<{
      [key: string]: ScaleOptionsByType<keyof CartesianScaleTypeRegistry>;
    }>
  | undefined = {
  xAxis: {
    grid: {
      display: false,
    },
    ticks: {
      maxTicksLimit: 12,
      minRotation: 50,
    },
  },
  yAxis: {
    ticks: {
      precision: 0,
    },
  },
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      cornerRadius: 8,
      displayColors: false,
    },
  },
  datasets: {
    bar: {
      barPercentage: 0.9,
      borderRadius: 2,
      maxBarThickness: 32,
    },
  },
};

export interface SurveySubmissionDataPaneProps {
  border: BorderStyle;
  borderRadius: CornerRadius;
  spacing: Spacing;
  surveyDataModel: Instance<typeof SurveyDataModel> | null;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  t: TFunction;
}

export const SurveySubmissionDataPane = observer(
  ({
    border,
    borderRadius,
    spacing,
    tokens,
    surveyDataModel,
    typography,
    t,
  }: SurveySubmissionDataPaneProps): React.ReactElement => {
    const labels = surveyDataModel?.submissionData.map((value) =>
      value.submissionDate.toDateString().slice(4, 10),
    );

    const SubmissionDataChart = (): React.ReactElement => {
      if (
        surveyDataModel === null ||
        surveyDataModel.isNoSubmissionDataPresent
      ) {
        return (
          <EmptyListPane
            headingText={t("projects.dashboard.noDataTitle")}
            subHeadingText={t("projects.dashboard.noSurveyResultsDescription")}
          />
        );
      }
      switch (surveyDataModel.submissionDataGraphType) {
        case SubmissionDataGraphType.Both: {
          return (
            <BarAndLineChart
              labels={labels ?? []}
              dailySubmissionData={surveyDataModel.submissionData.map(
                (value) => value.dailySubmissions,
              )}
              totalSubmissionData={surveyDataModel.submissionData.map(
                (value) => value.totalSubmissions,
              )}
              typography={typography}
              t={t}
            />
          );
        }
        case SubmissionDataGraphType.DailySubmissions: {
          return (
            <BarChart
              labels={labels ?? []}
              submissionData={surveyDataModel.submissionData.map(
                (value) => value.dailySubmissions,
              )}
              t={t}
            />
          );
        }
        case SubmissionDataGraphType.TotalSubmissions: {
          return (
            <LineChart
              labels={labels ?? []}
              totalSubmissionData={surveyDataModel.submissionData.map(
                (value) => value.totalSubmissions,
              )}
              t={t}
              lineChartCustomisation={{
                isColorGradientRequired: false,
                isTickRotationRequired: true,
              }}
            />
          );
        }
      }
    };

    return (
      <Stack width="100%" gap={spacing.spaceXL} height="492px">
        <Stack
          direction="row"
          padding={`${spacing.spaceXL} ${spacing.space2XL}`}
          justifyContent="space-between"
        >
          <Typography sx={{ ...typography.sh1, color: tokens.label }}>
            {t("projects.dashboard.submissionDataTitle")}
          </Typography>
          <MenuButton
            label={
              surveyDataModel?.dropDownMenuTextFromType ??
              t("projects.dashboard.bothText")
            }
            disabled={
              surveyDataModel ? surveyDataModel.isNoSubmissionDataPresent : true
            }
            typography={typography}
            colorPalette={tokens}
            spacing={spacing}
            cornerRadius={borderRadius}
            border={border}
            menuItems={[
              {
                label: t("projects.dashboard.bothText"),
                onClick: (): void => {
                  surveyDataModel?.setSubmissionDataGraphType(
                    SubmissionDataGraphType.Both,
                  );
                },
              },
              {
                label: t("projects.dashboard.dailySubmissionsText"),
                onClick: (): void => {
                  surveyDataModel?.setSubmissionDataGraphType(
                    SubmissionDataGraphType.DailySubmissions,
                  );
                },
              },
              {
                label: t("projects.dashboard.totalSubmissionsText"),
                onClick: (): void => {
                  surveyDataModel?.setSubmissionDataGraphType(
                    SubmissionDataGraphType.TotalSubmissions,
                  );
                },
              },
            ]}
            menuFullWidth
          />
        </Stack>
        {surveyDataModel?.hasMaxDataLimitReached && (
          <Stack direction="row" justifyContent="center" gap={spacing.spaceXXS}>
            <Typography sx={{ ...typography.s2, color: tokens.labelSubtle }}>
              {surveyDataModel?.minAndMaxDateString}
            </Typography>
            <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
              {t("projects.dashboard.lastDataSetsText", {
                days: MAX_SUBMISSION_DATA_SET_SIZE,
              })}
            </Typography>
          </Stack>
        )}

        <Stack
          height="300px"
          padding={`${spacing.spaceXL} ${spacing.space2XL}`}
          gap={spacing.spaceMD}
          alignItems="center"
        >
          <SubmissionDataChart />
          {!surveyDataModel?.isBothSubmissionDataGraphType && (
            <Typography sx={{ ...typography.s2, color: tokens.label }}>
              {surveyDataModel?.dropDownMenuTextFromType}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  },
);
