import React from "react";

export const UsersIcon = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C6.34315 6 5 7.34315 5 9C5 10.6569 6.34315 12 8 12C9.65685 12 11 10.6569 11 9C11 7.34315 9.65685 6 8 6ZM11.2497 12.8C12.321 11.883 13 10.5208 13 9C13 6.23858 10.7614 4 8 4C5.23858 4 3 6.23858 3 9C3 10.5208 3.67896 11.883 4.75029 12.8C4.12928 13.1255 3.55517 13.5453 3.05025 14.0503C1.7375 15.363 1 17.1435 1 19C1 19.5523 1.44772 20 2 20C2.55228 20 3 19.5523 3 19C3 17.6739 3.52678 16.4021 4.46447 15.4645C5.40215 14.5268 6.67392 14 8 14C9.32608 14 10.5979 14.5268 11.5355 15.4645C12.4732 16.4021 13 17.6739 13 19C13 19.5523 13.4477 20 14 20C14.5523 20 15 19.5523 15 19C15 17.1435 14.2625 15.363 12.9497 14.0503C12.4448 13.5453 11.8707 13.1255 11.2497 12.8ZM15 5C15 4.44772 15.4477 4 16 4C17.3261 4 18.5979 4.52678 19.5355 5.46447C20.4732 6.40215 21 7.67392 21 9C21 10.3261 20.4732 11.5979 19.5355 12.5355C19.4434 12.6277 19.348 12.7159 19.2496 12.8C19.8707 13.1255 20.4448 13.5453 20.9497 14.0503C22.2625 15.363 23 17.1435 23 19C23 19.5523 22.5523 20 22 20C21.4477 20 21 19.5523 21 19C21 17.6739 20.4732 16.4021 19.5355 15.4645C18.5979 14.5268 17.3261 14 16 14C15.4477 14 15 13.5523 15 13C15 12.4477 15.4477 12 16 12C16.7956 12 17.5587 11.6839 18.1213 11.1213C18.6839 10.5587 19 9.79565 19 9C19 8.20435 18.6839 7.44129 18.1213 6.87868C17.5587 6.31607 16.7956 6 16 6C15.4477 6 15 5.55228 15 5Z"
        fill="currentColor"
      />
    </svg>
  );
};
