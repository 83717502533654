import { FileAttributes, InsertDocumentRecordRPC } from "@pulse/pulse-rpcs";
import { useInsertDocumentRecordRPCClientImpl } from "../projects/rpcs/RPC";
import { APIClient } from "@surya-digital/tedwig";

/**
 * Downloads the file using the URL and the file name provided.
 *
 * @param url - The download link for the file.
 * @param fileName - The name of the file to be set on download.
 */
export const downloadFile = async (
  url: string,
  fileName: string,
): Promise<void> => {
  await fetch(url)
    .catch((error) => {
      throw new Error(
        "Fetching the URL failed while trying to download the file." + error,
      );
    })
    .then((response) => response.blob())
    .then((responseBlob) => {
      const blob = new Blob([responseBlob]);
      const downloadURL = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.download = fileName;
      link.href = downloadURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const insertDocumentRecord = async (
  fileAttributes: FileAttributes,
  apiClient: APIClient,
): Promise<string | undefined> => {
  const request = new InsertDocumentRecordRPC.Request(fileAttributes);
  const {
    response,
    error,
  }: {
    response?: InsertDocumentRecordRPC.Response;
    error?: InsertDocumentRecordRPC.Errors.Errors;
  } = await useInsertDocumentRecordRPCClientImpl(apiClient).execute(request);
  if (response) {
    return response.documentId.uuid;
  } else if (error) {
    console.error(`${error} occured while inserting document record.`);
  }
};
