import React from "react";
import { observer } from "mobx-react";
import { Stack } from "@mui/material";
import { Button, Spacing } from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";

interface ResetFieldsButtonProps {
  isResetDisabled: boolean;
  setIsResetDialogOpen: (value: React.SetStateAction<boolean>) => void;
  t: TFunction;
  spacing: Spacing;
}

export const ResetFieldsButton = observer(
  ({
    isResetDisabled,
    setIsResetDialogOpen,
    t,
    spacing,
  }: ResetFieldsButtonProps): React.ReactElement => {
    return (
      <Stack maxWidth="fit-content" mt={spacing.spaceXL}>
        <Button
          name="resetFields"
          label={t("surveys.addSurveyQuestion.reset")}
          variant="outlined-color"
          size="small"
          color="destructive"
          disabled={isResetDisabled}
          onClick={(): void => {
            setIsResetDialogOpen(true);
          }}
        />
      </Stack>
    );
  },
);
