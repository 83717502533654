import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { NetworkingError, RootContainer } from "@pulse/shared-components";
import { useRootStore } from "../store/RootStore";
import { Box, CircularProgress, styled } from "@mui/material";
import { useFoundationColorTokens } from "@surya-digital/leo-reactjs-material-ui";
import { useRouteNavigator } from "../../../routes/RoutesHelper";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { useKeycloak } from "keycloak-react-web";

export const RootPage = observer((): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const navigateTo = useRouteNavigator();
  const store = useRootStore();
  const errorStore = useNetworkingErrorStore();
  const { keycloak } = useKeycloak();

  const handleInitialStartState = async (): Promise<void> => {
    if (keycloak.authenticated && !errorStore.isAuthError) {
      navigateTo.project();
    } else {
      errorStore.removeError();
      store.signOutUser();
      navigateTo.signIn();
    }
  };

  const signOutUser = async (): Promise<void> => {
    store.signOutUser();
    errorStore.removeError();
    await keycloak.logout({
      redirectUri: `${window.location.protocol}//${window.location.host}`,
    });
  };

  useEffect(() => {
    if (errorStore.error) {
      if (errorStore.isAuthError) {
        signOutUser();
      } else {
        switch (errorStore.error) {
          case NetworkingError.PageNotFound:
            errorStore.removeError();
            navigateTo.pageNotFound();
            break;
          default:
            errorStore.removeError();
            navigateTo.internalServerError();
        }
      }
    }
  }, [errorStore.error]);

  useEffect(() => {
    handleInitialStartState();
  }, []);

  const StyledCenterContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }));

  return (
    <RootContainer>
      <StyledCenterContainer>
        <CircularProgress
          sx={{
            color: tokens.backgroundPrimary,
            height: "32px",
            width: "32px",
          }}
        />
      </StyledCenterContainer>
    </RootContainer>
  );
});
