import { Instance, types } from "mobx-state-tree";
import {
  SurveyLinkBreakdownModel,
  createSurveyLinkBreakdownModel,
} from "./SurveyBreakdownModel";
import {
  SubmissionDataModel,
  createSubmissionDataModel,
} from "./SubmissionDataModel";
import { SurveyLinkBreakdown, SurveySubmissionData } from "@pulse/pulse-rpcs";
import i18next from "i18next";
import {
  MAX_SUBMISSION_DATA_SET_SIZE,
  getFormattedDateString,
} from "@pulse/shared-components";

export enum SubmissionDataGraphType {
  DailySubmissions = "DAILY_SUBMISSIONS",
  TotalSubmissions = "TOTAL_SUBMISSIONS",
  Both = "BOTH",
}

export const SurveyDataModel = types
  .model("SurveyDataModel", {
    totalLinks: types.number,
    submittedLinks: SurveyLinkBreakdownModel,
    lastPageLinks: SurveyLinkBreakdownModel,
    inProgressLinks: SurveyLinkBreakdownModel,
    startedLinks: SurveyLinkBreakdownModel,
    viewedLinks: SurveyLinkBreakdownModel,
    notStartedLinks: SurveyLinkBreakdownModel,
    submissionDataGraphType: types.optional(
      types.enumeration(Object.values(SubmissionDataGraphType)),
      SubmissionDataGraphType.Both,
    ),
    submissionData: types.array(SubmissionDataModel),
  })
  .views((store) => ({
    get dropDownMenuTextFromType(): string {
      switch (store.submissionDataGraphType) {
        case SubmissionDataGraphType.Both: {
          return i18next.t("projects.dashboard.bothText");
        }
        case SubmissionDataGraphType.DailySubmissions: {
          return i18next.t("projects.dashboard.dailySubmissionsText");
        }
        case SubmissionDataGraphType.TotalSubmissions: {
          return i18next.t("projects.dashboard.totalSubmissionsText");
        }
      }
    },
    get isBothSubmissionDataGraphType(): boolean {
      return store.submissionDataGraphType === SubmissionDataGraphType.Both;
    },
    get hasMaxDataLimitReached(): boolean {
      return store.submissionData.length === MAX_SUBMISSION_DATA_SET_SIZE;
    },
    get minAndMaxDateString(): string {
      return (
        getFormattedDateString(store.submissionData[0].submissionDate) +
        " - " +
        getFormattedDateString(
          store.submissionData[store.submissionData.length - 1].submissionDate,
        )
      );
    },
    get isNoSubmissionDataPresent(): boolean {
      return store.submissionData.length === 0;
    },
  }))
  .actions((store) => ({
    setSubmissionDataGraphType: (
      submissionDataGraphType: SubmissionDataGraphType,
    ): void => {
      store.submissionDataGraphType = submissionDataGraphType;
    },
  }));

export const createSurveyDataModel = (
  surveyLinkBreakdown: SurveyLinkBreakdown,
  submissionData: SurveySubmissionData[],
): Instance<typeof SurveyDataModel> => {
  return SurveyDataModel.create({
    totalLinks: surveyLinkBreakdown.totalLinks,
    submittedLinks: createSurveyLinkBreakdownModel(
      surveyLinkBreakdown.submittedLink.volume,
      surveyLinkBreakdown.submittedLink.percentage,
    ),
    lastPageLinks: createSurveyLinkBreakdownModel(
      surveyLinkBreakdown.lastPageLink.volume,
      surveyLinkBreakdown.lastPageLink.percentage,
    ),
    inProgressLinks: createSurveyLinkBreakdownModel(
      surveyLinkBreakdown.inProgressLink.volume,
      surveyLinkBreakdown.inProgressLink.percentage,
    ),
    startedLinks: createSurveyLinkBreakdownModel(
      surveyLinkBreakdown.startedLink.volume,
      surveyLinkBreakdown.startedLink.percentage,
    ),
    viewedLinks: createSurveyLinkBreakdownModel(
      surveyLinkBreakdown.viewedLink.volume,
      surveyLinkBreakdown.viewedLink.percentage,
    ),
    notStartedLinks: createSurveyLinkBreakdownModel(
      surveyLinkBreakdown.notStartedLink.volume,
      surveyLinkBreakdown.notStartedLink.percentage,
    ),
    submissionData: submissionData.map((data) => {
      return createSubmissionDataModel(
        new Date(data.date.timestamp),
        data.dailySubmissions,
        data.totalSubmissions,
      );
    }),
  });
};
