import {
  Chip,
  ChipProps,
  ContentCellOptions,
  Dialog,
  FoundationColorTokens,
  IconButton,
  Spacing,
  Table,
  TableActionButtonProps,
  TableHeader,
  TableOptions,
  TableReloadHandle,
  TableRowItem,
  TableSortOption,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { SignInStore } from "../../auth/store/SignInStore";
import { Instance } from "mobx-state-tree";
import { UserPrivileges } from "../../store/user/UserPrivileges";
import { GetRespondentDetailsError } from "../store/RespondentStore";
import { TFunction } from "i18next";
import { ErrorSuccessDialogContent } from "./ErrorSuccessDialogContent";
import { Stack } from "@mui/material";
import { UnassignSurveyRespondentDialog } from "./UnassignSurveyRespondentDialog";
import { BorderStyle, IconContainer } from "@pulse/shared-components";
import { ReOpenSurveyLinkDialog } from "./ReOpenSurveyLinkDialog";
import {
  EditRespondentDetailsDialog,
  EditRespondentDialogState,
} from "./RespondentListChildComponents/EditRespondentDetailsDialog";
import { AssignRespondentDialog } from "./assignRespondent/AssignRespondentDialog";
import { DeleteRespondentDialog } from "./DeleteRespondentDialog";
import {
  AssignAndViewSpocsDialog,
  DialogType,
} from "./spocManagement/AssignAndViewSpocsDialog";
import { Edit, ListX, MinusSquare, Trash2Icon, UserCog } from "lucide-react";
import { RespondentFilterOptionsModel } from "../../models/respondentFilterOptionsModel";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../routes/RoutesHelper";
import { RespondentModel } from "../models/RespondentModel";
import { StyledEllipsisTypography } from "../../../components/StyledEllipsisTypography";
import { GetRespondentDetailsRPC, SurveyLinkStatus } from "@pulse/pulse-rpcs";
import { ProjectDetailsStore } from "../store/ProjectDetailsStore";
import { RespondentWithSurveyDetailsModel } from "../models/RespondentWithSurveyDetailsModel";

interface RespondentTableProps {
  signInStore: Instance<typeof SignInStore>;
  projectDetailsStore: Instance<typeof ProjectDetailsStore>;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  tableActionProps: TableActionButtonProps[];
  surveyFilterName: string | undefined;
  border: BorderStyle;
  navigateTo: NavigateToFunctions;
}

export const RespondentTable = observer(
  ({
    signInStore,
    tableRef,
    t,
    spacing,
    tokens,
    typography,
    tableActionProps,
    surveyFilterName,
    border,
    navigateTo,
    projectDetailsStore,
  }: RespondentTableProps): React.ReactElement => {
    const respondentStore = projectDetailsStore.respondentStore;
    const getSurveyLinkStatusChipProps = (
      surveyStatus: SurveyLinkStatus.SurveyLinkStatus,
    ): ChipProps => {
      switch (surveyStatus) {
        case SurveyLinkStatus.SurveyLinkStatus.SUBMITTED:
          return {
            color: "greenSubtle",
            label: t("surveyLinkStatus.submitted"),
          };
        case SurveyLinkStatus.SurveyLinkStatus.NOT_ASSIGNED:
          return {
            color: "neutral",
            label: t("surveyLinkStatus.unassigned"),
          };
        case SurveyLinkStatus.SurveyLinkStatus.NOT_STARTED:
          return {
            color: "redSubtle",
            label: t("surveyLinkStatus.notStarted"),
          };
        case SurveyLinkStatus.SurveyLinkStatus.STARTED:
          return {
            color: "blueSubtle",
            label: t("surveyLinkStatus.started"),
          };
        case SurveyLinkStatus.SurveyLinkStatus.VIEWED:
          return {
            color: "blueSubtle",
            label: t("surveyLinkStatus.viewed"),
          };
        case SurveyLinkStatus.SurveyLinkStatus.LAST_PAGE:
          return {
            color: "blueSubtle",
            label: t("surveyLinkStatus.lastPage"),
          };
        case SurveyLinkStatus.SurveyLinkStatus.IN_PROGRESS:
          return {
            color: "blueSubtle",
            label: t("surveyLinkStatus.inProgress"),
          };
        case SurveyLinkStatus.SurveyLinkStatus.ASSIGNING:
          return {
            color: "redSubtle",
            label: t("surveyLinkStatus.assigning"),
          };
      }
    };

    const getRespondentDetails = async (
      pageIndex: number,
      sort?: TableSortOption,
    ): Promise<void> => {
      await processProjectParams(async (projectId) => {
        await respondentStore.getRespondentDetails(
          pageIndex,
          projectId,
          respondentStore.isLiveRespondentsSelected,
          sort,
          surveyFilterName,
        );
      }, navigateTo);
    };

    const getHeaders = (): TableHeader => {
      const tableHeaders = respondentStore.tableColumnHeaders(t);

      if (
        surveyFilterName === undefined &&
        (!signInStore.isPrivilegeGranted(UserPrivileges.processProject) ||
          !signInStore.isPrivilegeGranted(UserPrivileges.viewSpocs))
      ) {
        tableHeaders.pop();
      }
      return tableHeaders;
    };

    const getRespondents = async (
      options: TableOptions<Instance<typeof RespondentFilterOptionsModel>>,
      setTotalItems: React.Dispatch<React.SetStateAction<number>>,
    ): Promise<string | TableRowItem[]> => {
      if (respondentStore.respondentUIStore.isApplyFiltersClicked) {
        respondentStore.respondentUIStore.setIsApplyFiltersClicked(false);
        await getRespondentDetails(0, options.sort);
      } else if (
        respondentStore.rpcErrors === GetRespondentDetailsError.InvalidPageIndex
      ) {
        respondentStore.clearRpcErrors();
        await getRespondentDetails(0, options.sort);
      } else {
        await getRespondentDetails(
          options.pageNumber ? options.pageNumber - 1 : 0,
          options.sort,
        );
      }

      const columnKeys =
        respondentStore.mapRespondentColumnsToColumnWithIndex();

      const columns = (
        respondent: Instance<typeof RespondentModel>,
      ): ContentCellOptions[] => {
        return columnKeys.map((column) => {
          return {
            columnId: column,
            data: respondent[column as keyof typeof respondent] as string,
          };
        });
      };

      setTotalItems(respondentStore.totalItems);
      const ActionButtons = observer(
        ({
          respondentSurveyDetails,
        }: {
          respondentSurveyDetails: Instance<
            typeof RespondentWithSurveyDetailsModel
          >;
        }): React.ReactElement => {
          return (
            <Stack flexDirection="row" gap={spacing.spaceXS}>
              {signInStore.isPrivilegeGranted(UserPrivileges.viewSpocs) &&
              respondentStore.isLiveRespondentsSelected ? (
                <IconButton
                  icon={<UserCog />}
                  color="primary"
                  name="viewSpocs"
                  variant="plain-neutral"
                  size="small"
                  onClick={() => {
                    respondentStore.setSelectedRespondentDetails(
                      respondentSurveyDetails.respondent.id,
                    );
                    processProjectParams(async (projectId): Promise<void> => {
                      await respondentStore.getRespondentSpocs(
                        projectId,
                        respondentSurveyDetails.respondent.id,
                      );
                    }, navigateTo);
                    respondentStore.respondentUIStore.setIsViewSpocsDialogVisible(
                      true,
                    );
                  }}
                />
              ) : (
                <></>
              )}
              {signInStore.isPrivilegeGranted(UserPrivileges.viewSpocs) ? (
                <>
                  <IconButton
                    icon={<Edit />}
                    color="primary"
                    name="editButton"
                    variant="plain-neutral"
                    size="small"
                    onClick={() => {
                      respondentStore.multiSelectTableStore.unselectAllRows();
                      respondentStore.respondentUIStore.setIsEditRespondentDetailsDialogVisible(
                        true,
                      );
                      processProjectParams(
                        async (projectId: string): Promise<void> => {
                          respondentStore.setEditRespondentDialogState(
                            EditRespondentDialogState.Loading,
                          );
                          await respondentStore.getProjectSpocDetails(
                            projectId,
                          );
                          await respondentStore.getRespondentSpocs(
                            projectId,
                            respondentSurveyDetails.respondent.id,
                          );
                          respondentStore.setSelectedRespondentDetails(
                            respondentSurveyDetails.respondent.id,
                          );
                          respondentStore.setEditRespondentDialogState(
                            EditRespondentDialogState.Edit,
                          );
                        },
                        navigateTo,
                      );
                    }}
                  />
                  <IconButton
                    icon={<Trash2Icon />}
                    color="destructive"
                    name="deleteButton"
                    variant="plain-color"
                    size="small"
                    onClick={() => {
                      respondentStore.setSelectedRespondentDetails(
                        respondentSurveyDetails.respondent.id,
                      );
                      // TODO: On click of remove option in the leo component is toggling the selection state of the row. This has to be updated once the Leo component is fixed.
                      respondentStore.multiSelectTableStore.unselectAllRows();
                      respondentStore.respondentUIStore.setIsDeleteRespondentDialogVisible(
                        true,
                      );
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </Stack>
          );
        },
      );

      return respondentStore.respondentWithSurveyDetailsList.map(
        (respondentSurveyDetails) => {
          const respondentTableCells = [
            {
              data: (
                <StyledEllipsisTypography
                  data={respondentSurveyDetails.respondent.id}
                  typography={typography}
                  t={t}
                />
              ),
              columnId:
                GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.ID,
            },
            {
              data: (
                <StyledEllipsisTypography
                  data={respondentSurveyDetails.respondent.firstName}
                  typography={typography}
                  t={t}
                />
              ),
              columnId:
                GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn
                  .FIRST_NAME,
            },
            {
              data: (
                <StyledEllipsisTypography
                  data={respondentSurveyDetails.respondent.lastName}
                  typography={typography}
                  t={t}
                />
              ),
              columnId:
                GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn
                  .LAST_NAME,
            },
            {
              data: (
                <StyledEllipsisTypography
                  data={respondentSurveyDetails.respondent.emailId}
                  typography={typography}
                  t={t}
                />
              ),
              columnId:
                GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn
                  .EMAIL_ID,
            },
            {
              data: (
                <StyledEllipsisTypography
                  data={respondentSurveyDetails.respondent.phoneNumber}
                  typography={typography}
                  t={t}
                />
              ),
              columnId: "PhoneNumber",
            },
            {
              data: (
                <StyledEllipsisTypography
                  data={respondentSurveyDetails.surveyName}
                  typography={typography}
                  t={t}
                />
              ),
              columnId: "SurveyName",
            },
            {
              data: (
                <Stack alignItems="start">
                  <Chip
                    {...getSurveyLinkStatusChipProps(
                      respondentSurveyDetails.surveyLinkStatus,
                    )}
                  />
                </Stack>
              ),
              columnId: "SurveyStatus",
            },
            ...columns(respondentSurveyDetails.respondent),
            {
              columnId: "IndividualRespondentAction",
              data:
                surveyFilterName === undefined ? (
                  <ActionButtons
                    respondentSurveyDetails={respondentSurveyDetails}
                  />
                ) : (
                  <IconButton
                    icon={<MinusSquare />}
                    color="destructive"
                    name="UnassignButton"
                    variant="plain-color"
                    size="small"
                    onClick={() => {
                      respondentStore.setSelectedRespondentDetails(
                        respondentSurveyDetails.respondent.id,
                      );
                      // TODO: On click of remove option in the leo component is toggling the selection state of the row. This has to be updated once the Leo component is fixed.
                      respondentStore.multiSelectTableStore.unselectAllRows();
                      respondentStore.respondentUIStore.setIsUnassignRespondentDialogVisible(
                        true,
                      );
                    }}
                  />
                ),
            },
          ];
          if (
            surveyFilterName === undefined &&
            (!signInStore.isPrivilegeGranted(UserPrivileges.processProject) ||
              !signInStore.isPrivilegeGranted(UserPrivileges.viewSpocs))
          ) {
            respondentTableCells.pop();
          }
          return {
            id: `${respondentSurveyDetails.respondent.id}`,
            cells: respondentTableCells,
          };
        },
      );
    };

    const DeletedColumnFilterErrorDialog = observer((): React.ReactElement => {
      return (
        <Dialog
          open={
            respondentStore.respondentUIStore
              .isDeletedColumnFilterErrorDialogVisible
          }
          title={t("projects.respondentList.deletedColumnFilterErrorTitle")}
          secondaryButtonText={t("common.refresh")}
          onSecondaryButtonClick={() => {
            respondentStore.clearRpcErrors();
            respondentStore.filterStore.removeAllFilters();
            tableRef.current?.reload();
            respondentStore.respondentUIStore.setIsDeletedColumnFilterErrorDialogVisible(
              false,
            );
          }}
          disableBackdropClick
        >
          <ErrorSuccessDialogContent
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            isError={true}
            errorSuccessText={t(
              "projects.respondentList.deletedColumnFilterDescription",
            )}
          />
        </Dialog>
      );
    });
    const actionElements: TableActionButtonProps[] =
      surveyFilterName === undefined
        ? signInStore.isPrivilegeGranted(
            UserPrivileges.processRespondentSurveyAction,
          )
          ? tableActionProps
          : []
        : [
            {
              name: "reOpenSurveyLink",
              label: t("projects.reOpenSurveyLink.reOpenLinks"),
              variant: "outlined-color",
              onClick: (): void => {
                respondentStore.respondentUIStore.setIsReopenSurveyLinkDialogVisible(
                  true,
                );
              },
            },
            {
              name: "unassignRespondent",
              label: t("common.unassign"),
              variant: "outlined-color",
              color: "destructive",
              onClick: (): void => {
                if (surveyFilterName) {
                  respondentStore.respondentUIStore.setIsUnassignRespondentDialogVisible(
                    true,
                  );
                }
              },
            },
          ];

    return (
      <Stack paddingBottom={spacing.spaceXL}>
        {respondentStore.respondentUIStore
          .isDeletedColumnFilterErrorDialogVisible && (
          <DeletedColumnFilterErrorDialog />
        )}
        {respondentStore.respondentUIStore.isUnassignRespondentDialogVisible &&
          surveyFilterName && (
            <UnassignSurveyRespondentDialog
              isUnassignRespondentDialogVisible={
                respondentStore.respondentUIStore
                  .isUnassignRespondentDialogVisible
              }
              setIsUnassignRespondentDialogVisible={
                respondentStore.respondentUIStore
                  .setIsUnassignRespondentDialogVisible
              }
              typography={typography}
              t={t}
              tokens={tokens}
              spacing={spacing}
              respondentStore={respondentStore}
              navigateTo={navigateTo}
              multiSelectTableStore={respondentStore.multiSelectTableStore}
              tableRef={tableRef}
              surveyFilterName={surveyFilterName}
            />
          )}
        {respondentStore.respondentUIStore.isReopenSurveyLinkDialogVisible && (
          <ReOpenSurveyLinkDialog
            isReOpenSurveyLinkDialogVisible={
              respondentStore.respondentUIStore.isReopenSurveyLinkDialogVisible
            }
            setIsReOpenSurveyLinkDialogVisible={
              respondentStore.respondentUIStore
                .setIsReopenSurveyLinkDialogVisible
            }
            t={t}
            spacing={spacing}
            navigateTo={navigateTo}
            tokens={tokens}
            typography={typography}
            respondentStore={respondentStore}
            tableRef={tableRef}
          />
        )}
        {respondentStore.respondentUIStore
          .isEditRespondentDetailsDialogVisible && (
          <EditRespondentDetailsDialog
            isEditRespondentDetailsDialogOpen={
              respondentStore.respondentUIStore
                .isEditRespondentDetailsDialogVisible
            }
            setIsEditRespondentDetailsDialogOpen={
              respondentStore.respondentUIStore
                .setIsEditRespondentDetailsDialogVisible
            }
            spacing={spacing}
            t={t}
            tokens={tokens}
            typography={typography}
            respondentStore={respondentStore}
            surveyLinkStatusChipProps={
              respondentStore.selectedRespondentLinkStatus &&
              getSurveyLinkStatusChipProps(
                respondentStore.selectedRespondentLinkStatus,
              )
            }
            dialogState={
              respondentStore.respondentUIStore.editRespondentDialogState
            }
            tableRef={tableRef}
          />
        )}
        {respondentStore.respondentUIStore.isAssignRespondentDialogVisible && (
          <AssignRespondentDialog
            isAssignRespondentDialogVisible={
              respondentStore.respondentUIStore.isAssignRespondentDialogVisible
            }
            setIsAssignRespondentDialogVisible={
              respondentStore.respondentUIStore
                .setIsAssignRespondentDialogVisible
            }
            t={t}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            navigateTo={navigateTo}
            respondentStore={respondentStore}
            projectStore={projectDetailsStore}
            tableRef={tableRef}
          />
        )}
        {respondentStore.respondentUIStore.isDeleteRespondentDialogVisible && (
          <DeleteRespondentDialog
            isDeleteRespondentDialogVisible={
              respondentStore.respondentUIStore.isDeleteRespondentDialogVisible
            }
            setIsDeleteRespondentDialogVisible={
              respondentStore.respondentUIStore
                .setIsDeleteRespondentDialogVisible
            }
            typography={typography}
            t={t}
            tokens={tokens}
            spacing={spacing}
            respondentStore={respondentStore}
            navigateTo={navigateTo}
            multiSelectTableStore={respondentStore.multiSelectTableStore}
            tableRef={tableRef}
          />
        )}
        {respondentStore.respondentUIStore.isAssignSpocsDialogVisible && (
          <AssignAndViewSpocsDialog
            isAssignAndViewSpocsDialogOpen={
              respondentStore.respondentUIStore.isAssignSpocsDialogVisible
            }
            setIsAssignAndViewSpocsDialogOpen={
              respondentStore.respondentUIStore.setIsAssignSpocsDialogVisible
            }
            t={t}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            navigateTo={navigateTo}
            border={border}
            dialogType={DialogType.AssignSpocs}
            projectDetailsStore={projectDetailsStore}
            searchSpocFieldGap={undefined}
            searchSpocFieldPadding={spacing.spaceLG}
            searchSpocFieldDescription={undefined}
            selectedSpocListPadding={`0px ${spacing.spaceLG}`}
            selectedSpocListGap={spacing.spaceLG}
            selectedSpocElementPadding={`0px 0px ${spacing.spaceLG} 0px`}
            noSpocsFoundText={t(
              "projects.assignSpocsToRespondents.noSpocsFoundText",
            )}
            searchSpocFieldTitle={undefined}
            isSpocListEmpty={projectDetailsStore.isProjectSpocListEmpty}
            isSearchProjectSpocFieldDisabled={
              projectDetailsStore.isSearchProjectSpocFieldDisabled
            }
          />
        )}
        {respondentStore.respondentUIStore.isViewSpocsDialogVisible && (
          <AssignAndViewSpocsDialog
            isAssignAndViewSpocsDialogOpen={
              respondentStore.respondentUIStore.isViewSpocsDialogVisible
            }
            setIsAssignAndViewSpocsDialogOpen={
              respondentStore.respondentUIStore.setIsViewSpocsDialogVisible
            }
            t={t}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            navigateTo={navigateTo}
            border={border}
            dialogType={DialogType.ViewSpocs}
            projectDetailsStore={projectDetailsStore}
            searchSpocFieldPadding={spacing.spaceLG}
            searchSpocFieldGap={spacing.spaceXS}
            searchSpocFieldDescription={undefined}
            selectedSpocListPadding={spacing.spaceLG}
            selectedSpocListGap={spacing.spaceSM}
            selectedSpocElementPadding={undefined}
            noSpocsFoundText={t(
              "projects.viewSpocs.noRespondentSpocsAssignedText",
            )}
            searchSpocFieldTitle={t("projects.viewSpocs.respondentText")}
            isSpocListEmpty={
              projectDetailsStore.respondentStore.isRespondentSpocListEmpty
            }
            isSearchProjectSpocFieldDisabled={
              projectDetailsStore.respondentStore.isRPCLoading ||
              projectDetailsStore.respondentStore.isRespondentSpocListEmpty
            }
          />
        )}
        <Table
          ref={tableRef}
          name="respondentlist"
          header={getHeaders()}
          onTableOptionsChange={getRespondents}
          viewOverrides={{
            empty: {
              icon: IconContainer(
                <ListX
                  width="32px"
                  height="32px"
                  color={tokens.iconLowEmphasis}
                />,
              ),
              message: t("projects.respondentList.noRespondentsAddedTitle"),
            },
            loading: { message: t("common.searchTableLoadingState") },
          }}
          paginationOption={{
            itemsPerPage: respondentStore.itemsPerPage,
            getPageIndicatorText(startItem, endItem, totalItems): string {
              return t("common.paginationIndicationText", {
                startItem,
                endItem,
                totalItems,
              });
            },
            position: "BOTH",
          }}
          styleOverrides={{
            background: tokens.backgroundElevatedLevel1,
            divider: "row",
          }}
          leftPinnedColumnIds={[
            GetRespondentDetailsRPC.RequestEnums.SortColumn.SortColumn.ID,
          ]}
          rightPinnedColumnIds={["IndividualRespondentAction"]}
          multiSelectOptions={{
            selectAllItemsText: t(
              "common.multiSelectTableTexts.selectAllItems",
              {
                totalItemCount: respondentStore.totalItems,
              },
            ),
            clearAllItemsText: t(
              "common.multiSelectTableTexts.clearAllSelection",
            ),
            clearSelectionText: t(
              "common.multiSelectTableTexts.clearSelection",
            ),
            selectionText: t("common.multiSelectTableTexts.selection", {
              selectedItemsCount:
                respondentStore.multiSelectTableStore.totalSelectedItems(
                  respondentStore.totalItems,
                ),
            }),
            shouldPinSelectionColumn: true,
            store: respondentStore.multiSelectTableStore,
            actionElements,
          }}
        />
      </Stack>
    );
  },
);
