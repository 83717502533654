import {
  FoundationColorTokens,
  IconButton,
  Spacing,
  TableReloadHandle,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Archive } from "lucide-react";
import React, { useState } from "react";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { SendPasswordResetLinkArchiveBOUserStore } from "../store/SendPasswordResetLinkArchiveBOUserStore";
import { ArchiveBOUserDialog } from "./ArchiveBOUserDialog";

interface ArchiveBOUserProps {
  t: TFunction;
  spacing: Spacing;
  sendPasswordResetLinkArchiveBOUserStore: Instance<
    typeof SendPasswordResetLinkArchiveBOUserStore
  >;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  boUserId: string;
  firstName: string;
  lastName: string;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const ArchiveBOUser = ({
  boUserId,
  sendPasswordResetLinkArchiveBOUserStore,
  spacing,
  t,
  tokens,
  typography,
  tableRef,
  firstName,
  lastName,
}: ArchiveBOUserProps): React.ReactElement => {
  const [isArchiveBOUserDialogVisible, setIsArchiveBOUserDialogVisible] =
    useState(false);

  return (
    <>
      {isArchiveBOUserDialogVisible && (
        <ArchiveBOUserDialog
          isArchiveBOUserDialogVisible={isArchiveBOUserDialogVisible}
          setIsArchiveBOUserDialogVisible={setIsArchiveBOUserDialogVisible}
          t={t}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
          boUserId={boUserId}
          tableRef={tableRef}
          sendPasswordResetLinkArchiveBOUserStore={
            sendPasswordResetLinkArchiveBOUserStore
          }
          firstName={firstName}
          lastName={lastName}
        />
      )}
      <IconButton
        icon={<Archive />}
        color="destructive"
        name="archiveUser"
        variant="plain-color"
        size="small"
        onClick={() => {
          setIsArchiveBOUserDialogVisible(true);
        }}
      />
    </>
  );
};
