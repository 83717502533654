import React, { useEffect } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import {
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../../routes/RoutesHelper";
import { UploadFileDialog } from "../../../../components/fileUpload/UploadFileDialog";
import { SurveyStore } from "../../store/SurveyStore";
import { TranslationUploadPane } from "./TranslationUploadPane";
import { UploadTranslationsDialogState } from "../../store/TranslationUploadStore";
import { TranslationValidationDialog } from "./TranslationValidationDialog";

export interface TranslationsUploadDialogProps {
  isUploadTranslationsDialogVisible: boolean;
  setIsUploadTranslationsDialogVisible: (isDialogVisible: boolean) => void;
  t: TFunction;
  navigateTo: NavigateToFunctions;
  surveyStore: Instance<typeof SurveyStore>;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
}

export const TranslationsUploadDialog = observer(
  ({
    isUploadTranslationsDialogVisible,
    setIsUploadTranslationsDialogVisible,
    t,
    navigateTo,
    surveyStore,
    typography,
    spacing,
    tokens,
  }: TranslationsUploadDialogProps): React.ReactElement => {
    const translationsUploadStore =
      surveyStore.translationsStore.translationUploadStore;
    let errorBannerText: string | undefined;

    useEffect(() => {
      return translationsUploadStore.clearErrorsAndFlags();
    }, []);

    if (translationsUploadStore.isFileInvalid) {
      errorBannerText = t("surveys.translations.invalidFileErrorDescription");
    }

    const downloadTranslations = async (): Promise<void> => {
      await processSurveyParams(async (surveyId, projectId) => {
        await translationsUploadStore.downloadTranslationFile(
          surveyId,
          projectId,
          surveyStore.surveyName,
        );
      }, navigateTo);
    };

    switch (translationsUploadStore.uploadTranslationsDialogState) {
      case UploadTranslationsDialogState.ValidatingFile:
      case UploadTranslationsDialogState.ValidationSuccessful:
      case UploadTranslationsDialogState.ErrorState: {
        return (
          <TranslationValidationDialog
            translationsStore={surveyStore.translationsStore}
            t={t}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            navigateTo={navigateTo}
          />
        );
      }
      default: {
        return (
          <UploadFileDialog
            isUploadFileDialogVisible={isUploadTranslationsDialogVisible}
            title={t("surveys.translations.uploadTranslationsText")}
            description={t(
              "surveys.translations.downloadTranslationDescription",
            )}
            note={t("common.uploadDialog.uploadNoteText")}
            primaryDownloadButtonText={t(
              "surveys.translations.downloadTranslationFile",
            )}
            primaryDownloadButtonOnClick={downloadTranslations}
            primaryDialogButtonText={t(
              "surveys.translations.uploadTranslationsText",
            )}
            primaryDialogButtonOnClick={async (): Promise<void> => {
              await processSurveyParams(async (surveyId, projectId) => {
                translationsUploadStore.validateAndUploadTranslations(
                  surveyId,
                  projectId,
                );
              }, navigateTo);
            }}
            secondaryDialogButtonText={t("common.cancel")}
            isPrimaryDialogButtonDisabled={
              translationsUploadStore.isUploadButtonDisabled
            }
            fileUploadPane={
              <TranslationUploadPane
                navigateTo={navigateTo}
                translationUploadStore={translationsUploadStore}
                t={t}
                spacing={spacing}
                tokens={tokens}
                typography={typography}
              />
            }
            isSecondaryDialogButtonDisabled={
              translationsUploadStore.isSecondaryDialogButtonDisabled
            }
            secondaryDialogButtonOnClick={() => {
              setIsUploadTranslationsDialogVisible(false);
            }}
            errorBannerText={errorBannerText}
          />
        );
      }
    }
  },
);
