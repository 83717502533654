import {
  DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE,
  DEFAULT_PROJECT_LIST_SORT_INDEX,
  EMPTY_CHARACTER,
  FeatureInDevelopmentSnackbar,
  ICON_SIZE,
  IconContainer,
  getIconProps,
} from "@pulse/shared-components";
import {
  FoundationColorTokens,
  Spacing,
  TableHeader,
  TableOptions,
  TableReloadHandle,
  TableRowItem,
  TableSortOption,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { IconButton, Table } from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Archive, ListX } from "lucide-react";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useState } from "react";
import { ProjectsRootStore } from "../../store/ProjectsRootStore";
import { StyledEllipsisTypography } from "../../../../components/StyledEllipsisTypography";
import { Stack } from "@mui/material";
import { NavigateToFunctions } from "../../../../routes/RoutesHelper";
import { EmptyListPane } from "../../../surveys/components/EmptyListPane";
import { CloneCreateNewProjectDialog } from "./CloneCreateNewProjectDialog";
import { ArchiveProjectDialog } from "./ArchiveProjectDialog";
import { SignInStore } from "../../../auth/store/SignInStore";
import { UserPrivileges } from "../../../store/user/UserPrivileges";
import { getFormattedDateString } from "@pulse/shared-components";
import { getFullySpacedString } from "../../utils/StringUtils";
import { CloneProject } from "./CloneProject";
import { getDefaultProjectDetailsTab } from "../../utils/ProjectDetailsTabUtils";

export interface ProjectListComponentProps {
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  isArchivedProjectsList: boolean;
  projectsRootStore: Instance<typeof ProjectsRootStore>;
  typography: Typography;
  spacing: Spacing;
  navigateTo: NavigateToFunctions;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
  signInStore: Instance<typeof SignInStore>;
}

export enum ProjectListHeaderEnum {
  Project = "Project",
  Creator = "Creator",
  Surveys = "Surveys",
  CreatedOn = "Created On",
  LastModified = "Last Modified",
  ProjectAction = "Project Action",
}

export const ProjectListComponent = observer(
  ({
    t,
    tokens,
    isArchivedProjectsList,
    projectsRootStore,
    typography,
    spacing,
    navigateTo,
    tableRef,
    signInStore,
  }: ProjectListComponentProps): React.ReactElement => {
    const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
    const [isArchiveProjectDialogOpen, setIsArchiveProjectDialogOpen] =
      useState(false);

    const createProjectHelperButtonCallback = (): (() => void) | undefined => {
      if (isArchivedProjectsList) {
        return undefined;
      } else {
        if (signInStore.isPrivilegeGranted(UserPrivileges.processProject)) {
          return (): void => {
            projectsRootStore.setIsCreateNewProjectDialogOpen(true);
          };
        } else {
          return undefined;
        }
      }
    };

    const EmptyProjectsListContainer = (): React.ReactElement => {
      return (
        <Stack height="100%">
          <EmptyListPane
            headingText={t("projects.noProjectsFoundTitle")}
            subHeadingText={
              signInStore.isPrivilegeGranted(UserPrivileges.processProject)
                ? t("projects.noProjectsFoundDescription")
                : undefined
            }
            helperButtonText={t("projects.createNewProject")}
            helperButtonCallback={createProjectHelperButtonCallback()}
          />
        </Stack>
      );
    };

    const getHeaders = (): TableHeader => {
      const columnHeaders = [
        {
          columnId: ProjectListHeaderEnum.Project,
          label: t("projects.projectListHeaders.project"),
          // A fixed width is added here as opposed to a fill (based on design) as the component title breaks with 100% width.
          width: "362px",
          sortable: true,
        },
        {
          columnId: ProjectListHeaderEnum.Creator,
          label: t("projects.projectListHeaders.creator"),
          // A fixed width is added here as opposed to a fill (based on design) as the component title breaks with 100% width.
          width: "362px",
        },
        {
          columnId: ProjectListHeaderEnum.Surveys,
          label: t("projects.projectListHeaders.surveys"),
          width: "132px",
        },
        {
          columnId: ProjectListHeaderEnum.CreatedOn,
          label: t("projects.projectListHeaders.createdOn"),
          width: "200px",
          sortable: true,
        },
        {
          columnId: ProjectListHeaderEnum.LastModified,
          label: t("projects.projectListHeaders.lastModified"),
          width: "208px",
          sortable: true,
        },
      ];
      if (signInStore.isPrivilegeGranted(UserPrivileges.processProject)) {
        columnHeaders.push({
          columnId: ProjectListHeaderEnum.ProjectAction,
          label: EMPTY_CHARACTER,
          width: "fit-content",
        });
      }
      return columnHeaders;
    };

    const getProjectDetails = async (
      pageIndex: number,
      sort?: TableSortOption,
    ): Promise<void> => {
      if (signInStore.isPrivilegeGranted(UserPrivileges.viewAssignedProjects)) {
        if (isArchivedProjectsList) {
          await projectsRootStore.getArchivedSpocProjects(
            pageIndex,
            signInStore.userId,
            sort,
          );
        } else {
          await projectsRootStore.getActiveSpocProjects(
            pageIndex,
            signInStore.userId,
            sort,
          );
        }
      } else {
        const boUserId =
          signInStore.checkIfViewAssignedProjectPrivilegeGrantedAndGetUserId;
        if (boUserId !== null) {
          if (isArchivedProjectsList) {
            await projectsRootStore.getArchivedResearcherProjects(
              pageIndex,
              boUserId,
              sort,
            );
          } else {
            await projectsRootStore.getActiveResearcherProjects(
              pageIndex,
              boUserId,
              sort,
            );
          }
        } else {
          if (isArchivedProjectsList) {
            await projectsRootStore.getAllArchivedProjects(pageIndex, sort);
          } else {
            await projectsRootStore.getAllActiveProjects(pageIndex, sort);
          }
        }
      }
    };

    const getProjectsList = async (
      options: TableOptions,
      setTotalItems: React.Dispatch<React.SetStateAction<number>>,
    ): Promise<string | TableRowItem[]> => {
      if (!projectsRootStore.filterStore.areNoFiltersAdded) {
        await getProjectDetails(DEFAULT_PROJECT_LIST_SORT_INDEX, options.sort);
      } else {
        await getProjectDetails(
          options.pageNumber
            ? options.pageNumber - 1
            : DEFAULT_PROJECT_LIST_SORT_INDEX,
          options.sort,
        );
      }

      setTotalItems(projectsRootStore.totalItems);

      const projectList = isArchivedProjectsList
        ? projectsRootStore.archivedProjectsList
        : projectsRootStore.activeProjectsList;

      return projectList.map(
        ({
          projectId,
          projectName,
          creator,
          surveys,
          createdOn,
          lastModified,
        }) => {
          const projectListCells = [
            {
              data: (
                <StyledEllipsisTypography
                  data={getFullySpacedString(projectName)}
                  t={t}
                  typography={typography}
                />
              ),
              columnId: ProjectListHeaderEnum.Project,
            },
            {
              data: (
                <StyledEllipsisTypography
                  data={creator}
                  t={t}
                  typography={typography}
                />
              ),
              columnId: ProjectListHeaderEnum.Creator,
            },
            {
              data: (
                <StyledEllipsisTypography
                  data={surveys}
                  t={t}
                  typography={typography}
                />
              ),
              columnId: ProjectListHeaderEnum.Surveys,
            },
            {
              data: (
                <StyledEllipsisTypography
                  data={getFormattedDateString(createdOn)}
                  t={t}
                  typography={typography}
                />
              ),
              columnId: ProjectListHeaderEnum.CreatedOn,
            },
            {
              data: (
                <StyledEllipsisTypography
                  data={getFormattedDateString(lastModified)}
                  t={t}
                  typography={typography}
                />
              ),
              columnId: ProjectListHeaderEnum.LastModified,
            },
          ];
          if (signInStore.isPrivilegeGranted(UserPrivileges.processProject)) {
            projectListCells.push({
              columnId: ProjectListHeaderEnum.ProjectAction,
              data: (
                <Stack flexDirection="row" gap={spacing.spaceXS}>
                  <CloneProject
                    projectName={projectName}
                    projectId={projectId}
                    t={t}
                    spacing={spacing}
                    typography={typography}
                    projectsRootStore={projectsRootStore}
                    navigateTo={navigateTo}
                    tokens={tokens}
                  />
                  {!isArchivedProjectsList && (
                    <IconButton
                      icon={<Archive />}
                      color="destructive"
                      name="archiveButton"
                      variant="plain-color"
                      size="small"
                      onClick={() => {
                        projectsRootStore.setSelectedProjectDetailsByProjectId(
                          projectId,
                        );
                        setIsArchiveProjectDialogOpen(true);
                      }}
                    />
                  )}
                </Stack>
              ),
            });
          }
          return {
            id: `${projectId}`,
            cells: projectListCells,
          };
        },
      );
    };
    return (
      <>
        <CloneCreateNewProjectDialog
          isCloneCreateNewProjectDialogOpen={
            projectsRootStore.isCreateNewProjectDialogOpen
          }
          t={t}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          projectsRootStore={projectsRootStore}
          navigateTo={navigateTo}
          setIsCloneCreateNewProjectDialogOpen={
            projectsRootStore.setIsCreateNewProjectDialogOpen
          }
          cloneProjectDialogProps={undefined}
        />
        <ArchiveProjectDialog
          isArchiveProjectDialogOpen={isArchiveProjectDialogOpen}
          t={t}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          projectRootStore={projectsRootStore}
          setIsArchiveProjectDialogOpen={setIsArchiveProjectDialogOpen}
          tableRef={tableRef}
        />
        <FeatureInDevelopmentSnackbar
          isSnackbarVisible={isSnackbarVisible}
          setIsSnackbarVisible={setIsSnackbarVisible}
        />
        {projectsRootStore.isPaginatedListVisible(isArchivedProjectsList) ? (
          <Table
            name="projectsList"
            ref={tableRef}
            onRowClick={(row: TableRowItem) => {
              const projectNameCell = row.cells.find((cell) => {
                return cell.columnId === ProjectListHeaderEnum.Project;
              });
              if (projectNameCell === undefined) {
                console.error("The project name cannot be undefined.");
                return;
              }
              const projectName =
                projectsRootStore.getProjectNameByProjectId(
                  isArchivedProjectsList,
                  row.id,
                ) ?? EMPTY_CHARACTER;
              projectsRootStore.projectDetailsStore.setProjectName(projectName);
              projectsRootStore.projectDetailsStore.respondentStore.setProjectId(
                row.id,
              );
              navigateTo.projectDetails(
                row.id,
                getDefaultProjectDetailsTab(t, navigateTo),
              );
            }}
            header={getHeaders()}
            onTableOptionsChange={getProjectsList}
            viewOverrides={{
              empty: {
                icon: IconContainer(
                  <ListX
                    {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
                  />,
                ),
                message: t("projects.noProjectsFoundTitle"),
              },
              loading: { message: t("common.searchTableLoadingState") },
            }}
            paginationOption={{
              itemsPerPage: DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE,
              getPageIndicatorText(startItem, endItem, totalItems): string {
                return t("common.paginationIndicationText", {
                  startItem,
                  endItem,
                  totalItems,
                });
              },
              position: "BOTH",
            }}
            styleOverrides={{
              background: tokens.backgroundElevatedLevel1,
              divider: "row",
            }}
            leftPinnedColumnIds={[]}
            rightPinnedColumnIds={[ProjectListHeaderEnum.ProjectAction]}
          />
        ) : (
          <EmptyProjectsListContainer />
        )}
      </>
    );
  },
);
