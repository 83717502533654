import {
  FoundationColorTokens,
  Typography as LeoTypography,
  MultiSelectTableStore,
  Spacing,
  TableReloadHandle,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useEffect, useState } from "react";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../routes/RoutesHelper";
import {
  DeleteRespondentError,
  RespondentStore,
} from "../store/RespondentStore";
import { Dialog } from "@surya-digital/leo-reactjs-material-ui";
import { ErrorSuccessDialogContent } from "./ErrorSuccessDialogContent";
import {
  TOTAL_COUNTDOWN_TIME_IN_SEC,
  COUNTDOWN_INTERVAL_IN_MS,
  END_OF_COUNTDOWN,
} from "@pulse/shared-components";
import { NestedTypographyDestructiveComponent } from "./NestedTypographyDestructiveComponent";

interface DeleteRespondentDialogProps {
  isDeleteRespondentDialogVisible: boolean;
  setIsDeleteRespondentDialogVisible: (isDialogVisible: boolean) => void;
  typography: LeoTypography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  respondentStore: Instance<typeof RespondentStore>;
  navigateTo: NavigateToFunctions;
  multiSelectTableStore: Instance<typeof MultiSelectTableStore>;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const DeleteRespondentDialog = observer(
  ({
    isDeleteRespondentDialogVisible,
    setIsDeleteRespondentDialogVisible,
    typography,
    t,
    tokens,
    spacing,
    respondentStore,
    navigateTo,
    multiSelectTableStore,
    tableRef,
  }: DeleteRespondentDialogProps): React.ReactElement => {
    const [primaryButtonCountDown, setPrimaryButtonCountDown] = useState(
      TOTAL_COUNTDOWN_TIME_IN_SEC,
    );

    useEffect(() => {
      const interval = setInterval(() => {
        setPrimaryButtonCountDown(primaryButtonCountDown - 1);
      }, COUNTDOWN_INTERVAL_IN_MS);
      if (primaryButtonCountDown === END_OF_COUNTDOWN) clearInterval(interval);
      return () => clearInterval(interval);
    }, [primaryButtonCountDown]);

    const primaryButtonText = respondentStore.rpcErrors
      ? undefined
      : primaryButtonCountDown !== END_OF_COUNTDOWN
        ? t("projects.deleteRespondents.delete", {
            primaryButtonCountdown: primaryButtonCountDown,
          })
        : t("common.delete");

    const getErrorText = (): string => {
      switch (respondentStore.rpcErrors) {
        case DeleteRespondentError.DeletedColumnFilter:
          return t("common.deletedColumnFilterErrorText");
        case DeleteRespondentError.ProjectArchived:
          return t(
            "projects.deleteRespondents.projectAlreadyArchivedErrorText",
          );
        default:
          return t(
            "projects.deleteRespondents.deleteRespondentGenericErrorText",
          );
      }
    };

    const respondentIdOrCount = respondentStore.isSingleRespondentSelected
      ? t("common.id", {
          id: respondentStore.selectedRespondentDetails?.respondentDetails.id
            ? respondentStore.selectedRespondentDetails.respondentDetails.id
            : multiSelectTableStore.selectedRowIds[0],
        })
      : multiSelectTableStore.areAllRowsSelected
        ? t("common.respondentCount", {
            respondentsCount: respondentStore.totalItems,
          })
        : t("common.respondentCount", {
            respondentsCount: multiSelectTableStore.selectedRowIds.length,
          });

    const endText = respondentStore.isSingleRespondentSelected
      ? t("projects.deleteRespondents.dialogWarningEndTextSingular")
      : t("projects.deleteRespondents.dialogWarningEndTextPlural");

    return (
      <Dialog
        title={t("projects.deleteRespondents.dialogTitleText")}
        open={isDeleteRespondentDialogVisible}
        secondaryButtonText={
          respondentStore.rpcErrors ? t("common.close") : t("common.cancel")
        }
        onSecondaryButtonClick={() => {
          setIsDeleteRespondentDialogVisible(false);
          respondentStore.resetSelectedRespondentDetails();
        }}
        isSecondaryButtonDisabled={respondentStore.isRPCLoading}
        isPrimaryButtonDisabled={
          primaryButtonCountDown !== END_OF_COUNTDOWN ||
          respondentStore.isRPCLoading
        }
        primaryButtonText={primaryButtonText}
        primaryButtonColor="destructive"
        onPrimaryButtonClick={async () => {
          await processProjectParams(
            respondentStore.deleteRespondent,
            navigateTo,
          );
          tableRef.current?.reload();
        }}
      >
        {respondentStore.rpcErrors ? (
          <ErrorSuccessDialogContent
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            isError={true}
            errorSuccessText={getErrorText()}
          />
        ) : (
          <NestedTypographyDestructiveComponent
            tokens={tokens}
            typography={typography}
            middleBoldText={respondentIdOrCount}
            startText={t("projects.deleteRespondents.dialogWarningStartText")}
            endText={endText}
            noteText={t("projects.deleteRespondents.deleteRespondentNote")}
            spacing={spacing}
          />
        )}
      </Dialog>
    );
  },
);
