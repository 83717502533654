import { Stack } from "@mui/material";
import { useSpacing } from "@surya-digital/leo-reactjs-material-ui";
import { Button } from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React, { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useQuestionDetailsStore } from "../../store/hooks";
import {
  processSurveyParams,
  useRouteNavigator,
} from "../../../../routes/RoutesHelper";
import { DividerComponent } from "./DividerComponent";
import { useSurveyStore } from "../../../store/Hooks";
import { clone } from "mobx-state-tree";

interface AddQuestionButtonsProps {
  setIsDeleteQuestionDialogOpen: (value: SetStateAction<boolean>) => void;
  setShowAddOrUpdateSuccessfulDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setShowSurveyLiveConfirmationDialog: (
    value: React.SetStateAction<boolean>,
  ) => void;
  setSelectedItemQuestionId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  selectedQuestionItemId: string | null;
  isGroupQuestion: boolean;
  setIsSaveButtonClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddQuestionButtons = observer(
  ({
    setIsDeleteQuestionDialogOpen,
    setShowAddOrUpdateSuccessfulDialog,
    setShowSurveyLiveConfirmationDialog,
    setSelectedItemQuestionId,
    selectedQuestionItemId,
    isGroupQuestion,
    setIsSaveButtonClicked,
  }: AddQuestionButtonsProps): React.ReactElement => {
    const spacing = useSpacing();
    const { t } = useTranslation();
    const surveyStore = useSurveyStore();
    const questionDetailsStore = useQuestionDetailsStore();
    const navigateTo = useRouteNavigator();

    return (
      <Stack
        direction="row"
        gap={spacing.spaceSM}
        divider={<DividerComponent height="24px" orientation="vertical" />}
      >
        <Button
          name="deleteQuestion"
          label={t("surveys.deleteQuestion.deleteButtonText")}
          variant="outlined-color"
          size="small"
          color="destructive"
          onClick={(): void => {
            setIsDeleteQuestionDialogOpen(true);
          }}
          disabled={
            surveyStore.isSurveyClosed || selectedQuestionItemId === null
          }
        />
        <>
          {isGroupQuestion ? (
            <></>
          ) : (
            <Button
              name="cloneQuestion"
              label={t("surveys.addSurveyQuestion.clone")}
              variant="outlined-neutral"
              size="small"
              disabled={questionDetailsStore.getIsCloneQuestionButtonDisabled(
                selectedQuestionItemId,
              )}
              onClick={() => {
                const selectedQuestion = clone(
                  surveyStore.getQuestionById(selectedQuestionItemId),
                );
                if (selectedQuestion) {
                  setSelectedItemQuestionId(null);
                  questionDetailsStore.setQuestionType(
                    selectedQuestion.surveyQuestionDetails.questionType,
                  );
                  questionDetailsStore.updatedQuestionDetails.basicQuestionDetails.setQuestionCode(
                    selectedQuestion.surveyQuestionDetails.questionCode,
                  );
                  surveyStore.cloneQuestion(selectedQuestion);
                }
              }}
            />
          )}
          <Button
            name="saveQuestion"
            label={t("surveys.addSurveyQuestion.save")}
            variant="filled"
            size="small"
            disabled={questionDetailsStore.isSaveButtonDisabled}
            onClick={async () => {
              setIsSaveButtonClicked(true);
              if (surveyStore.isSurveyActive) {
                setShowSurveyLiveConfirmationDialog(true);
              } else {
                const questionId = selectedQuestionItemId;
                if (questionId) {
                  await questionDetailsStore.updateQuestion(questionId, t);
                  if (questionDetailsStore.isSuccessfulDialogOpen) {
                    setShowAddOrUpdateSuccessfulDialog(true);
                  }
                } else {
                  processSurveyParams(async (surveyId, projectId) => {
                    const newlyAddedQuestionId =
                      await questionDetailsStore.addNewQuestion(
                        surveyId,
                        projectId,
                        t,
                      );
                    if (newlyAddedQuestionId !== undefined) {
                      setSelectedItemQuestionId(newlyAddedQuestionId);
                    }
                    if (questionDetailsStore.isSuccessfulDialogOpen) {
                      setShowAddOrUpdateSuccessfulDialog(true);
                    }
                  }, navigateTo);
                }
              }
            }}
          />
        </>
      </Stack>
    );
  },
);
