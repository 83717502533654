import { observer } from "mobx-react";
import React, { SetStateAction } from "react";
import { OpenEndedFieldCustomisationPane } from "../OpenEndedFieldCustomisationPane";
import { SingleSelectFieldCustomisationPane } from "../SingleSelectFieldCustomisationPane";
import { MultiSelectFieldCustomisationPane } from "../MultiSelectFieldCustomisationPane";
import { QuestionType } from "@pulse/pulse-rpcs";
import { RankingFieldCustomisationPane } from "../RankingFieldCustomisationPane";
import { GridQuestionCustomisationPane } from "../GridQuestionCustomisationPane";
import { Box, SxProps } from "@mui/material";
import { useBorder } from "@pulse/shared-components";
import { useSpacing } from "@surya-digital/leo-reactjs-material-ui";
import { Theme } from "@emotion/react";
import { GroupQuestionCustomisationPane } from "../GroupQuestionCustomisationPane";
import { useSurveyStore } from "../../../store/Hooks";

interface SelectedItemConfigurationPaneProps {
  setIsResetDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedItemQuestionId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  selectedQuestionItemId: string | null;
  setIsMessageBoxVisible: (value: SetStateAction<boolean>) => void;
  setMessageBoxQuestionItemId: (value: SetStateAction<string | null>) => void;
}

export const SelectedItemConfigurationPane = observer(
  ({
    setIsResetDialogOpen,
    setSelectedItemQuestionId,
    selectedQuestionItemId,
    setIsMessageBoxVisible,
    setMessageBoxQuestionItemId,
  }: SelectedItemConfigurationPaneProps): React.ReactElement => {
    const surveyStore = useSurveyStore();
    let questionType =
      selectedQuestionItemId !== null
        ? surveyStore.findQuestionById(selectedQuestionItemId)
            ?.surveyQuestionDetails?.questionType
        : undefined;
    if (questionType === undefined) {
      questionType =
        surveyStore.newlyAddedQuestion?.surveyQuestionDetails.questionType;
    }
    const border = useBorder();
    const spacing = useSpacing();

    const CustomisationPane = observer((): React.ReactElement => {
      switch (questionType) {
        case QuestionType.QuestionType.OPEN_ENDED:
          return (
            <OpenEndedFieldCustomisationPane
              setIsResetDialogOpen={setIsResetDialogOpen}
            />
          );
        case QuestionType.QuestionType.SINGLE_CHOICE:
          return (
            <SingleSelectFieldCustomisationPane
              setIsResetDialogOpen={setIsResetDialogOpen}
            />
          );
        case QuestionType.QuestionType.MULTIPLE_CHOICE:
          return (
            <MultiSelectFieldCustomisationPane
              setIsResetDialogOpen={setIsResetDialogOpen}
            />
          );
        case QuestionType.QuestionType.RANKING:
          return (
            <RankingFieldCustomisationPane
              setIsResetDialogOpen={setIsResetDialogOpen}
            />
          );
        case QuestionType.QuestionType.GRID:
          return (
            <GridQuestionCustomisationPane
              setIsResetDialogOpen={setIsResetDialogOpen}
            />
          );
        case QuestionType.QuestionType.MESSAGE:
          return <></>;
        case QuestionType.QuestionType.GROUP:
          return (
            <GroupQuestionCustomisationPane
              setSelectedQuestionItemId={setSelectedItemQuestionId}
              selectedQuestionItemId={selectedQuestionItemId}
              setIsMessageBoxVisible={setIsMessageBoxVisible}
              setMessageBoxQuestionItemId={setMessageBoxQuestionItemId}
            />
          );
        default:
          console.error(
            `Question type- ${questionType} was not found for the selected question.`,
          );
          return <></>;
      }
    });

    const boxStyle = (): SxProps<Theme> | undefined => {
      if (questionType !== QuestionType.QuestionType.MESSAGE) {
        return {
          borderTop: border.default,
          padding: `${spacing.space2XL} 0px`,
        };
      }
      return undefined;
    };

    return (
      <Box
        sx={{
          ...boxStyle(),
          borderBottom: border.default,
          marginX: spacing.space2XL,
        }}
      >
        <CustomisationPane />
      </Box>
    );
  },
);
