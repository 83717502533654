import {
  EMPTY_CHARACTER,
  MAX_USERNAME_CHAR_LIMIT,
  MIN_USERNAME_CHAR_LIMIT,
} from "@pulse/shared-components";
import { Instance, types } from "mobx-state-tree";
import { BoUserRole } from "@pulse/pulse-rpcs";
import { FIRST_AND_LAST_NAME_REGEX } from "@pulse/shared-components";

export const BOUserDetailsModel = types
  .model("BOUserDetailsModel", {
    firstName: types.optional(types.string, EMPTY_CHARACTER),
    lastName: types.optional(types.string, EMPTY_CHARACTER),
    selectedRole: types.maybeNull(
      types.enumeration(
        "BoUserRole.BoUserRole",
        Object.values(BoUserRole.BoUserRole),
      ),
    ),
  })
  .views((store) => ({
    get areUserDetailFieldsEmpty(): boolean {
      return (
        store.firstName === EMPTY_CHARACTER ||
        store.lastName === EMPTY_CHARACTER ||
        store.selectedRole === null
      );
    },
    areValuesUnchanged(
      firstName?: string,
      lastName?: string,
      role?: BoUserRole.BoUserRole,
    ): boolean {
      return (
        store.firstName === (firstName ?? EMPTY_CHARACTER) &&
        store.lastName === (lastName ?? EMPTY_CHARACTER) &&
        store.selectedRole === (role ?? null)
      );
    },
    get isFirstNameInvalid(): boolean {
      const userNameRegex = new RegExp(FIRST_AND_LAST_NAME_REGEX);
      return (
        store.firstName.length > MAX_USERNAME_CHAR_LIMIT ||
        store.firstName.length < MIN_USERNAME_CHAR_LIMIT ||
        !userNameRegex.test(store.firstName)
      );
    },
    get isLastNameInvalid(): boolean {
      const userNameRegex = new RegExp(FIRST_AND_LAST_NAME_REGEX);
      return (
        store.lastName.length > MAX_USERNAME_CHAR_LIMIT ||
        store.lastName.length < MIN_USERNAME_CHAR_LIMIT ||
        !userNameRegex.test(store.lastName)
      );
    },
  }))
  .actions((store) => ({
    resetStore: (): void => {
      store.firstName = EMPTY_CHARACTER;
      store.lastName = EMPTY_CHARACTER;
      store.selectedRole = null;
    },
    setFirstName: (firstName: string): void => {
      store.firstName = firstName;
    },
    setLastName: (lastName: string): void => {
      store.lastName = lastName;
    },
    resetRole: (): void => {
      store.selectedRole = null;
    },
    setRole: (role: BoUserRole.BoUserRole): void => {
      store.selectedRole = role;
    },
  }));

export const createBOUserDetailsModel = (): Instance<
  typeof BOUserDetailsModel
> => {
  return BOUserDetailsModel.create();
};
