import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React from "react";
import { AssignSpocsToRespondentsDialogState } from "../../store/RespondentStore";
import { Instance } from "mobx-state-tree";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { BorderStyle } from "@pulse/shared-components";
import { DialogLoaderContent } from "../DialogLoaderContent";
import { AssignRespondentsToSpocsDialogSuccess } from "./AssignRespondentsToSpocsDialogSuccess";
import { AssignRespondentsToSpocDialogError } from "./AssignRespondentsToSpocDialogError";
import { AssignAndViewSpocSelection } from "./AssignRespondentSelection";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";

export enum DialogType {
  AssignSpocs = "ASSIGN_SPOCS",
  ViewSpocs = "VIEW_SPOCS",
}

export interface SelectedSpocListProps {
  selectedSpocListPadding: string | undefined;
  selectedSpocListGap: string;
  selectedSpocElementPadding: string | undefined;
}

export interface SearchSpocFieldProps {
  searchSpocFieldGap: string | undefined;
  searchSpocFieldPadding: string | undefined;
  searchSpocFieldDescription: string | undefined;
  searchSpocFieldTitle: string | undefined;
  isSearchProjectSpocFieldDisabled: boolean;
}

export interface AssignAndViewSpocsDialogProps
  extends SearchSpocFieldProps,
    SelectedSpocListProps {
  isAssignAndViewSpocsDialogOpen: boolean;
  setIsAssignAndViewSpocsDialogOpen: (isDialogVisible: boolean) => void;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  dialogType: DialogType;
  navigateTo: NavigateToFunctions;
  border: BorderStyle;
  projectDetailsStore: Instance<typeof ProjectDetailsStore>;
  noSpocsFoundText: string;
  isSpocListEmpty: boolean;
}

export const AssignAndViewSpocsDialog = observer(
  ({
    isAssignAndViewSpocsDialogOpen,
    setIsAssignAndViewSpocsDialogOpen,
    t,
    navigateTo,
    spacing,
    typography,
    tokens,
    border,
    dialogType,
    projectDetailsStore,
    searchSpocFieldDescription,
    searchSpocFieldGap,
    searchSpocFieldPadding,
    selectedSpocListPadding,
    selectedSpocListGap,
    selectedSpocElementPadding,
    noSpocsFoundText,
    searchSpocFieldTitle,
    isSpocListEmpty,
    isSearchProjectSpocFieldDisabled,
  }: AssignAndViewSpocsDialogProps): React.ReactElement => {
    const DialogChild = observer((): React.ReactElement => {
      switch (
        projectDetailsStore.respondentStore
          .assignAndViewSpocsToRespondentsDialogState
      ) {
        case AssignSpocsToRespondentsDialogState.FetchingSpocDetails: {
          return (
            <DialogLoaderContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              loaderText={t("projects.commonTexts.fetchingSpocsText")}
            />
          );
        }
        case AssignSpocsToRespondentsDialogState.SelectingSpocs: {
          return (
            <AssignAndViewSpocSelection
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              t={t}
              border={border}
              dialogType={dialogType}
              projectDetailsStore={projectDetailsStore}
              searchSpocFieldDescription={searchSpocFieldDescription}
              searchSpocFieldGap={searchSpocFieldGap}
              searchSpocFieldPadding={searchSpocFieldPadding}
              noSpocsFoundContainerGap={spacing.spaceLG}
              noSpocsFoundContainerPadding={spacing.spaceSM}
              isSearchProjectSpocFieldDisabled={
                isSearchProjectSpocFieldDisabled
              }
              selectedSpocListPadding={selectedSpocListPadding}
              selectedSpocListGap={selectedSpocListGap}
              selectedSpocElementPadding={selectedSpocElementPadding}
              matchedSpocs={
                projectDetailsStore.respondentStore.matchedRespondentSpocs
              }
              noSpocsFoundText={noSpocsFoundText}
              selectedRespondentOrEmbedName={
                projectDetailsStore.respondentStore.selectedRespondentDetails
                  ?.respondentDetails.fullRespondentName
              }
              searchSpocFieldTitle={searchSpocFieldTitle}
              isSpocListEmpty={isSpocListEmpty}
            />
          );
        }
        case AssignSpocsToRespondentsDialogState.AssignSuccess: {
          return (
            <AssignRespondentsToSpocsDialogSuccess
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              respondentsAssigned={
                projectDetailsStore.respondentStore.totalAssignedRespondents
              }
            />
          );
        }
        case AssignSpocsToRespondentsDialogState.AssignFailed: {
          return (
            <AssignRespondentsToSpocDialogError
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              respondentStore={projectDetailsStore.respondentStore}
              t={t}
            />
          );
        }
      }
    });

    const getDialogTitle = (): string => {
      switch (dialogType) {
        case DialogType.ViewSpocs: {
          return t("projects.viewSpocs.dialogTitleText");
        }
        case DialogType.AssignSpocs: {
          return t("projects.assignSpocsToRespondents.dialogTitleText");
        }
      }
    };
    const getSecondaryButtonText = (): string => {
      switch (
        projectDetailsStore.respondentStore
          .assignAndViewSpocsToRespondentsDialogState
      ) {
        case AssignSpocsToRespondentsDialogState.SelectingSpocs:
        case AssignSpocsToRespondentsDialogState.FetchingSpocDetails: {
          if (dialogType === DialogType.ViewSpocs) {
            return t("common.close");
          }
          return t("common.cancel");
        }
        case AssignSpocsToRespondentsDialogState.AssignFailed: {
          return t("common.close");
        }
        case AssignSpocsToRespondentsDialogState.AssignSuccess: {
          return t("common.done");
        }
      }
    };

    const getPrimaryButtonText = (): string | undefined => {
      if (dialogType === DialogType.ViewSpocs) {
        return;
      }
      switch (
        projectDetailsStore.respondentStore
          .assignAndViewSpocsToRespondentsDialogState
      ) {
        case AssignSpocsToRespondentsDialogState.SelectingSpocs:
        case AssignSpocsToRespondentsDialogState.FetchingSpocDetails: {
          return t(
            "projects.assignSpocsToRespondents.assignSpocsPrimaryButtonText",
          );
        }
        case AssignSpocsToRespondentsDialogState.AssignFailed:
        case AssignSpocsToRespondentsDialogState.AssignSuccess: {
          return undefined;
        }
      }
    };

    const getDialogPadding = (): string => {
      switch (
        projectDetailsStore.respondentStore
          .assignAndViewSpocsToRespondentsDialogState
      ) {
        case AssignSpocsToRespondentsDialogState.SelectingSpocs:
        case AssignSpocsToRespondentsDialogState.FetchingSpocDetails: {
          return "0px";
        }
        case AssignSpocsToRespondentsDialogState.AssignFailed:
        case AssignSpocsToRespondentsDialogState.AssignSuccess: {
          return spacing.spaceLG;
        }
      }
    };

    return (
      <Dialog
        width="560px"
        open={isAssignAndViewSpocsDialogOpen}
        title={getDialogTitle()}
        secondaryButtonText={getSecondaryButtonText()}
        isSecondaryButtonDisabled={
          projectDetailsStore.respondentStore.isRPCLoading
        }
        onSecondaryButtonClick={() => {
          setIsAssignAndViewSpocsDialogOpen(false);
          projectDetailsStore.respondentStore.resetAssignSpocDialog();
        }}
        isPrimaryButtonDisabled={projectDetailsStore.isSelectedSpocListEmpty}
        primaryButtonText={getPrimaryButtonText()}
        onPrimaryButtonClick={() =>
          processProjectParams(
            projectDetailsStore.respondentStore.assignSpocsToRespondents,
            navigateTo,
          )
        }
        disableBackdropClick={true}
        contentPadding={getDialogPadding()}
      >
        <DialogChild />
      </Dialog>
    );
  },
);
