import { observer } from "mobx-react";
import React from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import { TFunction } from "i18next";
import {
  DropdownItem,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { DropdownInputField } from "@surya-digital/leo-reactjs-material-ui";
import { Stack } from "@mui/material";
import { Instance } from "mobx-state-tree";
import { SurveyLanguageModel } from "@pulse/shared-components";
import { SettingsCheckboxComponent } from "./SettingsCheckboxComponent";

interface LanguagePreferencesSettingsProps {
  setSelectedLanguageCode: (selectedLanguageCode: string) => void;
  selectedLanguageId: string | undefined;
  languageList: Instance<typeof SurveyLanguageModel>[];
  isLanguageSelectionOptionPersistent: boolean;
  isLanguageSelectionDisplayedOnStart: boolean;
  setIsLanguageSelectionOptionPersistent: (
    isLanguageSelectionOptionPersistent: boolean,
  ) => void;
  setIsLanguageSelectionDisplayedOnStart: (
    isLanguageSelectionDisplayedOnStart: boolean,
  ) => void;
  t: TFunction;
  spacing: Spacing;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
}

export const LanguagePreferencesSettings = observer(
  ({
    setSelectedLanguageCode,
    selectedLanguageId,
    languageList,
    isLanguageSelectionOptionPersistent,
    isLanguageSelectionDisplayedOnStart,
    setIsLanguageSelectionOptionPersistent,
    setIsLanguageSelectionDisplayedOnStart,
    t,
    spacing,
    typography,
    tokens,
  }: LanguagePreferencesSettingsProps): React.ReactElement => {
    return (
      <SettingsContainer
        settingHeadingText={t(
          "surveys.settings.languagePreferencesSettings.languagePreferencesTitleText",
        )}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
      >
        <Stack width="400px" gap={spacing.spaceLG}>
          <DropdownInputField
            name="surveyLanguage"
            label={t(
              "surveys.settings.languagePreferencesSettings.defaultLanguageDropdownLabelText",
            )}
            placeholder={t(
              "surveys.settings.languagePreferencesSettings.defaultLanguageDropdownPlaceholderText",
            )}
            value={selectedLanguageId}
            options={languageList.map((language) => {
              return {
                name: language.languageCode,
                value: language.languageId,
              };
            })}
            onSelect={(language: DropdownItem) => {
              setSelectedLanguageCode(language.name);
            }}
          />
          <Stack width="100%">
            <SettingsCheckboxComponent
              isCheckboxSelected={isLanguageSelectionOptionPersistent}
              setIsCheckboxSelected={setIsLanguageSelectionOptionPersistent}
              checkboxlabel={t(
                "surveys.settings.languagePreferencesSettings.persistentLanguageCheckboxLabelText",
              )}
              checkboxDescription={t(
                "surveys.settings.languagePreferencesSettings.persistentLanguageCheckboxDescriptionText",
              )}
              spacing={spacing}
              typography={typography}
              tokens={tokens}
            />
            <SettingsCheckboxComponent
              isCheckboxSelected={isLanguageSelectionDisplayedOnStart}
              setIsCheckboxSelected={setIsLanguageSelectionDisplayedOnStart}
              checkboxlabel={t(
                "surveys.settings.languagePreferencesSettings.defaultLanguageCheckboxLabelText",
              )}
              checkboxDescription={t(
                "surveys.settings.languagePreferencesSettings.defaultLanguageCheckboxDescriptionText",
              )}
              spacing={spacing}
              typography={typography}
              tokens={tokens}
            />
          </Stack>
        </Stack>
      </SettingsContainer>
    );
  },
);
