import { GetProjectBOUsersPaginationResponse } from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";

export const ManageProjectBOUsersDetailsModel = types.model(
  "ManageProjectBOUsersDetailsModel",
  {
    fullName: types.string,
    emailAddress: types.string,
    boUserId: types.string,
    projectId: types.string,
    createdOn: types.Date,
    role: types.string,
  },
);

export const createManageProjectBOUsersDetailsModel = (
  boUserDetails: GetProjectBOUsersPaginationResponse,
): Instance<typeof ManageProjectBOUsersDetailsModel> => {
  return ManageProjectBOUsersDetailsModel.create({
    createdOn: new Date(boUserDetails.addedOn.timestamp),
    emailAddress: boUserDetails.emailAddress,
    fullName: boUserDetails.name,
    projectId: boUserDetails.projectId.uuid,
    boUserId: boUserDetails.boUserId.uuid,
    role: boUserDetails.role,
  });
};
