import { observer } from "mobx-react";
import React from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Stack } from "@mui/material";
import { SettingsCheckboxComponent } from "./SettingsCheckboxComponent";
import { TFunction } from "i18next";

interface SurveyControlPreferencesSettingsProps {
  isAutoProgressEnabled: boolean;
  setIsAutoProgressEnabled: (isAutoProgressEnabled: boolean) => void;
  isProgressPercentageShown: boolean;
  setIsProgressPercentageShown: (isProgressPercentageShown: boolean) => void;
  isIpTracked: boolean;
  setIsIpTracked: (isIpTracked: boolean) => void;
  isResponseResetAllowed: boolean;
  setIsResponseResetAllowed: (isResponseResetAllowed: boolean) => void;
  isRespondentConsentRequired: boolean;
  setIsRespondentConsentRequired: (
    isRespondentConsentRequired: boolean,
  ) => void;
  isRatingScaleOptionVisible: boolean;
  setIsRatingScaleOptionVisible: (isRatingScaleOptionVisible: boolean) => void;
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}

export const SurveyControlPreferencesSettings = observer(
  ({
    isAutoProgressEnabled,
    setIsAutoProgressEnabled,
    isProgressPercentageShown,
    setIsProgressPercentageShown,
    isIpTracked,
    setIsIpTracked,
    isResponseResetAllowed,
    setIsResponseResetAllowed,
    isRespondentConsentRequired,
    setIsRespondentConsentRequired,
    isRatingScaleOptionVisible,
    setIsRatingScaleOptionVisible,
    t,
    spacing,
    typography,
    tokens,
  }: SurveyControlPreferencesSettingsProps): React.ReactElement => {
    return (
      <SettingsContainer
        settingHeadingText={t(
          "surveys.settings.surveyControlPreferencesSettings.surveyControlPreferencesTitleText",
        )}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
      >
        <Stack width="400px">
          <SettingsCheckboxComponent
            isCheckboxSelected={isAutoProgressEnabled}
            setIsCheckboxSelected={setIsAutoProgressEnabled}
            checkboxlabel={t(
              "surveys.settings.surveyControlPreferencesSettings.autoProgressLabelText",
            )}
            checkboxDescription={t(
              "surveys.settings.surveyControlPreferencesSettings.autoProgressDescriptionText",
            )}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
          />
          <SettingsCheckboxComponent
            isCheckboxSelected={isProgressPercentageShown}
            setIsCheckboxSelected={setIsProgressPercentageShown}
            checkboxlabel={t(
              "surveys.settings.surveyControlPreferencesSettings.showProgressPercentageLabelText",
            )}
            checkboxDescription={t(
              "surveys.settings.surveyControlPreferencesSettings.showProgressPercentageDescriptionText",
            )}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
          />
          <SettingsCheckboxComponent
            isCheckboxSelected={!isIpTracked}
            setIsCheckboxSelected={setIsIpTracked}
            checkboxlabel={t(
              "surveys.settings.surveyControlPreferencesSettings.donotTrackIpLabelText",
            )}
            checkboxDescription={t(
              "surveys.settings.surveyControlPreferencesSettings.donotTrackIpDescriptionText",
            )}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
          />
          <SettingsCheckboxComponent
            isCheckboxSelected={isRespondentConsentRequired}
            setIsCheckboxSelected={setIsRespondentConsentRequired}
            checkboxlabel={t(
              "surveys.settings.surveyControlPreferencesSettings.askRespondentConsentLabelText",
            )}
            checkboxDescription={t(
              "surveys.settings.surveyControlPreferencesSettings.askRespondentConsentDescriptionText",
            )}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
          />
          <SettingsCheckboxComponent
            isCheckboxSelected={isRatingScaleOptionVisible}
            setIsCheckboxSelected={setIsRatingScaleOptionVisible}
            checkboxlabel={t(
              "surveys.settings.surveyControlPreferencesSettings.showRatingScaleOptionText",
            )}
            checkboxDescription={t(
              "surveys.settings.surveyControlPreferencesSettings.showRatingScaleOptionDescriptionText",
            )}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
          />
          <SettingsCheckboxComponent
            isCheckboxSelected={isResponseResetAllowed}
            setIsCheckboxSelected={setIsResponseResetAllowed}
            checkboxlabel={t(
              "surveys.settings.surveyControlPreferencesSettings.resetOptionControlInQUestionnaireText",
            )}
            checkboxDescription={t(
              "surveys.settings.surveyControlPreferencesSettings.resetOptionControlInQUestionnaireDescriptionText",
            )}
            spacing={spacing}
            typography={typography}
            tokens={tokens}
          />
        </Stack>
      </SettingsContainer>
    );
  },
);
