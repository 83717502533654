import {
  GetPresignedDocumentUploadURLForRespondentsRPC,
  GetPresignedDocumentUploadURLForRespondentsRPCClientImpl,
  GetRespondentDetailsRPC,
  GetRespondentDetailsRPCClientImpl,
  ValidateUniqueColumnAndUpdateRespondentRPC,
  ValidateUniqueColumnAndUpdateRespondentRPCClientImpl,
  InsertDocumentRecordRPC,
  InsertDocumentRecordRPCClientImpl,
  GetPresignedDownloadURLForRespondentTemplateRPC,
  GetPresignedDownloadURLForRespondentTemplateRPCClientImpl,
  GetProjectSurveyResultsRPC,
  GetProjectSurveyResultsRPCClientImpl,
  UnassignSurveyRPC,
  UnassignSurveyRPCClientImpl,
  GetProjectSurveyNamesRPC,
  GetProjectSurveyNamesRPCClientImpl,
  RequestSurveyReportRPC,
  RequestSurveyReportRPCClientImpl,
  CreateNewSurveyRPC,
  CreateNewSurveyRPCClientImpl,
  EditRespondentDetailsRPCClientImpl,
  EditRespondentDetailsRPC,
  AssignSurveyRPC,
  AssignSurveyRPCClientImpl,
  DeleteColumnRPC,
  DeleteColumnRPCClientImpl,
  DeleteRespondentRPC,
  DeleteRespondentRPCClientImpl,
  GetPresignedDownloadURLForRespondentsWithFiltersRPC,
  GetPresignedDownloadURLForRespondentsWithFiltersRPCClientImpl,
  GetAllActiveProjectsRPC,
  GetAllActiveProjectsRPCClientImpl,
  GetAllArchivedProjectsRPC,
  GetAllArchivedProjectsRPCClientImpl,
  CreateNewProjectRPC,
  CreateNewProjectRPCClientImpl,
  ArchiveProjectRPC,
  ArchiveProjectRPCClientImpl,
  GetProjectSpocDetailsRPC,
  GetProjectSpocDetailsRPCClientImpl,
  AssignSpocsToRespondentsRPC,
  AssignSpocsToRespondentsRPCClientImpl,
  GetProjectSpocsRPC,
  GetProjectSpocsRPCClientImpl,
  GetArchivedSpocProjectsRPCClientImpl,
  GetActiveSpocProjectsRPCClientImpl,
  GetActiveSpocProjectsRPC,
  GetArchivedSpocProjectsRPC,
  AddSpocToProjectRPC,
  AddSpocToProjectRPCClientImpl,
  GetSpocDetailsRPCClientImpl,
  GetSpocDetailsRPC,
  UnassignRespondentFromSpocRPC,
  UnassignRespondentFromSpocRPCClientImpl,
  UpdateSpocNameRPC,
  UpdateSpocNameRPCClientImpl,
  ResetSpocPasswordRPC,
  ResetSpocPasswordRPCClientImpl,
  ChangeSpocPasswordRPC,
  ChangeSpocPasswordRPCClientImpl,
  DeleteProjectSpocsRPC,
  DeleteProjectSpocsRPCClientImpl,
  GetRespondentSpocsRPC,
  GetRespondentSpocsRPCClientImpl,
  GetProjectBOUsersRPC,
  GetProjectBOUsersRPCClientImpl,
  GetBOUserDetailsRPCClientImpl,
  GetBOUserDetailsRPC,
  AddBOUserToProjectRPC,
  AddBOUserToProjectRPCClientImpl,
  UpdateProjectDetailsRPCClientImpl,
  UpdateProjectDetailsRPC,
  DeleteProjectBOUsersRPC,
  DeleteProjectBOUsersRPCClientImpl,
  CloneSurveyRPC,
  CloneSurveyRPCClientImpl,
  CloneProjectRPC,
  CloneProjectRPCClientImpl,
  GetRespondentColumnNamesRPC,
  GetRespondentColumnNamesRPCClientImpl,
  GetPivotTableDataRPC,
  GetPivotTableDataRPCClientImpl,
  GetActiveResearcherProjectsRPC,
  GetActiveResearcherProjectsRPCClientImpl,
  GetArchivedResearcherProjectsRPC,
  GetArchivedResearcherProjectsRPCClientImpl,
} from "@pulse/pulse-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "@pulse/shared-components";
import { MockGetRespondentDetailsClientImpl } from "./MockGetRespondentDetails";
import { MockGetPresignedDocumentUploadURLForRespondentsClientImpl } from "./MockGetPresignedDocumentUploadURLForRespondents";
import { MockValidateUniqueColumnAndUpdateRespondentClientImpl } from "./MockValidateUniqueColumnAndUpdateRespondent";
import { MockInsertDocumentRecordClientImpl } from "./MockInsertDocumentRecord";
import { MockGetPresignedDownloadURLForRespondentTemplateClientImpl } from "./MockGetPresignedDownloadURLForRespondentTemplate";
import { MockGetProjectSurveyResultsClientImpl } from "./MockGetProjectSurveyResultsClientImpl";
import { MockUnassignSurveyRPCClientImpl } from "./MockUnassignSurveyRPCClientImpl";
import { MockGetProjectSurveyNamesRPCClientImpl } from "./MockGetProjectSurveyNames";
import { MockRequestSurveyReportRPCClientImpl } from "./MockRequestSurveyReportRPCClientImpl";
import { MockCreateNewSurveyClientImpl } from "./MockCreateNewSurveyClientImpl";
import { MockEditRespondentDetailsClientImpl } from "./MockEditRespondentDetailsClientImpl";
import { MockAssignSurveyRPCClientImpl } from "./MockAssignSurvey";
import { MockDeleteColumnRPCClientImpl } from "./MockDeleteColumn";
import { MockDeleteRespondentRPCClientImpl } from "./MockDeleteRespondent";
import { MockGetPresignedDownloadURLForRespondentsWithFiltersClientImpl } from "./MockGetPresignedDownloadURLForRespondentsWithFilters";
import { MockGetAllActiveProjectsRPCClientImpl } from "./MockGetAllActiveProjectsRPCClientImpl";
import { MockGetAllArchivedProjectsRPCClientImpl } from "./MockGetAllArchivedProjectsRPCClientImpl";
import { MockCreateNewProjectRPCClientImpl } from "./MockCreateNewProjectRPCClientImpl";
import { MockArchiveProjectRPCClientImpl } from "./MockArchiveProjectRPCClientImpl";
import { MockGetProjectSpocDetailsRPCClientImpl } from "./MockGetProjectSpocDetailsRPCClientImpl";
import { MockAssignSpocsToRespondentsRPCClientImpl } from "./MockAssignSpocsToRespondentsRPCClientImpl";
import { MockGetProjectSpocsRPCClientImpl } from "./MockGetProjectSpocsRPCClientImpl";
import { MockGetArchivedSpocProjectsRPCClienImpl } from "./MockGetArchivedSpocProjectsRPCClientImpl";
import { MockGetActiveSpocProjectsRPCClientImpl } from "./MockGetActiveSpocProjectsRPCClientImpl";
import { MockAddSpocToProjectRPCClientImpl } from "./MockAddSpocToProjectRPCClientImpl";
import { MockGetSpocDetailsRPCClientImpl } from "./MockGetSpocDetailsRPCClientImpl";
import { MockUnassignRespondentFromSpocRPCClientImpl } from "./MockUnassignRespondentFromSpocRPCClientImpl";
import { MockUpdateSpocNameRPCClientImpl } from "./MockUpdateSpocNameRPCClientImpl";
import { MockResetSpocPasswordRPCClientImpl } from "./MockResetSpocPasswordRPCClientImpl";
import { MockChangeSpocPasswordRPCClientImpl } from "./MockChangeSpocPasswordRPCClientImpl";
import { MockDeleteProjectSpocsRPCClientImpl } from "./MockDeleteProjectSpocsRPCClientImpl";
import { MockGetRespondentSpocsRPCClientImpl } from "./MockGetRespondentSpocsRPCClientImpl";
import { MockGetProjectBOUsersRPCClientImpl } from "./MockGetProjectBOUsersRPCClientImpl";
import { ReopenSurveyLinksRPC } from "@pulse/pulse-rpcs";
import { ReopenSurveyLinksRPCClientImpl } from "@pulse/pulse-rpcs";
import { MockReopenSurveyLinksRPCClientImpl } from "./MockReopenSurveyLinksRPCClientImpl";
import { MockGetBOUserDetailsRPCClientImpl } from "./MockGetBOUserDetailsRPCClientImpl";
import { MockAddBOUserToProjectRPCClientImpl } from "./MockAddBOUserToProjectRPCClientImpl";
import { MockUpdateProjectDetailsRPCClientImpl } from "./MockUpdateProjectDetailsRPCClientImpl";
import { MockDeleteProjectBOUsersRPCClientImpl } from "./MockDeleteProjectBOUsersRPCClientImpl";
import { MockCloneSurveyRPCClientImpl } from "./MockCloneSurveyRPCClient";
import { MockCloneProjectProjectRPCClientImpl } from "./MockCloneProjectProjectRPCClientImpl";
import { MockGetRespondentColumnNamesRPCClientImpl } from "./MockGetRespondentColumnNamesRPCClientImpl";
import { MockGetPivotTableDataRPCClientImpl } from "./MockGetPivotTableDataRPCClientImpl";
import { MockGetActiveResearcherProjectsRPCClientImpl } from "./MockGetActiveResearcherProjectsRPCClientImpl";
import { MockGetArchivedResearcherProjectsRPCClientImpl } from "./MockGetArchivedResearcherProjectsRPCClientImpl";
import { MockGetProjectDetails } from "./MockGetProjectDetails";
import {
  GetProjectDetailsRPC,
  GetProjectDetailsRPCClientImpl,
} from "@pulse/pulse-rpcs";

export const useGetRespondentDetailsRPCClientImpl = (
  apiClient: APIClient,
): GetRespondentDetailsRPC => {
  if (MOCK.respondentDetails) {
    return new MockGetRespondentDetailsClientImpl();
  } else {
    return new GetRespondentDetailsRPCClientImpl(apiClient);
  }
};

export const useGetPresignedDocumentUploadURLForRespondentsRPCClientImpl = (
  apiClient: APIClient,
): GetPresignedDocumentUploadURLForRespondentsRPC => {
  if (MOCK.respondentDetails) {
    return new MockGetPresignedDocumentUploadURLForRespondentsClientImpl();
  } else {
    return new GetPresignedDocumentUploadURLForRespondentsRPCClientImpl(
      apiClient,
    );
  }
};

export const useValidateUniqueColumnAndUpdateRespondentClientImpl = (
  apiClient: APIClient,
): ValidateUniqueColumnAndUpdateRespondentRPC => {
  if (MOCK.respondentDetails) {
    return new MockValidateUniqueColumnAndUpdateRespondentClientImpl();
  } else {
    return new ValidateUniqueColumnAndUpdateRespondentRPCClientImpl(apiClient);
  }
};

export const useInsertDocumentRecordRPCClientImpl = (
  apiClient: APIClient,
): InsertDocumentRecordRPC => {
  if (MOCK.respondentDetails) {
    return new MockInsertDocumentRecordClientImpl();
  }
  return new InsertDocumentRecordRPCClientImpl(apiClient);
};

export const useGetPresignedDownloadURLForRespondentTemplateRPCClient = (
  apiClient: APIClient,
): GetPresignedDownloadURLForRespondentTemplateRPC => {
  if (MOCK.respondentDetails) {
    return new MockGetPresignedDownloadURLForRespondentTemplateClientImpl();
  }
  return new GetPresignedDownloadURLForRespondentTemplateRPCClientImpl(
    apiClient,
  );
};

export const useGetProjectSurveyResultsRPCClient = (
  apiClient: APIClient,
): GetProjectSurveyResultsRPC => {
  if (MOCK.surveyResults) {
    return new MockGetProjectSurveyResultsClientImpl();
  }
  return new GetProjectSurveyResultsRPCClientImpl(apiClient);
};

export const useUnassignSurveyRPCClientImpl = (
  apiClient: APIClient,
): UnassignSurveyRPC => {
  if (MOCK.respondentDetails) {
    return new MockUnassignSurveyRPCClientImpl();
  } else {
    return new UnassignSurveyRPCClientImpl(apiClient);
  }
};

export const useGetProjectSurveyNamesRPCClientImpl = (
  apiClient: APIClient,
): GetProjectSurveyNamesRPC => {
  if (MOCK.reportDetails) {
    return new MockGetProjectSurveyNamesRPCClientImpl();
  }
  return new GetProjectSurveyNamesRPCClientImpl(apiClient);
};

export const useRequestSurveyReportRPCClientImpl = (
  apiClient: APIClient,
): RequestSurveyReportRPC => {
  if (MOCK.reportDetails) {
    return new MockRequestSurveyReportRPCClientImpl();
  }
  return new RequestSurveyReportRPCClientImpl(apiClient);
};

export const useCreateNewSurveyRPCClientImpl = (
  apiClient: APIClient,
): CreateNewSurveyRPC => {
  if (MOCK.survey) {
    return new MockCreateNewSurveyClientImpl();
  }
  return new CreateNewSurveyRPCClientImpl(apiClient);
};

export const useEditRespondentDetailsRPCClientImpl = (
  apiClient: APIClient,
): EditRespondentDetailsRPC => {
  if (MOCK.survey) {
    return new MockEditRespondentDetailsClientImpl();
  }
  return new EditRespondentDetailsRPCClientImpl(apiClient);
};

export const useAssignSurveyRPCClientImpl = (
  apiClient: APIClient,
): AssignSurveyRPC => {
  if (MOCK.respondentDetails) {
    return new MockAssignSurveyRPCClientImpl();
  }
  return new AssignSurveyRPCClientImpl(apiClient);
};

export const useDeleteColumnRPCClientImpl = (
  apiClient: APIClient,
): DeleteColumnRPC => {
  if (MOCK.respondentDetails) {
    return new MockDeleteColumnRPCClientImpl();
  }
  return new DeleteColumnRPCClientImpl(apiClient);
};

export const useDeleteRespondentRPCClientImpl = (
  apiClient: APIClient,
): DeleteRespondentRPC => {
  if (MOCK.respondentDetails) {
    return new MockDeleteRespondentRPCClientImpl();
  }
  return new DeleteRespondentRPCClientImpl(apiClient);
};

export const useGetPresignedDownloadURLForRespondentsWithFiltersRPCClient = (
  apiClient: APIClient,
): GetPresignedDownloadURLForRespondentsWithFiltersRPC => {
  if (MOCK.respondentDetails) {
    return new MockGetPresignedDownloadURLForRespondentsWithFiltersClientImpl();
  }
  return new GetPresignedDownloadURLForRespondentsWithFiltersRPCClientImpl(
    apiClient,
  );
};

export const useGetAllActiveProjectsRPCClient = (
  apiClient: APIClient,
): GetAllActiveProjectsRPC => {
  if (MOCK.projectList) {
    return new MockGetAllActiveProjectsRPCClientImpl();
  } else {
    return new GetAllActiveProjectsRPCClientImpl(apiClient);
  }
};

export const useGetActiveResearcherProjectsRPCClient = (
  apiClient: APIClient,
): GetActiveResearcherProjectsRPC => {
  if (MOCK.projectList) {
    return new MockGetActiveResearcherProjectsRPCClientImpl();
  } else {
    return new GetActiveResearcherProjectsRPCClientImpl(apiClient);
  }
};

export const useGetProjectSpocsRPCClient = (
  apiClient: APIClient,
): GetProjectSpocsRPC => {
  if (MOCK.userAccess) {
    return new MockGetProjectSpocsRPCClientImpl();
  } else {
    return new GetProjectSpocsRPCClientImpl(apiClient);
  }
};

export const useGetActiveSpocProjectsRPCClient = (
  apiClient: APIClient,
): GetActiveSpocProjectsRPC => {
  if (MOCK.projectList) {
    return new MockGetActiveSpocProjectsRPCClientImpl();
  } else {
    return new GetActiveSpocProjectsRPCClientImpl(apiClient);
  }
};

export const useGetArchivedSpocProjectsRPCClient = (
  apiClient: APIClient,
): GetArchivedSpocProjectsRPC => {
  if (MOCK.projectList) {
    return new MockGetArchivedSpocProjectsRPCClienImpl();
  } else {
    return new GetArchivedSpocProjectsRPCClientImpl(apiClient);
  }
};

export const useGetAllArchivedProjectsRPCClient = (
  apiClient: APIClient,
): GetAllArchivedProjectsRPC => {
  if (MOCK.projectList) {
    return new MockGetAllArchivedProjectsRPCClientImpl();
  } else {
    return new GetAllArchivedProjectsRPCClientImpl(apiClient);
  }
};

export const useGetArchivedResearcherProjectsRPCClient = (
  apiClient: APIClient,
): GetArchivedResearcherProjectsRPC => {
  if (MOCK.projectList) {
    return new MockGetArchivedResearcherProjectsRPCClientImpl();
  } else {
    return new GetArchivedResearcherProjectsRPCClientImpl(apiClient);
  }
};

export const useCreateNewProjectRPCClientImpl = (
  apiClient: APIClient,
): CreateNewProjectRPC => {
  if (MOCK.projectList) {
    return new MockCreateNewProjectRPCClientImpl();
  } else {
    return new CreateNewProjectRPCClientImpl(apiClient);
  }
};

export const useCloneProjectRPCClientImpl = (
  apiClient: APIClient,
): CloneProjectRPC => {
  if (MOCK.projectList) {
    return new MockCloneProjectProjectRPCClientImpl();
  } else {
    return new CloneProjectRPCClientImpl(apiClient);
  }
};

export const useArchiveProjectRPCClientImpl = (
  apiClient: APIClient,
): ArchiveProjectRPC => {
  if (MOCK.projectList) {
    return new MockArchiveProjectRPCClientImpl();
  } else {
    return new ArchiveProjectRPCClientImpl(apiClient);
  }
};

export const useGetProjectSpocDetailsRPCClientImpl = (
  apiClient: APIClient,
): GetProjectSpocDetailsRPC => {
  if (MOCK.spocManagement) {
    return new MockGetProjectSpocDetailsRPCClientImpl();
  } else {
    return new GetProjectSpocDetailsRPCClientImpl(apiClient);
  }
};

export const useGetRespondentSpocsRPCClientImpl = (
  apiClient: APIClient,
): GetRespondentSpocsRPC => {
  if (MOCK.spocManagement) {
    return new MockGetRespondentSpocsRPCClientImpl();
  } else {
    return new GetRespondentSpocsRPCClientImpl(apiClient);
  }
};

export const useAssignSpocsToRespondentsRPCClientImpl = (
  apiClient: APIClient,
): AssignSpocsToRespondentsRPC => {
  if (MOCK.spocManagement) {
    return new MockAssignSpocsToRespondentsRPCClientImpl();
  } else {
    return new AssignSpocsToRespondentsRPCClientImpl(apiClient);
  }
};

export const useAddSpocToProjectRPCClientImpl = (
  apiClient: APIClient,
): AddSpocToProjectRPC => {
  if (MOCK.userAccess) {
    return new MockAddSpocToProjectRPCClientImpl();
  } else {
    return new AddSpocToProjectRPCClientImpl(apiClient);
  }
};

export const useGetSpocDetailsRPCClient = (
  apiClient: APIClient,
): GetSpocDetailsRPC => {
  if (MOCK.userAccess) {
    return new MockGetSpocDetailsRPCClientImpl();
  } else {
    return new GetSpocDetailsRPCClientImpl(apiClient);
  }
};

export const useUpdateSpocNameRPCClient = (
  apiClient: APIClient,
): UpdateSpocNameRPC => {
  if (MOCK.userAccess) {
    return new MockUpdateSpocNameRPCClientImpl();
  } else {
    return new UpdateSpocNameRPCClientImpl(apiClient);
  }
};

export const useResetSpocPasswordRPCClient = (
  apiClient: APIClient,
): ResetSpocPasswordRPC => {
  if (MOCK.userAccess) {
    return new MockResetSpocPasswordRPCClientImpl();
  } else {
    return new ResetSpocPasswordRPCClientImpl(apiClient);
  }
};

export const useChangeSpocPasswordRPCClient = (
  apiClient: APIClient,
): ChangeSpocPasswordRPC => {
  if (MOCK.userAccess) {
    return new MockChangeSpocPasswordRPCClientImpl();
  } else {
    return new ChangeSpocPasswordRPCClientImpl(apiClient);
  }
};

export const useDeleteProjectSpocsRPCClient = (
  apiClient: APIClient,
): DeleteProjectSpocsRPC => {
  if (MOCK.userAccess) {
    return new MockDeleteProjectSpocsRPCClientImpl();
  } else {
    return new DeleteProjectSpocsRPCClientImpl(apiClient);
  }
};

export const useUnassignRespondentFromSpocRPCClientImpl = (
  apiClient: APIClient,
): UnassignRespondentFromSpocRPC => {
  if (MOCK.userAccess) {
    return new MockUnassignRespondentFromSpocRPCClientImpl();
  } else {
    return new UnassignRespondentFromSpocRPCClientImpl(apiClient);
  }
};

export const useGetProjectBOUsersRPCClient = (
  apiClient: APIClient,
): GetProjectBOUsersRPC => {
  if (MOCK.userAccess) {
    return new MockGetProjectBOUsersRPCClientImpl();
  } else {
    return new GetProjectBOUsersRPCClientImpl(apiClient);
  }
};

export const useReopenSurveyLinksRPCClientImpl = (
  apiClient: APIClient,
): ReopenSurveyLinksRPC => {
  if (MOCK.surveyRespondent) {
    return new MockReopenSurveyLinksRPCClientImpl();
  } else {
    return new ReopenSurveyLinksRPCClientImpl(apiClient);
  }
};

export const useGetBOUserDetailsRPCClient = (
  apiClient: APIClient,
): GetBOUserDetailsRPC => {
  if (MOCK.userAccess) {
    return new MockGetBOUserDetailsRPCClientImpl();
  }
  return new GetBOUserDetailsRPCClientImpl(apiClient);
};

export const useAddBOUserToProjectRPCClient = (
  apiClient: APIClient,
): AddBOUserToProjectRPC => {
  if (MOCK.userAccess) {
    return new MockAddBOUserToProjectRPCClientImpl();
  }
  return new AddBOUserToProjectRPCClientImpl(apiClient);
};

export const useUpdateProjectDetailsRPCClient = (
  apiClient: APIClient,
): UpdateProjectDetailsRPC => {
  if (MOCK.projectList) {
    return new MockUpdateProjectDetailsRPCClientImpl();
  }
  return new UpdateProjectDetailsRPCClientImpl(apiClient);
};

export const useGetProjectDetailsClientImpl = (
  apiClient: APIClient,
): GetProjectDetailsRPC => {
  if (MOCK.projectList) {
    return new MockGetProjectDetails();
  } else {
    return new GetProjectDetailsRPCClientImpl(apiClient);
  }
};

export const useDeleteProjectBOUsersRPCClientImpl = (
  apiClient: APIClient,
): DeleteProjectBOUsersRPC => {
  if (MOCK.userAccess) {
    return new MockDeleteProjectBOUsersRPCClientImpl();
  } else {
    return new DeleteProjectBOUsersRPCClientImpl(apiClient);
  }
};

export const useCloneSurveyRPCClientImpl = (
  apiClient: APIClient,
): CloneSurveyRPC => {
  if (MOCK.survey) {
    return new MockCloneSurveyRPCClientImpl();
  } else {
    return new CloneSurveyRPCClientImpl(apiClient);
  }
};

export const useGetRespondentColumnNamesRPCClientImpl = (
  apiClient: APIClient,
): GetRespondentColumnNamesRPC => {
  if (MOCK.surveyResults) {
    return new MockGetRespondentColumnNamesRPCClientImpl();
  } else {
    return new GetRespondentColumnNamesRPCClientImpl(apiClient);
  }
};

export const useGetPivotTableDataRPCClientImpl = (
  apiClient: APIClient,
): GetPivotTableDataRPC => {
  if (MOCK.surveyResults) {
    return new MockGetPivotTableDataRPCClientImpl();
  } else {
    return new GetPivotTableDataRPCClientImpl(apiClient);
  }
};
