import { Instance, types } from "mobx-state-tree";
import { RespondentModel, createRespondentModel } from "./RespondentModel";
import { SurveyLinkStatus } from "@pulse/pulse-rpcs";
import { RespondentWithSurveyDetails } from "@pulse/pulse-rpcs";

export const RespondentWithSurveyDetailsModel = types.model(
  "RespondentWithSurveyDetailsModel",
  {
    respondent: RespondentModel,
    surveyLinkStatus: types.enumeration<SurveyLinkStatus.SurveyLinkStatus>(
      "SurveyLinkStatus",
      Object.values(SurveyLinkStatus.SurveyLinkStatus),
    ),
    surveyName: types.maybeNull(types.string),
  },
);

export const createRespondentWithSurveyDetailsModel = ({
  respondent,
  surveyLinkStatus,
  surveyName,
}: RespondentWithSurveyDetails): Instance<
  typeof RespondentWithSurveyDetailsModel
> => {
  return RespondentWithSurveyDetailsModel.create({
    respondent: createRespondentModel(respondent),
    surveyLinkStatus,
    surveyName: surveyName && surveyName.name,
  });
};
