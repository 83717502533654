import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { eagleEnTranslation } from "@pulse/pulse-ui-strings";

export const resources = {
  en: {
    translation: eagleEnTranslation,
  },
};

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources,
  interpolation: { escapeValue: false },
});
