import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React, { SetStateAction } from "react";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { ErrorSuccessDialogContent } from "../ErrorSuccessDialogContent";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";
import { DialogLoaderContent } from "../DialogLoaderContent";
import {
  AddNewEmbedDialogState,
  AddNewEmbedError,
  EmbedStore,
} from "../../store/EmbedStore";
import { MAX_EMBED_COUNT_PER_PROJECT } from "@pulse/shared-components";
import { BorderStyle } from "@pulse/shared-components";
import { AddNewEmbedDialogChild } from "./AddNewEmbedDialogChild";

interface AddNewEmbedDialogProps {
  isAddNewEmbedDialogOpen: boolean;
  setIsAddNewEmbedDialogOpen: React.Dispatch<SetStateAction<boolean>>;
  t: TFunction;
  spacing: Spacing;
  embedStore: Instance<typeof EmbedStore>;
  projectDetailsStore: Instance<typeof ProjectDetailsStore>;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  navigateTo: NavigateToFunctions;
  border: BorderStyle;
}

export const AddNewEmbedDialog = observer(
  ({
    isAddNewEmbedDialogOpen,
    setIsAddNewEmbedDialogOpen,
    t,
    spacing,
    embedStore,
    projectDetailsStore,
    tokens,
    typography,
    navigateTo,
    border,
  }: AddNewEmbedDialogProps): React.ReactElement => {
    const getErrorText = (): string => {
      switch (embedStore.rpcAndValidationError) {
        case AddNewEmbedError.ProjectAlreadyArchived:
          return t("common.projectAlreadyArchivedErrorText");
        case AddNewEmbedError.MaximumEmbedCountReached:
          return t(
            "projects.reports.embeds.maximumEmbedCountExceededErrorText",
            { maxEmbedCount: MAX_EMBED_COUNT_PER_PROJECT },
          );
        case AddNewEmbedError.InvalidSpocId:
        default:
          return t("projects.reports.embeds.dialogErrorText");
      }
    };

    const getSecondaryButtonText = (): string => {
      switch (embedStore.addNewEmbedDialogState) {
        case AddNewEmbedDialogState.FetchingSPoCDetailsError:
        case AddNewEmbedDialogState.AddNewEmbedError:
          return t("common.close");
        case AddNewEmbedDialogState.AddNewEmbedSuccess:
          return t("common.dismiss");
        default:
          return t("common.cancel");
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (embedStore.addNewEmbedDialogState) {
        case AddNewEmbedDialogState.FetchingSPoCDetails:
          return (
            <DialogLoaderContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              loaderText={t("projects.commonTexts.fetchingSpocsText")}
            />
          );
        case AddNewEmbedDialogState.FetchingSPoCDetailsSuccess:
          return (
            <AddNewEmbedDialogChild
              t={t}
              spacing={spacing}
              embedStore={embedStore}
              projectDetailsStore={projectDetailsStore}
              tokens={tokens}
              typography={typography}
              border={border}
            />
          );
        case AddNewEmbedDialogState.FetchingSPoCDetailsError:
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={t(
                "projects.commonTexts.fetchingSpocsErrorText",
              )}
            />
          );
        case AddNewEmbedDialogState.AddNewEmbedSuccess:
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              isError={false}
              errorSuccessText={t(
                "projects.reports.embeds.addEmbedSuccessText",
              )}
              typography={typography}
            />
          );
        case AddNewEmbedDialogState.AddNewEmbedError:
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={getErrorText()}
            />
          );
      }
    };

    return (
      <Dialog
        open={isAddNewEmbedDialogOpen}
        title={t("projects.reports.embeds.addNewEmbed")}
        secondaryButtonText={getSecondaryButtonText()}
        isSecondaryButtonDisabled={
          embedStore.isRPCLoading || projectDetailsStore.isRPCLoading
        }
        onSecondaryButtonClick={() => {
          setIsAddNewEmbedDialogOpen(false);
          embedStore.resetAddNewEmbedDialog();
          projectDetailsStore.clearRPCError();
        }}
        primaryButtonText={
          embedStore.isAddNewEmbedDialogPrimaryButtonVisible
            ? t("projects.reports.embeds.addEmbedButtonText")
            : undefined
        }
        onPrimaryButtonClick={async (): Promise<void> => {
          if (embedStore.validateNewEmbedInputFields()) {
            await processProjectParams(embedStore.addNewEmbed, navigateTo);
          }
        }}
        isPrimaryButtonDisabled={embedStore.isEmbedDialogPrimaryButtonDisabled}
        disableBackdropClick={true}
        contentPadding={"0px"}
        width="560px"
      >
        {/*
        getDialogChild() does is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
