import { Stack, Typography } from "@mui/material";
import { getIconProps, ICON_SIZE } from "@pulse/shared-components";
import {
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { HelpCircle } from "lucide-react";
import { observer } from "mobx-react";
import React from "react";
import { NestedTypographyComponent } from "./NestedTypographyComponent";

interface NestedTypographyDestructiveComponentProps {
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  middleBoldText: string;
  startText: string;
  endText: string;
  noteText: string | undefined;
  spacing: Spacing;
}

export const NestedTypographyDestructiveComponent = observer(
  ({
    tokens,
    typography,
    middleBoldText,
    startText,
    endText,
    noteText,
    spacing,
  }: NestedTypographyDestructiveComponentProps): React.ReactElement => {
    return (
      <Stack direction="row" gap={spacing.spaceXS}>
        <HelpCircle {...getIconProps(tokens.iconError, ICON_SIZE.default)} />
        <Stack gap={noteText ? spacing.spaceXS : undefined}>
          <NestedTypographyComponent
            textColor={tokens.labelError}
            startText={startText}
            middleBoldText={middleBoldText}
            endText={endText}
            startAndEndTextTypography={typography.b2}
            boldTextTypography={typography.s2}
            isSpan={false}
          />
          {noteText && (
            <Typography
              {...typography.b2}
              color={tokens.labelError}
              width="492px"
            >
              {noteText}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  },
);
